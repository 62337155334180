import { useState } from "react";

import moment from "moment";

import getRequests from "../api/get-requests";

import applicationConfig from "../config/applicationConfig";
import handleError from "../utilities/handleError";
import parseSearchFilter from "../utilities/parseSearchFilter";

import useLoadingSpinner from "./useLoadingSpinner";
import useNotifier from "./useNotifier";

const useRequestsFilters = (config) => {
  const { addNotification } = useNotifier();

  const [myRequests, setMyRequests] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();
  const [myRequestsCount, setMyRequestsCount] = useState(0);

  const loadMyRequests = async (_page = 1, perPage = 20, filter) => {
    let filterFieldMap = {
      requestId: "requestId",
      name: "name",
      requester: "requester",
      status: "status",
      modules: "modules",
      type: "type",
      searchText: "searchText",
    };
    if (config.type === applicationConfig.common.approval) {
      filterFieldMap = {
        ...filterFieldMap,
        regions: "regions",
      };
    }
    if (config.module === applicationConfig.modules.data_sources) {
      filterFieldMap = {
        ...filterFieldMap,
        sourceName: "name",
      };
    } else if (config.module === applicationConfig.modules.traits) {
      filterFieldMap = {
        ...filterFieldMap,
        traitName: "name",
      };
    } else if (config.module === applicationConfig.modules.opts) {
      filterFieldMap = {
        ...filterFieldMap,
        serviceName: "name",
      };
    } else if (config.module === applicationConfig.modules.marketing_programs) {
      filterFieldMap = {
        ...filterFieldMap,
        marketingProgramName: "name",
      };
    } else if (config.module === applicationConfig.modules.events) {
      filterFieldMap = {
        ...filterFieldMap,
        eventName: "name",
      };
    }
    let backendFilter = filter;
    // Push type always
    backendFilter = [
      ...backendFilter,
      {
        name: "type",
        value: config.type,
      },
    ];
    if (
      config.module === applicationConfig.modules.data_sources ||
      config.module === applicationConfig.modules.traits ||
      config.module === applicationConfig.modules.opts ||
      config.module === applicationConfig.modules.marketing_programs ||
      config.module === applicationConfig.modules.events
    ) {
      backendFilter = [
        ...backendFilter,
        {
          name: "modules",
          value: config.module,
        },
      ];
    }

    let noFilterSelected = false;
    backendFilter = backendFilter.map((obj) => {
      if (obj.name === "status") {
        if (obj.value.length === 0) {
          noFilterSelected = true;
        }
        return {
          name: "status",
          value: obj.value
            .split(",")
            .map((_status) => _status.toLowerCase())
            .filter((x) => x !== "all")
            .join(","),
        };
      }
      if (obj.name === "searchText") {
        return {
          name: "searchText",
          value: obj.value?.replace(/CRS/gi, ""),
        };
      }
      if (obj.name === "requestId") {
        return {
          name: "requestId",
          value: obj.value?.replace(/CRS/gi, ""),
        };
      }
      if (obj.name === applicationConfig.filters.region) {
        if (obj.value.length === 0) {
          noFilterSelected = true;
        }
        return {
          name: "regions",
          value: obj.value.filter((x) => x !== "all").join(","),
        };
      }
      if (
        config.module === applicationConfig.modules.global &&
        obj.name === "modules"
      ) {
        if (obj.value.length === 0) {
          noFilterSelected = true;
        }
        return {
          name: "modules",
          value: obj.value
            .map((_type) => {
              if (_type === applicationConfig.modules.data_sources2) {
                return applicationConfig.modules.data_sources;
              }
              if (_type === applicationConfig.modules.marketing_programs2) {
                return applicationConfig.modules.marketing_programs;
              }
              if (_type) {
                return _type.toLowerCase();
              }
              return "";
            })
            .filter((x) => x !== "all")
            .join(","),
        };
      }
      return obj;
    });
    if (noFilterSelected) {
      setMyRequests([]);
      return null;
    }

    const startDate = filter.find(
      (x) => x.name === applicationConfig.filters.start_date
    );
    const endDate = filter.find(
      (x) => x.name === applicationConfig.filters.end_date
    );

    if (startDate && endDate) {
      if (startDate.value && endDate.value) {
        if (startDate.value > endDate.value) {
          return null;
        }
        filterFieldMap = {
          ...filterFieldMap,
          startDate: "createdDateStart",
          endDate: "createdDateEnd",
        };
        startDate.value = moment(
          `${startDate.value} 00:00:00`,
          applicationConfig.common.yyyy_mm_dd_hh_mm_ss
        )
          .utc()
          .toISOString();
        endDate.value = moment(
          `${endDate.value} 23:59:59`,
          applicationConfig.common.yyyy_mm_dd_hh_mm_ss
        )
          .utc()
          .toISOString();
      } else if (startDate.value || endDate.value) {
        return null;
      }
    }

    increaseRequestsCount();
    setMyRequests([]);

    const apiFilter = [];
    backendFilter.map((obj) => {
      if (config.type !== applicationConfig.common.approval) {
        if (obj.name === applicationConfig.filters.region) {
          return null;
        }
      }
      const index = apiFilter.findIndex((obj1) => obj1.name === obj.name);
      if (index === -1) {
        apiFilter.push(obj);
      } else {
        apiFilter[index].value = obj.value;
      }
      return null;
    });

    try {
      const response = await getRequests(
        parseSearchFilter(apiFilter, filterFieldMap, _page, perPage)
      );
      setMyRequests(
        response.items.map((item, index) => ({
          ...item,
          id: index,
        }))
      );
      setMyRequestsCount(response.totalItems);
      return { ...response, module: "tasks" };
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setMyRequestsCount(0);
        },
        addNotification,
      });
      setMyRequests([]);
    } finally {
      decreaseRequestsCount();
    }
    return null;
  };

  return {
    myRequests,
    loadMyRequests,
    searchFilter,
    setSearchFilter,
    myRequestsCount,
    setMyRequests,
    setMyRequestsCount,
  };
};

export default useRequestsFilters;
