import moment from "moment";

const parseDate = (date) => {
  if (date === "") {
    return "";
  }
  return date ? moment(date).format("YYYY-MM-DD") : null;
};

export default parseDate;
