import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ErrorIcon from "@material-ui/icons/Error";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import InfoIcon from "@material-ui/icons/Info";

import { ReactComponent as ApprovedIcon } from "../assets/images/approved.svg";
import { ReactComponent as PendingIcon } from "../assets/images/pending.svg";
import { ReactComponent as RejectedIcon } from "../assets/images/rejected.svg";
import { ReactComponent as ReturnedIcon } from "../assets/images/returned.svg";
import { lightTheme } from "../assets/styles/themes";

const useStyles = {
  statusText: {
    verticalAlign: "top",
  },
  statusAlert: {
    height: "10px",
    width: "10px",
    marginRight: "9px",
    borderRadius: "50%",
    marginTop: "2px",
  },
  statusPending: lightTheme.palette.static.orange,
  statusApproved: lightTheme.palette.static.blandGreen,
  statusRejected: lightTheme.palette.static.strongPing,
  statusReturned: lightTheme.palette.static.subtleYellow,
  statusFailed: lightTheme.palette.static.hardRed,
  statusSuccessPartial: lightTheme.palette.static.pureYellow,
  statusProcessing: lightTheme.palette.blue.light,
  infoIcon: {
    width: 18,
    height: 18,
    marginLeft: "6px",
    position: "relative",
    top: "-1px",
    cursor: "pointer",
  },
  outlinedIcon: {
    position: "relative",
    top: "-2px",
    cursor: "pointer",
  },
  editIcon: {
    width: 16,
    height: 16,
    cursor: "pointer",
    position: "relative",
    bottom: "1px",
    left: "10px",
  },
};

const getStatusColorIcon = (value, requestStatus) => {
  const classes = useStyles;

  let statusColor;
  let statusIcon = [];

  switch (value) {
    case "pending":
    case requestStatus.PENDING:
      statusColor = classes.statusPending;
      statusIcon = [<PendingIcon />, <InfoIcon style={classes.infoIcon} />];
      break;
    case "success":
    case "approved":
    case requestStatus.APPROVED:
      statusColor = classes.statusApproved;
      statusIcon = [
        <ApprovedIcon />,
        <FindInPageOutlinedIcon
          style={{ ...classes.infoIcon, ...classes.outlinedIcon }}
        />,
      ];
      break;
    case "rejected":
    case requestStatus.REJECTED:
      statusColor = classes.statusRejected;
      statusIcon = [<RejectedIcon />];
      break;
    case "returned":
    case requestStatus.RETURNED:
      statusColor = classes.statusReturned;
      statusIcon = [<ReturnedIcon />, <EditIcon />];
      break;
    case "error":
    case requestStatus.ERROR:
      statusColor = classes.statusRejected;
      statusIcon = [<InfoIcon style={classes.infoIcon} />];
      break;
    case "processing":
    case requestStatus.PROCESSING:
      statusColor = classes.statusProcessing;
      statusIcon = [<PendingIcon />, <InfoIcon style={classes.infoIcon} />];
      break;
    case "failed":
    case requestStatus.FAILED:
      statusColor = classes.statusFailed;
      statusIcon = [<CloseIcon />];
      break;
    case "success-partial":
    case requestStatus.SUCCESS_PARTIAL:
      statusColor = classes.statusSuccessPartial;
      statusIcon = [<ErrorIcon style={classes.infoIcon} />];
      break;
    default:
      statusColor = classes.statusPending;
      statusIcon = [<InfoIcon style={classes.infoIcon} />];
  }

  return {
    statusColor,
    statusIcon,
  };
};

export default getStatusColorIcon;
