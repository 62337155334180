import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100%",
    },
    marginTop20: {
      marginTop: "10px",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
      },
    },
    autoCompleteBox: {
      width: "100%",
    },
    alert: {
      width: "fit-content",
    },
    autoCompleteEditContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.blue.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    autoCompleteDeleteContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.error.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    accordion: {
      borderRadius: "4px",
      marginBottom: 16,
      width: "90%",
    },
    accordionHeader: {
      padding: "0 16px",
      "& .MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
      },
    },
    accordionContent: {
      margin: "0 !important",
      display: "flex",
      flexDirection: "column",
    },
    accordionChips: {
      "& .MuiAvatar-root": {
        fontSize: "8px",
      },
    },
    popper: {
      "& .MuiCheckbox-root": {
        color: theme.palette.primary.light,
      },
    },
    badge: {
      backgroundColor: theme.colorTheme.bluishWhite,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "4px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.colorTheme.grey,
    },
    useCaseContainer: {
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    paddingZero: {
      padding: "0px",
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    noOptionsContainer: {
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
      },
    },
    inputContainer: {
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: `${theme.colorTheme.black}`,
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    backBtn: {
      "& a": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
      "& button": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
    },
    marketingProgramContainer: {
      "& > div": {
        marginBottom: "30px",
      },
      "& .MuiTypography-h4": {
        fontSize: "16px",
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
    },
    confirmationContainer: {
      "& > div": {
        marginTop: "10px",
      },
      "& .MuiTypography-h4": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
        fontWeight: 500,
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
    },
    newBtnContainer: {
      display: "inline-block",
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.primary.light2,
        color: theme.colorTheme.grey,
        margin: "0px 10px",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    bold: {
      color: theme.colorTheme.grey,
      fontWeight: "bold",
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: `${theme.palette.background.default1} !important`,
      },
    },
    trait: {
      "& > div:first-child": {
        flex: "0 0 90%",
      },
      "& > div:last-child": {
        flex: "0 0 10%",
      },
    },
    traitTitle: {
      backgroundColor: theme.palette.blue.dark,
      color: theme.palette.static.white,
      minWidth: 88,
      padding: "10px 16px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
    },
    traitDescription: {
      marginLeft: 16,
    },
    statusLabel: {
      marginLeft: "auto",
    },
    deleted: {
      backgroundColor: theme.palette.error.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    addTraitBtn: {
      "& .MuiButton-label": {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
    },
    grid: {
      display: "grid",
      gridGap: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
      "& .MuiAutocomplete-root": {
        width: "auto !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginLeft: "10px",
      cursor: "pointer",
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    statusPending: {
      backgroundColor: theme.palette.warning.light,
    },
    statusApproved: {
      backgroundColor: theme.palette.green.main,
    },
    statusRejected: {
      backgroundColor: theme.palette.error.main,
    },
    fileIconWrapper: {
      marginLeft: "10px",
    },
    traitName: {
      cursor: "pointer",
    },
    traitId: {
      cursor: "pointer",
    },
    loadingSkeleton: {
      width: "807px !important",
      padding: "30px",
    },
    shrinkLabel: {
      transform: "translate(0, 1.5px)",
    },
  };
});

export default useStyles;
