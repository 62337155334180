import { useEffect, useMemo, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getReferenceData from "../../../../../api/get-reference-data";

import Form from "../../../../../components/Form";
import InlineMessage from "../../../../../components/InlineMessage";

import applicationConfig from "../../../../../config/applicationConfig";
import marketingProgramsModuleConfig from "../../../../../config/marketingProgramsModuleConfig";

import useImmer from "../../../../../utilities/useImmer";

import EditableText from "../../../NewMarketingProgramsContainer/components/EditableText";
import getEcosystemFields from "../../getEcosystemFields";

import validateEcosystemMapping from "../../helpers/validateEcosystemMapping";

import useStyles from "./styles";

const EcosystemMapping = ({
  ecosystems,
  ecosystemDetails,
  setEcosystemDetails,
  handleEcosystemChange,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  isExistingEcosystem,
}) => {
  const initialFocusedValues = {
    ecosystemId: false,
    ecosystemMarketingProgramName: false,
    ecosystemMarketingProgramKey: false,
    ecosystemMarketingProgramInstance: false,
    automationEnabled: false,
    segmentPersonaSpace: false,
    lyticsSecret: false,
  };

  const ecosystem =
    ecosystemDetails.ecosystemName ||
    ecosystems?.find(
      (system) => system.ecoSystemId === ecosystemDetails.ecosystemId
    )?.ecoSystemName;

  const [focused, setFocused] = useImmer(initialFocusedValues);

  const { mpEcosystemMappingConstants } = marketingProgramsModuleConfig;
  const { ecosystemNames } = mpEcosystemMappingConstants;

  const [isCancelClicked, setIsCancelClicked] = useState(false);

  const { t } = useTranslation();

  const classes = useStyles();

  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    (async () => {
      const { legalEntities } = await getReferenceData(
        applicationConfig.referenceDataQueries.countryCodes
      );
      setCountryCodes(legalEntities.map((code) => code.legalEntityShortName));
    })();
  }, []);

  let hideSubmitBtn = false;

  if (isDisabled) {
    hideSubmitBtn = true;
  }
  let disabled = isDisabled;
  // Set isDisable to true if it's set in ecosystem details
  if (ecosystemDetails.disable) {
    disabled = ecosystemDetails.disable;
  }

  let isEcosystemConfigurationAutoGenerated = false;
  let isExistingMappingAndAutomationEnabled = false;
  let isExistingMappingAndAutomationNotEnabled = false;

  if (ecosystemDetails.disable) {
    if (ecosystemDetails.showAutoGeneratedMessage) {
      isEcosystemConfigurationAutoGenerated = true;
    } else if (ecosystemDetails.showEnableAutomation) {
      isExistingMappingAndAutomationNotEnabled = true;
    } else {
      isExistingMappingAndAutomationEnabled = true;
    }
  }

  let noValidation = false;

  if (isExistingMappingAndAutomationEnabled || hideSubmitBtn) {
    noValidation = true;
  }

  const errors = useMemo(
    () =>
      validateEcosystemMapping(ecosystemDetails, noValidation, countryCodes),
    [countryCodes, ecosystemDetails, focused]
  );

  const validate = () => {
    const errorValues = Object.values(errors);
    return (
      errorValues.length > 0 && errorValues.some((error) => error !== null)
    );
  };

  let ecosystemFields = getEcosystemFields(ecosystemDetails);

  let tooltip = {
    placement: "top-end",
    icon: <InfoIcon className={classes.infoIcon} />,
  };

  ecosystemFields = ecosystemFields.map((field) => {
    if (field.label === t("common.labels.ecosystem")) {
      return {
        ...field,
        required: true,
        props: {
          label: "",
          select: true,
          variant: "outlined",
          onChange: (event) => {
            handleEcosystemChange(event.target.value);
          },
          values: ecosystems.map((option) => ({
            label: option.ecoSystemName,
            value: option.ecoSystemName,
          })),
          inputProps: {
            "data-testid": "ecosystems",
          },
          value: ecosystem,
          error: focused.ecosystemId && errors.ecosystemError !== null,
          helperText: focused.ecosystemId && (
            <InlineMessage message={errors.ecosystemError} />
          ),
          disabled: hideSubmitBtn,
          onBlur: () => {
            setFocused((draft) => {
              draft.ecosystemId = true;
            });
          },
        },
      };
    }
    if (field.label === t("mp_ecosystem_mapping_container.ecosystem_mp_name")) {
      const format =
        mpEcosystemMappingConstants.tooltips.ecosystem_mp_name.mapping[
          ecosystem
        ];
      if (format) {
        tooltip = {
          ...tooltip,
          title: t(
            "mp_ecosystem_mapping_container.tooltips.ecosystem_mp_name",
            {
              ecosystem,
              format,
            }
          ),
        };
      } else {
        tooltip = {
          ...tooltip,
          title: t(
            "mp_ecosystem_mapping_container.tooltips.ecosystem_mp_name_1"
          ),
        };
      }
      return {
        ...field,
        tooltip,
        required: true,
        type: "custom",
        element: EditableText,
        props: {
          editMode:
            isExistingEcosystem &&
            isExistingEcosystem?.ecosystemMarketingProgramName,
          onChange: async (value) => {
            setEcosystemDetails((draft) => {
              draft.ecosystemMarketingProgramName = value;
            });
          },
          onCancel: (initValue) => {
            setEcosystemDetails((draft) => {
              draft.ecosystemMarketingProgramName = initValue;
            });
          },
          variant: "outlined",
          value: ecosystemDetails.ecosystemMarketingProgramName,
          error:
            focused.ecosystemMarketingProgramName &&
            errors.ecosystemMarketingProgramNameError !== null,
          helperText: focused.ecosystemMarketingProgramName && (
            <InlineMessage
              message={errors.ecosystemMarketingProgramNameError}
            />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.ecosystemMarketingProgramName = true;
            });
          },
          isDisabled:
            (disabled &&
              ecosystemDetails.ecosystemMarketingProgramNameDisabled) ||
            hideSubmitBtn ||
            ecosystemDetails.disable,
          disabled:
            (disabled &&
              ecosystemDetails.ecosystemMarketingProgramNameDisabled) ||
            hideSubmitBtn ||
            ecosystemDetails.disable,
          inputProps: {
            "data-testid": "ecosystem-mp-name",
          },
        },
      };
    }
    if (field.label === t("mp_ecosystem_mapping_container.ecosystem_mp_key")) {
      const message =
        mpEcosystemMappingConstants.tooltips.ecosystem_mp_key.mapping[
          ecosystem
        ];
      if (message) {
        tooltip = {
          ...tooltip,
          title: t("mp_ecosystem_mapping_container.tooltips.ecosystem_mp_key", {
            ecosystem,
            message,
          }),
        };
      } else {
        tooltip = {
          ...tooltip,
          title: t(
            "mp_ecosystem_mapping_container.tooltips.ecosystem_mp_key_1"
          ),
        };
      }
      return {
        ...field,
        tooltip,
        type: "custom",
        element: EditableText,
        required:
          ecosystem === ecosystemNames.SEGMENT ||
          ecosystem === ecosystemNames.LYTICS ||
          ecosystem === ecosystemNames.MAPP,
        props: {
          editMode:
            isExistingEcosystem &&
            isExistingEcosystem?.ecosystemMarketingProgramKey,
          onChange: async (value) => {
            setEcosystemDetails((draft) => {
              draft.ecosystemMarketingProgramKey = value;
            });
          },
          onCancel: (initValue) => {
            setEcosystemDetails((draft) => {
              draft.ecosystemMarketingProgramKey = initValue;
            });
          },
          variant: "outlined",
          value: ecosystemDetails.ecosystemMarketingProgramKey,
          error:
            focused.ecosystemMarketingProgramKey &&
            errors.ecosystemMarketingProgramKeyError !== null,
          helperText: focused.ecosystemMarketingProgramKey && (
            <InlineMessage message={errors.ecosystemMarketingProgramKeyError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.ecosystemMarketingProgramKey = true;
            });
          },
          isDisabled:
            (disabled &&
              ecosystemDetails.ecosystemMarketingProgramKeyDisabled) ||
            hideSubmitBtn ||
            ecosystemDetails.disable,
          inputProps: {
            "data-testid": "ecosystem-mp-key",
          },
        },
      };
    }
    if (
      field.label === t("mp_ecosystem_mapping_container.ecosystem_instance")
    ) {
      const message =
        mpEcosystemMappingConstants.tooltips.ecosystem_instance.mapping[
          ecosystem
        ];
      if (message) {
        tooltip = {
          ...tooltip,
          title: t(
            "mp_ecosystem_mapping_container.tooltips.ecosystem_instance",
            {
              ecosystem,
              message,
            }
          ),
        };
      } else {
        tooltip = {
          ...tooltip,
          title: t(
            "mp_ecosystem_mapping_container.tooltips.ecosystem_instance_1"
          ),
        };
      }
      return {
        ...field,
        tooltip,
        type: "custom",
        element: EditableText,
        required:
          ecosystem === ecosystemNames.SEGMENT ||
          ecosystem === ecosystemNames.MAPP ||
          ecosystem === ecosystemNames.JANRAIN,
        props: {
          editMode:
            isExistingEcosystem &&
            isExistingEcosystem?.ecosystemMarketingProgramInstance,
          onChange: async (value) => {
            setEcosystemDetails((draft) => {
              draft.ecosystemMarketingProgramInstance = value;
            });
          },
          onCancel: (initValue) => {
            setEcosystemDetails((draft) => {
              draft.ecosystemMarketingProgramInstance = initValue;
            });
          },
          variant: "outlined",
          value: ecosystemDetails.ecosystemMarketingProgramInstance,
          error:
            focused.ecosystemMarketingProgramInstance &&
            errors.ecosystemMarketingProgramInstanceError !== null,
          helperText: focused.ecosystemMarketingProgramInstance && (
            <InlineMessage
              message={errors.ecosystemMarketingProgramInstanceError}
            />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.ecosystemMarketingProgramInstance = true;
            });
          },
          isDisabled:
            (disabled &&
              ecosystemDetails.ecosystemMarketingProgramInstanceDisabled) ||
            hideSubmitBtn ||
            ecosystemDetails.disable,
          inputProps: {
            "data-testid": "ecosystem-instance",
          },
        },
      };
    }

    if (
      ecosystemDetails.showEnableAutomation &&
      field.label === t("mp_ecosystem_mapping_container.enable_automation")
    ) {
      return {
        ...field,
        label: "",
        element: FormGroup,
        props: {},
        children: [
          {
            element: FormControlLabel,
            props: {
              disabled: false,
              control: (
                <div className={classes.checkboxContainer}>
                  <Checkbox
                    checked={ecosystemDetails.automationEnabled}
                    onChange={() => {
                      setEcosystemDetails((draft) => {
                        draft.automationEnabled = !draft.automationEnabled;
                      });
                    }}
                    disabled={hideSubmitBtn || ecosystemDetails.disable}
                    name="enable automation"
                  />
                </div>
              ),
              label: "Enable Automation" || (
                <Alert icon={false} severity="info">
                  {t("mp_ecosystem_mapping_container.enable_automation")}
                </Alert>
              ),
            },
          },
        ],
      };
    }

    if (
      field.label ===
        t("mp_ecosystem_mapping_container.segment_persona_space") &&
      ecosystem === ecosystemNames.SEGMENT
    ) {
      if (!ecosystemDetails.automationEnabled) {
        return {};
      }

      if (isExistingMappingAndAutomationEnabled) {
        return {};
      }

      if (
        isExistingMappingAndAutomationNotEnabled &&
        !ecosystemDetails.automationEnabled
      ) {
        return {};
      }

      return {
        ...field,
        required: ecosystem === ecosystemNames.SEGMENT,
        tooltip: {
          title: t(
            "mp_ecosystem_mapping_container.tooltips.segment_lytics_hover_info"
          ),
          placement: "top-end",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        props: {
          label: "",
          select: true,
          variant: "outlined",
          onChange: (event) =>
            setEcosystemDetails((draft) => {
              draft.segmentPersonaSpace = event.target.value;
            }),
          values: mpEcosystemMappingConstants.yesOrNoOptions.map((option) => ({
            label: option,
            value: option,
          })),
          inputProps: {
            "data-testid": "segment-persona-space",
          },
          value: ecosystemDetails.segmentPersonaSpace,
          error:
            focused.segmentPersonaSpace &&
            errors.segmentPersonaSpaceError !== null,
          disabled: hideSubmitBtn,
          helperText: focused.segmentPersonaSpace && (
            <InlineMessage message={errors.segmentPersonaSpaceError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.segmentPersonaSpace = true;
            });
          },
        },
      };
    }
    if (
      field.label === t("mp_ecosystem_mapping_container.lytics_secret") &&
      ecosystem === ecosystemNames.LYTICS
    ) {
      if (!ecosystemDetails.automationEnabled) {
        return {};
      }

      if (isExistingMappingAndAutomationEnabled) {
        return {};
      }

      if (
        isExistingMappingAndAutomationNotEnabled &&
        !ecosystemDetails.automationEnabled
      ) {
        return {};
      }

      return {
        ...field,
        tooltip: {
          title: t(
            "mp_ecosystem_mapping_container.tooltips.segment_lytics_hover_info"
          ),
          placement: "top-end",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        props: {
          label: "",
          select: true,
          variant: "outlined",
          onChange: (event) =>
            setEcosystemDetails((draft) => {
              draft.lyticsSecret = event.target.value;
            }),
          values: mpEcosystemMappingConstants.yesOrNoOptions.map((option) => ({
            label: option,
            value: option,
          })),
          inputProps: {
            "data-testid": "lytics-secret",
          },
          value: ecosystemDetails.lyticsSecret,
          disabled: hideSubmitBtn,
          error: focused.lyticsSecret && errors.lyticsSecretError != null,
          helperText: focused.lyticsSecret && (
            <InlineMessage message={errors.lyticsSecretError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.lyticsSecret = true;
            });
          },
        },
      };
    }

    return {};
  });

  if (isEcosystemConfigurationAutoGenerated) {
    ecosystemFields.unshift({
      type: "custom",
      label: "",
      element: Alert,
      flex: 2,
      props: {
        severity: "error",
        children: t(
          "mp_ecosystem_mapping_container.messages.auto_generated_segment_configuration"
        ),
      },
    });
  }

  if (isExistingMappingAndAutomationEnabled) {
    ecosystemFields.unshift({
      type: "custom",
      label: "",
      element: Alert,
      flex: 2,
      props: {
        severity: "error",
        children: t(
          "mp_ecosystem_mapping_container.messages.mp_ecosystem_combination_exists"
        ),
      },
    });
  }

  useEffect(() => {
    if (!openModal) {
      setFocused(initialFocusedValues);
    }
  }, [openModal]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          handleClose();
        }}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">
            {ecosystemDetails.index === -1
              ? t("mp_ecosystem_mapping_container.new_ecosystem")
              : `${t("common.labels.ecosystem")} - ${ecosystem}`}
          </Typography>
          <div
            className={clsx(classes.marginLeftAuto, classes.closeBtn)}
            onClick={() => {
              handleClose();
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        <div className={classes.horizontalBar} />
        <DialogContent>
          <Form
            fields={ecosystemFields}
            fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
          />
          <div className={classes.horizontalBar} />
        </DialogContent>
        {!hideSubmitBtn && (
          <DialogActions>
            <div className={classes.btnContainer}>
              <Button
                onClick={() => {
                  setFocused({
                    ecosystemId: true,
                    ecosystemMarketingProgramName: true,
                    ecosystemMarketingProgramKey: true,
                    ecosystemMarketingProgramInstance: true,
                    automationEnabled: true,
                    segmentPersonaSpace: true,
                    lyticsSecret: true,
                  });
                  if (validate()) {
                    return;
                  }
                  handleSubmit(ecosystemDetails);
                }}
                color="primary"
                variant="contained"
              >
                {t("common.submit")}
              </Button>
            </div>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={clsx(classes.btnContainer, classes.flexContainer)}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

EcosystemMapping.defaultProps = {
  ecosystemDetails: {},
  ecosystems: [],
  setEcosystemDetails: () => {},
  handleSubmit: () => {},
  isDisabled: false,
  handleEcosystemChange: () => {},
  isExistingEcosystem: false,
};

EcosystemMapping.propTypes = {
  ecosystemDetails: PropTypes.shape({
    ecosystemId: PropTypes.string,
    ecosystemName: PropTypes.string,
    ecosystemMarketingProgramName: PropTypes.string,
    ecosystemMarketingProgramKey: PropTypes.string,
    ecosystemMarketingProgramInstance: PropTypes.string,
    segmentPersonaSpace: PropTypes.string,
    lyticsSecret: PropTypes.string,
    automationEnabled: PropTypes.bool,
    showEnableAutomation: PropTypes.bool,
    showAutoGeneratedMessage: PropTypes.bool,
    disable: PropTypes.bool,
    index: PropTypes.number,
    ecosystemMarketingProgramNameDisabled: PropTypes.bool,
    ecosystemMarketingProgramKeyDisabled: PropTypes.bool,
    ecosystemMarketingProgramInstanceDisabled: PropTypes.bool,
  }),
  handleEcosystemChange: PropTypes.func,
  setEcosystemDetails: PropTypes.func,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  ecosystems: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ),
  isExistingEcosystem: PropTypes.shape({
    ecosystemMarketingProgramName: PropTypes.string,
    ecosystemMarketingProgramInstance: PropTypes.string,
    ecosystemMarketingProgramKey: PropTypes.string,
  }),
};

export default EcosystemMapping;
