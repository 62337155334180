import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useGlobalStyles from "../../../../../assets/styles/global";
import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import Button from "../../../../../components/Button";
import CustomModal from "../../../../../components/CustomModal";

import applicationConfig from "../../../../../config/applicationConfig";
import consentModuleConfig from "../../../../../config/consentsModuleConfig";

import pageAccessConfig from "../../../../../config/pageAccessConfig";
import useUserProfile from "../../../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../../../utilities/checkUserAuthorization";

import useStyles from "./styles";

const ConsentTemplateVersionsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { user } = useUserProfile();
  const history = useHistory();

  const { consentTemplateVersions } = data;
  const { consentVersionKeys } = consentModuleConfig;

  const renderUi = () => {
    return (
      <AccordionModalDetails
        keys={consentVersionKeys}
        rows={consentTemplateVersions?.map((version) => ({
          ...version,
          templateVersionNumber: `V${version.templateVersionNumber}`,
          createdDate: version.createdDate
            .replace("T", " ")
            .replace(".000Z", ""),
        }))}
        rowKeys={{
          title: "templateVersionNumber",
          subTitle: "consentText",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>{renderUi()}</div>
        {checkUserAuthorization(
          user.access,
          pageAccessConfig.manageConsents
        ) && (
          <Button
            btnText={t("consent_container.create_new_version")}
            variant="contained"
            classes={{
              root: globalStyles.btn,
            }}
            onClick={() => {
              history.push(
                applicationConfig.pathnames.consent_templates_manage,
                {
                  consentTemplate: data,
                }
              );
            }}
            style={{
              marginRight: "34px",
            }}
          />
        )}
      </div>
    </CustomModal>
  );
};

ConsentTemplateVersionsModal.defaultProps = {
  title: null,
};

ConsentTemplateVersionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    consentTemplateVersions: PropTypes.shape([]).isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ConsentTemplateVersionsModal;
