import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    MuiPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minWidth: "807px !important",
      padding: "20px 25px",
      "& .MuiTypography-h5": {
        fontSize: "20px",
        color: theme.colorTheme.grey,
        fontWeight: "500",
      },
      "& .MuiDialogContent-root": {
        maxHeight: "810px",
        padding: "0px !important",
      },
      "& .MuiDialogActions-root": {
        padding: "0px !important",
      },
      "& .MuiFormHelperText-contained": {
        margin: "0px !important",
        marginTop: "5px !important",
      },
    },
    header: {
      marginBottom: "15px",
    },
    closeBtn: {
      cursor: "pointer",
    },
    horizontalBar: {
      height: "1px",
      backgroundColor: theme.colorTheme.greyishWhite,
      marginLeft: "-25px",
      marginBottom: "14px",
    },
    fieldContainer: {
      justifyContent: "space-between",
      marginBottom: "16px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "& input,& textarea, & .MuiInputBase-root": {
        minWidth: "558px",
      },
      "& .MuiSelect-root": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& .MuiFormControl-root": {
        marginRight: "10px",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };
});

export default useStyles;
