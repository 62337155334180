import { Fragment, useState, useEffect } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Close } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import getServiceNames from "../../../../../api/get-service-names";
import useGlobalStyles from "../../../../../assets/styles/global";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import Form from "../../../../../components/Form";
import InlineMessage from "../../../../../components/InlineMessage";
import optsModuleConfig from "../../../../../config/optsModuleConfig";
import useNotifier from "../../../../../hooks/useNotifier";
import handleError from "../../../../../utilities/handleError";
import isEmpty from "../../../../../utilities/isEmpty";
import useImmer from "../../../../../utilities/useImmer";

import NewServiceName from "../../../components/NewServiceName";
import SelectedService from "../../../components/SelectedService";
import validateNewMapping from "../../../NewOptIdContainer/helpers/validateNewMapping";
import getFields from "../../../NewOptsMappingContainer/getFields";

import useStyles from "./styles";

const NewOptIdMapping = ({
  optMappingInfo,
  setOptMappingInfo,
  optMappings,
  serviceName,
  setServiceName,
  cdpMarketingProgramOptions,
  cdpMarketingProgramsLoading,
  getcdpMarketingProgramOptions,
  editableOptMapping,
  optIds,
  optIdsLoading,
  openModal,
  handleClose,
  handleSubmit,
  title,
  subscriptionOptNumbers,
}) => {
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [optIdSearch, setOptIdSearch] = useState("");
  const [filteredOptIdOptions, setFiletedOptIdOptions] = useState([]);
  const [serviceNameOptions, setServiceNameOptions] = useState([]);
  const [newServiceName, setNewServiceName] = useImmer({
    marketingProgramName: "",
    serviceName: "",
    serviceNameDescription: "",
  });

  const [newServiceNameModal, setNewServiceNameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [serviceNamesLoading, setServiceNamesLoading] = useState(false);

  const [serviceNameFocused, setServiceNameFocused] = useImmer({
    marketingProgramName: false,
    serviceName: false,
    serviceNameDescription: false,
  });

  const [errors, setErrors] = useState({});

  const globalStyles = useGlobalStyles();

  const { t } = useTranslation();

  const { addNotification } = useNotifier();

  const resetState = () => {
    setServiceName({});
    setOptMappingInfo((draft) => {
      draft.optId = {};
      draft.channel = "";
      draft.contactPointCategoryCode = "";
      draft.serviceName = "";
      draft.primaryIndicator = "";
      draft.subscriptionOptNumber = "";
    });
    setServiceNameFocused((draft) => {
      draft.marketingProgramName = false;
      draft.serviceName = false;
      draft.serviceNameDescription = false;
    });
    setNewServiceName((draft) => {
      draft.marketingProgramName = "";
      draft.serviceName = "";
      draft.serviceNameDescription = "";
    });
    setErrors({});
  };

  useEffect(() => {
    if (Object.keys(editableOptMapping || {}).length > 0 && openModal) {
      const localStateData = editableOptMapping.og_data;
      if (localStateData) {
        setOptMappingInfo((draft) => {
          draft.optId = localStateData.optId;
          draft.cdpMarketingProgram = localStateData.cdpMarketingProgram;
          draft.channel = localStateData.channel;
          draft.contactPointCategoryCode =
            localStateData.contactPointCategoryCode;
          draft.serviceName = localStateData.serviceName;
          draft.primaryIndicator = localStateData.primaryIndicator;
          draft.subscriptionOptNumber = localStateData.subscriptionOptNumber;
        });
      }
    } else {
      resetState();
    }
  }, [editableOptMapping, openModal]);

  useEffect(() => {
    if (optIdSearch.length > 0) {
      setFiletedOptIdOptions(
        optIds
          .map((option) => ({
            ...option,
            title: option.ciamOptId,
          }))
          .filter((option) => option.ciamOptId.match(optIdSearch))
      );
    } else {
      setFiletedOptIdOptions(
        optIds.slice(0, 3).map((option) => ({
          ...option,
          title: option.ciamOptId,
        }))
      );
    }
  }, [optIdSearch, optIds]);

  const getServiceNamesFromApi = async (marketingProgramNumber) => {
    try {
      setServiceNamesLoading(true);
      const rsp1 = await getServiceNames(marketingProgramNumber, serviceName);
      setServiceNameOptions(rsp1);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setServiceNameOptions([]);
        },
        addNotification,
      });
    } finally {
      setServiceNamesLoading(false);
    }
  };

  const getDynamicPlaceholder = (field) => {
    if (field.label === t("common.labels.service_name")) {
      if (!optMappingInfo.cdpMarketingProgram) {
        return "Please select Marketing Program first";
      }
      return "Please select Service Name";
    }

    if (field.label === t("opts_mapping.contact_point_category_code")) {
      // Check if the channel is not selected
      if (!optMappingInfo.channel) {
        return "Please select Channel first";
      }
      // Return the placeholder for Contact Point Category code
      return "Contact Point Category code Placeholder";
    }

    // Return an empty string for other fields
    return "";
  };

  const onCloseDeleteModal = () => {
    setNewServiceName((draft) => {
      draft.marketingProgramName = "";
      draft.serviceName = "";
      draft.serviceNameDescription = "";
    });
    setServiceName({});
    setServiceNameFocused((draft) => {
      draft.marketingProgramName = false;
      draft.serviceName = false;
      draft.serviceNameDescription = false;
    });
    setOptMappingInfo((draft) => {
      draft.serviceName = "";
    });
    setOpenDeleteModal(false);
  };

  const classes = useStyles();

  const { contactPointTypeOptions, contactPointCategoryOptions } =
    optsModuleConfig;

  const newServiceNameButton = (
    <div
      className={clsx(
        classes.flexContainer,
        classes.addServiceNameBtn,
        classes.noOptionsContainer
      )}
      key="new-service-name-btn"
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setNewServiceName((draft) => {
            draft.marketingProgramName =
              optMappingInfo.cdpMarketingProgram.description;
            draft.serviceName = optMappingInfo.cdpMarketingProgram.description;
          });
          setNewServiceNameModal(true);
        }}
        data-testid="new-service-name"
      >
        {t("opts_mapping.new_service_name")}
      </Button>
    </div>
  );

  useEffect(async () => {
    if (
      optMappingInfo.cdpMarketingProgram &&
      optMappingInfo.cdpMarketingProgram.marketingProgramNumber
    ) {
      await getServiceNamesFromApi(
        optMappingInfo.cdpMarketingProgram.marketingProgramNumber
      );
    }
  }, [optMappingInfo.cdpMarketingProgram]);

  let optMappingFields = getFields(optMappingInfo);
  optMappingFields = optMappingFields
    .map((field) => {
      if (field.label === t("common.labels.opt_id")) {
        return {
          ...field,
          type: "custom",
          label: field.label,
          element: Autocomplete,
          required: true,
          props: {
            id: "combo-box-demo",
            options: filteredOptIdOptions,
            loading: optIdsLoading,
            getOptionLabel: (option) => option.title || "",
            value: optMappingInfo.optId,
            onChange: async (event, value) => {
              setOptMappingInfo((draft) => {
                draft.optId = value;
              });
              setErrors({
                ...errors,
                optIdError: null,
              });
              if (value) {
                setServiceName({});
                setOptMappingInfo((draft) => {
                  draft.serviceName = "";
                });
              }
            },
            renderInput: (params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Please select an Opt Id"
                error={Boolean(errors.optIdError)}
                onChange={(e) => setOptIdSearch(e.target.value)}
                helperText={<InlineMessage message={errors.optIdError} />}
              />
            ),
          },
        };
      }

      if (field.label === t("common.labels.marketing_program")) {
        return {
          ...field,
          type: "custom",
          label: t("common.labels.marketing_program"),
          element: Autocomplete,
          required: true,
          props: {
            id: "combo-box-demo",
            options: cdpMarketingProgramOptions.map((option) => ({
              ...option,
              title: `${option.marketingProgramNumber} - ${option.description}`,
            })),
            loading: cdpMarketingProgramsLoading,
            getOptionLabel: (option) => option.title || "",
            onChange: async (event, value) => {
              setOptMappingInfo((draft) => {
                draft.cdpMarketingProgram = value;
              });
              setErrors({
                ...errors,
                cdpMarketingProgramError: null,
              });
              if (value) {
                setServiceName({});
                setOptMappingInfo((draft) => {
                  draft.serviceName = "";
                });
              }
            },
            value: optMappingInfo.cdpMarketingProgram,
            disabled: true,
            renderInput: (params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t(
                  "new_opt_id.placeholders.ciam_marketing_program"
                )}
                error={Boolean(errors.cdpMarketingProgramError)}
                helperText={
                  <InlineMessage message={errors.cdpMarketingProgramError} />
                }
                onChange={(event) => {
                  getcdpMarketingProgramOptions(event.target.value);
                }}
              />
            ),
          },
        };
      }
      if (field.label === t("common.labels.channel")) {
        return {
          ...field,
          required: true,
          props: {
            label:
              optMappingInfo.channel === ""
                ? t("opts_mapping.placeholders.channel")
                : "",
            onChange: (event) => {
              const contactPointCategoryOption =
                contactPointCategoryOptions.find(
                  (option) =>
                    option.contact_point_category_name ===
                    event.target.value.default_contact_point_category_name
                );
              setOptMappingInfo((draft) => {
                draft.channel = event.target.value;
                draft.contactPointCategoryCode = contactPointCategoryOption;
              });
              setErrors({
                ...errors,
                channelError: null,
                contactPointCategoryCodeError: null,
              });
            },
            select: true,
            SelectProps: {
              displayEmpty: true,
              renderValue: () => (
                <span>{optMappingInfo.channel.contact_point_type_name}</span>
              ),
            },
            variant: "outlined",
            inputProps: {
              "data-testid": "channel",
            },
            error: Boolean(errors.channelError),
            helperText: <InlineMessage message={errors.channelError} />,
            value: optMappingInfo.channel.contact_point_type_name,
            values: contactPointTypeOptions.map((channel) => ({
              label: channel.contact_point_type_name,
              value: channel,
            })),
            //   disabled: isFormDisabled,
          },
        };
      }
      if (field.label === t("opts_mapping.contact_point_category_code")) {
        return {
          ...field,
          required: true,
          props: {
            label:
              optMappingInfo.contactPointCategoryCode === ""
                ? getDynamicPlaceholder(field)
                : "",
            onChange: (event) => {
              setOptMappingInfo((draft) => {
                draft.contactPointCategoryCode = event.target.value;
              });
              setErrors({
                ...errors,
                contactPointCategoryCodeError: null,
              });
            },
            select: true,
            SelectProps: {
              displayEmpty: true,
              renderValue: () => (
                <span>
                  {
                    optMappingInfo.contactPointCategoryCode
                      .contact_point_category_name
                  }
                </span>
              ),
            },
            variant: "outlined",
            value:
              optMappingInfo.contactPointCategoryCode
                .contact_point_category_name,
            error: Boolean(errors.contactPointCategoryCodeError),
            helperText: (
              <InlineMessage message={errors.contactPointCategoryCodeError} />
            ),
            values: contactPointCategoryOptions
              .filter(
                (category) =>
                  category.contact_point_type_code ===
                  optMappingInfo.channel.contact_point_type_code
              )
              .map((category) => ({
                label: category.contact_point_category_name,
                value: category,
              })),
            inputProps: {
              "data-testid": "contact-point-category-code",
            },
            //   disabled: isFormDisabled || !optMappingInfo.channel,
          },
        };
      }
      if (field.label === t("common.labels.service_name")) {
        if (
          serviceName &&
          Object.keys(serviceName).length > 0 &&
          serviceName.isNewService &&
          optMappingInfo.cdpMarketingProgram
        ) {
          return {
            type: "custom",
            element: SelectedService,
            required: true,
            props: {
              // isDisabled: isFormDisabled,
              isNewService: serviceName.isNewService,
              serviceName: serviceName.serviceName,
              // isConfirmationStep: currentStep === 2,
              onClick: () => {
                setNewServiceName((draft) => {
                  draft.marketingProgramName = serviceName.marketingProgramName;
                  draft.serviceName = serviceName.serviceName;
                  draft.serviceNameDescription =
                    serviceName.serviceNameDescription;
                });
                setNewServiceNameModal(true);
              },
              onDelete: () => {
                setOpenDeleteModal(true);
              },
            },
          };
        }
        return {
          ...field,
          required: true,
          props: {
            label: !optMappingInfo.serviceName
              ? getDynamicPlaceholder(field)
              : "",
            onChange: (event) => {
              setOptMappingInfo((draft) => {
                draft.serviceName = event.target.value;
              });
              setErrors({
                ...errors,
                serviceNameError: null,
              });
            },
            value: optMappingInfo.serviceName,
            values: [
              ...serviceNameOptions.map((option) => ({
                label: option.serviceName,
                value: option.serviceName,
              })),
              newServiceNameButton,
            ],
            inputProps: {
              "data-testid": "service-name",
            },
            select: true,
            variant: "outlined",
            error: Boolean(errors.serviceNameError),
            helperText: <InlineMessage message={errors.serviceNameError} />,
            disabled:
              // isFormDisabled ||
              !optMappingInfo.cdpMarketingProgram ||
              cdpMarketingProgramsLoading ||
              serviceNamesLoading,
          },
        };
      }
      if (field.label === t("opts_mapping.primary_indicator")) {
        return {
          ...field,
          required: true,
          props: {
            label:
              optMappingInfo.primaryIndicator === ""
                ? t("opts_mapping.placeholders.primary_indicator")
                : "",
            onChange: (event) => {
              setOptMappingInfo((draft) => {
                draft.primaryIndicator = event.target.value;
              });
              setErrors({
                ...errors,
                primaryIndicatorError: null,
              });
            },
            select: true,
            variant: "outlined",
            value: optMappingInfo.primaryIndicator,
            values:
              optsModuleConfig.createOptConstants.primary_indicator_options.map(
                (option) => ({
                  label: option,
                  value: option,
                })
              ),
            error: Boolean(errors.primaryIndicatorError),
            helperText: (
              <InlineMessage message={errors.primaryIndicatorError} />
            ),
            inputProps: {
              "data-testid": "primary-indicator",
            },
            "data-testid": "kind-of-data-collected",
            //   disabled: isFormDisabled,
          },
        };
      }
      if (field.label === t("opts_mapping.subscription_opt_number")) {
        return {
          ...field,
          required: true,
          props: {
            placeholder: t("opts_mapping.placeholders.subscription_opt_number"),
            onChange: (event) => {
              setOptMappingInfo((draft) => {
                draft.subscriptionOptNumber =
                  Number(event.target.value) > 1000 ? 1000 : event.target.value;
              });
              setErrors({
                ...errors,
                subscriptionOptNumberError: null,
              });
            },
            value: optMappingInfo.subscriptionOptNumber,
            variant: "outlined",
            inputProps: {
              "data-testid": "subscription-opt-number",
            },
            error: Boolean(errors.subscriptionOptNumberError),
            helperText: (
              <InlineMessage message={errors.subscriptionOptNumberError} />
            ),
            //   disabled: isFormDisabled,
          },
        };
      }
      return {};
    })
    .filter((field) => !isEmpty(field));

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          handleClose();
          resetState();
        }}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h4">{title}</Typography>
          <div
            className={clsx(classes.marginLeftAuto, classes.closeBtn)}
            onClick={() => {
              setIsCancelClicked(true);
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        <DialogContent>
          <Form
            fields={optMappingFields}
            fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
          />
          {errors.mappingError && (
            <Box sx={{ mb: 1 }}>
              <Alert severity="error">{errors.mappingError}</Alert>
            </Box>
          )}
          {errors.duplicateRecordError && (
            <Box sx={{ mb: 1 }}>
              <Alert severity="error">{errors.duplicateRecordError}</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                const info = JSON.parse(JSON.stringify(optMappingInfo));
                if (serviceName.isNewService) {
                  info.serviceName = serviceName;
                }
                const errorValues = validateNewMapping(
                  info,
                  subscriptionOptNumbers,
                  editableOptMapping,
                  optMappings
                );
                if (Object.values(errorValues).some((x) => x)) {
                  setErrors(errorValues);
                  return;
                }
                handleSubmit(info, editableOptMapping);
                handleClose();
                resetState();
              }}
              classes={{
                root: globalStyles.btn,
              }}
              // disabled={checkDisabledStatus()}
              color="primary"
              variant="contained"
            >
              {t("common.submit")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {newServiceNameModal && (
        <NewServiceName
          isOpen={newServiceNameModal}
          newServiceName={newServiceName}
          setNewServiceName={setNewServiceName}
          setServiceName={setServiceName}
          marketingProgramNumber={
            optMappingInfo.cdpMarketingProgram.marketingProgramNumber
          }
          focused={serviceNameFocused}
          setFocused={setServiceNameFocused}
          marketingProgram={optMappingInfo.cdpMarketingProgram}
          setNewServiceNameModal={setNewServiceNameModal}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={t("opts_mapping.remove_service_name_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onCloseDeleteModal();
        }}
        type="error"
      />
    </>
  );
};

NewOptIdMapping.defaultProps = {
  editableOptMapping: null,
};

NewOptIdMapping.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cdpMarketingProgramOptions: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  optIds: PropTypes.array.isRequired,
  optIdsLoading: PropTypes.bool.isRequired,
  getcdpMarketingProgramOptions: PropTypes.func.isRequired,
  cdpMarketingProgramsLoading: PropTypes.bool.isRequired,
  setOptMappingInfo: PropTypes.func.isRequired,
  editableOptMapping: PropTypes.any,
  optMappingInfo: PropTypes.shape({
    optIdSearch: PropTypes.string,
    optId: PropTypes.string.isRequired,
    channel: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      contact_point_type_name: PropTypes.string,
      contact_point_type_code: PropTypes.string,
    }).isRequired,
    contactPointCategoryCode: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      contact_point_category_name: PropTypes.string,
    }).isRequired,
    serviceName: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }).isRequired,
    primaryIndicator: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }).isRequired,
    subscriptionOptNumber: PropTypes.string.isRequired,
    cdpMarketingProgram: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      marketingProgramNumber: PropTypes.number,
      countryCodes: PropTypes.string,
      brandName: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
  }).isRequired,
  serviceName: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isNewService: PropTypes.bool.isRequired,
    serviceName: PropTypes.string.isRequired,
    marketingProgramName: PropTypes.string.isRequired,
    serviceNameDescription: PropTypes.string.isRequired,
  }).isRequired,
  setServiceName: PropTypes.func.isRequired,
  optMappings: PropTypes.array.isRequired,
  subscriptionOptNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default NewOptIdMapping;
