import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  footer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
