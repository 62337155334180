import { useState, useEffect, useCallback, useRef } from "react";

import { Button, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Info";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Prompt, Link } from "react-router-dom";

import createOptMapping from "../../../api/create-opt-mapping";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import getMarketingProgramOpts from "../../../api/get-opt-marketing-programs";
import getOpts from "../../../api/get-opts";
import getRequestDetails from "../../../api/get-request-details";
import getServiceNames from "../../../api/get-service-names";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Form from "../../../components/Form";
import InputFlow from "../../../components/InputFlow";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import getStatusColorIcon from "../../../utilities/getStatusColorIcon";
import handleError from "../../../utilities/handleError";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";

import NewServiceName from "../components/NewServiceName";
import SelectedService from "../components/SelectedService";

import OptsRequestOutputModal from "./components/OptsRequestOutputModal";
import getFields from "./getFields";
import convertBackendPayload from "./helpers/convertBackendPayload";
import createBackendPayload from "./helpers/createBackendPayload";
import useStyles from "./styles";

const CreateOptMappingsContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const location = useLocation();
  const updateMarketingProgram = useRef(true);

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createOpts
  );

  const history = useHistory();

  const { request, setRequest } = useRequest();
  const totalSteps = 2;

  const [currentStep, setCurrentStep] = useState(1);
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const isInfoStep = currentStep > totalSteps;
  const isFormDisabled = currentStep === 2;

  const {
    createOptConstants,
    requestStatus,
    optRequestsConstants,
    contactPointTypeOptions,
    contactPointCategoryOptions,
  } = optsModuleConfig;
  const { toolTipTextPending, toolTipTextApproved, toolTipTextProcessing } =
    optRequestsConstants;

  const { addNotification } = useNotifier();

  // Marketing Program
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(true);

  const [serviceNameOptions, setServiceNameOptions] = useState([]);

  const [optsLoading, setOptsLoading] = useState(true);

  const [serviceNameFocused, setServiceNameFocused] = useImmer({
    marketingProgramName: false,
    serviceName: false,
    serviceNameDescription: false,
  });

  const [newServiceNameModal, setNewServiceNameModal] = useState(false);

  const [marketingProgram, setMarketingProgram] = useState(null);
  const [optIdValue, setOptIdValue] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [optsMappingInfo, setOptsMappingInfo] = useImmer({
    optId: "",
    optText: "",
    marketingProgram: "",
    channel: "",
    contactPointCategoryCode: "",
    serviceName: "",
    primaryIndicator: "",
    subscriptionOptNumber: "",
    marketingProgramNumber: "",
    marketingProgramDescription: "",
  });

  const [newServiceName, setNewServiceName] = useImmer({
    marketingProgramName: "",
    serviceName: "",
    serviceNameDescription: "",
  });

  const [serviceName, setServiceName] = useState({});

  const [opts, setOpts] = useState([]);

  const isRequestRevisionFlow = Object.keys(request).length !== 0;
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const { t } = useTranslation();

  const [mappingResponse, setMappingResponse] = useState([]);
  const [optsOutputTableLoading, setOptsOutputTableLoading] = useState(false);
  const [serviceNamesLoading, setServiceNamesLoading] = useState(false);
  const [showOptOutput, setShowOptOutput] = useState(false);
  const [optsOutput, setOptOutput] = useState([]);

  const checkDisabledStatus = (step) => {
    if (step === 1) {
      const mappingInfo = JSON.parse(JSON.stringify(optsMappingInfo));
      delete mappingInfo.serviceName;
      const mappingInfoLen = Object.values(mappingInfo).filter((val) =>
        Boolean(val)
      );
      if (
        mappingInfoLen &&
        mappingInfoLen.length === Object.keys(mappingInfo).length &&
        (optsMappingInfo.serviceName !== "" ||
          Object.keys(serviceName).length > 0)
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const onCloseDeleteModal = () => {
    setNewServiceName((draft) => {
      draft.marketingProgramName = optsMappingInfo.marketingProgram;
      draft.serviceName = optsMappingInfo.marketingProgram;
      draft.serviceNameDescription = "";
    });
    setServiceName({});
    setServiceNameFocused((draft) => {
      draft.marketingProgramName = false;
      draft.serviceName = false;
      draft.serviceNameDescription = false;
    });
    setOpenDeleteModal(false);
  };

  const newServiceNameButton = (
    <div
      className={clsx(
        classes.flexContainer,
        classes.addServiceNameBtn,
        classes.noOptionsContainer
      )}
      key="new-service-name-btn"
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setNewServiceNameModal(true);
        }}
        data-testid="new-service-name"
      >
        {t("opts_mapping.new_service_name")}
      </Button>
    </div>
  );

  const getServiceNamesFromApi = async (marketingProgramNumber) => {
    try {
      setServiceNamesLoading(true);
      const rsp1 = await getServiceNames(marketingProgramNumber, serviceName);
      setServiceNameOptions(rsp1);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setServiceNameOptions([]);
        },
        addNotification,
      });
    } finally {
      setServiceNamesLoading(false);
    }
  };

  const getMarketingProgramOptsFromApi = async (optId) => {
    try {
      const rsp1 = await getMarketingProgramOpts(optId);
      let maxNumber = -1;
      rsp1.items
        .map((item) => item.subscriptionOptNumber)
        .map((no) => {
          if (no > maxNumber) {
            maxNumber = no;
          }
          return [];
        });
      setOptsMappingInfo((draft) => {
        draft.subscriptionOptNumber = `${maxNumber + 1}`;
      });
    } catch (error) {
      handleError({
        error,
        addNotification,
      });
    }
  };

  const getMarketingProgramsFromApi = useCallback(
    async (searchText, legalEntity) => {
      let filter = { itemsPerPage: 3, page: 1, legalEntity };
      if (searchText && searchText.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      try {
        const rsp1 = await getMarketingProgramsApi(filter);
        setMarketingProgramOptions(rsp1.items);
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setMarketingProgramOptions([]);
          },
          addNotification,
        });
      } finally {
        setMarketingProgramsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (isRequestRevisionFlow && !updateMarketingProgram.current) {
      return;
    }
    setServiceName({});
    setNewServiceName((draft) => {
      draft.marketingProgramName = optsMappingInfo.marketingProgram;
      draft.serviceName = optsMappingInfo.marketingProgram;
      draft.serviceNameDescription = "";
    });
    setOptsMappingInfo((draft) => {
      draft.serviceName = "";
    });
  }, [marketingProgram]);

  const newOptId = (
    <div className="newOpt">
      <Typography variant="h6">
        {t("create_trait.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          // setOpenModal(true);
        }}
      >
        {t("opts_container.new_opt_id")}
      </Button>
    </div>
  );

  const getOptsFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
      };
    }
    const filterFieldMap = {
      searchText: "searchText",
    };
    try {
      const rsp1 = await getOpts(
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap,
          1,
          3
        )
      );
      setOpts([...rsp1.items, newOptId]);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setOpts([]);
        },
        addNotification,
      });
    } finally {
      setOptsLoading(false);
    }
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );

  // Debounce & Memoize
  const debouncedOptsFromApi = debounce(
    getOptsFromApi,
    applicationConfig.waitTime
  );

  const memoizedMarketingProgramsFromApi = useCallback((val, legalEntity) => {
    debouncedMarketingProgramsFromApi(val, legalEntity);
  }, []);

  const memoizedOptsFromApi = useCallback((searchText, program) => {
    debouncedOptsFromApi(searchText, program);
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount();
      await getOptsFromApi("");
      decreaseRequestsCount();
    }
  }, [user]);

  const setOptsMappingInfoFromApi = async (data) => {
    const uiData = convertBackendPayload(data.requestDetails);
    const { service } = data.requestDetails;
    const { isNew: isServiceNameNew, description: serviceNameDescription } =
      service;
    setOptIdValue({ title: uiData.optId });
    let [marketingProgramNumber, description] =
      uiData.marketingProgram.split("-");
    marketingProgramNumber = marketingProgramNumber.trim();
    description = description.trim();
    updateMarketingProgram.current = false;
    setMarketingProgram({
      title: uiData.marketingProgram,
      marketingProgramNumber,
      description,
    });
    setOptsMappingInfo({
      ...uiData,
      description,
      marketingProgram: description,
    });
    if (isServiceNameNew) {
      setNewServiceName({
        marketingProgramName: description,
        serviceName: uiData.serviceName,
        serviceNameDescription: uiData.serviceNameDescription,
      });
    } else {
      setNewServiceName({
        marketingProgramName: description,
        serviceName: description,
        serviceNameDescription: "",
      });
    }
    setServiceName({
      isNewService: isServiceNameNew,
      marketingProgramName: description,
      serviceName: uiData.serviceName,
      serviceNameDescription,
    });
    await getMarketingProgramsFromApi("");
    await getServiceNamesFromApi(marketingProgramNumber);
    updateMarketingProgram.current = true;
  };

  useEffect(() => {
    if (
      location.pathname === applicationConfig.pathnames.opts_mapping_revision
    ) {
      if (Object.keys(request).length === 0) {
        history.goBack();
      }
    }
  }, []);

  useEffect(async () => {
    if (isRequestRevisionFlow) {
      setOptsMappingInfoFromApi(request);
    }
  }, []);

  const handleOptMappingOutputModal = async (reqId) => {
    setOptsOutputTableLoading(true);
    setOptOutput({
      output: {
        warnings: [],
        items: [],
      },
    });
    try {
      const response = await getRequestDetails(reqId);
      setOptOutput(response);
      setOptsOutputTableLoading(false);
    } catch (error) {
      setShowOptOutput(false);
      setOptsOutputTableLoading(false);
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const optsRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "optId",
      headerName: t("common.labels.opt_id"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        const { value } = params;

        const { statusColor, statusIcon } = getStatusColorIcon(
          value,
          requestStatus
        );

        const toolTipHeadings = {
          [requestStatus.PENDING.toLowerCase()]: toolTipTextPending,
          [requestStatus.APPROVED.toLowerCase()]: toolTipTextApproved,
          [requestStatus.PROCESSING.toLowerCase()]: toolTipTextProcessing,
        };

        return (
          <div className={classes.statusWrapper}>
            <div className={classes.statusAlert} style={statusColor} />
            <span className={classes.statusText}>{value}</span>
            <StyledTooltip
              placement="top"
              title={<span>{toolTipHeadings[value.toLowerCase()]}</span>}
            >
              <div
                role="button"
                aria-hidden="true"
                data-testid="clickable-cell"
                onClick={() => {
                  if (value === requestStatus.APPROVED) {
                    handleOptMappingOutputModal(mappingResponse[0].requestId);
                    setShowOptOutput(true);
                  }
                }}
              >
                {statusIcon}
              </div>
            </StyledTooltip>
          </div>
        );
      },
      sortable: false,
    },
  ];

  const getDynamicPlaceholder = (field) => {
    if (field.label === t("common.labels.opt_id")) {
      return t("opts_mapping.placeholders.opt_id");
    }
    if (field.label === t("common.labels.marketing_program")) {
      if (optsMappingInfo.optId === "") {
        return t("opts_mapping.placeholders.opt_id");
      }

      return t("opts_mapping.placeholders.marketing_program");
    }
    if (field.label === t("common.labels.service_name")) {
      if (optsMappingInfo.optId === "") {
        return t("opts_mapping.placeholders.opt_id");
      }
      if (!marketingProgram) {
        return t("opts_mapping.placeholders.marketing_program");
      }

      return t("opts_mapping.placeholders.service_name");
    }
    return "";
  };

  let newOptFields = getFields(optsMappingInfo);
  newOptFields = newOptFields.map((field) => {
    if (field.label === t("common.labels.opt_id")) {
      return {
        type: "custom",
        element: Autocomplete,
        label: t("common.labels.opt_id"),
        props: {
          id: "combo-box-demo",
          placeholder: getDynamicPlaceholder(field),
          options: opts.map((option) => {
            if (option.type !== "div") {
              return {
                ...option,
                title: option.ciamOptId,
              };
            }
            return option;
          }),
          loading: optsLoading,
          getOptionLabel: (option) => option.title || "",
          onChange: async (event, value) => {
            let marketingProgramNumber = "";
            let marketingProgramDescription = "";
            if (value && value.ciamMarketingProgram) {
              [marketingProgramNumber, marketingProgramDescription] =
                value.ciamMarketingProgram.split(" - ");
            }
            setOptsMappingInfo((draft) => {
              draft.optId = value ? value.title : "";
              draft.optText = value ? value.optTextEnglish : "";
              draft.ciamLegalEntityId = value ? value.ciamLegalEntityId : "";
              draft.marketingProgramNumber = marketingProgramNumber;
              draft.marketingProgramDescription = marketingProgramDescription;
            });
            setOptIdValue(value);
            setMarketingProgram("");
            if (value && value.title) {
              await getMarketingProgramOptsFromApi(value.title);
              memoizedMarketingProgramsFromApi("", value.ciamLegalEntityId);
            }
          },
          disabled: isFormDisabled,
          value: optIdValue,
          renderInput: (params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t("opts_mapping.placeholders.opt_id")}
              onChange={(event) => {
                setOptsLoading(true);
                setOpts([]);
                memoizedOptsFromApi(event.target.value);
              }}
            />
          ),
        },
      };
    }
    if (field.label === t("common.labels.opt_text")) {
      return {
        type: "custom",
        element: TextField,
        label: t("common.labels.opt_text"),
        tooltip: {
          title: isFormDisabled
            ? optsMappingInfo.optText
            : t("opts_mapping.opt_text_uneditable_text"),
          placement: "top-start",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        props: {
          variant: "standard",
          placeholder: t("common.labels.opt_text"),
          value: optsMappingInfo.optText,
          multiline: true,
          rowsMax: isFormDisabled ? 2 : Infinity,
          inputProps: {
            readOnly: true,
          },
        },
      };
    }
    if (field.label === t("common.labels.marketing_program")) {
      return {
        ...field,
        type: "custom",
        label: t("common.labels.marketing_program"),
        element: Autocomplete,
        props: {
          id: "combo-box-demo",
          options: marketingProgramOptions.map((option) => ({
            ...option,
            title: `${option.marketingProgramNumber} - ${option.description}`,
          })),
          loading: marketingProgramsLoading,
          getOptionLabel: (option) => option.title || "",
          onChange: async (event, value) => {
            setOptsMappingInfo((draft) => {
              draft.marketingProgram = value ? value.description : "";
            });
            if (value && value.description) {
              await getServiceNamesFromApi(value.marketingProgramNumber);
              setNewServiceName((draft) => {
                draft.marketingProgramName = value.description;
                draft.serviceName = value.description;
              });
            }
            setMarketingProgram(value);
          },
          value: marketingProgram,
          disabled: !optsMappingInfo.optId || isFormDisabled,
          renderInput: (params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={getDynamicPlaceholder(field)}
              onChange={(event) => {
                setMarketingProgramsLoading(true);
                setMarketingProgramOptions([]);
                memoizedMarketingProgramsFromApi(
                  event.target.value,
                  optsMappingInfo.ciamLegalEntityId
                );
              }}
            />
          ),
        },
      };
    }
    if (field.label === t("common.labels.channel")) {
      return {
        ...field,
        props: {
          label:
            optsMappingInfo.channel === ""
              ? t("opts_mapping.placeholders.channel")
              : "",
          onChange: (event) => {
            const contactPointCategoryOption = contactPointCategoryOptions.find(
              (option) =>
                option.contact_point_category_name ===
                event.target.value.default_contact_point_category_name
            );
            setOptsMappingInfo((draft) => {
              draft.channel = event.target.value;
              draft.contactPointCategoryCode = contactPointCategoryOption;
            });
          },
          select: true,
          SelectProps: {
            displayEmpty: true,
            renderValue: () => (
              <span>{optsMappingInfo.channel.contact_point_type_name}</span>
            ),
          },
          variant: "outlined",
          inputProps: {
            "data-testid": "channel",
          },
          value: optsMappingInfo.channel.contact_point_type_name,
          values: contactPointTypeOptions.map((channel) => ({
            label: channel.contact_point_type_name,
            value: channel,
          })),
          disabled: isFormDisabled,
        },
      };
    }
    if (field.label === t("opts_mapping.contact_point_category_code")) {
      return {
        ...field,
        props: {
          label:
            optsMappingInfo.contactPointCategoryCode === ""
              ? t("opts_mapping.placeholders.contact_point_category_code")
              : "",
          onChange: (event) =>
            setOptsMappingInfo((draft) => {
              draft.contactPointCategoryCode = event.target.value;
            }),
          select: true,
          SelectProps: {
            displayEmpty: true,
            renderValue: () => (
              <span>
                {
                  optsMappingInfo.contactPointCategoryCode
                    .contact_point_category_name
                }
              </span>
            ),
          },
          variant: "outlined",
          value:
            optsMappingInfo.contactPointCategoryCode
              .contact_point_category_name,
          values: contactPointCategoryOptions
            .filter(
              (category) =>
                category.contact_point_type_code ===
                optsMappingInfo.channel.contact_point_type_code
            )
            .map((category) => ({
              label: category.contact_point_category_name,
              value: category,
            })),
          inputProps: {
            "data-testid": "contact-point-category-code",
          },
          disabled: isFormDisabled || !optsMappingInfo.channel,
        },
      };
    }
    if (field.label === t("common.labels.service_name")) {
      if (
        serviceName &&
        Object.keys(serviceName).length > 0 &&
        serviceName.isNewService &&
        marketingProgram
      ) {
        return {
          type: "custom",
          element: SelectedService,
          props: {
            isDisabled: isFormDisabled,
            isNewService: serviceName.isNewService,
            serviceName: serviceName.serviceName,
            isConfirmationStep: currentStep === 2,
            openDetailsModal: () => {
              setNewServiceName((draft) => {
                draft.marketingProgramName = serviceName.marketingProgramName;
                draft.serviceName = serviceName.serviceName;
                draft.serviceNameDescription =
                  serviceName.serviceNameDescription;
              });
              setNewServiceNameModal(true);
            },
            onClick: () => {
              setNewServiceName((draft) => {
                draft.marketingProgramName = serviceName.marketingProgramName;
                draft.serviceName = serviceName.serviceName;
                draft.serviceNameDescription =
                  serviceName.serviceNameDescription;
              });
              setNewServiceNameModal(true);
            },
            onDelete: () => {
              setOpenDeleteModal(true);
            },
          },
        };
      }
      return {
        ...field,
        props: {
          label: !optsMappingInfo.serviceName
            ? getDynamicPlaceholder(field)
            : "",
          onChange: (event) => {
            setOptsMappingInfo((draft) => {
              draft.serviceName = event.target.value;
            });
          },
          value: optsMappingInfo.serviceName,
          values: [
            ...serviceNameOptions.map((option) => ({
              label: option.serviceName,
              value: option.serviceName,
            })),
            newServiceNameButton,
          ],
          inputProps: {
            "data-testid": "service-name",
          },
          select: true,
          variant: "outlined",
          disabled:
            isFormDisabled ||
            !marketingProgram ||
            marketingProgramsLoading ||
            serviceNamesLoading,
        },
      };
    }
    if (field.label === t("opts_mapping.primary_indicator")) {
      return {
        ...field,
        props: {
          label:
            optsMappingInfo.primaryIndicator === ""
              ? t("opts_mapping.placeholders.primary_indicator")
              : "",
          onChange: (event) =>
            setOptsMappingInfo((draft) => {
              draft.primaryIndicator = event.target.value;
            }),
          select: true,
          variant: "outlined",
          value: optsMappingInfo.primaryIndicator,
          values:
            optsModuleConfig.createOptConstants.primary_indicator_options.map(
              (option) => ({
                label: option,
                value: option,
              })
            ),
          inputProps: {
            "data-testid": "primary-indicator",
          },
          "data-testid": "kind-of-data-collected",
          disabled: isFormDisabled,
        },
      };
    }
    if (field.label === t("opts_mapping.subscription_opt_number")) {
      return {
        ...field,
        props: {
          placeholder: t("opts_mapping.placeholders.subscription_opt_number"),
          onChange: (event) =>
            setOptsMappingInfo((draft) => {
              draft.subscriptionOptNumber = event.target.value;
            }),
          value: optsMappingInfo.subscriptionOptNumber,
          variant: "outlined",
          inputProps: {
            "data-testid": "subscription-opt-number",
          },
          disabled: isFormDisabled,
        },
      };
    }
    return {};
  });

  const renderStep = (step) => {
    if (step === 1 || step === 2) {
      return (
        <div className={classes.container} data-testid="opts-mapping-container">
          <Form
            fields={newOptFields}
            fieldClassName={classes.inputContainer}
            containerClassName={clsx(
              classes.grid,
              step === 2 && classes.columnGrid
            )}
          />
        </div>
      );
    }
    return (
      <div
        style={{
          height: 70 * mappingResponse.length + 60,
          maxHeight: "calc(100vh - 300px)",
        }}
      >
        <Table
          columns={optsRequestTableColumns}
          rows={mappingResponse}
          tableStyle={classes.myRequestsTable}
        />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/opts" goToText={t("access_denied.go_to_opts")} />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        currentStep={currentStep}
        className={globalStyles.paper1150}
        header={
          <Typography variant="h4" gutterBottom>
            {isRequestRevisionFlow
              ? createOptConstants.optMappingRevisionHeadings[currentStep]
              : createOptConstants.optMappingCreationHeadings[currentStep]}
          </Typography>
        }
        headerText={
          isRequestRevisionFlow
            ? createOptConstants.optMappingRevisionMessages[currentStep]
            : createOptConstants.optMappingCreationMessages[currentStep]
        }
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <div
                    className={clsx(classes.flexContainer, classes.step2Footer)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        setCurrentStep(currentStep - 1);
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                variant="outlined"
                disabled={checkDisabledStatus(currentStep)}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      const payload = createBackendPayload(
                        optsMappingInfo,
                        marketingProgram,
                        serviceName
                      );
                      increaseRequestsCount();
                      if (!isRequestRevisionFlow) {
                        const data = await createOptMapping(payload);
                        const { items } = data;
                        setMappingResponse(
                          items.map((item, index) => ({
                            id: index,
                            ...item,
                            requestId: item.requestId,
                            status:
                              item.status[0].toUpperCase() +
                              item.status.slice(1),
                            optId: item.opt.optId,
                          }))
                        );
                      } else if (currentStep === totalSteps) {
                        const data = await updateRequest(
                          request.requestId,
                          payload,
                          applicationConfig.modules.opts
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {createOptConstants.optFooterText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/opts"
                >
                  {t("common.labels.back_to_opts")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${mappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.sourceContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {showOptOutput && (
        <OptsRequestOutputModal
          isOpen={showOptOutput}
          isLoading={optsOutputTableLoading}
          requestId={optsOutput.requestId}
          marketingProgramDescription={
            optsOutput &&
            optsOutput.requestDetails &&
            optsOutput.requestDetails.marketingProgram
              ? optsOutput.requestDetails.marketingProgram.description
              : ""
          }
          isNew={
            optsOutput &&
            optsOutput.requestDetails &&
            optsOutput.requestDetails.service
              ? optsOutput.requestDetails.service.isNew
              : false
          }
          data={optsOutput.output}
          setShowOptOutput={() => setShowOptOutput(false)}
        />
      )}
      {newServiceNameModal && (
        <NewServiceName
          isOpen={newServiceNameModal}
          newServiceName={newServiceName}
          setNewServiceName={setNewServiceName}
          setServiceName={setServiceName}
          marketingProgramNumber={marketingProgram.marketingProgramNumber}
          focused={serviceNameFocused}
          setFocused={setServiceNameFocused}
          marketingProgram={optsMappingInfo.marketingProgram}
          isDisabled={isFormDisabled}
          setNewServiceNameModal={setNewServiceNameModal}
          currentStep={currentStep}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={t("opts_mapping.remove_service_name_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onCloseDeleteModal();
        }}
        type="error"
      />
      <Prompt
        message={() => {
          if (isRequestRevisionFlow) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={
          isInfoStep || isRequestRevisionFlow
            ? false
            : !checkDisabledStatus(currentStep)
        }
      />
    </>
  );
};

export default CreateOptMappingsContainer;
