import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    title: {
      "& h2.MuiTypography-root": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    footer: {
      paddingRight: "32px",
      marginBottom: "20px",
    },
    closeBtn: {
      width: "100px",
    },
    subTitle: {
      fontSize: "14px",
      margin: "unset",
    },
  };
});

export default useStyles;
