import { useState } from "react";

import {
  Select,
  MenuItem,
  FormControl,
  Button,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";

import CustomModal from "../../../../components/CustomModal";
import InlineMessage from "../../../../components/InlineMessage";

import traitsModuleConfig from "../../../../config/traitsModuleConfig";

import i18n from "../../../../i18n/init";

import validateSurvivorshipRule from "../../CreateTraitsContainer/helpers/validateSurvivorshipRules";

import useStyles from "./styles";

import "./index.css";

const TraitMpMappingModal = ({
  isOpen,
  onClose,
  traits,
  marketingPrograms,
  ecosystems,
  view,
  mappingsStore,
  disabled,
  onSubmit,
}) => {
  const handleModalClose = () => {
    onClose();
  };

  const classes = useStyles();

  const [store, setStore] = useState(() => mappingsStore);

  const getMenuItem = (row, item) => {
    const {
      value,
      error,
      disabled: disabledRule,
    } = validateSurvivorshipRule(row.trait, item);
    return (
      <MenuItem value={value} key={item} disabled={disabledRule}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {item}
          {error && (
            <div style={{ marginLeft: "20px" }}>
              <InlineMessage state="error" message={error} />
            </div>
          )}
        </div>
      </MenuItem>
    );
  };

  const columns = [
    {
      field: "traitName",
      headerName: "Trait Name",
      width: 150,
      sortable: false,
      renderCell: (traitName) => {
        if (traitName.length > 20) {
          return (
            <Tooltip title={traitName}>
              <span>{traitName.slice(0, 20)}...</span>
            </Tooltip>
          );
        }
        return traitName;
      },
    },
    {
      field: "marketingProgram",
      headerName: "Marketing Program",
      width: 200,
      sortable: false,
    },
  ];

  if (view === "traitResponseTypes") {
    columns.push({
      field: "traitResponseType",
      headerName: "Trait Response Type",
      width: 200,
      type: "singleSelect",
      sortable: false,
      renderCell: (traitResponseType, row) => {
        return (
          <div>
            <FormControl>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                disabled={disabled}
                value={
                  !traitResponseType
                    ? i18n.t("constants.trait_response_type_options.single")
                    : i18n.t("constants.trait_response_type_options.multiple")
                }
                onClick={(event) => {
                  if (event.target.value) {
                    let responseType = true;
                    if (
                      event.target.value ===
                      i18n.t("constants.trait_response_type_options.single")
                    ) {
                      responseType = false;
                    }
                    setStore({
                      ...store,
                      [`${row.traitId || row.traitName}#${
                        row.marketingProgramNumber
                      }`]: responseType,
                    });
                  }
                }}
                label="Trait Response Type"
              >
                {traitsModuleConfig.createTraitConstants.traitResponseTypeOptions.map(
                  (item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </div>
        );
      },
    });
  } else if (view === "survivorshipRules") {
    columns.push(
      ...[
        {
          field: "ecosystemName",
          headerName: "Ecosystem",
          width: 200,
          sortable: false,
        },
        {
          field: "survivorshipRule",
          headerName: "Survivorship Rule",
          width: 200,
          type: "singleSelect",
          sortable: false,
          renderCell: (survivorshipRule, row) => {
            return (
              <div>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={survivorshipRule}
                    disabled={disabled}
                    onClick={(event) => {
                      if (event.target.value) {
                        setStore({
                          ...store,
                          [`${row.traitId || row.traitName}#${
                            row.marketingProgramNumber
                          }#${row.ecosystemName}`]: event.target.value,
                        });
                      }
                    }}
                    label="Trait Response Type"
                  >
                    {traitsModuleConfig.createTraitConstants.survivorshipRules.map(
                      (item) => {
                        return getMenuItem(row, item);
                      }
                    )}
                  </Select>
                </FormControl>
              </div>
            );
          },
        },
      ]
    );
  }

  const rows = [];

  // Populate mappingData based on dataStore, traits, and mpData
  // You'll need to implement this logic

  traits.forEach((trait) => {
    return marketingPrograms.forEach((marketingProgram) => {
      if (view === "traitResponseTypes") {
        if (
          `${trait.traitId || trait.traitName}#${
            marketingProgram.marketingProgramNumber
          }` in store
        ) {
          rows.push({
            traitName: trait.traitName,
            traitId: trait.traitId,
            marketingProgramNumber: marketingProgram.marketingProgramNumber,
            marketingProgram: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
            traitResponseType:
              store[
                `${trait.traitId || trait.traitName}#${
                  marketingProgram.marketingProgramNumber
                }`
              ],
            id: `${trait.traitId || trait.traitName}#${
              marketingProgram.marketingProgramNumber
            }`,
          });
        } else {
          rows.push({
            traitName: trait.traitName,
            traitId: trait.traitId,
            marketingProgramNumber: marketingProgram.marketingProgramNumber,
            marketingProgram: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
            traitResponseType: false,
            id: `${trait.traitId || trait.traitName}#${
              marketingProgram.marketingProgramNumber
            }`,
          });
        }
      } else if (view === "survivorshipRules") {
        ecosystems.forEach((ecosystem) => {
          const { allowSurvivorshipUpdate = true } = trait;
          const isEcosystemPresent =
            `${trait.traitId || trait.traitName}#${
              marketingProgram.marketingProgramNumber
            }#${ecosystem.ecosystemName}` in store;
          if (allowSurvivorshipUpdate && isEcosystemPresent) {
            rows.push({
              traitName: trait.traitName,
              traitId: trait.traitId,
              trait,
              marketingProgramNumber: marketingProgram.marketingProgramNumber,
              ecosystem: ecosystem.ecosystemId,
              ecosystemName: ecosystem.ecosystemName,
              marketingProgram: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
              survivorshipRule:
                store[
                  `${trait.traitId || trait.traitName}#${
                    marketingProgram.marketingProgramNumber
                  }#${ecosystem.ecosystemName}`
                ],
              id: `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }#${ecosystem.ecosystemName}`,
            });
          } else {
            rows.push({
              traitName: trait.traitName,
              traitId: trait.traitId,
              trait,
              marketingProgramNumber: marketingProgram.marketingProgramNumber,
              ecosystem: ecosystem.ecosystemId,
              ecosystemName: ecosystem.ecosystemName,
              marketingProgram: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
              survivorshipRule:
                traitsModuleConfig.createTraitConstants.survivorshipRules[0],
              id: `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }#${ecosystem.ecosystemName}`,
            });
          }
        });
      }
    });
  });

  return (
    <CustomModal
      open={isOpen}
      onClose={handleModalClose}
      title={
        view === "traitResponseTypes"
          ? "Trait Response Type Mappings"
          : "Survivorship Rule Mappings"
      }
      showCloseIcon
      closeAfterTransition
    >
      <div className="mappings-container">
        <table className="table-container">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.field}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                {columns.map((column) => (
                  <td key={column.field}>
                    {column.renderCell
                      ? column.renderCell(row[column.field], row)
                      : row[column.field]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!disabled && (
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit(store)}
          >
            Submit
          </Button>
        </div>
      )}
    </CustomModal>
  );
};

TraitMpMappingModal.defaultProps = {
  mappingsStore: {},
};

TraitMpMappingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mappingsStore: PropTypes.shape({}),
  onSubmit: PropTypes.shape({}).isRequired,
  view: PropTypes.string.isRequired,
  traits: PropTypes.arrayOf({
    traitId: PropTypes.number,
    traitName: PropTypes.string,
    allowSurvivorshipUpdate: PropTypes.bool,
  }).isRequired,
  marketingPrograms: PropTypes.arrayOf({
    marketingProgramNumber: PropTypes.number,
    description: PropTypes.string,
  }).isRequired,
  ecosystems: PropTypes.arrayOf({
    ecosystemId: PropTypes.number,
    ecosystemName: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TraitMpMappingModal;
