import { useState, useEffect } from "react";

import { Typography } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";

import useStyles from "./styles";

const EditableText = ({
  value,
  onChange,
  onCancel,
  onOk,
  isDisabled,
  error,
  helperText,
  onEditMode,
  placeholder,
  disabled,
  label,
  disableViewMode,
  ...props
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(() => value);
  const [previousValue, setPreviousValue] = useState(() => value);

  useEffect(() => {
    setInputValue(value);
    setPreviousValue(value);
  }, [value]);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.flexContainer}>
        <div className={clsx(classes.container, disabled && classes.disabled)}>
          <Typography variant="h6">{label}</Typography>
          <div
            className={clsx(
              classes.flexContainer2,
              disableViewMode && !isEditMode && classes.disableViewMode
            )}
          >
            <TextField
              value={inputValue}
              onChange={(event) => {
                if (props.editMode && !isEditMode) {
                  return "";
                }
                setInputValue(event.target.value);
                onChange(event.target.value);
                return "";
              }}
              placeholder={placeholder}
              disabled={isDisabled || (props.editMode && !isEditMode)}
              inputProps={{
                "data-testid": "editable-text",
              }}
              variant="outlined"
              {...props}
            />
          </div>
        </div>

        {props.editMode && !isEditMode && onChange && !disabled && (
          <div
            onClick={() => {
              setIsEditMode(true);
              onEditMode();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            className={clsx(classes.btnContainer, classes.editIcon)}
            data-testid="btn-1"
          >
            <EditOutlinedIcon />
          </div>
        )}
        {props.editMode && isEditMode && (
          <div className={clsx(classes.flexContainer1, classes.btnContainer)}>
            <div
              onClick={() => {
                if (!error) {
                  setIsEditMode(false);
                  setPreviousValue(inputValue);
                  onOk(inputValue);
                }
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              data-testid="btn-2"
              className={classes.doneIcon}
            >
              <DoneIcon />
            </div>
            <div
              onClick={() => {
                setIsEditMode(false);
                setInputValue(previousValue);
                onCancel(previousValue);
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              data-testid="btn-3"
              className={classes.clearIcon}
            >
              <ClearIcon />
            </div>
          </div>
        )}
      </div>
      {helperText && (
        <FormHelperText id="my-helper-text" error={Boolean(error)}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

EditableText.defaultProps = {
  value: "",
  onChange: null,
  isDisabled: false,
  error: false,
  helperText: <></>,
  onEditMode: () => {},
  onOk: () => {},
  placeholder: "",
  disabled: false,
  label: "",
  disableViewMode: false,
  editMode: false,
};

EditableText.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onOk: PropTypes.func,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  onEditMode: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disableViewMode: PropTypes.bool,
  editMode: PropTypes.bool,
};

export default EditableText;
