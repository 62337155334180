const isEmpty = (val) => {
  if (val === undefined) return true;

  if (
    typeof val === "function" ||
    typeof val === "number" ||
    typeof val === "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val === "object") {
    // empty object
    if (Object.keys(val).length === 0) {
      return true;
    }
  }
  return false;
};

module.exports = isEmpty;
