import api from "../utilities/api";
import { getValue } from "../utilities/cache";
import isEmpty from "../utilities/isEmpty";

const getEvents = async (eventName, schemaVersion, queryParams) => {
  let url = "/events";
  const refreshCache = getValue("events");
  if (eventName && schemaVersion) {
    url += `?eventName=${eventName
      .split(" ")
      .join("_")}&schemaVersion=${schemaVersion}&refreshCache=${refreshCache}`;
  } else if (!isEmpty(queryParams)) {
    url += `${queryParams}&refreshCache=${refreshCache}&schemaVersion=v2`;
  } else {
    url += `?refreshCache=${refreshCache}`;
  }
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getEvents;
