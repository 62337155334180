import { Container, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import useStyles from "./styles";

const Layout = ({ header, headerClassName, children, childrenClassName }) => {
  const classes = useStyles();

  return (
    <div className={classes.themeWrapper}>
      <Container maxWidth="xl" className={classes.container}>
        {Boolean(header) && (
          <div className={clsx(classes.header, headerClassName)}>
            {typeof header === "string" ? (
              <Typography variant="h4" gutterBottom>
                {header}
              </Typography>
            ) : (
              header
            )}
          </div>
        )}
        <div className={`${childrenClassName}`}>{children}</div>
      </Container>
    </div>
  );
};

Layout.defaultProps = {
  header: <></>,
  headerClassName: null,
  childrenClassName: "",
  children: <></>,
};

Layout.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
