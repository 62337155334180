import { Paper, Typography, Button } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import i18n from "../../i18n/init";

import useStyles from "./styles";

const AccessDenied = ({ showButton, goToLink, goToText }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.paperContainer}>
        <Paper data-testid="access-denied-container" elevation={1}>
          <div className={classes.flexContainer}>
            <div className={classes.infoContainer}>
              <Typography variant="h1">{t("access_denied.status")}</Typography>
              <div>
                <Typography variant="h3">{t("access_denied.title")}</Typography>
                <Typography variant="h6">
                  {t("access_denied.you_do_not_have_access")}
                </Typography>
              </div>
            </div>
            <LockOutlinedIcon />
          </div>
          {showButton && (
            <div className={classes.footer}>
              <Button
                variant="contained"
                component={Link}
                color="primary"
                to={goToLink}
              >
                {goToText}
              </Button>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
};

AccessDenied.defaultProps = {
  goToLink: "/",
  goToText: i18n.t("common.go_to_home"),
  showButton: true,
};

AccessDenied.propTypes = {
  goToLink: PropTypes.string,
  goToText: PropTypes.string,
  showButton: PropTypes.bool,
};

export default AccessDenied;
