const createBackendPayload = (
  marketingProgram,
  optsTraitInfo,
  ecosystems,
  optMappings
) => {
  const payload = {
    schemaVersion: "v2",
    marketingProgram: {
      marketingProgramNumber: marketingProgram.marketingProgramNumber,
      description: marketingProgram.description,
    },
    contactPointType: {
      contactPointTypeCode: optsTraitInfo.channel.contact_point_type_code,
      contactPointTypeName: optsTraitInfo.channel.contact_point_type_name,
    },
    trait: {
      optIndicator: {
        traitName:
          optsTraitInfo.statusTraitNameOptIndicator +
          optsTraitInfo.optIndicator,
      },
      optChoiceDate: {
        traitName:
          optsTraitInfo.statusTraitNameOptChoiceDate +
          optsTraitInfo.optChoiceDate,
      },
    },
    ecosystems: ecosystems.map((ecosystem) => ({
      ecosystemId: ecosystem.ecoSystemId,
      ecosystemName: ecosystem.ecoSystemName,
    })),
    optMappings: optMappings.map((mapping) => {
      const data = {
        ...mapping,
        optText: mapping.optTextEnglish,
        subscriptionOptNumber: Number(mapping.subscriptionOptNumber),
        ciamOptId: mapping.id.slice(0, mapping.id.lastIndexOf("-")),
      };

      if (mapping.new) {
        return {
          ...data,
          opt: {
            optTextEnglish: data.optTextEnglish,
            optId: data.ciamOptId,
          },
          primaryIndicator: mapping.og_data.primaryIndicator,
          marketingProgram: {
            marketingProgramNumber: marketingProgram.marketingProgramNumber,
            description: marketingProgram.description,
          },
          contactPointType: {
            contactPointTypeCode: mapping.contactPointTypeCode,
            contactPointTypeName: mapping.contactPointTypeName,
          },
          contactPointCategory: {
            contactPointCategoryCode: mapping.contactPointCategoryCode,
            description: mapping.description,
          },
          service: {
            serviceName: data.serviceName,
          },
        };
      }

      return data;
    }),
  };

  return payload;
};

export default createBackendPayload;
