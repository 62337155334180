const convertBackendPayload = (info) => {
  const {
    optId,
    optText,
    marketingProgramNumber: ciamMarketingProgramNumber,
    marketingProgramDescription: ciamMarketingProgramDescription,
  } = info.opt;
  const { marketingProgramNumber, description } = info.marketingProgram;
  const {
    serviceName,
    channel,
    contactPointCategoryCode,
    primaryIndicator,
    subscriptionOptNumber,
  } = info.service;
  return {
    optId,
    optText,
    marketingProgram: `${marketingProgramNumber} - ${description}`,
    marketingProgramNumber: ciamMarketingProgramNumber,
    marketingProgramDescription: ciamMarketingProgramDescription,
    serviceName,
    channel: {
      contact_point_type_code: channel.split("-")[0],
      contact_point_type_name: channel.split("-")[1],
    },
    contactPointCategoryCode: {
      contact_point_category_code: contactPointCategoryCode.split("-")[0],
      contact_point_category_name: contactPointCategoryCode.split("-")[1],
    },
    primaryIndicator,
    subscriptionOptNumber,
  };
};

export default convertBackendPayload;
