import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
      margin: "0px",
      "& span": {
        fontSize: "12px !important",
        color: `${theme.palette.error.main2} !important`,
      },
      "& .MuiSvgIcon-root": {
        width: "20px !important",
        height: "20px !important",
        marginRight: "6px",
        fill: `${theme.palette.error.main2} !important`,
        "& path": {
          fill: `${theme.palette.error.main2} !important`,
        },
      },
    },
    success: {
      "& span": {
        color: `${theme.palette.green.main2} !important`,
      },
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: `${theme.palette.green.main2} !important`,
        },
      },
    },
    info: {
      "& span": {
        color: `${theme.palette.primary.main} !important`,
      },
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: `${theme.palette.primary.main} !important`,
        },
      },
    },
  };
});

export default useStyles;
