import api from "../utilities/api";

const getReferenceData = async (query) => {
  const url = "/reference-data";

  const {
    data: { data },
  } = await api.post(url, {
    query,
  });

  return data;
};

export default getReferenceData;
