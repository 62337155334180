import api from "../utilities/api";

const getOptsDetails = async (optId) => {
  const apiUrl = `/opts/${optId}?refreshCache=true`;
  const {
    data: { data },
  } = await api.get(apiUrl);

  return data;
};

export default getOptsDetails;
