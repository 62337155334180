import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";
import applicationConfig from "../../../../../config/applicationConfig";

const UseCaseRequestOutputModal = ({
  onClose,
  isOpen,
  data,
  requestId,
  marketingProgram,
  traits,
  eventProperties,
  isLoading,
  module,
}) => {
  const { warnings, items, ...rest } = data;
  const { t } = useTranslation();

  let columns = [
    {
      field: "useCaseId",
      headerName: t("create_use_case.use_case_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "useCaseName",
      headerName: t("common.labels.use_case_name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Scope",
      flex: 1,
      sortable: false,
    },
    {
      field: "trait",
      headerName: t("common.labels.trait"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
      sortable: false,
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
    },
  ];

  if (module === applicationConfig.modules.events_use_case) {
    columns.splice(3, 1);
    columns = [
      ...columns.slice(0, 3),
      {
        field: "eventProperty",
        headerName: t("common.labels.event_property_name"),
        flex: 1,
        sortable: false,
      },
      ...columns.slice(4),
    ];
  }

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    const trait = traits.find(
      (traitObject) => traitObject.traitId === warning.traitId
    );
    const eventProperty = eventProperties.find((_eventProperty) => {
      return _eventProperty.eventProperty === warning.eventProperty;
    });
    return {
      id: `${warning.useCaseId}-${index}`,
      useCaseId: warning.useCaseId,
      useCaseName: warning.useCaseName,
      marketingProgram,
      status: "warning",
      trait: !trait ? "" : `${trait.traitId} - ${trait.traitName}`,
      eventProperty: !eventProperty ? "" : eventProperty.eventProperty,
      message: warning.message,
    };
  });

  let useCaseName;
  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;

      if (
        item.useCaseName &&
        item.useCaseId &&
        !item.mappingWarning &&
        index === 0 &&
        item.new !== false
      ) {
        status = "success";
        message = t("create_use_case.new_use_case");
        useCaseName = item.useCaseName;
      } else if (item.mappingWarning) {
        status = "warning";
        message = item.mappingWarning;
      } else if (item.new) {
        status = "success";
        if (module === applicationConfig.modules.events_use_case) {
          message = t("create_use_case.event_property_added");
        } else {
          message = t("create_use_case.trait_added");
        }
      } else if (item.deleted) {
        status = "success";
        if (module === applicationConfig.modules.events_use_case) {
          message = t("create_use_case.event_property_delete");
        } else {
          message = t("create_use_case.trait_deleted");
        }
      } else {
        status = "success";
        message = t("create_use_case.update_use_case");
        useCaseName = item.useCaseName;
      }
      return {
        id: `${item.useCaseId}-${index}`,
        useCaseId: item.useCaseId,
        useCaseName: useCaseName || rest.useCaseName,
        type: (item.scope?.type || item.scope)
          ?.replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase()),
        status,
        eventProperty: item.eventProperty,
        trait: !item.traitId ? "" : `${item.traitId} - ${item.traitName}`,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => onClose()}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={
        module === applicationConfig.modules.traits_use_case
          ? t("request_output.use_case_modal_title")
          : t("request_output.use_case_event_properties_modal_title")
      }
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

UseCaseRequestOutputModal.defaultProps = {
  requestId: "",
  isLoading: false,
};
UseCaseRequestOutputModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    useCaseName: PropTypes.string,
  }).isRequired,
  requestId: PropTypes.string,
  marketingProgram: PropTypes.string.isRequired,
  traits: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  eventProperties: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  module: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default UseCaseRequestOutputModal;
