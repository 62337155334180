import { withStyles } from "@material-ui/core";

const useStyles = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.colorTheme.white,
    color: theme.colorTheme.grey,
    padding: "6px 10px",
    margin: "0",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    boxShadow: `0px 5px 10px ${theme.colorTheme.modalTitle}`,
  },
}));

export default useStyles;
