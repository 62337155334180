import getSourceDetails from "../api/get-source-details";
import i18n from "../i18n/init";

const sourcesModuleConfig = {
  sourceSearchFilters: [
    {
      name: "sourceId",
      label: i18n.t("common.labels.source_id"),
    },
    {
      name: "sourceName",
      label: i18n.t("common.labels.source_name"),
    },
    {
      name: "url",
      label: i18n.t("sources_container.search_filter.url"),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.marketing_program"),
    },
  ],
  sourcesRequestsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "sourceName",
      label: i18n.t("common.labels.source_name"),
    },
  ],
  createSourceConstants: {
    dataTypeOptions: [
      i18n.t("constants.kind_of_data_collected_options.registration_data"),
      i18n.t("constants.kind_of_data_collected_options.clickstream"),
      i18n.t("constants.kind_of_data_collected_options.purchase_data"),
      i18n.t("constants.kind_of_data_collected_options.dependent_data"),
      i18n.t("constants.kind_of_data_collected_options.survey_data"),
      i18n.t("constants.kind_of_data_collected_options.enriched_data"),
    ],
    yesOrNoOptions: [
      i18n.t("constants.yes_or_no_options.yes"),
      i18n.t("constants.yes_or_no_options.no"),
    ],
    sourceTypeOptions: [
      i18n.t("constants.source_type_options.standard"),
      i18n.t("constants.source_type_options.offline"),
      i18n.t("constants.source_type_options.coregistration"),
    ],
    sourceIntegrationTypeOptions: [
      i18n.t("constants.source_integration_type_options.janrain_source"),
      i18n.t("constants.source_integration_type_options.i2_offline"),
      i18n.t("constants.source_integration_type_options.i5_offline"),
      i18n.t("constants.source_integration_type_options.clickstream"),
      i18n.t("constants.source_integration_type_options.other_http_api_source"),
    ],
    sourceCreationHeadings: {
      1: i18n.t("create_source.headings.step_1"),
      2: i18n.t("create_source.headings.step_2"),
      3: i18n.t("create_source.headings.step_3"),
    },
    sourceCreationMessages: {
      1: i18n.t("create_source.steps.step_1"),
      2: i18n.t("create_source.steps.step_2"),
      3: i18n.t("common.texts.request_details"),
      4: i18n.t("create_source.steps.step_4"),
    },
    sourceRevisionHeadings: {
      1: i18n.t("revise_source.headings.step_1"),
      2: i18n.t("revise_source.headings.step_2"),
    },
    sourceRevisionMessages: {
      1: i18n.t("revise_source.steps.step_1"),
      2: i18n.t("revise_source.steps.step_2"),
    },
    sourceFooterText: {
      1: i18n.t("common.next"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
    segmentSourceCreationHeadings: {
      1: i18n.t("create_segment_source.headings.step_1"),
      2: i18n.t("create_segment_source.headings.step_2"),
      3: i18n.t("create_segment_source.headings.step_3"),
    },
    segmentSourceRevisionHeadings: {
      1: i18n.t("revise_source_request.headings.step_1"),
      2: i18n.t("revise_source_request.headings.step_2"),
    },
    segmentSourceCreationMessages: {
      1: i18n.t("create_segment_source.steps.step_1"),
      2: i18n.t("create_segment_source.steps.step_2"),
      3: i18n.t("common.texts.request_details"),
    },
    segmentSourceRevisionMessages: {
      1: i18n.t("revise_source_request.steps.step_1"),
      2: i18n.t("revise_source_request.steps.step_2"),
      3: i18n.t("revise_source_request.steps.step_3"),
    },
    characterLimits: {
      sourceName: 100,
      description: 700,
      consumerFacingDescription: 700,
      systemDisplayingPage: 200,
      agencyName: 100,
      ropaId: 45,
      descriptionOfProcess: 45,
      businessFacingName: 200,
      url: 200,
      registrationPageUrl: 200,
    },
  },
  sourcesTableColumns: [
    {
      field: "sourceId",
      sortable: false,
      headerName: i18n.t("common.labels.source_id"),
      flex: 0.5,
      clickable: true,
      custom: true,
      clickableCellData: {
        params: ["sourceId"],
        api: getSourceDetails,
      },
      disableToggle: true,
    },
    {
      field: "sourceName",
      sortable: false,
      headerName: i18n.t("common.labels.source_name"),
      truncate: true,
      width: "30%",
    },
    {
      field: "description",
      sortable: false,
      headerName: i18n.t("common.labels.description"),
      truncate: true,
      width: "10%",
    },
    {
      field: "businessSourceName",
      sortable: false,
      headerName: i18n.t("common.labels.business_source_name"),
      width: "20%",
    },
  ],
  sourcesApprovalsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "sourceName",
      label: i18n.t("common.labels.source_name"),
    },
    {
      name: "requester",
      label: i18n.t("common.labels.requester"),
    },
  ],
  requestStatus: {
    APPROVED: i18n.t("status.approved"),
    REJECTED: i18n.t("status.rejected"),
    PENDING: i18n.t("status.pending"),
    RETURNED: i18n.t("status.returned"),
    PROCESSING: i18n.t("status.processing"),
  },
  sourceRequestsConstants: {
    toolTipTextPending: i18n.t("traits_requests.tooltip.pending_status"),
    toolTipTextApproved: i18n.t("traits_requests.tooltip.approved_status"),
    toolTipTextReturned: i18n.t("traits_requests.tooltip.returned_status"),
  },
  sourcesSearchDefaultFilterState: {
    sourceId: false,
    requestId: false,
    sourceName: false,
    traitDescription: false,
    marketingProgram: false,
    requester: false,
    url: false,
  },
  ecosystemFeatureKeys: [
    {
      id: "ecosystemSourceName",
      name: i18n.t("ecosystems.create_source_keys.ecosystem_source_name"),
    },
    {
      id: "ecosystemSourceType",
      name: i18n.t("ecosystems.create_source_keys.ecosystem_source_type"),
    },
    {
      id: "ecosystemSourceKey",
      name: i18n.t("ecosystems.create_source_keys.ecosystem_source_key"),
    },
    {
      id: "ecosystemSourceLink",
      name: i18n.t("ecosystems.create_source_keys.ecosystem_source_link"),
    },
    {
      id: "ecosystemSourceSlug",
      name: i18n.t("ecosystems.create_source_keys.ecosystem_source_slug"),
    },
  ],
};

export default sourcesModuleConfig;
