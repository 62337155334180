import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { setValue } = require("../utilities/cache");

const { requestIdPrefix } = applicationConfig;

const createUseCase = async (body) => {
  const url = `/use-cases`;
  let {
    data: { data },
  } = await api.post(url, body);

  if (data.some((item) => item.status === "approved")) {
    setValue("traits");
  }
  data = data.map((item) => ({
    ...item,
    requestId: `${requestIdPrefix}${item.requestId}`,
  }));
  return data;
};

export default createUseCase;
