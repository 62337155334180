import { useState } from "react";

import { Typography } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";

import useStyles from "./styles";

const PartiallyEditableText = ({
  prefixText,
  suffixText,
  editablePrefix,
  onChangePrefix,
  onSave,
  onCancel,
  isDisabled,
  error,
  helperText,
  onEditMode,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.flexContainer}>
        <div className={clsx(classes.container)}>
          {!isEditMode && (
            <Typography variant="h6">{prefixText + suffixText}</Typography>
          )}
          {isEditMode && (
            <div className={classes.flexContainer2}>
              <TextField
                value={editablePrefix}
                onChange={(event) => {
                  onChangePrefix(event.target.value);
                }}
                disabled={isDisabled}
                inputProps={{
                  "data-testid": "editable-text",
                }}
              />
              <TextField value={suffixText} disabled />
            </div>
          )}
        </div>

        {!isEditMode && onChangePrefix && (
          <div
            onClick={() => {
              setIsEditMode(true);
              onEditMode();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            className={clsx(classes.btnContainer, classes.editIconBtn)}
            data-testid="btn-1"
          >
            <EditIcon />
          </div>
        )}
        {isEditMode && (
          <div className={clsx(classes.flexContainer1, classes.btnContainer)}>
            <div
              onClick={() => {
                setIsEditMode(false);
                onSave();
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              data-testid="btn-2"
              className={classes.deleteIcon}
            >
              <DoneIcon />
            </div>
            <div
              onClick={() => {
                setIsEditMode(false);
                onCancel();
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              data-testid="btn-3"
              className={classes.clearIcon}
            >
              <ClearIcon />
            </div>
          </div>
        )}
      </div>
      {helperText && (
        <FormHelperText id="my-helper-text" error={Boolean(error)}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

PartiallyEditableText.defaultProps = {
  prefixText: "",
  suffixText: "",
  editablePrefix: "",
  onChangePrefix: null,
  isDisabled: false,
  error: false,
  helperText: <></>,
  onEditMode: () => {},
};

PartiallyEditableText.propTypes = {
  prefixText: PropTypes.string,
  suffixText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editablePrefix: PropTypes.string,
  onChangePrefix: PropTypes.func,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  onEditMode: PropTypes.func,
};

export default PartiallyEditableText;
