import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { requestIdPrefix } = applicationConfig;

const updateRequest = async (requestId, updatedData, module) => {
  const updatedRequestId = requestId.replace(requestIdPrefix, "");
  const url = `/${module}/requests/${updatedRequestId}`;
  const {
    data: { data },
  } = await api.put(url, updatedData);
  data.requestId = `${requestIdPrefix}${data.requestId}`;
  return data;
};

export default updateRequest;
