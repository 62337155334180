import React from "react";

import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { GraphiQL } from "graphiql";

import applicationConfig from "../../config/applicationConfig";

import useStyles from "./styles";

import "graphiql/graphiql.css";
import "./GraphQLWrapper.css";

const getUrl = (relativePath) =>
  `${
    applicationConfig.deploymentEnv === "local" ? "http://localhost:3100" : ""
  }${relativePath}`;

const graphqlEndpointOptions = [
  {
    label: "Reference Data API",
    value: getUrl("/api/reference-data"),
  },
  {
    label: "Consumer Data Functions API",
    value: getUrl("/api/consumer-data-functions"),
  },
  {
    label: "Products API",
    value: getUrl("/api/products-data"),
  },
];

const GraphQLWrapper = () => {
  const classes = useStyles();
  const [selectedGraphqlEndpoint, setSelectedGraphqlEndpoint] = React.useState(
    graphqlEndpointOptions[0]
  );

  const fetcher = createGraphiQLFetcher({
    url: selectedGraphqlEndpoint.value,
    headers: {
      "x-crs-ping-token": localStorage.getItem("ping-token"),
      "x-crs-user-groups-token": localStorage.getItem("groups-token"),
    },
  });

  return (
    <div className={classes.graphqlContainer}>
      <FormControl className={classes.apiSelect}>
        <InputLabel>Select CRS API</InputLabel>
        <Select
          value={selectedGraphqlEndpoint}
          label="CRS API"
          onChange={(e) => setSelectedGraphqlEndpoint(e.target.value)}
        >
          {graphqlEndpointOptions.map((option) => (
            <MenuItem value={option}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <GraphiQL fetcher={fetcher} />
    </div>
  );
};

export default GraphQLWrapper;
