export const actions = {
  INCREMENT_REQUESTS_COUNT: "INCREMENT_REQUESTS_COUNT",
  DECREMENT_REQUESTS_COUNT: "DECREMENT_REQUESTS_COUNT",
};

// Reducer to Handle Actions
export const reducer = (state, action) => {
  switch (action.type) {
    case actions.INCREMENT_REQUESTS_COUNT:
      return {
        count: state.count + action.payload,
        loading: true,
      };
    case actions.DECREMENT_REQUESTS_COUNT:
      return {
        count: state.count - action.payload,
        loading: state.count !== action.payload,
      };
    default:
      return state;
  }
};
