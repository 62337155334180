import React, { useState, useRef, useMemo, useEffect } from "react";

import {
  Paper,
  Typography,
  Button,
  Tab,
  Tabs,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Select,
  InputLabel,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getTraitsResponses from "../../../api/get-traits-responses";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import CustomModal from "../../../components/CustomModal";
import InlineMessage from "../../../components/InlineMessage";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";

import useLegalEntities from "../../../hooks/useLegalEntities";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useMarketingPrograms from "../../../hooks/useMarketingPrograms";
import useNotifier from "../../../hooks/useNotifier";
import useTraits from "../../../hooks/useTraits";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import { isEmptyString } from "../../../utilities/formValidation";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import truncateString from "../../../utilities/truncateString";
import useImmer from "../../../utilities/useImmer";

import AliasesModal from "../NewTraitsResponseContainer/components/AliasesModal";

import traitResponsesStyles from "./styles";

const TraitsResponsesManagementContainer = () => {
  const {
    traitsResponsesTableColumns,
    traitsResponsesSearchDefaultFilterState,
    traitsResponsesSearchFilters,
    traitsResponsesKeys,
    globalTraitsResponsesKeys,
  } = traitsModuleConfig;
  const globalStyles = useGlobalStyles();
  const accordionClasses = useAccordionStyles();
  const classes = traitResponsesStyles();
  const history = useHistory();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [tab, setTab] = useState(1);
  const [traitsResponsesModalIsOpen, setTraitsResponsesModalIsOpen] =
    useState(false);
  const [openAliasesModal, setOpenAliasesModal] = useState(false);
  const [traitDetails, setTraitDetails] = useState({});
  const childRef = useRef();
  const [filters, setFilters] = useState({});
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [selectedTrait, setSelectedTrait] = useState({});
  const [loadingCellData, setLoadingCellData] = useState(false);

  const [traitResponses, setTraitResponses] = useState({});
  const [aliases, setAliases] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [scope, setScope] = useState("global");
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useImmer({
    scopeIdentifier: null,
  });
  const [viewCloneMappings, setViewCloneMappings] = useState(false);
  const [clone, setClone] = useState({
    trait: {
      title: "",
    },
  });
  const [subMenu, setSubMenu] = useState([]);
  const isGlobalScope = scope === "global";

  const { addNotification } = useNotifier();
  const { user } = useUserProfile();
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const {
    getTraitsFromApi,
    traitsLoading,
    setTraitsLoading,
    traitOptions,
    setTraitOptions,
    initialTraitOptions,
  } = useTraits();

  const {
    getMarketingProgramsFromApi,
    initialMarketingProgramOptions,
    marketingProgramOptions,
    marketingProgramsLoading,
    setMarketingProgramOptions,
    setMarketingProgramsLoading,
  } = useMarketingPrograms();

  const {
    memoizedLegalEntitiesFromApi,
    legalEntities,
    legalEntitiesLoading,
    initialLegalEntitiesOptions,
    setLegalEntities,
    getLegalEntitiesFromApi,
  } = useLegalEntities();
  const [traitValue, setTraitValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");

  useEffect(() => {
    (async () => {
      await getMarketingProgramsFromApi("");
      await getLegalEntitiesFromApi("");
      await getTraitsFromApi?.("");
    })();
  }, [getTraitsFromApi]);

  const traitDropdownValues = useMemo(
    () =>
      traitValue.length > 0 ||
      traitOptions.some(
        ({ traitId, traitName, description }) =>
          `${traitId} - ${traitName} - ${description}` === clone.trait?.title
      )
        ? traitOptions
        : initialTraitOptions,
    [initialTraitOptions, traitOptions, clone.trait?.title]
  );

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchTraits
  );

  const toggleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loadTraitResponses = (data) => {
    if (!data?.mappedTraitResponses) {
      setTraitResponses((traitResp) => ({
        ...traitResp,
        mappedTraitResponses: [],
      }));
      return;
    }
    try {
      let response = { ...data };
      let subMenuList = [];
      let selectedSubMenuListItem = null;

      if (response.mappedTraitResponses.length > 0) {
        if (response.scope === "marketingProgram") {
          subMenuList = response.mappedTraitResponses.map((x) => ({
            name: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
            value: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
          }));
          selectedSubMenuListItem = `${response.mappedTraitResponses[0].marketingProgramNumber} - ${response.mappedTraitResponses[0].marketingProgramDescription}`;
          response = {
            ...response,
            mappedTraitResponses: response.mappedTraitResponses.map((y) => ({
              ...y,
              title: `${y.marketingProgramNumber} - ${y.marketingProgramDescription}`,
            })),
          };
        } else if (response.scope === "legalEntity") {
          subMenuList = response.mappedTraitResponses.map((x) => ({
            name: `${x.legalEntityId} - ${x.legalEntityName}`,
            value: `${x.legalEntityId} - ${x.legalEntityName}`,
          }));
          selectedSubMenuListItem = `${response.mappedTraitResponses[0].legalEntityId} - ${response.mappedTraitResponses[0].legalEntityName}`;
          response = {
            ...response,
            mappedTraitResponses: response.mappedTraitResponses.map((y) => ({
              ...y,
              title: `${y.legalEntityId} - ${y.legalEntityName}`,
            })),
          };
        } else if (response.scope === "regional") {
          subMenuList = response.mappedTraitResponses.map((x) => ({
            name: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
            value: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
          }));
          selectedSubMenuListItem = `${response.mappedTraitResponses[0].geoRegionCode} - ${response.mappedTraitResponses[0].geoRegionDescription}`;
          response = {
            ...response,
            mappedTraitResponses: response.mappedTraitResponses.map((y) => ({
              ...y,
              title: `${y.geoRegionCode} - ${y.geoRegionDescription}`,
            })),
          };
        } else {
          selectedSubMenuListItem = "Global";
          response = {
            ...response,
            mappedTraitResponses: response.mappedTraitResponses.map((y) => ({
              ...y,
              title: "Global",
            })),
          };
        }
        setSubMenu(subMenuList);
        setSelectedSubMenuItem((draft) => {
          draft.scopeIdentifier = selectedSubMenuListItem;
        });
        setTraitResponses(response);
      }
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const filterFieldMap = {
    traitId: "traitId",
    traitName: "traitName",
    traitResponseEnglish: "traitResponseEnglish",
    traitResponseLocalLanguage: "traitResponseLocalLanguage",
    marketingProgram: "marketingProgram",
    "": "searchText",
  };

  const loadTraitsResponses = async (_page = 1, perPage, filter) => {
    try {
      increaseRequestsCount();
      const response = await getTraitsResponses(
        parseSearchFilter([filter], filterFieldMap, _page, perPage)
      );
      return response;
    } catch (error) {
      handleError({
        error,
        addNotification,
      });
    } finally {
      decreaseRequestsCount();
    }
    return null;
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
    loadTraitsResponses();
  };

  const getAllowedColumns = () => {
    const columns = [...traitsResponsesTableColumns];
    return columns;
  };

  const subList = () => {
    const marketingProgramDropdownValues =
      subMenuValue.length > 0 || initialMarketingProgramOptions.length === 0
        ? marketingProgramOptions
        : initialMarketingProgramOptions;
    const legalEntitiesDropdownValues =
      subMenuValue.length > 0 || initialLegalEntitiesOptions.length === 0
        ? legalEntities
        : initialLegalEntitiesOptions;
    const regionalDropdownValues = applicationConfig.regionFilters.map((r) => ({
      ...r,
      geoRegionCode: r.name,
      geoRegionName: r.label,
    }));
    if (clone.scope === "regional") {
      return regionalDropdownValues
        ?.filter((region) => {
          if (region.label !== "GLOBAL" && region.geoRegionName !== "GLOBAL") {
            return true;
          }
          return false;
        })
        .map((region) => {
          return {
            ...region,
            title: region.label
              ? `${region.name} - ${region.label}`
              : `${region.geoRegionCode} - ${region.geoRegionName}`,
          };
        });
    }
    if (clone.scope === "legalEntity") {
      return legalEntitiesDropdownValues.map((entity) => ({
        ...entity,
        title: `${entity.legalEntityId || entity.legalEntityNumber} - ${
          entity.legalEntityName
        }`,
      }));
    }
    return marketingProgramDropdownValues.map((program) => ({
      ...program,
      title: `${program.marketingProgramNumber} - ${
        program.marketingProgramDescription ||
        /* istanbul ignore next */ program.description
      }`,
    }));
  };

  const handleModalClose = () => {
    setExpanded(false);
    setTraitsResponsesModalIsOpen(false);
    setScope("global");
  };

  const validateCloneResponses = () => {
    const e = {};
    if (isEmptyString(clone.trait)) {
      e.traitError = t("errors.field_required");
    }
    if (isEmptyString(clone.scope)) {
      e.scopeError = t("errors.field_required");
    }
    if (
      clone.scope !== "global" &&
      isEmpty(clone.selectedSubMenuItem?.scopeIdentifier)
    ) {
      e.scopeIdentifierError = t("errors.field_required");
    }
    if (
      (clone.scope === "global" || scope === "global") &&
      scope !== clone.scope
    ) {
      e.cloneFormError = "Only Global scope can be cloned to Global scope";
    }
    return e;
  };

  const cellParams = useMemo(() => [{ scope, schemaVersion: "v2" }], [scope]);

  const scopedTraitResponses =
    traitResponses.mappedTraitResponses?.length > 0
      ? (
          traitResponses.mappedTraitResponses.find(
            (x) => x.title === selectedSubMenuItem.scopeIdentifier
          ) || traitResponses.mappedTraitResponses?.[0]
        )?.traitResponses
      : [];

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("traits_container.traits_responses_management")}
          </Typography>
        </div>
      }
      showSpinner={loading}
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={tab}
          onChange={(_, number) => setTab(number)}
          aria-label="basic tabs"
          classes={{
            indicator: globalStyles.indicator,
          }}
        >
          <Tab
            label="Traits"
            onClick={() => history.push("/traits?displayTab=traits")}
          />
          <Tab
            label="Trait Responses"
            onClick={() => history.push("/traits?displayTab=trait-responses")}
          />
          <Tab
            label="Insights"
            onClick={() => history.push("/traits?displayTab=insights")}
          />
        </Tabs>
        <div className={classes.flexContainer}>
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createTraits
          ) && (
            <Button
              onClick={() => {
                history.push(
                  applicationConfig.pathnames.traits_response_manage,
                  {
                    from: applicationConfig.modules.trait_response,
                    trait: { ...selectedTrait },
                  }
                );
              }}
              classes={{
                root: globalStyles.btn,
              }}
              variant="outlined"
              style={{
                marginRight: "34px",
                justifySelf: "flex-end",
              }}
            >
              {isEmpty(selectedTrait)
                ? t("traits_traits_responses.new_traits_responses")
                : t("common.labels.manage_responses")}
            </Button>
          )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={traitsResponsesSearchFilters}
            defaultFilterState={traitsResponsesSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              loadTraitsResponses(page, perPage, filters)
            }
            moduleName="trait-responses"
            openModal={(params) => {
              setTraitDetails({
                ...params.row,
              });
              setTraitsResponsesModalIsOpen(true);
            }}
            disableSelectAllCheckbox
            checkboxSelection
            selectionModel={selectedTraits}
            onSelectionModelChange={(e, tableData) => {
              setSelectedTraits(e.at(-1));
              setSelectedTrait(tableData[e.at(-1)]);
            }}
            renderCellData={(data, field, row) => {
              loadTraitResponses({
                ...data,
                traitDescription: row.traitDescription,
              });
            }}
            setLoadingCellData={setLoadingCellData}
            filters={filters}
            columns={getAllowedColumns()}
            clickableCellExternalParams={cellParams}
            showArrayValueInNewSheet={(key) => key === "traitResponses"}
            customizeExportData={(sheetObj, key, obj) => {
              const newSheetObj = {};
              Object.keys(sheetObj).forEach((sheetKey) => {
                if (sheetKey === "traitResponses") {
                  newSheetObj["Traits Responses"] = sheetObj[sheetKey].map(
                    (x) => ({
                      traitId: obj.traitId,
                      traitName: obj.traitName,
                      traitDescription: obj.traitDescription,
                      ...x,
                    })
                  );
                }
              });
              return newSheetObj;
            }}
            exportMainSheetName="Traits"
          />
        </div>
      </Paper>
      {traitsResponsesModalIsOpen && (
        <CustomModal
          open={traitsResponsesModalIsOpen}
          onClose={handleModalClose}
          showCloseIcon
          title={`${traitDetails?.traitId} - ${traitDetails?.traitName}`}
          loading={loadingCellData && !traitDetails?.traitId}
          subtitle={t("traitsResponses.traits_responses_modal_title")}
          data={{
            scope: {
              scope,
              identifier: selectedSubMenuItem.scopeIdentifier,
            },
            traitResponses: scopedTraitResponses,
          }}
        >
          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <InputLabel id="scope-select-label">Scope</InputLabel>
            <Select
              value={scope}
              label="Scope"
              data-testid="scope-select"
              id="scope-select"
              onChange={(e) => {
                setScope(e.target.value);
                setSubMenu([]);
                setSelectedSubMenuItem((draft) => {
                  draft.scopeIdentifier = null;
                });
                if (expanded) {
                  setExpanded(false);
                }
              }}
            >
              <MenuItem value="global">{t("common.global")}</MenuItem>
              <MenuItem value="regional">{t("common.labels.region")}</MenuItem>
              <MenuItem value="legalEntity">
                {t("common.labels.legal_entity")}
              </MenuItem>
              <MenuItem value="marketingProgram">
                {t("common.labels.marketing_program")}
              </MenuItem>
            </Select>
          </FormControl>
          {subMenu.length > 0 && !loadingCellData && (
            <FormControl fullWidth className={classes.accordionSelect}>
              <InputLabel id="scope-item-select-label" shrink>
                {traitResponses.scope === "regional"
                  ? "Region"
                  : traitResponses.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Select
                labelId="scope-select-label"
                id="scope-item-select"
                value={selectedSubMenuItem.scopeIdentifier}
                label="Marketing Program"
                onChange={(e) => {
                  setSelectedSubMenuItem((draft) => {
                    draft.scopeIdentifier = e.target.value;
                  });
                  if (expanded) {
                    setExpanded(false);
                  }
                }}
              >
                {subMenu.map(({ name, value }) => (
                  <MenuItem value={value} key={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={accordionClasses.systems}>
            {Array.isArray(traitResponses.mappedTraitResponses) &&
            traitResponses.mappedTraitResponses.length > 0 &&
            !loadingCellData
              ? scopedTraitResponses?.map((row, i) => {
                  const id = `system-${i.toString()}`;
                  const languageCode =
                    row?.languageCode?.toUpperCase() ||
                    (scope === "global" && "EN");
                  const traitResponse = truncateString(
                    scope === "global"
                      ? row?.predefinedTraitResponse
                      : row?.predefinedTraitResponseLocalLanguage,
                    {
                      maxLength: 40,
                      showEllipsis: true,
                    }
                  );
                  return (
                    <Accordion
                      key={i.toString()}
                      className={clsx(
                        accordionClasses.accordion,
                        classes.customAccordion
                      )}
                      expanded={expanded === id}
                      onChange={toggleAccordion(id)}
                    >
                      <AccordionSummary
                        data-testid="accordion-summary"
                        className={accordionClasses.accordionHeader}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={accordionClasses.systemType}>
                          {languageCode}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={accordionClasses.systemName}
                        >
                          {traitResponse}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={accordionClasses.accordionContent}
                      >
                        <AccordionRowDetails
                          keys={
                            isGlobalScope
                              ? globalTraitsResponsesKeys
                              : traitsResponsesKeys
                          }
                          row={{
                            ...row,
                            language: `${row?.languageName} (${languageCode})`,
                            aliases: row.predefinedTraitResponseAliases ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setAliases(
                                    row.predefinedTraitResponseAliases.map(
                                      (alias) => ({
                                        ...alias,
                                        id: alias.predefinedTraitResponseAliasId,
                                      })
                                    )
                                  );
                                  setOpenAliasesModal(true);
                                }}
                              >
                                View Aliases
                              </Button>
                            ) : (
                              <Typography style={{ fontSize: "0.875rem" }}>
                                None
                              </Typography>
                            ),
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : !loadingCellData && (
                  <Alert severity="info">
                    {t("traits_container.empty_mapped_properties_alert")}
                  </Alert>
                )}
          </div>
          {loadingCellData && (
            <div style={{ padding: "8px" }}>
              {Array.from({ length: 3 }).map((_, i) => (
                <React.Fragment key={i}>
                  <Skeleton variant="rect" width={640} height={60} />
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
          {openAliasesModal && (
            <AliasesModal
              openManageAliasesModal={openAliasesModal}
              viewOnly
              setOpenManageAliasesModal={() => {
                setOpenAliasesModal(false);
              }}
              isGlobal={isGlobalScope}
              traitResponses={aliases}
            />
          )}
          <div className={accordionClasses.footer2}>
            {checkUserAuthorization(
              user.access,
              pageAccessConfig.createTraits
            ) && (
              <>
                <Button
                  data-testid="closeButton"
                  variant="contained"
                  color="primary"
                  disabled={loadingCellData}
                  onClick={() => {
                    const state = {
                      trait: {
                        ...traitResponses,
                      },
                      scope,
                      selectedSubMenuItem,
                      from: applicationConfig.modules.trait_response,
                    };
                    history.push({
                      pathname:
                        applicationConfig.pathnames.traits_response_manage,
                      state,
                    });
                  }}
                >
                  {t("common.manage_responses")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    loadingCellData ||
                    (!traitResponses?.mappedTraitResponses?.length > 0 &&
                      !loadingCellData)
                  }
                  classes={{
                    root: globalStyles.btn,
                  }}
                  onClick={() => {
                    setViewCloneMappings(true);
                  }}
                >
                  Clone Responses
                </Button>
              </>
            )}
          </div>
        </CustomModal>
      )}
      <CustomModal
        open={viewCloneMappings}
        onClose={() => {
          setViewCloneMappings(false);
          setClone({
            trait: {
              title: "",
            },
          });
          setErrors({});
        }}
        showCloseIcon
        title="Clone Responses"
      >
        <div className={classes.inputContainer} key="marketing-program">
          <InputLabel
            id="scope-value-label"
            classes={{
              root: globalStyles.label,
            }}
          >
            Trait
          </InputLabel>
          <Autocomplete
            options={traitDropdownValues.map((option) => ({
              ...option,
              title: `${option.traitId} - ${option.traitName} - ${option.description}`,
            }))}
            filterOptions={(options) => options}
            id="trait"
            placeholder="Please select a Trait"
            loading={traitsLoading}
            getOptionLabel={(option) => option.title || ""}
            value={clone.trait}
            onChange={(event, value) => {
              setClone({
                ...clone,
                trait: value,
              });
              setErrors({
                ...errors,
                traitError: "",
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Please select a Trait"
                onChange={(event) => {
                  setTraitsLoading(true);
                  setTraitOptions([]);
                  setTraitValue(event.target.value);
                  getTraitsFromApi(event.target.value);
                }}
              />
            )}
          />
          {errors.traitError && (
            <FormHelperText
              id="my-helper-text"
              error={Boolean(errors.traitError)}
            >
              <InlineMessage message={errors.traitError} />
            </FormHelperText>
          )}
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <FormControl fullWidth>
              <InputLabel
                id="scope-label"
                classes={{
                  root: globalStyles.label,
                  shrink: classes.shrinkLabel,
                }}
              >
                Scope
              </InputLabel>
              <Select
                value={clone.scope}
                label="Scope"
                id="scope"
                onChange={(e) => {
                  setClone({
                    ...clone,
                    scope: e.target.value,
                    selectedSubMenuItem: {
                      title: "Global",
                    },
                  });
                  setSubMenuValue("");
                  setErrors({
                    ...errors,
                    scopeError: "",
                  });
                }}
              >
                <MenuItem value="global" data-testid="global-option">
                  Global
                </MenuItem>
                <MenuItem value="regional" data-testid="regional-option">
                  Region
                </MenuItem>
                <MenuItem value="legalEntity" data-testid="legal-option">
                  Legal Entity
                </MenuItem>
                <MenuItem
                  value="marketingProgram"
                  data-testid="marketing-option"
                >
                  Marketing Program
                </MenuItem>
              </Select>
              {errors.scopeError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.scopeError)}
                >
                  <InlineMessage message={errors.scopeError} />
                </FormHelperText>
              )}
              {errors.cloneFormError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.cloneFormError)}
                >
                  <InlineMessage message={errors.cloneFormError} />
                </FormHelperText>
              )}
            </FormControl>
          </div>
          {clone.scope && clone.scope !== "global" && (
            <div
              className={clsx(classes.inputContainer, classes.justifyContent)}
              key="region"
            >
              <InputLabel
                id="scope-value-label"
                classes={{
                  root: globalStyles.label,
                }}
              >
                {clone.scope === "regional"
                  ? "Region"
                  : clone.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Autocomplete
                options={subList()}
                id="regions"
                placeholder={`Please select a ${
                  clone.scope === "regional"
                    ? "Region"
                    : clone.scope
                        ?.replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())
                }`}
                loading={marketingProgramsLoading || legalEntitiesLoading}
                getOptionLabel={(option) => option.title || ""}
                value={clone.selectedSubMenuItem?.scopeIdentifier}
                onChange={async (event, value) => {
                  setClone({
                    ...clone,
                    selectedSubMenuItem: {
                      ...value,
                      scopeIdentifier: isEmpty(value) ? {} : value.title,
                    },
                  });
                  setErrors({
                    ...errors,
                    scopeIdentifierError: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={`Please select a ${
                      clone.scope === "regional"
                        ? "Region"
                        : clone.scope
                            ?.replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())
                    }`}
                    onChange={(event) => {
                      if (clone.scope === "marketingProgram") {
                        setMarketingProgramsLoading(true);
                        setMarketingProgramOptions([]);
                        getMarketingProgramsFromApi(event.target.value);
                      }
                      if (clone.scope === "legalEntity") {
                        setLegalEntities([]);
                        memoizedLegalEntitiesFromApi(event.target.value);
                      }
                      setSubMenuValue(event.target.value);
                    }}
                  />
                )}
              />
              {errors.scopeIdentifierError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.scopeIdentifierError)}
                >
                  <InlineMessage message={errors.scopeIdentifierError} />
                </FormHelperText>
              )}
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={async () => {
              const validationErrors = validateCloneResponses();
              setErrors(validationErrors);
              if (
                validationErrors &&
                Object.values(validationErrors).filter((e) => e).length > 0
              ) {
                return;
              }
              history.push("/trait-responses/manage", {
                from: applicationConfig.modules.trait_response,
                ...clone,
                clonedTraitResponses: traitResponses.mappedTraitResponses
                  .filter((mtr) => {
                    if (
                      scope === "global" ||
                      mtr.title === selectedSubMenuItem.scopeIdentifier
                    ) {
                      return true;
                    }
                    return false;
                  })?.[0]
                  ?.traitResponses?.map((traitResponse) => ({
                    ...traitResponse,
                    new: true,
                    predefinedTraitResponseAliases:
                      traitResponse.predefinedTraitResponseAliases?.map(
                        ({ predefinedTraitResponseAliasId, ...alias }) => alias
                      ),
                  })),
              });
            }}
          >
            Clone Responses
          </Button>
        </div>
      </CustomModal>
    </Layout>
  );
};

TraitsResponsesManagementContainer.propTypes = {};

export default TraitsResponsesManagementContainer;
