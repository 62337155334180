import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    paddingTop: 0,
    border: `1px solid ${theme.colorTheme.greyishWhite}`,
  },
}));

export default useStyles;
