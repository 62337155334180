import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getMarketingProramDetails = async (marketingProgramNumber) => {
  const refreshCache = getValue("marketing-programs");
  const url = `/marketing-programs/${marketingProgramNumber}?refreshCache=${refreshCache}`;
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getMarketingProramDetails;
