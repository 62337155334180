import { Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

import applicationConfig from "../../../../../config/applicationConfig";

import useStyles from "./styles";

const MarketingProgramRequestOutputModal = ({
  isOpen,
  isLoading,
  requestId,
  data,
  setShowOutput,
}) => {
  const { warnings, items } = data;

  const classes = useStyles();

  const { t } = useTranslation();
  let marketingProgram = "";

  if (items.length > 0) {
    marketingProgram = `${items[0].marketingProgramNumber}-${items[0].description}`;
  }

  const columns = [
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "entityType",
      headerName: t("common.labels.entity_type"),
      flex: 1,
      sortable: false,
    },
    {
      field: "entity",
      headerName: t("common.labels.entity"),
      flex: 1,
      sortable: false,
      renderCell: (columnData) => {
        const { value } = columnData;
        if (value && value.type === "div") {
          return (
            <div className={clsx(classes.flexContainer, classes.container)}>
              {value}
              <Button variant="contained">{t("common.new")}</Button>
            </div>
          );
        }
        return value;
      },
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const getEntityType = (item) => {
    let entityType = "";
    let entity = "";
    if (
      item.marketingProgramNumber &&
      item.marketingProgramName &&
      item.description
    ) {
      entityType = applicationConfig.modules.marketing_program;
      entity = `${item.marketingProgramNumber} - ${item.description} - ${item.countryCode}`;
    } else if (item.traitName && item.traitDescription) {
      entityType = applicationConfig.modules.trait;
      entity = `${item.traitId} - ${item.traitName}`;
    } else if (item.serviceName) {
      entityType = applicationConfig.modules.service;
      entity = item.serviceName;
    } else if (item.sourceName) {
      entityType = applicationConfig.modules.data_source;
      entity = `${item.pgDataSourceId} - ${item.sourceName}`;
    } else if (item.ecosystemSourceName) {
      entityType = applicationConfig.modules.ecosystem_source;
      entity = `${item.ecosystemSourceId} - ${item.ecosystemName} - ${item.ecosystemSourceName}`;
    }

    return {
      entity,
      entityType,
    };
  };

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    const { entityType, entity } = getEntityType(warning);
    return {
      id: index,
      marketingProgram,
      entityType,
      entity,
      status: applicationConfig.status.warning,
      message: warning.message,
    };
  });

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      if (item.mappingWarning) {
        status = applicationConfig.status.warning;
        message = item.mappingWarning;
      } else if (item.new) {
        status = applicationConfig.status.success;
      } else {
        status = applicationConfig.status.warning;
        message = item.message;
      }
      const { entityType, entity } = getEntityType(item);
      return {
        id: index,
        marketingProgram,
        entityType,
        entity: item.new && entity ? <div>{entity}</div> : entity,
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.marketing_program_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

MarketingProgramRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

MarketingProgramRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
        description: PropTypes.string,
        marketingProgramNumber: PropTypes.number,
      })
    ),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default MarketingProgramRequestOutputModal;
