import traitsModuleConfig from "../../../../config/traitsModuleConfig";

import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateAlphaNumericCharacters,
  validateMinimumCharactersCount,
  validateMaximumCharactersCount,
} from "../../../../utilities/formValidation";

const { createTraitConstants } = traitsModuleConfig;

const validateEcosystemTraits = (traits, ecoSystemsFocused, isDisabled) => {
  const errors = traits.map((trait, index) => {
    const error = {
      ecoTraitNameError: null,
      ecoSystemTraitTypeError: null,
      ecoSystemTraitMiscPropertyError: null,
      ecoTraitNameCharacterLimitError: null,
    };
    if (isDisabled) {
      return error;
    }
    if (
      isEmptyString(trait.ecosystemTraitName) &&
      ecoSystemsFocused[index] &&
      ecoSystemsFocused[index].ecosystemTraitName
    ) {
      error.ecoTraitNameError = i18n.t("errors.field_required");
    }
    if (
      isEmptyString(trait.ecosystemTraitType) &&
      ecoSystemsFocused &&
      ecoSystemsFocused[index].ecosystemTraitType
    ) {
      error.ecoSystemTraitTypeError = i18n.t("errors.field_required");
    }
    if (!isEmptyString(trait.ecosystemTraitName)) {
      if (
        trait.ecosystemTraitName.length >
        parseInt(trait.ecoSystemTraitCharacterLimit, 10)
      ) {
        error.ecoTraitNameCharacterLimitError = i18n.t(
          "new_trait.errors.ecosystem_traits.eco_trait_name.error_1"
        );
      }
      if (
        !validateAlphaNumericCharacters(
          trait.ecosystemTraitName,
          trait.specialCharacters
        )
      ) {
        if (trait.specialCharacters) {
          error.ecoTraitNameError = i18n.t(
            "new_trait.errors.ecosystem_traits.eco_trait_name.error_2",
            {
              characters: trait.specialCharacters,
            }
          );
        } else {
          error.ecoTraitNameError = i18n.t(
            "common.errors.only_letters_numbers"
          );
        }
      } else if (!validateMinimumCharactersCount(trait.ecosystemTraitName, 3)) {
        error.ecoTraitNameError = i18n.t(
          "new_trait.errors.ecosystem_traits.eco_trait_name.error_4"
        );
      } else if (
        !validateMaximumCharactersCount(
          trait.ecosystemTraitName,
          createTraitConstants.characterLimits.ecosystemTraitName
        )
      ) {
        error.ecoTraitNameError = i18n.t(
          "common.errors.character_limit_error",
          {
            count: createTraitConstants.characterLimits.ecosystemTraitName,
          }
        );
      }
    }
    if (!isEmptyString(trait.ecosystemTraitType)) {
      if (!validateAlphaNumericCharacters(trait.ecosystemTraitType, " ")) {
        error.ecoSystemTraitTypeError = i18n.t(
          "new_trait.errors.only_letters_numbers_spaces"
        );
      } else if (!validateMinimumCharactersCount(trait.ecosystemTraitType, 3)) {
        error.ecoSystemTraitTypeError = i18n.t(
          "new_trait.errors.ecosystem_traits.eco_trait_type.error_1"
        );
      } else if (
        !validateMaximumCharactersCount(
          trait.ecosystemTraitType,
          createTraitConstants.characterLimits.ecosystemTraitType
        )
      ) {
        error.ecoSystemTraitTypeError = i18n.t(
          "common.errors.character_limit_error",
          {
            count: createTraitConstants.characterLimits.ecosystemTraitType,
          }
        );
      }
    }
    if (!isEmptyString(trait.ecosystemTraitMiscProperty1)) {
      if (
        !validateAlphaNumericCharacters(
          trait.ecosystemTraitMiscProperty1,
          ` ${trait.ecoSystemDelimeter}`
        )
      ) {
        error.ecoSystemTraitMiscPropertyError = i18n.t(
          "new_trait.errors.only_letters_numbers_spaces"
        );
      } else if (
        !validateMinimumCharactersCount(trait.ecosystemTraitMiscProperty1, 3)
      ) {
        error.ecoSystemTraitMiscPropertyError = i18n.t(
          "new_trait.errors.ecosystem_traits.eco_system_misc_property.error_1"
        );
      } else if (
        !validateMaximumCharactersCount(
          trait.ecosystemTraitMiscProperty1,
          createTraitConstants.characterLimits.ecosystemTraitMiscProperty1
        )
      ) {
        error.ecoSystemTraitMiscPropertyError = i18n.t(
          "common.errors.character_limit_error",
          {
            count:
              createTraitConstants.characterLimits.ecosystemTraitMiscProperty1,
          }
        );
      }
    }
    return error;
  });
  return errors;
};

export default validateEcosystemTraits;
