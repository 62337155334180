import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    MuiPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minWidth: "807px !important",
      maxWidth: "807px !important",
      padding: "20px 25px",
      overflowY: "unset !important",
      "& .MuiTypography-h5": {
        marginBottom: "15px",
        fontSize: "20px",
        color: theme.colorTheme.grey,
        fontWeight: "500",
      },
      "& .MuiDialogContent-root": {
        maxHeight: "810px",
        padding: "1px 0 0 0 !important",
        overflowX: "hidden !important",
      },
      "& .MuiDialogActions-root": {
        padding: "0px !important",
      },
      "& .MuiFormHelperText-contained": {
        margin: "0px !important",
        marginTop: "5px !important",
      },
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
      },
    },
    horizontalBar: {
      height: "1px",
      backgroundColor: theme.colorTheme.greyishWhite,
      marginLeft: "-25px",
      width: "calc(100% + 50px)",
      marginBottom: "14px",
    },
    flexColumn: {
      flexDirection: "column",
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100% !important",
    },
    fieldContainer: {
      justifyContent: "space-between",
      marginBottom: "16px",
      gap: "20px",
      "& > *:first-child": {
        width: "calc(40% - 10px)",
      },
      "& > *:last-child": {
        width: "calc(60% - 10px) !important",
        marginRight: "10px",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "& .MuiSelect-root": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& .MuiFormControl-root": {
        marginRight: "10px",
      },
    },
    checkboxContainer: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    infoIcon: {
      width: 18,
      height: 18,
      marginLeft: "6px",
      position: "relative",
      top: "2px",
      cursor: "pointer",
    },
    alignStart: {
      alignItems: "start",
    },
    btnContainer: {
      minWidth: "200px",
      height: "35px",
      "&:last-child": {
        marginLeft: "20px",
      },
      "& .MuiButton-root": {
        width: "100%",
      },
      "& .MuiButton-contained.Mui-disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
      },
      "& .MuiButton-containedSecondary": {
        color: theme.colorTheme.white,
      },
    },
    closeBtn: {
      cursor: "pointer",
    },
    justifyEnd: {
      justifyContent: "flex-end",
    },
    justifySpaceBetween: {
      justifyContent: "space-between",
    },
    marginLeftAuto: {
      marginLeft: "auto",
    },
    viewDetailsContainer: {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiTypography-h6": {
        fontSize: "14px",
        marginTop: "8px",
      },
      "& .MuiTypography-h6:first-child": {
        marginTop: "0px",
      },
      "& > div:last-child": {
        marginTop: "10px",
        marginLeft: "auto",
        alignSelf: "flex-start",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
