const handleError = ({ error, handle404, addNotification }) => {
  const handleErrorMessage = (err) => {
    if (err.status === 400 && err.data && err.data.error) {
      let errors = err.data.error.message;
      // Show Multiple Errors
      if (err.data.error.errors) {
        errors = [
          ...err.data.error.errors.map((obj, index) =>
            typeof obj === "object"
              ? `${index + 1}. ${obj.msg}`
              : `${index + 1}. ${obj}`
          ),
        ];
      }
      addNotification(errors);
    } else if (err.data && err.data.error) {
      addNotification(err.data.error.message);
    } else if (err.data && err.data.message) {
      addNotification(err.data.message);
    } else {
      addNotification(err.message);
    }
  };
  if (handle404) {
    if (error && error.status === 404) {
      if (
        !typeof handle404 !== "boolean" &&
        {}.toString.call(handle404) === "[object Function]"
      ) {
        handle404();
      }
      return;
    }
  }
  handleErrorMessage(error);
};

export default handleError;
