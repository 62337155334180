import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import getMarketingProramDetails from "../api/get-marketing-program-details";
import i18n from "../i18n/init";

const marketingProgramsModuleConfig = {
  searchFilters: [
    {
      name: "marketingProgramNumber",
      label: i18n.t("common.labels.marketing_program_number"),
    },
    {
      name: "marketingProgramDescription",
      label: i18n.t("common.labels.marketing_program_description"),
    },
    {
      name: "legalEntity",
      label: i18n.t("common.labels.legal_entity"),
    },
    {
      name: "regionCode",
      label: i18n.t("common.labels.geo_region_code"),
    },
  ],
  tableColumns: [
    {
      field: "marketingProgramNumber",
      sortable: false,
      headerName: i18n.t("common.labels.marketing_program_number"),
      flex: 0.5,
      clickable: true,
      clickableCellData: {
        params: ["marketingProgramNumber"],
        api: getMarketingProramDetails,
      },
      disableToggle: true,
    },
    {
      field: "marketingProgramDescription",
      sortable: false,
      headerName: i18n.t("common.labels.marketing_program_description"),
      width: "20%",
    },
    {
      field: "legalEntity",
      sortable: false,
      headerName: i18n.t("common.labels.legal_entity"),
      width: "20%",
      parseData: (row) => `${row.legalEntityNumber} - ${row.legalEntityName}`,
    },
    {
      field: "geoRegionCode",
      sortable: false,
      headerName: i18n.t("common.labels.geo_region_code"),
      width: "20%",
      renderHeader: () => {
        return (
          <>
            <span
              style={{
                fontWeight: "500",
              }}
            >
              {i18n.t("common.labels.geo_region_code")}
            </span>
            <Tooltip
              style={{ marginLeft: "10px" }}
              title={i18n.t("constants.geo_region_code_tooltip")}
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "countryCodes",
      sortable: false,
      headerName: i18n.t("common.labels.country_code"),
      width: "20%",
      parseData: (row) => row.countryCodes.split(",").join(", "),
    },
    {
      field: "mpBusinessName",
      sortable: false,
      headerName: i18n.t("common.labels.business_name"),
      width: "20%",
    },
    {
      field: "brandName",
      sortable: false,
      headerName: i18n.t("common.labels.brand_name"),
      width: "20%",
    },
    {
      field: "marketingProgramStatus",
      sortable: false,
      headerName: i18n.t("common.labels.marketing_program_status"),
      width: "20%",
    },
  ],
  requestStatus: {
    APPROVED: i18n.t("status.approved"),
    REJECTED: i18n.t("status.rejected"),
    PENDING: i18n.t("status.pending"),
    RETURNED: i18n.t("status.returned"),
    PROCESSING: i18n.t("status.processing"),
  },
  searchDefaultFilterState: {
    marketingProgramNumber: false,
    marketingProgramDescription: false,
    legalEntity: false,
  },
  marketingProgramsRequestsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "marketingProgramName",
      label: i18n.t("common.labels.marketing_program"),
    },
  ],
  marketingProgramsApprovalsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "marketingProgramName",
      label: i18n.t("common.labels.marketing_program"),
    },
    {
      name: "requester",
      label: i18n.t("common.labels.requester"),
    },
  ],
  newMarketingProgramConstants: {
    footerText: {
      1: i18n.t("common.next"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
  },
  createMarketingProgramConstants: {
    creationHeading: i18n.t("common.labels.new_marketing_program"),
    revisionHeading: i18n.t(
      "marketing_programs_container.revision.headings.step_1"
    ),
    creationMessages: {
      1: i18n.t("marketing_programs_container.steps.step_1"),
      2: i18n.t("marketing_programs_container.steps.step_2"),
      3: i18n.t("common.texts.request_details"),
    },
    revisionMessages: {
      1: i18n.t("marketing_programs_container.revision.steps.step_1"),
      2: i18n.t("marketing_programs_container.revision.steps.step_2"),
      3: i18n.t("marketing_programs_container.revision.steps.step_3"),
    },
    footerText: {
      1: i18n.t("common.labels.proceed_to_mapping"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
    businessOrgUnits: [
      "Corporate",
      "Beauty",
      "Fem Care",
      "Personal Health Care",
      "Hair Care",
      "Grooming",
      "Home Care",
      "Oral Care",
      "Historical",
      "Baby Care",
      "Family Care",
      "Fabric Care",
      "Skin Care",
      "Multibrand",
    ],
    channels: {
      contactPointTypeOptions: [
        {
          contact_point_type_code: i18n.t("constants.channel_codes.a"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.postal_address"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.residential_address"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.e"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.email_address"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.personal_email"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.p"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.phone_number"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.mobile_phone"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.s"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.social_address"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.line"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.w"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.website_pull"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.pull_channel"
          ),
        },
      ],
    },
  },
  mpEcosystemMappingConstants: {
    mpEcosystemKeys: [
      {
        id: "ecosystemId",
        name: i18n.t("mp_ecosystem_mapping_container.ecosystem_id"),
      },
      {
        id: "ecosystemName",
        name: i18n.t("mp_ecosystem_mapping_container.ecosystem_name"),
      },
      {
        id: "ecosystemMarketingProgramInstance",
        name: i18n.t("mp_ecosystem_mapping_container.ecosystem_instance"),
      },
      {
        id: "ecosystemMarketingProgramKey",
        name: i18n.t("mp_ecosystem_mapping_container.ecosystem_mp_key"),
      },
      {
        id: "ecosystemMarketingProgramName",
        name: i18n.t("mp_ecosystem_mapping_container.ecosystem_mp_name"),
      },
      {
        id: "automationEnabled",
        name: i18n.t("mp_ecosystem_mapping_container.automation_enabled"),
      },
    ],
    yesOrNoOptions: [
      i18n.t("constants.yes_or_no_options.yes"),
      i18n.t("constants.yes_or_no_options.no"),
    ],
    ecosystemNames: {
      SEGMENT: "segment",
      LYTICS: "lytics",
      MAPP: "mapp",
      JANRAIN: "janrain",
    },
    ecosystemInstances: {
      segment: {
        PRD: [
          "pg-asia-prod",
          "pg-americas-prod",
          "pg-europe-prod",
          "pampersrewards",
        ],
        DEV: ["pg-global-dev"],
        STG: ["pg-global-qa"],
      },
      janrain: ["us", "eu", "ru"],
    },
    creationHeadings: {
      1: i18n.t("mp_ecosystem_mapping_container.headings.step_1"),
      2: i18n.t("mp_ecosystem_mapping_container.headings.step_2"),
      3: i18n.t("mp_ecosystem_mapping_container.headings.step_3"),
    },
    creationMessages: {
      1: i18n.t("mp_ecosystem_mapping_container.steps.step_1"),
      2: i18n.t("mp_ecosystem_mapping_container.steps.step_2"),
      3: i18n.t("common.texts.request_details"),
    },
    revisionHeadings: {
      1: i18n.t("mp_ecosystem_mapping_container.revision.headings.step_1"),
      2: i18n.t("mp_ecosystem_mapping_container.revision.headings.step_2"),
      3: i18n.t("mp_ecosystem_mapping_container.revision.headings.step_3"),
    },
    revisionMessages: {
      1: i18n.t("mp_ecosystem_mapping_container.revision.steps.step_1"),
      2: i18n.t("mp_ecosystem_mapping_container.revision.steps.step_2"),
      3: i18n.t("mp_ecosystem_mapping_container.revision.steps.step_3"),
    },
    footerText: {
      1: i18n.t("common.labels.proceed_to_confirmation"),
      2: i18n.t("common.labels.confirm_and_submit"),
    },
    channels: {
      contactPointTypeOptions: [
        {
          contact_point_type_code: i18n.t("constants.channel_codes.a"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.postal_address"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.residential_address"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.e"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.email_address"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.personal_email"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.p"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.phone_number"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.mobile_phone"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.s"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.social_network"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.line"
          ),
        },
        {
          contact_point_type_code: i18n.t("constants.channel_codes.w"),
          contact_point_type_name: i18n.t(
            "constants.channel_options.website_pull"
          ),
          default_contact_point_category_name: i18n.t(
            "constants.channel_options.pull_channel"
          ),
        },
      ],
    },
    tooltips: {
      ecosystem_mp_name: {
        mapping: {
          segment: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_name.segment"
          ),
          lytics: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_name.lytics"
          ),
          mapp: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_name.mapp"
          ),
          janrain: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_name.janrain"
          ),
        },
      },
      ecosystem_mp_key: {
        mapping: {
          segment: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_key.segment"
          ),
          lytics: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_key.lytics"
          ),
          mapp: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_key.mapp"
          ),
          janrain: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_mp_key.janrain"
          ),
        },
      },
      ecosystem_instance: {
        mapping: {
          segment: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_instance.segment"
          ),
          lytics: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_instance.lytics"
          ),
          mapp: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_instance.mapp"
          ),
          janrain: i18n.t(
            "mp_ecosystem_mapping_container.constants.ecosystem_instance.janrain"
          ),
        },
      },
    },
  },
  requestsConstants: {
    toolTipTextPending: i18n.t("traits_requests.tooltip.pending_status"),
    toolTipTextApproved: i18n.t("traits_requests.tooltip.approved_status"),
    toolTipTextReturned: i18n.t("traits_requests.tooltip.returned_status"),
    toolTipTextProcessing: i18n.t("traits_requests.tooltip.processing_status"),
  },
};

export default marketingProgramsModuleConfig;
