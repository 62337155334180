import { useState, useEffect } from "react";

import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Select,
  Tabs,
  Tab,
  InputLabel,
  Button,
  Box,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import useAccordionStyles from "../../../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../../../components/AccordionRowDetails";

import CustomModal from "../../../../../components/CustomModal";
import applicationConfig from "../../../../../config/applicationConfig";
import eventsModuleConfig from "../../../../../config/eventsModuleConfig";

import useStyles from "./styles";

const EventsModal = ({ title, subtitle, data, loading, open, onClose }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const accordionClasses = useAccordionStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [marketingProgram, setMarketingProgram] = useState("");
  const [expanded, setExpanded] = useState(false);

  const { properties, marketingProgramEventProperties } = data;
  const { eventPropertiesKeys } = eventsModuleConfig;

  const accordionRowDetails = [
    {
      id: "propertyName",
      name: t("use_events_container.property_name"),
    },
    {
      id: "description",
      name: t("common.labels.description"),
    },
    {
      id: "dataType",
      name: t("use_events_container.accordion_details.dataType"),
    },
    {
      id: "valueTypes",
      name: t("use_events_container.accordion_details.valueTypes"),
    },
  ];

  const toggleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setTab(0);
    if (marketingProgramEventProperties?.length > 0) {
      setMarketingProgram(
        marketingProgramEventProperties[0].marketingProgramNumber
      );
    }
  }, [title]);

  const mpEventProperties = marketingProgramEventProperties?.find(
    (marketing) => marketing.marketingProgramNumber === marketingProgram
  )?.eventProperties;

  const renderUi = () => {
    if (tab === 1) {
      return (
        <>
          {marketingProgramEventProperties?.length > 0 ? (
            <FormControl fullWidth className={classes.accordionSelect}>
              <InputLabel id="demo-simple-select-label">
                Marketing Program
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={marketingProgram}
                label="Marketing Program"
                defaultValue={marketingProgram}
                onChange={(e) => setMarketingProgram(e.target.value)}
              >
                {marketingProgramEventProperties.map(
                  ({ marketingProgramNumber, description }) => (
                    <MenuItem
                      value={marketingProgramNumber}
                      key={marketingProgramNumber}
                    >
                      {marketingProgramNumber} - {description}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          ) : (
            <Alert severity="info">
              No Marketing Programs mapped to the Event
            </Alert>
          )}
          <div className={accordionClasses.systems}>
            {mpEventProperties?.map((row, i) => {
              const id = `system-${i.toString()}`;
              return (
                <Accordion
                  key={i.toString()}
                  className={accordionClasses.accordion}
                  expanded={expanded === id}
                  onChange={toggleAccordion(id)}
                >
                  <AccordionSummary
                    data-testid="accordion-summary"
                    className={accordionClasses.accordionHeader}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className={accordionClasses.systemType}>
                      {row.eventPropertyValyeType.join(",")}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={accordionClasses.systemName}
                    >
                      {row.eventProperty}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    className={accordionClasses.accordionContent}
                  >
                    <AccordionRowDetails
                      keys={accordionRowDetails}
                      row={[
                        {
                          propertyName: row.eventProperty,
                          description: row.eventPropertyDescription,
                          dataType: row.eventPropertyDataType,
                          valueTypes: row.eventPropertyValyeType.join(","),
                        },
                      ]}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </>
      );
    }
    return (
      <AccordionModalDetails
        keys={eventPropertiesKeys}
        rows={properties}
        rowKeys={{
          title: "propertyName",
          subTitle: "description",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      data={{
        eventProperties: properties,
        marketingPrograms: mpEventProperties,
      }}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <Box sx={{ mb: 2 }}>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            classes={{
              indicator: globalClasses.indicator,
            }}
            aria-label="basic tabs"
          >
            <Tab label="Event Properties" />
            <Tab label="Marketing Programs" />
          </Tabs>
        </Box>
        {renderUi()}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            disabled={loading}
            component={Link}
            to={{
              pathname: applicationConfig.pathnames.events_map,
              state: {
                eventName: data.eventName,
              },
            }}
            onClick={() => {}}
            className={globalClasses.btn}
          >
            {t("manage_events.use")}
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            component={Link}
            to={{
              pathname: applicationConfig.pathnames.events_manage,
              state: {
                event: data,
              },
            }}
            onClick={() => {}}
            className={globalClasses.btn}
          >
            {t("manage_events.manage_events")}
          </Button>
        </Box>
      </div>
    </CustomModal>
  );
};
EventsModal.defaultProps = {
  title: null,
};

EventsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    eventName: PropTypes.string.isRequired,
    properties: PropTypes.shape([]).isRequired,
    marketingProgramEventProperties: PropTypes.shape([]).isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EventsModal;
