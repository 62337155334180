import React from "react";

import {
  Fade,
  Modal,
  Paper,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";

import { ReactComponent as DownloadIcon } from "../../assets/images/download.svg";
import { lightTheme } from "../../assets/styles/themes";
import exportToExcel from "../../utilities/exportExcel";

import useStyles from "./styles";

const CustomModal = ({
  open,
  onClose,
  title,
  subtitle,
  children,
  showCloseIcon,
  loading,
  data,
  exportColumnsToExclude,
  contentClassName,
  showArrayValueInNewSheet,
  customizeData,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.container}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={clsx(classes.content, contentClassName)}>
          <div className={classes.flexContainer}>
            {!loading && title && (
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            )}
            {!loading && (
              <div className={classes.flexContainer}>
                {data && (
                  <Box
                    mr={1}
                    onClick={async () => {
                      try {
                        exportToExcel(
                          data,
                          exportColumnsToExclude,
                          title,
                          true,
                          showArrayValueInNewSheet,
                          customizeData
                        );
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    style={{
                      color: lightTheme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title="Export as Excel">
                      <DownloadIcon />
                    </Tooltip>
                  </Box>
                )}
                {showCloseIcon && (
                  <div
                    className={classes.closeBtn}
                    onClick={() => {
                      onClose();
                    }}
                    onKeyDown={() => null}
                    role="button"
                    tabIndex={0}
                    data-testid="close-btn"
                  >
                    <Close />
                  </div>
                )}
              </div>
            )}
          </div>
          {!loading && subtitle && (
            <Typography
              variant="body2"
              gutterBottom
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          )}
          {!loading ? (
            children
          ) : (
            <div style={{ padding: "8px" }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              <br />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              <br />
              {Array.from({ length: 5 }).map((_, i) => (
                <React.Fragment key={i}>
                  <Skeleton variant="rect" width={640} height={60} />
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showCloseIcon: PropTypes.bool,
  loading: PropTypes.bool,
  contentClassName: PropTypes.string,
  data: PropTypes.shape({
    [PropTypes.string]: PropTypes.any,
  }).isRequired,
  exportColumnsToExclude: PropTypes.arrayOf(PropTypes.string).isRequired,
  showArrayValueInNewSheet: PropTypes.func,
  customizeData: PropTypes.func,
};

CustomModal.defaultProps = {
  subtitle: "",
  title: "",
  showCloseIcon: false,
  loading: false,
  contentClassName: "",
  showArrayValueInNewSheet: () => false,
  customizeData: (sheetsData) => sheetsData,
};

export default CustomModal;
