import api from "../utilities/api";
import { getValue } from "../utilities/cache";
import isEmpty from "../utilities/isEmpty";

const getTraits = async (queryParams) => {
  let url = "/traits";
  const refreshCache = getValue("traits");
  if (!isEmpty(queryParams)) {
    url += `${queryParams}&refreshCache=${refreshCache}`;
  } else {
    url += `?refreshCache=${refreshCache}`;
  }
  url += "&schemaVersion=v2";
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getTraits;
