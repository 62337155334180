import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    newOptionTag: {
      backgroundColor: "#1976d2",
      color: "white",
      marginLeft: "12px",
      "&:hover": {
        backgroundColor: "#1976d2",
        color: "white",
      },
    },
    labelsInput: {},
    newLabelContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& >:first-child": {
        marginRight: "16px",
        width: "20%",
      },
      "& > div": {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      },
    },
    eventPropertyModalFooter: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "30px",
    },
    eventPropertyModalField: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
      "& > p.MuiTypography-root.MuiTypography-body1": {
        whiteSpace: "nowrap",
      },
    },
    addTraitBtn: {
      "& .MuiButton-label": {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
    },
    noOptionsContainer: {
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    labelsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .MuiChip-root.MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
      "& .MuiAvatar-root": {
        fontSize: "8px",
        color: "white",
        backgroundColor: "#115293",
      },
      "& .MuiChip-deleteIcon": {
        color: "unset",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    accordionContent: {
      padding: 16,
      paddingBottom: 0,
      borderTop: `1px solid ${theme.colorTheme.greyishWhite}`,
      background: theme.colorTheme.white1,
      margin: "0 !important",
      display: "flex",
      flexDirection: "column",
    },
    fullWidth: {
      width: "100%",
    },
    statusLabel: {
      marginLeft: "auto",
    },
    deleted: {
      backgroundColor: theme.palette.error.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
  };
});

export default useStyles;
