import { useCallback, useEffect, useRef, useState } from "react";

import getTraits from "../api/get-traits";
import applicationConfig from "../config/applicationConfig";
import debounce from "../utilities/debounce";
import handleError from "../utilities/handleError";

import useNotifier from "./useNotifier";

function useTraits() {
  const { addNotification } = useNotifier();
  const [traitOptions, setTraitOptions] = useState([]);
  const [initialTraitOptions, setInitialTraitOptions] = useState([]);
  const [traitsLoading, setTraitsLoading] = useState(false);

  const debouncedTraitsFromApiRef = useRef(null);

  const getTraitsFromApi = useCallback(
    async (searchText) => {
      let filter = {};
      if (searchText && searchText.length > 0) {
        filter = {
          searchText,
          searchFields: "traitId,traitName,description",
        };
      }
      try {
        const rsp1 = await getTraits(
          `?page=1&itemsPerPage=3&${new URLSearchParams(filter).toString()}`
        );
        setTraitOptions([...rsp1.items]);
        if (searchText.length === 0) {
          setInitialTraitOptions([...rsp1.items]);
        }
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setTraitOptions([]);
          },
          addNotification,
        });
      } finally {
        setTraitsLoading(false);
      }
    },
    [addNotification]
  );

  useEffect(() => {
    debouncedTraitsFromApiRef.current = debounce(
      getTraitsFromApi,
      applicationConfig.waitTime
    );
  }, [getTraitsFromApi]);

  return {
    getTraitsFromApi: debouncedTraitsFromApiRef.current,
    initialTraitOptions,
    traitOptions,
    traitsLoading,
    setTraitsLoading,
    setTraitOptions,
  };
}

export default useTraits;
