const mergeArrays = (array1, array2, key) => {
  const mergedMap = new Map();
  array1.forEach((item) => {
    const keyVal = item[key];
    if (!mergedMap.has(keyVal)) {
      mergedMap.set(keyVal, item);
    }
  });

  array2.forEach((item) => {
    const keyVal = item[key];
    if (!mergedMap.has(keyVal)) {
      mergedMap.set(keyVal, item);
    }
  });

  return Array.from(mergedMap.values());
};

export default mergeArrays;
