import { useState, useRef } from "react";

import { Paper, Button, Typography, ButtonGroup } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import getSources from "../../../api/get-sources";

import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import sourcesModuleConfig from "../../../config/sourcesModuleConfig";

import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import SourceIdModal from "../components/SourceIdModal";

import useStyles from "./styles";

const SourcesContainer = () => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const childRef = useRef();

  const {
    sourcesTableColumns,
    sourceSearchFilters,
    // ecosystemFeatureKeys,
    sourcesSearchDefaultFilterState,
  } = sourcesModuleConfig;
  // const [openDropdown, setOpenDropdown] = useState(false);
  // const anchorRef = useRef(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { setRequest } = useRequest();
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});

  const [cellData, setCellData] = useState({
    data: [],
    field: "sourceId",
    row: {},
  });

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchSources
  );

  const removeSourceIds = (string) => {
    if (string.indexOf("("))
      return `${string.slice(0, string.indexOf("("))}...`;
    return string;
  };

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: {},
      field: "sourceId",
      row: {},
    });
  };

  const renderClickableCell = (params) => {
    let content = params.value;
    if (!content) {
      return (
        <div data-testid="clickable-cell" className={classes.clickableCell} />
      );
    }
    if (typeof content === "string" && !Array.isArray(content)) {
      content = content.split(",");
    }

    if (params.field === applicationConfig.common.ecosystem_source_names) {
      if (content.length > 0 && !content[0].includes("...")) {
        content[0] = removeSourceIds(content[0]);
      }
    }

    if (content.length >= 2) {
      const noOfItems = content.length - 1;
      content = content.slice(0, 1);

      if (noOfItems > 0) {
        if (
          params.field !== applicationConfig.common.ecosystem_source_names &&
          !content[0].includes("...")
        ) {
          content[0] += "...";
        }
        content[0] += `+${noOfItems}`;
      }
    }

    return (
      <div data-testid="clickable-cell" className={classes.clickableCell}>
        {Array.isArray(content) ? content.join("\n\n") : content}
      </div>
    );
  };

  const renderDescriptionCell = (params) => {
    const { description } = params.row;
    // const info = sources.find((source) => source.sourceId === sourceId);
    return (
      <StyledTooltip placement="top" title={<div>{description}</div>}>
        <div>{params.value}</div>
      </StyledTooltip>
    );
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const filterFieldMap = {
    sourceId: "sourceId",
    sourceName: "sourceName",
    url: "url",
    marketingProgram: "marketingProgram",
    "": "searchText",
  };

  const getAllowedColumns = () => {
    const columns = [...sourcesTableColumns];
    return columns.map((data) => {
      const columnConfig = {
        ...data,
        sortable: false,
        editable: false,
        disableClickEventBubbling: true,
      };
      if (
        data.field === applicationConfig.common.ecosystem_source_names ||
        data.field === applicationConfig.common.marketing_programs ||
        data.field === applicationConfig.common.sourceId
      ) {
        columnConfig.renderCell = renderClickableCell;
      }

      if (data.field === applicationConfig.common.description) {
        columnConfig.renderCell = renderDescriptionCell;
      }

      return columnConfig;
    });
  };

  return !isUserAuthorized && !user.loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("sources_container.sources_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Sources",
              onClick: () => history.push("/sources"),
            },
          ]}
        />
        <div className={clsx(classes.marginLeftAuto, classes.flexContainer)}>
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createSources
          ) && (
            <div className={classes.btnContainer}>
              <ButtonGroup variant="contained" color="primary">
                <Button
                  component={Link}
                  to={{
                    pathname: applicationConfig.pathnames.sources_new,
                  }}
                  onClick={() => setRequest({})}
                >
                  {t("sources_container.create_source")}
                </Button>
              </ButtonGroup>
            </div>
          )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={sourceSearchFilters}
            defaultFilterState={sourcesSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="sources-container" elevation={0}>
        <div className={classes.sourcesTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getSources(
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="sources"
            filters={filters}
            columns={getAllowedColumns()}
            openModal={() => setDrawerOpen(true)}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) => {
              if (field === "ecosystemSourceNames") {
                setCellData({
                  data: data.map((item) => ({
                    ...item,
                    ecosystemSourceTruncatedName: removeSourceIds(
                      item.ecosystemSourceName
                    ),
                  })),
                  field,
                  row,
                });
              } else {
                setCellData({
                  data,
                  field,
                  row,
                });
              }
            }}
          />
        </div>
      </Paper>
      {cellData.field === "sourceId" && drawerOpen && (
        <SourceIdModal
          title={`${t("common.data_source_id")} - ${cellData.row.sourceId}`}
          subtitle={t("sources_container.source_id_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
          type="sources"
        />
      )}
    </Layout>
  );
};

export default SourcesContainer;
