import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    tabsContainer: {
      "& .MuiTabs-indicator": {
        bottom: "unset",
        backgroundColor: theme.palette.blue.darker,
      },
    },
    tab: {
      "&.Mui-selected": {
        borderBottom: `2px solid ${theme.palette.blue.main}`,
      },
    },
  };
});

export default useStyles;
