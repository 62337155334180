import i18n from "../../../i18n/init";

const getFields = (optsInfo) => {
  const fields = [
    {
      label: i18n.t("common.labels.marketing_program"),
      type: "dropdown",
      props: {
        value: optsInfo.marketingProgram,
      },
    },
    {
      label: i18n.t("common.labels.ecosystems"),
      type: "dropdown",
      props: {
        value: optsInfo.ecosystems,
      },
    },
    {
      label: i18n.t("common.labels.channel"),
      type: "dropdown",
      props: {
        value: optsInfo.channel.contact_point_type_name,
      },
    },
    {
      label: i18n.t("opts_status_trait.status_trait_name_opt_indicator"),
      type: "text-input",
      props: {
        value: optsInfo.statusTraitNameOptIndicator,
      },
    },
    {
      label: i18n.t("opts_status_trait.status_trait_name_opt_choice_date"),
      type: "text-input",
      props: {
        value: optsInfo.statusTraitNameOptChoiceDate,
      },
    },
  ];
  return fields;
};

export default getFields;
