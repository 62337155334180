import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    traitsTableContainer: {
      "& .MuiDataGrid-colCellWrapper": {
        backgroundColor: theme.colorTheme.bluishWhite,
      },
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    btnContainer: {
      minWidth: "102px",
      height: "35px",
      "& .MuiButtonBase-root:disabled": {
        backgroundColor: "red",
      },
      "& .MuiButton-containedPrimary": {
        height: "100%",
        display: "flex",
        alignItems: "end",
      },
      "& > div:nth-child(2)": {
        zIndex: "100",
      },
      "& button:last-child": {
        width: "40px !important",
      },
    },
    mb10: {
      marginBottom: "10px",
    },
  };
});

export default useStyles;
