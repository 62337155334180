import { useState, useRef, useEffect } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import useGlobalStyles from "../../../../assets/styles/global";

import useAccordionStyles from "../../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CustomModal from "../../../../components/CustomModal";

import consentsModuleConfig from "../../../../config/consentsModuleConfig";
import languages from "../../../../config/languages";

import replaceValuesWithPlaceholders from "../helpers/replaceValuesWithPlaceholders";
import updateQuillContent from "../helpers/updateQuillContext";

const ReactQuill = require("react-quill");

const TemplateTexts = ({
  updatedConsentTexts,
  isDisabled,
  templateVariablesValues,
  onSubmit,
}) => {
  const { consentTemplateKeys } = consentsModuleConfig;
  const accordionClasses = useAccordionStyles();
  const [showModal, setShowModal] = useState(false);
  const consentInfo = useRef();
  const globalStyles = useGlobalStyles();
  const [consentTextMap, setConsentTextMap] = useState(updatedConsentTexts);
  const [openOnCloseWarningModal, setOpenOnCloseWarningModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setConsentTextMap(updatedConsentTexts);
  }, [updatedConsentTexts]);

  return Object.keys(updatedConsentTexts)?.map((consentTextKey) => {
    const languageCode = consentTextKey.split("-")[2];
    const language = languages.find(
      (l) => l.languageCode === languageCode.toLowerCase()
    )?.languageName;
    const { consentText, noticeText } = updatedConsentTexts[consentTextKey];
    const consentTextFilledWithVariables = updateQuillContent(
      consentText || "",
      templateVariablesValues
    );
    const noticeTextFilledWithVariables = updateQuillContent(
      noticeText || "",
      templateVariablesValues
    );
    return (
      <div>
        <div
          className={clsx(globalStyles.flexContainer)}
          style={{ paddingBottom: 0 }}
        >
          <Accordion
            key={consentTextKey}
            className={clsx(accordionClasses.accordion)}
          >
            <AccordionSummary
              data-testid="accordion-summary"
              className={accordionClasses.accordionHeader}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={accordionClasses.systemType}>
                {language}
              </Typography>
              <Typography
                variant="body2"
                className={accordionClasses.systemName}
                dangerouslySetInnerHTML={{
                  __html: consentText,
                }}
              />
            </AccordionSummary>
            <AccordionDetails className={accordionClasses.accordionContent}>
              <AccordionRowDetails
                keys={consentTemplateKeys}
                row={{
                  consentText: consentTextFilledWithVariables,
                  noticeText: noticeTextFilledWithVariables,
                }}
              />
            </AccordionDetails>
          </Accordion>
          {!isDisabled && (
            <IconButton
              className={globalStyles.editButton}
              style={{
                width: 45,
                height: 45,
                position: "relative",
                bottom: "10px",
              }}
              onClick={() => {
                consentInfo.current = {
                  consentTextKey,
                };
                setShowModal(true);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
        <CustomModal
          open={showModal}
          onClose={() => {
            setOpenOnCloseWarningModal(true);
          }}
          title={consentInfo.current?.consentTextKey}
          subtitle="Consent Text & Notice Text is listed below"
          showCloseIcon
        >
          <div style={{ marginBottom: 25 }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Consent Text
            </Typography>
            <ReactQuill
              theme="snow"
              value={updateQuillContent(
                consentTextMap[consentInfo.current?.consentTextKey]
                  ?.consentText || "",
                templateVariablesValues
              )}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              onKeyUp={(e) => {
                e.preventDefault();
              }}
              onChange={(value, delta, source) => {
                const res = replaceValuesWithPlaceholders(
                  value,
                  consentTextMap[consentInfo.current?.consentTextKey]
                    ?.consentText || "",
                  templateVariablesValues
                );
                if (
                  (delta.ops || []).filter(
                    (ops) =>
                      Object.keys(ops).filter(
                        (key) => key !== "retain" && key !== "attributes"
                      ).length > 0
                  ).length > 0
                ) {
                  if (source === "user") {
                    setConsentTextMap({
                      ...consentTextMap,
                    });
                  }
                } else {
                  setConsentTextMap((ct) => ({
                    ...ct,
                    [consentInfo.current?.consentTextKey]: {
                      ...consentTextMap[consentInfo.current?.consentTextKey],
                      consentText: res,
                    },
                  }));
                }
              }}
              readOnly={isDisabled}
              modules={{
                toolbar: [
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                ],
              }}
            />
          </div>
          <div>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Notice Text
            </Typography>
            <ReactQuill
              theme="snow"
              value={updateQuillContent(
                consentTextMap[consentInfo.current?.consentTextKey]
                  ?.noticeText || "",
                templateVariablesValues
              )}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              onKeyUp={(e) => {
                e.preventDefault();
              }}
              onChange={(value, delta, source) => {
                const res = replaceValuesWithPlaceholders(
                  value,
                  consentTextMap[consentInfo.current?.consentTextKey]
                    ?.noticeText || "",
                  templateVariablesValues
                );
                if (
                  (delta.ops || []).filter(
                    (ops) =>
                      Object.keys(ops).filter(
                        (key) => key !== "retain" && key !== "attributes"
                      ).length > 0
                  ).length > 0
                ) {
                  if (source === "user") {
                    setConsentTextMap({
                      ...consentTextMap,
                    });
                  }
                } else {
                  setConsentTextMap((ct) => ({
                    ...ct,
                    [consentInfo.current?.consentTextKey]: {
                      ...consentTextMap[consentInfo.current?.consentTextKey],
                      noticeText: res,
                    },
                  }));
                }
              }}
              readOnly={isDisabled}
              modules={{
                toolbar: [
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                ],
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              classes={{
                root: globalStyles.btn,
              }}
              color="primary"
              onClick={() => {
                onSubmit(consentTextMap);
                setShowModal(false);
              }}
              style={{ marginTop: 20 }}
            >
              Submit
            </Button>
          </div>
        </CustomModal>
        {openOnCloseWarningModal && (
          <ConfirmationModal
            open={openOnCloseWarningModal}
            onClose={() => setOpenOnCloseWarningModal(false)}
            title={t("dialogs.confirm_remove")}
            message="Are you sure you want to close? Any Unsaved changes will be lost."
            btn1Text={t("common.cancel")}
            btn2Text={t("common.ok")}
            btn2Action={async () => {
              consentInfo.current = undefined;
              setConsentTextMap(updatedConsentTexts);
              setShowModal(false);
              setOpenOnCloseWarningModal(false);
            }}
            type="error"
          />
        )}
      </div>
    );
  });
};

TemplateTexts.propTypes = {
  updatedConsentTexts: PropTypes.objectOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool,
  templateVariablesValues: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default TemplateTexts;
