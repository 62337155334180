import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { requestIdPrefix } = applicationConfig;

const getRequestDetails = async (requestId) => {
  const updatedRequestId = requestId.replace(requestIdPrefix, "");
  const url = `/requests/${updatedRequestId}`;
  const {
    data: { data },
  } = await api.get(url);
  data.requestId = `${requestIdPrefix}${data.requestId}`;
  return data;
};

export default getRequestDetails;
