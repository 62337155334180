const createBackendPayload = (
  eventName,
  events,
  marketingPrograms,
  ecosystems
) => {
  return {
    eventName,
    eventProperties: events.map((event) => {
      return {
        propertyName: event.eventProperty.propertyName,
        dataType: event.eventProperty.dataType,
        description: event.eventProperty.description,
        valueType: event.valueTypes,
      };
    }),
    marketingPrograms: marketingPrograms.map((program) => ({
      marketingProgramNumber: program.marketingProgramNumber,
      description: program.description,
    })),
    ecosystems: ecosystems.map((system) => ({
      ecosystemId: system.ecosystemId,
      ecosystemName: system.ecosystemName,
    })),
  };
};

export default createBackendPayload;
