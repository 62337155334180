const parseDataFromTableConfig = (data, config) => {
  const parsedData = [];
  data.forEach((row, index) => {
    const parsedRow = {
      id: index,
    };

    config.forEach((configField) => {
      if (configField.parseData) {
        parsedRow[configField.field] = configField.parseData(row);
      } else {
        parsedRow[configField.field] = row[configField.field];
      }
    });

    Object.keys(row)
      .filter((key) => !(key in parsedRow))
      .forEach((extraKey) => {
        parsedRow[extraKey] = row[extraKey];
      });

    parsedData.push(parsedRow);
  });
  return parsedData;
};

export default parseDataFromTableConfig;
