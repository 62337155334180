import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import DCFUseCases from "./DCFUseCase";
import NewUseCaseContainer from "./NewUseCaseContainer";
import UseCaseManagementContainer from "./UseCaseManagementContainer";

const UseCaseManagementModule = ({ basePath }) => {
  return (
    <Switch>
      <Route
        exact
        path={basePath}
        component={({ location: { search } }) => {
          if (
            search.includes(applicationConfig.modules.dcf_use_cases) &&
            applicationConfig.deploymentEnv !== "prd"
          ) {
            return <DCFUseCases />;
          }
          return <UseCaseManagementContainer />;
        }}
      />
      <Route
        exact
        path={`${basePath}/manage`}
        render={() => <NewUseCaseContainer />}
      />
      <Route
        exact
        path={`${basePath}/revision`}
        render={() => <NewUseCaseContainer />}
      />
      <Route exact path={`${basePath}/*`} render={() => <Redirect to="/" />} />
    </Switch>
  );
};

UseCaseManagementModule.defaultProps = {
  basePath: applicationConfig.basePaths.use_cases,
};

UseCaseManagementModule.propTypes = {
  basePath: PropTypes.string,
};

export default UseCaseManagementModule;
