import { useContext } from "react";

import { RequestContext } from "../providers/RequestProvider";

const useRequest = () => {
  const { request, removeRequest, setRequest } = useContext(RequestContext);
  return { request, removeRequest, setRequest };
};

export default useRequest;
