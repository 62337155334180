import { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../components/RequestOutputModal";
import StatusBadge from "../../../../components/StatusBadge";
import Table from "../../../../components/Table";

const EventRequestOutputModal = ({
  setShowOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "eventName",
      headerName: t("common.labels.event_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "propertyName",
      headerName: t("create_event.create_event_property.property_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const rows = useMemo(() => {
    return (
      data?.items?.map((item) => {
        const row = {
          id: `${item.eventName}${item.propertyName}`,
          eventName: item.eventName,
          propertyName: item.propertyName,
          message: item.mappingWarning,
        };
        if (item.mappingWarning) {
          row.status = "warning";
        } else if (item.new) {
          row.status = "success";
        } else {
          row.status = "";
        }
        return row;
      }) || []
    );
  }, [data]);

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.event_output_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

EventRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

EventRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.objectOf({
    items: PropTypes.shape({
      dataType: PropTypes.string,
      description: PropTypes.string,
      eventName: PropTypes.string,
      mappingWarning: PropTypes.string,
      new: PropTypes.number,
      parentPropertyName: PropTypes.string,
      propertyName: PropTypes.string,
      sampleValues: PropTypes.string,
      subPropertyName: PropTypes.string,
    }),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EventRequestOutputModal;
