import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.palette.text.primary,
      },
      "& > *": {
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    justifyContent: {
      justifyContent: "space-between",
    },
  };
});

export default useStyles;
