import PropTypes from "prop-types";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import Table from "../../../../../components/Table";

import optsMappingTableColumns from "../../optsMappingTableColumns";

import useStyles from "./styles";

const OptsMappingModal = ({
  title,
  isOpen,
  mappings,
  setOpenOptMappingsModal,
}) => {
  const rows = mappings.map((mapping, index) => ({
    optId: mapping.ciamOptId,
    id: `${mapping.id}-${index}`,
    optText: mapping.optTextEnglish,
    subId: mapping.subscriptionOptNumber,
    serviceName: mapping.serviceName,
    channelType: mapping.contactPointTypeName,
  }));

  const classes = useStyles();

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setOpenOptMappingsModal(false)}
      title={title}
      isLoading={false}
    >
      <Table
        columns={optsMappingTableColumns}
        rows={rows}
        tableStyle={classes.myRequestsTable}
      />
    </RequestOutputModal>
  );
};

OptsMappingModal.defaultProps = {
  isOpen: false,
  mappings: [],
  setOpenOptMappingsModal: () => {},
};

OptsMappingModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  mappings: PropTypes.arrayOf(PropTypes.shape({})),
  setOpenOptMappingsModal: PropTypes.func,
};

export default OptsMappingModal;
