import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

const EventsRequestOutputModal = ({
  isOpen,
  isLoading,
  requestId,
  data,
  onClose,
}) => {
  const { warnings, items } = data;

  const { t } = useTranslation();

  const columns = [
    {
      field: "eventName",
      headerName: t("common.labels.event_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "eventProperty",
      headerName: t("manage_events.table_columns.event_property"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "ecosystemName",
      headerName: t("common.labels.ecosystem"),
      flex: 1,
      sortable: false,
    },
    {
      field: "eventValueType",
      headerName: t("manage_events.table_columns.event_value_type"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (columnData) => {
        const { value } = columnData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    return {
      id: index,
      ...warning,
      marketingProgram: `${warning.marketingProgramNumber} - ${warning.marketingProgramDescription}`,
      status: "warning",
      message: warning.message,
    };
  });

  rows.push(
    ...(items || []).map((item, index) => {
      let status;
      let message;
      if (item.mappingWarning) {
        status = "warning";
        message = item.mappingWarning;
      } else if (item.new) {
        status = "success";
        message = t("use_events_container.new_event_mapping");
      } else {
        status = "warning";
        message = item.message;
      }
      return {
        id: index,
        ...item,
        marketingProgram: `${item.marketingProgramNumber} - ${item.marketingProgramDescription}`,
        eventName: item.eventName,
        eventProperty: item.eventProperty,
        eventValueType: item.eventValueType,
        ecosystemName: item.ecosystemName,
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => onClose(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.event_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

EventsRequestOutputModal.defaultProps = {
  requestId: "",
  isLoading: false,
  data: {},
};

EventsRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
        description: PropTypes.string,
        marketingProgramNumber: PropTypes.number,
      })
    ),
  }),
  requestId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EventsRequestOutputModal;
