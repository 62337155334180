import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    remainingItems: {
      textDecoration: "underline",
    },
  };
});

export default useStyles;
