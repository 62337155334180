import i18n from "../../../../i18n/init";

const approvalsTableColumns = [
  {
    field: "requestId",
    headerName: i18n.t("common.labels.request_id"),
    flex: 0.5,
    sortable: false,
  },
  {
    field: "requestDate",
    headerName: i18n.t("requests.table_columns.request_date"),
    flex: 0.5,
    sortable: false,
  },
  {
    field: "requestedBy",
    headerName: i18n.t("common.labels.requester"),
    flex: 0.5,
    sortable: false,
  },
  {
    field: "region",
    headerName: i18n.t("common.labels.region"),
    flex: 0.75,
    sortable: false,
  },
  {
    field: "module",
    headerName: i18n.t("requests.table_columns.module"),
    flex: 0.5,
    sortable: false,
  },
  {
    field: "name",
    headerName: i18n.t("common.labels.name"),
    flex: 1,
    sortable: false,
  },
  {
    field: "status",
    headerName: i18n.t("common.labels.status"),
    flex: 0.5,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "",
    flex: 0.7,
    sortable: false,
  },
];

export default approvalsTableColumns;
