const createBackendPayload = (
  traitSelected,
  selectedEventProperty,
  diffList,
  scope,
  selectedSubMenuItem,
  globalTraitResponses
) => {
  const payload = {
    scope: {
      type: scope,
    },
    schemaVersion: "v2",
  };
  if (Object.keys(traitSelected).length > 0) {
    payload.trait = {
      traitId: traitSelected.traitId,
      traitName: traitSelected.traitName,
      traitDescription:
        traitSelected.description ?? traitSelected.traitDescription,
    };
    payload.traitResponses = diffList.map((x) => {
      const final =
        scope === "global"
          ? {
              globalStandardResponse: x.predefinedTraitResponse,
            }
          : {
              languageName: x.languageName,
              languageCode: x.languageCode,
              predefinedTraitResponseLocalLanguage:
                x.predefinedTraitResponseLocalLanguage,
              predefinedTraitResponse: x.predefinedTraitResponse,
              globalStandardResponse: x.globalStandardResponse,
            };
      if (scope !== "global" && x.globalStandardResponse) {
        const globalPredefinedTraitNumber = globalTraitResponses?.find(
          (r) => r.predefinedTraitResponse === x.globalStandardResponse
        )?.predefinedTraitNumber;
        if (globalPredefinedTraitNumber) {
          final.globalPredefinedTraitNumber = globalPredefinedTraitNumber;
        }
      }
      if (x.new) {
        final.new = x.new;
      }
      if (x.deleted && x.isUpdated) {
        final.deleted = x.deleted;
        final.isUpdated = x.isUpdated;
      } else if (x.deleted) {
        final.deleted = x.deleted;
      } else if (x.isUpdated) {
        final.isUpdated = x.isUpdated;
        final.new = x.new;
      }
      if (x.predefinedTraitResponseAliases) {
        final.predefinedTraitResponseAliases = x.predefinedTraitResponseAliases;
        if (scope === "global") {
          final.predefinedTraitResponseAliases =
            final.predefinedTraitResponseAliases.map((alias) => ({
              predefinedTraitResponseAliasId:
                alias.predefinedTraitResponseAliasId,
              predefinedTraitResponseAlias: alias.predefinedTraitResponseAlias,
            }));
        }
      }
      return final;
    });
  } else {
    payload.eventName = selectedEventProperty.eventName;
    payload.propertyName = selectedEventProperty.propertyName;
    payload.eventPropertyResponses = diffList.map((x) => {
      const final = {
        ...x,
        language: x.languageName,
      };
      const propertyResponse =
        x.predefinedEventPropertyResponse ||
        x.predefinedEventPropertyResponseEnglish;
      if (scope === "global") {
        final.predefinedEventPropertyResponse = propertyResponse;
      } else {
        final.predefinedEventPropertyResponseEnglish = propertyResponse;
        delete final.predefinedEventPropertyResponse;
      }
      delete final.predefinedTraitNumber;
      delete final.predefinedTraitResponse;
      delete final.traitResponse;
      delete final.traitResponseEnglish;
      delete final.traitResponseLocalLanguage;
      delete final.language;
      delete final.id;
      delete final.sId;
      delete final.reference;

      if (final.predefinedTraitNumber === null) {
        delete final.predefinedTraitNumber;
      }
      return final;
    });
  }

  if (scope === "regional") {
    payload.scope.regionCode =
      selectedSubMenuItem.geoRegionCode ||
      selectedSubMenuItem.name ||
      selectedSubMenuItem.regionCode;
    payload.scope.regionName =
      selectedSubMenuItem.geoRegionName ||
      selectedSubMenuItem.label ||
      selectedSubMenuItem.regionName;
  }

  if (scope === "legalEntity") {
    payload.scope.legalEntityId =
      selectedSubMenuItem.legalEntityId ||
      selectedSubMenuItem.legalEntityNumber;
    payload.scope.legalEntityName = selectedSubMenuItem.legalEntityName;
  }

  if (scope === "marketingProgram") {
    payload.scope.marketingProgramNumber =
      selectedSubMenuItem.marketingProgramNumber;
    payload.scope.description =
      selectedSubMenuItem.marketingProgramDescription ||
      selectedSubMenuItem.description;
  }

  return payload;
};

export default createBackendPayload;
