import { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  Box,
  IconButton,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import RefreshIcon from "@material-ui/icons/Refresh";

import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import retryJob from "../../../../../api/retry-job";

import useGlobalStyles from "../../../../../assets/styles/global";
import AccordionRowDetails from "../../../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import RequestModal from "../../../../../components/RequestModal";
import StatusBadge from "../../../../../components/StatusBadge";
import StyledTooltip from "../../../../../components/StyledTooltip";

import useLoadingSpinner from "../../../../../hooks/useLoadingSpinner";
import useNotifier from "../../../../../hooks/useNotifier";

import handleError from "../../../../../utilities/handleError";
import isEmpty from "../../../../../utilities/isEmpty";

import useStyles from "./styles";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

const AutomationStatus = ({
  title,
  loadingJobs,
  loadingJobDetails,
  loadJobDetails,
  loadJobsInfo,
  open,
  jobs,
  jobDetails,
  onBack,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [openRetryConfirmationModal, setOpenRetryConfirmationModal] =
    useState(false);
  const [retryJobDetails, setRetryJobDetails] = useState({});
  const globalStyles = useGlobalStyles();

  const { addNotification } = useNotifier();
  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();

  const ignorableFields = [
    "retryRequest",
    "response",
    "jobId",
    "status",
    "message",
    "requestId",
    "payload",
  ];

  const toggleAccordion = (panel, jobId) => (event, isExpanded) => {
    if (expanded !== panel) {
      loadJobDetails(jobId);
    }
    setExpanded(isExpanded ? panel : false);
  };

  const convertObjectToKeyValuePairs = (object, level) => {
    const dynamicKeys = [];
    Object.keys(object).forEach((key) => {
      if (key === "filter" && !isEmpty(object[key])) {
        dynamicKeys.push({
          name: t("marketing_programs_container.job.details.filter_title"),
          id: "entity.filter.ecoSystemDestinationFilterTitle",
        });
      } else if (key === "entity" && !isEmpty(object[key])) {
        dynamicKeys.push(
          ...convertObjectToKeyValuePairs(object[key], "entity")
        );
      } else {
        const uiName = key.replace(/[A-Z]/g, (letter) => ` ${letter}`);
        if (!ignorableFields.includes(key)) {
          dynamicKeys.push({
            name: uiName.charAt(0).toUpperCase() + uiName.slice(1),
            id: isEmpty(level) ? key : `${level}.${key}`,
          });
        }
      }
    });
    return dynamicKeys;
  };

  if (!title) {
    return null;
  }

  const newJobDetails = { ...jobDetails };
  if (jobDetails) {
    const keys = [
      "jobStartTime",
      "jobEndTime",
      "batchProcessingStartTime",
      "batchProcessingEndTime",
    ];
    // Format Date And Time
    keys.forEach((key) => {
      if (jobDetails[key]) {
        newJobDetails[key] = moment(jobDetails[key]).format(
          "MM/DD/YYYY HH:mm:ss"
        );
      } else {
        newJobDetails[key] = null;
      }
    });

    if (!jobDetails.output) {
      newJobDetails.output = [];
    }
  }

  const jobDetailsKeys = [
    {
      name: t("common.labels.status"),
      id: "status",
    },
    {
      name: t("common.labels.message"),
      id: "message",
    },
    {
      name: t("common.labels.request_id"),
      id: "requestId",
    },
  ];

  let outputValue = null;

  if (newJobDetails.output && !isEmpty(newJobDetails.output)) {
    Object.values(newJobDetails.output).some((outputFieldValue) => {
      return outputFieldValue.some((value) => {
        if (!isEmpty(value)) {
          outputValue = value;
          return true;
        }
        return false;
      });
    });
  }

  if (outputValue && !isEmpty(outputValue)) {
    jobDetailsKeys.push(...convertObjectToKeyValuePairs(outputValue, ""));
  }

  return (
    <>
      <RequestModal
        open={open}
        isLoading={loadingJobs}
        className={classes.dialog}
      >
        <>
          <DialogTitle
            id="request-output-modal-title"
            className={classes.title}
            data-testid="request-output-modal"
          >
            <div className={classes.flexContainer}>
              <div>
                <Box mr={1} component="span">
                  {title}
                </Box>
                <Tooltip
                  placement="top"
                  title={t("marketing_programs_container.refresh")}
                  className={classes.hover}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      marginRight: "8px",
                    }}
                    className={classes.refreshBtn}
                    onClick={() => {
                      setExpanded(false);
                      loadJobsInfo();
                    }}
                    data-testid="Refresh"
                  >
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              </div>
              <div>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setExpanded(false);
                    onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {!jobs || jobs.length === 0 ? (
              <Typography>
                {t("marketing_programs_container.empty_jobs_message")}
              </Typography>
            ) : (
              <> </>
            )}
            {jobs.map((job, i) => {
              const id = `automation-status-log-${i.toString()}`;
              const jobNames = job.jobName.replace(/_/g, " ").split(" ");
              const jobName = jobNames
                .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
                .join(" ");
              const showRetryButton =
                newJobDetails.status === "failed" ||
                newJobDetails.status === "success-partial";

              let tooltipInfo = "";

              if (job.status === "failed" || job.status === "success-partial") {
                if (!job.retryJob) {
                  tooltipInfo = t(
                    "marketing_programs_container.job.details.tooltip.message_1"
                  );
                } else if (job.retryCount === job.maxRetries) {
                  tooltipInfo = t(
                    "marketing_programs_container.job.details.tooltip.message_2"
                  );
                } else if (job.retryImmediately) {
                  tooltipInfo = t(
                    "marketing_programs_container.job.details.tooltip.message_3"
                  );
                } else {
                  tooltipInfo = t(
                    "marketing_programs_container.job.details.tooltip.message_4"
                  );
                }
              }

              let dynamicKeys = [];
              if (job.batchProcessingStartTime && job.batchProcessingEndTime) {
                dynamicKeys = [
                  {
                    name: t(
                      "marketing_programs_container.job.details.batch_processing_start_time"
                    ),
                    id: "batchProcessingStartTime",
                  },
                  {
                    name: t(
                      "marketing_programs_container.job.details.batch_processing_end_time"
                    ),
                    id: "batchProcessingEndTime",
                  },
                ];
              } else if (job.entity) {
                dynamicKeys = convertObjectToKeyValuePairs(
                  job.entity,
                  "entity"
                );
              }

              return (
                <Accordion
                  key={i.toString()}
                  className={classes.accordion}
                  expanded={expanded === id}
                  onChange={toggleAccordion(id, job.jobId)}
                  data-testid={id}
                >
                  <AccordionSummary
                    className={classes.accordionHeader}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className={classes.jobInfo}>
                      <Typography className={classes.label}>
                        {`${t("common.labels.job_id")}:`}
                        <Typography className={classes.labelValue}>
                          {job.jobId}
                        </Typography>
                      </Typography>
                      <div className={classes.divider} />
                      <Typography className={classes.label}>
                        {`${t("common.labels.job_name")}:`}
                        <Typography className={classes.labelValue}>
                          {jobName}
                        </Typography>
                      </Typography>
                    </div>
                    <div
                      className={clsx(
                        classes.marginLeft,
                        classes.flexContainer
                      )}
                    >
                      <StatusBadge
                        status={
                          newJobDetails.jobId === job.jobId
                            ? newJobDetails.status
                            : job.status || "failed"
                        }
                      />
                      {!isEmpty(tooltipInfo) && (
                        <StyledTooltip placement="top" title={tooltipInfo}>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <InfoIcon className={classes.infoIcon} />
                          </div>
                        </StyledTooltip>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionContent}>
                    {loadingJobDetails ? (
                      <div>
                        <Skeleton variant="text" width={543} height={65} />
                        <Skeleton variant="text" width={543} height={65} />
                        <Skeleton variant="text" width={543} height={130} />
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          classes.flexContainer,
                          showRetryButton
                            ? classes.retryJobDetailsContainer
                            : classes.jobDetailsContainer
                        )}
                      >
                        <div>
                          <Tabs
                            value={currentTab}
                            onChange={(event, newVal) => {
                              setCurrentTab(newVal);
                            }}
                            textColor="primary"
                            classes={{
                              indicator: globalStyles.indicator,
                            }}
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                          >
                            <Tab label="Details" {...a11yProps(0)} />
                            <Tab label="Output" {...a11yProps(1)} />
                          </Tabs>
                          <div className={classes.accordionDivider} />
                          <TabPanel value={currentTab} index={0}>
                            <AccordionRowDetails
                              keys={[
                                {
                                  name: t(
                                    "marketing_programs_container.job.details.start_time"
                                  ),
                                  id: "jobStartTime",
                                },
                                {
                                  name: t(
                                    "marketing_programs_container.job.details.end_time"
                                  ),
                                  id: "jobEndTime",
                                },
                                {
                                  name: t("common.labels.message"),
                                  id: "message",
                                },
                                {
                                  name: t(
                                    "marketing_programs_container.job.details.retry_count"
                                  ),
                                  id: "retryCount",
                                },
                                {
                                  name: t(
                                    "marketing_programs_container.job.details.max_retries"
                                  ),
                                  id: "maxRetries",
                                },
                                {
                                  name: t(
                                    "marketing_programs_container.job.details.retry_job"
                                  ),
                                  id: "retryJob",
                                },
                                {
                                  name: t(
                                    "marketing_programs_container.job.details.dependent_job_ids"
                                  ),
                                  id: "prerequisiteJobIds",
                                },
                                ...dynamicKeys,
                              ]}
                              row={newJobDetails}
                              defaultValue={t("common.NA")}
                            />
                          </TabPanel>
                          <TabPanel value={currentTab} index={1}>
                            {Object.keys(newJobDetails.output).map(
                              (key, index) => {
                                return (
                                  <Accordion
                                    key={index.toString()}
                                    className={clsx(
                                      classes.accordion,
                                      classes.gridContainer1
                                    )}
                                    disabled={isEmpty(
                                      newJobDetails.output[key]
                                    )}
                                  >
                                    <AccordionSummary
                                      className={classes.accordionHeader}
                                      expandIcon={<ExpandMoreIcon />}
                                    >
                                      <Typography
                                        className={classes.systemType}
                                      >
                                        {`${
                                          key.charAt(0).toUpperCase() +
                                          key.slice(1, key.length - 1)
                                        } ${key[key.length - 1]}`}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      className={classes.accordionContent}
                                    >
                                      <AccordionRowDetails
                                        keys={jobDetailsKeys}
                                        row={newJobDetails.output[key].map(
                                          (item) => ({
                                            ...item,
                                            status: (
                                              <StatusBadge
                                                status={
                                                  item.status ||
                                                  t("status.failed")
                                                }
                                              />
                                            ),
                                          })
                                        )}
                                        defaultValue={t("common.NA")}
                                        rowClassName={
                                          showRetryButton
                                            ? classes.detailsRow
                                            : ""
                                        }
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              }
                            )}
                          </TabPanel>
                        </div>
                        {showRetryButton && (
                          <Button
                            data-testid="retryButton"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setRetryJobDetails(newJobDetails);
                              setOpenRetryConfirmationModal(true);
                            }}
                          >
                            {t("common.retry")}
                          </Button>
                        )}
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </DialogContent>
          {onBack && (
            <DialogActions className={classes.footer}>
              <Button
                data-testid="closeButton"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setExpanded(false);
                  onBack();
                }}
              >
                {t("common.back")}
              </Button>
            </DialogActions>
          )}
        </>
      </RequestModal>
      <ConfirmationModal
        open={openRetryConfirmationModal}
        onClose={() => setOpenRetryConfirmationModal(false)}
        title={t("dialogs.confirm_retry")}
        message={t("marketing_programs_container.retry_message")}
        btn1Text={t("common.no")}
        btn2Text={t("common.yes")}
        btn2Action={async () => {
          setOpenRetryConfirmationModal(false);
          let params = {
            retryImmediately: true,
          };
          if (!retryJobDetails.retryJob || retryJobDetails.retryJob === "No") {
            params = {
              retryJob: true,
              retryImmediately: true,
            };
          }
          if (retryJobDetails.maxRetries === retryJobDetails.retryCount) {
            params = {
              ...params,
              maxRetries: retryJobDetails.retryCount + 1,
            };
          }
          try {
            increaseRequestsCount();
            await retryJob(retryJobDetails.jobId, params);
            setExpanded(false);
            loadJobsInfo();
          } catch (error) {
            handleError({
              error,
              handle404: false,
              addNotification,
            });
            return;
          } finally {
            decreaseRequestsCount();
          }
          loadJobDetails(retryJobDetails.jobId);
        }}
        type="error"
      />
    </>
  );
};

AutomationStatus.defaultProps = {
  title: null,
  jobs: [],
  jobDetails: {},
  loadJobDetails: () => {},
  loadJobsInfo: () => {},
  onBack: null,
};

AutomationStatus.propTypes = {
  open: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  jobDetails: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
    output: PropTypes.shape({
      [PropTypes.string]: PropTypes.arrayOf(
        PropTypes.shape({
          [PropTypes.string]: PropTypes.string,
        })
      ),
    }),
    jobStartTime: PropTypes.string,
    jobEndTime: PropTypes.string,
    jobId: PropTypes.number,
    status: PropTypes.string,
    retryJob: PropTypes.bool,
    retryCount: PropTypes.number,
    maxRetries: PropTypes.number,
    entity: PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  loadingJobs: PropTypes.bool.isRequired,
  loadingJobDetails: PropTypes.bool.isRequired,
  loadJobDetails: PropTypes.func,
  loadJobsInfo: PropTypes.func,
};

export default AutomationStatus;
