import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getUseCaseDetails = async (id, params) => {
  const url = `/use-cases/${id}?${params}`;
  const refreshCache = getValue("traits");
  const {
    data: { data },
  } = await api.get(url, {
    params: {
      refreshCache,
    },
  });
  return data;
};

export default getUseCaseDetails;
