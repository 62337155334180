import { useContext } from "react";

import { LoadingSpinnerContext } from "../providers/LoadingSpinnerProvider";

const useLoadingSpinner = () => {
  const { loading, increaseRequestsCount, decreaseRequestsCount } = useContext(
    LoadingSpinnerContext
  );
  return { loading, increaseRequestsCount, decreaseRequestsCount };
};

export default useLoadingSpinner;
