import { isValidElement } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import AutoComplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";

import traitsModuleConfig from "../../config/traitsModuleConfig";
import InlineMessage from "../InlineMessage";

import useStyles from "./styles";

const { personalTraitErrorMessage } = traitsModuleConfig;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomAutoComplete = ({
  options,
  disableFilterOptions,
  placeholder,
  value,
  setValue,
  onInputChange,
  optionDisabled,
  selectedTagDisabled,
  isDisabled,
  onClose,
  loading,
  id,
  isMultiple,
  hideCheckbox,
  inputValue,
  isExternalInputValue,
  noOptionsText,
  onOpen,
  closeIcon,
  disablePortal,
  className,
  classes: muiClasses,
  error,
  errorText,
  disableCloseOnSelect,
  onBlur,
}) => {
  const classes = useStyles();
  let inputValProps = {};
  if (isExternalInputValue) {
    inputValProps = { inputValue };
  }
  let closeIconButton = closeIcon;
  if (closeIconButton === null) {
    closeIconButton = <CloseIcon fontSize="small" />;
  }

  return (
    <AutoComplete
      onBlur={onBlur}
      data-testid="autocomplete-wrapper"
      className={classes.autocompleteWrapper}
      multiple={isMultiple}
      options={options}
      loading={loading}
      classes={muiClasses}
      id={id}
      closeIcon={closeIconButton}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionLabel={(option) => {
        if (option.title) {
          return option.title.toString();
        }
        return "";
      }}
      {...inputValProps}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      disabled={isDisabled}
      noOptionsText={noOptionsText}
      getOptionDisabled={(option) => optionDisabled(option)}
      onOpen={onOpen}
      onClose={onClose}
      renderOption={(option) => {
        if (option.type === "div") {
          return option;
        }
        if (isValidElement(option)) {
          return option;
        }
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.fullWidth,
              classes.flexJustify,
              className
            )}
          >
            <div className={classes.popper} data-testid="checkbox-option">
              {!hideCheckbox && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    isMultiple
                      ? value.map((val) => val.title).includes(option.title)
                      : value?.title === option.title
                  }
                />
              )}
              {option.title}
            </div>
            {((optionDisabled && optionDisabled(option)) ||
              option.title === "CDP2.0") && (
              <div className={clsx(classes.flexContainer, classes.errorText)}>
                <InfoIcon
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "5px",
                  }}
                />
                <Typography>
                  {personalTraitErrorMessage(option.title)}
                </Typography>
              </div>
            )}
          </div>
        );
      }}
      getOptionSelected={(option, _value) => {
        return option.title === _value.title;
      }}
      {...(!disableFilterOptions ? { filterOptions: (a) => a } : {})}
      value={value}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.title}
            deleteIcon={
              !isDisabled && !selectedTagDisabled(option) ? (
                <CloseIcon />
              ) : (
                <></>
              )
            }
            {...getTagProps({ index })}
          />
        ))
      }
      style={{ width: 700 }}
      disablePortal={disablePortal}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            value: inputValue,
          }}
          variant="outlined"
          placeholder={value && value?.length > 0 ? "" : placeholder}
          onChange={(event) => {
            onInputChange(event.target.value);
          }}
          classes={{
            root: classes.textFieldContainer,
          }}
          error={error}
          helperText={error && <InlineMessage message={errorText} />}
        />
      )}
    />
  );
};

CustomAutoComplete.defaultProps = {
  optionDisabled: () => {},
  isMultiple: true,
  hideCheckbox: false,
  inputValue: "",
  isExternalInputValue: false,
  noOptionsText: "No options",
  closeIcon: null,
  onOpen: () => {},
  isDisabled: false,
  onClose: () => {},
  disablePortal: false,
  className: "",
  classes: {},
  error: false,
  errorText: "",
  disableCloseOnSelect: true,
  selectedTagDisabled: () => null,
  disableFilterOptions: false,
  onBlur: () => {},
};

CustomAutoComplete.propTypes = {
  loading: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  optionDisabled: PropTypes.func,
  isDisabled: PropTypes.bool,
  onClose: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  onOpen: PropTypes.func,
  selectedTagDisabled: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  inputValue: PropTypes.string,
  isExternalInputValue: PropTypes.bool,
  noOptionsText: PropTypes.string,
  closeIcon: PropTypes.node,
  disablePortal: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  error: PropTypes.bool,
  errorText: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  disableFilterOptions: PropTypes.bool,
  onBlur: PropTypes.func,
};

export default CustomAutoComplete;
