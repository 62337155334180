import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  container: {
    "& .MuiButton-root": {
      minWidth: "42px",
      maxHeight: "33px",
      backgroundColor: "rgba(38, 153, 251, 0.2)",
      color: theme.colorTheme.grey,
      marginLeft: "20px",
      "& .MuiButton-label": {
        whiteSpace: "nowrap",
      },
    },
  },
}));

export default useStyles;
