import applicationConfig from "../../../config/applicationConfig";

import i18n from "../../../i18n/init";

const getEcosystemFields = (info) => {
  const fields = [
    {
      label: i18n.t("common.labels.ecosystem"),
      type: applicationConfig.inputType.dropdown,
      flex: 2,
      props: {
        value: info.ecosystem,
      },
    },
    {
      label: i18n.t("mp_ecosystem_mapping_container.ecosystem_mp_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        value: info.ecosystemMpName,
      },
    },
    {
      label: i18n.t("mp_ecosystem_mapping_container.ecosystem_mp_key"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        value: info.ecosystemMpKey,
      },
    },
    {
      label: i18n.t("mp_ecosystem_mapping_container.ecosystem_instance"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        value: info.ecosystemMpInstance,
      },
    },
    {
      label: i18n.t("mp_ecosystem_mapping_container.enable_automation"),
      type: applicationConfig.inputType.custom,
      flex: 2,
      props: {
        value: info.enableAutomation,
      },
    },
    {
      label: i18n.t("mp_ecosystem_mapping_container.segment_persona_space"),
      flex: 2,
      type: applicationConfig.inputType.dropdown,
      props: {
        value: info.ecosystemSegmentPersonaSpace,
      },
    },
    {
      label: i18n.t("mp_ecosystem_mapping_container.lytics_secret"),
      type: applicationConfig.inputType.dropdown,
      flex: 2,
      props: {
        value: info.ecosystemLyticsSecret,
      },
    },
  ];
  return fields;
};

export default getEcosystemFields;
