import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import MarketingProgramsContainer from "./MarketingProgramsContainer";
import NewMarketingProgram from "./NewMarketingProgram";

const MarketingProgramsModule = ({ basePath }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}`}
        component={MarketingProgramsContainer}
      />
      <Route
        exact
        path={`${basePath}/manage`}
        component={NewMarketingProgram}
      />
      <Route
        exact
        path={`${basePath}/revision`}
        component={NewMarketingProgram}
      />
      <Route
        exact
        path={`${basePath}/*`}
        render={() => <Redirect to={`${basePath}`} />}
      />
    </Switch>
  );
};

MarketingProgramsModule.defaultProps = {
  basePath: applicationConfig.basePaths.marketing_programs,
};

MarketingProgramsModule.propTypes = {
  basePath: PropTypes.string,
};

export default MarketingProgramsModule;
