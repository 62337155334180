import React, { createElement, useState, useContext } from "react";

import {
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  Box,
  MenuItem,
  Menu,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as DarkLogo } from "../../assets/images/CRS_LOGO.svg";
import { ReactComponent as HelpIcon } from "../../assets/images/help.svg";
import listCheckmark from "../../assets/images/list_checkmark.svg";
import { lightTheme } from "../../assets/styles/themes";
import applicationConfig from "../../config/applicationConfig";

import useLoadingSpinner from "../../hooks/useLoadingSpinner";

import { ColorModeContext } from "../../providers/ColorModeProvider";

import useStyles from "./styles";

const LoginModal = () => {
  const { search } = useLocation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const query = new URLSearchParams(search);
  const redirectUri = query.get("redirect_uri");

  const { t } = useTranslation();

  const colorMode = useContext(ColorModeContext);

  const {
    sidebarMenuConfig,
    deploymentEnv,
    endpoints: { apiBaseUrl },
  } = applicationConfig;

  const helpMeMenuItem = sidebarMenuConfig.find(
    (config) => config.text === t("sidebar_menu_config.help_me")
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { loading } = useLoadingSpinner();

  return (
    <Modal open>
      <Box className={classes.modalBox}>
        <Card variant="outlined" className={classes.modalWrapper}>
          <div className={classes.modalLeftContainer}>
            <DarkLogo />
            <ul
              className={classes.infoList}
              style={{
                listStyle: `url(${listCheckmark})`,
              }}
            >
              <li>Digital Marketing Self-Service</li>
              <li>Data Centricity</li>
              <li>Metadata Management</li>
            </ul>
          </div>
          <CardContent>
            <Typography
              variant="h5"
              style={{ fontWeight: "500", fontSize: "28px" }}
            >
              Please login to use the Consumer Reference Services Portal
            </Typography>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 1,
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {helpMeMenuItem.children &&
                helpMeMenuItem.children.map((item) => (
                  <MenuItem key={item.text} className={classes.menuItem}>
                    <a href={item.path} target="_blank" rel="noreferrer">
                      {item.text}
                    </a>
                  </MenuItem>
                ))}
            </Menu>
            <div className={classes.footerContainer}>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  const loginBaseUrl =
                    deploymentEnv !== "local"
                      ? window.location.origin
                      : apiBaseUrl;
                  window.location.assign(
                    `${loginBaseUrl}/api/auth/login?redirect_uri=${redirectUri}`
                  );
                }}
              >
                {loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                Login
              </Button>
              <Tooltip title="Help">
                <IconButton
                  size="small"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {createElement(HelpIcon, {
                    width: 30,
                    height: 30,
                    fill:
                      colorMode.mode === "light"
                        ? lightTheme.palette.static.darkerGrey
                        : lightTheme.palette.static.white,
                  })}
                </IconButton>
              </Tooltip>
            </div>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

LoginModal.propTypes = {};

export default LoginModal;
