import api from "../utilities/api";

const createResponsesRequest = async (module, body) => {
  const url = `/${module}/responses`;

  const {
    data: { data },
  } = await api.post(url, body);
  return data;
};

export default createResponsesRequest;
