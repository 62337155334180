import { useState } from "react";

import moment from "moment";

import getJobs from "../api/get-jobs";

import applicationConfig from "../config/applicationConfig";

import handleError from "../utilities/handleError";
import parseSearchFilter from "../utilities/parseSearchFilter";

import useLoadingSpinner from "./useLoadingSpinner";
import useNotifier from "./useNotifier";

const useJobsFilters = () => {
  const { addNotification } = useNotifier();
  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();

  const [jobs, setJobs] = useState([]);
  const [jobsAnalytics, setJobsAnalytics] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(true);
  const [jobsCount, setJobsCount] = useState(0);

  const loadJobServices = async (_page = 1, _perPage = 50, filter) => {
    let response = {};
    let filterFieldMap = {
      status: "status",
      jobName: "jobName",
      startTime: "startTime",
      endTime: "endTime",
      jobId: "jobId",
    };

    let backendFilter = filter || [];
    backendFilter = [...backendFilter];

    let noFilterSelected = false;
    backendFilter = backendFilter.map((obj) => {
      if (obj.name === applicationConfig.common.status) {
        if (obj.value.length === 0) {
          noFilterSelected = true;
        }
        return {
          name: "status",
          value: obj.value
            .split(",")
            .map((_status) => _status.toLowerCase())
            .filter((x) => x !== "all")
            .join(","),
        };
      }
      if (obj.name === applicationConfig.common.job_name) {
        if (obj.value.length === 0) {
          noFilterSelected = true;
        }
        return {
          name: "jobName",
          value: obj.value
            .map((_type) => {
              if (_type) {
                return _type.replace(/ /g, "_").toLowerCase();
              }
              return "";
            })
            .filter((x) => x !== "all")
            .join(","),
        };
      }
      if (obj.name === applicationConfig.common.job_id) {
        return {
          name: "jobId",
          value: obj.value,
        };
      }
      return obj;
    });
    if (noFilterSelected) {
      setJobs([]);
      setJobsAnalytics([]);
      return null;
    }

    const startTime =
      filter &&
      filter.find((x) => x.name === applicationConfig.filters.start_time);
    const endTime =
      filter &&
      filter.find((x) => x.name === applicationConfig.filters.end_time);

    if (startTime && endTime) {
      if (startTime.value && endTime.value) {
        if (startTime.value > endTime.value) {
          return null;
        }
        filterFieldMap = {
          ...filterFieldMap,
          startTime: "startTime",
          endTime: "endTime",
        };
        startTime.value = moment(
          `${startTime.value} 00:00:00`,
          applicationConfig.common.yyyy_mm_dd_hh_mm_ss
        )
          .utc()
          .toISOString();
        endTime.value = moment(
          `${endTime.value} 23:59:59`,
          applicationConfig.common.yyyy_mm_dd_hh_mm_ss
        )
          .utc()
          .toISOString();
      } else if (startTime.value || endTime.value) {
        return null;
      }
    }

    setJobsLoading(true);
    setJobs([]);

    const apiFilter = [];
    backendFilter.map((obj) => {
      const index = apiFilter.findIndex((obj1) => obj1.name === obj.name);
      if (index === -1) {
        apiFilter.push(obj);
      } else {
        apiFilter[index].value = obj.value;
      }
      return null;
    });

    try {
      increaseRequestsCount();
      response = await getJobs(
        parseSearchFilter(apiFilter, filterFieldMap, _page, _perPage)
      );
      setJobs(
        response.items.map((item, index) => ({
          ...item,
          id: index,
        }))
      );
      setJobsCount(response.totalItems);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setJobsCount(0);
        },
        addNotification,
      });
      setJobs([]);
    } finally {
      decreaseRequestsCount();
      setJobsLoading(false);
    }
    return response;
  };

  return {
    jobs,
    jobsAnalytics,
    jobsLoading,
    jobsCount,
    searchFilter,
    loadJobServices,
    setJobs,
    setJobsAnalytics,
    setJobsLoading,
    setJobsCount,
    setSearchFilter,
  };
};

export default useJobsFilters;
