const checkUserAuthorization = (access, allowedAccess) => {
  if (allowedAccess.length === 0) {
    // Allow everyone to access
    return true;
  }
  const intersectingAccess = access.filter((value) =>
    allowedAccess.includes(value)
  );
  if (intersectingAccess.length > 0) {
    return true;
  }
  return false;
};

export default checkUserAuthorization;
