import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    marketingProgramContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "40px",
    },
    fullWidth: {
      width: "100%",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
      },
    },
    traitContainer: {
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.blue.main,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
    },
    autoCompleteDeleteContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
      },
      "&:focus": {
        outline: "none",
      },
      "&[aria-label='edit']": {
        "& svg": {
          fill: theme.palette.blue.light,
        },
      },
      "&[aria-label='delete']": {
        "& svg": {
          fill: theme.palette.error.light,
        },
      },
    },
    accordion: {
      border: `1px solid ${theme.colorTheme.greyishWhite}`,
      borderRadius: "4px",
      marginBottom: 16,
      width: "90%",
    },
    accordionHeader: {
      padding: "0 16px",
      "& .MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
      },
    },
    accordionContent: {
      margin: "0 !important",
      display: "flex",
      flexDirection: "column",
    },
    badge: {
      backgroundColor: theme.palette.blue.darker,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "20px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.static.white,
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    backBtn: {
      "& a": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
      "& button": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
    },
    eventContainer: {
      "& .MuiTypography-h4": {
        fontSize: "16px",
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
    },
    confirmationContainer: {
      "& .MuiTypography-h4": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
        fontWeight: 500,
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: `${theme.palette.background.default1} !important`,
      },
    },
    trait: {
      "& > div:first-child": {
        flex: "0 0 93%",
      },
      "& > div:last-child": {
        flex: "0 0 3%",
      },
    },
    traitTitle: {
      backgroundColor: theme.palette.blue.darker,
      color: theme.colorTheme.black,
      minWidth: 88,
      padding: "10px 16px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
    },
    traitDescription: {
      marginLeft: 16,
    },
    statusLabel: {
      marginLeft: "auto",
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    traitName: {
      cursor: "pointer",
    },
    eventPropertyModalFooter: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "30px",
    },
    eventPropertyModalField: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
      "& > p.MuiTypography-root.MuiTypography-body1": {
        whiteSpace: "nowrap",
      },
    },
  };
});

export default useStyles;
