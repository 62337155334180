import { useEffect, useState, useRef, useCallback } from "react";

import {
  Paper,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  Chip,
  Avatar,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import getUsecases from "../../../api/get-use-cases";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import CustomModal from "../../../components/CustomModal";
import InlineMessage from "../../../components/InlineMessage";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";

import useLegalEntities from "../../../hooks/useLegalEntities";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useMarketingPrograms from "../../../hooks/useMarketingPrograms";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import i18n from "../../../i18n/init";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import { isEmptyString } from "../../../utilities/formValidation";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import truncateString from "../../../utilities/truncateString";
import useImmer from "../../../utilities/useImmer";
import useStyles from "../../Traits/TraitsContainer/styles";
import EventPropertiesAccordion from "../NewUseCaseContainer/components/EventPropertiesAccordion";

import useCaseStyles from "./styles";

const UseCaseManagementContainer = () => {
  const {
    traitsUsecaseTableColumns,
    useCaseSearchDefaultFilterState,
    traitsUsecaseSearchFilters,
    relatedTraitsKeys,
  } = traitsModuleConfig;
  const classes = useStyles();
  const accordionClasses = useAccordionStyles();
  const globalStyles = useGlobalStyles();
  const useCaseClasses = useCaseStyles();
  const history = useHistory();
  const [module, setModule] = useState("traits");

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [relatedTraitsModalIsOpen, setRelatedTraitsModalIsOpen] =
    useState(false);
  const childRef = useRef();
  const [filters, setFilters] = useState({});
  const { setRequest } = useRequest();
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [scope, setScope] = useState("global");
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useImmer({
    scopeIdentifier: null,
  });
  const [viewCloneMappings, setViewCloneMappings] = useState(false);
  const [clone, setClone] = useState({
    scope: "",
    useCase: "",
    selectedSubMenuItem: {
      scopeIdentifier: "",
    },
  });
  const [eventName, setEventName] = useState(null);
  const [useCaseSelected, setUseCaseSelected] = useState([]);
  const [manageUseCase, setManageUseCase] = useState({});
  const [relatedEventsModalIsOpen, setRelatedEventsModalIsOpen] =
    useState(false);
  const [subMenuValue, setSubMenuValue] = useState("");
  const [useCase, setUseCase] = useState({});
  const [useCaseName, setUseCaseName] = useState("");
  const [eventProperties, setEventProperties] = useState([]);
  const cloneRef = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [subMenu, setSubMenu] = useState([]);

  const { addNotification } = useNotifier();
  const { user } = useUserProfile();
  const { t } = useTranslation();

  const {
    getMarketingProgramsFromApi,
    initialMarketingProgramOptions,
    marketingProgramOptions,
    marketingProgramsLoading,
    setMarketingProgramOptions,
    setMarketingProgramsLoading,
  } = useMarketingPrograms();

  const {
    memoizedLegalEntitiesFromApi,
    legalEntities,
    legalEntitiesLoading,
    initialLegalEntitiesOptions,
    setLegalEntities,
    getLegalEntitiesFromApi,
  } = useLegalEntities();

  const [useCasesLoading, setUseCasesLoading] = useState(false);
  const [useCaseOptions, setUseCaseOptions] = useState([]);
  const [useCaseValue, setUseCaseValue] = useState("");
  const [initialUseCaseOptions, setInitialUseCaseOptions] = useState([]);

  const [errors, setErrors] = useState({});

  const getUseCasesFromApi = useCallback(async (searchText) => {
    setUseCasesLoading(true);
    let txt = `?itemsPerPage=3&page=1`;
    if (searchText?.length > 0) {
      txt += `&searchText=${searchText}`;
    }
    try {
      const rsp1 = await getUsecases(txt);
      setUseCaseOptions(rsp1.items);
      if (searchText.length === 0) {
        setInitialUseCaseOptions(rsp1.items);
      }
      return rsp1;
    } catch (error) {
      handleError({
        error,
        handle404: true,
        addNotification,
      });
      return [];
    } finally {
      setUseCasesLoading(false);
    }
  }, []);

  const memoizedUseCasesFromApi = useCallback(
    debounce(getUseCasesFromApi, applicationConfig.waitTime),
    []
  );

  const eventPropertiesList = (
    eventProperties || /* istanbul ignore next */ []
  ).filter((property) => property.eventName === eventName);
  const [eventNamesList, setEventNamesList] = useState([]);

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchTraits
  );

  const toggleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (
      module === "events" &&
      !isEmpty(useCase) &&
      !selectedSubMenuItem.eventName
    ) {
      const newEventProperties =
        (
          useCase?.mappedEventProperties?.find(
            (x) => x.title === selectedSubMenuItem.scopeIdentifier
          ) || useCase.mappedEventProperties?.[0]
        )?.properties || [];
      setEventProperties(newEventProperties);
      const eventNames = Array.from(
        new Set(newEventProperties.map((property) => property.eventName))
      );
      setEventNamesList(eventNames);
      setEventName(eventNames[0]);
      if (!selectedSubMenuItem.eventName) {
        setSelectedSubMenuItem((draft) => {
          [draft.eventName] = eventNames;
        });
      }
    }
  }, [module, useCase, selectedSubMenuItem.scopeIdentifier]);

  useEffect(() => {
    (async () => {
      await getMarketingProgramsFromApi("");
      await getLegalEntitiesFromApi("");
      await getUseCasesFromApi("");
    })();
  }, []);

  const loadRelatedEvents = (data) => {
    if (data?.useCaseId) {
      setUseCase({});
      try {
        let response = { ...data };
        let subMenuList = [];
        let selectedSubMenuListItem = null;

        if (response.mappedEventProperties.length > 0) {
          if (response.scope === "marketingProgram") {
            subMenuList = response.mappedEventProperties.map((x) => ({
              name: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
              value: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
            }));
            selectedSubMenuListItem = `${response.mappedEventProperties[0].marketingProgramNumber} - ${response.mappedEventProperties[0].marketingProgramDescription}`;
            response = {
              ...response,
              mappedEventProperties: response.mappedEventProperties.map(
                (y) => ({
                  ...y,
                  title: `${y.marketingProgramNumber} - ${y.marketingProgramDescription}`,
                })
              ),
            };
          } else if (response.scope === "legalEntity") {
            subMenuList = response.mappedEventProperties.map((x) => ({
              name: `${x.legalEntityId} - ${x.legalEntityName}`,
              value: `${x.legalEntityId} - ${x.legalEntityName}`,
            }));
            selectedSubMenuListItem = `${response.mappedEventProperties[0].legalEntityId} - ${response.mappedEventProperties[0].legalEntityName}`;
            response = {
              ...response,
              mappedEventProperties: response.mappedEventProperties.map(
                (y) => ({
                  ...y,
                  title: `${y.legalEntityId} - ${y.legalEntityName}`,
                })
              ),
            };
          } else if (response.scope === "regional") {
            subMenuList = response.mappedEventProperties.map((x) => ({
              name: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
              value: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
            }));
            selectedSubMenuListItem = `${response.mappedEventProperties[0].geoRegionCode} - ${response.mappedEventProperties[0].geoRegionDescription}`;
            response = {
              ...response,
              mappedEventProperties: response.mappedEventProperties.map(
                (y) => ({
                  ...y,
                  title: `${y.geoRegionCode} - ${y.geoRegionDescription}`,
                })
              ),
            };
          } else {
            selectedSubMenuListItem = "Global";
            response = {
              ...response,
              mappedEventProperties: response.mappedEventProperties.map(
                (y) => ({
                  ...y,
                  title: "Global",
                })
              ),
            };
          }
        }

        setSubMenu(subMenuList);
        const mappedEventName = !isEmpty(response.mappedEventProperties)
          ? response.mappedEventProperties[0]?.properties[0]?.eventName
          : /* istanbul ignore next */ null;
        setEventName(mappedEventName);
        setSelectedSubMenuItem((draft) => {
          draft.scopeIdentifier = selectedSubMenuListItem;
          draft.eventName = null;
        });
        setUseCase(response);
      } catch (error) {
        handleError({
          error,
          handle404: false,
          addNotification,
        });
      }
    }
  };

  const loadRelatedTraits = (data) => {
    if (data?.useCaseId) {
      setUseCase({});
      try {
        let subMenuList = [];
        let selectedSubMenuListItem = null;
        let usecase = { ...data };

        if (data.mappedTraits.length > 0) {
          if (data.scope === "marketingProgram") {
            subMenuList = data.mappedTraits.map((x) => ({
              name: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
              value: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
            }));
            selectedSubMenuListItem = `${data.mappedTraits[0].marketingProgramNumber} - ${data.mappedTraits[0].marketingProgramDescription}`;
            usecase = {
              ...data,
              mappedTraits: data.mappedTraits.map((y) => ({
                ...y,
                title: `${y.marketingProgramNumber} - ${y.marketingProgramDescription}`,
              })),
            };
          } else if (data.scope === "legalEntity") {
            subMenuList = data.mappedTraits.map((x) => ({
              name: `${x.legalEntityId} - ${x.legalEntityName}`,
              value: `${x.legalEntityId} - ${x.legalEntityName}`,
            }));
            selectedSubMenuListItem = `${data.mappedTraits[0].legalEntityId} - ${data.mappedTraits[0].legalEntityName}`;
            usecase = {
              ...data,
              mappedTraits: data.mappedTraits.map((y) => ({
                ...y,
                title: `${y.legalEntityId} - ${y.legalEntityName}`,
              })),
            };
          } else if (data.scope === "regional") {
            subMenuList = data.mappedTraits.map((x) => ({
              name: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
              value: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
            }));
            selectedSubMenuListItem = `${data.mappedTraits[0].geoRegionCode} - ${data.mappedTraits[0].geoRegionDescription}`;
            usecase = {
              ...data,
              mappedTraits: data.mappedTraits.map((y) => ({
                ...y,
                title: `${y.geoRegionCode} - ${y.geoRegionDescription}`,
              })),
            };
          } else {
            selectedSubMenuListItem = "Global";
          }
        }

        setSubMenu(subMenuList);
        setSelectedSubMenuItem((draft) => {
          draft.scopeIdentifier = selectedSubMenuListItem;
        });
        setUseCase(usecase);
        setUseCaseName(usecase.useCaseName);
      } catch (error) {
        handleError({
          error,
          handle404: false,
          addNotification,
        });
      }
    }
  };

  const filterFieldMap = {
    useCaseName: "useCaseName",
    useCaseDescription: "useCaseDescription",
    traitName: "traitName",
    eventProperty: "eventProperty",
    marketingProgram: "marketingProgram",
    "": "searchText",
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const getAllowedColumns = () => {
    const columns = traitsUsecaseTableColumns;
    return columns;
  };

  const subList = () => {
    const marketingProgramDropdownValues =
      subMenuValue.length > 0 || initialMarketingProgramOptions.length === 0
        ? marketingProgramOptions
        : initialMarketingProgramOptions;
    const legalEntitiesDropdownValues =
      subMenuValue.length > 0 || initialLegalEntitiesOptions.length === 0
        ? legalEntities
        : initialLegalEntitiesOptions;
    const regionalDropdownValues = applicationConfig.regionFilters;

    if (clone.scope === "regional") {
      return regionalDropdownValues
        ?.filter((region) => {
          if (region.label !== "GLOBAL" && region.geoRegionName !== "GLOBAL") {
            return true;
          }
          return false;
        })
        .map((region) => {
          return {
            ...region,
            title: region.label
              ? `${region.name} - ${region.label}`
              : `${region.geoRegionCode} - ${region.geoRegionName}`,
          };
        });
    }
    if (clone.scope === "legalEntity") {
      return legalEntitiesDropdownValues.map((entity) => ({
        ...entity,
        title: `${entity.legalEntityId || entity.legalEntityNumber} - ${
          entity.legalEntityName
        }`,
      }));
    }
    return marketingProgramDropdownValues.map((program) => ({
      ...program,
      title: `${program.marketingProgramNumber} - ${
        program.marketingProgramDescription ||
        /* istanbul ignore next */ program.description
      }`,
    }));
  };

  const useCases = () => {
    const useCaseValues =
      useCaseValue.length > 0 || initialUseCaseOptions.length === 0
        ? useCaseOptions
        : initialUseCaseOptions;
    return useCaseValues.map((usecase) => ({
      ...usecase,
      title: `${usecase.useCaseId} - ${usecase.useCaseName}`,
    }));
  };

  const handleModalClose = () => {
    setScope("global");
    setUseCaseName("");
    setExpanded(false);
    setRelatedTraitsModalIsOpen(false);
    setRelatedEventsModalIsOpen(false);
  };

  const validateCloneMappings = () => {
    const e = {};
    if (isEmptyString(clone.useCase)) {
      e.useCaseError = i18n.t("errors.field_required");
    }
    if (isEmptyString(clone.scope)) {
      e.scopeError = i18n.t("errors.field_required");
    }
    if (
      clone.scope !== "global" &&
      isEmpty(clone.selectedSubMenuItem?.scopeIdentifier)
    ) {
      e.scopeIdentifierError = i18n.t("errors.field_required");
    }
    return e;
  };

  const useCaseMappedTraits =
    useCase.mappedTraits?.length > 0
      ? (
          useCase.mappedTraits?.find(
            (x) => x.title === selectedSubMenuItem.scopeIdentifier
          ) || useCase?.mappedTraits[0]
        )?.traits
      : [];

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("traits_container.use_case_management")}
          </Typography>
        </div>
      }
      showSpinner={loading}
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Use Case",
              onClick: () => history.push("/use-cases"),
            },
            {
              label: t("common.labels.dcf_usecase"),
              hidden: applicationConfig.deploymentEnv === "prd",
              onClick: () =>
                history.push("/use-cases?displayTab=dcf-use-cases"),
            },
          ]}
        />
        <div className={classes.flexContainer}>
          {useCaseSelected.length > 0
            ? checkUserAuthorization(
                user.access,
                pageAccessConfig.createTraits
              ) && (
                <Button
                  onClick={() => {
                    history.push(applicationConfig.pathnames.use_cases_manage, {
                      useCaseId: manageUseCase.useCaseId,
                      useCaseName: manageUseCase.useCaseName,
                      useCaseDescription: manageUseCase.useCaseDescription,
                      selectedSubMenuItem: {
                        scopeIdentifier: "Global",
                      },
                      scope: "global",
                    });
                  }}
                  classes={{
                    root: globalStyles.btn,
                  }}
                  variant="outlined"
                  style={{
                    marginRight: "34px",
                    justifySelf: "flex-end",
                  }}
                >
                  {t("manage_use_cases.manage")}
                </Button>
              )
            : checkUserAuthorization(
                user.access,
                pageAccessConfig.createTraits
              ) && (
                <Button
                  onClick={() => {
                    history.push(
                      applicationConfig.pathnames.use_cases_manage,
                      {}
                    );
                  }}
                  classes={{
                    root: globalStyles.btn,
                  }}
                  variant="outlined"
                  style={{
                    marginRight: "34px",
                    justifySelf: "flex-end",
                  }}
                >
                  {t("traits_usecases.new_usecase")}
                </Button>
              )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              const newData = { ...data };
              if (data && data.name === applicationConfig.filters.event_name) {
                newData.value = data.value.split(" ").join("_");
              }
              setFilters(newData);
            }}
            searchFilters={traitsUsecaseSearchFilters}
            defaultFilterState={useCaseSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={useCaseClasses.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) => {
              try {
                increaseRequestsCount();
                return getUsecases(
                  parseSearchFilter([filters], filterFieldMap, page, perPage)
                );
              } catch (error) {
                handleError({
                  error,
                  handle404: false,
                  addNotification,
                });
                return error;
              } finally {
                decreaseRequestsCount();
              }
            }}
            moduleName="usecase"
            openModal={(params) => {
              if (!relatedTraitsModalIsOpen && !relatedEventsModalIsOpen) {
                if (params.field === "traits") {
                  setModule("traits");
                  setRelatedTraitsModalIsOpen(true);
                } else if (params.field === "eventProperties") {
                  setModule("events");
                  setRelatedEventsModalIsOpen(true);
                }

                setUseCase({});
              }
            }}
            filters={filters}
            renderCellData={(data) => {
              if (data.mappedTraits) {
                loadRelatedTraits(data);
              } else if (data.mappedEventProperties) {
                loadRelatedEvents(data);
              }
            }}
            setLoadingCellData={setLoadingCellData}
            columns={getAllowedColumns()}
            maxSelected={1}
            checkboxSelection
            selectionModel={useCaseSelected}
            disableSelectAllCheckbox
            onSelectionModelChange={(e, tableData) => {
              const rows = e;
              if (rows.length > 2) {
                return "";
              }
              const row = rows.filter(
                (newId) => !useCaseSelected.includes(newId)
              );
              setUseCaseSelected(row);
              setManageUseCase(tableData[row[0]]);
              return null;
            }}
            clickableCellExternalParams={[`scope=${scope}`]}
            showArrayValueInNewSheet={(key) =>
              ["traits", "eventProperties"].includes(key)
            }
            customizeExportData={(sheetsData, key, obj) => {
              const newSheetsData = {};
              Object.keys(sheetsData).forEach((sheetKey) => {
                if (["traits", "eventProperties"].includes(sheetKey)) {
                  newSheetsData[sheetKey] = sheetsData[sheetKey].map((row) => ({
                    useCaseId: obj.useCaseId,
                    useCaseName: obj.useCaseName,
                    ...row,
                  }));
                }
              });
              return newSheetsData;
            }}
            exportMainSheetName="Use Cases"
          />
        </div>
      </Paper>
      {relatedEventsModalIsOpen && (
        <CustomModal
          open={relatedEventsModalIsOpen}
          onClose={handleModalClose}
          showCloseIcon
          loading={loadingCellData && useCaseName === ""}
          title={useCase.useCaseName}
          subtitle={t("events_usecases.usecase_modal_title")}
          className={useCaseClasses.customModal}
          data={{
            scope: {
              scope: useCase.scope,
              identifier: selectedSubMenuItem.scopeIdentifier,
            },
            eventPropertiesList,
          }}
        >
          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <InputLabel id="scope-select-label">Scope</InputLabel>
            <Select
              value={scope}
              label="Scope"
              id="scope-select"
              onChange={(e) => setScope(e.target.value)}
            >
              <MenuItem value="global">Global</MenuItem>
              <MenuItem value="regional">Region</MenuItem>
              <MenuItem value="legalEntity">Legal Entity</MenuItem>
              <MenuItem value="marketingProgram">Marketing Program</MenuItem>
            </Select>
          </FormControl>
          {subMenu.length > 0 && (
            <FormControl
              fullWidth
              className={classes.accordionSelect}
              classes={{
                root: classes.mb10,
              }}
            >
              <InputLabel id="scope-select-label" shrink>
                {useCase.scope === "regional"
                  ? "Region"
                  : useCase.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Select
                labelId="scope-select-label"
                id="scope-select"
                value={selectedSubMenuItem.scopeIdentifier}
                label="Marketing Program"
                onChange={(e) => {
                  setSelectedSubMenuItem((draft) => {
                    draft.scopeIdentifier = e.target.value;
                    draft.eventName = undefined;
                  });
                }}
              >
                {subMenu.map(({ name, value }) => (
                  <MenuItem value={value} key={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!isEmpty(eventName) && (
            <FormControl
              fullWidth
              classes={{
                root: classes.mb10,
              }}
            >
              <InputLabel id="scope-select-label">Event Name</InputLabel>
              <Select
                value={eventName}
                label="Scope"
                id="scope-select"
                onChange={(e) => {
                  setEventName(e.target.value);
                  setSelectedSubMenuItem((draft) => {
                    draft.eventName = e.target.value;
                  });
                }}
              >
                {eventNamesList.map((name) => (
                  <MenuItem value={name} key={name} data-testid={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={accordionClasses.systems}>
            {!loadingCellData && eventPropertiesList.length > 0
              ? eventPropertiesList.map((row) => {
                  const rowData = {
                    propertyName: row.eventProperty,
                    description: row.eventPropertyDescription,
                    selectedLabels: row.labels?.map((y) => ({
                      ...y,
                      title: y.labelName,
                    })),
                  };
                  return <EventPropertiesAccordion eventProperty={rowData} />;
                })
              : !loadingCellData && (
                  <Alert severity="info">
                    No Event Properties Mapped at this level
                  </Alert>
                )}
            {loadingCellData && (
              <div style={{ padding: "8px" }}>
                {Array.from({ length: 3 }).map((_, i) => (
                  <>
                    <Skeleton
                      key={i}
                      variant="rounded"
                      width={640}
                      height={60}
                    />
                    <br />
                  </>
                ))}
              </div>
            )}
          </div>
          <div className={accordionClasses.footer2}>
            <div className={useCaseClasses.accordionFooter}>
              {checkUserAuthorization(
                user.access,
                pageAccessConfig.useEvents
              ) && (
                <div className={classes.btnContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loadingCellData}
                    component={Link}
                    to={{
                      pathname: "/use-cases/manage",
                      state: {
                        ...useCase,
                        selectedSubMenuItem,
                        from: applicationConfig.modules.events_use_case,
                      },
                    }}
                    onClick={() => setRequest({})}
                  >
                    {t("common.modify")}
                  </Button>
                </div>
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={
                loadingCellData ||
                !(
                  Array.isArray(useCase.mappedEventProperties) &&
                  useCase.mappedEventProperties.length > 0 &&
                  !loadingCellData
                )
              }
              classes={{
                root: globalStyles.btn,
              }}
              onClick={() => {
                cloneRef.current = "events";
                setViewCloneMappings(true);
              }}
            >
              Clone Mappings
            </Button>
          </div>
        </CustomModal>
      )}
      <CustomModal
        open={viewCloneMappings}
        onClose={() => {
          setViewCloneMappings(false);
          setClone({});
        }}
        showCloseIcon
        title="Clone Mappings"
      >
        <div className={classes.inputContainer} key="marketing-program">
          <InputLabel
            id="scope-value-label"
            classes={{
              root: globalStyles.label,
            }}
          >
            Use Case
          </InputLabel>
          <Autocomplete
            options={useCases()}
            filterOptions={(options) => options}
            id="use-case"
            placeholder="Please select an Use Case"
            loading={useCasesLoading}
            getOptionLabel={(option) => option.title || ""}
            value={clone.useCase}
            onChange={(event, value) => {
              setClone({
                ...clone,
                useCase: value,
              });
              setErrors({
                ...errors,
                useCaseError: "",
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Please select an Use Case"
                onChange={(event) => {
                  setUseCasesLoading(true);
                  setUseCaseOptions([]);
                  setUseCaseValue(event.target.value);
                  memoizedUseCasesFromApi(event.target.value);
                }}
              />
            )}
          />
          {errors.useCaseError && (
            <FormHelperText
              id="my-helper-text"
              error={Boolean(errors.useCaseError)}
            >
              <InlineMessage message={errors.useCaseError} />
            </FormHelperText>
          )}
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <FormControl fullWidth>
              <InputLabel
                id="scope-label"
                classes={{
                  root: globalStyles.label,
                  shrink: classes.shrinkLabel,
                }}
              >
                Scope
              </InputLabel>
              <Select
                value={clone.scope}
                label="Scope"
                id="scope"
                onChange={(e) => {
                  setClone({
                    ...clone,
                    scope: e.target.value,
                    selectedSubMenuItem: {},
                  });
                  setSubMenuValue("");
                  setErrors({
                    ...errors,
                    scopeError: "",
                  });
                }}
              >
                <MenuItem value="global" data-testid="global-option">
                  Global
                </MenuItem>
                <MenuItem value="regional" data-testid="regional-option">
                  Region
                </MenuItem>
                <MenuItem value="legalEntity" data-testid="legal-option">
                  Legal Entity
                </MenuItem>
                <MenuItem
                  value="marketingProgram"
                  data-testid="marketing-option"
                >
                  Marketing Program
                </MenuItem>
              </Select>
              {errors.scopeError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.scopeError)}
                >
                  <InlineMessage message={errors.scopeError} />
                </FormHelperText>
              )}
            </FormControl>
          </div>
          {clone.scope && clone.scope !== "global" && (
            <div
              className={clsx(classes.inputContainer, classes.justifyContent)}
              key="region"
            >
              <InputLabel
                id="scope-value-label"
                classes={{
                  root: globalStyles.label,
                }}
              >
                {clone.scope === "regional"
                  ? "Region"
                  : clone.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Autocomplete
                options={subList()}
                id="regions"
                placeholder={`Please select a ${
                  clone.scope === "regional"
                    ? "Region"
                    : clone.scope
                        ?.replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())
                }`}
                loading={marketingProgramsLoading || legalEntitiesLoading}
                getOptionLabel={(option) => option.title || ""}
                value={clone.selectedSubMenuItem}
                onChange={(event, value) => {
                  setClone({
                    ...clone,
                    selectedSubMenuItem: {
                      ...value,
                      scopeIdentifier: isEmpty(value) ? {} : value.title,
                    },
                  });
                  setErrors({
                    ...errors,
                    scopeIdentifierError: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={`Please select a ${
                      clone.scope === "regional"
                        ? "Region"
                        : clone.scope
                            ?.replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())
                    }`}
                    onChange={(event) => {
                      if (clone.scope === "marketingProgram") {
                        setMarketingProgramsLoading(true);
                        setMarketingProgramOptions([]);
                        getMarketingProgramsFromApi(event.target.value);
                      }
                      if (clone.scope === "legalEntity") {
                        setLegalEntities([]);
                        memoizedLegalEntitiesFromApi(event.target.value);
                      }
                      setSubMenuValue(event.target.value);
                    }}
                  />
                )}
              />
              {errors.scopeIdentifierError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.scopeIdentifierError)}
                >
                  <InlineMessage message={errors.scopeIdentifierError} />
                </FormHelperText>
              )}
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={() => {
              const validationErrors = validateCloneMappings();
              setErrors(validationErrors);
              if (
                validationErrors &&
                Object.values(validationErrors).filter((e) => e).length > 0
              ) {
                return;
              }
              if (cloneRef.current === "traits") {
                history.push("/use-cases/manage", {
                  from: applicationConfig.modules.traits_use_case,
                  ...clone,
                  ...clone.useCase,
                  clonedTraits: useCaseMappedTraits.map((trait) => ({
                    ...trait,
                    title: trait.traitName,
                    new: true,
                  })),
                });
              } else {
                history.push("/use-cases/manage", {
                  from: applicationConfig.modules.events_use_case,
                  ...clone,
                  ...clone.useCase,
                  clonedEventProperties: eventProperties.map((property) => ({
                    ...property,
                    title: property.eventName,
                    new: true,
                  })),
                });
              }
            }}
          >
            Clone
          </Button>
        </div>
      </CustomModal>
      {relatedTraitsModalIsOpen && (
        <CustomModal
          open={relatedTraitsModalIsOpen}
          onClose={handleModalClose}
          title={useCaseName}
          showCloseIcon
          loading={loadingCellData && useCaseName === ""}
          subtitle={t("traits_usecases.usecase_modal_title")}
          contentClassName={useCaseClasses.content}
          data={{
            scope: {
              scope: useCase.scope,
              subMenuItem: selectedSubMenuItem.scopeIdentifier,
            },
            useCaseMappedTraits,
          }}
        >
          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <InputLabel id="scope-select-label">Scope</InputLabel>
            <Select
              value={scope}
              label="Scope"
              id="scope-select"
              onChange={(e) => {
                setExpanded(false);
                setScope(e.target.value);
              }}
            >
              <MenuItem value="global">Global</MenuItem>
              <MenuItem value="regional">Region</MenuItem>
              <MenuItem value="legalEntity">Legal Entity</MenuItem>
              <MenuItem value="marketingProgram">Marketing Program</MenuItem>
            </Select>
          </FormControl>
          {subMenu.length > 0 && !loadingCellData && (
            <FormControl
              fullWidth
              className={classes.accordionSelect}
              classes={{
                root: classes.mb10,
              }}
            >
              <InputLabel id="scope-select-label" shrink>
                {useCase.scope === "regional"
                  ? "Region"
                  : useCase.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Select
                labelId="scope-select-label"
                id="scope-select"
                value={selectedSubMenuItem.scopeIdentifier}
                label="Marketing Program"
                onChange={(e) => {
                  setExpanded(false);
                  setSelectedSubMenuItem((draft) => {
                    draft.scopeIdentifier = e.target.value;
                  });
                }}
              >
                {subMenu.map(({ name, value }) => (
                  <MenuItem value={value} key={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={accordionClasses.systems}>
            {Array.isArray(useCase.mappedTraits) &&
            !loadingCellData &&
            !isEmpty(useCaseMappedTraits)
              ? useCaseMappedTraits?.map((row, i) => {
                  const id = `system-${i.toString()}`;
                  const rowTraitName = truncateString(row.traitName, {
                    maxLength: 40,
                    showEllipsis: true,
                  });
                  const rowTraitDescription = truncateString(row.description, {
                    maxLength: 40,
                    showEllipsis: true,
                  });
                  return (
                    <Accordion
                      key={i.toString()}
                      className={accordionClasses.accordion}
                      expanded={expanded === id}
                      onChange={toggleAccordion(id)}
                    >
                      <AccordionSummary
                        data-testid="accordion-summary"
                        className={accordionClasses.accordionHeader}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={accordionClasses.systemType}>
                          {rowTraitName}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={accordionClasses.systemName}
                        >
                          {rowTraitDescription}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={accordionClasses.accordionContent}
                      >
                        <AccordionRowDetails
                          keys={relatedTraitsKeys}
                          row={{
                            ...row,
                            traitName: truncateString(row.traitName, {
                              maxLength: 90,
                              showEllipsis: true,
                            }),
                            description: truncateString(row.description, {
                              maxLength: 90,
                              showEllipsis: true,
                            }),
                            labels: row.labels?.map((x, idx) => (
                              <div key={idx} className={classes.accordionChips}>
                                <Chip
                                  label={x.labelName}
                                  color="primary"
                                  avatar={
                                    x.labelId ? <></> : <Avatar>NEW</Avatar>
                                  }
                                />
                              </div>
                            )),
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : !loadingCellData && (
                  <Alert severity="info">No Traits Mapped at this level</Alert>
                )}
            {loadingCellData && (
              <div style={{ padding: "8px" }}>
                {Array.from({ length: 3 }).map((_, i) => (
                  <>
                    <Skeleton
                      key={i}
                      variant="rounded"
                      width={640}
                      height={60}
                    />
                    <br />
                  </>
                ))}
              </div>
            )}
          </div>
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createTraits
          ) && (
            <div className={accordionClasses.footer2}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                disabled={loadingCellData}
                classes={{
                  root: globalStyles.btn,
                }}
                to={{
                  pathname: "/use-cases/manage",
                  state: {
                    ...useCase,
                    selectedSubMenuItem,
                    from: applicationConfig.modules.traits_use_case,
                  },
                }}
                onClick={() => setRequest({})}
              >
                {t("common.modify")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  loadingCellData ||
                  !(
                    Array.isArray(useCase.mappedTraits) &&
                    useCase.mappedTraits.length > 0 &&
                    !loadingCellData
                  )
                }
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={() => {
                  cloneRef.current = "traits";
                  setViewCloneMappings(true);
                }}
              >
                Clone Mappings
              </Button>
              <Button
                disabled={
                  loadingCellData ||
                  !(
                    Array.isArray(useCase.mappedTraits) &&
                    useCase.mappedTraits.length > 0 &&
                    !loadingCellData
                  )
                }
                onClick={() => {
                  setRequest({});
                  history.push({
                    pathname: applicationConfig.pathnames.traits_map,
                    state: {
                      flow: "reuse",
                      traits:
                        useCaseMappedTraits || useCase.mappedTraits[0].traits,
                    },
                  });
                }}
                variant="contained"
                color="primary"
                classes={{
                  root: globalStyles.btn,
                }}
              >
                {t("manage_use_cases.reuse")}
              </Button>
            </div>
          )}
        </CustomModal>
      )}
    </Layout>
  );
};

UseCaseManagementContainer.propTypes = {};

export default UseCaseManagementContainer;
