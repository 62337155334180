import { useCallback, useEffect, useMemo, useState, Fragment } from "react";

import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getMarketingProgramsApi from "../../../../api/get-marketing-programs";

import CustomAutoComplete from "../../../../components/CustomAutoComplete";

import applicationConfig from "../../../../config/applicationConfig";

import useLoadingSpinner from "../../../../hooks/useLoadingSpinner";
import useNotifier from "../../../../hooks/useNotifier";

import debounce from "../../../../utilities/debounce";
import handleError from "../../../../utilities/handleError";

import validateConsentMappingForm from "../helpers/validateConsentMappingForm";

import useStyles from "./styles";

const ConsentID = ({
  consentTemplateTypeValue,
  setConsentTemplateTypeValue,
  consentTemplateTypes,
  consentTemplatesLoading,
  marketingProgramSelected,
  setMarketingProgramSelected,
  showValidation,
}) => {
  const { t } = useTranslation();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const classes = useStyles();
  const { addNotification } = useNotifier();

  const [consentTemplateTypeInputValue, setConsentTemplateTypeInputValue] =
    useState("");

  const errors = useMemo(
    () =>
      validateConsentMappingForm(
        consentTemplateTypeValue,
        marketingProgramSelected
      ),
    [consentTemplateTypeValue, marketingProgramSelected]
  );

  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [marketingProgramInputVal, setMarketingProgramInputVal] = useState("");
  useEffect(() => {
    setMarketingProgramInputVal(marketingProgramSelected?.title);
  }, [marketingProgramSelected]);
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(true);

  const getMarketingProgramsFromApi = useCallback(async (searchText) => {
    let filter = { itemsPerPage: 3, page: 1 };
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const rsp1 = await getMarketingProgramsApi(filter);
      setMarketingProgramOptions(rsp1.items);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setMarketingProgramOptions([]);
        },
        addNotification,
      });
    } finally {
      setMarketingProgramsLoading(false);
    }
  }, []);

  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );
  const memoizedMarketingProgramsFromApi = useCallback((val) => {
    debouncedMarketingProgramsFromApi(val);
  }, []);

  useEffect(async () => {
    setMarketingProgramsLoading(true);
    increaseRequestsCount(1);
    await getMarketingProgramsFromApi("");
    decreaseRequestsCount(1);
    setMarketingProgramsLoading(false);
  }, []);

  const [focused, setFocused] = useState({
    consentTemplateType: false,
    marketingProgram: false,
  });

  return (
    <div className={classes.grid}>
      {consentTemplatesLoading && marketingProgramsLoading ? (
        <div style={{ padding: "8px" }}>
          {Array.from({ length: 3 }).map((_, i) => (
            <Fragment key={i}>
              <Skeleton variant="rect" width="100%" height={60} />
              <br />
            </Fragment>
          ))}
        </div>
      ) : (
        <>
          <div className={classes.inputContainer}>
            <Typography>Marketing Program</Typography>
            <CustomAutoComplete
              isMultiple={false}
              hideCheckbox
              disableCloseOnSelect={false}
              options={marketingProgramOptions.map((option) => ({
                ...option,
                title: `${option.marketingProgramNumber} - ${option.description}`,
              }))}
              id="marketing-programs"
              placeholder={t("autocomplete.marketing_program_placeholder")}
              loading={marketingProgramsLoading}
              value={marketingProgramSelected}
              inputValue={marketingProgramInputVal}
              setValue={(value) => {
                setMarketingProgramSelected(value);
                setMarketingProgramInputVal(value?.title);
              }}
              onInputChange={(val) => {
                setMarketingProgramsLoading(true);
                setMarketingProgramOptions([]);
                memoizedMarketingProgramsFromApi(val);
                setMarketingProgramInputVal(val);
              }}
              onBlur={() => {
                if (!focused.marketingProgram) {
                  setFocused((f) => ({
                    ...f,
                    marketingProgram: true,
                  }));
                }
              }}
              error={
                (focused.marketingProgram || showValidation) &&
                errors.marketingProgramError
              }
              errorText={errors.marketingProgramError}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>{t("common.labels.consent_template_type")}</Typography>
            <CustomAutoComplete
              isMultiple={false}
              hideCheckbox
              disableCloseOnSelect={false}
              disableFilterOptions
              id="consent-template-id"
              loading={!loading}
              placeholder={t(
                "create_consent_template.placeholders.consent_template_type"
              )}
              options={consentTemplateTypes.map((type) => ({
                ...type,
                title: `${type.consentTemplateTypeId} - ${type.description}`,
              }))}
              value={consentTemplateTypeValue}
              inputValue={
                consentTemplateTypeInputValue || consentTemplateTypeValue?.title
              }
              setValue={(value) => {
                setConsentTemplateTypeValue(value);
                setConsentTemplateTypeInputValue(value?.title);
              }}
              onInputChange={setConsentTemplateTypeInputValue}
              onBlur={() => {
                if (!focused.consentTemplateType) {
                  setFocused((f) => ({
                    ...f,
                    consentTemplateType: true,
                  }));
                }
              }}
              error={
                (focused.consentTemplateType || showValidation) &&
                errors.consentTemplateTypeError
              }
              errorText={errors.consentTemplateTypeError}
            />
          </div>
        </>
      )}
    </div>
  );
};

ConsentID.defaultProps = {
  showValidation: false,
};

ConsentID.propTypes = {
  consentTemplateTypeValue: PropTypes.shape({
    consentTemplateTypeId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  setConsentTemplateTypeValue: PropTypes.func.isRequired,
  consentTemplateTypes: PropTypes.arrayOf({
    consentTemplateTypeId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  consentTemplatesLoading: PropTypes.bool.isRequired,
  marketingProgramSelected: PropTypes.shape({
    description: PropTypes.string,
    legalEntityName: PropTypes.string,
    legalEntityNumber: PropTypes.number,
    marketingProgramName: PropTypes.number,
    marketingProgramNumber: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  setMarketingProgramSelected: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
};

export default ConsentID;
