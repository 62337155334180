import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { requestIdPrefix } = applicationConfig;

const updateBatchRequests = async (batchRequests) => {
  const url = `/requests`;
  const updatedBatchRequests = {
    ...batchRequests,
    requests: batchRequests.requests.map((request) => ({
      ...request,
      requestId: request.requestId.replace(requestIdPrefix, ""),
      module: request.module.toLowerCase().replace(" ", "-"),
    })),
  };
  const {
    data: { data },
  } = await api.patch(url, updatedBatchRequests);
  return data;
};

export default updateBatchRequests;
