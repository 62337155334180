import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { requestIdPrefix } = applicationConfig;

const getRequests = async (queryParams) => {
  const apiUrl = "/requests";
  const {
    data: { data },
  } = await api.get(apiUrl + queryParams);
  data.items = data.items.map((item) => ({
    ...item,
    requestId: `${requestIdPrefix}${item.requestId}`,
  }));
  return data;
};

export default getRequests;
