import api from "../utilities/api";

const retryJob = async (jobId, params) => {
  const url = `/jobs/${jobId}`;
  const {
    data: { data },
  } = await api.patch(url, {
    ...params,
  });
  return data;
};

export default retryJob;
