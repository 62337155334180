import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "../../../../components/Form";
import i18n from "../../../../i18n/init";

import getSourceFields from "../../CreateSourcesContainer/getSourceFields";

import useStyles from "./styles";

const NewDataSource = ({
  isOpen,
  newDataSource,
  setNewDataSourceModal,
  title,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  let newDataSourceFields = getSourceFields(newDataSource);
  newDataSourceFields = newDataSourceFields.map((field) => {
    if (field.label === t("new_source.kind_of_data_collected")) {
      return {
        ...field,
        props: {
          ...field.props,
          disabled: true,
          variant: "outlined",
          multiple: true,
          renderValue: (selected) => selected,
        },
      };
    }
    return {
      ...field,
      props: {
        ...field.props,
        disabled: true,
        variant: "outlined",
      },
    };
  });

  const handleModalClose = () => {
    setNewDataSourceModal(false);
  };

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <DialogContent>
        <div>
          <div className={classes.modalHeaderWrapper}>
            <Typography variant="h5">{title}</Typography>
            <IconButton aria-label="close" onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.horizontalBar} />

          <Form
            fields={newDataSourceFields}
            fieldClassName={classes.inputContainer}
            containerClassName={classes.columnGrid}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

NewDataSource.defaultProps = {
  isOpen: false,
  newDataSource: {},
  setNewDataSourceModal: () => {},
  title: i18n.t("common.labels.new_segment_source"),
};

NewDataSource.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  newDataSource: PropTypes.shape({}),
  setNewDataSourceModal: PropTypes.func,
};

export default NewDataSource;
