import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import NewTraitsResponseContainer from "./NewTraitsResponseContainer";

const TraitsResponsesManagementModule = () => {
  const paths = [
    applicationConfig.basePaths.event_property_responses,
    applicationConfig.basePaths.traits_responses,
  ];
  return (
    <Switch>
      <Route
        path={paths.map((path) => `${path}/manage`)}
        render={() => <NewTraitsResponseContainer />}
      />
      <Route
        path={paths.map((path) => `${path}/revision`)}
        render={() => <NewTraitsResponseContainer />}
      />
      <Route
        path={paths.map((path) => `${path}/*`)}
        render={() => <Redirect to="/" />}
      />
    </Switch>
  );
};

export default TraitsResponsesManagementModule;
