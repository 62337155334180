const mapValuesFromKeys = (data, label, value, keys) => {
  return data
    .filter((obj) => {
      if (keys.find((key) => obj[label] === key)) {
        return obj[value];
      }
      return false;
    })
    .map((obj) => obj[value]);
};

export default mapValuesFromKeys;
