import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: `${theme.palette.background.default1} !important`,
      },
    },
    newConsentContainer: {
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.palette.text.primary,
      },
      "& > *": {
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
  };
});

export default useStyles;
