import api from "../utilities/api";

import { getValue } from "../utilities/cache";
import isEmpty from "../utilities/isEmpty";

const getOpts = async (queryParams) => {
  let apiUrl = `/opts`;
  const refreshCache = getValue("opts");
  if (!isEmpty(queryParams)) {
    apiUrl += `${queryParams}&refreshCache=${refreshCache}`;
  } else {
    apiUrl += `?refreshCache=${refreshCache}`;
  }
  apiUrl += `&schemaVersion=v2`;
  const {
    data: { data },
  } = await api.get(apiUrl);

  return data;
};

export default getOpts;
