import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getConsentTemplates = async (consentTemplateKey, params) => {
  const refreshCache = getValue("consents");
  let url = `/consent-templates`;
  if (params) {
    url += `${params}&refreshCache=${refreshCache}`;
  } else {
    url += `?refreshCache=${refreshCache}`;
  }
  if (consentTemplateKey) {
    url += `&consentTemplateKey=${consentTemplateKey}`;
  }
  const {
    data: { data },
  } = await api.get(url);

  return data;
};

export default getConsentTemplates;
