import { useCallback, useMemo, useRef, useState, useEffect } from "react";

import {
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs as MuiTabs,
  Tab,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getUsecases from "../../../api/get-use-cases";
import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import { AccordionModalDetails } from "../../../components/AccordionModal";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import CustomModal from "../../../components/CustomModal";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useCaseManagementModuleConfig from "../../../config/useCaseManagementModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import handleError from "../../../utilities/handleError";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import useStyles from "../../Traits/TraitsContainer/styles";
import useCaseStyles from "../UseCaseManagementContainer/styles";

const {
  dcfUseCaseTableColumns,
  dcfUseCaseSearchFilters,
  dcfUseCaseSearchDefaultFilterState,
} = useCaseManagementModuleConfig;

const filterFieldMap = {
  useCaseName: "useCaseName",
  services: "services",
  isDCFUseCase: "isDCFUseCase",
  "": "searchText",
};

function DCFUseCases() {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const useCaseClasses = useCaseStyles();
  const { user } = useUserProfile();
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchUsecase
  );
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const { addNotification } = useNotifier();
  const { t } = useTranslation();
  const history = useHistory();
  const childRef = useRef();
  const [filters, setFilters] = useState({});
  const resetFilter = useCallback(() => {
    childRef.current.clearFilter();
    setFilters({});
  }, [childRef.current, setFilters]);
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [openDCFUseCaseDetailsModal, setOpenDCFUseCaseDetailsModal] =
    useState(false);
  const [dcfUseCase, setDcfUseCase] = useState();
  const [scope, setScope] = useState("global");
  const scopeMapping = useMemo(
    () => ({
      global: "Global",
      legalEntity: "Legal Entity",
      marketingProgram: "Marketing Program",
    }),
    []
  );
  const dcfUseCaseScopeMapping = useMemo(() => {
    if (Array.isArray(dcfUseCase?.mappings)) {
      return dcfUseCase?.mappings?.find((x) => x.scope === scopeMapping[scope]);
    }
    return null;
  }, [scope, dcfUseCase]);
  const detailsKeys = useMemo(() => {
    const keys = [
      {
        name: t("dcf_use_cases.table_columns.crs_use_case_id"),
        id: "useCaseId",
      },
      {
        name: t("dcf_use_cases.table_columns.pipeline_name"),
        id: "useCaseName",
      },
      {
        name: t(
          "dcf_use_cases.table_columns.implemented_in_below_microservices"
        ),
        id: "cdpServiceMapping",
      },
      {
        name: t("dcf_use_cases.exceptions"),
        id: "exceptions",
      },
    ];
    if (scope === "legalEntity") {
      keys.push({
        name: t("dcf_use_cases.applicable_legal_entities"),
        id: "applicableEntities",
      });
    } else if (scope === "marketingProgram") {
      keys.push({
        name: t("dcf_use_cases.applicable_marketing_programs"),
        id: "applicableEntities",
      });
    }
    return keys;
  }, [scope]);
  const schemaTemplatesMapping = useMemo(() => {
    if (dcfUseCaseScopeMapping?.schemaValidation) {
      return {
        ...dcfUseCaseScopeMapping.schemaValidation,
        schemaTemplate: JSON.stringify(
          dcfUseCaseScopeMapping?.schemaValidation?.schemaTemplate
        ),
        title: t("dcf_use_cases.schema_template.schema"),
      };
    }
    return null;
  }, [dcfUseCase]);
  const exceptions = useMemo(() => {
    if (scope === "legalEntity") {
      return dcfUseCase?.exceptions?.legalEntities?.split(",");
    }
    if (scope === "marketingPrograms") {
      return dcfUseCase?.exceptions?.marketingPrograms?.split(",");
    }
    return null;
  }, [scope, dcfUseCase]);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (scope !== "global" && tab === 0) {
      setTab(1);
    }
  }, [scope, tab]);

  const applicableEntities = useMemo(() => {
    const scopeToKey = {
      legalEntity: "legalEntities",
      marketingProgram: "marketingPrograms",
    };
    switch (tab) {
      case 1:
        return dcfUseCaseScopeMapping?.dataQualityRulesScopes?.[
          scopeToKey[scope]
        ]?.split(",");
      case 2:
        return dcfUseCaseScopeMapping?.dataTransformationScopes?.[
          scopeToKey[scope]
        ]?.split(",");
      case 3:
        return dcfUseCaseScopeMapping?.mlFunctionScopes?.[
          scopeToKey[scope]
        ]?.split(",");
      default:
        return null;
    }
  }, [scope, tab, dcfUseCaseScopeMapping]);

  const handleModalClose = useCallback(() => {
    setOpenDCFUseCaseDetailsModal(false);
    setTab(0);
    setScope("global");
  }, [setOpenDCFUseCaseDetailsModal]);

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("traits_container.use_case_management")}
          </Typography>
        </div>
      }
      showSpinner={loading}
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={1}
          tabList={[
            {
              label: t("common.labels.usecase"),
              onClick: () => history.push("/use-cases"),
            },
            {
              label: t("common.labels.dcf_usecase"),
              hidden: applicationConfig.deploymentEnv === "prd",
              onClick: () =>
                history.push("/use-cases?displayTab=dcf-use-cases"),
            },
          ]}
        />
        <div className={classes.flexContainer}>
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={dcfUseCaseSearchFilters}
            defaultFilterState={dcfUseCaseSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={useCaseClasses.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) => {
              try {
                increaseRequestsCount();
                return getUsecases(
                  parseSearchFilter(
                    [
                      {
                        name: "isDCFUseCase",
                        value: true,
                      },
                      filters,
                    ],
                    filterFieldMap,
                    page,
                    perPage
                  )
                );
              } catch (error) {
                handleError({
                  error,
                  handle404: false,
                  addNotification,
                });
                return error;
              } finally {
                decreaseRequestsCount();
              }
            }}
            moduleName="dcf-use-cases"
            openModal={() => {
              setOpenDCFUseCaseDetailsModal(true);
            }}
            filters={filters}
            renderCellData={(data) => {
              setDcfUseCase(data);
            }}
            setLoadingCellData={setLoadingCellData}
            columns={dcfUseCaseTableColumns}
            clickableCellExternalParams={[`isDCFUseCase=true`]}
            exportMainSheetName="DCF Use Cases"
          />
        </div>
      </Paper>
      {openDCFUseCaseDetailsModal && (
        <CustomModal
          style={{ maxWidth: 800 }}
          open={openDCFUseCaseDetailsModal}
          onClose={handleModalClose}
          showCloseIcon
          loading={loadingCellData}
          title={dcfUseCase?.useCaseName}
          subtitle="DCF Use Case Details are listed below"
          className={useCaseClasses.customModal}
          data={{
            details: {
              scope,
              useCaseId: dcfUseCase?.useCaseId,
              useCaseName: dcfUseCase?.useCaseName,
              cdpServiceMapping: dcfUseCase?.cdpServiceMapping,
              exceptions,
              [(() => {
                if (scope === "legalEntity") {
                  return "applicableLegalEntities";
                }
                if (scope === "marketingProgram") {
                  return "applicableMarketingPrograms";
                }
                return null;
              })()]: applicableEntities,
            },
            schemaTemplates: dcfUseCaseScopeMapping?.schemaValidation,
            dataQualityRules: dcfUseCaseScopeMapping?.dataQualityRules,
            dataTransformationFunctions:
              dcfUseCaseScopeMapping?.dataTransformationFunctions,
            mlFunctions: dcfUseCaseScopeMapping?.mlFunctions,
          }}
        >
          <AccordionRowDetails
            keys={detailsKeys}
            row={{
              useCaseId: dcfUseCase?.useCaseId,
              useCaseName: dcfUseCase?.useCaseName,
              cdpServiceMapping: dcfUseCase?.cdpServiceMapping
                ?.split(",")
                ?.map((x) => <div>{x}</div>),
              exceptions: exceptions?.map((exception) => (
                <div>{exception}</div>
              )),
              applicableEntities: applicableEntities?.map((x) => (
                <div>{x}</div>
              )),
            }}
          />
          <FormControl fullWidth style={{ margin: "16px 0" }}>
            <InputLabel id="scope-select-label">Scope</InputLabel>
            <Select
              value={scope}
              label="Scope"
              id="scope-select"
              onChange={(e) => setScope(e.target.value)}
            >
              <MenuItem value="global">{t("common.global")}</MenuItem>
              <MenuItem value="legalEntity">
                {t("common.labels.legal_entity")}
              </MenuItem>
              <MenuItem value="marketingProgram">
                {t("common.labels.marketing_program")}
              </MenuItem>
            </Select>
          </FormControl>
          <MuiTabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalStyles.indicator,
            }}
            style={{
              marginBottom: "16px",
            }}
          >
            {scope === "global" && <Tab value={0} label="Schema Validation" />}
            <Tab value={1} label="Data Quality Rules" />
            <Tab value={2} label="Transformations" />
            <Tab value={3} label="ML" />
          </MuiTabs>
          {tab === 0 && (
            <AccordionRowDetails
              emptyDataMessage={t(
                "dcf_use_cases.schema_template.empty_message"
              )}
              keys={[
                {
                  name: t("dcf_use_cases.schema_template.schema_id"),
                  id: "schemaId",
                },
                {
                  name: t("dcf_use_cases.schema_template.schema_version"),
                  id: "schemaVersion",
                },
                {
                  name: t("dcf_use_cases.schema_template.schema_name"),
                  id: "schemaName",
                },
                {
                  name: t("dcf_use_cases.schema_template.schema_template"),
                  id: "schemaTemplate",
                },
              ]}
              row={schemaTemplatesMapping}
              rowKeys={{
                title: "title",
                subTitle: "schemaName",
              }}
            />
          )}
          {tab === 0 && !schemaTemplatesMapping && (
            <Alert severity="info">
              <Typography variant="body2">
                {t("dcf_use_cases.schema_template.empty_message")}
              </Typography>
            </Alert>
          )}
          {tab === 1 && (
            <AccordionModalDetails
              emptyDataMessage={t("dcf_use_cases.rule.empty_message")}
              keys={[
                {
                  name: t("dcf_use_cases.rule.rule"),
                  id: "ruleId",
                },
                {
                  name: t("dcf_use_cases.rule.rule_version"),
                  id: "ruleVersion",
                },
                {
                  name: t("dcf_use_cases.rule.rule_name"),
                  id: "ruleName",
                },
                {
                  name: t("dcf_use_cases.rule.sequence_number"),
                  id: "sequenceNumber",
                },
                {
                  name: t("dcf_use_cases.rule.validation_rule"),
                  id: "validationRule",
                  preformat: true,
                  formatName: "Rule",
                },
                {
                  name: t("dcf_use_cases.rule.rule_failure_outcome"),
                  id: "ruleFailureOutcome",
                },
              ]}
              rows={dcfUseCaseScopeMapping?.dataQualityRules?.map((x) => ({
                ...x,
                validationRule: JSON.stringify(x.validationRule),
                title: "RULE",
              }))}
              rowKeys={{
                title: "title",
                subTitle: "ruleName",
              }}
            />
          )}
          {tab === 2 && (
            <AccordionModalDetails
              emptyDataMessage={t("dcf_use_cases.functions.empty_message")}
              keys={[
                {
                  name: t("dcf_use_cases.functions.function_id"),
                  id: "functionId",
                },
                {
                  name: t("dcf_use_cases.functions.function_version"),
                  id: "functionVersion",
                },
                {
                  name: t("dcf_use_cases.functions.function_name"),
                  id: "functionName",
                },
                {
                  name: t("dcf_use_cases.functions.function_type"),
                  id: "functionType",
                },
                {
                  name: t("dcf_use_cases.functions.sequence_number"),
                  id: "sequenceNumber",
                },
                {
                  name: t("dcf_use_cases.functions.transformation_function"),
                  id: "transformationFunction",
                  preformat: true,
                  formatNameKey: "functionType",
                },
              ]}
              rows={dcfUseCaseScopeMapping?.dataTransformationFunctions?.map(
                (x) => ({
                  ...x,
                  title: "TRANSFORMATION",
                })
              )}
              rowKeys={{
                title: "title",
                subTitle: "functionName",
              }}
            />
          )}
          {tab === 3 && (
            <AccordionModalDetails
              emptyDataMessage={t("dcf_use_cases.functions.empty_message")}
              keys={[
                {
                  name: t("dcf_use_cases.functions.function_id"),
                  id: "functionId",
                },
                {
                  name: t("dcf_use_cases.functions.function_version"),
                  id: "functionVersion",
                },
                {
                  name: t("dcf_use_cases.functions.function_name"),
                  id: "functionName",
                },
                {
                  name: t("dcf_use_cases.functions.function_type"),
                  id: "functionType",
                },
                {
                  name: t("dcf_use_cases.functions.sequence_number"),
                  id: "sequenceNumber",
                },
                {
                  name: t("dcf_use_cases.functions.transformation_function"),
                  id: "transformationFunction",
                  preformat: true,
                  formatNameKey: "functionType",
                },
              ]}
              rows={dcfUseCaseScopeMapping?.mlFunctions?.map((x) => ({
                ...x,
                title: "ML",
              }))}
              rowKeys={{
                title: "title",
                subTitle: "functionName",
              }}
            />
          )}
        </CustomModal>
      )}
    </Layout>
  );
}

export default DCFUseCases;
