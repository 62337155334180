import applicationConfig from "../config/applicationConfig";

const { paginationConfig } = applicationConfig;

const parseSearchFilter = (
  filters,
  filterFieldMap,
  page = paginationConfig.defaultPage,
  perPage = paginationConfig.defaultPerPage
) => {
  let parsedUrl = "";
  parsedUrl += `?page=${page}&itemsPerPage=${perPage}`;

  if (!filters.length) {
    return parsedUrl;
  }

  filters.forEach((filter) => {
    if (filter && filterFieldMap[filter.name] && filter.value) {
      parsedUrl += `&${filterFieldMap[filter.name]}=${filter.value}`;
    }
  });

  return parsedUrl;
};

export default parseSearchFilter;
