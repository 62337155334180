import { useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import applicationConfig from "../../config/applicationConfig";
import InlineMessage from "../InlineMessage";

import useStyles from "./styles";

const ConfirmationModal = ({
  open,
  onClose,
  title,
  message,
  btn1Text,
  btn1Action,
  btn2Text,
  btn2Action,
  isBatchRequest,
  commentRef,
  type,
  status,
  loading,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const { requestStatus } = applicationConfig;

  const commentLengthError = comment.length > 475;
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="confirmation-modal"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.container,
      }}
    >
      <DialogContent>
        <Typography variant="h4">{title}</Typography>
        {typeof message === "string" ? (
          <Typography variant="h6">{message}</Typography>
        ) : (
          message
        )}
        {(status === requestStatus.PENDING.toLowerCase() ||
          status === requestStatus.RETURNED.toLowerCase() ||
          isBatchRequest) &&
          commentRef &&
          btn2Text !== "APPROVE" && (
            <>
              <div className={classes.horizontalContainer} />
              <div className={classes.commentContainer}>
                <div data-testid="comment-test-id">
                  <TextField
                    id="outlined-basic"
                    label={
                      btn2Text === "REJECT"
                        ? "Reason for rejecting"
                        : "Reason for returning"
                    }
                    variant="outlined"
                    multiline
                    rows={5}
                    rowsMax={10}
                    onChange={(e) => {
                      setComment(e.target.value);
                      // eslint-disable-next-line
                      commentRef.current = e.target.value;
                    }}
                    error={commentLengthError}
                    helperText={
                      commentLengthError ? (
                        <InlineMessage
                          message={t("common.errors.character_limit_error", {
                            count: 475,
                          })}
                        />
                      ) : null
                    }
                    value={commentRef.current}
                    inputref={commentRef}
                  />
                </div>
              </div>
            </>
          )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogFooter }}>
        <div
          className={clsx(
            classes.flexContainer,
            classes.btnContainer,
            classes[type]
          )}
        >
          <Button
            onClick={() => {
              if (!btn1Action) {
                onClose();
              } else {
                btn1Action();
              }
            }}
            color="primary"
            variant="outlined"
          >
            {btn1Text}
          </Button>
          <Button
            onClick={() => {
              if (!btn2Action) {
                onClose();
              } else {
                btn2Action();
              }
            }}
            color="secondary"
            variant="contained"
            disabled={
              loading ||
              (["REJECT", "RETURN"].includes(btn2Text) &&
                !commentRef.current) ||
              commentLengthError
            }
          >
            {loading && (
              <Box
                sx={{
                  mr: 1,
                  mt: 0.5,
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )}
            {btn2Text}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.defaultProps = {
  btn1Action: null,
  btn2Action: null,
  isBatchRequest: false,
  commentRef: null,
  status: "",
  loading: false,
};

ConfirmationModal.propTypes = {
  loading: PropTypes.bool,
  btn1Action: PropTypes.func,
  btn1Text: PropTypes.string.isRequired,
  btn2Action: PropTypes.func,
  btn2Text: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isBatchRequest: PropTypes.bool,
  status: PropTypes.string,
  commentRef: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }),
};

export default ConfirmationModal;
