import api from "../utilities/api";

import { getValue } from "../utilities/cache";

const getEventsResponse = async (propertyName, eventName, params) => {
  const url = `/events/responses`;
  const refreshCache = getValue("events");
  const {
    data: { data },
  } = await api.get(url, {
    params: {
      propertyName,
      eventName,
      refreshCache,
      ...params,
    },
  });
  return data;
};

export default getEventsResponse;
