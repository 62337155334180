import React from "react";

import { Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const TableFooter = ({
  items,
  itemsLabel,
  onCancelClick,
  proceedBtnText,
  onProceedClick,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={clsx(classes.tableFooterContainer, classes.flexContainer)}>
      <div className={classes.tableFooterLeft}>
        <div className={classes.flexContainer}>
          <Typography>{items.length}&nbsp;</Typography>
          <Typography>
            {items.length === 1 ? itemsLabel : `${itemsLabel}s`} selected
          </Typography>
        </div>
        <Typography>{items.join(",")}</Typography>
      </div>
      <div className={clsx(classes.tableFooterRight, classes.flexContainer)}>
        <Typography
          onClick={() => {
            onCancelClick();
          }}
        >
          {t("traits_container.cancel_selection")}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onProceedClick()}
        >
          {proceedBtnText}
        </Button>
      </div>
    </div>
  );
};

TableFooter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemsLabel: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  proceedBtnText: PropTypes.string.isRequired,
  onProceedClick: PropTypes.func.isRequired,
};

export default TableFooter;
