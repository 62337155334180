import React from "react";

import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import InsightsContainer from "../Insights/InsightsContainer";

import TraitsResponsesManagementContainer from "../TraitsResponses/TraitsResponsesManagementContainer";

import CreateTraitsContainer from "./CreateTraitsContainer";
import TraitsContainer from "./TraitsContainer";

const TraitsModule = React.memo(
  ({ basePath }) => {
    return (
      <Switch>
        <Route
          exact
          path={`${basePath}`}
          component={({ location: { search } }) => {
            if (search.includes(applicationConfig.modules.traits_responses)) {
              return <TraitsResponsesManagementContainer />;
            }
            if (search.includes(applicationConfig.modules.insights)) {
              return <InsightsContainer />;
            }
            return <TraitsContainer />;
          }}
        />
        <Route
          exact
          path={`${basePath}/map`}
          component={CreateTraitsContainer}
        />
        <Route
          exact
          path={`${basePath}/requests/revision`}
          component={CreateTraitsContainer}
        />
        <Route
          exact
          path={`${basePath}/*`}
          render={() => <Redirect to={`${basePath}`} />}
        />
      </Switch>
    );
  },
  () => true
);

TraitsModule.defaultProps = {
  basePath: applicationConfig.basePaths.traits,
};

TraitsModule.propTypes = {
  basePath: PropTypes.string,
};

export default TraitsModule;
