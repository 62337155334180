import i18n from "../i18n/init";

const traitsResponsesModuleConfig = {
  createTraitsResponsesConstants: {
    traitsResponsesCreationHeadings: {
      1: i18n.t("manage_traits_responses.headings.step_1"),
      2: i18n.t("manage_traits_responses.headings.step_2_a"),
      3: i18n.t("manage_traits_responses.headings.step_3_a"),
    },
    globalStandardResponsesCreationHeadings: {
      2: i18n.t("manage_traits_responses.headings.step_2_b"),
      3: i18n.t("manage_traits_responses.headings.step_3_b"),
    },
    traitsResponsesCreationMessages: {
      1: i18n.t("create_use_case.steps.step_1"),
      2: i18n.t("create_use_case.steps.step_2"),
      3: i18n.t("create_use_case.steps.step_3"),
      4: i18n.t("common.texts.request_details"),
    },
    footerText: {
      1: i18n.t("common.next"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
      4: i18n.t("create_use_case.footer.step_4"),
    },
  },
  requestStatus: {
    APPROVED: i18n.t("status.approved"),
    REJECTED: i18n.t("status.rejected"),
    PENDING: i18n.t("status.pending"),
    RETURNED: i18n.t("status.returned"),
    PROCESSING: i18n.t("status.processing"),
  },
  requestsConstants: {
    toolTipTextPending: i18n.t("traits_requests.tooltip.pending_status"),
    toolTipTextApproved: i18n.t("traits_requests.tooltip.approved_status"),
    toolTipTextReturned: i18n.t("traits_requests.tooltip.returned_status"),
  },
};

export default traitsResponsesModuleConfig;
