import { useState, useEffect, useCallback } from "react";

import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Skeleton, Alert } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useHistory,
  Prompt,
  Redirect,
  Link,
} from "react-router-dom";

import createEventMappingRequest from "../../../api/create-event-mapping-request";
import getEventProperties from "../../../api/get-event-properties";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import getRequestDetails from "../../../api/get-request-details";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomTooltip from "../../../components/CustomTooltip";
import InlineMessage from "../../../components/InlineMessage";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";

import EventPropertyModal from "../components/EventPropertyModal";

import EventsRequestOutputModal from "./components/EventsRequestOutputModal";
import createBackendPayload from "./helpers/createBackendPayload";
import useStyles from "./styles";

const UseEventContainer = () => {
  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const { t } = useTranslation();
  const { addNotification } = useNotifier();
  const { user } = useUserProfile();

  const [currentStep, setCurrentStep] = useState(1);

  const { requestStatus } = applicationConfig;

  const { useEventsConstants } = eventsModuleConfig;

  const { request, setRequest } = useRequest();
  const isRequestRevisionFlow = Object.keys(request).length !== 0;
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const totalSteps = 3;
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.useEvents
  );

  if (!isRequestRevisionFlow && !location.state) {
    return <Redirect to="/events" />;
  }

  if (isRequestRevisionFlow && Object.keys(request).length > 0) {
    location.state = request.requestDetails;
  }

  const { eventName } = location.state;

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  // Marketing Program
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [marketingProgramsSelected, setMarketingProgramsSelected] = useState(
    []
  );
  const [marketingProgramInputVal, setMarketingProgramInputVal] = useState("");
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(true);

  // Ecosystems
  const [ecoSystemsSelected, setEcosystemsSelected] = useState([
    {
      ecoSystemId: 2,
      ecoSystemName: "lytics",
      description: "lytics",
      aliasName: "lyt",
      personalDataAllowed: true,
      ecoSystemTraitPrefix: "trait_",
      ecoSystemTraitSuffix: "_id_value",
      ecoSystemTraitDelimiter: "_",
      ecoSystemTraitCharacterLimit: null,
      ecosystemId: 2,
      ecosystemName: "lytics",
      title: "lytics",
    },
  ]);
  const [formErrors, setFormErrors] = useState({});

  const [events, setEvents] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [eventSelectedIndex, setEventSelectedIndex] = useState(-1);

  // Event Output
  const [eventOutputLoading, setEventOutputLoading] = useState(false);
  const [eventPropertyModal, setEventPropertyModal] = useState(false);
  const [eventProperties, setEventProperties] = useState([]);
  const [eventOutput, setEventOutput] = useState({});
  const [eventOutputModal, setEventOutputModal] = useState(false);

  const [eventMappingResponse, setEventMappingResponse] = useState([]);

  // Hide Prompt
  const [hidePrompt, setHidePrompt] = useState(false);

  useEffect(() => {
    const selectedEventProperties = events.map(
      (event) => event.eventProperty?.propertyName || event.propertyName
    );
    setEventProperties((cs) =>
      cs.filter((item) => !selectedEventProperties.includes(item.propertyName))
    );
  }, [events]);

  const getMarketingProgramsFromApi = useCallback(async (searchText) => {
    let filter = { itemsPerPage: 3, page: 1 };
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const rsp1 = await getMarketingProgramsApi(filter);
      setMarketingProgramOptions(rsp1.items);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setMarketingProgramOptions([]);
        },
        addNotification,
      });
    } finally {
      setMarketingProgramsLoading(false);
    }
  }, []);

  useEffect(async () => {
    increaseRequestsCount(2);
    await getMarketingProgramsFromApi("");
    const data = await getEventProperties(eventName, {
      denormalize: true,
    });
    setEventProperties(data);
    decreaseRequestsCount(2);
  }, []);

  useEffect(() => {
    if (location.pathname === applicationConfig.pathnames.events_map_revision) {
      if (Object.keys(request).length === 0) {
        history.goBack();
      } else {
        const selectedEvents = request.requestDetails.eventProperties.map(
          ({ valueType, ...rest }) => ({
            eventProperty: rest,
            valueTypes: valueType,
          })
        );
        const { marketingPrograms } = request.requestDetails;
        setEvents(selectedEvents);
        setMarketingProgramsSelected(
          marketingPrograms.map((mp) => ({
            ...mp,
            title: `${mp.marketingProgramNumber} - ${mp.description}`,
          }))
        );
        setMarketingProgramInputVal("");
      }
    }
  }, []);

  const isInfoStep = currentStep > totalSteps;

  // Debounce & Memoize Api Calls
  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );
  const memoizedMarketingProgramsFromApi = useCallback((val) => {
    debouncedMarketingProgramsFromApi(val);
  }, []);

  useEffect(() => {
    if (marketingProgramInputVal.length > 0) {
      memoizedMarketingProgramsFromApi(marketingProgramInputVal);
    }
  }, [marketingProgramInputVal]);

  const handleEventOutputModal = async (reqId) => {
    setEventOutputLoading(true);
    setEventOutputModal(true);
    try {
      const response = await getRequestDetails(reqId);
      setEventOutput(response);
      setEventOutputLoading(false);
    } catch (error) {
      setEventOutputModal(true);
      setEventOutputLoading(false);
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const createRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "eventName",
      headerName: t("common.labels.event_name"),
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return <span>{value}</span>;
      },
      sortable: false,
    },
    {
      field: "ecosystems",
      headerName: t("common.labels.ecosystems"),
      flex: 1,
      renderCell: ({ value }) => <CustomTooltip items={value} />,
      sortable: false,
    },
    {
      field: "marketingPrograms",
      headerName: t("common.labels.marketing_programs"),
      flex: 1,
      truncate: true,
      renderCell: ({ value }) => <CustomTooltip items={value} />,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  handleEventOutputModal(row.requestId);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const renderEventAccordion = (event) => {
    const statusLabel = "";

    const accordionRowDetails = [
      {
        id: "propertyName",
        name: t("use_events_container.property_name"),
      },
      {
        id: "description",
        name: t("common.labels.description"),
      },
      {
        id: "dataType",
        name: t("use_events_container.accordion_details.dataType"),
      },
      {
        id: "valueTypes",
        name: t("use_events_container.accordion_details.valueTypes"),
      },
    ];

    return (
      <>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionHeader}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={clsx(classes.flexContainer, classes.fullWidth)}>
              <Typography className={classes.traitTitle}>
                {event.valueTypes.join(",")}
              </Typography>
              <Typography variant="body2" className={classes.traitDescription}>
                {event.eventProperty?.propertyName || event.propertyName}
              </Typography>
              <Typography
                variant="body2"
                className={clsx(
                  classes.statusLabel,
                  classes[statusLabel.toLowerCase()]
                )}
              >
                {statusLabel}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <AccordionRowDetails
              keys={accordionRowDetails}
              row={{
                ...(event.eventProperty ? event.eventProperty : event),
                valueTypes: event.valueTypes,
                marketingPrograms: event.marketingPrograms,
                ecoSystems: event.ecoSystems,
              }}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.event_name"),
        value: eventName,
      },
      {
        label: t("common.labels.marketing_programs"),
        value: marketingProgramsSelected
          .map(
            ({ marketingProgramNumber, description }) =>
              `${marketingProgramNumber} - ${description}`
          )
          .join(", "),
      },
      {
        label: t("common.labels.ecosystems"),
        value: ecoSystemsSelected[0].title,
      },
    ];
    return info;
  };

  const validateMappings = () => {
    if (currentStep === 1) {
      if (isEmpty(events)) {
        return {
          eventsError: "No Event Properties Mapped at this level",
        };
      }
    } else if (currentStep === 2) {
      if (marketingProgramsSelected.length === 0) {
        return {
          marketingProgramsError: "Please select atleast one marketing program",
        };
      }
    }
    return {};
  };

  const renderStep = (step) => {
    if (step === 1) {
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <div className={clsx(classes.eventContainer, classes.center)}>
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent,
              classes.fullWidth
            )}
          >
            <Typography variant="h6">
              {t("common.labels.event_name")} :
            </Typography>
            <Typography variant="h6">{eventName}</Typography>
          </div>
          {events.map((event, eventIndex) => (
            <div
              key={event.eventProperty?.propertyName || event.propertyName}
              className={clsx(
                classes.flexContainer,
                classes.fullWidth,
                classes.trait
              )}
            >
              {renderEventAccordion(event)}
              <div className={classes.flexContainer}>
                <div
                  className={classes.autoCompleteDeleteContainer}
                  onClick={() => {
                    setEventPropertyModal(true);
                    setEventSelectedIndex(eventIndex);
                  }}
                  role="button"
                  data-testid="accordion-edit"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  aria-label="edit"
                >
                  <EditOutlinedIcon />
                </div>
                <div
                  className={classes.autoCompleteDeleteContainer}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setEventSelectedIndex(eventIndex);
                  }}
                  role="button"
                  data-testid="accordion-delete"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  aria-label="delete"
                >
                  <DeleteOutlineOutlinedIcon />
                </div>
              </div>
            </div>
          ))}
          {formErrors.eventsError && (
            <InlineMessage message={formErrors.eventsError} />
          )}
          <div className={globalStyles.addTraitBtn}>
            <AddCircleOutlineIcon />
            <Button onClick={() => setEventPropertyModal(true)}>
              {t("use_events_container.add_property")}
            </Button>
          </div>
          {events.length === 0 && (
            <Alert severity="info">
              {t("events_container.errors.no_event_properties")}
            </Alert>
          )}
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className={classes.eventContainer}>
          <div className={classes.marketingProgramContainer}>
            <Typography>Marketing Program</Typography>
            <CustomAutoComplete
              options={marketingProgramOptions.map((option) => ({
                ...option,
                title: `${option.marketingProgramNumber} - ${option.description}`,
              }))}
              id="marketing-programs"
              placeholder={t("autocomplete.marketing_program_placeholder")}
              loading={marketingProgramsLoading}
              value={marketingProgramsSelected}
              inputValue={marketingProgramInputVal}
              setValue={(value) => {
                setFormErrors({});
                setMarketingProgramsSelected(value);
                setMarketingProgramInputVal("");
              }}
              onInputChange={(val) => {
                setMarketingProgramsLoading(true);
                setMarketingProgramOptions([]);
                setMarketingProgramInputVal(val);
              }}
              errorText={formErrors.marketingProgramsError}
            />
          </div>
          <div className={classes.flexContainer}>
            <Typography>{t("common.labels.ecosystems")}</Typography>
            <StyledTooltip
              placement="bottom-start"
              title={
                <span>{t("use_events_container.only_lytics_allowed")}</span>
              }
            >
              <div
                onClick={() => {}}
                role="button"
                aria-hidden="true"
                data-testid="clickable-cell"
              >
                <CustomAutoComplete
                  isDisabled
                  options={[]}
                  loading={false}
                  id="ecosystems"
                  placeholder=""
                  data-testid="ecosystems"
                  value={ecoSystemsSelected}
                  setValue={setEcosystemsSelected}
                  onInputChange={() => {}}
                />
              </div>
            </StyledTooltip>
          </div>
        </div>
      );
    }

    if (step === 3) {
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            key={`${info.label}${info.index}`}
          >
            <Typography variant="h6">{info.label} :</Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });
      return (
        <div className={classes.confirmationContainer}>
          {detailsDOM}
          <Box mb={1}>
            <Typography variant="h4">
              {t("use_events_container.associated_event_properties")}
            </Typography>
          </Box>
          {events.map((event) => (
            <div
              className={clsx(classes.eventContainer, classes.center)}
              key={event.eventProperty.propertyName}
            >
              <div className={clsx(classes.fullWidth, classes.trait)}>
                {renderEventAccordion(event)}
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div
        style={{
          height: 70 * eventMappingResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table
          columns={createRequestTableColumns}
          rows={eventMappingResponse}
          tableStyle={classes.myRequestsTable}
        />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/events"
      goToText={t("access_denied.go_to_events")}
    />
  ) : (
    <>
      <InputFlow
        header={
          <>
            <Typography variant="h4" gutterBottom>
              {useEventsConstants.eventsHeadings[currentStep]}
            </Typography>
            {currentStep === 1 && (
              <Typography variant="h6" gutterBottom>
                {t("use_events_container.select_properties_for_setup", {
                  eventName,
                })}
              </Typography>
            )}
          </>
        }
        headerText={t("events_container.events_management")}
        steps={[
          t("use_events_container.headings.step_1"),
          t("use_events_container.headings.step_2"),
          t("use_events_container.headings.step_3"),
        ]}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    {!isRequestRevisionFlow ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        classes={{
                          root: globalStyles.btn,
                        }}
                        to="/events"
                      >
                        {t("back")}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        classes={{
                          root: globalStyles.btn,
                        }}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {t("back")}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    {t("common.back")}
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                variant="outlined"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const payload = createBackendPayload(
                        eventName,
                        events,
                        marketingProgramsSelected,
                        ecoSystemsSelected
                      );

                      let data;

                      if (isRequestRevisionFlow) {
                        data = await updateRequest(
                          request.requestId,
                          payload,
                          applicationConfig.modules.events
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      } else {
                        data = await createEventMappingRequest(payload);

                        setEventMappingResponse(
                          data.items.map((item, index) => ({
                            id: index,
                            requestId: item.requestId,
                            eventName,
                            ecosystems: item.ecosystems.map(
                              (system) => system.ecosystemName
                            ),
                            status:
                              item.status.charAt(0).toUpperCase() +
                              item.status.slice(1),
                            marketingPrograms: item.marketingPrograms.map(
                              (mp) =>
                                `${mp.marketingProgramNumber} - ${mp.description}`
                            ),
                            output: item.output,
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      setHidePrompt(true);
                      decreaseRequestsCount();
                    }
                  } else {
                    const errors = validateMappings();
                    setFormErrors(errors);
                    if (Object.keys(errors).length > 0) {
                      setFormErrors(errors);
                    } else {
                      setCurrentStep(currentStep + 1);
                    }
                  }
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {currentStep === 1 || currentStep === 2
                  ? t("common.next")
                  : t("common.labels.confirm_and_submit")}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/events"
                >
                  {t("use_events_container.back_to_events")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${eventMappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
        currentStep={currentStep}
        totalSteps={totalSteps}
      >
        <div className={classes.traitContainer}>{renderStep(currentStep)}</div>
      </InputFlow>
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={t("create_event.event_removal_warning", {
          useCaseName:
            events[eventSelectedIndex]?.eventProperty?.propertyName ||
            events[eventSelectedIndex]?.propertyName,
          marketingProgram:
            events[eventSelectedIndex]?.eventProperty?.description ||
            events[eventSelectedIndex]?.description,
        })}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          const newEvents = [...events];
          const deletedEvent = newEvents.splice(eventSelectedIndex, 1);
          setEvents(newEvents);
          setEventProperties((cs) => [...cs, deletedEvent[0].eventProperty]);
          setOpenDeleteModal(false);
          setEventSelectedIndex(-1);
        }}
        type="error"
      />
      <EventsRequestOutputModal
        requestId={eventOutput?.requestId}
        data={eventOutput?.output}
        isLoading={eventOutputLoading}
        isOpen={eventOutputModal}
        onClose={() => {
          setEventOutputModal(false);
        }}
      />
      <EventPropertyModal
        selectedEvent={events[eventSelectedIndex]}
        setEvents={(cs) => {
          setFormErrors({});
          setEvents(cs);
          setEventPropertyModal(false);
        }}
        events={events}
        eventProperties={eventProperties}
        setEventProperties={setEventProperties}
        open={eventPropertyModal}
        handleClose={() => {
          setEventPropertyModal(false);
          setEventSelectedIndex(-1);
        }}
        hideLabels
      />
      <Prompt
        message={t("prompt.progress_lost")}
        when={
          !hidePrompt &&
          (marketingProgramsSelected.length > 0 ||
            ecoSystemsSelected.length > 0)
        }
      />
    </>
  );
};

export default UseEventContainer;
