import { Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";
import applicationConfig from "../../../../../config/applicationConfig";

import useStyles from "./styles";

const SourcesRequestOutputModal = ({
  setShowSourceOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  const { warnings, items } = data;
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      field: "sourceId",
      headerName: t("common.labels.source_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "sourceName",
      headerName: t("common.labels.source_name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "segmentSourceName",
      headerName: t("common.labels.segment_source_name"),
      flex: 1,
      renderCell: (columnData) => {
        const { value } = columnData;
        if (value && value.type === "div") {
          return (
            <div className={clsx(classes.flexContainer, classes.container)}>
              {value}
              <Button variant="contained">{t("common.new")}</Button>
            </div>
          );
        }
        return value;
      },
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const getSegmentSourceName = (item, isNewSource) => {
    if (isNewSource) {
      return <div>{item.ecosystemSourceName}</div>;
    }

    if (!item.ecosystemSourceId) {
      return item.ecosystemSourceName;
    }
    return `${item.ecosystemSourceId} - ${item.ecosystemSourceName}`;
  };

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    return {
      id: `${warning.sourceId}-${index}`,
      sourceId: warning.sourceId,
      sourceName: warning.sourceName,
      marketingProgram: warning.marketingProgramNumber
        ? `${warning.marketingProgramNumber} - ${warning.marketingProgramDescription}`
        : "",
      status: applicationConfig.status.warning,
      message: warning.message,
      ecosystemSourceId: warning.ecosystemSourceId,
      segmentSourceName: `${warning.ecosystemSourceId} - ${warning.ecosystemSourceName}`,
    };
  });

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      const isNewSegmentSource =
        item.new && !item.marketingProgramDescription && !item.sourceName;
      if (item.mappingWarning) {
        status = applicationConfig.status.warning;
        message = item.mappingWarning;
      } else if (item.new) {
        status = applicationConfig.status.success;
        message = isNewSegmentSource
          ? "Segment Source created/mapped successfully"
          : "Data Source created/mapped successfully";
      } else {
        status = applicationConfig.status.warning;
        message = "";
      }
      return {
        id: `${item.sourceId}-${index}`,
        sourceId: item.sourceId,
        sourceName: item.sourceName,
        ecosystemSourceId: item.ecosystemSourceId,
        marketingProgram: item.marketingProgramNumber
          ? `${item.marketingProgramNumber} - ${item.marketingProgramDescription}`
          : "",
        segmentSourceName: getSegmentSourceName(item, isNewSegmentSource),
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowSourceOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.source_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

SourcesRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

SourcesRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
  }).isRequired,
  requestId: PropTypes.string,
  setShowSourceOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default SourcesRequestOutputModal;
