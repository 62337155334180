import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    height: "100% !important",
    "& .MuiDataGrid-colCellWrapper": {
      backgroundColor: theme.colorTheme.bluishWhite,
    },
    "& .MuiDataGrid-window": {
      overflowX: "hidden",
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      position: "unset",
    },
  },
  root: {
    height: 500,
    "& .MuiSnackbar-anchorOriginTopCenter": {
      width: 680,
    },
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  container: {
    "& .MuiButton-root": {
      minWidth: "42px",
      maxHeight: "33px",
      backgroundColor: theme.palette.blue.darker,
      color: theme.palette.static.white,
      marginLeft: "20px",
      "& .MuiButton-label": {
        whiteSpace: "nowrap",
      },
    },
  },
}));

export default useStyles;
