const createBackendPayload = (
  optsMappingInfo,
  marketingProgram,
  serviceName
) => {
  const payload = {
    opt: {
      optId: optsMappingInfo.optId,
      optText: optsMappingInfo.optText,
      marketingProgramNumber: parseInt(
        optsMappingInfo.marketingProgramNumber,
        10
      ),
      marketingProgramDescription: optsMappingInfo.marketingProgramDescription,
    },
    marketingProgram: {
      marketingProgramNumber: parseInt(
        marketingProgram.marketingProgramNumber,
        10
      ),
      description: marketingProgram.description,
    },
  };

  let service = {
    serviceName: optsMappingInfo.serviceName,
    isNew: false,
    channel: `${optsMappingInfo.channel.contact_point_type_code}-${optsMappingInfo.channel.contact_point_type_name}`,
    contactPointCategoryCode: `${optsMappingInfo.contactPointCategoryCode.contact_point_category_code}-${optsMappingInfo.contactPointCategoryCode.contact_point_category_name}`,
    primaryIndicator: optsMappingInfo.primaryIndicator,
    subscriptionOptNumber: optsMappingInfo.subscriptionOptNumber,
  };

  if (Object.keys(serviceName).length > 0 && serviceName.isNewService) {
    service = {
      ...service,
      serviceName: serviceName.serviceName,
      isNew: true,
    };

    if (serviceName.serviceNameDescription) {
      service = {
        ...service,
        description: serviceName.serviceNameDescription,
      };
    }
  }

  payload.service = service;

  return payload;
};

export default createBackendPayload;
