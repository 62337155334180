import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const ConfirmationDetails = ({ details }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return details.map((info) => {
    return (
      <div
        className={clsx(
          classes.flexContainer,
          classes.infoContainer,
          classes.justifyContent
        )}
        key={`${info.label}${info.index}`}
      >
        <Typography variant="h6">{info.label} :</Typography>
        {info.label === t("new_opt_id.opt_text_english") ? (
          <div dangerouslySetInnerHTML={{ __html: info.value }} />
        ) : (
          <Typography>{info.value}</Typography>
        )}
      </div>
    );
  });
};

export default ConfirmationDetails;
