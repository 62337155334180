import { useState } from "react";

import { Button, Paper, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useTranslation } from "react-i18next";

import createJob from "../../../api/create-job";
import getJobDetails from "../../../api/get-job-details";
import getJobs from "../../../api/get-jobs";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import StatusBadge from "../../../components/StatusBadge";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import TableHeader from "../../../components/TableHeader";
import applicationConfig from "../../../config/applicationConfig";

import JobsModuleConfig from "../../../config/jobsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useJobsFilters from "../../../hooks/useJobsFilters";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";

import AutomationStatus from "../../MarketingPrograms/MarketingProgramsContainer/components/AutomationStatus";

import NewJobModal from "./components/NewJobModal";
import useStyles from "./styles";

const JobsManagementContainer = () => {
  const { jobServiceTableColumn, jobStatus } = JobsModuleConfig;
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const { loadJobServices, jobsLoading, searchFilter, setSearchFilter } =
    useJobsFilters();

  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();

  const classes = useStyles();
  const [jobDetails, setJobDetails] = useState({});
  const [jobsData, setJobsData] = useState([]);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(false);
  const [jobsDataLoading, setJobsDataLoading] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [isJobDetailModalOpen, setIsJobDetailModalOpen] = useState(false);
  const [openJobCreationModal, setOpenJobCreationModal] = useState(false);

  const { addNotification } = useNotifier();
  const globalStyles = useGlobalStyles();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchJobs
  );

  const loadJobsInfo = async (jobId) => {
    try {
      setJobsDataLoading(true);
      const param = `?jobId=${jobId}`;
      const response = await getJobs(param);
      setJobsData(response.items);
    } catch (error) {
      setJobsData([]);
      handleError({
        error,
        handle404: () => {},
        addNotification,
      });
    } finally {
      setJobsDataLoading(false);
    }
  };

  const loadJobDetails = async (jobId) => {
    try {
      setJobDetailsLoading(true);
      const response = await getJobDetails(jobId);
      setJobDetails({
        ...response,
        retryJob: response.retryJob ? t("common.yes") : t("common.no"),
      });
    } catch (error) {
      setJobDetails({});
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    } finally {
      setJobDetailsLoading(false);
    }
  };

  const getAllowedColumns = () => {
    let columns = [...jobServiceTableColumn];
    columns = columns.map((column) => {
      if (column.field === "jobId") {
        return {
          field: "jobId",
          sortable: false,
          disableClickEventBubbling: true,
          disableToggle: true,
          renderCell: (param) => {
            return (
              <div
                className={classes.clickableCell}
                onKeyDown={() => null}
                role="button"
                tabIndex={0}
                onClick={async () => {
                  setIsJobDetailModalOpen(true);
                  setCurrentRow(param.row);
                  await loadJobsInfo(param.row.jobId);
                }}
              >
                {param.row.jobId}
              </div>
            );
          },
        };
      }
      return column;
    });
    return columns.map((data) => {
      const columnConfig = {
        ...data,
        flex: 1,
        sortable: false,
        editable: false,
        disableClickEventBubbling: true,
      };
      if (data.field === applicationConfig.common.status) {
        columnConfig.renderCell = (params) => {
          const content = params.value;
          const { row } = params;
          let tooltipInfo = "";
          if (
            row.status === applicationConfig.status.failed ||
            row.status === applicationConfig.status.success_partial
          ) {
            if (!row.retryJob) {
              tooltipInfo = t(
                "marketing_programs_container.job.details.tooltip.message_1"
              );
            } else if (row.retryCount === row.maxRetries) {
              tooltipInfo = t(
                "marketing_programs_container.job.details.tooltip.message_2"
              );
            } else if (row.retryImmediately) {
              tooltipInfo = t(
                "marketing_programs_container.job.details.tooltip.message_2"
              );
            } else {
              tooltipInfo = t(
                "marketing_programs_container.job.details.tooltip.message_4"
              );
            }
          }
          return (
            <div className={classes.flexContainer}>
              <StatusBadge
                status={content || applicationConfig.status.failed}
              />
              {!isEmpty(tooltipInfo) && (
                <StyledTooltip placement="top" title={tooltipInfo}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </div>
                </StyledTooltip>
              )}
            </div>
          );
        };
      }
      return columnConfig;
    });
  };

  const handleCloseModal = () => {
    setIsJobDetailModalOpen(false);
    setJobDetails({});
  };

  const getDefaultStatusFilters = () => {
    return [
      applicationConfig.filters.all,
      jobStatus.PENDING,
      jobStatus.RUNNING,
      jobStatus.FAILED,
      jobStatus.SUCCESS,
      jobStatus.SUCCESS_PARTIAL,
    ];
  };

  const renderHeader = () => {
    const visibleFilters = [
      applicationConfig.filters.status,
      applicationConfig.filters.date_range,
      applicationConfig.filters.job_service_type,
    ];

    return (
      <TableHeader
        handleFilterChange={setSearchFilter}
        defaultStatus={getDefaultStatusFilters()}
        visibleFilters={visibleFilters}
        searchFilters={applicationConfig.jobsSearchFilter}
        isJobStatusFilter
        headerComp={
          checkUserAuthorization(user.access, pageAccessConfig.manageJobs) && (
            <div className={classes.btnContainer}>
              <Button
                variant="contained"
                color="primary"
                className={globalStyles.btn}
                onClick={() => {
                  setOpenJobCreationModal(true);
                }}
              >
                {t("jobs_monitoring_container.create_job")}
              </Button>
            </div>
          )
        }
      />
    );
  };

  return !isUserAuthorized && !user.loading && !jobsLoading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("jobs_monitoring_container.recent_job_services")}
          </Typography>
        </div>
      }
      showSpinner={jobsLoading}
    >
      <Paper data-testid="recent-jobs-container" elevation={0}>
        <div>{renderHeader()}</div>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage, _searchFilter) =>
              _searchFilter.length > 0 &&
              loadJobServices(page, perPage, searchFilter)
            }
            filters={searchFilter}
            externalLoading={jobsLoading}
            columns={getAllowedColumns()}
          />
        </div>
      </Paper>
      {isJobDetailModalOpen && (
        <AutomationStatus
          title={`${t("common.labels.view_details")}`}
          open={isJobDetailModalOpen}
          onClose={() => handleCloseModal()}
          loadingJobs={jobsDataLoading}
          loadingJobDetails={jobDetailsLoading}
          loadJobDetails={async (jobId) => {
            await loadJobDetails(jobId);
          }}
          loadJobsInfo={async () => {
            setJobDetails({});
            await loadJobsInfo(currentRow.jobId);
          }}
          jobDetails={jobDetails}
          jobs={jobsData}
        />
      )}

      <NewJobModal
        title={t("jobs_monitoring_container.create_new_job")}
        isOpen={openJobCreationModal}
        setOpenJobCreationModal={setOpenJobCreationModal}
        handleSubmit={async (data) => {
          try {
            increaseRequestsCount();
            const payload = {};
            Object.keys(data).forEach((key) => {
              if (key === "jobName") {
                payload[key] = data[key].split(" ").join("_").toLowerCase();
              } else if (!isEmpty(data[key])) {
                if (data.jobName === "Segment Sources") {
                  payload.ecosystemSourceIds =
                    data.ecosystemSourceIds.split(",");
                } else {
                  payload[key] = data[key];
                }
              }
            });
            const response = await createJob(payload);
            setOpenJobCreationModal(false);
            setCurrentRow(response);
            setIsJobDetailModalOpen(true);
            await loadJobsInfo(response.jobId);
          } catch (error) {
            handleError({
              error,
              handle404: false,
              addNotification,
            });
          } finally {
            decreaseRequestsCount();
          }
        }}
      />
    </Layout>
  );
};

export default JobsManagementContainer;
