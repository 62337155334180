import { useEffect, useState } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { lightTheme } from "../../../../../assets/styles/themes";

import Form from "../../../../../components/Form";
import InlineMessage from "../../../../../components/InlineMessage";
import StyledTooltip from "../../../../../components/StyledTooltip";

import sourcesModuleConfig from "../../../../../config/sourcesModuleConfig";

import isEmpty from "../../../../../utilities/isEmpty";
import useImmer from "../../../../../utilities/useImmer";

import getSourceFields from "../../getSourceFields";

import validateSourcesInfo from "../../helpers/validateSourcesInfo";

import useStyles from "./styles";

const DataSourceModal = ({
  isNewDataSourceEditDisabled,
  editDataSource,
  setEditDataSource,
  marketingProgram,
  openCreateDatasourceModal,
  setOpenCreateDatasourceModal,
  addNewSource,
  dataSourceInfo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { createSourceConstants } = sourcesModuleConfig;

  const initialSourceInfo = {
    new: true,
    marketingProgramDescription: "",
    sourceName: "",
    description: "",
    sourceType: null,
    consumerFacingDescription: "",
    descriptionOfProcess: "",
    dataType: [],
    businessFacingName: "",
    dataSourceWebsite: null,
    url: "",
    registrationPageUrl: "",
    systemDisplayingPage: "",
    managedByExternalAgency: null,
    agencyName: "",
    coControllerInd: null,
    ropaId: "",
  };

  const initialFocusedValues = {
    sourceName: false,
    sourceType: false,
    description: false,
    consumerFacingDescription: false,
    descriptionOfProcess: false,
    dataType: false,
    businessFacingName: false,
    dataSourceWebsite: false,
    url: false,
    registrationPageUrl: false,
    systemDisplayingPage: false,
    managedByExternalAgency: false,
    agencyName: false,
    coControllerInd: false,
    ropaId: false,
  };

  const [sourcesInfo, setSourcesInfo] = useImmer(initialSourceInfo);
  const [focused, setFocused] = useImmer(initialFocusedValues);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isNewDataSourceEditDisabled) {
      setSourcesInfo((cs) => ({
        ...cs,
        sourceName: marketingProgram.description,
        marketingProgramDescription: marketingProgram.description,
      }));
    }
  }, [
    marketingProgram,
    openCreateDatasourceModal,
    isNewDataSourceEditDisabled,
  ]);

  useEffect(() => {
    if (Object.keys(editDataSource).length !== 0) {
      setSourcesInfo({ ...editDataSource });
    } else if (!isEmpty(dataSourceInfo)) {
      setSourcesInfo(dataSourceInfo);
    }
  }, [editDataSource, dataSourceInfo, openCreateDatasourceModal]);

  useEffect(
    () => setErrors(validateSourcesInfo(sourcesInfo, focused)),
    [focused, sourcesInfo]
  );

  let newSourceFields = getSourceFields(sourcesInfo);
  newSourceFields = newSourceFields.map((field) => {
    if (field.label === t("common.labels.source_description")) {
      return {
        ...field,
        tooltip: {
          title: t("new_source.tooltips.source_description"),
          placement: "top-start",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.source_description"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.description = event.target.value;
            }),
          helperText: <InlineMessage message={errors.descriptionError} />,
          error: errors.descriptionError !== null,
          value: sourcesInfo.description,
          onBlur: () => {
            setFocused((draft) => {
              draft.description = true;
            });
          },
        },
      };
    }

    if (field.label === t("common.labels.source_type")) {
      return {
        ...field,
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          label:
            sourcesInfo.sourceType === null
              ? t("new_source.placeholders.source_type")
              : "",
          select: true,
          variant: "outlined",
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.sourceType = event.target.value;
            }),
          inputProps: {
            "data-testid": "source-type",
          },
          onBlur: () => {
            setFocused((draft) => {
              draft.sourceType = true;
            });
          },
          error: errors.sourceTypeError !== null,
          helperText: <InlineMessage message={errors.sourceTypeError} />,
          values: createSourceConstants.sourceTypeOptions.map((option) => ({
            label: option,
            value: option,
          })),
          value: sourcesInfo.sourceType,
        },
      };
    }

    if (field.label === t("new_source.consumer_facing_description")) {
      return {
        ...field,
        tooltip: {
          title: t("new_source.tooltips.consumer_facing_description"),
          placement: "top-start",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.consumer_facing_description"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.consumerFacingDescription = event.target.value;
            }),
          error: errors.consumerFacingDescriptionError !== null,
          value: sourcesInfo.consumerFacingDescription,
          helperText: (
            <InlineMessage message={errors.consumerFacingDescriptionError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.consumerFacingDescription = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.description_of_process")) {
      return {
        ...field,
        tooltip: {
          title: t("new_source.tooltips.description_of_process"),
          placement: "top-start",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.description_of_process"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.descriptionOfProcess = event.target.value;
            }),
          error: errors.descriptionOfProcessError !== null,
          value: sourcesInfo.descriptionOfProcess,
          helperText: (
            <InlineMessage message={errors.descriptionOfProcessError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.descriptionOfProcess = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.kind_of_data_collected")) {
      return {
        ...field,
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          },
          variant: "outlined",
          multiple: true,
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.dataType = event.target.value;
            }),
          renderValue: (selected) => selected.join(", "),
          values: createSourceConstants.dataTypeOptions,
          error: errors.dataTypeError !== null,
          helperText: <InlineMessage message={errors.dataTypeError} />,
          value: sourcesInfo.dataType,
          "data-testid": "kind-of-data-collected",
          onClose: () => {
            setFocused((draft) => {
              draft.dataType = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.business_facing_name")) {
      return {
        ...field,
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.business_facing_name"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.businessFacingName = event.target.value;
            }),
          error: errors.businessFacingNameError !== null,
          value: sourcesInfo.businessFacingName,
          helperText: (
            <InlineMessage message={errors.businessFacingNameError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.businessFacingName = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.is_data_source_website")) {
      return {
        ...field,
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          label:
            sourcesInfo.dataSourceWebsite === null
              ? t("new_source.select_yes_or_no")
              : "",
          select: true,
          variant: "outlined",
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.dataSourceWebsite = event.target.value;
            }),
          inputProps: {
            "data-testid": "is-source-website",
          },
          values: createSourceConstants.yesOrNoOptions.map((option) => ({
            label: option,
            value: option === t("common.yes"),
          })),
          value:
            sourcesInfo.dataSourceWebsite === null
              ? ""
              : sourcesInfo.dataSourceWebsite,
          error: errors.dataSourceWebsiteError !== null,
          helperText: <InlineMessage message={errors.dataSourceWebsiteError} />,
          onBlur: () => {
            setFocused((draft) => {
              draft.dataSourceWebsite = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.data_source_url")) {
      return {
        ...field,
        tooltip: {
          placement: "top-start",
          title: t("new_source.tooltips.data_source_url"),
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.data_source_url"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.url = event.target.value;
            }),
          error: errors.urlError !== null,
          value: sourcesInfo.url,
          helperText: <InlineMessage message={errors.urlError} />,
          onBlur: () => {
            setFocused((draft) => {
              draft.url = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.registration_page_url")) {
      return {
        ...field,
        tooltip: {
          placement: "top-start",
          title: t("new_source.tooltips.registration_page_url"),
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.registration_page_url"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.registrationPageUrl = event.target.value;
            }),
          error: errors.registrationPageUrlError !== null,
          value: sourcesInfo.registrationPageUrl,
          helperText: (
            <InlineMessage message={errors.registrationPageUrlError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.registrationPageUrl = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.system_displaying_page")) {
      return {
        ...field,
        tooltip: {
          placement: "top-start",
          title: t("new_source.tooltips.system_displaying_page"),
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.system_displaying_page"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.systemDisplayingPage = event.target.value;
            }),
          error: errors.systemDisplayingPageError !== null,
          value: sourcesInfo.systemDisplayingPage,
          helperText: (
            <InlineMessage message={errors.systemDisplayingPageError} />
          ),
          onBlur: () => {
            setFocused((draft) => {
              draft.systemDisplayingPage = true;
            });
          },
          onFocus: () => {},
        },
      };
    }
    if (field.label === t("new_source.is_managed_by_agency")) {
      return {
        ...field,
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          label:
            sourcesInfo.managedByExternalAgency === null
              ? t("new_source.select_yes_or_no")
              : "",
          select: true,
          variant: "outlined",
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.managedByExternalAgency = event.target.value;
            }),
          inputProps: {
            "data-testid": "is-managed-by-agency",
          },
          onBlur: () => {
            setFocused((draft) => {
              draft.managedByExternalAgency = true;
            });
          },
          error: errors.managedByExternalAgencyError !== null,
          helperText: (
            <InlineMessage message={errors.managedByExternalAgencyError} />
          ),
          values: createSourceConstants.yesOrNoOptions.map((option) => ({
            label: option,
            value: option === t("constants.yes_or_no_options.yes"),
          })),
          value:
            sourcesInfo.managedByExternalAgency === null
              ? ""
              : sourcesInfo.managedByExternalAgency,
        },
      };
    }
    if (field.label === t("new_source.agency_name")) {
      return {
        ...field,
        tooltip: {
          placement: "top-start",
          title: t("new_source.tooltips.agency_name"),
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.agency_name"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.agencyName = event.target.value;
            }),
          error: errors.agencyNameError !== null,
          value: sourcesInfo.agencyName,
          helperText: <InlineMessage message={errors.agencyNameError} />,
          onBlur: () => {
            setFocused((draft) => {
              draft.agencyName = true;
            });
          },
        },
      };
    }
    if (field.label === t("new_source.is_source_controller_of_data")) {
      return {
        ...field,
        required: true,
        props: {
          disabled: isNewDataSourceEditDisabled,
          label:
            sourcesInfo.coControllerInd === null
              ? t("new_source.select_yes_or_no")
              : "",
          select: true,
          variant: "outlined",
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.coControllerInd = event.target.value;
            }),
          inputProps: {
            "data-testid": "is-controller-of-data",
          },
          onBlur: () => {
            setFocused((draft) => {
              draft.coControllerInd = true;
            });
          },
          error: errors.coControllerIndError !== null,
          helperText: <InlineMessage message={errors.coControllerIndError} />,
          values: createSourceConstants.yesOrNoOptions.map((option) => ({
            label: option,
            value: option === t("constants.yes_or_no_options.yes"),
          })),
          value:
            sourcesInfo.coControllerInd === null
              ? ""
              : sourcesInfo.coControllerInd,
        },
      };
    }
    if (field.label === t("new_source.ropa_id")) {
      return {
        ...field,
        tooltip: {
          placement: "top-start",
          title: t("new_source.tooltips.ropa_id"),
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        required: true,
        flex: 2,
        props: {
          disabled: isNewDataSourceEditDisabled,
          placeholder: t("new_source.placeholders.ropa_id"),
          onChange: (event) =>
            setSourcesInfo((draft) => {
              draft.ropaId = event.target.value;
            }),
          error: errors.ropaIdError !== null,
          value: sourcesInfo.ropaId,
          helperText: <InlineMessage message={errors.ropaIdError} />,
          onBlur: () => {
            setFocused((draft) => {
              draft.ropaId = true;
            });
          },
        },
      };
    }
    return {};
  });

  const handleClose = () => {
    setOpenCreateDatasourceModal(false);
    setSourcesInfo(initialSourceInfo);
    setFocused(initialFocusedValues);
    setEditDataSource({});
  };

  return (
    <>
      <Dialog
        open={openCreateDatasourceModal}
        onClose={() => {
          if (isNewDataSourceEditDisabled) {
            handleClose();
          } else {
            setIsCancelClicked(true);
          }
        }}
        classes={{
          paper: classes.newDataSourceModal,
        }}
      >
        <div className={classes.dialogHeader}>
          <Typography variant="h5">{t("common.new_source")}</Typography>
          <div
            className={classes.marginLeftAuto}
            onClick={() => {
              if (isNewDataSourceEditDisabled) {
                handleClose();
              } else {
                setIsCancelClicked(true);
              }
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        <div className={classes.horizontalBar} />
        <DialogContent>
          <>
            <div
              className={clsx(
                classes.grid,
                classes.marketingProgramSourceContainer
                // step === 2 && classes.columnGrid
              )}
              data-testid="sources-container"
            >
              <div
                className={clsx(
                  classes.inputContainer,
                  classes.sourceNameContainer
                )}
              >
                <StyledTooltip
                  placement="top-start"
                  title={<div>{t("new_source.tooltips.source_name")}</div>}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Typography>
                      {t("common.labels.source_name")}
                      <i style={{ color: "red" }}>*</i>
                    </Typography>
                    <InfoIcon className={classes.infoIcon} />
                  </div>
                </StyledTooltip>
                <div className={classes.flexContainer}>
                  <TextField
                    disabled={isNewDataSourceEditDisabled}
                    id="filled-required"
                    placeholder={t("new_source.placeholders.source_name")}
                    variant="outlined"
                    value={sourcesInfo.sourceName}
                    onChange={(event) => {
                      setSourcesInfo((draft) => {
                        draft.sourceName = event.target.value;
                      });
                    }}
                    onBlur={() => {
                      setFocused((draft) => {
                        draft.sourceName = true;
                      });
                    }}
                    error={errors.sourceNameError !== null}
                    helperText={
                      <InlineMessage message={errors.sourceNameError} />
                    }
                  />
                </div>
              </div>
            </div>
            <Form
              fields={newSourceFields}
              fieldClassName={classes.inputContainer}
              containerClassName={clsx(classes.grid)}
            />
          </>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            {!isNewDataSourceEditDisabled && (
              <Button
                onClick={() => {
                  const deepInputs = {
                    ropaId: "coControllerInd",
                    agencyName: "managedByExternalAgency",
                    url: "dataSourceWebsite",
                    systemDisplayingPage: "dataSourceWebsite",
                    registrationPageUrl: "dataSourceWebsite",
                  };
                  const allFocused = Object.fromEntries(
                    Object.entries(initialFocusedValues).map((x) => [
                      x[0],
                      Object.keys(deepInputs).includes(x[0])
                        ? !!sourcesInfo[deepInputs[x[0]]]
                        : true,
                    ])
                  );
                  const errorz = validateSourcesInfo(sourcesInfo, allFocused);
                  const errorValues = Object.values(errorz);

                  if (errorValues.some((x) => x)) {
                    setErrors(errorz);
                    setFocused(allFocused);
                    return;
                  }
                  addNewSource(
                    sourcesInfo,
                    Object.keys(editDataSource).length === 0 ? "new" : "edit"
                  );
                  handleClose();
                }}
                style={{
                  marginLeft: "auto",
                  backgroundColor: lightTheme.palette.blue.darker,
                  color: lightTheme.palette.static.white,
                }}
                variant="contained"
              >
                {Object.keys(editDataSource).length === 0
                  ? t("common.submit")
                  : t("common.update")}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

DataSourceModal.propTypes = {
  isNewDataSourceEditDisabled: PropTypes.bool.isRequired,
  editDataSource: PropTypes.shape({
    businessSourceName: PropTypes.string,
    description: PropTypes.string,
    ecosystemSourceNames: PropTypes.string,
    marketingPrograms: PropTypes.string,
    sourceId: PropTypes.number,
    sourceName: PropTypes.string,
  }).isRequired,
  setEditDataSource: PropTypes.func.isRequired,
  marketingProgram: PropTypes.shape({
    marketingProgramNumber: PropTypes.number,
    description: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  openCreateDatasourceModal: PropTypes.bool.isRequired,
  setOpenCreateDatasourceModal: PropTypes.func.isRequired,
  addNewSource: PropTypes.func.isRequired,
  dataSourceInfo: PropTypes.shape({}).isRequired,
};

export default DataSourceModal;
