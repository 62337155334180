import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import PropTypes from "prop-types";

import RequestModal from "../RequestModal";

import useStyles from "./styles";

const RequestOutputModal = ({
  isLoading,
  open,
  handleClose,
  children,
  title,
  subTitle,
  requestModalStyle,
}) => {
  const classes = useStyles();

  return (
    <RequestModal
      className={requestModalStyle}
      isLoading={isLoading}
      open={open}
    >
      <DialogTitle
        id="request-output-modal-title"
        className={classes.title}
        data-testid="request-output-modal"
      >
        <div>
          {title}
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
    </RequestModal>
  );
};

RequestOutputModal.defaultProps = {
  open: false,
  isLoading: false,
  subTitle: "",
  requestModalStyle: "",
};

RequestOutputModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  requestModalStyle: PropTypes.object,
};

export default RequestOutputModal;
