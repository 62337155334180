import React, { useEffect, useState } from "react";

import {
  Typography,
  OutlinedInput,
  TextField,
  Button,
  FormHelperText,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AutoComplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CustomModal from "../../../../../components/CustomModal";

import languages from "../../../../../config/languages";

import compareObjects from "../../../../../utilities/compareObjects";
import { isEmptyString } from "../../../../../utilities/formValidation";
import isEmpty from "../../../../../utilities/isEmpty";

import useStyles from "../../styles";

import AliasesModal from "../AliasesModal";

const TraitsResponseModal = React.forwardRef(
  (
    {
      isTraitsFlow,
      renderList,
      setRenderList,
      editableTrait,
      isGlobal,
      diffList,
      open,
      handleClose,
      defaultLanguage,
      setDefaultLanguage,
    },
    globalTraitResponses
  ) => {
    const classes = useStyles();
    const [language, setLanguage] = useState(defaultLanguage);
    const [traitResponseLocalLanguage, setTraitResponseLocalLanguage] =
      useState("");
    const [traitResponseEnglish, setTraitResponseEnglish] = useState("");
    const [selectedGlobalTrait, setSelectedGlobalTrait] = useState({});
    const [newGlobalResponseValue, setNewGlobalResponseValue] = useState("");
    const [newGlobalResponsesModal, setNewGlobalResponsesModal] =
      useState(false);
    const [predefinedTraitResponse, setPredefinedTraitResponse] = useState("");
    const [openManageAliasesModal, setOpenManageAliasesModal] = useState(false);
    const [aliases, setAliases] = useState([]);
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    useEffect(() => {
      setLanguage(defaultLanguage);
    }, [defaultLanguage]);

    const clear = () => {
      setLanguage(defaultLanguage);
      setTraitResponseLocalLanguage("");
      setTraitResponseEnglish("");
      setSelectedGlobalTrait({});
      setPredefinedTraitResponse("");
      setErrors({});
    };

    useEffect(() => {
      if (!open || Object.keys(editableTrait).length === 0) {
        clear();
      }
      if (isGlobal) {
        setLanguage({
          languageCode: "en",
          languageName: "English",
          title: "English",
        });
      }
      setAliases(editableTrait?.predefinedTraitResponseAliases);
    }, [editableTrait, isGlobal, open]);

    useEffect(() => {
      if (Object.keys(editableTrait).length > 0) {
        setLanguage({
          languageCode: isGlobal ? "en" : editableTrait.languageCode,
          languageName: isGlobal
            ? "English"
            : editableTrait.language ?? editableTrait.languageName,
          title: isGlobal
            ? "English"
            : editableTrait.language ?? editableTrait.languageName,
        });
        setTraitResponseEnglish(
          editableTrait.predefinedTraitResponse ??
            editableTrait.predefinedEventPropertyResponse
        );
        setTraitResponseLocalLanguage(
          editableTrait.predefinedTraitResponseLocalLanguage ||
            editableTrait.predefinedEventPropertyResponseLocalLanguage
        );
        setSelectedGlobalTrait({
          predefinedTraitResponse: editableTrait.globalStandardResponse,
          predefinedTraitNumber:
            editableTrait.predefinedTraitNumber ??
            editableTrait.predefinedEventPropertyNumber,
          title: editableTrait.globalStandardResponse,
        });
        setPredefinedTraitResponse(
          editableTrait.globalStandardResponse ??
            editableTrait.predefinedTraitResponse ??
            editableTrait.predefinedEventPropertyResponse
        );
      }
    }, [editableTrait, isGlobal]);

    const handleTraitResponseDuplication = () => {
      const state = {
        id: `${language?.languageName}:${traitResponseLocalLanguage}`,
        sId: `${
          selectedGlobalTrait?.predefinedTraitResponse || "*"
        }:${traitResponseEnglish}`,
      };

      const currentId = state.id;
      const currentSecId = state.sId;
      const previousId = editableTrait.id;
      const previousSecId = editableTrait.sId;

      const sameId = currentId?.toLowerCase() === previousId?.toLowerCase();
      const sameSecId =
        currentSecId?.toLowerCase() === previousSecId?.toLowerCase();

      if (sameId && sameSecId) {
        return false;
      }

      if (
        renderList.some((x) => x.id === currentId && x.sId === currentSecId)
      ) {
        return true;
      }

      return false;
    };

    const handleGlobalTraitDuplication = () => {
      const state = {
        id: predefinedTraitResponse,
      };

      const currentId = state.id;
      const previousId = editableTrait.id;

      if (currentId?.toLowerCase() === previousId?.toLowerCase()) {
        return false;
      }

      if (
        renderList.some((x) => x.id?.toLowerCase() === currentId?.toLowerCase())
      ) {
        return true;
      }

      return false;
    };

    const handleDuplicationValidation = () => {
      return isGlobal
        ? handleGlobalTraitDuplication()
        : handleTraitResponseDuplication();
    };

    const handleInputValidation = () => {
      const err = {};
      if (isEmpty(language)) {
        err.languageError = t("errors.field_required");
      }
      if (isGlobal) {
        if (isEmptyString(predefinedTraitResponse)) {
          err.predefinedTraitResponseError = t("errors.field_required");
        }
      } else {
        if (isEmptyString(traitResponseLocalLanguage)) {
          err.traitResponseLocalLanguageError = t("errors.field_required");
        }
        if (isEmptyString(traitResponseEnglish)) {
          err.traitResponseEnglishError = t("errors.field_required");
        }
      }
      setErrors(err);
      const values = Object.values(err);
      return values.length > 0 && values.every((val) => val && val.length > 0);
    };

    const newGlobalStandardResponse = isGlobal ? null : (
      <div
        className={clsx(
          classes.flexContainer,
          classes.addTraitBtn,
          classes.noOptionsContainer
        )}
      >
        <Typography variant="h6">Looking to add Global Responses?</Typography>
        <Button
          data-testid="global-btn"
          onClick={(e) => {
            e.stopPropagation();
            setNewGlobalResponsesModal(true);
          }}
        >
          New Global Response
        </Button>
      </div>
    );

    const handleEdgeCases = (state, currentSecId, previousSecId) => {
      const currentId = state.id;
      const previousId = editableTrait.id;

      const diffItem = diffList.find((x) => x.id === currentId);
      const replacedItem = renderList.find((x) => x.id === currentId);
      const editedOutItem = renderList.find(
        (x) => "oldValue" in x && x.oldValue.id === currentId
      );

      let callback = (cs) => [...cs];

      if (Object.keys(editableTrait).length !== 0) {
        const sameId = currentId === previousId;
        const sameSecId = currentSecId === previousSecId;

        if (
          isTraitsFlow &&
          JSON.stringify(editableTrait.predefinedTraitResponseAliases) ===
            JSON.stringify(state.predefinedTraitResponseAliases)
        ) {
          if (sameId && sameSecId) {
            // nothing changed, clear and close
            clear();
            handleClose();
            return callback;
          }
        } else if (!isTraitsFlow) {
          if (sameId && sameSecId) {
            // nothing changed, clear and close
            clear();
            handleClose();
            return callback;
          }
        }

        const newItem =
          "new" in editableTrait && !("reference" in editableTrait);
        const modifiedItem = "oldValue" in editableTrait;

        if (newItem || modifiedItem) {
          callback = (cs) =>
            cs.map((x) => {
              if (x.id === previousId) {
                return {
                  ...x,
                  ...state,
                };
              }
              return x;
            });
        } else {
          callback = (cs) =>
            cs.map((x) => {
              if (x.id === previousId || x.id === replacedItem?.id) {
                return { ...state, new: true, oldValue: { ...editableTrait } };
              }
              return x;
            });
        }
      } else if (replacedItem && !editedOutItem) {
        callback = (cs) => [
          ...cs.filter((x) => x.id !== currentId),
          { ...state, new: true, oldValue: { ...replacedItem } },
        ];
      } else if (editedOutItem) {
        callback = (cs) =>
          cs.map((x) => {
            if (x.id === editedOutItem.id) {
              const currentState = { ...state };
              delete currentState.predefinedTraitNumber;

              if (compareObjects(x.oldValue, currentState)) {
                return {
                  ...currentState,
                };
              }

              return {
                new: true,
                ...state,
                oldValue: {
                  ...x.oldValue,
                },
              };
            }
            return x;
          });
      } else {
        callback = (cs) => [
          ...cs,
          { ...state, ...(!diffItem ? { new: true } : {}) },
        ];
      }

      return callback;
    };

    const handleTraitResponseChange = () => {
      const state = {
        globalStandardResponse: isGlobal
          ? predefinedTraitResponse
          : selectedGlobalTrait?.title,
        [isTraitsFlow
          ? "predefinedTraitResponseLocalLanguage"
          : "predefinedEventPropertyResponseLocalLanguage"]: traitResponseLocalLanguage,
        [isTraitsFlow
          ? "predefinedTraitNumber"
          : "predefinedEventPropertyNumber"]:
          editableTrait.predefinedTraitNumber ||
          editableTrait.predefinedEventPropertyNumber,
        languageCode: language.languageCode,
        languageName: language.languageName,
        [isTraitsFlow
          ? "predefinedTraitResponse"
          : "predefinedEventPropertyResponse"]:
          language?.title === "English"
            ? traitResponseLocalLanguage
            : traitResponseEnglish,
        predefinedTraitResponseAliases: aliases,
      };
      state.id = `${state.languageName}:${
        state.predefinedTraitResponseLocalLanguage ??
        state.predefinedEventPropertyResponseLocalLanguage
      }`;
      state.sId = `${
        state.predefinedTraitResponse ??
        state.predefinedEventPropertyResponse ??
        "*"
      }:${state.globalStandardResponse}`;

      const currentSecId = state.sId;
      const previousSecId = editableTrait.sId;

      return handleEdgeCases(state, currentSecId, previousSecId);
    };

    const handleGlobalTraitChange = () => {
      const state = {
        id: predefinedTraitResponse,
        languageCode: language.languageCode,
        languageName: language.languageName,
        [isTraitsFlow
          ? "predefinedTraitResponse"
          : "predefinedEventPropertyResponse"]: predefinedTraitResponse,
        predefinedTraitResponseAliases: aliases,
      };

      return handleEdgeCases(state);
    };

    const responseTitle = () => {
      if (isGlobal) {
        return t("manage_traits_responses.new_global_standard_response");
      }

      if (isTraitsFlow) {
        return t("manage_traits_responses.new_traits_response");
      }

      return t("manage_traits_responses.new_event_response");
    };

    return (
      <>
        <CustomModal
          open={open}
          showCloseIcon
          onClose={handleClose}
          title={responseTitle()}
        >
          <div style={{ padding: "10px" }}>
            <div className={classes.eventPropertyModalField}>
              <Typography>
                Local Language
                <i style={{ color: "red" }}>*</i>
              </Typography>
              <div style={{ width: "65%" }}>
                <AutoComplete
                  disablePortal
                  id="language"
                  disabled={isGlobal}
                  data-testid="language"
                  value={language}
                  getOptionLabel={(option) => {
                    if (option.title) {
                      return option.title.toString();
                    }
                    return "";
                  }}
                  getOptionSelected={(option, value) =>
                    option.title === value.title
                  }
                  options={languages.map((x) => ({
                    ...x,
                    title: x.languageName,
                  }))}
                  fullWidth
                  onChange={(_, value) => {
                    if (value?.title === "English") {
                      setTraitResponseLocalLanguage(traitResponseEnglish);
                    }
                    setLanguage(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} data-testid="property-input" />
                  )}
                />
                {errors.languageError && (
                  <FormHelperText error>{errors.languageError}</FormHelperText>
                )}
              </div>
            </div>
            {!isGlobal && (
              <>
                <div className={classes.eventPropertyModalField}>
                  <Typography>
                    {isTraitsFlow
                      ? "Trait Response"
                      : "Event Property Response"}{" "}
                    (Local Language)
                    <i style={{ color: "red" }}>*</i>
                  </Typography>
                  <div style={{ width: "65%" }}>
                    <OutlinedInput
                      disabled={language?.title === "English"}
                      style={{ width: "100%" }}
                      value={traitResponseLocalLanguage}
                      onChange={(e) =>
                        setTraitResponseLocalLanguage(e.target.value)
                      }
                      placeholder={(() => {
                        if (language?.title === "English") {
                          return t("common.texts.input_not_required");
                        }
                        if (isTraitsFlow) {
                          return t(
                            "manage_traits_responses.placeholders.trait_response_local"
                          );
                        }
                        return t(
                          "manage_traits_responses.placeholders.event_response_local"
                        );
                      })()}
                    />
                    {errors.traitResponseLocalLanguageError && (
                      <FormHelperText
                        error={Boolean(errors.traitResponseLocalLanguageError)}
                      >
                        {errors.traitResponseLocalLanguageError}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                <div className={classes.eventPropertyModalField}>
                  <Typography>
                    {isTraitsFlow
                      ? t("common.labels.trait_response")
                      : t("common.labels.event_response")}{" "}
                    (English)
                    <i style={{ color: "red" }}>*</i>
                  </Typography>
                  <div style={{ width: "65%" }}>
                    <OutlinedInput
                      style={{ width: "100%" }}
                      value={traitResponseEnglish}
                      onChange={(e) => {
                        if (language?.title === "English") {
                          setTraitResponseLocalLanguage(e.target.value);
                        }
                        setTraitResponseEnglish(e.target.value);
                      }}
                      placeholder={
                        isTraitsFlow
                          ? t(
                              "manage_traits_responses.placeholders.trait_response_english"
                            )
                          : t(
                              "manage_traits_responses.placeholders.event_response_english"
                            )
                      }
                    />
                    {errors.traitResponseEnglishError && (
                      <FormHelperText
                        error={Boolean(errors.traitResponseEnglishError)}
                      >
                        {errors.traitResponseEnglishError}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={classes.eventPropertyModalField}>
              <Typography>
                Global Standard Response
                {isGlobal && <i style={{ color: "red" }}>*</i>}
              </Typography>
              <div style={{ width: "65%" }}>
                {isGlobal ? (
                  <div style={{ width: "100%" }}>
                    <OutlinedInput
                      fullWidth
                      value={predefinedTraitResponse}
                      onChange={(e) => {
                        setPredefinedTraitResponse(e.target.value);
                        setErrors({
                          predefinedTraitResponseError: false,
                        });
                      }}
                      placeholder={t(
                        "manage_traits_responses.placeholders.global_standard_response"
                      )}
                    />
                    {errors.predefinedTraitResponseError && (
                      <FormHelperText
                        error={Boolean(errors.predefinedTraitResponseError)}
                      >
                        {errors.predefinedTraitResponseError}
                      </FormHelperText>
                    )}
                  </div>
                ) : (
                  <AutoComplete
                    disablePortal
                    id="global-trait"
                    data-testid="global-trait"
                    value={selectedGlobalTrait}
                    getOptionLabel={(option) => {
                      if (option.type !== "div") {
                        return option.title || "";
                      }
                      return "";
                    }}
                    renderOption={(option) => {
                      if (option.type !== "div") {
                        return option.title;
                      }
                      return option;
                    }}
                    getOptionSelected={(option, value) =>
                      option.type === "div"
                        ? option.props.title === value.title
                        : option.title === value.title
                    }
                    options={[
                      ...globalTraitResponses.current.map((globalTrait) => {
                        const newGlobalTraitChangeHandler = () => {
                          if (
                            !compareObjects(selectedGlobalTrait, globalTrait)
                          ) {
                            setSelectedGlobalTrait(globalTrait);
                          } else {
                            setSelectedGlobalTrait({});
                          }
                        };
                        if (globalTrait.new) {
                          return (
                            <div
                              tabIndex={0}
                              role="button"
                              style={{ width: "100%" }}
                              title={globalTrait.predefinedTraitResponse}
                              onKeyDown={newGlobalTraitChangeHandler}
                              onClick={newGlobalTraitChangeHandler}
                            >
                              {globalTrait.predefinedTraitResponse}
                              <Button
                                style={{ marginLeft: "8px" }}
                                className={classes.new}
                              >
                                NEW
                              </Button>
                            </div>
                          );
                        }
                        return {
                          ...globalTrait,
                          title:
                            globalTrait.predefinedTraitResponse ||
                            globalTrait.predefinedEventPropertyResponse,
                        };
                      }),
                      ...(newGlobalStandardResponse
                        ? [newGlobalStandardResponse]
                        : []),
                    ]}
                    fullWidth
                    onChange={(_, value) => {
                      if (value?.type !== "div") {
                        setSelectedGlobalTrait(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} data-testid="property-input" />
                    )}
                  />
                )}
              </div>
            </div>
            {handleDuplicationValidation() && (
              <Alert severity="error">
                {isGlobal
                  ? t(
                      "manage_traits_responses.global_standard_response_already_mapped"
                    )
                  : t("manage_traits_responses.trait_already_mapped")}
              </Alert>
            )}
            {isTraitsFlow && (
              <div className={classes.eventPropertyModalFooter}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenManageAliasesModal(true)}
                >
                  Manage Aliases
                </Button>
              </div>
            )}
            <div className={classes.eventPropertyModalFooter}>
              <div />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (
                    handleInputValidation() ||
                    handleDuplicationValidation()
                  ) {
                    return;
                  }
                  let callback;

                  if (isGlobal) {
                    callback = handleGlobalTraitChange();
                  } else {
                    callback = handleTraitResponseChange();
                  }

                  setRenderList(callback);
                  setDefaultLanguage((draft) => {
                    draft.languageCode = language.languageCode;
                    draft.languageName = language.languageName;
                    draft.title = language.title;
                  });
                  clear();
                  handleClose();
                }}
              >
                {t("manage_traits_responses.submit")}
              </Button>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={newGlobalResponsesModal}
          showCloseIcon
          onClose={() => {
            setNewGlobalResponsesModal(false);
          }}
          title="New Global Standard Response"
        >
          <div className={classes.newGlobalResponse}>
            <div className={classes.newGlobalResponseInputWrapper}>
              <Typography>Local Language</Typography>
              <OutlinedInput
                disabled
                value="English"
                style={{ width: "65%" }}
              />
            </div>
            <div className={classes.newGlobalResponseInputWrapper}>
              <Typography>Global Standard Response</Typography>
              <div style={{ width: "65%" }}>
                <OutlinedInput
                  value={newGlobalResponseValue}
                  onChange={(e) => setNewGlobalResponseValue(e.target.value)}
                  placeholder="Please enter a global standard response"
                  style={{ width: "100%" }}
                />
                {errors.newGlobalResponseValueError && (
                  <FormHelperText
                    error={Boolean(errors.newGlobalResponseValueError)}
                  >
                    {errors.newGlobalResponseValueError}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
          <div className={classes.eventPropertyModalFooter}>
            <div />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!newGlobalResponseValue) {
                  setErrors({
                    newGlobalResponseValueError: t("errors.field_required"),
                  });
                  return;
                }
                const existing = globalTraitResponses.current.find(
                  (x) => x.predefinedTraitResponse === newGlobalResponseValue
                );

                if (existing) {
                  setSelectedGlobalTrait({
                    ...existing,
                    title: newGlobalResponseValue,
                  });
                } else {
                  const newGlobalResponse = {
                    predefinedTraitResponse: newGlobalResponseValue,
                    title: newGlobalResponseValue,
                    new: true,
                  };
                  const { current: globalTraitResponsesCurrent } =
                    globalTraitResponses;
                  globalTraitResponsesCurrent.push(newGlobalResponse);
                  setSelectedGlobalTrait(newGlobalResponse);
                }

                setNewGlobalResponseValue("");
                setNewGlobalResponsesModal(false);
              }}
            >
              {t("use_events_container.confirm")}
            </Button>
          </div>
        </CustomModal>
        <AliasesModal
          traitResponses={
            aliases?.map((row, index) => ({
              ...row,
              id: index,
            })) || []
          }
          isGlobal={isGlobal}
          handleAliasSubmit={(aliasesList) => {
            const modifiedTraitResponses = globalTraitResponses.current.map(
              (traitResponse) => {
                const updatedResponse = traitResponse;
                if (
                  traitResponse.predefinedTraitNumber ===
                  editableTrait.predefinedTraitNumber
                ) {
                  updatedResponse.predefinedTraitResponseAliases = aliases;
                }
                return updatedResponse;
              }
            );
            globalTraitResponses.current.splice(
              0,
              globalTraitResponses.current.length
            );
            globalTraitResponses.current.push(...modifiedTraitResponses);
            setAliases(aliasesList);
            setOpenManageAliasesModal(false);
          }}
          openManageAliasesModal={openManageAliasesModal}
          setOpenManageAliasesModal={setOpenManageAliasesModal}
        />
      </>
    );
  }
);

TraitsResponseModal.defaultProps = {
  open: false,
  editableTrait: {},
  diffList: [],
  isTraitsFlow: false,
  defaultLanguage: {},
};

TraitsResponseModal.propTypes = {
  renderList: PropTypes.array.isRequired,
  setRenderList: PropTypes.func.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  globalTraitResponses: PropTypes.array.isRequired,
  diffList: PropTypes.array,
  isTraitsFlow: PropTypes.bool,
  isGlobal: PropTypes.bool.isRequired,
  editableTrait: PropTypes.shape({
    id: PropTypes.string,
    sId: PropTypes.string,
    language: PropTypes.string,
    languageCode: PropTypes.string,
    predefinedTraitResponse: PropTypes.string,
    predefinedTraitResponseLocalLanguage: PropTypes.string,
    globalStandardResponse: PropTypes.string,
    predefinedTraitNumber: PropTypes.number,
    languageName: PropTypes.string,
    predefinedEventPropertyResponseLocalLanguage: PropTypes.string,
    predefinedEventPropertyResponse: PropTypes.string,
    predefinedEventPropertyNumber: PropTypes.number,
    predefinedTraitResponseAliases: PropTypes.arrayOf().isRequired,
  }),
  defaultLanguage: PropTypes.shape({
    languageName: PropTypes.string,
    languageCode: PropTypes.string,
    title: PropTypes.string,
  }),
  setDefaultLanguage: PropTypes.func.isRequired,
};

export default TraitsResponseModal;
