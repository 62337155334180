import { createElement } from "react";

import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import applicationConfig from "../../config/applicationConfig";

import InlineMessage from "../InlineMessage";
import StyledTooltip from "../StyledTooltip";

const getComponent = (type, props) => {
  if (type === applicationConfig.inputType.textInput) {
    return <TextField {...props} variant="outlined" />;
  }
  if (type === applicationConfig.inputType.dropdown) {
    return (
      <TextField InputLabelProps={{ shrink: false }} {...props}>
        {props.values &&
          props.values.map((item) => {
            if (item.type === "div") {
              return item;
            }
            return (
              <MenuItem
                value={item.value}
                key={item.label}
                disabled={item.disabled}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {item.label}
                  {item.error && (
                    <InlineMessage state="error" message={item.error} />
                  )}
                </div>
              </MenuItem>
            );
          })}
      </TextField>
    );
  }
  if (type === applicationConfig.inputType.multiSelectDropdown) {
    const formProps = { ...props };
    const { helperText } = formProps;
    delete formProps.helperText;
    return (
      <FormControl error={props.error}>
        <Select {...formProps}>
          {props.values &&
            props.values.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={props?.value?.indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
        </Select>
        {props.error && (
          <FormHelperText id="my-helper-text" error={Boolean(props.error)}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
  return <></>;
};

const renderCustomElement = (field) => {
  if (!field.children) {
    return createElement(field.element, field.props);
  }

  return createElement(
    field.element,
    field.props,
    ...field.children.map((child) => renderCustomElement(child))
  );
};

const Form = ({ fields, fieldClassName, containerClassName }) => {
  return (
    <div className={containerClassName}>
      {fields.map((field, index) => {
        return (
          <div
            className={fieldClassName}
            key={field.label || `${field.type}-${index + 1}`}
          >
            {!field.tooltip ? (
              <Typography variant="h6">
                {field.label}
                {field.required && <i style={{ color: "red" }}>*</i>}
              </Typography>
            ) : (
              <StyledTooltip
                placement={field.tooltip.placement}
                title={field.tooltip.title}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography variant="h6">
                    {field.label}
                    {field.required && <i style={{ color: "red" }}>*</i>}
                  </Typography>
                  {field.tooltip.icon}
                </div>
              </StyledTooltip>
            )}
            {field.type === "custom"
              ? renderCustomElement(field)
              : getComponent(field.type, field.props)}
          </div>
        );
      })}
    </div>
  );
};

Form.defaultProps = {
  fieldClassName: "",
  containerClassName: "",
};

Form.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ).isRequired,
  fieldClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

getComponent.propTypes = {
  type: PropTypes.string.isRequired,
  props: PropTypes.shape({
    values: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Form;
