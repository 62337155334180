import api from "../utilities/api";

const validateLogin = async (code) => {
  const response = await api.post("/auth/validate", {
    code,
  });
  return response.data;
};

export default validateLogin;
