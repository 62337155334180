import i18n from "../../../../i18n/init";
import { isEmptyString } from "../../../../utilities/formValidation";
import isEmpty from "../../../../utilities/isEmpty";

const validateNewMapping = (
  data,
  subscriptionOptNumbers,
  editableOptMapping,
  optMappings
) => {
  const requiredFields = [
    "optId",
    "channel",
    "contactPointCategoryCode",
    "serviceName",
    "primaryIndicator",
    "subscriptionOptNumber",
    "cdpMarketingProgram",
  ];

  const errors = {};

  if (
    optMappings.find(
      (x) =>
        x.ciamOptId === data.optId.ciamOptId &&
        Number(x.subscriptionOptNumber) ===
          Number(data.subscriptionOptNumber) &&
        x.serviceName === data.serviceName
    )
  ) {
    errors.duplicateRecordError = "This mapping already exists";
  } else {
    errors.duplicateRecordError = null;
  }

  requiredFields.forEach((field) => {
    if (isEmptyString(data[field])) {
      errors[`${field}Error`] = i18n.t("errors.field_required");
    }
    if (isEmpty(data[field])) {
      errors[`${field}Error`] = i18n.t("errors.field_required");
    }
  });

  if (
    !isEmptyString(data.subscriptionOptNumber) &&
    subscriptionOptNumbers.includes(parseInt(data.subscriptionOptNumber, 10)) &&
    !editableOptMapping
  ) {
    errors.subscriptionOptNumberError = i18n.t(
      "new_opt_id.errors.duplicate_subscription_opt_number"
    );
  } else if (
    !isEmptyString(data.subscriptionOptNumber) &&
    !/^\d+$/.test(data.subscriptionOptNumber)
  ) {
    errors.subscriptionOptNumberError = "Must be a numeric value";
  }

  return errors;
};

export default validateNewMapping;
