const createBackendPayload = (
  marketingProgram,
  ecosystems,
  channels,
  newEcosystems
) => {
  const payload = {
    schemaVersion: "v2",
    legalEntity: {
      legalEntityName: marketingProgram.legalEntity.legalOrg,
      legalEntityNumber: marketingProgram.legalEntity.legalEntityId,
      legalEntityShortName: marketingProgram.legalEntity.legalEntityShortName,
    },
    marketingProgram: {
      brandProgramName: marketingProgram.brandName,
      brandProgramShortName: marketingProgram.brandShortName,
      marketingProgramName: marketingProgram.marketingProgramName,
      description: marketingProgram.marketingProgramDescription,
      mpBusinessName: marketingProgram.marketingProgramBusinessName,
      businessOrgUnit: marketingProgram.businessOrgUnit,
      marketingProgramNumber: marketingProgram?.marketingProgramNumber,
    },
    marketingProgramEcosystems: ecosystems
      .filter((ecosystem) =>
        marketingProgram.startingEcosystems.includes(ecosystem.ecoSystemName)
      )
      .map((ecosystem) => ({
        ecosystemId: ecosystem.ecoSystemId,
        ecosystemName: ecosystem.ecoSystemName,
      })),
    ecosystems: newEcosystems?.map((ecosystem) => ({
      ecosystemId: ecosystem.ecosystemId,
      ecosystemName: ecosystem.ecosystemName,
      ecosystemMarketingProgramName: ecosystem.ecosystemMarketingProgramName,
      ecosystemMarketingProgramKey: ecosystem.ecosystemMarketingProgramKey,
      ecosystemMarketingProgramInstance:
        ecosystem.ecosystemMarketingProgramInstance,
      automationEnabled: true,
      segmentPersonaSpaceName: ecosystem.segmentPersonaSpaceName,
      isUpdated: ecosystem.isUpdated,
      new: ecosystem.new,
    })),
    contactPointTypes: channels
      .filter((channel) =>
        marketingProgram.startingChannels.includes(
          channel.contact_point_type_name
        )
      )
      .map((channel) => ({
        contactPointTypeCode: channel.contact_point_type_code,
        contactPointTypeName: channel.contact_point_type_name,
      })),
  };

  if (!payload.marketingProgram.marketingProgramNumber) {
    delete payload.marketingProgram.marketingProgramNumber;
  }

  return payload;
};

export default createBackendPayload;
