import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    regularBtn: {
      backgroundColor: `${theme.palette.blue.dark} !important`,
      color: "white !important",
      "&:hover": {
        backgroundColor: `${theme.palette.blue.dark} !important`,
        color: "white !important",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    flexContainer2: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
    },
    tableWrapper: {
      maxHeight: "600px",
      "& > div": {
        overflow: "auto",
        overflowX: "hidden",
        height: "100% !important",
      },
    },
    deleted: {
      backgroundColor: theme.palette.error.light,
      padding: "5px 8px",
      borderRadius: 20,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
      fontWeight: "bold",
      marginLeft: "8px",
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    new: {
      backgroundColor: theme.palette.blue.dark,
      padding: "5px 8px",
      borderRadius: 20,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
      fontWeight: "bold",
      marginLeft: "8px",
      "&:hover": {
        backgroundColor: theme.palette.blue.dark,
      },
    },
    fullWidth: {
      width: "100%",
    },
    marginTop20: {
      marginTop: "10px",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
        marginBottom: "24px",
      },
    },
    grid: {
      display: "grid",
      gridGap: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
      "& .MuiAutocomplete-root": {
        width: "auto !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    columnGrid: {
      gridTemplateColumns: "repeat(auto-fill, minmax(400px, 2fr)) !important",
    },
    labelContainer: {
      display: "flex",
    },
    inputContainer: {
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: `${theme.colorTheme.grey} !important`,
        fontWeight: 400,
      },
      "& .MuiPaper-elevation1": {
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    sourceNameContainer: {
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
      },
    },
    marketingProgramSourceContainer: {
      marginBottom: "20px",
    },
    popper: {
      "& .MuiCheckbox-root": {
        color: theme.palette.primary.light,
      },
    },
    badge: {
      backgroundColor: theme.colorTheme.bluishWhite,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "4px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.colorTheme.grey,
    },
    sourceContainer: {
      padding: "20px 40px !important",
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    paddingZero: {
      padding: "0px",
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    confirmationContainer: {
      "& .MuiTypography-h4": {
        fontSize: "18px",
        color: theme.palette.text.primary,
        fontWeight: "bold",
        marginBottom: "16px",
      },
      "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell button.MuiButtonBase-root.MuiButton-root":
        {
          backgroundColor: theme.palette.blue.dark,
          padding: "5px 8px",
          borderRadius: 20,
          fontSize: "12px",
          textAlign: "center",
          color: theme.colorTheme.white,
          fontWeight: "bold",
          marginLeft: "8px",
          "&:hover": {
            backgroundColor: theme.palette.blue.dark,
          },
        },
    },
    noOptionsContainer: {
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    flexContainerVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "5px",
    },
    badgeText2: {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
      },
    },
    backBtn: {
      "& a": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
      "& button": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
    },
    optsTraitContainer: {
      marginLeft: "30px",
      "& > div:first-child": {
        marginBottom: "20px",
      },
      "& .MuiPaper-elevation1": {
        boxShadow: "none",
      },
    },
    inputContainer1: {
      width: "700px",
      "& > div": {
        width: "100%",
      },
    },
    btnContainer: {
      "& button": {
        minWidth: "200px !important",
      },
      "& span > span": {
        marginLeft: "auto",
      },
      "& svg": {
        marginLeft: "auto",
      },
    },
    footerRightContainer: {
      flex: "0 0 50%",
      display: "flex",
      alignItems: "start",
      "& a": {
        textAlign: "center",
        marginRight: "20px !important",
      },
    },
    step2Footer: {
      "& button, & a": {
        marginRight: "10px",
        whiteSpace: "no-wrap",
      },
      "& .MuiButton-label": {
        whiteSpace: "no-wrap",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.palette.text.primary,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.palette.text.primary,
        fontWeight: "bold",
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& a,& a:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        border: "none",
        marginLeft: "auto",
      },
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
      "& h6": {
        fontSize: "0.875rem",
        fontWeight: "400",
      },
    },
    infoIcon: {
      width: 18,
      height: 18,
      marginLeft: "6px",
      position: "relative",
      top: "2px",
      cursor: "pointer",
    },
    outlinedIcon: {
      position: "relative",
      top: "-2px",
      cursor: "pointer",
    },
    optsIndicatorMessage: {
      marginTop: "14px",
      fontSize: "16px",
      "& span": {
        fontWeight: "bold",
      },
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    statusPending: {
      backgroundColor: theme.palette.warning.light,
    },
    statusApproved: {
      backgroundColor: theme.palette.green.main,
    },
    statusRejected: {
      backgroundColor: theme.palette.error.main,
    },
    fileIconWrapper: {
      marginLeft: "10px",
    },
    loadingSkeleton: {
      width: "807px !important",
      padding: "30px",
    },
  };
});

export default useStyles;
