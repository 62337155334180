import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    tableWrapper: {
      height: "calc(100vh - 220px) !important",
      "& .MuiDataGrid-footerContainer, & .MuiDataGrid-window": {
        backgroundColor: theme.palette.background.default,
      },
      "& .MuiDataGrid-cellCheckbox .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-disabled path":
        {
          color: "grey",
        },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
      },
    },
    table: {
      "& .MuiDataGrid-columnHeader:focus": {
        outline: "none !important",
      },
      "& .MuiDataGrid-main": {
        border: "1px solid rgba(255, 255, 255, 0.12)",
        backgroundColor: theme.palette.background.default,
      },
      "& .MuiDataGrid-columnHeadersInner, & .MuiDataGrid-columnHeadersInner > div":
        {
          borderBottom: "1px solid white",
        },
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& .MuiDataGrid-columnHeaderWrapper": {
        background: theme.palette.background.default,
      },
      "& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(even)":
        {
          backgroundColor: theme.colorTheme.tableRowEven,
        },
      "& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(odd)":
        {
          backgroundColor: theme.colorTheme.tableRowOdd,
        },
      "& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
        minHeight: "55px",
      },
      "& .MuiDataGrid-colCellTitleContainer": {
        overflow: "visible",
      },
      "& .MuiDataGrid-cell": {
        // backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        wordBreak: "break-word",
        whiteSpace: "normal !important",
        paddingTop: "10px",
        paddingBottom: "10px",
        outline: "unset !important",
        "& .MuiDataGrid-cellContent": {
          overflow: "hidden",
          hyphens: "auto",
        },
      },
      "& .MuiSvgIcon-root": {
        "& path": {
          color: theme.colorTheme.black,
        },
      },
      "& .MuiDataGrid-colCellTitle, .MuiDataGrid-cell": {
        // lineHeight: "1 !important",
        overflow: "visible",
        display: "flex",
        alignItems: "center",
      },
      "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-footer": {
        position: "absolute",
        bottom: "-54px",
        right: "-5px",
      },
      "& .MuiDataGrid-cell.MuiDataGrid-cellLeft": {
        maxHeight: "unset !important",
        wordBreak: "break-word",
        textOverflow: "ellipsis",
      },
      "& .MuiDataGrid-row, .MuiDataGrid-renderingZone, .MuiDataGrid-viewport, .MuiDataGrid-cell":
        {
          maxHeight: "unset !important",
        },
    },
    noRowsOverlay: {
      marginTop: 140,
      width: "100%",
      textAlign: "center",
      color: theme.colorTheme.greyishBlack3,
    },
    clickableCell: {
      color: theme.palette.blue.light,
      cursor: "pointer",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    footerOperation: {
      "& > span:first-child": {
        marginRight: "10px",
      },
      "& input": {
        padding: "8.5px 14px",
      },
    },
    drawerWrapper: {
      backgroundColor: theme.palette.background.default,
      flex: "1",
      padding: "10px 20px",
      "& > div:first-child": {
        marginBottom: "10px",
      },
      "& > div:not(:first-child)": {
        marginBottom: "15px",
        padding: "5px 10px",
        borderRadius: "5px",
        backgroundColor: theme.colorTheme.tableDrawerBackgroundColor,
      },
    },
    drawerHeaderWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "200px",
      "& p": {
        fontWeight: "bold",
        fontSize: "18px",
      },
    },
    columnFilterBtn: {
      padding: "unset",
      "&:hover": {
        backgroundColor: "unset",
      },
    },
  };
});

export default useStyles;
