import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "@material-ui/core";

import getReferenceData from "../api/get-reference-data";
import applicationConfig from "../config/applicationConfig";
import handleError from "../utilities/handleError";

import useLoadingSpinner from "./useLoadingSpinner";
import useNotifier from "./useNotifier";

function useLegalEntities() {
  const { addNotification } = useNotifier();
  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();
  const [allLegalEntitiesOptions, setAllLegalEntitiesOptions] = useState([]);
  const [legalEntitiesLoading, setLegalEntitiesLoading] = useState(false);
  const [legalEntities, setLegalEntities] = useState([]);
  const [initialLegalEntitiesOptions, setInitialLegalEntitiesOptions] =
    useState([]);
  const waitForReferenceDataPromiseRef = useRef();

  const getLegalEntitiesFromApi = useCallback(
    async (searchText) => {
      try {
        setLegalEntitiesLoading(true);
        await waitForReferenceDataPromiseRef.current;
        let localLegalEntities = [...allLegalEntitiesOptions];
        if (searchText?.length > 0) {
          localLegalEntities = localLegalEntities.filter(
            (x) =>
              `${x.legalEntityId}`.includes(searchText) ||
              x.legalOrg.toLowerCase().includes(searchText.toLowerCase())
          );
          if (localLegalEntities.length === 0) {
            setLegalEntitiesLoading(false);
          }
          setLegalEntities(localLegalEntities);
        } else {
          setInitialLegalEntitiesOptions([...localLegalEntities.slice(0, 3)]);
        }
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setLegalEntities([]);
          },
          addNotification,
        });
      }
    },
    [addNotification, allLegalEntitiesOptions]
  );

  useEffect(() => {
    if (allLegalEntitiesOptions.length > 0) {
      getLegalEntitiesFromApi();
    }
  }, [allLegalEntitiesOptions, getLegalEntitiesFromApi]);

  useEffect(() => {
    waitForReferenceDataPromiseRef.current = (async () => {
      increaseRequestsCount(1);
      setLegalEntitiesLoading(true);
      try {
        const data = await getReferenceData(
          applicationConfig.referenceDataQueries.legalEntities
        );

        setAllLegalEntitiesOptions(
          data.legalEntities.map((x) => ({
            ...x,
            legalEntityName: x.legalOrg,
          }))
        );
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setAllLegalEntitiesOptions([]);
          },
          addNotification,
        });
      } finally {
        setLegalEntitiesLoading(false);
        decreaseRequestsCount(1);
      }
    })();
  }, [addNotification, decreaseRequestsCount, increaseRequestsCount]);

  const memoizedLegalEntitiesFromApi = useCallback(
    debounce(getLegalEntitiesFromApi, applicationConfig.waitTime),
    [getLegalEntitiesFromApi]
  );

  useEffect(() => {
    return () => {
      memoizedLegalEntitiesFromApi.clear();
    };
  }, [memoizedLegalEntitiesFromApi]);

  return useMemo(
    () => ({
      memoizedLegalEntitiesFromApi,
      allLegalEntitiesOptions,
      legalEntitiesLoading,
      legalEntities,
      initialLegalEntitiesOptions,
      getLegalEntitiesFromApi,
      setInitialLegalEntitiesOptions,
      setLegalEntities,
      setLegalEntitiesLoading,
    }),
    [
      allLegalEntitiesOptions,
      getLegalEntitiesFromApi,
      initialLegalEntitiesOptions,
      legalEntities,
      legalEntitiesLoading,
      memoizedLegalEntitiesFromApi,
    ]
  );
}

export default useLegalEntities;
