import { useState, useEffect, useCallback, useMemo, useRef } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { Skeleton, Alert } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createTraitRequest from "../../../api/create-trait-request";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import getReferenceData from "../../../api/get-reference-data";
import getRequestDetails from "../../../api/get-request-details";
import getTrait from "../../../api/get-trait";
import getTraits from "../../../api/get-traits";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomTooltip from "../../../components/CustomTooltip";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import TextBox from "../../../components/TextBox";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import reverseObject from "../../../utilities/reverseObject";
import useImmer from "../../../utilities/useImmer";

import NewTrait from "../components/NewTrait";
import TraitMpMappingModal from "../components/TraitsMarketingProgramMappingModal";
import TraitsRequestOutputModal from "../components/TraitsRequestOutputModal";

import buildMappings from "./helpers/buildMappings";
import createBackendPayload from "./helpers/createBackendPayload";
import useStyles from "./styles";

const CreateTraitsContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const { requestStatus } = applicationConfig;
  const { createTraitConstants, survivorshipRuleMapping } = traitsModuleConfig;
  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const location = useLocation();
  const isReuseTraitsFlow = location?.state?.flow === "reuse";

  let requestId = null;

  if (location.state) {
    requestId = location.state.requestId;
  }

  useEffect(() => {
    if (
      location.pathname === applicationConfig.pathnames.traits_requests_revision
    ) {
      if (Object.keys(request).length === 0 && !requestId) {
        history.goBack();
      }
    }
  }, []);

  const isRequestRevisionFlow =
    Object.keys(request).length !== 0 || Boolean(requestId);
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [mapTraitResponseType, setMapTraitResponseType] = useState(false);
  const [mapSurvivorshipRules, setMapSurvivorshipRules] = useState(false);
  const [viewMappings, setViewMappings] = useState(false);
  const [storeDataLoading, setStoreDataLoading] = useState(false);
  // const [traitMappings, setTraitMappings] = useState({});
  const [ecoSystems, setEcoSystems] = useState([]);

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createTraits
  );

  const totalSteps = 3;

  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation();

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const [formErrors, setFormErrors] = useState({});

  const [traitValue, setTraitValue] = useState("");
  const [traitOptions, setTraitOptions] = useState([]);
  const [initialTraitOptions, setInitialTraitOptions] = useState([]);
  const traitMpEcosystemApiCombinations = useRef({});
  const [traitsInfo, setTraitsInfo] = useImmer({
    dataType: createTraitConstants.dataTypeOptions[0],
    dataClass: createTraitConstants.dataClassOptions[0],
    traitType: createTraitConstants.traitTypeOptions[0],
    traitResponseType: createTraitConstants.traitResponseTypeOptions[0],
    personalData: false,
    personalDataNonPii: false,
    healthData: false,
    frequencyChange: "",
    ecoSystemTraits: [],
    traitName: "",
    traitDescription: "",
    exampleValues: "",
    traitInputType: "",
    survivorshipRule: createTraitConstants.survivorshipRules[0],
  });
  const [traitsSelected, setTraitsSelected] = useState([
    {
      title: "",
      isSelected: false,
      isNewTrait: false,
    },
  ]);
  const [traitSelectedIndex, setTraitSelectedIndex] = useState(-1);
  const [ecosystemOptions, setEcosystemOptions] = useState([]);
  const [selectedMappings, setSelectedMappings] = useState("");

  const [isNewTraitDisabled, setIsNewTraitDisabled] = useState(false);

  // Marketing Program
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [initialMarketingProgramOptions, setInitialMarketingProgramOptions] =
    useState([]);
  const initialMarketingProgramRespState = {
    searchText: "",
    page: 0,
  };
  const [marketingProgramsResp, setMarketingProgramsResp] = useState(
    initialMarketingProgramRespState
  );
  const [searchMarketingProgramValue, setSearchMarketingProgramValue] =
    useState("");
  const [marketingProgramsSelected, setMarketingProgramsSelected] = useState(
    []
  );
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(false);

  // Ecosystems
  const [ecoSystemsSelected, setEcosystemsSelected] = useState([
    {
      title: "dataLake",
      ecosystemId: 3,
      ecosystemName: "dataLake",
    },
    {
      title: "bigQuery",
      ecosystemId: 5,
      ecosystemName: "bigQuery",
    },
    {
      title: "CPC",
      ecosystemId: 9,
      ecosystemName: "CPC",
    },
  ]);
  const [ecoSystemsLoading, setEcosystemsLoading] = useState(true);

  // TraitOutput
  const [traitOutputLoading, setTraitOutputLoading] = useState(false);
  const [traitOutputModal, setTraitOutputModal] = useState(false);
  const [traitOutput, setTraitOutput] = useState({});

  const [traitMappingResponse, setTraitMappingResponse] = useState([]);

  // current trait index
  const [currentTraitIndex, setCurrentTraitIndex] = useState(0);

  const [traitsLoading, setTraitsLoading] = useState(false);

  const handleEcoSystemSelection = (selectedEcosystems) => {
    const selEcosystems = selectedEcosystems.map(
      (value) => value.ecosystemName
    );
    if (
      selEcosystems.includes("segment") &&
      !selEcosystems.includes("CDP2.0")
    ) {
      selectedEcosystems.push({
        title: "CDP2.0",
        ecosystemId: 10,
        ecosystemName: "CDP2.0",
        personalDataAllowed: true,
      });
    }
    setEcosystemsSelected(selectedEcosystems);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
    setTraitsInfo({
      dataType: createTraitConstants.dataTypeOptions[0],
      dataClass: createTraitConstants.dataClassOptions[0],
      traitType: createTraitConstants.traitTypeOptions[0],
      traitResponseType: createTraitConstants.traitResponseTypeOptions[0],
      personalData: false,
      personalDataNonPii: false,
      healthData: false,
      ecoSystemTraits: [],
      traitName: "",
      traitDescription: "",
      exampleValues: "",
      traitInputType: "",
      frequencyChange: "",
      survivorshipRule: createTraitConstants.survivorshipRules[0],
    });
    setTraitValue("");
    setIsNewTraitDisabled(false);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const validateForm = (step) => {
    if (step === 1) {
      if (
        traitsSelected.filter((trait) => trait.title).length !==
        traitsSelected.length
      ) {
        return {
          traits: traitsSelected.map((trait) => {
            if (!trait.title) {
              return {
                error: t("traits_container.errors.select_trait"),
              };
            }
            return {
              error: "",
            };
          }),
        };
      }
    }
    if (step === 2) {
      const errors = {};
      if (marketingProgramsSelected.length === 0) {
        errors.marketingPrograms =
          "Please select atleast one marketing program";
      }
      if (ecoSystemsSelected.length === 0) {
        errors.ecosystems = "Please select atleast one ecosystem";
      }
      return errors;
    }
    return {};
  };

  const isInfoStep = currentStep > totalSteps;

  const newTrait = (
    <div className="newTrait">
      <Typography variant="h6">
        {t("create_trait.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
      >
        {t("create_trait.new_trait")}
      </Button>
    </div>
  );

  const getTraitsFromApi = useCallback(async (searchText, page, perPage) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
        searchFields: "traitId,traitName,description",
      };
    }
    try {
      const rsp1 = await getTraits(
        `?page=${page || 1}&itemsPerPage=${perPage || 6}&${new URLSearchParams(
          filter
        ).toString()}`
      );
      setTraitOptions([...rsp1.items, newTrait]);
      if (searchText.length === 0) {
        if (initialTraitOptions.length > 0) {
          const newInitialTraitOptions = [...initialTraitOptions];
          newInitialTraitOptions.splice(
            newInitialTraitOptions.length - 1,
            0,
            ...rsp1.items
          );
          setInitialTraitOptions(newInitialTraitOptions);
        } else {
          setInitialTraitOptions([...rsp1.items, newTrait]);
        }
      }
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setTraitOptions([newTrait]);
        },
        addNotification,
      });
    } finally {
      setTraitsLoading(false);
    }
  }, []);

  const getEcosystemsFromApi = useCallback(
    async (name) => {
      try {
        let localEcosystems = [...ecoSystems];

        if (name?.length > 0) {
          localEcosystems = localEcosystems.filter((x) =>
            x.ecoSystemName.match(name)
          );
        }

        let ecosystemTraits = [];

        const ecosystemEntries = [];

        if (location.state?.type === "single-reuse") {
          const traitResponse = await getTrait(
            location.state.traits[0].traitId
          );
          ecosystemTraits = traitResponse.ecosystems;
        }

        localEcosystems.forEach((ecosystem) => {
          const ecoEntry = {
            ...ecosystem,
          };
          const ecoTrait = ecosystemTraits.find(
            (entry) => entry.ecosystemName === ecosystem.ecoSystemName
          );
          if (ecoTrait) {
            ecoEntry.personalDataAllowed =
              ecoEntry.personalDataAllowed || ecoTrait.personalDataException;
          }
          ecosystemEntries.push(ecoEntry);
        });

        setEcosystemOptions(localEcosystems);
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      } finally {
        setEcosystemsLoading(false);
      }
      return [];
    },
    [ecoSystems]
  );

  useEffect(async () => {
    increaseRequestsCount(1);
    try {
      const data = await getReferenceData(
        applicationConfig.referenceDataQueries.ecoSystems
      );

      setEcoSystems(
        data.ecoSystems
          .filter((x) => x.ecoSystemName !== "1CP")
          .map((x) => ({
            ...x,
            ecosystemName: x.ecoSystemName,
            ecosystemId: x.ecoSystemId,
          }))
      );
    } catch (error) {
      handleError({
        error,
        handle404: true,
        addNotification,
      });
    } finally {
      setEcosystemsLoading(false);
      decreaseRequestsCount(1);
    }
  }, []);

  useEffect(() => {
    if (ecoSystems.length > 0) {
      getEcosystemsFromApi();
    }
  }, [ecoSystems]);

  const marketingProgramValues =
    searchMarketingProgramValue === ""
      ? initialMarketingProgramOptions
      : marketingProgramOptions;

  const filteredMarketingProgramOpts = useMemo(
    () =>
      marketingProgramValues.filter(
        (mpOpt) =>
          !marketingProgramsSelected.some(
            (mpS) => mpS.marketingProgramNumber === mpOpt.marketingProgramNumber
          )
      ),
    [marketingProgramValues, marketingProgramsSelected]
  );

  const getMarketingProgramsFromApi = useCallback(
    async (
      searchText,
      marketingProgramsResponse,
      marketingProgramOpts,
      filteredMarketingProgramOptions
    ) => {
      let filter = { itemsPerPage: 6, page: 1 };
      if (searchText && searchText.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      try {
        if (searchText === marketingProgramsResponse?.searchText) {
          if (
            filteredMarketingProgramOptions.length > 2 ||
            marketingProgramsResponse?.items?.length === 0
          ) {
            return;
          }
          filter.page = Number(marketingProgramsResponse.page) + 1;
        }
        const rsp1 = await getMarketingProgramsApi(filter);
        if (searchText === marketingProgramsResponse?.searchText) {
          const mpOptions = [
            ...marketingProgramOpts,
            ...rsp1.items.filter(
              (x) =>
                !marketingProgramOpts.find(
                  (y) => y.marketingProgramNumber === x.marketingProgramNumber
                )
            ),
          ];
          setMarketingProgramOptions(mpOptions);
        } else {
          setMarketingProgramOptions(rsp1.items);
        }
        if (searchText.length === 0) {
          setInitialMarketingProgramOptions([...rsp1.items]);
        }
        setMarketingProgramsResp({
          ...rsp1,
          searchText,
        });
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      } finally {
        setMarketingProgramsLoading(false);
      }
    },
    [addNotification]
  );

  const handleSubmit = (traitinfo) => {
    const currentTraits = [...traitsSelected];
    currentTraits[currentTraitIndex] = {
      ...traitinfo,
      title: traitinfo.traitName,
      isSelected: true,
      isNewTrait: true,
    };
    setTraitsSelected(currentTraits);
    handleClose();
  };

  const convertToUiSchema = (trait) => {
    const ruleMapping = reverseObject(survivorshipRuleMapping);
    if (!trait.traitId) {
      return {
        ...trait,
        personalData: trait.personalData,
        personalDataNonPii: trait.personalDataNonPii,
        ecoSystemTraits: trait.ecosystemTraits.map((ecosystem) => {
          return {
            ...ecosystem,
            dataType:
              ecosystem.dataType.charAt(0).toUpperCase() +
              ecosystem.dataType.slice(1),
          };
        }),
        traitDescription: trait.description,
        dataType:
          trait.dataType.charAt(0).toUpperCase() + trait.dataType.slice(1),
        traitInputType: trait.formInput,
        frequencyChange: trait.uDataChangeFrequency,
        traitResponseType: trait.multiAnswerResponseIndicator
          ? "Multiple"
          : "Single",
        survivorshipRule: ruleMapping[trait.survivorshipRule],
      };
    }
    return trait;
  };

  // Debounce & Memoize Api Calls
  const debouncedTraitsFromApi = debounce(
    getTraitsFromApi,
    applicationConfig.waitTime
  );

  const memoizedTraitsFromApi = useCallback(async (val) => {
    debouncedTraitsFromApi(val);
  }, []);

  const memoizedMarketingProgramsFromApi = useMemo(() => {
    return debounce(getMarketingProgramsFromApi, 2000);
  }, [getMarketingProgramsFromApi]);

  // Debounce & Memoize Api Calls
  const debouncedEcosystemsFromApi = debounce(
    getEcosystemsFromApi,
    applicationConfig.waitTime
  );

  const memoizedEcosystemsFromApi = useCallback(
    (val) => {
      debouncedEcosystemsFromApi(val);
    },
    [ecoSystems, currentStep]
  );

  useEffect(() => {
    if (traitValue.length > 0) {
      memoizedTraitsFromApi(traitValue);
    }
  }, [traitValue]);

  const setTraitsData = (data) => {
    const trait = data.requestDetails.traits
      ? data.requestDetails.traits[0]
      : data.requestDetails.trait;
    setTraitsSelected([
      {
        ...convertToUiSchema(trait),
        title: trait.traitName,
        isSelected: true,
        isNewTrait: !trait.traitId,
      },
    ]);
    setMarketingProgramsSelected(
      data.requestDetails.marketingPrograms.map((program) => ({
        title: `${program.marketingProgramNumber} - ${program.description}`,
        marketingProgramNumber: program.marketingProgramNumber,
        description: program.description,
      }))
    );
    setEcosystemsSelected(
      data.requestDetails.ecosystems.map((ecosystem) => ({
        title: ecosystem.ecosystemName,
        ecosystemId: ecosystem.ecosystemId,
        ecosystemName: ecosystem.ecosystemName,
      }))
    );
    const mappings = buildMappings(
      data.requestDetails.survivorshipRules,
      data.requestDetails.traitResponseTypes,
      data.requestDetails.ecosystems
    );
    traitMpEcosystemApiCombinations.current = mappings;
  };

  useEffect(async () => {
    if (currentStep === 2) {
      const mappingStore = {};
      setStoreDataLoading(true);
      const selectedTraitIds = [];
      let latestTraits = traitsSelected;
      traitsSelected.forEach((trait) => {
        if (
          trait.traitId &&
          (!trait.marketingPrograms ||
            !trait.marketingPrograms[0]?.survivorshipRules)
        ) {
          selectedTraitIds.push(trait.traitId);
        }
      });
      if (selectedTraitIds.length > 0) {
        const { items: traitsData } = await getTraits(
          `?traitIds=${selectedTraitIds.join(",")}`
        );
        latestTraits = traitsSelected.map((trait) => {
          const traitMapping = traitsData.find(
            (tr) => tr.traitId === trait.traitId
          );
          if (traitMapping) {
            return {
              ...trait,
              ...traitMapping,
            };
          }
          return trait;
        });
        setTraitsSelected(latestTraits);
      }
      setStoreDataLoading(false);
      const ruleMapping = reverseObject(survivorshipRuleMapping);
      latestTraits.forEach((trait) => {
        if (trait.traitResponseTypes && trait.survivorshipRules) {
          trait.traitResponseTypes.forEach((entry) => {
            mappingStore[
              `${entry.traitId || entry.traitName}#${
                entry.marketingProgramNumber
              }`
            ] = entry.multiAnswerResponseIndicator;
          });
          trait.survivorshipRules.forEach((entry) => {
            mappingStore[
              `${entry.traitId || entry.traitName}#${
                entry.marketingProgramNumber
              }#${entry.ecosystemName}`
            ] = entry.multiAnswerResponseIndicator;
          });
        }
        if (trait.traitId && trait.marketingPrograms) {
          trait.marketingPrograms.forEach((marketingProgram) => {
            mappingStore[
              `${trait.traitId}#${marketingProgram.marketingProgramNumber}`
            ] = marketingProgram.multiAnswerResponseIndicator;
            const rules = marketingProgram.survivorshipRules.split(",");
            marketingProgram.ecosystems
              .split(",")
              .forEach((ecosystem, index) => {
                mappingStore[
                  `${trait.traitId}#${marketingProgram.marketingProgramNumber}#${ecosystem}`
                ] = ruleMapping[rules[index]];
              });
            ecoSystems.forEach((ecosystem) => {
              if (
                !marketingProgram.ecosystems
                  .split(",")
                  .includes(ecosystem.ecosystemName)
              ) {
                mappingStore[
                  `${trait.traitId}#${marketingProgram.marketingProgramNumber}#${ecosystem.ecosystemName}`
                ] = t("constants.survivorship_rules.most_recent");
              }
            });
          });
        }
        if (!trait.traitId && marketingProgramsSelected.length > 0) {
          marketingProgramsSelected.forEach((marketingProgram) => {
            mappingStore[
              `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }`
            ] = trait.traitResponseType;
            ecoSystemsSelected.forEach((ecosystem) => {
              mappingStore[
                `${trait.traitId || trait.traitName}#${
                  marketingProgram.marketingProgramNumber
                }#${ecosystem.ecosystemName}`
              ] = trait.survivorshipRule;
            });
          });
        }
      });
      traitMpEcosystemApiCombinations.current = {
        ...mappingStore,
        ...traitMpEcosystemApiCombinations.current,
      };
    }
  }, [currentStep]);

  useEffect(() => {
    const mappingStore = traitMpEcosystemApiCombinations.current;
    if (!mappingStore) return;
    traitsSelected.forEach((trait) => {
      marketingProgramsSelected.forEach((marketingProgram) => {
        if (
          !(
            `${trait.traitId || trait.traitName}#${
              marketingProgram.marketingProgramNumber
            }` in mappingStore
          )
        ) {
          if (!trait.traitId) {
            mappingStore[
              `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }`
            ] = trait.traitResponseType === "Multiple";
          } else {
            mappingStore[
              `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }`
            ] = false;
          }
        }
      });
    });
  }, [marketingProgramsSelected]);

  useEffect(() => {
    const mappingStore = traitMpEcosystemApiCombinations.current;
    traitsSelected.forEach((trait) => {
      marketingProgramsSelected.forEach((marketingProgram) => {
        ecoSystemsSelected.forEach((ecosystem) => {
          if (
            !(
              `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }#${ecosystem.ecosystemName}` in mappingStore
            )
          ) {
            if (!trait.traitId) {
              mappingStore[
                `${trait.traitId || trait.traitName}#${
                  marketingProgram.marketingProgramNumber
                }#${ecosystem.ecosystemName}`
              ] = trait.survivorshipRule;
            } else {
              mappingStore[
                `${trait.traitId || trait.traitName}#${
                  marketingProgram.marketingProgramNumber
                }#${ecosystem.ecosystemName}`
              ] = t("constants.survivorship_rules.most_recent");
            }
          }
        });
      });
    });
  }, [marketingProgramsSelected, ecoSystemsSelected]);

  useEffect(async () => {
    if (isRequestRevisionFlow && !requestId) {
      setTraitsData(request);
    } else if (requestId) {
      const details = await getRequestDetails(requestId);
      setTraitsData(details);
    }
  }, []);

  useEffect(() => {
    if (isReuseTraitsFlow) {
      setTraitsSelected(
        location.state?.traits.map((trait) => ({
          ...convertToUiSchema(trait),
          title: `${trait.traitId} - ${trait.traitName} - ${trait.description}`,
          isSelected: true,
          isNewTrait: false,
        }))
      );
    }
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(2);
      await getTraitsFromApi("");
      await getMarketingProgramsFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  useEffect(async () => {
    const filteredInitialTraitOptions = initialTraitOptions.filter((_trait) => {
      if (_trait.type !== "div") {
        const isExisting = traitsSelected.find(
          (existingTrait) => existingTrait.title === _trait.traitName
        );
        if (isExisting) {
          return false;
        }
        return true;
      }
      return true;
    });
    if (
      initialTraitOptions.length > 0 &&
      filteredInitialTraitOptions.length < 4 &&
      !traitsLoading
    ) {
      setTraitsLoading(true);
      try {
        await getTraitsFromApi(
          "",
          Math.floor(initialTraitOptions.length / 6 + 1),
          6
        );
        traitMpEcosystemApiCombinations.current = {};
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      }
    }
  }, [initialTraitOptions, traitsSelected, getTraitsFromApi]);

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.trait_names"),
        value: traitsSelected.map((trait) => {
          if (trait.isNewTrait) {
            return (
              <div
                key={`${trait.title}`}
                className={clsx(classes.flexContainer, classes.marginTop20)}
              >
                <div
                  className={classes.newBtnContainer}
                  onClick={() => {
                    setIsNewTraitDisabled(true);
                    setTraitsInfo(trait);
                    setOpenModal(true);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  <span className="traitName">{trait.title}</span>
                  <Button variant="contained">{t("common.new")}</Button>
                </div>
              </div>
            );
          }
          return <div>{trait.title}</div>;
        }),
      },
      {
        label: t("common.labels.marketing_programs"),
        value: (
          <div>
            {marketingProgramsSelected.map((program) => (
              <Typography variant="h6">{program.title}</Typography>
            ))}
          </div>
        ),
      },
      {
        label: t("common.labels.ecosystems"),
        value: (
          <div>
            {ecoSystemsSelected.map((ecoSystem) => (
              <Typography variant="h6">{ecoSystem.title}</Typography>
            ))}
          </div>
        ),
      },
      {
        label: "Manage Trait Response Types",
        value: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedMappings("traitResponseTypes");
              setViewMappings(true);
            }}
          >
            View Mappings
          </Button>
        ),
      },
      {
        label: "Manage Survivorship Rules",
        value: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSelectedMappings("survivorshipRules");
              setViewMappings(true);
            }}
          >
            View Mappings
          </Button>
        ),
      },
    ];
    return info;
  };

  const handleTraitOutputModal = async (reqId) => {
    setTraitOutputLoading(true);
    setTraitOutputModal(true);
    try {
      const response = await getRequestDetails(reqId);
      setTraitOutput(response);
      setTraitOutputLoading(false);
    } catch (error) {
      setTraitOutputModal(false);
      setTraitOutputLoading(false);
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const createRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "traitName",
      headerName: t("common.labels.trait_name"),
      flex: 1,
      renderCell: (params) => {
        const { value, row } = params;
        return row.isNewTrait ? (
          <div role="button" aria-hidden="true" className={classes.traitName}>
            <span>{value}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        ) : (
          <span>{value}</span>
        );
      },
      sortable: false,
    },
    {
      field: "ecosystems",
      headerName: t("common.labels.ecosystems"),
      flex: 1,
      renderCell: ({ value }) => <CustomTooltip items={value} />,
      sortable: false,
    },
    {
      field: "marketingPrograms",
      headerName: t("common.labels.marketing_programs"),
      flex: 1,
      renderCell: ({ value }) => <CustomTooltip items={value} />,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  handleTraitOutputModal(params.row.requestId);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const renderStep = (step) => {
    if (step === 1) {
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      const traitDropdownValues =
        traitValue.length > 0 ? traitOptions : initialTraitOptions;
      return (
        <div className={classes.center} data-testid="traits-container">
          {traitsSelected.map((trait, traitIndex) => {
            const traitName = trait.title;
            let errorText = "";
            if (formErrors.traits && formErrors.traits[traitIndex]) {
              errorText = formErrors.traits[traitIndex].error;
            }
            return !trait.isSelected ? (
              <div
                className={clsx(classes.flexContainer, classes.autoCompleteBox)}
                key={`${traitName} - ${traitIndex + 1}`}
              >
                <CustomAutoComplete
                  isMultiple={false}
                  classes={{
                    listbox: classes.listbox,
                  }}
                  options={(() => {
                    const options = traitDropdownValues
                      .filter((_trait) => {
                        if (_trait.type !== "div") {
                          const isExisting = traitsSelected.find(
                            (existingTrait) =>
                              existingTrait.traitName === _trait.traitName
                          );
                          if (isExisting) {
                            return false;
                          }
                          return true;
                        }
                        return true;
                      })
                      .map((option) => {
                        if (option.type !== "div") {
                          return {
                            title: `${option.traitId} - ${option.traitName} - ${option.description}`,
                            ...option,
                          };
                        }
                        return option;
                      });
                    if (options.length > 4) {
                      return [...options.slice(0, 3), ...options.slice(-1)];
                    }
                    return options;
                  })()}
                  id={`trait-${traitIndex}`}
                  placeholder={
                    loading
                      ? t("common.loading")
                      : t("autocomplete.trait_select_or_create_placeholder")
                  }
                  loading={traitsLoading}
                  value={
                    trait.title && [
                      {
                        title: trait.title,
                      },
                    ]
                  }
                  closeIcon={<></>}
                  isExternalInputValue
                  inputValue={traitValue}
                  setValue={(val) => {
                    if (val && val.type !== "div") {
                      if (val) {
                        const traitId = Number(
                          val.title.substring(0, val.title.indexOf("-")).trim()
                        );
                        const option = [
                          ...traitOptions,
                          ...initialTraitOptions,
                        ].find((Option) => Option.traitId === traitId);
                        const newTraits = [...traitsSelected];
                        newTraits[traitIndex].title = val.title;
                        newTraits[traitIndex].isSelected =
                          !newTraits[traitIndex].isSelected;
                        newTraits[traitIndex].isNewTrait = false;
                        newTraits[traitIndex].traitName = option.traitName;
                        newTraits[traitIndex].traitId = option.traitId;
                        newTraits[traitIndex] = {
                          ...newTraits[traitIndex],
                          ...option,
                        };
                        setTraitsSelected(newTraits);
                      }
                    }
                  }}
                  onOpen={() => {
                    setCurrentTraitIndex(traitIndex);
                  }}
                  onInputChange={(val) => {
                    setTraitValue(val);
                    setTraitOptions([]);
                    setTraitsLoading(true);
                  }}
                  error={Boolean(errorText)}
                  errorText={errorText}
                />
                {traitIndex !== 0 && (
                  <div
                    className={classes.autoCompleteDeleteContainer}
                    onClick={() => {
                      const newTraits = [...traitsSelected];
                      newTraits.splice(traitIndex, 1);
                      if (newTraits.length > 0) {
                        setTraitsSelected(newTraits);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </div>
                )}
              </div>
            ) : (
              <div
                key={`${traitName}-${traitIndex + 1}`}
                className={classes.fullWidth}
              >
                <TextBox
                  isNew={traitsSelected[traitIndex].isNewTrait}
                  name={traitName}
                  onClick={() => {
                    // EDIT NEW TRAIT
                    if (traitsSelected[traitIndex].isNewTrait) {
                      setTraitsInfo({
                        ...traitsSelected[traitIndex],
                      });
                      setCurrentTraitIndex(traitIndex);
                      setOpenModal(true);
                    } else {
                      // DELETE TRAIT
                      setOpenDeleteModal(true);
                      setTraitSelectedIndex(traitIndex);
                    }
                  }}
                  onDelete={() => {
                    // DELETE TRAIT
                    setOpenDeleteModal(true);
                    setTraitSelectedIndex(traitIndex);
                  }}
                />
              </div>
            );
          })}
          {!isRequestRevisionFlow && (
            <div className={globalStyles.addTraitBtn}>
              <AddCircleOutlineIcon />
              <Button
                onClick={() => {
                  if (traitsSelected.length < 10) {
                    const newTraits = [...traitsSelected];
                    newTraits.push({
                      title: "",
                      isSelected: false,
                      isNewTrait: false,
                    });
                    setTraitsSelected(newTraits);
                    setTraitValue("");
                  } else {
                    addNotification(
                      createTraitConstants.traitsLimitErrorMessage
                    );
                  }
                }}
              >
                {t("common.new_trait")}
              </Button>
            </div>
          )}
        </div>
      );
    }
    if (step === 2) {
      if (storeDataLoading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <div className={classes.marketingProgramContainer}>
          <div
            className={clsx(classes.flexContainer, classes.justifyContent)}
            key="marketing-program"
          >
            <Typography className="label">
              {t("common.labels.marketing_program")}
            </Typography>
            <CustomAutoComplete
              options={filteredMarketingProgramOpts
                .slice(0, Math.min(3, filteredMarketingProgramOpts.length))
                .map((option) => ({
                  ...option,
                  title: `${option.marketingProgramNumber} - ${option.description}`,
                }))}
              id="marketing-programs"
              placeholder={
                loading
                  ? t("common.loading")
                  : t("autocomplete.marketing_program_placeholder")
              }
              loading={marketingProgramsLoading}
              value={marketingProgramsSelected}
              setValue={setMarketingProgramsSelected}
              error={Boolean(formErrors.marketingPrograms)}
              errorText={formErrors.marketingPrograms}
              isExternalInputValue
              onClose={() => setSearchMarketingProgramValue("")}
              inputValue={searchMarketingProgramValue}
              onInputChange={(val) => {
                if (val !== marketingProgramsResp?.searchText) {
                  setMarketingProgramOptions([]);
                  setMarketingProgramsResp(initialMarketingProgramRespState);
                  setMarketingProgramsLoading(true);
                  memoizedMarketingProgramsFromApi(
                    val,
                    marketingProgramsResp,
                    marketingProgramOptions,
                    filteredMarketingProgramOpts
                  );
                }
                setSearchMarketingProgramValue(val);
              }}
            />
          </div>
          <div
            className={clsx(classes.flexContainer, classes.justifyContent)}
            key="ecosystem-container"
          >
            <Typography className="label">
              {t("common.labels.ecosystems")}
            </Typography>
            <CustomAutoComplete
              options={ecosystemOptions.map((option) => {
                let personalDataAllowed = true;
                if (
                  isReuseTraitsFlow &&
                  location.state?.type === "single-reuse"
                ) {
                  if (
                    location.state.traits[0].personalData === true ||
                    location.state.traits[0].personalData === "Yes"
                  ) {
                    personalDataAllowed = option.personalDataAllowed;
                  }
                }
                return {
                  ...option,
                  title: option.ecosystemName,
                  personalDataAllowed,
                };
              })}
              id="ecosystems"
              data-testid="ecosystems"
              placeholder={
                loading
                  ? t("common.loading")
                  : t("autocomplete.ecosystem_placeholder")
              }
              loading={ecoSystemsLoading}
              value={ecoSystemsSelected}
              setValue={handleEcoSystemSelection}
              onInputChange={async (val) => {
                setEcosystemsLoading(true);
                setEcosystemOptions([]);
                memoizedEcosystemsFromApi(val);
                setFormErrors({
                  ...formErrors,
                  ecosystems: "",
                });
              }}
              selectedTagDisabled={(option) =>
                ["dataLake", "CPC", "bigQuery"].includes(option.title)
              }
              closeIcon={
                <CloseIcon
                  onClick={(e) => {
                    setEcosystemsSelected([
                      {
                        title: "dataLake",
                        ecosystemId: 3,
                        ecosystemName: "dataLake",
                      },
                      {
                        title: "bigQuery",
                        ecosystemId: 5,
                        ecosystemName: "bigQuery",
                      },
                      {
                        title: "CPC",
                        ecosystemId: 9,
                        ecosystemName: "CPC",
                      },
                    ]);
                    memoizedEcosystemsFromApi("");
                    e.stopPropagation();
                  }}
                />
              }
              optionDisabled={(option) => {
                return (
                  !option.personalDataAllowed ||
                  ["dataLake", "CPC", "bigQuery"].includes(option.ecoSystemName)
                );
              }}
              error={Boolean(formErrors.ecosystems)}
              errorText={formErrors.ecosystems}
            />
          </div>
          <div className={classes.btnSwitchWrapper}>
            <div className={classes.btnSwitchWrapper2}>
              <Typography className="label">
                Manage Trait Response Types
              </Typography>
              <div className={classes.mapBtns}>
                <Button
                  className={mapTraitResponseType ? "active" : ""}
                  onClick={() => {
                    setMapTraitResponseType(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={!mapTraitResponseType ? "active" : ""}
                  onClick={() => setMapTraitResponseType(false)}
                >
                  No
                </Button>
              </div>
              <Button
                style={{ marginLeft: "20px" }}
                variant={mapTraitResponseType ? "contained" : "outlined"}
                color="primary"
                onClick={() => {
                  const errors = validateForm(currentStep);
                  setFormErrors(errors);
                  if (Object.keys(errors).length === 0) {
                    setSelectedMappings("traitResponseTypes");
                    setViewMappings(true);
                  }
                }}
              >
                {mapTraitResponseType ? "Manage Mappings" : "View Mappings"}
              </Button>
            </div>
          </div>
          <div className={classes.btnSwitchWrapper}>
            <div className={classes.btnSwitchWrapper2}>
              <Typography className="label">
                Manage Survivorship Rules
              </Typography>
              <div className={classes.mapBtns}>
                <Button
                  className={mapSurvivorshipRules ? "active" : ""}
                  onClick={() => {
                    setMapSurvivorshipRules(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={!mapSurvivorshipRules ? "active" : ""}
                  onClick={() => setMapSurvivorshipRules(false)}
                >
                  No
                </Button>
              </div>
              <Button
                style={{ marginLeft: "20px" }}
                variant={mapSurvivorshipRules ? "contained" : "outlined"}
                color="primary"
                onClick={() => {
                  const errors = validateForm(currentStep);
                  setFormErrors(errors);
                  if (Object.keys(errors).length === 0) {
                    setSelectedMappings("survivorshipRules");
                    setViewMappings(true);
                  }
                }}
              >
                {mapSurvivorshipRules ? "Manage Mappings" : "View Mappings"}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (step === 3) {
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            style={{ alignItems: "flex-start" }}
            key={`${info.label}${info.index}`}
          >
            <Typography className="label" variant="h6">
              {info.label} :
            </Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });
      const isJanrainSelected = ecoSystemsSelected.find(
        (ecosystem) => ecosystem.title === "janrain"
      );
      return (
        <>
          {detailsDOM}
          {isJanrainSelected ? (
            <Alert severity="info">
              Please raise a request in{" "}
              <a
                href="https://pgglobalenterprise.service-now.com/dbce_homepage?id=pgcs_sc_cat_item_form&sys_id=8f15f092dbdeb19476edc04b139619a1&access=itemone"
                target="_blank"
                rel="noreferrer"
              >
                ServiceNow
              </a>{" "}
              for the Janrain team to complete the trait setup on their end
            </Alert>
          ) : (
            <></>
          )}
        </>
      );
    }
    return (
      <div
        style={{
          height: 70 * traitMappingResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table
          columns={createRequestTableColumns}
          rows={traitMappingResponse}
        />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/traits" goToText="GO TO TRAITS" />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        steps={[
          t("create_trait.headings.step_1"),
          t("create_trait.headings.step_2"),
          t("create_trait.headings.step_3"),
        ]}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        header={
          <>
            <Typography variant="h4" gutterBottom>
              {isRequestRevisionFlow
                ? createTraitConstants.requestRevisionHeadings[currentStep]
                : createTraitConstants.traitCreationHeadings[currentStep]}
            </Typography>
            {currentStep === 2 && (
              <Typography variant="h6" gutterBottom>
                {t("create_trait.mapping_traits")}:{" "}
                {traitsSelected.map((trait, index) => {
                  if (trait.isNewTrait) {
                    return (
                      <div
                        className={classes.newBtnContainer}
                        key={trait.title}
                      >
                        <span>{trait.title}</span>
                        <Button variant="contained">{t("common.new")}</Button>
                        {index !== traitsSelected.length - 1 && ","}
                      </div>
                    );
                  }

                  return index === traitsSelected.length - 1
                    ? trait.title
                    : `${trait.title}, `;
                })}
              </Typography>
            )}
          </>
        }
        headerText={t("traits_container.traits_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{
                        root: globalStyles.btn,
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                      root: globalStyles.btn,
                    }}
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                disabled={loading}
                className="nextStep"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const payload = createBackendPayload(
                        traitsSelected,
                        marketingProgramsSelected,
                        ecoSystemsSelected,
                        traitMpEcosystemApiCombinations.current
                      );
                      if (!isRequestRevisionFlow) {
                        const data = await createTraitRequest(payload);
                        const { items } = data;
                        setTraitMappingResponse(
                          items.map((item, index) => ({
                            id: index,
                            requestId: item.requestId,
                            traitName: item.trait.traitName,
                            ecosystems: item.ecosystems.map(
                              (system) => system.ecosystemName
                            ),
                            status:
                              item.status.charAt(0).toUpperCase() +
                              item.status.slice(1),
                            marketingPrograms: item.marketingPrograms.map(
                              (mp) =>
                                `${mp.marketingProgramNumber} - ${mp.description}`
                            ),
                            isNewTrait: !item.trait.traitId,
                          }))
                        );
                        const isJanrainSelected = ecoSystemsSelected.find(
                          (ecosystem) => ecosystem.title === "janrain"
                        );
                        if (isJanrainSelected) {
                          window.open(
                            "https://pgglobalenterprise.service-now.com/dbce_homepage?id=pgcs_sc_cat_item_form&sys_id=8f15f092dbdeb19476edc04b139619a1&access=itemone",
                            "_blank"
                          );
                        }
                        setCurrentStep(currentStep + 1);
                      } else {
                        const data = await updateRequest(
                          request.requestId || requestId,
                          payload,
                          applicationConfig.modules.traits
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    const errors = validateForm(currentStep);
                    setFormErrors(errors);
                    if (Object.keys(errors).length > 0) {
                      return null;
                    }
                    setCurrentStep(currentStep + 1);
                  }
                  return null;
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}

                {createTraitConstants.traitFooterText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/traits"
                >
                  {t("common.labels.back_to_traits")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${traitMappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.traitContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {openModal && (
        <NewTrait
          openModal={openModal}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          traitsInfo={traitsInfo}
          setTraitsInfo={setTraitsInfo}
          ecosystems={ecosystemOptions}
          isDisabled={isNewTraitDisabled}
          traitsSelected={traitsSelected}
          isRequestRevisionFlow={isRequestRevisionFlow}
          title={t("create_trait.new_trait")}
        />
      )}
      {viewMappings && (
        <TraitMpMappingModal
          isOpen
          onSubmit={(data) => {
            traitMpEcosystemApiCombinations.current = data;
            setViewMappings(false);
          }}
          onClose={() => setViewMappings(false)}
          traits={traitsSelected}
          marketingPrograms={marketingProgramsSelected}
          disabled={
            currentStep === 3 ||
            (!mapSurvivorshipRules && !mapTraitResponseType) ||
            (mapSurvivorshipRules &&
              !mapTraitResponseType &&
              selectedMappings === "traitResponseTypes") ||
            (mapTraitResponseType &&
              !mapSurvivorshipRules &&
              selectedMappings === "survivorshipRules")
          }
          ecosystems={ecoSystemsSelected}
          traitResponseTypeMapping={mapTraitResponseType}
          view={selectedMappings}
          mappingsStore={traitMpEcosystemApiCombinations.current}
          setMappingStore={(data) => {
            traitMpEcosystemApiCombinations.current = data;
          }}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("create_trait.trait_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          const newTraits = [...traitsSelected];
          newTraits.splice(traitSelectedIndex, 1);
          if (newTraits.length > 0) {
            setTraitsSelected(newTraits);
          } else {
            setTraitsSelected([
              {
                title: "",
                isSelected: false,
                isNewTrait: false,
              },
            ]);
          }
          onCloseDeleteModal();
        }}
        type="error"
      />
      <TraitsRequestOutputModal
        requestId={traitOutput ? traitOutput.requestId : ""}
        traitOutput={traitOutput}
        isLoading={traitOutputLoading}
        open={traitOutputModal}
        handleClose={() => {
          setTraitOutputModal(false);
        }}
      />
      <Prompt
        message={() => {
          if (isRequestRevisionFlow) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={
          !isInfoStep &&
          (traitsSelected.filter((trait) => trait.title).length > 0 ||
            marketingProgramsSelected.length > 0 ||
            ecoSystemsSelected.length > 0)
        }
      />
    </>
  );
};

export default CreateTraitsContainer;
