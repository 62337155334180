import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import useNotifier from "../../hooks/useNotifier";

const CustomSnackbar = () => {
  const { notification, removeNotification } = useNotifier();
  const open = Boolean(notification && notification.message);

  return open ? (
    <div data-testid="custom-snackbar">
      <Snackbar
        open={open}
        onClose={removeNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={removeNotification}
          severity={notification.type}
          variant="filled"
        >
          {typeof notification.message === "object"
            ? notification.message.map((msg) => <div key={msg}>{msg}</div>)
            : notification.message}
        </Alert>
      </Snackbar>
    </div>
  ) : (
    <></>
  );
};

export default CustomSnackbar;
