import { useState, useEffect, useCallback } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
  Typography,
  Tabs,
  Tab,
  TextField,
  Tooltip,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getEventProperties from "../../../../api/get-event-properties";

import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";
import eventsModuleConfig from "../../../../config/eventsModuleConfig";
import useEvents from "../../../../hooks/useEvents";
import useLoadingSpinner from "../../../../hooks/useLoadingSpinner";
import useNotifier from "../../../../hooks/useNotifier";
import {
  validatePropertyName,
  isEmptyString,
} from "../../../../utilities/formValidation";

import handleError from "../../../../utilities/handleError";
import useImmer from "../../../../utilities/useImmer";

import useStyles from "./styles";

const NewEventProperty = ({
  title,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  setPropertyInfo,
  propertyInfo,
  isEditFlow,
  existingEventProperties,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addNotification } = useNotifier();
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const { createEventConstants } = eventsModuleConfig;
  const [errors, setErrors] = useState({
    consentTemplateTextError: null,
  });
  const [tabValue, setTabValue] = useState(0);
  const [eventValue, setEventValue] = useState("");
  const [eventProperties, setEventProperties] = useState([]);
  const [isEventPropertiesLoading, setIsEventPropertiesLoading] =
    useState(false);
  const resetState = () => {
    setPropertyInfo((draft) => {
      draft.propertyName = "";
      draft.description = "";
      draft.dataType = "";
      draft.sampleValues = "";
      draft.required = false;
      draft.personalData = false;
      draft.personalDataNonPii = false;
      draft.healthData = false;
    });
  };

  const [importInfo, setImportInfo] = useImmer({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const initialEventNameOptionsFilter = useCallback(() => true, []);

  const {
    memoizedEventsFromApi,
    eventNameOptions,
    eventNamesLoading,
    initialEventNameOptions,
    getEventsFromApi,
    setEventNamesLoading,
  } = useEvents({ initialEventNameOptionsFilter });

  const newPropertyFields = [
    {
      label: t("create_event.create_event_property.property_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      required: true,
      props: {
        placeholder: t(
          "create_event.create_event_property.placeholders.enter_property_name"
        ),
        onChange: (event) => {
          setPropertyInfo((draft) => {
            draft.propertyName = event.target.value;
          });
          if (
            existingEventProperties.find(
              (property) =>
                property.propertyName === event.target.value &&
                property.id !== propertyInfo.id
            )
          ) {
            setErrors({
              ...errors,
              propertyNameError: "Property Name already exists",
            });
          } else if (event.target.value?.includes(".")) {
            const propertyArray = event.target.value.split(".");
            let hasError = true;
            for (let idx = 0; idx < propertyArray.length; idx += 1) {
              if (
                existingEventProperties.find((existingProperty) => {
                  if (
                    existingProperty.propertyName ===
                      propertyArray.filter((p, i) => i <= idx).join(".") &&
                    existingProperty.id !== propertyInfo.id
                  ) {
                    return true;
                  }
                  return false;
                })
              ) {
                hasError = false;
              }
            }
            if (hasError) {
              setErrors({
                ...errors,
                propertyNameError: "Parent property doesn't exist",
              });
            }
            if (!hasError && errors.propertyNameError) {
              setErrors({
                ...errors,
                propertyNameError: null,
              });
            }
          } else {
            setErrors({
              ...errors,
              propertyNameError: null,
            });
          }
        },
        value: propertyInfo.propertyName,
        error: !isEmptyString(errors.propertyNameError),
        helperText: (
          <InlineMessage message={errors.propertyNameError} state="error" />
        ),
        onBlur: () => {},
        onFocus: () => {},
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("create_event.create_event_property.property_description"),
      type: applicationConfig.inputType.textInput,
      required: true,
      props: {
        placeholder: t(
          "create_event.create_event_property.placeholders.property_description"
        ),
        onChange: (event) => {
          setPropertyInfo((draft) => {
            draft.description = event.target.value;
          });
          setErrors({
            ...errors,
            descriptionError: null,
          });
        },
        value: propertyInfo.description
          ? propertyInfo.description
          : propertyInfo.description,
        helperText: (
          <InlineMessage message={errors.descriptionError} state="error" />
        ),
        inputProps: {
          "data-testid": "proptery-description",
        },
        rows: 2,
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("create_event.create_event_property.data_type"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        label: isEmptyString(propertyInfo.dataType)
          ? t("create_event.create_event_property.placeholders.data_type")
          : "",
        onChange: (event) =>
          setPropertyInfo((draft) => {
            draft.dataType = event.target.value;
          }),
        inputProps: {
          "data-testid": "data-type",
        },
        values: createEventConstants.dataType.map((type) => ({
          label: type.label,
          value: type.option,
        })),
        value: propertyInfo.dataType,
        disabled: isDisabled,
      },
    },
    {
      label: t("create_event.create_event_property.sample_values"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t(
          "create_event.create_event_property.placeholders.enter_sample_values"
        ),
        onChange: (event) => {
          setPropertyInfo((draft) => {
            draft.sampleValues = event.target.value;
          });
        },

        value: propertyInfo.sampleValues,
        onBlur: () => {},
        onFocus: () => {},
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("create_event.create_event_property.required"),
      type: applicationConfig.inputType.dropdown,
      props: {
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setPropertyInfo((draft) => {
            draft.required = event.target.value;
          }),
        inputProps: {
          "data-testid": "is-required",
        },
        values: createEventConstants.isRequiredOptions.map((type) => ({
          label: type.label,
          value: type.option,
        })),
        value: propertyInfo.required,
        disabled: isDisabled,
      },
    },
  ];

  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();

  const eventDropdownValues =
    eventValue.length > 0 || initialEventNameOptions.length === 0
      ? eventNameOptions
      : initialEventNameOptions;

  useEffect(() => {
    (async () => {
      increaseRequestsCount(1);
      if (initialEventNameOptions.length === 0) {
        await getEventsFromApi("");
      }
      decreaseRequestsCount(1);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (importInfo.eventName) {
        setIsEventPropertiesLoading(true);
        try {
          const { properties } = await getEventProperties(importInfo.eventName);
          setEventProperties(
            properties.filter(
              (property) =>
                !existingEventProperties.find(
                  (existingProperty) =>
                    existingProperty.propertyName === property.propertyName
                )
            )
          );
        } catch (error) {
          handleError({
            error,
            handle404: true,
            addNotification,
          });
        } finally {
          setIsEventPropertiesLoading(false);
        }
      }
    })();
  }, [importInfo.eventName]);

  const importFields = [
    {
      type: "custom",
      label: "Event Name",
      element: Autocomplete,
      required: true,
      props: {
        id: "combo-box-demo",
        options: eventDropdownValues.map((value) => ({
          ...value,
          title: value.eventName,
        })),
        loading: eventNamesLoading,
        getOptionLabel: (option) => option.title || "",
        onChange: async (event, value) => {
          setImportInfo((draft) => {
            /* istanbul ignore next */
            draft.eventName = value?.eventName || "";
          });
        },
        renderInput: (params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Please select an event name"
            error={Boolean(errors.optIdError)}
            value={eventValue}
            onChange={(event) => {
              setEventNamesLoading(true);
              memoizedEventsFromApi(event.target.value);
              setEventValue(event.target.value);
            }}
          />
        ),
      },
    },
    {
      type: applicationConfig.inputType.multiSelectDropdown,
      label: "Event Properties",
      required: true,
      props: {
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
        placeholder: "Event Properties",
        variant: "outlined",
        multiple: true,
        onChange: (event) => {
          setImportInfo((draft) => {
            draft.eventPropertiesSelected = event.target.value;
          });
        },
        displayEmpty: true,
        endAdornment: isEventPropertiesLoading && (
          <InputAdornment position="end">
            <CircularProgress size={20} style={{ marginRight: "20px" }} />
          </InputAdornment>
        ),
        renderValue: (selected) => {
          if (selected.length === 0) {
            return (
              <Typography style={{ color: "#a2a1a2" }}>
                {!importInfo.eventName
                  ? "Please select an event"
                  : "Please select Event Properties to be imported"}
              </Typography>
            );
          }

          return selected.join(", ");
        },
        values: eventProperties.map(
          (eventProperty) => eventProperty.propertyName
        ),
        value: importInfo.eventPropertiesSelected || [],
        disabled: isDisabled,
      },
    },
  ];

  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          handleClose();
          resetState();
        }}
        disableEnforceFocus
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">
            {tabValue === 0 ? title : "Import Event Properties"}
          </Typography>
          <Box
            className={clsx(classes.marginLeftAuto, classes.closeBtn)}
            onClick={() => {
              if (isDisabled) {
                handleClose();
              } else {
                setIsCancelClicked(true);
              }
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </Box>
        </div>
        {!isEditFlow && (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            style={{ marginBottom: 10 }}
          >
            <Tab label="New Property" />
            <Tab label="Import Event Properties" />
          </Tabs>
        )}
        <DialogContent>
          {tabValue === 0 && (
            <>
              <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
                {t("create_event.dialogs.property_classification")}
              </Typography>
              <Box display="flex" alignItems="flexStart">
                <div className={classes.personalDataContainer}>
                  <div
                    className={clsx(classes.flexContainer, classes.fullWidth)}
                  >
                    <Switch
                      checked={propertyInfo.personalData}
                      data-testid="personal-data"
                      onChange={(event) => {
                        if (!event.target.checked) {
                          setPropertyInfo((draft) => {
                            draft.personalData = event.target.checked;
                          });
                        }
                        setPropertyInfo((draft) => {
                          draft.personalData = event.target.checked;
                        });
                      }}
                      disabled={isDisabled}
                      color="primary"
                    />
                    <Typography variant="h6">
                      {t("new_trait.personal_data")}{" "}
                    </Typography>
                    <Tooltip title={createEventConstants.personalDataTooltip}>
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </div>
                  <div
                    className={clsx(classes.flexContainer, classes.fullWidth)}
                  >
                    <Switch
                      checked={propertyInfo.personalDataNonPii}
                      data-testid="sensitive-data"
                      onChange={(event) => {
                        if (event.target.checked) {
                          setPropertyInfo((draft) => {
                            draft.personalDataNonPii = event.target.checked;
                          });
                        } else {
                          setPropertyInfo((draft) => {
                            if (!draft.healthData) {
                              draft.personalDataNonPii = event.target.checked;
                            }
                          });
                        }
                      }}
                      disabled={isDisabled}
                      color="primary"
                    />
                    <Typography variant="h6">
                      {t("new_trait.sensitive_data")}
                    </Typography>
                    <Tooltip title={createEventConstants.sensitiveDataTooltip}>
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </div>
                </div>
                <div className={classes.personalDataContainer}>
                  <div
                    className={clsx(classes.flexContainer, classes.fullWidth)}
                  >
                    <Switch
                      checked={propertyInfo.healthData}
                      data-testid="health-data"
                      onChange={(event) => {
                        if (event.target.checked) {
                          setPropertyInfo((draft) => {
                            draft.healthData = event.target.checked;
                            draft.personalDataNonPii = event.target.checked;
                          });
                        } else {
                          setPropertyInfo((draft) => {
                            draft.healthData = event.target.checked;
                          });
                        }
                      }}
                      disabled={isDisabled}
                      color="primary"
                    />
                    <Typography variant="h6">
                      {t("new_trait.health_data")}{" "}
                    </Typography>
                    <Tooltip title={createEventConstants.healthDataTooltip}>
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </div>
                </div>
              </Box>
              <Form
                fields={newPropertyFields}
                fieldClassName={clsx(
                  classes.fieldContainer,
                  classes.flexContainer
                )}
              />
            </>
          )}
          {tabValue === 1 && (
            <Form
              fields={importFields}
              fieldClassName={clsx(
                classes.fieldContainer,
                classes.flexContainer
              )}
            />
          )}
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          <div className={classes.btnContainer}>
            {!isDisabled && tabValue === 0 && (
              <Button
                onClick={() => {
                  if (isEmptyString(propertyInfo.propertyName)) {
                    setErrors({
                      ...errors,
                      propertyNameError: t("errors.field_required"),
                    });
                    return;
                  }
                  if (!validatePropertyName(propertyInfo.propertyName)) {
                    setErrors({
                      ...errors,
                      propertyNameError:
                        "Property Name should be single word or two word combined with .(dot).",
                    });
                    return;
                  }
                  if (isEmptyString(propertyInfo.description)) {
                    setErrors({
                      ...errors,
                      descriptionError: t("errors.field_required"),
                    });
                    return;
                  }
                  handleSubmit(
                    {
                      propertyName: propertyInfo.propertyName,
                      description: propertyInfo.description,
                      dataType: propertyInfo.dataType,
                      sampleValues: propertyInfo.sampleValues,
                      required: propertyInfo.required,
                      personalData: propertyInfo.personalData,
                      personalDataNonPii: propertyInfo.personalDataNonPii,
                      healthData: propertyInfo.healthData,
                    },
                    "single"
                  );
                  handleClose();
                  resetState();
                }}
                color="primary"
                variant="contained"
              >
                {t("common.submit")}
              </Button>
            )}
            {tabValue === 1 && (
              <Button
                onClick={() => {
                  const { eventPropertiesSelected } = importInfo;
                  handleSubmit(
                    eventProperties.filter((property) =>
                      eventPropertiesSelected.includes(property.propertyName)
                    ),
                    "multiple"
                  );
                }}
                color="primary"
                variant="contained"
              >
                Import
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => {
          setIsCancelClicked(false);
          handleClose();
          resetState();
        }}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewEventProperty.defaultProps = {
  propertyInfo: {},
  isDisabled: false,
  isEditFlow: false,
  existingEventProperties: [],
};

NewEventProperty.propTypes = {
  openModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  propertyInfo: PropTypes.shape({
    id: PropTypes.string,
    propertyName: PropTypes.string,
    description: PropTypes.string,
    dataType: PropTypes.string,
    sampleValues: PropTypes.string,
    required: PropTypes.bool,
    personalData: PropTypes.bool,
    personalDataNonPii: PropTypes.bool,
    healthData: PropTypes.bool,
  }),
  setPropertyInfo: PropTypes.func.isRequired,
  isEditFlow: PropTypes.bool,
  existingEventProperties: PropTypes.arrayOf({
    id: PropTypes.string,
    propertyName: PropTypes.string,
    description: PropTypes.string,
    dataType: PropTypes.string,
    sampleValues: PropTypes.string,
    required: PropTypes.bool,
    personalData: PropTypes.bool,
    personalDataNonPii: PropTypes.bool,
    healthData: PropTypes.bool,
  }),
};

export default NewEventProperty;
