import i18n from "../../../../i18n/init";

const validateSurvivorshipRule = (traitsInfo, option, newTrait = false) => {
  if (traitsInfo.traitType === "Computed Trait") {
    if (option !== i18n.t("constants.survivorship_rules.most_recent")) {
      return {
        label: option,
        value: option,
        error: "Survivorship Rule cannot be updated for Computed Traits",
        disabled: true,
      };
    }
  }
  const { allowSurvivorshipUpdate = true } = traitsInfo;
  if (!allowSurvivorshipUpdate) {
    if (option !== i18n.t("constants.survivorship_rules.most_recent")) {
      return {
        label: option,
        value: option,
        error: "Survivorship Rule cannot be updated for this trait",
        disabled: true,
      };
    }
  }
  if (
    option === i18n.t("constants.survivorship_rules.source_priority") &&
    newTrait
  ) {
    return {
      label: option,
      value: option,
      error: "Survivorship Rule not applicable at trait level",
      disabled: true,
    };
  }
  if (option === i18n.t("constants.survivorship_rules.aggregate")) {
    if (traitsInfo.dataType && traitsInfo.dataType.toLowerCase() !== "string") {
      return {
        label: option,
        value: option,
        error:
          "Aggregated Array can be selected only when the trait data type is string",
        disabled: true,
      };
    }
  }
  return {
    label: option,
    value: option,
  };
};

export default validateSurvivorshipRule;
