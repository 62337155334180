import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    viewDetailsContainer: {
      "& .MuiTypography-root.MuiTypography-h6": {
        color: `${theme.palette.blue.light2} !important`,
        cursor: "pointer",
      },
    },
    rootContainer: {
      width: "100%",
      "& .MuiTypography-h4": {
        color: `${theme.colorTheme.modalTitle}`,
        fontSize: "14px",
        fontWeight: 400,
        marginBottom: "6px",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.colorTheme.greyishWhite3}`,
      borderRadius: "4px",
      marginRight: "12px",
      padding: "10px",
      minHeight: "34px",
      "& .MuiTypography-h6": {
        fontSize: "14px",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
        marginRight: "20px",
      },
    },
    btnContainer: {
      "&:focus": {
        outline: "none",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
