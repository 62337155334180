import { useState, useRef } from "react";

import { Paper, Typography, Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getOpts from "../../../api/get-opts";

import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import OptsDetailsModal from "./components/OptsDetailsModal";
import useStyles from "./styles";

const OptsContainer = () => {
  const classes = useStyles();
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const childRef = useRef();
  const history = useHistory();

  const { optsTableColumns, optSearchFilters, optsSearchDefaultFilterState } =
    optsModuleConfig;
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [optSelected, setOptSelected] = useState([]);
  const [manageOpt, setManageOpt] = useState({});

  const [cellData, setCellData] = useState({
    data: [],
    field: "ciamOptId",
    row: {},
  });

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "ciamOptId",
      row: {},
    });
  };

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchOpts
  );

  const filterFieldMap = {
    optId: "ciamOptId",
    marketingProgram: "marketingProgram",
    serviceName: "serviceName",
    optTraitName: "traitName",
    "": "searchText",
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  return !isUserAuthorized && !user.loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4">
            {t("opts_container.opt_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Opts",
              onClick: () => history.push("/opts"),
            },
            {
              label: "Opt Traits",
              onClick: () => history.push("/opts?displayTab=opt-traits"),
            },
          ]}
        />
        <div className={clsx(classes.marginLeftAuto, classes.flexContainer)}>
          {optSelected.length > 0
            ? checkUserAuthorization(
                user.access,
                pageAccessConfig.createOpts
              ) && (
                <Button
                  onClick={() => {
                    history.push(applicationConfig.pathnames.opts_revision, {
                      ciamOptId: manageOpt.ciamOptId,
                      optTextEnglish: manageOpt.optTextEnglish,
                      optTextLocal: manageOpt.optTextLocalLanguage,
                      ciamLegalEntityId: manageOpt.ciamLegalEntityId,
                      marketingProgramNumber: parseInt(
                        manageOpt.ciamMarketingProgram.split(" - ")[0],
                        10
                      ),
                      marketingProgramDescription:
                        manageOpt.ciamMarketingProgram.split(" - ")[1],
                    });
                  }}
                  variant="outlined"
                  className={classes.newOptIdBtn}
                >
                  MANAGE OPT ID
                </Button>
              )
            : checkUserAuthorization(
                user.access,
                pageAccessConfig.createOpts
              ) && (
                <Button
                  onClick={() => {
                    history.push(applicationConfig.pathnames.opts_new, {});
                  }}
                  className={classes.newOptIdBtn}
                >
                  CREATE OPT ID
                </Button>
              )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={optSearchFilters}
            defaultFilterState={optsSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="opts-container" elevation={0}>
        <div className={classes.optsTableContainer}>
          <Table
            columns={optsTableColumns}
            filters={filters}
            openModal={() => setDrawerOpen(true)}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            disableSelectAllCheckbox
            maxSelected={1}
            moduleName="opts"
            selectionModel={optSelected}
            onSelectionModelChange={(e, tableData, originalData) => {
              const rows = e;
              if (rows.length > 2) {
                return "";
              }
              const row = rows.filter((newId) => !optSelected.includes(newId));
              setOptSelected(row);
              setManageOpt({
                ...tableData[row[0]],
                ciamLegalEntityId: originalData[row[0]]?.ciamLegalEntityId,
              });
              return null;
            }}
            checkboxSelection
            initApiCall={(_page, perPage) =>
              getOpts(
                parseSearchFilter([filters], filterFieldMap, _page, perPage)
              )
            }
            setLoadingCellData={setLoadingCellData}
          />
        </div>
      </Paper>
      {cellData.field === "ciamOptId" && drawerOpen && (
        <OptsDetailsModal
          title={`Opt ID - ${cellData.row.ciamOptId}`}
          subtitle="This Opt has the following details and mappings"
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

export default OptsContainer;
