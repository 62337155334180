import { useState, useRef } from "react";

import {
  Paper,
  // Switch,
  // FormGroup,
  // FormControlLabel,
  Button,
  ButtonGroup,
  MenuList,
  MenuItem,
  Grow,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import useStyles from "./styles";

const ButtonComponent = ({
  onClick,
  goToLink,
  options,
  btnText,
  variant,
  style,
  classes,
}) => {
  const styles = useStyles();
  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  let linkProps = {};

  if (goToLink) {
    linkProps = {
      to: {
        pathname: goToLink,
      },
      component: Link,
    };
  }

  return (
    <div className={styles.btnContainer} style={style}>
      <ButtonGroup variant={variant} color="primary" ref={anchorRef}>
        <Button
          variant="contained"
          color="primary"
          {...linkProps}
          onClick={onClick}
          classes={classes}
        >
          {btnText}
        </Button>
        {options.length > 0 && (
          <Button color="primary" variant={variant} onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      {options.length > 0 && (
        <Popper
          open={openDropdown}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <MenuList id="split-button-menu">
                    {options.map((option) => {
                      //   if (option.accessorFn) {
                      //     if (!option.accessorFn()) {
                      //       return null;
                      //     }
                      //   }
                      return (
                        <MenuItem
                          onClick={() => {
                            option.onClick();
                          }}
                        >
                          {option.text}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
};

export default ButtonComponent;

ButtonComponent.defaultProps = {
  onClick: () => {},
  goToLink: "",
  variant: "contained",
  options: [],
  style: {},
  classes: {},
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  goToLink: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      accessorFn: PropTypes.func,
    })
  ),
  style: PropTypes.object,
  classes: PropTypes.object,
};
