import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    traitsTableContainer: {
      "& .MuiDataGrid-colCellWrapper": {
        backgroundColor: theme.colorTheme.bluishWhite,
      },
    },
    accordionFooter: {
      "& > a:first-child": {
        marginRight: "8px",
      },
    },
    content: {
      width: 700,
    },
    accordionChips: {
      "& .MuiAvatar-root": {
        fontSize: "8px",
      },
    },
    inputContainer: {
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: `${theme.colorTheme.black}`,
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    justifyContent: {
      justifyContent: "space-between",
    },
  };
});

export default useStyles;
