import { useState } from "react";

import { Typography, Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import { KeyValueSeparator } from "../../../../../components/AccordionRowDetails";
import CustomModal from "../../../../../components/CustomModal";
import traitsModuleConfig from "../../../../../config/traitsModuleConfig";

import { splitCamelCaseString } from "../../../../../utilities/formValidation";

import useStyles from "./styles";

const TraitDetailsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const { opts } = data;
  const { optsFeatureKeys } = traitsModuleConfig;

  const rows = Object.keys(data)
    .filter((item) => item !== "opts")
    .map((key) => {
      const camelCase = splitCamelCaseString(key);
      return {
        label: camelCase[0].toUpperCase() + camelCase.slice(1),
        value: data[key]?.toString(),
      };
    })
    .filter(({ value }) => !!value);

  const optMappings = opts.map((opt) => ({
    ...opt,
    contactPointType: `${opt.contactPointTypeCode} - ${opt.contactPointTypeName}`,
  }));

  const renderUi = () => {
    if (tab === 0) {
      return (
        <table className={globalClasses.table}>
          <tbody>
            {rows.map((row, ind) => (
              <KeyValueSeparator
                keyVal={row.label}
                value={row.value}
                ind={ind.toString()}
                separator={null}
              />
            ))}
          </tbody>
        </table>
      );
    }

    return (
      <AccordionModalDetails
        keys={optsFeatureKeys}
        rows={optMappings}
        rowKeys={{
          title: "ciamOptId",
          subTitle: "optTextEnglish",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      data={{
        traitDetails: rows,
        mappings: optMappings,
      }}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
            }}
          >
            <Tab label="Trait Details" />
            <Tab label="Mappings" />
          </Tabs>
          {renderUi()}
        </div>
      </div>
    </CustomModal>
  );
};
TraitDetailsModal.defaultProps = {
  title: null,
};

TraitDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    opts: PropTypes.shape([]).isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TraitDetailsModal;
