import api from "../utilities/api";

import { getValue } from "../utilities/cache";

const getOptMappings = async (params) => {
  const apiUrl = `/opt-mappings`;
  const refreshCache = getValue("opts");
  const {
    data: { data },
  } = await api.get(apiUrl, {
    params: {
      ...params,
      refreshCache,
      page: 1,
      itemsPerPage: 100,
    },
  });

  return data;
};

export default getOptMappings;
