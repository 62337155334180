import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100%",
    },
    disablePointerEvents: {
      pointerEvents: "none",
    },
    marginTop20: {
      marginTop: "10px",
    },
    step2Wrapper: {
      "& >div:nth-child(2) h5 + div:nth-child(2)": {
        width: "95%",
      },
      "& .MuiTypography-root": {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    newTextField: {
      "& input": {
        textIndent: "70px",
      },
    },
    addTraitBtn: {
      "& button": {
        color: theme.palette.blue.main,
      },
    },
    mapToSegmentSourceWrapper: {
      margin: "20px 0",
      display: "flex",
      alignItems: "center",
    },
    horizontalBar: {
      height: "1px",
      backgroundColor: theme.colorTheme.greyishWhite,
      marginLeft: "-25px",
      width: "calc(100% + 50px)",
      marginBottom: "14px",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
        marginBottom: "24px",
      },
    },
    newBtnContainer: {
      display: "inline-block",
      cursor: "pointer",
      "& span.traitName": {
        color: theme.palette.blue.main,
      },
      "& .MuiButton-root": {
        width: "37px",
        height: "20px",
        backgroundColor: theme.palette.blue.main,
        color: theme.colorTheme.white,
        borderRadius: "10px",
        margin: "0px 10px",
      },
    },
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 24px",
      alignItems: "center",
    },
    newDataSourceModal: {
      width: "1000px",
      maxWidth: "unset",
      padding: "8px",
      overflowX: "hidden",
      "& .MuiDialogActions-root": {
        padding: "8px 24px",
      },
    },
    addDataSourcesBtn: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "4px",
        "& path": {
          fill: theme.palette.blue.main,
        },
      },
      "& .MuiButton-label": {
        color: theme.palette.blue.main,
        textTransform: "upperCase",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
    },
    radioGroup: {
      margin: "10px 0",
      "& span.MuiIconButton-label": {
        color: theme.palette.blue.darker,
      },
    },
    listbox: {
      "& .newDataSource": {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-label": {
          color: theme.palette.blue.main,
          textTransform: "upperCase",
          fontWeight: "normal",
        },
        "& .MuiButton-root": {
          padding: "0px",
        },
        "& .MuiTypography-h6": {
          fontSize: "14px",
          color: theme.colorTheme.grey,
          fontWeight: "normal",
          marginRight: "8px",
        },
      },
    },
    autoCompleteEditContainer: {
      marginLeft: "8px",
      "& svg": {
        fill: theme.palette.blue.light,
      },
    },
    autoCompleteDeleteContainer: {
      marginLeft: "8px",
      "& svg": {
        fill: theme.palette.error.light,
      },
    },
    dataSourceInput: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
      "& .MuiAutocomplete-root": {
        flex: 1.1,
      },
      "& >:not(:first-child)": {
        flex: 0.02,
        cursor: "pointer",
      },
    },
    grid: {
      display: "grid",
      gridGap: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
      "& .MuiAutocomplete-root": {
        width: "95% !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    columnGrid: {
      gridTemplateColumns: "repeat(auto-fill, minmax(400px, 2fr)) !important",
    },
    labelContainer: {
      display: "flex",
    },
    segmentSourceAutoComplete: {
      "& .MuiAutocomplete-root": {
        width: "95% !important",
      },
    },
    inputContainer: {
      "& .MuiTypography-root": {
        marginBottom: "6px",
        fontSize: "14px",
        color: `${theme.palette.text.primary} !important`,
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiFormHelperText-root": {
        marginLeft: "unset",
      },
    },
    sourceNameContainer: {
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
      },
    },
    marketingProgramSourceContainer: {
      marginBottom: "20px",
    },
    popper: {
      "& .MuiCheckbox-root": {
        color: theme.palette.primary.light,
      },
    },
    badge: {
      backgroundColor: theme.palette.blue.darker,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "20px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.static.white,
    },
    sourceContainer: {
      padding: "40px",
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    paddingZero: {
      padding: "0px",
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    noOptionsContainer: {
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
      },
    },
    backBtn: {
      "& a": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: "transparent !important",
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
      "& button": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: "transparent !important",
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
    },
    marketingProgramContainer: {
      marginLeft: "30px",
      "& > div:first-child": {
        marginBottom: "40px",
      },
    },
    btnContainer: {
      "& button": {
        minWidth: "200px !important",
      },
      "& >:nth-child(1)": {
        marginRight: "8px",
      },
      "& >:nth-child(2)": {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.static.white,
      },
      "& span > span": {
        marginLeft: "auto",
      },
      "& svg": {
        marginLeft: "auto",
      },
    },
    lastStepFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "& .MuiButtonBase-root.MuiButton-root": {
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
        marginLeft: "unset",
      },
    },
    footerRightContainer: {
      flex: "0 0 50%",
      display: "flex",
      alignItems: "start",
      "& a": {
        textAlign: "center",
        marginRight: "20px !important",
      },
    },
    step2Footer: {
      "& button, & a": {
        marginRight: "10px",
        whiteSpace: "no-wrap",
      },
      "& .MuiButton-label": {
        whiteSpace: "no-wrap",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        fontWeight: "bold",
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    mapToSegmentSourceBtns: {
      marginLeft: "15px",
      "& .MuiButtonBase-root": {
        border: `2px solid ${theme.palette.blue.darker}`,
        fontSize: "18px",
        padding: "unset !important",
        color: theme.palette.blue.darker,
      },
      "& .MuiButtonBase-root:first-child": {
        borderRadius: "10px",
        borderBottomRightRadius: "unset",
        borderTopRightRadius: "unset",
      },
      "& .MuiButtonBase-root:not(:first-child)": {
        borderRadius: "10px",
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
      },
      "& .MuiButtonBase-root.active": {
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& a,& a:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        border: "none",
        marginLeft: "auto",
      },
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
    },
    infoIcon: {
      width: 18,
      height: 18,
      marginLeft: "6px",
      position: "relative",
      top: "2px",
      cursor: "pointer",
    },
    outlinedIcon: {
      position: "relative",
      top: "-2px",
      cursor: "pointer",
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    statusPending: {
      backgroundColor: theme.palette.warning.light,
    },
    statusApproved: {
      backgroundColor: theme.palette.green.main,
    },
    statusRejected: {
      backgroundColor: theme.palette.error.main,
    },
    fileIconWrapper: {
      marginLeft: "10px",
    },
    loadingSkeleton: {
      width: "807px !important",
      padding: "30px",
    },
  };
});

export default useStyles;
