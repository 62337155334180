import { useState, useRef } from "react";

import { Paper, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getTraits from "../../../api/get-traits";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import Tabs from "../../../components/Tabs";

import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import TraitDetailsModal from "./components/TraitDetailsModal";
import useStyles from "./styles";

const TraitsContainer = () => {
  const {
    traitsTableColumns,
    traitsSearchDefaultFilterState,
    traitSearchFilters,
  } = traitsModuleConfig;
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const [reuseMultiple] = useState(false);
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const childRef = useRef();

  const { setRequest } = useRequest();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "traitId",
    row: {},
  });
  const [reusedTraits, setReusedTraits] = useState([]);

  const { user } = useUserProfile();
  const { t } = useTranslation();
  const { loading } = useLoadingSpinner();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchTraits
  );

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const [multipleTraits, setMultipleTraits] = useState([]);
  const [traitsSelected, setTraitsSelected] = useState([]);

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "traitId",
      row: {},
    });
  };

  const filterFieldMap = {
    traitId: "traitId",
    traitName: "globalTraitName",
    traitDescription: "description",
    "": "searchText",
  };

  return !loading && !isUserAuthorized ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("traits_container.traits_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Traits",
              onClick: () => history.push("/traits?displayTab=traits"),
            },
            {
              label: "Trait Responses",
              onClick: () => history.push("/traits?displayTab=trait-responses"),
            },
            {
              label: "Insights",
              onClick: () => history.push("/traits?displayTab=insights"),
            },
          ]}
          classes={{
            indicator: globalStyles.indicator,
          }}
        />
        <div className={classes.flexContainer}>
          {traitsSelected?.length === 0
            ? checkUserAuthorization(
                user.access,
                pageAccessConfig.createTraits
              ) && (
                <Button
                  btnText={t("traits_container.create_trait")}
                  onClick={() => setRequest({})}
                  variant="contained"
                  classes={{
                    root: globalStyles.btn,
                  }}
                  goToLink="/traits/map"
                  style={{
                    marginRight: "34px",
                  }}
                />
              )
            : checkUserAuthorization(
                user.access,
                pageAccessConfig.createTraits
              ) && (
                <Button
                  btnText={t("traits_container.reuse_trait")}
                  onClick={() => {
                    let state;
                    if (reusedTraits.length === 1) {
                      state = {
                        traits: reusedTraits,
                        type: "single-reuse",
                        flow: "reuse",
                      };
                    } else {
                      state = {
                        traits: reusedTraits,
                        flow: "reuse",
                      };
                    }
                    history.push({
                      pathname: "/traits/map",
                      state,
                    });
                  }}
                  classes={{
                    root: globalStyles.btn,
                  }}
                  variant="outlined"
                  style={{
                    marginRight: "34px",
                    justifySelf: "flex-end",
                  }}
                />
              )}
          <div className={classes.flexContainer}>
            <SearchFilter
              ref={childRef}
              onSearch={(data) => {
                setFilters(data);
              }}
              searchFilters={traitSearchFilters}
              defaultFilterState={traitsSearchDefaultFilterState}
            />
            <StyledTooltip
              placement="top"
              title={t("common.labels.clear_search")}
            >
              <div
                role="button"
                aria-hidden="true"
                data-testid="reset-btn"
                onClick={resetFilter}
              >
                <ClearIcon data-testid="filter-icon" />
              </div>
            </StyledTooltip>
          </div>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getTraits(
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="traits"
            filters={filters}
            hidePagination={!!(reuseMultiple && multipleTraits.length > 0)}
            columns={traitsTableColumns}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            exportMainSheetName="Traits"
            showArrayValueInNewSheet={(key) => key === "ecosystems"}
            customizeExportData={(sheetObj, key, obj) => {
              const newSheetObj = {};
              Object.keys(sheetObj).forEach((sheetKey) => {
                if (sheetKey === "ecosystems") {
                  newSheetObj["Ecosystem Traits"] = sheetObj[sheetKey].map(
                    (item) => ({
                      traitId: obj.traitId,
                      traitName: obj.traitName,
                      ...item,
                    })
                  );
                }
              });
              return newSheetObj;
            }}
            openModal={() => setDrawerOpen(true)}
            checkboxSelection
            selectionModel={traitsSelected}
            onSelectionModelChange={(e, tableData) => {
              const rows = e;
              if (JSON.stringify(traitsSelected) !== JSON.stringify(rows)) {
                setTraitsSelected(rows);
                setReusedTraits(rows.map((row) => tableData[row]));
              }
            }}
          />
        </div>
        {multipleTraits.length > 0 && reuseMultiple && (
          <TableFooter
            items={multipleTraits.map((trait) => trait.traitName)}
            itemsLabel={t("common.labels.trait")}
            onCancelClick={() => {
              setMultipleTraits(() => {
                return [];
              });
            }}
            proceedBtnText={t("common.labels.proceed_to_mapping")}
            onProceedClick={() => {
              history.push({
                pathname: "/traits/map",
                state: {
                  type: "batch-traits",
                  traits: multipleTraits,
                },
              });
            }}
          />
        )}
      </Paper>
      {cellData.field === "traitId" && drawerOpen && (
        <TraitDetailsModal
          title={`${t("common.labels.trait_id")} - ${cellData.row.traitId}`}
          subtitle={t("traits_container.trait_id_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

export default TraitsContainer;
