import { createContext } from "react";

import PropTypes from "prop-types";

export const ColorModeContext = createContext({});

export default function ColorModeProvider({ children, value }) {
  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
}

ColorModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object.isRequired,
};
