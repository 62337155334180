import { createContext, useState, useCallback } from "react";

import PropTypes from "prop-types";

export const RequestContext = createContext({
  request: {},
  removeRequest: () => {},
  setRequest: () => {},
});

export default function RequestProvider({ children }) {
  const [request, setRequest] = useState({});

  const removeRequest = () => setRequest({});

  const contextValue = {
    request,
    removeRequest: useCallback(() => removeRequest(), []),
    setRequest: useCallback((requestData) => setRequest(requestData), []),
  };

  return (
    <RequestContext.Provider value={contextValue}>
      {children}
    </RequestContext.Provider>
  );
}

RequestProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
