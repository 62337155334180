import i18n from "../i18n/init";

const jobsModuleConfig = {
  jobSearchFilters: [
    {
      name: "jobName",
      label: i18n.t("common.labels.job_name"),
    },
  ],
  jobFilterItemsPerPage: {
    max: 1000,
    min: 50,
  },
  searchDefaultFilterState: {
    jobName: false,
  },
  jobsList: {
    lyticsQueries: i18n.t("jobs_monitoring_container.services.lyticsQueries"),
    lyticsEvents: i18n.t("jobs_monitoring_container.services.lyticsEvents"),
    pubsubTraits: i18n.t("jobs_monitoring_container.services.pubsubTraits"),
    pubsubOpts: i18n.t("jobs_monitoring_container.services.pubsubOpts"),
    pubsubMarketingPrograms: i18n.t(
      "jobs_monitoring_container.services.pubsubMarketingPrograms"
    ),
    mappEmails: i18n.t("jobs_monitoring_container.services.mapp_email"),
    segmentTrackingPlans: i18n.t(
      "jobs_monitoring_container.services.segmentTrackingPlans"
    ),
    segmentSources: i18n.t("jobs_monitoring_container.services.segmentSources"),
    segmentDestinations: i18n.t(
      "jobs_monitoring_container.services.segmentDestinations"
    ),
    resciEmails: i18n.t("jobs_monitoring_container.services.resciEmails"),
  },
  jobsDefaultFilterState: {
    jobName: false,
  },
  tableColumns: [
    {
      field: "jobName",
      sortable: false,
      headerName: i18n.t("common.labels.job_name"),
      width: "20%",
    },
    {
      field: "averageDuration",
      sortable: false,
      headerName: i18n.t(
        "jobs_monitoring_container.table_columns.average_job_running_time"
      ),
      width: "20%",
    },
    {
      field: "averageFailures",
      sortable: false,
      headerName: i18n.t(
        "jobs_monitoring_container.table_columns.average_number_of_failures"
      ),
      width: "20%",
    },
    {
      field: "longJobs",
      sortable: false,
      headerName: i18n.t(
        "jobs_monitoring_container.table_columns.average_number_of_jobs_more_than_five_minutes"
      ),
      width: "20%",
    },
  ],
  jobServiceTableColumn: [
    {
      field: "jobId",
      sortable: false,
      headerName: i18n.t("common.labels.job_id"),
      width: "20%",
    },
    {
      field: "jobName",
      sortable: false,
      headerName: i18n.t("common.labels.job_name"),
      width: "20%",
      renderCell: (param) => {
        return param.value
          ?.split("_")
          .map((x) => x[0].toUpperCase() + x.slice(1))
          .join(" ");
      },
    },
    {
      field: "jobStartTime",
      sortable: false,
      headerName: i18n.t(
        "jobs_monitoring_container.table_columns.job_start_time"
      ),
      width: "20%",
      renderCell: (param) => {
        return param.value?.substr(0, param.value.lastIndexOf("."));
      },
    },
    {
      field: "jobEndTime",
      sortable: false,
      headerName: i18n.t(
        "jobs_monitoring_container.table_columns.job_end_time"
      ),
      width: "20%",
      renderCell: (param) => {
        return param.value?.substr(0, param.value.lastIndexOf("."));
      },
    },
    {
      field: "status",
      sortable: false,
      headerName: i18n.t("common.labels.status"),
      width: "20%",
    },
  ],
  jobStatus: {
    PENDING: i18n.t("status.pending"),
    FAILED: i18n.t("status.failed"),
    SUCCESS: i18n.t("status.success"),
    SUCCESS_PARTIAL: i18n.t("status.success_partial"),
    RUNNING: i18n.t("status.running"),
  },
};

export default jobsModuleConfig;
