import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    MuiPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minWidth: "807px !important",
      maxWidth: "807px !important",
      padding: "20px 25px",
      overflowY: "unset !important",
      "& .MuiTypography-h5": {
        marginBottom: "15px",
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        color: `${theme.palette.text.primary} !important`,
      },
      "& .MuiDialogContent-root": {
        maxHeight: "810px",
        padding: "0px !important",
        overflowY: "auto !important",
        overflowX: "hidden !important",
      },
      "& .MuiDialogActions-root": {
        padding: "0px !important",
      },
      "& .MuiFormHelperText-contained": {
        margin: "0px !important",
        marginTop: "5px !important",
      },
      "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined":
        {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
    },
    accordionWrapper: {
      "& .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded": {
        backgroundColor: theme.palette.background.default1,
        color: theme.colorTheme.black,
        "& .MuiButtonBase-root.MuiAccordionSummary-root": {
          borderBottom: "unset !important",
          "& button.MuiButtonBase-root.MuiButton-root.MuiButton-text": {
            backgroundColor: theme.palette.blue.main,
            borderRadius: "20px",
          },
        },
      },
    },
    errorContainer: {
      "& .MuiAccordionSummary-root": {
        border: `1px solid ${theme.palette.error.main} !important`,
      },
      "& .MuiCollapse-container": {
        border: `1px solid ${theme.palette.error.main} !important`,
        borderTop: "none !important",
      },
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.static.white,
      },
    },
    horizontalBar: {
      height: "1px",
      backgroundColor: theme.colorTheme.greyishBlack3,
      marginLeft: "-25px",
      width: "calc(100% + 50px)",
      marginBottom: "14px",
    },
    editIcon: {
      "& path": {
        fill: `${theme.palette.blue.light} !important`,
      },
    },
    personalDataContainer: {
      width: "100%",
      padding: "0px 0px 14px 0px",
      marginBottom: "22px",
      marginLeft: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginRight: "10px",
      },
      "& .MuiSwitch-root": {
        marginLeft: "-12px",
        marginRight: "8px",
      },
      "& svg": {
        position: "relative",
        bottom: "1px",
      },
      "& path": {
        fill: theme.colorTheme.grey,
      },
    },
    ecoSystemDetailsContainer: {
      "& .MuiAccordionSummary-root": {
        borderBottom: `1px solid ${theme.colorTheme.greyishWhite}`,
        padding: "2px 16px !important",
      },
      "& .MuiAccordionSummary-content": {
        alignItems: "center",
      },
      "& .MuiAccordion-root": {
        border: `1px solid ${theme.colorTheme.greyishWhite}`,
        marginTop: "20px !important",
        marginRight: "12px",
        boxShadow: "0px 0px 0px 0px !important",
      },
      "& .MuiAccordion-root:before": {
        backgroundColor: "transparent",
      },
      "& .MuiAccordionDetails-root": {
        flexDirection: "column",
      },
      "& .MuiButton-root": {
        minWidth: "88px",
        backgroundColor: theme.palette.primary.light2,
        color: theme.colorTheme.white,
        marginRight: "20px",
      },
      "& > div:last-child": {
        marginTop: "20px !important",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100% !important",
    },
    fieldContainer: {
      justifyContent: "space-between",
      marginBottom: "16px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "& input,& .MuiInputBase-root": {
        minWidth: "558px",
        maxWidth: "558px",
      },
      "& .MuiSelect-root": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& .MuiFormControl-root": {
        marginRight: "10px",
      },
    },
    alignStart: {
      alignItems: "start",
    },
    fieldContainer1: {
      width: "100%",
      justifyContent: "space-between",
      marginBottom: "16px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "400",
        color: theme.colorTheme.greyishBlack,
      },
      "& input,& .MuiInputBase-root": {
        minWidth: "352px",
        maxWidth: "352px",
      },
      "& textarea": {
        minWidth: "324px",
        maxWidth: "324px",
      },
      "& .MuiSelect-root": {
        backgroundColor: theme.colorTheme.white,
      },
      "& .MuiFormHelperText-contained": {
        maxWidth: "352px",
      },
    },
    fieldContainer1Parent: {
      width: "100%",
    },
    btnContainer: {
      minWidth: "102px",
      height: "35px",
      "& .MuiButton-containedPrimary": {
        width: "100%",
        height: "100%",
        backgroundColor: `${theme.palette.blue.main} !important`,
        display: "flex",
      },
      "& .MuiButton-contained.Mui-disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        color: `${theme.colorTheme.white} !important`,
      },
      "&:last-child": {
        marginLeft: "auto",
      },
    },
    justifySpaceBetween: {
      justifyContent: "space-between",
    },
    marginLeftAuto: {
      marginLeft: "auto",
      cursor: "pointer",
    },
    viewDetailsContainer: {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiTypography-h6": {
        fontSize: "14px",
        marginTop: "8px",
      },
      "& .MuiTypography-h6:first-child": {
        marginTop: "0px",
      },
      "& > div:last-child": {
        marginTop: "10px",
        marginLeft: "auto",
        alignSelf: "flex-start",
        cursor: "pointer",
      },
    },
    ecosystemFooter: {
      justifyContent: "space-between",
      "& .MuiButton-outlinedPrimary": {
        color: theme.palette.primary.light,
        backgroundColor: "white",
      },
      "& .MuiButton-containedPrimary": {
        maxWidth: "88px",
        marginRight: "0px !important",
        backgroundColor: theme.palette.blue.main,
        color: "white",
      },
      "& .MuiButton-root.Mui-disabled": {
        backgroundColor: theme.colorTheme.greyishWhite,
        color: theme.colorTheme.greyishWhie2,
      },
    },
  };
});

export default useStyles;
