import { useState, useRef } from "react";

import { Paper, Typography, Tabs, Tab, Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import getEvents from "../../../api/get-events";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import EventsModal from "./components/EventsModal";
import useStyles from "./styles";

const EventsContainer = () => {
  const history = useHistory();
  const {
    eventsTableColumns,
    eventsSearchDefaultFilterState,
    eventsSearchFilters,
  } = eventsModuleConfig;
  const classes = useStyles();
  const [eventsSelected, setEventsSelected] = useState([]);
  const [useEvent, setUseEvent] = useState({});

  const [loadingCellData, setLoadingCellData] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "eventName",
    row: {},
  });
  const [filters, setFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const childRef = useRef();
  const { setRequest } = useRequest();
  const [tab, setTab] = useState(0);

  const { user } = useUserProfile();
  const { t } = useTranslation();

  const globalClasses = useGlobalStyles();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchEvents
  );

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "eventName",
      row: {},
    });
  };

  const getAllowedColumns = () => {
    const columns = [...eventsTableColumns];
    return columns;
  };

  const filterFieldMap = {
    eventName: "eventName",
    eventDescription: "eventDescription",
    propertyName: "propertyName",
    propertyDescription: "propertyDescription",
    "": "searchText",
  };

  return !isUserAuthorized && !user.loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("events_container.events_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={tab}
          classes={{
            indicator: globalClasses.indicator,
          }}
          onChange={(_, number) => setTab(number)}
          aria-label="basic tabs"
        >
          <Tab
            label="Events"
            onClick={() => history.push("/events?displayTab=events")}
          />
          <Tab
            label="Event Responses"
            onClick={() => history.push("/events?displayTab=event-responses")}
          />
        </Tabs>
        <div
          className={clsx(classes.searchInputWrapper, classes.flexContainer)}
        >
          {eventsSelected.length > 0
            ? checkUserAuthorization(
                user.access,
                pageAccessConfig.useEvents
              ) && (
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={{
                    pathname: applicationConfig.pathnames.events_manage,
                    state: {
                      event: useEvent,
                    },
                  }}
                  onClick={() => setRequest({})}
                >
                  {t("manage_events.manage_events")}
                </Button>
              )
            : checkUserAuthorization(
                user.access,
                pageAccessConfig.useEvents
              ) && (
                <Button
                  onClick={() => {
                    history.push(applicationConfig.pathnames.events_manage, {});
                  }}
                  classes={{
                    root: globalClasses.btn,
                  }}
                  variant="outlined"
                  style={{
                    marginRight: "34px",
                    justifySelf: "flex-end",
                  }}
                >
                  {t("manage_events.new_events")}
                </Button>
              )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              const newData = { ...data };
              if (data && data.name === applicationConfig.filters.event_name) {
                newData.value = data.value.split(" ").join("_");
              }
              setFilters(newData);
            }}
            searchFilters={eventsSearchFilters}
            defaultFilterState={eventsSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="events-container" elevation={0}>
        <div className={classes.eventsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getEvents(
                null,
                null,
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="events"
            filters={filters}
            columns={getAllowedColumns()}
            setLoadingCellData={setLoadingCellData}
            openModal={() => setDrawerOpen(true)}
            renderCellData={(data, field, row) => {
              setCellData({ data, field, row });
            }}
            clickableCellExternalParams={["v3"]}
            checkboxSelection
            disableSelectAllCheckbox
            selectionModel={eventsSelected}
            onSelectionModelChange={(e, tableData) => {
              const rows = e;
              if (rows.length > 2) {
                return "";
              }
              const row = rows.filter(
                (newId) => !eventsSelected.includes(newId)
              );
              setEventsSelected(row);
              setUseEvent(tableData[row[0]]);
              return null;
            }}
            exportMainSheetName="Events"
            showArrayValueInNewSheet={(key) => key === "properties"}
            customizeExportData={(sheetObj, key, obj) => {
              const newSheetObj = {};
              Object.keys(sheetObj).forEach((sheetKey) => {
                if (sheetKey === "properties") {
                  newSheetObj["Event Properties"] = sheetObj[sheetKey]
                    ?.split(",")
                    ?.map((property) => ({
                      eventName: obj.eventName,
                      property,
                    }));
                }
              });
              return newSheetObj;
            }}
          />
        </div>
      </Paper>
      {drawerOpen && (
        <EventsModal
          title={cellData.row.eventName}
          subtitle={t("events_container.events_modal_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={resetDrawer}
        />
      )}
    </Layout>
  );
};

export default EventsContainer;
