import PropTypes from "prop-types";

import { lightTheme } from "../../assets/styles/themes";
import applicationConfig from "../../config/applicationConfig";

import getStatusColorIcon from "../../utilities/getStatusColorIcon";

import StyledTooltip from "../StyledTooltip";

import useStyles from "./styles";

const StatusBadge = ({ status, showTooltip, onTooltipClick }) => {
  const classes = useStyles();
  const { requestStatus, myApprovalsTooltips } = applicationConfig;
  const { toolTipTextApproved, toolTipTextPending, toolTipTextProcessing } =
    myApprovalsTooltips;

  if (!status) {
    return <></>;
  }

  const { statusColor, statusIcon } = getStatusColorIcon(
    status.toLowerCase(),
    requestStatus
  );

  let statusValue = status;

  if (statusValue === "success-partial") {
    statusValue = "success partial";
  }

  const toolTipHeadings = {
    [requestStatus.PENDING.toLowerCase()]: toolTipTextPending,
    [requestStatus.APPROVED.toLowerCase()]: toolTipTextApproved,
    [requestStatus.PROCESSING.toLowerCase()]: toolTipTextProcessing,
  };

  return (
    <div className={classes.statusContainer}>
      <div
        className={classes.statusWrapper}
        style={{
          backgroundColor: statusColor,
          color: lightTheme.palette.static.pureGrey,
        }}
      >
        {statusIcon[0]}
        <span className={classes.statusText}>
          {statusValue.charAt(0).toUpperCase() +
            statusValue.substr(1).toLowerCase()}
        </span>
      </div>
      {showTooltip && (
        <StyledTooltip
          placement="top"
          title={<span>{toolTipHeadings[status.toLowerCase()]}</span>}
        >
          <div
            onClick={() => {
              onTooltipClick();
            }}
            role="button"
            aria-hidden="true"
            data-testid="clickable-cell"
            className="icon"
          >
            {statusIcon[1]}
          </div>
        </StyledTooltip>
      )}
    </div>
  );
};

StatusBadge.defaultProps = {
  showTooltip: false,
  onTooltipClick: () => {},
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  onTooltipClick: PropTypes.func,
};

export default StatusBadge;
