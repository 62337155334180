import { Typography, Button } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const TextBox = ({ isNew, name, onClick, onDelete, hideDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.flexContainer}>
      <div className={clsx(classes.container)}>
        {isNew && <Button variant="contained">{t("common.new")}</Button>}
        <Typography variant="h6">{name}</Typography>
      </div>
      <div
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
        className={classes.btnContainer}
        data-testid="button-1"
      >
        {isNew ? (
          <span className={classes.editIcon}>
            <EditOutlinedIcon />
          </span>
        ) : (
          !hideDelete && (
            <span className={classes.deleteIcon}>
              <DeleteOutlineOutlinedIcon />
            </span>
          )
        )}
      </div>
      {isNew && !hideDelete && (
        <div
          onClick={onDelete}
          onKeyDown={onDelete}
          role="button"
          tabIndex={0}
          className={classes.btnContainer}
          data-testid="button-2"
        >
          <span className={classes.deleteIcon}>
            <DeleteOutlineOutlinedIcon />
          </span>
        </div>
      )}
    </div>
  );
};

TextBox.defaultProps = {
  hideDelete: false,
};

TextBox.propTypes = {
  isNew: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hideDelete: PropTypes.bool,
};

export default TextBox;
