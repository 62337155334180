import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      width: "469px",
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
        padding: "0 24px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        backgroundColor: theme.palette.error.main,
      },
      "& .MuiFormControl-root.MuiTextField-root": {
        width: "100%",
        marginTop: "8px",
      },
    },
    commentContainer: {
      "& textarea": {
        height: "126px !important",
        width: "100% !important",
        borderColor: theme.colorTheme.greyishWhite3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: "4px",
        outline: "none",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        marginBottom: "15px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "14px",
        marginBottom: "8px",
      },
      "& .MuiTypography-h6:nth-child(2)": {
        color: theme.colorTheme.greyishBlack2,
        marginLeft: "2px",
      },
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    asterisk: {
      color: theme.palette.error.main,
      fontSize: "18px",
    },
    dialogFooter: {
      justifyContent: "space-between",
      "& > div": {
        width: "100%",
      },
    },
    btnContainer: {
      "& .MuiButton-containedSecondary": {
        color: `${theme.colorTheme.white} !important`,
      },
    },
    error: {
      "& .MuiButton-containedSecondary": {
        backgroundColor: `${theme.palette.error.light2} !important`,
        color: `${theme.palette.static.white} !important`,
        "&:disabled": {
          backgroundColor: `${theme.palette.static.mildGrey} !important`,
          color: `${theme.palette.static.dullGrey} !important`,
        },
      },
    },
    success: {
      "& .MuiButton-containedSecondary": {
        backgroundColor: `${theme.palette.green.main3} !important`,
        color: `${theme.palette.static.white} !important`,
        "&:disabled": {
          backgroundColor: `${theme.palette.static.mildGrey} !important`,
          color: `${theme.palette.static.dullGrey} !important`,
        },
      },
    },
    info: {
      "& .MuiButton-containedSecondary": {
        color: `${theme.palette.static.white} !important`,
        backgroundColor: `${theme.palette.blue.darker} !important`,
        "&:disabled": {
          backgroundColor: `${theme.palette.static.mildGrey} !important`,
          color: `${theme.palette.static.dullGrey} !important`,
        },
      },
    },
  };
});

export default useStyles;
