import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import JobsManagementContainer from "./JobsManagementContainer";

const MonitoringModule = ({ basePath }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}/jobs`}
        component={JobsManagementContainer}
      />
      <Route exact path={`${basePath}/*`} render={() => <Redirect to="/" />} />
    </Switch>
  );
};

MonitoringModule.defaultProps = {
  basePath: applicationConfig.basePaths.monitoring,
};

MonitoringModule.propTypes = {
  basePath: PropTypes.string,
};

export default MonitoringModule;
