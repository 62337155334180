import { useState, useRef, useMemo, useEffect, useCallback } from "react";

import {
  Paper,
  Typography,
  Tab,
  Tabs,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Select,
  InputLabel,
  Button,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getEventProperties from "../../../api/get-event-properties";
import getEventsResponses from "../../../api/get-events-responses";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import CustomModal from "../../../components/CustomModal";
import InlineMessage from "../../../components/InlineMessage";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useEvents from "../../../hooks/useEvents";
import useLegalEntities from "../../../hooks/useLegalEntities";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useMarketingPrograms from "../../../hooks/useMarketingPrograms";
import useNotifier from "../../../hooks/useNotifier";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import { isEmptyString } from "../../../utilities/formValidation";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import truncateString from "../../../utilities/truncateString";
import useImmer from "../../../utilities/useImmer";

import traitResponsesStyles from "./styles";

const EventsResponsesManagementContainer = () => {
  const {
    eventsResponsesTableColumns,
    eventResponsesSearchDefaultFilterState,
    eventResponsesSearchFilters,
    eventsResponsesKeys,
  } = eventsModuleConfig;
  const globalStyles = useGlobalStyles();
  const accordionClasses = useAccordionStyles();
  const classes = traitResponsesStyles();
  const history = useHistory();
  const { loading } = useLoadingSpinner();
  const [tab, setTab] = useState(1);
  const [eventsResponsesModalIsOpen, setEventsResponsesModalIsOpen] =
    useState(false);
  const childRef = useRef();
  const [eventResponse, setEventResponse] = useState({});
  const [scope, setScope] = useState("global");
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useImmer({
    scopeIdentifier: null,
  });
  const [viewCloneMappings, setViewCloneMappings] = useState(false);
  const [clone, setClone] = useState({
    event: {},
  });
  const [subMenu, setSubMenu] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [selectedEventIdx, setSelectedEventIdx] = useState(undefined);
  const [loadingCellData, setLoadingCellData] = useState(false);

  const { addNotification } = useNotifier();
  const { user } = useUserProfile();
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const initialEventNameOptionsFilter = useCallback(() => true, []);
  const [eventProperties, setEventProperties] = useState([]);
  const [eventPropertiesLoading, setEventPropertiesLoading] = useState(false);
  const {
    getEventsFromApi,
    memoizedEventsFromApi,
    eventNameOptions,
    eventNamesLoading,
    setEventNamesLoading,
    initialEventNameOptions,
  } = useEvents({
    initialEventNameOptionsFilter,
  });

  const {
    getMarketingProgramsFromApi,
    initialMarketingProgramOptions,
    marketingProgramOptions,
    marketingProgramsLoading,
    setMarketingProgramOptions,
    setMarketingProgramsLoading,
  } = useMarketingPrograms();

  const {
    memoizedLegalEntitiesFromApi,
    legalEntities,
    legalEntitiesLoading,
    initialLegalEntitiesOptions,
    setLegalEntities,
    getLegalEntitiesFromApi,
  } = useLegalEntities();

  const [eventValue, setEventValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");

  useEffect(() => {
    (async () => {
      await getMarketingProgramsFromApi("");
      await getLegalEntitiesFromApi("");
      await getEventsFromApi("");
    })();
  }, []);

  const eventDropdownValues = useMemo(
    () =>
      eventValue.length > 0 || initialEventNameOptions.length === 0
        ? eventNameOptions
        : initialEventNameOptions,
    [eventNameOptions, eventValue.length, initialEventNameOptions]
  );

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchEvents
  );

  const clickableCellExternalParams = useMemo(
    () => [{ scope, schemaVersion: "v2", refreshCache: true }],
    [scope]
  );

  const toggleAccordion = (panel) => (event, isExpanded) => {
    if (scope !== "global") {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const loadEventsResponse = (data) => {
    try {
      if (!data) {
        setEventResponse((eventResp) => ({
          ...eventResp,
          mappedEventPropertyResponses: [],
        }));
        return;
      }
      let response = { ...data };
      let subMenuList = [];
      let selectedSubMenuListItem = null;

      if (response.mappedEventPropertyResponses.length > 0) {
        if (response.scope === "marketingProgram") {
          subMenuList = response.mappedEventPropertyResponses.map((x) => ({
            name: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
            value: `${x.marketingProgramNumber} - ${x.marketingProgramDescription}`,
          }));
          selectedSubMenuListItem = `${response.mappedEventPropertyResponses[0].marketingProgramNumber} - ${response.mappedEventPropertyResponses[0].marketingProgramDescription}`;
          response = {
            ...response,
            mappedEventPropertyResponses:
              response.mappedEventPropertyResponses.map((y) => ({
                ...y,
                title: `${y.marketingProgramNumber} - ${y.marketingProgramDescription}`,
              })),
          };
        } else if (response.scope === "legalEntity") {
          subMenuList = response.mappedEventPropertyResponses.map((x) => ({
            name: `${x.legalEntityId} - ${x.legalEntityName}`,
            value: `${x.legalEntityId} - ${x.legalEntityName}`,
          }));
          selectedSubMenuListItem = `${response.mappedEventPropertyResponses[0].legalEntityId} - ${response.mappedEventPropertyResponses[0].legalEntityName}`;
          response = {
            ...response,
            mappedEventPropertyResponses:
              response.mappedEventPropertyResponses.map((y) => ({
                ...y,
                title: `${y.legalEntityId} - ${y.legalEntityName}`,
              })),
          };
        } else if (response.scope === "regional") {
          subMenuList = response.mappedEventPropertyResponses.map((x) => ({
            name: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
            value: `${x.geoRegionCode} - ${x.geoRegionDescription}`,
          }));
          selectedSubMenuListItem = `${response.mappedEventPropertyResponses[0].geoRegionCode} - ${response.mappedEventPropertyResponses[0].geoRegionDescription}`;
          response = {
            ...response,
            mappedEventPropertyResponses:
              response.mappedEventPropertyResponses.map((y) => ({
                ...y,
                title: `${y.geoRegionCode} - ${y.geoRegionDescription}`,
              })),
          };
        } else {
          selectedSubMenuListItem = "Global";
          response = {
            ...response,
            mappedEventPropertyResponses:
              response.mappedEventPropertyResponses.map((y) => ({
                ...y,
                title: "Global",
              })),
          };
        }
        setSubMenu(subMenuList);
        setSelectedSubMenuItem((draft) => {
          draft.scopeIdentifier = selectedSubMenuListItem;
        });
        setEventResponse(response);
      }
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const filterFieldMap = {
    eventName: "eventName",
    propertyName: "propertyName",
    eventPropertyResponseEnglish: "eventPropertyResponseEnglish",
    eventPropertyResponseLocalLanguage: "eventPropertyResponseLocalLanguage",
    marketingProgram: "marketingProgram",
    "": "searchText",
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const getAllowedColumns = () => {
    const columns = [...eventsResponsesTableColumns];
    return columns;
  };

  useEffect(() => {
    (async () => {
      if (isEmpty(clone.event) || !clone.event.eventName) {
        setEventProperties([]);
        setClone((cloneValue) => ({
          ...cloneValue,
          eventProperty: {},
        }));
        return;
      }
      setEventPropertiesLoading(true);
      const response = await getEventProperties(clone.event.eventName, {
        denormalize: false,
      });
      if (response && response.properties) {
        setEventProperties(response.properties);
      }
      setEventPropertiesLoading(false);
    })();
  }, [clone.event]);

  const subList = () => {
    const marketingProgramDropdownValues =
      subMenuValue.length > 0 || initialMarketingProgramOptions.length === 0
        ? marketingProgramOptions
        : initialMarketingProgramOptions;
    const legalEntitiesDropdownValues =
      subMenuValue.length > 0 || initialLegalEntitiesOptions.length === 0
        ? legalEntities
        : initialLegalEntitiesOptions;
    const regionalDropdownValues = applicationConfig.regionFilters.map((r) => ({
      ...r,
      geoRegionCode: r.name,
      geoRegionName: r.label,
    }));

    if (clone.scope === "regional") {
      return regionalDropdownValues
        ?.filter((region) => {
          if (region.label !== "GLOBAL" && region.geoRegionName !== "GLOBAL") {
            return true;
          }
          return false;
        })
        .map((region) => {
          return {
            ...region,
            title: region.label
              ? `${region.name} - ${region.label}`
              : `${region.geoRegionCode} - ${region.geoRegionName}`,
          };
        });
    }
    if (clone.scope === "legalEntity") {
      return legalEntitiesDropdownValues.map((entity) => ({
        ...entity,
        title: `${entity.legalEntityId || entity.legalEntityNumber} - ${
          entity.legalEntityName
        }`,
      }));
    }
    return marketingProgramDropdownValues.map((program) => ({
      ...program,
      title: `${program.marketingProgramNumber} - ${
        program.marketingProgramDescription ||
        /* istanbul ignore next */ program.description
      }`,
    }));
  };

  const handleModalClose = () => {
    setExpanded(false);
    setEventsResponsesModalIsOpen(false);
    setScope("global");
  };

  const validateCloneResponses = () => {
    const e = {};
    if (isEmptyString(clone.event)) {
      e.eventError = t("errors.field_required");
    }
    if (isEmptyString(clone.eventProperty)) {
      e.selectedEventPropertyError = t("errors.field_required");
    }
    if (isEmptyString(clone.scope)) {
      e.scopeError = t("errors.field_required");
    }
    if (
      clone.scope !== "global" &&
      isEmpty(clone.selectedSubMenuItem?.scopeIdentifier)
    ) {
      e.scopeIdentifierError = t("errors.field_required");
    }
    if (
      (clone.scope === "global" || scope === "global") &&
      scope !== clone.scope
    ) {
      e.cloneFormError = "Only Global scope can be cloned to Global scope";
    }
    return e;
  };

  const scopedEventPropertyResponses =
    eventResponse?.mappedEventPropertyResponses?.length > 0
      ? (
          eventResponse.mappedEventPropertyResponses.find(
            (x) => x.title === selectedSubMenuItem.scopeIdentifier
          ) || eventResponse.mappedEventPropertyResponses?.[0]
        )?.eventPropertyResponses
      : [];

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("events_container.events_responses_management")}
          </Typography>
        </div>
      }
      showSpinner={loading}
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={tab}
          classes={{
            indicator: globalStyles.indicator,
          }}
          onChange={(_, number) => setTab(number)}
          aria-label="basic tabs"
        >
          <Tab
            label="Events"
            onClick={() => history.push("/events?displayTab=events")}
          />
          <Tab
            label="Event Responses"
            onClick={() => history.push("/events?displayTab=event-responses")}
          />
        </Tabs>
        <div className={classes.flexContainer}>
          {checkUserAuthorization(user.access, pageAccessConfig.useEvents) && (
            <Button
              onClick={() => {
                history.push(
                  applicationConfig.pathnames.traits_response_manage,
                  {
                    from: applicationConfig.modules.event_response,
                    event: { ...selectedEvent },
                  }
                );
              }}
              classes={{
                root: globalStyles.btn,
              }}
              variant="outlined"
              style={{
                marginRight: "34px",
                justifySelf: "flex-end",
              }}
            >
              {isEmpty(selectedEvent)
                ? t("traits_traits_responses.new_event_responses")
                : t("common.labels.manage_responses")}
            </Button>
          )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              const newData = { ...data };
              if (data && data.name === applicationConfig.filters.event_name) {
                newData.value = data.value.split(" ").join("_");
              }
              setFilters(newData);
            }}
            searchFilters={eventResponsesSearchFilters}
            defaultFilterState={eventResponsesSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="events-container" elevation={0}>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getEventsResponses(
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="event-responses"
            openModal={() => {
              setEventsResponsesModalIsOpen(true);
            }}
            disableSelectAllCheckbox
            checkboxSelection
            selectionModel={selectedEventIdx}
            onSelectionModelChange={(e, tableData) => {
              setSelectedEvent(tableData[e.at(-1)]);
              setSelectedEventIdx(e.at(-1));
            }}
            filters={filters}
            renderCellData={(data) => loadEventsResponse(data)}
            setLoadingCellData={setLoadingCellData}
            columns={getAllowedColumns()}
            clickableCellExternalParams={clickableCellExternalParams}
            exportMainSheetName="Event Properties"
            showArrayValueInNewSheet={(key) =>
              key === "predefinedEventPropertyResponses"
            }
            customizeExportData={(sheetObj, key, obj) => {
              const newSheetObj = {};
              Object.keys(sheetObj).forEach((sheetKey) => {
                if (sheetKey === "predefinedEventPropertyResponse") {
                  newSheetObj["Event Property Responses"] = sheetObj[
                    sheetKey
                  ].map((x) => ({
                    eventName: obj.eventName,
                    propertyName: obj.propertyName,
                    ...x,
                  }));
                }
              });
              return newSheetObj;
            }}
          />
        </div>
      </Paper>
      {eventsResponsesModalIsOpen && (
        <CustomModal
          open={eventsResponsesModalIsOpen}
          onClose={handleModalClose}
          loading={loadingCellData && !eventResponse?.eventName}
          showCloseIcon
          title={`${eventResponse.eventName} - ${eventResponse.propertyName}`}
          subtitle={t("eventsResponses.event_responses_modal_title")}
          data={{
            scope: {
              scope,
              identifier: selectedSubMenuItem.scopeIdentifier,
            },
            eventPropertyResponses: scopedEventPropertyResponses,
          }}
        >
          <FormControl fullWidth style={{ marginBottom: "10px" }}>
            <InputLabel id="scope-select-label">Scope</InputLabel>
            <Select
              value={scope}
              label="Scope"
              id="scope-select"
              onChange={(e) => {
                setScope(e.target.value);
                setSubMenu([]);
                setSelectedSubMenuItem((draft) => {
                  draft.scopeIdentifier = null;
                });
                if (expanded) {
                  setExpanded(false);
                }
              }}
            >
              <MenuItem value="global">{t("common.global")}</MenuItem>
              <MenuItem value="regional">{t("common.labels.region")}</MenuItem>
              <MenuItem value="legalEntity">
                {t("common.labels.legal_entity")}
              </MenuItem>
              <MenuItem value="marketingProgram">
                {t("common.labels.marketing_program")}
              </MenuItem>
            </Select>
          </FormControl>
          {subMenu.length > 0 && !loadingCellData && (
            <FormControl fullWidth className={classes.accordionSelect}>
              <InputLabel id="scope-select-label" shrink>
                {eventResponse.scope === "regional"
                  ? "Region"
                  : eventResponse.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Select
                labelId="scope-select-label"
                id="scope-select"
                value={selectedSubMenuItem.scopeIdentifier}
                label="Marketing Program"
                onChange={(e) => {
                  setSelectedSubMenuItem((draft) => {
                    draft.scopeIdentifier = e.target.value;
                  });
                  if (expanded) {
                    setExpanded(false);
                  }
                }}
              >
                {subMenu.map(({ name, value }) => (
                  <MenuItem value={value} key={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={accordionClasses.systems}>
            {Array.isArray(eventResponse.mappedEventPropertyResponses) &&
            eventResponse.mappedEventPropertyResponses.length > 0 &&
            !loadingCellData
              ? scopedEventPropertyResponses?.map((row, i) => {
                  const id = `system-${i.toString()}`;
                  const languageCode =
                    row?.languageCode?.toUpperCase() ||
                    (scope === "global" && "EN");
                  const eventResp = truncateString(
                    row?.predefinedEventPropertyResponseLocalLanguage ??
                      row.predefinedEventPropertyResponse,
                    {
                      maxLength: 40,
                      showEllipsis: true,
                    }
                  );
                  return (
                    <Accordion
                      key={i.toString()}
                      className={clsx(
                        accordionClasses.accordion,
                        classes.customAccordion
                      )}
                      expanded={expanded === id}
                      onChange={toggleAccordion(id)}
                    >
                      <AccordionSummary
                        data-testid="accordion-summary"
                        className={accordionClasses.accordionHeader}
                        expandIcon={scope !== "global" && <ExpandMoreIcon />}
                      >
                        <Typography className={accordionClasses.systemType}>
                          {languageCode}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={accordionClasses.systemName}
                        >
                          {eventResp}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className={accordionClasses.accordionContent}
                      >
                        <AccordionRowDetails
                          keys={eventsResponsesKeys}
                          row={{
                            ...row,
                            language: `${row?.languageName} (${languageCode})`,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : !loadingCellData && (
                  <Alert severity="info">
                    {t("events_container.empty_mapped_properties_alert")}
                  </Alert>
                )}
          </div>
          {loadingCellData && (
            <div style={{ padding: "8px" }}>
              {Array.from({ length: 3 }).map((_, i) => (
                <>
                  <Skeleton key={i} variant="rounded" width={640} height={60} />
                  <br />
                </>
              ))}
            </div>
          )}

          <div className={accordionClasses.footer2}>
            {checkUserAuthorization(
              user.access,
              pageAccessConfig.useEvents
            ) && (
              <Button
                data-testid="closeButton"
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(
                    applicationConfig.pathnames.traits_response_manage,
                    {
                      from: applicationConfig.modules.event_response,
                      event: eventResponse,
                      scope,
                      selectedSubMenuItem,
                    }
                  );
                }}
              >
                {t("common.manage_responses")}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={
                loadingCellData ||
                !(
                  eventResponse?.mappedEventPropertyResponses?.length > 0 &&
                  !loadingCellData
                )
              }
              classes={{
                root: globalStyles.btn,
              }}
              onClick={() => {
                setViewCloneMappings(true);
              }}
            >
              Clone Responses
            </Button>
          </div>
        </CustomModal>
      )}
      <CustomModal
        open={viewCloneMappings}
        onClose={() => {
          setViewCloneMappings(false);
          setClone({});
          setErrors({});
        }}
        showCloseIcon
        title="Clone Responses"
      >
        <div className={classes.inputContainer} key="marketing-program">
          <InputLabel
            id="event-name-label"
            classes={{
              root: globalStyles.label,
            }}
          >
            Event
          </InputLabel>
          <Autocomplete
            options={eventDropdownValues.map((option) => ({
              ...option,
              title: option.eventName,
            }))}
            filterOptions={(options) => options}
            id="event"
            placeholder="Please select a Event"
            loading={eventNamesLoading}
            getOptionLabel={(option) => option.title || ""}
            value={clone.event}
            onChange={(event, value) => {
              setClone({
                ...clone,
                event: value,
              });
              setErrors({
                ...errors,
                eventError: "",
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Please select an Event Name"
                onChange={(event) => {
                  setEventNamesLoading(true);
                  setEventValue(event.target.value);
                  memoizedEventsFromApi(event.target.value);
                }}
              />
            )}
          />
          {errors.eventError && (
            <FormHelperText
              id="my-helper-text"
              error={Boolean(errors.eventError)}
            >
              <InlineMessage message={errors.eventError} />
            </FormHelperText>
          )}
          <div
            style={{ marginTop: 20, marginBottom: 20 }}
            className={clsx(classes.eventPropertyInput, classes.inputContainer)}
          >
            <InputLabel
              id="event-property-label"
              classes={{
                root: globalStyles.label,
              }}
            >
              {t("common.labels.event_property_name")}
            </InputLabel>
            <div>
              <Autocomplete
                disabled={isEmpty(clone.event)}
                id="event-property"
                data-testid="autocomplete-wrapper"
                value={clone.eventProperty || {}}
                getOptionLabel={(option) => {
                  if (option.title) {
                    return option.title.toString();
                  }
                  return "";
                }}
                loading={eventPropertiesLoading}
                options={eventProperties.map((property) => ({
                  ...property,
                  title: property.propertyName,
                }))}
                getOptionSelected={(option, value) =>
                  option.title === value.title
                }
                fullWidth
                onChange={(_, value) => {
                  setClone((cloneValue) => ({
                    ...cloneValue,
                    eventProperty: value,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    data-testid="property-input"
                    placeholder={
                      clone.event
                        ? "Example: bannerTitle"
                        : "Please select Event first"
                    }
                  />
                )}
              />
              {errors.selectedEventPropertyError && (
                <FormHelperText error>
                  {errors.selectedEventPropertyError}
                </FormHelperText>
              )}
            </div>
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <FormControl fullWidth>
              <InputLabel
                id="scope-label"
                classes={{
                  root: globalStyles.label,
                  shrink: classes.shrinkLabel,
                }}
              >
                Scope
              </InputLabel>
              <Select
                value={clone.scope}
                label="Scope"
                id="scope"
                onChange={(e) => {
                  setClone({
                    ...clone,
                    scope: e.target.value,
                    selectedSubMenuItem: {},
                  });
                  setSubMenuValue("");
                  setErrors({
                    ...errors,
                    scopeError: "",
                  });
                }}
              >
                <MenuItem value="global" data-testid="global-option">
                  Global
                </MenuItem>
                <MenuItem value="regional" data-testid="regional-option">
                  Region
                </MenuItem>
                <MenuItem value="legalEntity" data-testid="legal-option">
                  Legal Entity
                </MenuItem>
                <MenuItem
                  value="marketingProgram"
                  data-testid="marketing-option"
                >
                  Marketing Program
                </MenuItem>
              </Select>
              {errors.scopeError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.scopeError)}
                >
                  <InlineMessage message={errors.scopeError} />
                </FormHelperText>
              )}
              {errors.cloneFormError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.cloneFormError)}
                >
                  <InlineMessage message={errors.cloneFormError} />
                </FormHelperText>
              )}
            </FormControl>
          </div>
          {clone.scope && clone.scope !== "global" && (
            <div
              className={clsx(classes.inputContainer, classes.justifyContent)}
              key="region"
            >
              <InputLabel
                id="scope-value-label"
                classes={{
                  root: globalStyles.label,
                }}
              >
                {clone.scope === "regional"
                  ? "Region"
                  : clone.scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Autocomplete
                options={subList()}
                id="regions"
                placeholder={`Please select a ${
                  clone.scope === "regional"
                    ? "Region"
                    : clone.scope
                        ?.replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())
                }`}
                loading={marketingProgramsLoading || legalEntitiesLoading}
                getOptionLabel={(option) => option.title || ""}
                value={clone.selectedSubMenuItem?.scopeIdentifier}
                onChange={async (event, value) => {
                  setClone({
                    ...clone,
                    selectedSubMenuItem: {
                      ...value,
                      scopeIdentifier: isEmpty(value) ? {} : value.title,
                    },
                  });
                  setErrors({
                    ...errors,
                    scopeIdentifierError: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={`Please select a ${
                      clone.scope === "regional"
                        ? "Region"
                        : clone.scope
                            ?.replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())
                    }`}
                    onChange={(event) => {
                      if (clone.scope === "marketingProgram") {
                        setMarketingProgramsLoading(true);
                        setMarketingProgramOptions([]);
                        getMarketingProgramsFromApi(event.target.value);
                      }
                      if (clone.scope === "legalEntity") {
                        setLegalEntities([]);
                        memoizedLegalEntitiesFromApi(event.target.value);
                      }
                      setSubMenuValue(event.target.value);
                    }}
                  />
                )}
              />
              {errors.scopeIdentifierError && (
                <FormHelperText
                  id="my-helper-text"
                  error={Boolean(errors.scopeIdentifierError)}
                >
                  <InlineMessage message={errors.scopeIdentifierError} />
                </FormHelperText>
              )}
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={async () => {
              const validationErrors = validateCloneResponses();
              setErrors(validationErrors);
              if (
                validationErrors &&
                Object.values(validationErrors).filter((e) => e).length > 0
              ) {
                return;
              }
              history.push(applicationConfig.pathnames.traits_response_manage, {
                from: applicationConfig.modules.event_response,
                ...clone,
                event: {
                  ...clone.eventProperty,
                  ...clone.event,
                },
                clonedEventPropertyResponses:
                  eventResponse?.mappedEventPropertyResponses
                    ?.filter((mepr) => {
                      if (
                        scope === "global" ||
                        mepr.title === selectedSubMenuItem.scopeIdentifier
                      ) {
                        return true;
                      }
                      return false;
                    })?.[0]
                    ?.eventPropertyResponses?.map((evtResponse) => ({
                      ...evtResponse,
                      new: true,
                    })),
              });
            }}
          >
            Clone Responses
          </Button>
        </div>
      </CustomModal>
    </Layout>
  );
};

EventsResponsesManagementContainer.propTypes = {};

export default EventsResponsesManagementContainer;
