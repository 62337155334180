const convertBackendPayload = (info) => {
  const {
    brandProgramName,
    brandProgramShortName,
    marketingProgramName,
    mpBusinessName,
    description,
    businessOrgUnit,
  } = info.marketingProgram;
  return {
    legalEntity: info.legalEntity,
    brandName: brandProgramName,
    brandShortName: brandProgramShortName,
    businessOrgUnit,
    marketingProgramName,
    marketingProgramDescription: description,
    marketingProgramBusinessName: mpBusinessName,
    startingEcosystems: info.marketingProgramEcosystems?.map(
      (ecosystem) => ecosystem.ecoSystemName
    ),
    startingChannels: info.contactPointTypes?.map(
      (contactPoint) => contactPoint.contactPointTypeName
    ),
  };
};

export default convertBackendPayload;
