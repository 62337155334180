import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Close } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-quill/dist/quill.snow.css";

import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

import Form from "../../../../../components/Form";
import InlineMessage from "../../../../../components/InlineMessage";
import StyledTooltip from "../../../../../components/StyledTooltip";
import validateNewOptId from "../../helpers/validateNewOptId";

import useStyles from "./styles";

const NewOptIdModal = ({
  openModal,
  handleClose,
  ciamMarketingProgramOptions,
  isDisabled,
  ciamMarketingProgramsLoading,
  setCiamMarketingProgramsLoading,
  setCiamMarketingProgramOptions,
  memoizedMarketingProgramsFromApi,
  setOptInfo,
  resetState,
  optInfo,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    optTextEnglishError: null,
    optTextLocalError: null,
    marketingProgramError: null,
  });
  const classes = useStyles();
  const [isCancelClicked, setIsCancelClicked] = useState(false);

  const fields = [
    {
      type: "custom",
      label: t("common.labels.marketing_program"),
      required: true,
      element: Autocomplete,
      props: {
        id: "combo-box-demo",
        disabled: isDisabled,
        options: ciamMarketingProgramOptions.map((option) => ({
          ...option,
          title: `${option.marketingProgramNumber} - ${option.description}`,
        })),
        loading: ciamMarketingProgramsLoading,
        getOptionLabel: (option) => option.title || "",
        onChange: async (event, value) => {
          let newValue = value;
          if (!value) {
            newValue = {};
          }
          setOptInfo((draft) => {
            draft.ciamMarketingProgram = newValue;
          });
          setErrors({
            ...errors,
            marketingProgramError: null,
          });
        },
        value: optInfo.ciamMarketingProgram,
        renderInput: (params) => (
          <>
            <TextField
              {...params}
              variant="outlined"
              placeholder={t("new_opt_id.placeholders.ciam_marketing_program")}
              error={!!errors.marketingProgramError}
              helperText={
                <InlineMessage message={errors.marketingProgramError} />
              }
              onChange={(event) => {
                setCiamMarketingProgramsLoading(true);
                setCiamMarketingProgramOptions([]);
                memoizedMarketingProgramsFromApi(event.target.value);
              }}
            />
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        disableEnforceFocus
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">{t("opts_container.new_opt_id")}</Typography>
          <Box
            className={classes.marginLeftAuto}
            onClick={() => {
              if (isDisabled) {
                handleClose();
              } else {
                setIsCancelClicked(true);
              }
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </Box>
        </div>
        <DialogContent>
          <div className={classes.grid}>
            <Form fields={fields} fieldClassName={classes.inputContainer} />
            <div className={classes.inputContainer}>
              <StyledTooltip
                placement="top-start"
                title={t("new_opt_id.tooltips.opt_text_english")}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography variant="h6">
                    {t("new_opt_id.opt_text_english")}
                    <i style={{ color: "red" }}>*</i>
                  </Typography>
                  <InfoIcon className={classes.infoIcon} />
                </div>
              </StyledTooltip>
              <ReactQuill
                theme="snow"
                value={optInfo.optTextEnglish}
                onChange={(value) => {
                  setOptInfo((draft) => {
                    draft.optTextEnglish = value;
                  });
                  setErrors({
                    ...errors,
                    optTextEnglishError: null,
                  });
                }}
                readOnly={isDisabled}
                modules={{
                  toolbar: [
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                  ],
                }}
              />
              <InlineMessage message={errors.optTextEnglishError} />
            </div>
            <div className={classes.inputContainer}>
              <StyledTooltip
                placement="top-start"
                title={t("new_opt_id.tooltips.opt_text_local")}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography variant="h6">
                    {t("new_opt_id.opt_text_local")}
                    <i style={{ color: "red" }}>*</i>
                  </Typography>
                  <InfoIcon className={classes.infoIcon} />
                </div>
              </StyledTooltip>
              <ReactQuill
                theme="snow"
                value={optInfo.optTextLocal}
                readOnly={isDisabled}
                modules={{
                  toolbar: [
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                  ],
                }}
                onChange={(value) => {
                  setOptInfo((draft) => {
                    draft.optTextLocal = value;
                  });
                  setErrors({
                    ...errors,
                    optTextLocalError: null,
                  });
                }}
              />
              <InlineMessage message={errors.optTextLocalError} />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          <div className={classes.btnContainer}>
            {!isDisabled && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  const errorValues = validateNewOptId(optInfo);
                  if (Object.values(errorValues).some((x) => x)) {
                    setErrors(errorValues);
                    return;
                  }
                  handleSubmit();
                }}
              >
                {t("common.submit")}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewOptIdModal.defaultProps = {};

NewOptIdModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ciamMarketingProgramOptions: PropTypes.arrayOf({
    description: PropTypes.string,
    legalEntityName: PropTypes.string,
    legalEntityNumber: PropTypes.number,
    marketingProgramName: PropTypes.string,
    marketingProgramNumber: PropTypes.number,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  ciamMarketingProgramsLoading: PropTypes.bool.isRequired,
  setCiamMarketingProgramsLoading: PropTypes.func.isRequired,
  setCiamMarketingProgramOptions: PropTypes.func.isRequired,
  memoizedMarketingProgramsFromApi: PropTypes.func.isRequired,
  setOptInfo: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  optInfo: PropTypes.shape({
    ciamMarketingProgram: PropTypes.string,
    optTextEnglish: PropTypes.string,
    optTextLocal: PropTypes.string,
  }).isRequired,
};

export default NewOptIdModal;
