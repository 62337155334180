import i18n from "../../../i18n/init";

const getSourceFields = (sourcesInfo) => {
  let fields = [
    {
      label: i18n.t("common.labels.source_description"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.description,
      },
    },
    {
      label: i18n.t("new_source.consumer_facing_description"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.consumerFacingDescription,
      },
    },
    {
      label: i18n.t("new_source.description_of_process"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.descriptionOfProcess,
      },
    },
    {
      label: i18n.t("common.labels.source_type"),
      type: "dropdown",
      flex: 2,
      props: {
        value: sourcesInfo.sourceType,
      },
    },
    {
      label: i18n.t("new_source.kind_of_data_collected"),
      type: "multi-select-dropdown",
      props: {
        value: sourcesInfo.dataType,
      },
    },
    {
      label: i18n.t("new_source.business_facing_name"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.businessFacingName,
      },
    },
    {
      label: i18n.t("new_source.is_data_source_website"),
      type: "dropdown",
      props: {
        value: sourcesInfo.dataSourceWebsite
          ? i18n.t("common.yes")
          : i18n.t("common.no"),
      },
    },
    {
      label: i18n.t("new_source.data_source_url"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.url,
      },
    },
    {
      label: i18n.t("new_source.registration_page_url"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.registrationPageUrl,
      },
    },
    {
      label: i18n.t("new_source.system_displaying_page"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.systemDisplayingPage,
      },
    },
    {
      label: i18n.t("new_source.is_managed_by_agency"),
      type: "dropdown",
      props: {
        value: sourcesInfo.managedByExternalAgency
          ? i18n.t("common.yes")
          : i18n.t("common.no"),
      },
    },
    {
      label: i18n.t("new_source.agency_name"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.agencyName,
      },
    },
    {
      label: i18n.t("new_source.is_source_controller_of_data"),
      type: "dropdown",
      flex: 2,
      props: {
        value: sourcesInfo.coControllerInd
          ? i18n.t("common.yes")
          : i18n.t("common.no"),
      },
    },
    {
      label: i18n.t("new_source.ropa_id"),
      type: "text-input",
      flex: 2,
      props: {
        value: sourcesInfo.ropaId,
      },
    },
  ];
  fields = fields.filter((field) => {
    if (field.label === i18n.t("new_source.data_source_url")) {
      if (sourcesInfo.dataSourceWebsite) {
        return true;
      }
      return false;
    }
    if (field.label === i18n.t("new_source.registration_page_url")) {
      if (
        sourcesInfo.dataSourceWebsite &&
        sourcesInfo.dataType.includes(
          i18n.t("constants.kind_of_data_collected_options.registration_data")
        )
      ) {
        return true;
      }
      return false;
    }
    if (field.label === i18n.t("new_source.system_displaying_page")) {
      if (sourcesInfo.dataSourceWebsite) {
        return true;
      }
      return false;
    }
    if (field.label === i18n.t("new_source.agency_name")) {
      if (!sourcesInfo.managedByExternalAgency) {
        return false;
      }
      return true;
    }
    if (field.label === i18n.t("new_source.ropa_id")) {
      if (!sourcesInfo.coControllerInd) {
        return false;
      }
      return true;
    }
    return true;
  });
  return fields;
};

export default getSourceFields;
