import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    disabled: {
      "& .MuiTypography-h6": {
        color: "rgba(0, 0, 0, 0.38) !important",
      },
    },
    rootContainer: {
      "& .MuiTypography-h5": {
        color: `${theme.colorTheme.modalTitle} !important`,
        fontSize: "14px",
        fontWeight: 400,
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    flexContainer1: {
      display: "flex",
      alignItems: "center",
      marginLeft: "12px",
    },
    flexContainer2: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& > div": {
        width: "100%",
      },
    },
    disableViewMode: {
      opacity: 0.5,
    },
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      "& + div[role='button']": {
        marginLeft: "12px",
      },
      "& .MuiFormControl-root": {
        marginRight: 0,
      },
      minHeight: "34px",
      "& .MuiTypography-h6": {
        fontSize: "14px",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
        marginRight: "20px",
      },
    },
    btnContainer: {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
        cursor: "pointer",
      },
    },
    editIcon: {
      "& svg path": {
        fill: `${theme.palette.blue.light}`,
      },
    },
    doneIcon: {
      "& svg path": {
        fill: `${theme.palette.green.main}`,
      },
    },
    clearIcon: {
      "& svg path": {
        fill: `${theme.palette.static.hardRed}`,
      },
    },
  };
});

export default useStyles;
