import applicationConfig from "../config/applicationConfig";

const cache = {
  traits: true,
  "data-sources": true,
  opts: true,
  "marketing-programs": true,
  events: true,
  consents: true,
};

export const initCache = () => {
  window.sessionStorage.setItem("refreshCache", JSON.stringify(cache));
};

const resetCacheParam = (param) => {
  const currentVal = JSON.parse(window.sessionStorage.getItem("refreshCache"));
  currentVal[param] = true;
  window.sessionStorage.setItem("refreshCache", JSON.stringify(currentVal));
};
class Timer {
  constructor() {
    this.modules = new Map();
    this.timeout = parseInt(applicationConfig.refreshCacheTimeOut, 10);
  }

  start(param) {
    let id = null;
    id = this.modules.get(param);
    if (id) {
      Timer.stop(id);
    }
    id = setTimeout(() => {
      resetCacheParam(param);
      Timer.stop(this.modules.get(param));
    }, this.timeout);

    this.modules.set(param, id);
  }

  static stop(id) {
    if (id) {
      clearTimeout(id);
    }
  }
}

const cacheTimer = new Timer();

export const setValue = (param) => {
  if (param in cache) {
    const currentVal = JSON.parse(
      window.sessionStorage.getItem("refreshCache")
    );
    currentVal[param] = true;
    window.sessionStorage.setItem("refreshCache", JSON.stringify(currentVal));
    // Start timer to invalidate
    cacheTimer.start(param);
  }
};

export const getValue = (param) => {
  if (param in cache && window.sessionStorage.getItem("refreshCache")) {
    return JSON.parse(window.sessionStorage.getItem("refreshCache"))[param];
  }
  return false;
};
