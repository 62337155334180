import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import useAccordionStyles from "../../../../../components/AccordionModal/styles";
import { KeyValueSeparator } from "../../../../../components/AccordionRowDetails";
import CustomModal from "../../../../../components/CustomModal";
import { TraitsMapping } from "../../../../../components/MarketingProgramsModal";

import applicationConfig from "../../../../../config/applicationConfig";
import marketingProgramsModuleConfig from "../../../../../config/marketingProgramsModuleConfig";
import pageAccessConfig from "../../../../../config/pageAccessConfig";

import useRequest from "../../../../../hooks/useRequest";
import useUserProfile from "../../../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../../../utilities/checkUserAuthorization";
import { splitCamelCaseString } from "../../../../../utilities/formValidation";

import useStyles from "./styles";

const MarketingProgramDetailsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const accordionClasses = useAccordionStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const history = useHistory();
  const { user } = useUserProfile();
  const { setRequest } = useRequest();

  const { ecosystems, traits } = data;
  const { mpEcosystemKeys } =
    marketingProgramsModuleConfig.mpEcosystemMappingConstants;

  const rows = Object.keys(data)
    .filter((item) => item !== "ecosystems" && item !== "traits")
    .map((key) => {
      const camelCase = splitCamelCaseString(key);
      return {
        label: camelCase[0].toUpperCase() + camelCase.slice(1),
        value: data[key]?.toString(),
      };
    })
    .filter(({ value }) => !!value);

  const renderUi = () => {
    if (tab === 0) {
      return (
        <table className={globalClasses.table}>
          <tbody>
            {rows.map((row, ind) => (
              <KeyValueSeparator
                keyVal={row.label}
                value={row.value}
                ind={ind.toString()}
                separator={null}
              />
            ))}
          </tbody>
        </table>
      );
    }

    if (tab === 1) {
      return <TraitsMapping marketingPrograms={traits} mpLevel />;
    }

    return (
      <AccordionModalDetails
        keys={mpEcosystemKeys}
        rows={ecosystems}
        rowKeys={{
          title: "ecosystemName",
          subTitle: "ecosystemMarketingProgramName",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      data={{
        marketingProgramDetails: rows,
        traits,
        ecosystems,
      }}
      title={title}
      subtitle={subtitle}
      loading={loading}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
            }}
          >
            <Tab label="Marketing Program Details" />
            <Tab label="Traits" />
            <Tab label="Ecosystems" />
          </Tabs>
          {renderUi()}
        </div>
        <div className={accordionClasses.footer2}>
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createMarketingPrograms
          ) && (
            <Button
              disabled={loading}
              onClick={() => {
                history.push(
                  applicationConfig.pathnames.marketing_program_manage,
                  {
                    mp: {
                      marketingProgramNumber: data.marketingProgramNumber,
                      description: data.description,
                      legalEntityName: data.legalEntityName,
                      legalEntityNumber: data.legalEntityNumber,
                      marketingProgramName: data.marketingProgramName,
                      title: `${data.marketingProgramNumber} - ${data.description}`,
                    },
                  }
                );
              }}
              variant="contained"
              color="primary"
              classes={{
                root: globalClasses.btn,
              }}
            >
              {t("manage_mps.manage")}
            </Button>
          )}
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createTraits
          ) && (
            <Button
              disabled={loading || data.traits.length === 0}
              onClick={() => {
                setRequest({});
                history.push({
                  pathname: applicationConfig.pathnames.traits_map,
                  state: {
                    flow: "reuse",
                    traits: data.traits,
                  },
                });
              }}
              variant="outlined"
              color="primary"
            >
              {t("manage_mps.reuse")}
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

MarketingProgramDetailsModal.defaultProps = {
  title: null,
};

MarketingProgramDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    marketingProgramNumber: PropTypes.number,
    marketingProgramName: PropTypes.string,
    description: PropTypes.string,
    traits: PropTypes.shape([]).isRequired,
    legalEntityName: PropTypes.string,
    legalEntityNumber: PropTypes.number,
    ecosystems: PropTypes.shape([]).isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MarketingProgramDetailsModal;
