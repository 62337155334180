import getInsight from "../api/get-insight";
import getTrait from "../api/get-trait";
import getTraitsResponse from "../api/get-traits-response";

import getUseCaseDetails from "../api/get-use-case-details";

import i18n from "../i18n/init";

const traitsModuleConfig = {
  traitSearchFilters: [
    {
      name: "traitId",
      label: i18n.t("common.labels.trait_id"),
    },
    {
      name: "traitName",
      label: i18n.t("common.labels.trait_name"),
    },
    {
      name: "traitDescription",
      label: i18n.t("common.labels.trait_description"),
    },
  ],
  insightsSearchFilter: [
    {
      name: "insightsId",
      label: i18n.t("common.labels.insight_id"),
    },
    {
      name: "insightsName",
      label: i18n.t("common.labels.insight_name"),
    },
    {
      name: "description",
      label: i18n.t("common.labels.insight_description"),
    },
    {
      name: "outputTrait",
      label: i18n.t("common.labels.output_trait"),
    },
  ],
  traitsResponsesSearchFilters: [
    {
      name: "traitId",
      label: i18n.t("common.labels.trait_id"),
    },
    {
      name: "traitName",
      label: i18n.t("common.labels.trait_name"),
    },
    {
      name: "traitResponseEnglish",
      label: i18n.t("traits_container.search_filter.traits_response_english"),
    },
    {
      name: "traitResponseLocalLanguage",
      label: i18n.t(
        "traits_container.search_filter.traits_response_local_language"
      ),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.marketing_program"),
    },
  ],
  traitsUsecaseSearchFilters: [
    {
      name: "useCaseName",
      label: i18n.t("common.labels.use_case_name"),
    },
    {
      name: "useCaseDescription",
      label: i18n.t("traits_usecases.search_filter.usecase_description"),
    },
    {
      name: "traitName",
      label: i18n.t("common.labels.trait_name"),
    },
    {
      name: "eventProperty",
      label: i18n.t("common.labels.event_property_name"),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.marketing_program"),
    },
  ],
  createTraitConstants: {
    dataTypeOptions: [
      i18n.t("constants.data_type_options.string"),
      i18n.t("constants.data_type_options.integer"),
      i18n.t("constants.data_type_options.date_time"),
      i18n.t("constants.data_type_options.date"),
      i18n.t("constants.data_type_options.float"),
      i18n.t("constants.data_type_options.array"),
      i18n.t("constants.data_type_options.boolean"),
    ],
    traitResponseTypeOptions: [
      i18n.t("constants.trait_response_type_options.single"),
      i18n.t("constants.trait_response_type_options.multiple"),
    ],
    survivorshipRules: [
      i18n.t("constants.survivorship_rules.most_recent"),
      i18n.t("constants.survivorship_rules.first"),
      i18n.t("constants.survivorship_rules.aggregate"),
      i18n.t("constants.survivorship_rules.source_priority"),
    ],
    dataClassOptions: [
      i18n.t("constants.data_class_options.persona_traits"),
      i18n.t("constants.data_class_options.dependent_traits"),
      i18n.t("constants.data_class_options.scores"),
      i18n.t("constants.data_class_options.enriched_traits"),
    ],
    traitTypeOptions: [
      i18n.t("constants.trait_type_options.custom_trait"),
      i18n.t("constants.trait_type_options.computed_trait"),
    ],
    traitInputTypeOptions: [
      i18n.t("constants.trait_input_type_options.freetext_textbox"),
      i18n.t("constants.trait_input_type_options.dropdown"),
      i18n.t("constants.trait_input_type_options.checkboxes"),
      i18n.t("constants.trait_input_type_options.number_field"),
      i18n.t("constants.trait_input_type_options.radio_buttons"),
      i18n.t("constants.trait_input_type_options.collected_offline"),
      i18n.t("constants.trait_input_type_options.purchased_data"),
      i18n.t("constants.trait_input_type_options.other"),
    ],
    frequencyOfDataChange: [
      i18n.t("constants.frequency_of_data_change_options.daily"),
      i18n.t("constants.frequency_of_data_change_options.more_than_once_week"),
      i18n.t("constants.frequency_of_data_change_options.once_week"),
      i18n.t("constants.frequency_of_data_change_options.more_than_once_month"),
      i18n.t("constants.frequency_of_data_change_options.once_month"),
      i18n.t("constants.frequency_of_data_change_options.more_than_once_year"),
      i18n.t("constants.frequency_of_data_change_options.once_year_less"),
    ],
    traitsLimitErrorMessage: i18n.t("constants.traits_limit_error_message"),
    personalDataTooltip: i18n.t("constants.personal_data_tooltip"),
    sensitiveDataTooltip: i18n.t("constants.sensitive_data_tooltip"),
    healthDataTooltip: i18n.t("constants.health_data_tooltip"),
    traitCreationHeadings: {
      1: i18n.t("create_trait.headings.step_1"),
      2: i18n.t("create_trait.headings.step_1"),
      3: i18n.t("create_trait.headings.step_3"),
    },
    requestRevisionHeadings: {
      1: i18n.t("revise_request.headings.step_1"),
      2: i18n.t("revise_request.headings.step_1"),
      3: i18n.t("revise_request.headings.step_3"),
    },
    traitCreationMessages: {
      1: i18n.t("create_trait.steps.step_1"),
      2: i18n.t("common.texts.marketing_program_and_ecosystem_traits_map"),
      3: i18n.t("create_trait.steps.step_3"),
    },
    requestRevisionMessages: {
      1: i18n.t("revise_request.steps.step_1"),
      2: i18n.t("common.texts.marketing_program_and_ecosystem_traits_map"),
      3: i18n.t("revise_request.steps.step_3"),
      4: i18n.t("revise_request.steps.step_3"),
    },
    traitFooterText: {
      1: i18n.t("common.labels.proceed_to_mapping"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
    characterLimits: {
      traitName: 100,
      traitDescription: 500,
      exampleValues: 100,
      ecosystemTraitName: 300,
      ecosystemTraitType: 50,
      ecosystemTraitMiscProperty1: 300,
    },
  },
  survivorshipRuleMapping: {
    [i18n.t("constants.survivorship_rules.most_recent")]: "Most Recent",
    [i18n.t("constants.survivorship_rules.first")]: "First",
    [i18n.t("constants.survivorship_rules.aggregate")]: "Aggregate",
    [i18n.t("constants.survivorship_rules.source_priority")]: "Source Priority",
  },
  reuseTraitsTableColumns: [
    {
      field: "traitName",
      sortable: false,
      headerName: i18n.t("common.labels.trait_name"),
      flex: 2,
    },
    {
      field: "marketingProgram",
      sortable: false,
      headerName: i18n.t("common.labels.marketing_program"),
    },
    {
      field: "ecosystem",
      sortable: false,
      headerName: i18n.t("common.labels.ecosystem"),
    },
    {
      field: "ecosystemTraitName",
      sortable: false,
      headerName: i18n.t("common.labels.ecosystem_trait_name"),
    },
    {
      field: "survivorshipRule",
      sortable: false,
      headerName: i18n.t("traits_container.survivorshipRule"),
    },
    {
      field: "status",
      sortable: false,
      headerName: i18n.t("common.labels.status"),
    },
    {
      field: "message",
      sortable: false,
      headerName: i18n.t("common.labels.message"),
      flex: 2,
    },
  ],
  traitsUsecaseTableColumns: [
    {
      field: "useCaseId",
      sortable: false,
      headerName: i18n.t("manage_use_cases.table_columns.use_case_id"),
      flex: 0.5,
      disableToggle: true,
    },
    {
      field: "useCaseName",
      sortable: false,
      flex: 1,
      headerName: i18n.t("common.labels.use_case_name"),
    },
    {
      field: "useCaseDescription",
      sortable: false,
      flex: 1,
      headerName: i18n.t("manage_use_cases.table_columns.use_case_description"),
    },
    {
      field: "traits",
      headerName: i18n.t("manage_use_cases.table_columns.related_traits"),
      truncate: true,
      sortable: false,
      clickable: true,
      flex: 1,
      parseData: (row) => row.traits.map((x) => x.traitName),
      clickableCellData: {
        params: ["useCaseId"],
        api: getUseCaseDetails,
      },
      clickableCellExternalParams: [`&module=traits`],
    },
    {
      field: "eventProperties",
      headerName: i18n.t(
        "manage_use_cases.table_columns.related_event_properties"
      ),
      sortable: false,
      truncate: true,
      clickable: true,
      parseData: (row) =>
        row.eventProperties.map(({ eventPropertyName }) => eventPropertyName),
      clickableCellData: {
        params: ["useCaseId"],
        api: getUseCaseDetails,
      },
      clickableCellExternalParams: [`&module=events`],
    },
  ],
  traitsResponsesTableColumns: [
    {
      field: "traitId",
      headerName: i18n.t("common.labels.trait_id"),
      flex: 0.4,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "traitName",
      headerName: i18n.t("common.labels.trait_name"),
      sortable: false,
      flex: 0.6,
    },
    {
      field: "traitDescription",
      headerName: i18n.t("common.labels.trait_description"),
      sortable: false,
      flex: 1,
    },
    {
      field: "traitResponses",
      headerName: i18n.t("common.labels.trait_responses"),
      truncate: true,
      sortable: false,
      clickable: true,
      flex: 1,
      parseData: (row) =>
        row.traitResponses.map((x) => x.predefinedTraitResponse),
      clickableCellData: {
        params: ["traitId"],
        api: getTraitsResponse,
        handle404: true,
      },
    },
  ],
  traitsTableColumns: [
    {
      field: "traitId",
      headerName: i18n.t("common.labels.trait_id"),
      flex: 0.5,
      sortable: false,
      clickable: true,
      clickableCellData: {
        params: ["traitId"],
        api: getTrait,
      },
      disableToggle: true,
    },
    {
      field: "traitName",
      headerName: i18n.t("common.labels.trait_name"),
      sortable: false,
      truncate: true,
    },
    {
      field: "description",
      headerName: i18n.t("common.labels.description"),
      sortable: false,
      truncate: true,
      parseData: (row) => row?.description?.trim(),
    },
    {
      field: "dataType",
      headerName: i18n.t("common.labels.data_type"),
      flex: 0.5,
      sortable: false,
    },
    {
      field: "personalData",
      headerName: i18n.t("common.labels.personal_data"),
      flex: 0.5,
      sortable: false,
      parseData: (row) =>
        row.personalData ? i18n.t("common.yes") : i18n.t("common.no"),
    },
    {
      field: "healthData",
      headerName: i18n.t("common.labels.health_data"),
      flex: 0.5,
      sortable: false,
      parseData: (row) =>
        row.healthData ? i18n.t("common.yes") : i18n.t("common.no"),
    },
  ],
  insightTableColumns: [
    {
      field: "insightsId",
      headerName: i18n.t("common.labels.insight_id"),
      flex: 0.5,
      sortable: false,
      clickable: true,
      clickableCellData: {
        params: ["insightsId"],
        api: getInsight,
      },
      disableToggle: true,
    },
    {
      field: "insightsName",
      headerName: i18n.t("common.labels.insight_name"),
      sortable: false,
      truncate: true,
    },
    {
      field: "description",
      headerName: i18n.t("common.labels.insight_description"),
      sortable: false,
      truncate: true,
    },
    {
      field: "outputTrait",
      headerName: i18n.t("common.labels.output_trait"),
      sortable: false,
      truncate: true,
    },
  ],
  traitsSearchDefaultFilterState: {
    traitId: false,
    requestId: false,
    traitName: false,
    traitDescription: false,
    marketingProgram: false,
    requester: false,
  },
  insightsSearchDefaultFilterState: {
    insightsId: false,
    insightsName: false,
    description: false,
    insightOutputTrait: false,
  },
  useCaseSearchDefaultFilterState: {
    useCaseDescription: false,
    useCaseName: false,
    traitName: false,
    eventProperty: false,
    marketingProgram: false,
  },
  traitsResponsesSearchDefaultFilterState: {
    traitId: false,
    traitResponseEnglish: false,
    traitResponseLocalLanguage: false,
    traitName: false,
    marketingProgram: false,
  },
  personalTraitErrorMessage: (title) => {
    if (["dataLake", "bigQuery"].includes(title)) {
      return `${title} ecosystem is automatically created.`;
    }
    if (title === "CPC") {
      return `${title} ecosystem is automatically created. For CPC changes, please contact your respective regional Data Managers.`;
    }
    if (title === "CDP2.0") {
      return `${title} ecosystem will be automatically selected if you select Segment. You can separately select CDP2.0 too.`;
    }
    return i18n.t("traits_container.personal_data_error_message", { title });
  },
  optsFeatureKeys: [
    {
      id: "ciamOptId",
      name: "Opt Id",
    },
    {
      id: "contactPointType",
      name: "Contact Point Type",
    },
    {
      id: "optTextEnglish",
      name: "Opt Text English",
    },
    {
      id: "serviceName",
      name: "Service Name",
    },
    {
      id: "subscriptionOptNumber",
      name: "Subscription Opt Number",
    },
  ],
  insightMappingsKeys: [
    {
      id: "marketingProgram",
      name: i18n.t("common.labels.marketing_program"),
    },
    {
      id: "status",
      name: i18n.t("common.labels.status"),
    },
    {
      id: "computeFrequency",
      name: i18n.t("common.labels.compute_frequency"),
    },
    {
      id: "processingType",
      name: i18n.t("common.labels.processing_type"),
    },
    {
      id: "sourceDetails",
      name: i18n.t("common.labels.source_details"),
    },
    {
      id: "destinationDetails",
      name: i18n.t("common.labels.destination_details"),
    },
  ],
  ecosystemFeatureKeys: [
    {
      id: "ecosystemTraitName",
      name: i18n.t("common.labels.ecosystem_trait_name"),
    },
    { id: "dataType", name: i18n.t("common.labels.data_type") },
    {
      id: "dataClass",
      name: i18n.t("common.labels.data_class"),
    },
    {
      id: "ecosystemTraitType",
      name: i18n.t("common.labels.ecosystem_trait_type"),
    },
    {
      id: "ecosystemTraitMiscProperty",
      name: i18n.t(
        "ecosystems.create_traits_keys.ecosystem_trait_misc_property"
      ),
    },
    {
      id: "personalDataException",
      name: i18n.t("ecosystems.personal_data_exception"),
    },
  ],
  traitsResponsesKeys: [
    {
      id: "predefinedTraitResponseLocalLanguage",
      name: i18n.t(
        "traitsResponses.accordion_keys.trait_response_local_language"
      ),
    },
    {
      id: "language",
      name: i18n.t("traitsResponses.accordion_keys.language"),
    },
    {
      id: "predefinedTraitResponse",
      name: i18n.t("traitsResponses.accordion_keys.trait_response_english"),
    },
    {
      id: "globalStandardResponse",
      name: i18n.t("traitsResponses.accordion_keys.predefined_trait_response"),
    },
    {
      id: "aliases",
      name: "Aliases",
    },
  ],
  globalTraitsResponsesKeys: [
    {
      id: "predefinedTraitResponse",
      name: i18n.t("traitsResponses.accordion_keys.predefined_trait_response"),
    },
    {
      id: "aliases",
      name: "Aliases",
    },
  ],
  relatedTraitsKeys: [
    {
      id: "traitId",
      name: i18n.t("common.labels.trait_id"),
    },
    {
      id: "dataType",
      name: i18n.t("common.labels.data_type"),
    },
    {
      id: "traitName",
      name: i18n.t("common.labels.trait_name"),
    },
    {
      id: "description",
      name: i18n.t("common.labels.description"),
    },
    {
      id: "personalData",
      name: i18n.t("common.labels.personal_data"),
    },
    {
      id: "labels",
      name: "Labels",
    },
  ],
};

export default traitsModuleConfig;
