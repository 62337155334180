import { Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

import useStyles from "./styles";

const ConsentIdRequestOutputModal = ({
  setShowOutput,
  isOpen,
  data,
  requestId,
  isLoading,
  marketingProgramDescription,
}) => {
  const { warnings, items } = data;
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      field: "consentId",
      headerName: t("common.labels.consent_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "serviceName",
      headerName: t("common.labels.service_name"),
      flex: 2,
      sortable: false,
      renderCell: (columnData) => {
        const { value } = columnData;
        if (value && value.type === "div") {
          return (
            <div className={clsx(classes.flexContainer, classes.container)}>
              {value}
              <Button variant="contained">{t("common.new")}</Button>
            </div>
          );
        }
        return value;
      },
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    let marketingProgram = `${warning.cdpMarketingProgramNumber} - ${warning.marketingProgramDescription}`;
    if (index === 0) {
      marketingProgram = `${
        warning.consentId.split("_")[0]
      } - ${marketingProgramDescription}`;
    }
    return {
      id: `${warning.consentId}-${index}`,
      consentId: warning.consentId,
      optId: warning.consentId,
      serviceName:
        !warning.consentId && warning.new ? (
          <div>{warning.serviceName}</div>
        ) : (
          warning.serviceName
        ),
      status: "warning",
      message: warning.message,
      marketingProgram,
    };
  });

  rows.push(
    ...(items || []).map((item, index) => {
      let marketingProgram = "";
      if (item.cdpMarketingProgramNumber && item.cdpMarketingProgramNumber) {
        marketingProgram = `${item.cdpMarketingProgramNumber} - ${item.marketingProgramDescription}`;
      }
      if (item.marketingProgramDescription && item.consentId) {
        marketingProgram = `${item.consentId?.split("_")[0]} - ${
          item.marketingProgramDescription
        }`;
      }
      let status;
      let message;
      if (item.mappingWarning) {
        status = "warning";
        message = item.mappingWarning;
      } else if (
        index === 0 &&
        item.consentId &&
        item.new &&
        !item.cdpMarketingProgramNumber
      ) {
        status = "success";
        message = t("new_opt_id.opt_id_creation");
      } else if (!item.consentId && item.new) {
        status = "success";
        message = t("new_opt_id.service_name_creation");
        marketingProgram = `${items[index + 1].cdpMarketingProgramNumber} - ${
          items[index + 1].marketingProgramDescription
        }`;
      } else if (item.new) {
        status = "success";
        message = t("new_opt_id.opt_mapping_creation");
      } else {
        status = "warning";
      }
      return {
        id: `${item.consentId}-${index}`,
        consentId: item.consentId,
        optId: item.consentId,
        serviceName:
          !item.consentId && item.new ? (
            <div>{item.serviceName}</div>
          ) : (
            item.serviceName
          ),
        marketingProgram,
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.opt_id_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

ConsentIdRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

ConsentIdRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
        cdpMarketingProgramNumber: PropTypes.string,
        marketingProgramDescription: PropTypes.string,
      })
    ),
  }).isRequired,
  requestId: PropTypes.string,
  marketingProgramDescription: PropTypes.string.isRequired,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ConsentIdRequestOutputModal;
