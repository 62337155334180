import { makeStyles } from "@material-ui/core";

const useGlobalStyles = makeStyles((theme) => {
  return {
    container1400: {
      maxWidth: "1400px",
    },
    paper1150: {
      minWidth: "1150px",
    },
    paper1300: {
      minWidth: "1300px",
    },
    newBtnContainer: {
      cursor: "pointer",
      "& span.traitName": {
        color: theme.palette.blue.main,
      },
      "& .MuiButton-root": {
        width: "37px",
        height: "20px",
        backgroundColor: theme.palette.blue.main,
        color: theme.colorTheme.white,
        borderRadius: "10px",
        margin: "0px 10px",
      },
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    accordionHeader: {
      padding: "0 16px",
      "& .MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
      },
      "& .MuiAccordionSummary-content.Mui-expanded": {
        marginBottom: 0,
        marginTop: 0,
      },
    },
    footerContainer: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "& a.MuiButton-root:first-child": {
        border: `1px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.light,
      },
      "& button": {
        backgroundColor: theme.palette.primary.light,
      },
      "& button, & a": {
        margin: "0px !important",
        whiteSpace: "no-wrap",
      },
      "& .MuiList-root .MuiListItem-root": {
        display: "flex",
        flexDirection: "column",
      },
    },
    btn: {
      "&.MuiButton-outlinedPrimary": {
        color: `${theme.palette.blue.main} !important`,
        border: `1px solid ${theme.palette.blue.main}`,
      },
      "&.MuiButton-root": {
        backgroundColor: theme.palette.blue.main,
        color: theme.colorTheme.white,
      },
      "& svg": {
        color: theme.colorTheme.white,
      },
    },
    autoComplete: {
      "& .MuiAutocomplete-option:nth-child(even)": {
        backgroundColor: theme.palette.type === "dark" ? "red" : "yellow",
      },
    },
    table: {
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      borderCollapse: "collapse",
      width: "100%",
      overflow: "hidden",
      tableLayout: "fixed",

      "& td": {
        border: "1px solid #ddd",
        padding: "8px",
      },

      "& tr:nth-child(even)": {
        backgroundColor: theme.colorTheme.greyishWhite4,
      },
    },
    addTraitBtn: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      marginTop: "8px",
      "& svg": {
        marginRight: "4px",
        "& path": {
          fill: theme.palette.blue.main,
        },
      },
      "& .MuiButton-label": {
        color: theme.palette.blue.main,
        textTransform: "upperCase",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
    },
    text: {
      color: theme.colorTheme.black,
    },
    editIcon: {
      "& svg": {
        fill: theme.palette.blue.light,
      },
    },
    deleteIcon: {
      "& svg": {
        fill: theme.palette.error.light,
      },
    },
    indicator: {
      backgroundColor: theme.colorTheme.indicatorColor,
    },
    label: {
      fontSize: "14px",
      marginBottom: "5px",
      fontWeight: "400",
      color: theme.palette.static.darkGrey,
    },
  };
});

export default useGlobalStyles;
