import moment from "moment";

import getOptsDetails from "../api/get-opts-details";
import i18n from "../i18n/init";

const removeHtmlTagsAndTruncate = (html, maxLength) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const plainText = doc.body.textContent || "";
  if (plainText.length > maxLength) {
    return `${plainText.substring(0, maxLength)}...`;
  }
  return plainText;
};

const optsModuleConfig = {
  optSearchFilters: [
    {
      name: "optId",
      label: i18n.t("common.labels.opt_id"),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.marketing_program"),
    },
    {
      name: "serviceName",
      label: i18n.t("common.labels.service_name"),
    },
    {
      name: "optTraitName",
      label: i18n.t("opts_container.search_filter.opt_trait_name"),
    },
  ],
  optTraitSearchFilters: [
    {
      name: "traitId",
      label: "Trait Id",
    },
    {
      name: "traitName",
      label: "Trait Name",
    },
    {
      name: "description",
      label: "Trait Description",
    },
  ],
  optsTableColumns: [
    {
      field: "ciamOptId",
      sortable: false,
      headerName: i18n.t("common.labels.opt_id"),
      flex: 0.5,
      clickable: true,
      clickableCellData: {
        params: ["ciamOptId"],
        api: getOptsDetails,
      },
      disableToggle: true,
    },
    {
      field: "optTextEnglish",
      sortable: false,
      headerName: "Opt Text English",
      truncate: true,
      renderCell: (params) => {
        const plainText = removeHtmlTagsAndTruncate(params.value, 70);
        return plainText;
      },
    },
    {
      field: "optTextLocalLanguage",
      sortable: false,
      headerName: "Opt Text Local Language",
      truncate: true,
      renderCell: (params) => {
        const plainText = removeHtmlTagsAndTruncate(params.value, 70);
        return plainText;
      },
    },
    {
      field: "ciamMarketingProgram",
      sortable: false,
      headerName: i18n.t(
        "opts_container.table_columns.origin_marketing_program"
      ),
    },
    {
      field: "countryCode",
      sortable: false,
      headerName: "Country Code",
      flex: 0.6,
    },
    {
      field: "ciamOptIdStatus",
      sortable: false,
      headerName: "Opt ID Status",
      flex: 0.6,
    },
    {
      field: "optStartDate",
      sortable: false,
      headerName: "Opt Start Date",
      parseData: (row) =>
        row.optStartDate &&
        moment(row.optStartDate).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      field: "optEndDate",
      sortable: false,
      headerName: "Opt End Date",
      parseData: (row) =>
        row.optEndDate && moment(row.optEndDate).format("YYYY-MM-DD HH:mm:ss"),
    },
  ],
  optTraitsColumns: [
    {
      field: "traitId",
      sortable: false,
      headerName: "Trait Id",
      flex: 0.2,
      clickable: true,
      clickableCellData: {
        params: [
          "opts",
          "traitId",
          "traitName",
          "personalData",
          "description",
          "dataType",
        ],
        api: (opts, traitId, traitName, personalData, description, dataType) =>
          new Promise((resolve) => {
            resolve({
              opts,
              traitId,
              traitName,
              personalData,
              description,
              dataType,
            });
          }),
      },
      disableToggle: true,
    },
    {
      field: "traitName",
      sortable: false,
      headerName: "Trait Name",
      truncate: true,
    },
    {
      field: "description",
      sortable: false,
      headerName: "Description",
      truncate: true,
    },
    {
      field: "dataType",
      sortable: false,
      headerName: "Data Type",
      flex: 0.3,
      truncate: true,
    },
    {
      field: "personalData",
      sortable: false,
      headerName: "Personal Data",
      flex: 0.3,
      parseData: (row) => (row.personalData ? "true" : "false"),
    },
  ],
  optsSearchDefaultFilterState: {
    optId: false,
    serviceName: false,
    marketingProgram: false,
    optTraitName: false,
  },
  optsTraitSearchDefaultFilterState: {
    traitId: false,
    traitName: false,
    description: false,
  },
  categoryTypeMapping: {
    P: "Phone",
    E: "Email",
    A: "Postal",
  },
  contactPointTypeOptions: [
    {
      contact_point_type_code: "A",
      contact_point_type_name: "Postal Address",
      default_contact_point_category_name: "Residential Address",
    },
    {
      contact_point_type_code: "E",
      contact_point_type_name: "Email Address",
      default_contact_point_category_name: "Personal Email",
    },
    {
      contact_point_type_code: "P",
      contact_point_type_name: "Phone Number",
      default_contact_point_category_name: "Mobile Phone",
    },
    {
      contact_point_type_code: "R",
      contact_point_type_name: "Regulations",
      default_contact_point_category_name: "Privacy Statement",
    },
    {
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      default_contact_point_category_name: "Line",
    },
    {
      contact_point_type_code: "W",
      contact_point_type_name: "Website Pull",
      default_contact_point_category_name: "Pull channel non PII",
    },
  ],
  contactPointCategoryOptions: [
    {
      contact_point_category_code: "AB",
      description: "Business Address",
      contact_point_type_code: "A",
      contact_point_type_name: "Postal Address",
      contact_point_category_name: "Business Address",
    },
    {
      contact_point_category_code: "AP",
      description: "Alipay",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Alipay",
    },
    {
      contact_point_category_code: "AR",
      description: "Residential Address",
      contact_point_type_code: "A",
      contact_point_type_name: "Postal Address",
      contact_point_category_name: "Residential Address",
    },
    {
      contact_point_category_code: "CP",
      description: "Consumer Pull",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Consumer Pull",
    },
    {
      contact_point_category_code: "EB",
      description: "Business Email",
      contact_point_type_code: "E",
      contact_point_type_name: "Email Address",
      contact_point_category_name: "Business Email",
    },
    {
      contact_point_category_code: "EM",
      description: "Mobile Email",
      contact_point_type_code: "E",
      contact_point_type_name: "Email Address",
      contact_point_category_name: "Mobile Email",
    },
    {
      contact_point_category_code: "EP",
      description: "Personal Email",
      contact_point_type_code: "E",
      contact_point_type_name: "Email Address",
      contact_point_category_name: "Personal Email",
    },
    {
      contact_point_category_code: "GO",
      description: "Google",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Google",
    },
    {
      contact_point_category_code: "KK",
      description: "Kik Messenger",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Kik Messenger",
    },
    {
      contact_point_category_code: "LN",
      description: "Line",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Line",
    },
    {
      contact_point_category_code: "MS",
      description: "MSN",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "MSN",
    },
    {
      contact_point_category_code: "MV",
      description: "Mobile Voicemail",
      contact_point_type_code: "P",
      contact_point_type_name: "Phone Number",
      contact_point_category_name: "Mobile Voicemail",
    },
    {
      contact_point_category_code: "OT",
      description: "Other",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Other",
    },
    {
      contact_point_category_code: "PB",
      description: "Business Phone",
      contact_point_type_code: "P",
      contact_point_type_name: "Phone Number",
      contact_point_category_name: "Business Phone",
    },
    {
      contact_point_category_code: "PH",
      description: "Home Phone",
      contact_point_type_code: "P",
      contact_point_type_name: "Phone Number",
      contact_point_category_name: "Home Phone",
    },
    {
      contact_point_category_code: "PM",
      description: "Mobile Phone",
      contact_point_type_code: "P",
      contact_point_type_name: "Phone Number",
      contact_point_category_name: "Mobile Phone",
    },
    {
      contact_point_category_code: "PN",
      description: "Pull channel non PII",
      contact_point_type_code: "W",
      contact_point_type_name: "Website Pull",
      contact_point_category_name: "Pull channel non PII",
    },
    {
      contact_point_category_code: "PS",
      description: "Privacy Statement",
      contact_point_type_code: "R",
      contact_point_type_name: "Regulations",
      contact_point_category_name: "Privacy Statement",
    },
    {
      contact_point_category_code: "QQ",
      description: "QQ for China",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "QQ for China",
    },
    {
      contact_point_category_code: "SF",
      description: "Facebook",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Facebook",
    },
    {
      contact_point_category_code: "ST",
      description: "Twitter",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "Twitter",
    },
    {
      contact_point_category_code: "TM",
      description: "TMALL",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "TMALL",
    },
    {
      contact_point_category_code: "WA",
      description: "WhatsApp",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "WhatsApp",
    },
    {
      contact_point_category_code: "WC",
      description: "WE CHAT",
      contact_point_type_code: "S",
      contact_point_type_name: "Social Network",
      contact_point_category_name: "WE CHAT",
    },
  ],
  optsRequestsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "serviceName",
      label: i18n.t("common.labels.service_name"),
    },
  ],
  optsApprovalsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "serviceName",
      label: i18n.t("common.labels.service_name"),
    },
    {
      name: "requester",
      label: i18n.t("opts_approvals.search_filter.requester"),
    },
  ],
  requestStatus: {
    APPROVED: i18n.t("status.approved"),
    REJECTED: i18n.t("status.rejected"),
    PENDING: i18n.t("status.pending"),
    RETURNED: i18n.t("status.returned"),
    PROCESSING: i18n.t("status.processing"),
  },
  optRequestsConstants: {
    toolTipTextPending: i18n.t("traits_requests.tooltip.pending_status"),
    toolTipTextApproved: i18n.t("traits_requests.tooltip.approved_status"),
    toolTipTextReturned: i18n.t("traits_requests.tooltip.returned_status"),
    toolTipTextProcessing: i18n.t("traits_requests.tooltip.processing_status"),
  },
  createOptConstants: {
    optMappingCreationHeadings: {
      1: i18n.t("opts_mapping.headings.step_1"),
      2: i18n.t("opts_mapping.headings.step_2"),
      3: i18n.t("opts_mapping.headings.step_3"),
    },
    optMappingRevisionHeadings: {
      1: i18n.t("revise_opt_request.headings.step_1"),
      2: i18n.t("revise_opt_request.headings.step_2"),
    },
    optMappingCreationMessages: {
      1: i18n.t("opts_mapping.steps.step_1"),
      2: i18n.t("opts_mapping.steps.step_2"),
      3: i18n.t("common.texts.request_details"),
    },
    optMappingRevisionMessages: {
      1: i18n.t("revise_opt_request.steps.step_1"),
      2: i18n.t("revise_opt_request.steps.step_2"),
      3: i18n.t("revise_opt_request.steps.step_3"),
    },
    optFooterText: {
      1: i18n.t("common.labels.proceed_to_confirmation"),
      2: i18n.t("common.labels.confirm_and_submit"),
    },
    channels: [
      i18n.t("constants.channel_options.email_personal"),
      i18n.t("constants.channel_options.residential_address"),
      i18n.t("constants.channel_options.line"),
      i18n.t("constants.channel_options.pull_channel"),
    ],
    contact_point_category_channel_mapping: {
      [i18n.t("constants.channel_options.email_personal")]: "E",
      [i18n.t("constants.channel_options.residential_address")]: "A",
      [i18n.t("constants.channel_options.line")]: "S",
      [i18n.t("constants.channel_options.pull_channel")]: "W",
    },
    primary_indicator_options: [
      i18n.t("constants.primary_indicator_options.primary"),
      i18n.t("constants.primary_indicator_options.secondary"),
    ],
  },
  createOptTraitConstants: {
    optCreationHeadings: {
      1: i18n.t("opts_status_trait.headings.step_1"),
      2: i18n.t("opts_status_trait.headings.step_1"),
      3: i18n.t("opts_status_trait.headings.step_3"),
      4: i18n.t("opts_status_trait.headings.step_4"),
    },
    optRevisionHeadings: {
      1: i18n.t("revise_opts_status_trait.headings.step_1"),
      2: i18n.t("revise_opts_status_trait.headings.step_2"),
      3: i18n.t("revise_opts_status_trait.headings.step_3"),
      4: i18n.t("revise_opts_status_trait.headings.step_4"),
    },
    optCreationMessages: {
      1: i18n.t("opts_status_trait.steps.step_1"),
      2: i18n.t("opts_status_trait.steps.step_1"),
      3: i18n.t("opts_status_trait.steps.step_1"),
      4: i18n.t("common.texts.request_details"),
    },
    optRevisionMessages: {
      1: i18n.t("revise_opts_status_trait.steps.step_1"),
      2: i18n.t("revise_opts_status_trait.steps.step_1"),
      3: i18n.t("revise_opts_status_trait.steps.step_1"),
      4: i18n.t("revise_opts_status_trait.steps.step_1"),
    },
    optFooterText: {
      1: i18n.t("common.labels.proceed_to_mapping"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
  },
  createOptIdConstants: {
    optIdCreationHeadings: {
      1: i18n.t("new_opt_id.headings.step_1"),
      2: i18n.t("new_opt_id.headings.step_1"),
      3: i18n.t("new_opt_id.headings.step_3"),
      4: i18n.t("new_opt_id.headings.step_4"),
    },
    optIdRevisionHeadings: {
      1: i18n.t("revise_new_opt_request.headings.step_1"),
      2: i18n.t("revise_new_opt_request.headings.step_1"),
      3: i18n.t("revise_new_opt_request.headings.step_1"),
    },
    optIdCreationMessages: {
      1: i18n.t("new_opt_id.steps.step_1"),
      2: i18n.t("new_opt_id.steps.step_2"),
      3: i18n.t("new_opt_id.steps.step_3"),
      4: i18n.t("common.texts.request_details"),
    },
    optIdRevisionMessages: {
      1: i18n.t("new_opt_id.steps.step_1"),
      2: i18n.t("new_opt_id.steps.step_2"),
      3: i18n.t("new_opt_id.steps.step_3"),
      4: i18n.t("common.texts.request_details"),
    },
    optFooterText: {
      1: i18n.t("new_opt_id.footer.step_1"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
    channels: [
      i18n.t("constants.channel_options.email_personal"),
      i18n.t("constants.channel_options.residential_address"),
      i18n.t("constants.channel_options.line"),
      i18n.t("constants.channel_options.pull_channel"),
    ],
    contact_point_category_channel_mapping: {
      [i18n.t("constants.channel_options.email_personal")]: "E",
      [i18n.t("constants.channel_options.residential_address")]: "A",
      [i18n.t("constants.channel_options.line")]: "S",
      [i18n.t("constants.channel_options.pull_channel")]: "W",
    },
    primary_indicator_options: [
      i18n.t("constants.primary_indicator_options.primary"),
      i18n.t("constants.primary_indicator_options.secondary"),
    ],
    validOptTexts: [
      "email",
      "phone",
      "SMS",
      "postal",
      "address",
      "social",
      "LINE",
      "Facebook",
      "WhatsApp",
      "advertising",
      "online",
      "data collection",
      "age",
    ],
  },
};

export default optsModuleConfig;
