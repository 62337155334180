import {
  Container,
  Typography,
  Paper,
  Box,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import useStyles from "./styles";

const InputFlow = ({
  totalSteps,
  currentStep,
  steps,
  children,
  headerText,
  footer,
  className,
}) => {
  const classes = useStyles();
  const isFinalStep = currentStep > totalSteps;

  return (
    <>
      <Container
        maxWidth="xl"
        className={clsx(
          classes.container,
          isFinalStep && classes.container1400,
          className
        )}
      >
        <div className={classes.flexContainer}>
          <Typography variant="h4" className="heading">
            {headerText}
          </Typography>
          <Box sx={{ width: "60%" }}>
            <Stepper
              activeStep={currentStep - 1}
              classes={{
                horizontal: classes.step,
                root: classes.paperColor,
              }}
            >
              {steps?.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
        <div data-testid="input-flow">
          <Paper
            elevation={1}
            classes={{
              root: clsx(
                classes.paper,
                isFinalStep && classes.paperHeight,
                classes.paperColor
              ),
            }}
          >
            <Typography variant="h6" className="title heading">
              {steps[currentStep - 1]}
            </Typography>
            {children}
          </Paper>
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {footer}
          </div>
        </div>
      </Container>
    </>
  );
};

InputFlow.defaultProps = {
  footer: <></>,
  headerText: "",
  className: "",
};

InputFlow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  currentStep: PropTypes.number.isRequired,
  footer: PropTypes.node,
  // header: PropTypes.node,
  headerText: PropTypes.string,
  className: PropTypes.string,
  totalSteps: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  // loading: PropTypes.bool,
};

export default InputFlow;
