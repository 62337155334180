import i18next from "i18next";

import isEmpty from "../../../../utilities/isEmpty";

const validateConsentTemplate = (
  consentTemplateTypeValue,
  countryValue,
  languageValue
) => {
  const errs = {};
  if (isEmpty(consentTemplateTypeValue)) {
    errs.consentTemplateTypeError = i18next.t("errors.field_required");
  }
  if (isEmpty(languageValue)) {
    errs.languageError = i18next.t("errors.field_required");
  }
  if (isEmpty(countryValue)) {
    errs.countryError = i18next.t("errors.field_required");
  }
  return errs;
};

export default validateConsentTemplate;
