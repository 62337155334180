import updateQuillContent from "./updateQuillContext";

const createBackendPayload = (
  marketingProgram,
  consentTemplateType,
  optMappings,
  updatedConsentTexts,
  consentTemplates,
  consentMappingInfo,
  templateVariables
) => {
  const consentTexts = [];
  Object.keys(updatedConsentTexts).forEach((key) => {
    const template = consentTemplates.find(
      (temp) => temp.consentTemplateKey === key
    );
    if (template) {
      consentTexts.push({
        consentTemplateId: template.consentTemplateId,
        country: {
          countryCode: template.countryCode,
          description: template.country,
        },
        language: {
          languageCode: template.languageCode,
          language: template.language,
        },
        consentText: updateQuillContent(
          updatedConsentTexts[template.consentTemplateKey].consentText,
          templateVariables
        ),
        noticeText: updateQuillContent(
          updatedConsentTexts[template.consentTemplateKey].noticeText,
          templateVariables
        ),
      });
    }
  });

  let consent = {};

  if (consentMappingInfo?.consentDetails) {
    consent = {
      consentId: consentMappingInfo.consentDetails.consentId,
      consentVersionNumber: Number(
        consentMappingInfo.consentDetails.latestVersion
      ),
      isNew: false,
      isUpdated: (() => {
        return (
          consentMappingInfo?.consentVersions?.length !== consentTexts.length ||
          consentMappingInfo?.consentVersions?.find((consentVersion) => {
            const updatedVersion = consentTexts.find(
              (c) =>
                c.language.languageCode ===
                  consentVersion.language.split(" - ")?.[0] &&
                c.country.countryCode ===
                  consentVersion.country.split(" - ")?.[0]
            );
            return (
              updatedVersion.noticeText !== consentVersion.noticeText ||
              updatedVersion.consentText !== consentVersion.consentText
            );
          }) !== undefined
        );
      })(),
    };
  } else {
    consent = {
      consentId: `${marketingProgram.marketingProgramNumber}_${consentTemplateType.consentTemplateTypeId}`,
      consentVersionNumber: 1,
      isNew: true,
    };
  }

  const payload = {
    marketingProgram: {
      marketingProgramNumber:
        marketingProgram?.marketingProgramNumber ||
        consentMappingInfo?.consentDetails?.marketingProgram.split(" - ")[0],
      description:
        marketingProgram?.description ||
        consentMappingInfo?.consentDetails?.marketingProgram.split(" - ")[1],
    },
    consentTemplateType: {
      consentTemplateTypeId: consentTemplateType.consentTemplateTypeId,
      description: consentTemplateType.description,
    },
    marketingProgramOpts: optMappings.map((mapping) => {
      return {
        contactPointType: {
          contactPointTypeCode: mapping.channel.contact_point_type_code,
          contactPointTypeName: mapping.channel.contact_point_type_name,
        },
        contactPointCategory: {
          contactPointCategoryCode:
            mapping.contactPointCategoryCode.contact_point_category_code,
          description: mapping.contactPointCategoryCode.description,
        },
        service: {
          serviceName: mapping.serviceName,
        },
        primaryIndicator: mapping.primaryIndicator,
        subscriptionOptNumber: mapping.subscriptionOptNumber,
        marketingProgram: {
          marketingProgramNumber:
            mapping.cdpMarketingProgram.marketingProgramNumber,
          description: mapping.cdpMarketingProgram.description,
        },
      };
    }),
    consent: {
      ...consent,
      consentTexts,
    },
  };

  return payload;
};

export default createBackendPayload;
