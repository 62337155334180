import { useState, useEffect, useCallback, useMemo } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
  Box,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Close } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import createMarketingProgram from "../../../api/create-marketing-program";

import getReferenceData from "../../../api/get-reference-data";
import Form from "../../../components/Form";
import InlineMessage from "../../../components/InlineMessage";

import applicationConfig from "../../../config/applicationConfig";
import marketingProgramsModuleConfig from "../../../config/marketingProgramsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";

import EditableText from "./components/EditableText";
import getFields from "./getFields";
import createBackendPayload from "./helpers/createBackendPayload";
import validateNewMarketingProgram from "./helpers/validateNewMarketingProgram";
import useStyles from "./styles";

const CreateMarketingProgramsContainer = ({
  open,
  handleClose,
  handleSubmit,
  marketingProgramData,
  isDisabled,
}) => {
  const classes = useStyles();
  const { user } = useUserProfile();
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createMarketingPrograms
  );
  const history = useHistory();
  const location = useLocation();
  const { request } = useRequest();
  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();
  const { createMarketingProgramConstants } = marketingProgramsModuleConfig;
  const { addNotification } = useNotifier();

  const initialNewMarketingProgramData = {
    legalEntity: "",
    brandName: "",
    brandShortName: "",
    marketingProgramName: "",
    businessOrgUnit: "",
    marketingProgramDescription: "",
    marketingProgramBusinessName: "",
    startingEcosystems: [],
    startingChannels: [],
  };

  const [newMarketingProgram, setNewMarketingProgram] = useImmer(
    initialNewMarketingProgramData
  );

  const [legalEntityValue, setLegalEntityValue] = useState({});

  const { t } = useTranslation();

  const [validationErrors, setValidationErrors] = useState([]);
  const [legalEntities, setLegalEntities] = useState([]);
  const [ecosystems, setEcosystems] = useState([]);
  const [legalEntitiesLoading, setLegalEntitiesLoading] = useState(false);
  const [newMpLoading, setNewMpLoading] = useState(false);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (validationErrors.length > 0) {
      setErrors(
        validateNewMarketingProgram(
          newMarketingProgram,
          false,
          validationErrors
        )
      );
    }
  }, [newMarketingProgram, validationErrors]);

  useEffect(() => {
    if (marketingProgramData) {
      setNewMarketingProgram({
        ...marketingProgramData,
        editablemarketingProgramBusinessName:
          marketingProgramData.marketingProgramBusinessName,
      });
      setLegalEntityValue({
        title: marketingProgramData?.legalEntity?.legalEntityName,
      });
    }
  }, [marketingProgramData]);

  const isRequestRevisionFlow = useMemo(
    () => Object.keys(request?.revisionMp || {}).length !== 0,
    [request?.revisionMp]
  );

  const getLegalEntitiesFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
      };
    }
    const filterFieldMap = {
      searchText: "legalEntityName",
    };
    try {
      const rsp1 = await getReferenceData(
        applicationConfig.referenceDataQueries.legalEntities,
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap,
          1,
          3
        )
      );
      setLegalEntities(rsp1.legalEntities);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setLegalEntities([]);
        },
        addNotification,
      });
    } finally {
      setLegalEntitiesLoading(false);
    }
  }, []);

  const getEcosystemsFromApi = useCallback(async (name) => {
    const filter = { name };
    if (!name && name.length === 0) {
      delete filter.name;
    }
    try {
      const rsp1 = await getReferenceData(
        applicationConfig.referenceDataQueries.ecoSystems
      );
      setEcosystems(rsp1.ecoSystems);
      return rsp1.ecoSystems;
    } catch (error) {
      handleError({
        error,
        handle404: true,
        addNotification,
      });
    }

    return [];
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedLegalEntitiesFromApi = debounce(
    getLegalEntitiesFromApi,
    applicationConfig.waitTime
  );

  const memoizedLegalEntitiesFromApi = useCallback((val, legalEntity) => {
    debouncedLegalEntitiesFromApi(val, legalEntity);
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized && !isDisabled) {
      increaseRequestsCount(2);
      await getLegalEntitiesFromApi("");
      await getEcosystemsFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  useEffect(() => {
    if (
      !isDisabled &&
      newMarketingProgram.brandShortName &&
      newMarketingProgram?.legalEntity?.legalEntityShortName
    ) {
      const marketingProgramBusinessNameVar = `${newMarketingProgram.brandShortName}-${newMarketingProgram.legalEntity.legalEntityShortName}-1`;
      setNewMarketingProgram((draft) => {
        draft.marketingProgramName = `${newMarketingProgram.brandShortName}-${newMarketingProgram.legalEntity.legalEntityShortName}-1`;
        draft.marketingProgramDescription = `${newMarketingProgram.legalEntity.legalEntityShortName} ${newMarketingProgram.brandName}`;
        draft.editablemarketingProgramBusinessName =
          marketingProgramBusinessNameVar;
        draft.marketingProgramBusinessName = marketingProgramBusinessNameVar;
      });
    }
  }, [
    newMarketingProgram.brandShortName,
    newMarketingProgram.brandName,
    newMarketingProgram?.legalEntity?.legalEntityShortName,
  ]);
  useEffect(() => {
    if (
      location.pathname ===
      applicationConfig.pathnames.marketing_program_map_revision
    ) {
      if (Object.keys(request).length === 0) {
        history.goBack();
      }
    }
  }, []);

  useEffect(() => {
    (() => {
      if (isRequestRevisionFlow) {
        setNewMarketingProgram(request?.revisionMp);
        setLegalEntityValue({
          ...request?.revisionMp?.legalEntity,
          title: request?.revisionMp?.legalEntity?.legalOrg,
        });
      }
    })();
  }, [isRequestRevisionFlow, request?.revisionMp, setNewMarketingProgram]);

  const resetValidationError = (param) => {
    if (validationErrors && validationErrors.length > 0) {
      setValidationErrors(
        validationErrors.filter((error) => {
          return error.param !== param;
        })
      );
    }
  };

  const resetError = (param) => {
    setErrors({
      ...errors,
      [param]: null,
    });
  };

  const reset = () => {
    setValidationErrors([]);
    setErrors({});
    setLegalEntityValue({});
    setNewMarketingProgram((draft) => {
      Object.keys(draft).forEach((key) => {
        draft[key] = initialNewMarketingProgramData?.[key];
      });
    });
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  let newMarketingProgramFields = getFields(newMarketingProgram);
  newMarketingProgramFields = newMarketingProgramFields
    .map((field) => {
      if (field.label === t("common.labels.legal_entity")) {
        return {
          type: "custom",
          element: Autocomplete,
          label: t("common.labels.legal_entity"),
          required: true,
          props: {
            id: "combo-box-demo",
            placeholder: t(
              "marketing_programs_container.placeholders.legal_entity"
            ),
            options: legalEntities.map((option) => ({
              ...option,
              title: option.legalOrg,
            })),
            loading: legalEntitiesLoading,
            getOptionLabel: (option) => option.title || "",
            onChange: async (event, value) => {
              setLegalEntityValue(value);
              setNewMarketingProgram((draft) => {
                draft.legalEntity = value;
              });
            },
            disabled: isDisabled,
            value: legalEntityValue,
            renderInput: (params) => (
              <>
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t(
                    "marketing_programs_container.placeholders.legal_entity"
                  )}
                  onChange={(event) => {
                    setLegalEntitiesLoading(true);
                    setLegalEntities([]);
                    memoizedLegalEntitiesFromApi(event.target.value);
                  }}
                />
                {errors.legalEntityError && (
                  <InlineMessage
                    message={errors.legalEntityError}
                    state="error"
                  />
                )}
              </>
            ),
          },
        };
      }
      if (field.label === t("common.labels.brand_program_name")) {
        return {
          label: t("common.labels.brand_program_name"),
          type: "text-input",
          required: true,
          flex: 2,
          props: {
            onChange: async (event) => {
              setNewMarketingProgram((draft) => {
                draft.brandName = event.target.value;
              });
              resetError("brandNameError");
            },
            placeholder: t("common.labels.brand_program_name"),
            error: Boolean(errors.brandNameError),
            variant: "outlined",
            value: newMarketingProgram.brandName,
            helperText: (
              <InlineMessage message={errors.brandNameError} state="error" />
            ),
            disabled: isDisabled,
            inputProps: {
              "data-testid": "brand-name-input",
            },
          },
        };
      }
      if (field.label === t("common.labels.brand_short_name")) {
        return {
          label: t("common.labels.brand_short_name"),
          type: "text-input",
          flex: 2,
          required: true,
          props: {
            onChange: async (event) => {
              setNewMarketingProgram((draft) => {
                draft.brandShortName = event.target.value;
              });
              resetError("brandShortNameError");
            },
            placeholder: t("common.labels.brand_short_name"),
            error: Boolean(errors.brandShortNameError),
            variant: "outlined",
            value: newMarketingProgram.brandShortName,
            helperText: (
              <InlineMessage
                message={errors.brandShortNameError}
                state="error"
              />
            ),
            disabled: isDisabled,
            inputProps: {
              "data-testid": "brand-short-name-input",
            },
          },
        };
      }

      if (newMarketingProgram.marketingProgramName) {
        if (field.label === t("common.labels.marketing_program_name")) {
          return {
            ...field,
            type: "custom",
            element: EditableText,
            required: true,
            props: {
              label:
                newMarketingProgram.marketingProgramName === ""
                  ? t("common.labels.marketing_program_name")
                  : "",
              placeholder: t("common.labels.marketing_program_name"),
              error: Boolean(errors.marketingProgramNameError),
              helperText: (
                <InlineMessage
                  message={errors.marketingProgramNameError}
                  state="error"
                />
              ),
              onChange: (value) => {
                setNewMarketingProgram((draft) => {
                  draft.marketingProgramName = value;
                });
                if (
                  validationErrors.length > 0 &&
                  errors.marketingProgramNameError
                ) {
                  resetValidationError("marketingProgram.marketingProgramName");
                }
              },
              value: newMarketingProgram.marketingProgramName,
              onCancel: () => {
                setNewMarketingProgram((draft) => {
                  draft.marketingProgramName =
                    newMarketingProgram.marketingProgramName;
                });
              },
              isDisabled: true,
              disabled: isDisabled,
            },
          };
        }
      }
      if (newMarketingProgram.marketingProgramDescription) {
        if (field.label === t("common.labels.marketing_program_description")) {
          return {
            ...field,
            type: "custom",
            element: EditableText,
            required: true,
            props: {
              label:
                newMarketingProgram.marketingProgramDescription === ""
                  ? t("common.labels.marketing_program_description")
                  : "",
              placeholder: t("common.labels.marketing_program_description"),
              error: Boolean(errors.marketingProgramDescriptionError),
              helperText: (
                <InlineMessage
                  message={errors.marketingProgramDescriptionError}
                  state="error"
                />
              ),
              onChange: (value) => {
                setNewMarketingProgram((draft) => {
                  draft.marketingProgramDescription = value;
                });
                if (
                  validationErrors.length > 0 &&
                  errors.marketingProgramDescriptionError
                ) {
                  resetValidationError("marketingProgram.description");
                }
              },
              value: newMarketingProgram.marketingProgramDescription,
              onCancel: () => {
                setNewMarketingProgram((draft) => {
                  draft.marketingProgramDescription =
                    newMarketingProgram.marketingProgramDescription;
                });
              },
              isDisabled: true,
              disabled: isDisabled,
            },
          };
        }
      }

      if (newMarketingProgram.marketingProgramBusinessName) {
        if (
          field.label === t("common.labels.marketing_program_business_name")
        ) {
          return {
            ...field,
            type: "custom",
            element: EditableText,
            required: true,
            props: {
              label:
                newMarketingProgram.marketingProgramBusinessName === ""
                  ? t("common.labels.marketing_program_business_name")
                  : "",
              placeholder: t("common.labels.marketing_program_business_name"),
              error: Boolean(errors.mpBusinessNameError),
              helperText: (
                <InlineMessage
                  message={errors.mpBusinessNameError}
                  state="error"
                />
              ),
              onChange: (value) => {
                setNewMarketingProgram((draft) => {
                  draft.editablemarketingProgramBusinessName = value;
                });
                if (validationErrors.length > 0 && errors.mpBusinessNameError) {
                  resetValidationError("marketingProgram.mpBusinessName");
                }
              },
              onOk: (currentValue) => {
                setNewMarketingProgram((draft) => {
                  draft.editablemarketingProgramBusinessName = currentValue;
                  draft.marketingProgramBusinessName = currentValue;
                });
              },
              value: newMarketingProgram.editablemarketingProgramBusinessName,
              onCancel: (oldValue) => {
                setNewMarketingProgram((draft) => {
                  draft.editablemarketingProgramBusinessName = oldValue;
                  draft.marketingProgramBusinessName = oldValue;
                });
              },
              isDisabled,
              disabled: isDisabled,
            },
          };
        }
      }

      if (field.label === t("common.labels.starting_ecosystems")) {
        return {
          ...field,
          required: true,
          props: {
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
            placeholder: t("common.labels.starting_ecosystems"),
            error: Boolean(errors.startingEcosystemsError),
            helperText: (
              <InlineMessage
                message={errors.startingEcosystemsError}
                state="error"
              />
            ),
            variant: "outlined",
            multiple: true,
            onChange: (event) => {
              setNewMarketingProgram((draft) => {
                draft.startingEcosystems = event.target.value;
              });
              resetError("startingEcosystemsError");
            },
            renderValue: (selected) => selected.join(", "),
            values: (ecosystems || []).map(
              (ecosystem) => ecosystem.ecoSystemName
            ),
            value: newMarketingProgram.startingEcosystems || [],
            "data-testid": "starting-ecosystems",
            disabled: isDisabled,
          },
        };
      }
      if (field.label === t("common.labels.starting_channels")) {
        return {
          ...field,
          required: true,
          props: {
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
            placeholder: t("common.labels.starting_channels"),
            error: Boolean(errors.startingChannelsError),
            helperText: (
              <InlineMessage
                message={errors.startingChannelsError}
                state="error"
              />
            ),
            variant: "outlined",
            multiple: true,
            onChange: (event) => {
              setNewMarketingProgram((draft) => {
                draft.startingChannels = event.target.value;
              });
              resetError("startingChannelsError");
            },
            renderValue: (selected) => selected.join(", "),
            values:
              createMarketingProgramConstants.channels.contactPointTypeOptions.map(
                (channel) => channel.contact_point_type_name
              ),
            value: newMarketingProgram.startingChannels || [],
            "data-testid": "starting-channels",
            disabled: isDisabled,
          },
        };
      }
      if (field.label === t("common.labels.business_org_unit")) {
        return {
          ...field,
          required: true,
          props: {
            ...field.props,
            select: true,
            placeholder: t("common.labels.business_org_unit"),
            error: Boolean(errors.businessOrgUnitError),
            helperText: (
              <InlineMessage
                message={errors.businessOrgUnitError}
                state="error"
              />
            ),
            variant: "outlined",
            onChange: (event) => {
              setNewMarketingProgram((draft) => {
                draft.businessOrgUnit = event.target.value;
              });
              resetError("businessOrgUnitError");
            },
            values: createMarketingProgramConstants.businessOrgUnits.map(
              (option) => ({
                label: option,
                value: option,
              })
            ),
            value: newMarketingProgram.businessOrgUnit || [],
            "data-testid": "business-org-units",
            disabled: isDisabled,
          },
        };
      }
      return {};
    })
    .filter((field) => {
      return Object.keys(field).length > 0;
    });

  const renderStep = () => {
    return (
      <div
        className={classes.container}
        data-testid="new-marketing-program-container"
      >
        <Form
          fields={newMarketingProgramFields}
          fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
        />
        <div className={classes.horizontalBar} />
      </div>
    );
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.MuiPaper,
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h4">
            {isRequestRevisionFlow
              ? createMarketingProgramConstants.revisionHeading
              : createMarketingProgramConstants.creationHeading}
          </Typography>
          <div
            className={clsx(classes.marginLeftAuto, classes.closeBtn)}
            onClick={() => {
              handleClose();
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        <div className={classes.horizontalBar} />
        <DialogContent>
          <div className={clsx(classes.newMarketingProgramsContainer)}>
            {renderStep()}
          </div>
        </DialogContent>
        <DialogActions>
          {!isDisabled && (
            <div className={clsx(classes.btnContainer)}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const errs = validateNewMarketingProgram(
                    newMarketingProgram,
                    false,
                    []
                  );
                  if (!Object.values(errs).every((error) => error === null)) {
                    setErrors(errs);
                    return;
                  }
                  try {
                    const payload = createBackendPayload(
                      newMarketingProgram,
                      ecosystems,
                      createMarketingProgramConstants.channels
                        .contactPointTypeOptions
                    );
                    setNewMpLoading(true);
                    await createMarketingProgram(payload, true);
                    handleSubmit(newMarketingProgram);
                  } catch (error) {
                    if (error?.data?.error?.errors) {
                      setValidationErrors(error.data.error.errors);
                    } else {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    }
                  } finally {
                    setNewMpLoading(false);
                  }
                }}
              >
                {newMpLoading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} color="white" />
                  </Box>
                )}
                {t("create_marketing_program.submit")}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
      {/* {showOutput && (
        <MarketingProgramRequestOutputModal
          isOpen={showOutput}
          isLoading={outputTableLoading}
          requestId={output.requestId}
          data={output.output}
          setShowOutput={() => setShowOutput(false)}
        />
      )} */}
      {/* <Prompt
        message={() => {
          return t("prompt.progress_lost");
        }}
        when={
          isInfoStep || isRequestRevisionFlow
            ? false
            : !checkDisabledStatus(currentStep)
        }
      /> */}
    </>
  );
};

CreateMarketingProgramsContainer.defaultProps = {
  isDisabled: false,
};

CreateMarketingProgramsContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  marketingProgramData: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default CreateMarketingProgramsContainer;
