import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getSourceDetails = async (sourceId) => {
  const refreshCache = getValue("sources");
  const url = `/data-sources/${sourceId}?refreshCache=${refreshCache}`;
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getSourceDetails;
