import applicationConfig from "../../../../config/applicationConfig";
import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateEqualStrings,
  validateMinimumCharactersCount,
  validateMinimumNonSpaceWords,
} from "../../../../utilities/formValidation";

const validateUseCaseInfo = (
  useCaseInfo,
  isDisabled,
  legalEntities,
  module
) => {
  const errors = {};
  if (isDisabled) {
    return errors;
  }
  // Required field checks
  if (isEmptyString(useCaseInfo.useCaseName)) {
    errors.useCaseNameError = i18n.t("errors.field_required");
  }
  if (isEmptyString(useCaseInfo.useCaseDescription)) {
    errors.useCaseDescriptionError = i18n.t("errors.field_required");
  }
  if (isEmptyString(module)) {
    errors.moduleError = i18n.t("errors.field_required");
  }
  // Individual field checks
  if (!isEmptyString(useCaseInfo.useCaseName)) {
    if (!validateMinimumCharactersCount(useCaseInfo.useCaseName, 3)) {
      errors.useCaseNameError = i18n.t(
        "create_use_case.errors.use_case_name_error"
      );
    }
  }
  if (
    useCaseInfo.useCaseName.toLowerCase().includes("golden traits") &&
    (legalEntities.some(
      (legalEntity) =>
        useCaseInfo.useCaseName
          .toLowerCase()
          .includes(legalEntity.legalEntityName?.toLowerCase()) ||
        useCaseInfo.useCaseName
          .toLowerCase()
          .includes(legalEntity.legalEntityShortName?.toLowerCase())
    ) ||
      applicationConfig.regionFilters.some((region) =>
        useCaseInfo.useCaseName
          .toLowerCase()
          .includes(region.name?.toLowerCase())
      ))
  ) {
    errors.useCaseNameError = i18n.t(
      "create_use_case.errors.use_case_name_scope_error"
    );
  }
  if (!isEmptyString(useCaseInfo.useCaseDescription)) {
    if (
      validateEqualStrings(
        useCaseInfo.useCaseName,
        useCaseInfo.useCaseDescription
      )
    ) {
      errors.useCaseDescriptionError = i18n.t(
        "create_use_case.errors.use_case_description.error_1"
      );
    } else if (
      !validateMinimumCharactersCount(useCaseInfo.useCaseDescription, 5)
    ) {
      errors.useCaseDescriptionError = i18n.t(
        "create_use_case.errors.use_case_description.error_2"
      );
    } else if (
      !validateMinimumNonSpaceWords(useCaseInfo.useCaseDescription, 3)
    ) {
      errors.useCaseDescriptionError = i18n.t(
        "create_use_case.errors.use_case_description.error_3"
      );
    }
  }
  return errors;
};

export default validateUseCaseInfo;
