import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "800px !important",
    height: "600px !important",
  },
  systems: {
    maxHeight: 1320,
    overflow: "scroll",
    width: "100%",
    paddingBottom: 16,
    maxWidth: "60vw",
  },
  accordion: {
    borderBottom: "none",
    borderRadius: 4,
    marginBottom: 16,
  },
  accordionHeader: {
    padding: "0 16px",
    "& .MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "center",
    },
    height: "67px",
  },
  jobInfo: {
    display: "flex",
    justifyContent: "space-between",
    "& p": {
      fontSize: "14px",
    },
  },
  systemName: {
    marginLeft: 16,
  },
  infoIcon: {
    width: 18,
    height: 18,
    marginLeft: "6px",
    position: "relative",
    top: "20%",
    cursor: "pointer",
  },
  label: {
    display: "flex",
    color: theme.colorTheme.greyishBlack,
  },
  labelValue: {
    marginLeft: "6px",
    color: theme.colorTheme.grey,
  },
  accordionContent: {
    borderTop: `1px solid ${theme.colorTheme.greyishWhite}`,
    margin: "0 !important",
    display: "flex",
    boxShadow: "0px 2px 3px #00000029",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    flexDirection: "column",
    "& button": {
      textTransform: "none",
    },
    "&.MuiAccordionDetails-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& table": {
      paddingTop: "10px !important",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    "& table tbody tr": {
      verticalAlign: "top",
    },
  },
  programName: {
    marginBottom: 10,
  },
  divider: {
    height: "20px",
    width: "2px",
    backgroundColor: theme.colorTheme.greyishWhite,
    margin: "0px 10px",
  },
  marginLeft: {
    marginLeft: "auto",
  },
  hover: {
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    marginTop: 18,
    padding: "0px 24px",
    marginBottom: "24px",
    justifyContent: "space-between",
    "& .MuiButton-containedPrimary:first-child": {
      backgroundColor: theme.colorTheme.white,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  retryJobDetailsContainer: {
    "& > div:first-child": {
      flex: "0 0 90%",
    },
    "& > div:last-child": {
      flex: "0 0 10%",
    },
    "& .MuiButton-root": {
      alignSelf: "center",
    },
  },
  jobDetailsContainer: {
    "& > div:first-child": {
      width: "100%",
      overflow: "hidden",
    },
  },
  detailsRow: {
    "& p:first-child": {
      flex: "0 0 35%",
    },
  },
  accordionDivider: {
    height: "1px",
    margin: "-2px",
    width: "100%",
    backgroundColor: theme.colorTheme.greyishWhite,
  },
  gridContainer1: {
    "& .MuiAccordionDetails-root": {
      paddingBottom: "10px !important",
      "& p.MuiTypography-root.MuiTypography-body2 div.statusWrapper": {
        width: "80px",
      },
    },
  },
  refreshBtn: {
    minWidth: "48px !important",
    padding: "4px 10px !important",
  },
}));

export default useStyles;
