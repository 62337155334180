function replaceValuesWithPlaceholders(
  updatedText,
  templateText,
  placeholders
) {
  let newText = updatedText;
  Object.keys(placeholders).forEach((key) => {
    if ([...templateText.matchAll(placeholders[key])].length === 0) {
      newText = newText.replaceAll(placeholders[key], `[${key}]`);
      return;
    }
    const matches = [
      ...templateText.matchAll(
        new RegExp(`(${placeholders[key]}|\\[${key}\\])`, "g")
      ),
    ];
    const replaceArray = matches.map((match) => match[0] === `[${key}]`);
    let idx = 0;
    newText = newText.replaceAll(placeholders[key], (match) => {
      if (replaceArray[idx]) {
        idx += 1;
        return `[${key}]`;
      }
      idx += 1;
      return match;
    });
  });
  return newText;
}

export default replaceValuesWithPlaceholders;
