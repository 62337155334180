import { useContext } from "react";

import { UserProfileContext } from "../providers/UserProfileProvider";

const useUserProfile = () => {
  const { user, removeUser, setUser } = useContext(UserProfileContext);
  return { user, removeUser, setUser };
};

export default useUserProfile;
