import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link, useHistory, useLocation } from "react-router-dom";

import createMarketingProgram from "../../../api/create-marketing-program";
import getMarketingProgramEcosystems from "../../../api/get-marketing-program-ecosystems";
import getMarketingPrograms from "../../../api/get-marketing-programs";
import getReferenceData from "../../../api/get-reference-data";
import getRequestDetails from "../../../api/get-request-details";
import updateRequest from "../../../api/update-request";
import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import TextBox from "../../../components/TextBox";
import applicationConfig from "../../../config/applicationConfig";
import marketingProgramsModuleConfig from "../../../config/marketingProgramsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import truncateString from "../../../utilities/truncateString";
import useImmer from "../../../utilities/useImmer";
import EcosystemMapping from "../NewMarketingProgramEcosystemMappingContainer/components/EcosystemMapping";
import EcosystemMappingRequestOutputModal from "../NewMarketingProgramEcosystemMappingContainer/components/EcosystemMappingRequestOutputModal";
import CreateMarketingProgramsContainerModal from "../NewMarketingProgramsContainer";
import MarketingProgramRequestOutputModal from "../NewMarketingProgramsContainer/components/MarketingProgramRequestOutputModal";
import createBackendPayload from "../NewMarketingProgramsContainer/helpers/createBackendPayload";

import useStyles from "./styles";

function createMp(draft, obj) {
  const newObject = JSON.parse(JSON.stringify(obj));
  newObject.new = true;
  draft.push(newObject);
}

function compareMpE(eco1, eco2) {
  return (
    eco1?.ecosystemId === eco2?.ecosystemId &&
    eco1.marketingProgramNumber === eco2.marketingProgramNumber &&
    eco1.ecosystemMarketingProgramName === eco2.ecosystemMarketingProgramName &&
    eco1.ecosystemMarketingProgramKey === eco2.ecosystemMarketingProgramKey &&
    eco1.ecosystemMarketingProgramInstance ===
      eco2.ecosystemMarketingProgramInstance &&
    eco1.automationEnabled === eco2.automationEnabled &&
    (eco1.segmentPersonaSpace || "") === (eco2.segmentPersonaSpace || "") &&
    (eco1.lyticsSecret || "") === (eco2.lyticsSecret || "")
  );
}

function updateMp(draft, object) {
  const deletedObjectIdx = draft.findIndex(
    (mpE) => mpE.ecosystemId === object.ecosystemId && mpE.deleted
  );
  const existingObjectIdx = draft.findIndex(
    (mpE) => mpE.ecosystemId === object.ecosystemId && !mpE.deleted
  );
  const updatedObject = JSON.parse(JSON.stringify(object));
  updatedObject.isUpdated = true;
  updatedObject.new = true;
  if (deletedObjectIdx >= 0) {
    if (compareMpE(draft[deletedObjectIdx], updatedObject)) {
      delete draft[deletedObjectIdx].deleted;
      delete draft[deletedObjectIdx].isUpdated;
      draft.splice(existingObjectIdx, 1);
    } else {
      draft[existingObjectIdx] = updatedObject;
    }
  } else if (draft[existingObjectIdx].new) {
    delete updatedObject.isUpdated;
    draft[existingObjectIdx] = updatedObject;
  } else if (!compareMpE(draft[existingObjectIdx], updatedObject)) {
    draft[existingObjectIdx].deleted = true;
    draft[existingObjectIdx].isUpdated = true;
    draft.splice(existingObjectIdx + 1, 0, updatedObject);
  }
}

function deleteMp(draft, object) {
  const index = draft.findIndex(
    (mpE) => mpE.ecosystemId === object.ecosystemId
  );
  draft.splice(index, 1);
  const deletedObject = JSON.parse(JSON.stringify(object));
  deletedObject.deleted = true;
  if (!object.new) {
    draft.push(deletedObject);
  }
}

function NewMarketingProgram() {
  const classes = useStyles();
  const { createMarketingProgramConstants, requestStatus } =
    marketingProgramsModuleConfig;
  const globalStyles = useGlobalStyles();
  const { user } = useUserProfile();
  const { request, setRequest } = useRequest();
  const isRequestRevisionFlow = Object.keys(request).length !== 0;
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const location = useLocation();
  const manageMp = location?.state?.mp;
  const isUserAuthorized = useMemo(() => {
    return checkUserAuthorization(
      user.access,
      pageAccessConfig.createMarketingPrograms
    );
  }, [user?.access]);
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [manageEcosystemDetails, setManageEcosystemDetails] = useState(false);
  const { t } = useTranslation();

  const history = useHistory();
  const { addNotification } = useNotifier();

  const totalSteps = 3;
  const [currentStep, setCurrentStep] = React.useState(1);

  useEffect(() => {
    if (manageMp) {
      setCurrentStep(2);
      setManageEcosystemDetails(true);
    }
  }, [manageMp]);

  const [errors, setErrors] = useState({});

  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(false);
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [initialMpOptions, setInitialMpOptions] = useState([]);
  const initialOptsLoadingRef = useRef(false);
  const [mpDropdownValue, setMpDropdownValue] = useState(manageMp || null);
  const [newMps, setNewMps] = useState([]);
  const newMpOptions = useMemo(
    () =>
      newMps.map((newMp) => ({
        description: newMp?.marketingProgramDescription,
        legalEntityName: newMp?.legalEntity?.legalEntityName,
        legalEntityNumber: newMp?.legalEntity?.legalEntityNumber,
        marketingProgramName: newMp?.marketingProgramName,
        marketingProgramNumber: null,
        title: newMp?.marketingProgramDescription,
        new: true,
      })),
    [newMps]
  );
  const [mpInputValue, setMpInputValue] = useState("");

  const [openCreateMpModal, setOpenCreateMpModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [initialStepLoading, setInitialStepLoading] = useState(false);
  const [showEcosystemMappingModal, setShowEcosystemMappingModal] =
    useState(false);

  const [initialMarketingEcosystems, setInitialMarketingEcosystems] = useState(
    []
  );
  const [marketingEcosystems, setMarketingEcosystems] = useImmer([]);
  const [ecosystems, setEcosystems] = useState([]);
  const renderList = useMemo(() => {
    return marketingEcosystems.filter((mpE) => !mpE.deleted);
  }, [marketingEcosystems]);
  const diffList = useMemo(() => {
    return marketingEcosystems.filter(
      (mpE) => mpE.deleted || mpE.new || mpE.isUpdated
    );
  }, [marketingEcosystems]);

  useEffect(() => {
    if (
      errors.noChangesError &&
      diffList.length > 0 &&
      manageEcosystemDetails
    ) {
      setErrors((prev) => ({ ...prev, noChangesError: null }));
    }
  }, [diffList, errors.noChangesError, manageEcosystemDetails]);

  const getMarketingProgramEcosystemsCallback = useCallback(async () => {
    if (!isRequestRevisionFlow) {
      setInitialMarketingEcosystems([]);
      setMarketingEcosystems((draft) => {
        draft.splice(0, draft.length);
      });
    }
    if (currentStep === 2 && mpDropdownValue?.marketingProgramNumber) {
      increaseRequestsCount();
      try {
        const resp = await getMarketingProgramEcosystems(
          mpDropdownValue?.marketingProgramNumber
        );
        if (!isRequestRevisionFlow) {
          setInitialMarketingEcosystems(resp.items);
          setMarketingEcosystems((draft) => {
            draft.splice(0, draft.length);
            draft.push(
              ...resp.items.map((item) => ({
                ...item,
                [item.ecosystemName === "lytics" && "lyticsSecret"]:
                  item.automationEnabled ? "Yes" : "No",
                [item.ecosystemName === "segment" && "segmentPersonaSpace"]:
                  item.automationEnabled ? "Yes" : "No",
              }))
            );
          });
        } else {
          setInitialMarketingEcosystems(resp.items);
          setMarketingEcosystems((draft) => {
            const prev = JSON.parse(JSON.stringify([...draft]));
            draft.splice(0, draft.length);
            draft.push(
              ...resp.items
                .filter(
                  (item) =>
                    !draft.find((i) => i.ecosystemId !== item.ecosystemId)
                )
                .map((item) => ({
                  ...item,
                  [item.ecosystemName === "lytics" && "lyticsSecret"]:
                    item.automationEnabled ? "Yes" : "No",
                  [item.ecosystemName === "segment" && "segmentPersonaSpace"]:
                    item.automationEnabled ? "Yes" : "No",
                }))
            );
            draft.push(...prev);
          });
        }
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      } finally {
        decreaseRequestsCount();
      }
    }
  }, [
    addNotification,
    currentStep,
    mpDropdownValue?.marketingProgramNumber,
    setMarketingEcosystems,
  ]);

  useEffect(() => {
    if (isRequestRevisionFlow) {
      if (request?.requestDetails?.marketingProgram) {
        const {
          marketingProgram,
          legalEntity,
          marketingProgramEcosystems,
          contactPointTypes,
        } = request.requestDetails;
        if (newMps.length === 0) {
          setNewMps([
            {
              legalEntity: {
                legalOrg: legalEntity?.legalEntityName,
                legalEntityId: legalEntity?.legalEntityNumber,
                legalEntityShortName: legalEntity?.legalEntityShortName,
              },
              brandName: marketingProgram.brandProgramName,
              brandShortName: marketingProgram.brandProgramShortName,
              marketingProgramName: marketingProgram.marketingProgramName,
              marketingProgramDescription: marketingProgram.description,
              title: marketingProgram.description,
              marketingProgramBusinessName: marketingProgram.mpBusinessName,
              businessOrgUnit: marketingProgram.businessOrgUnit,
              startingEcosystems: marketingProgramEcosystems?.map(
                (i) => i.ecosystemName
              ),
              startingChannels: contactPointTypes?.map(
                (i) => i.contactPointTypeName
              ),
            },
          ]);
          setMpDropdownValue({
            legalEntity,
            marketingProgramNumber: marketingProgram.marketingProgramNumber,
            description: marketingProgram.description,
            legalEntityName: legalEntity?.legalEntityName,
            legalEntityNumber: legalEntity?.legalEntityNumber,
            marketingProgramName: marketingProgram.marketingProgramName,
            title: `${
              marketingProgram.marketingProgramNumber
                ? `${marketingProgram.marketingProgramNumber} - `
                : ""
            }${marketingProgram.description}`,
            new: !!contactPointTypes,
          });
          const originalEcosystems =
            request?.originalRequestDetails?.ecosystems;
          if (originalEcosystems) {
            setMarketingEcosystems((draft) => {
              draft.push(
                ...request.requestDetails.ecosystems.reduce((acc, curr) => {
                  const originalEcosystem = originalEcosystems?.find(
                    (i) => i.ecosystemId === curr.ecosystemId
                  );
                  if (originalEcosystem) {
                    acc.push({
                      ...curr,
                      isUpdated: true,
                      new: true,
                    });
                    acc.push({
                      ...originalEcosystem,
                      deleted: true,
                      isUpdated: true,
                    });
                    return acc;
                  }
                  acc.push({
                    ...curr,
                    isUpdated: undefined,
                    new: true,
                  });
                  return acc;
                }, [])
              );
              originalEcosystems?.forEach((originalEcosystem) => {
                const exists = draft.find(
                  (i) => i.ecosystemId === originalEcosystem.ecosystemId
                );
                if (!exists) {
                  draft.push({
                    ...originalEcosystem,
                    deleted: true,
                    isUpdated: true,
                  });
                }
              });
            });
          } else {
            setMarketingEcosystems((draft) => {
              draft.push(...(request.requestDetails?.ecosystems || []));
            });
          }
        }
      }
    }
  }, [request]);

  const isRequestFlowMpEcosystemsLoadedRef = useRef(false);

  useEffect(() => {
    if (currentStep === 2 && !isRequestFlowMpEcosystemsLoadedRef.current) {
      getMarketingProgramEcosystemsCallback();
      isRequestFlowMpEcosystemsLoadedRef.current = true;
    }
  }, [currentStep]);

  const initialEcosystemState = {
    automationEnabled: false,
    ecosystemId: undefined,
    ecosystemMarketingProgramInstance: "",
    ecosystemMarketingProgramKey: "",
    ecosystemMarketingProgramName: "",
    marketingProgramNumber: undefined,
    showEnableAutomation: true,

    segmentPersonaSpace: "",
    lyticsSecret: "",
    index: -1,
    disable: false,
    ecosystemMpNameDisabled: true,
    ecosystemMpKeyDisabled: true,
    ecosystemInstanceDisabled: true,
  };
  const [ecosystemDetails, setEcosystemDetails] = useImmer(
    initialEcosystemState
  );
  const [showMapEcosystemsOutput, setShowMapEcosystemsOutput] = useState(false);
  const [mapEcosystemsOutput, setMapEcosystemsOutput] = useState({});
  const [marketingProgramDescription, setMarketingProgramDescription] =
    useState("");
  const [ecosystemsMappingResponse, setEcosystemsMappingResponse] = useState(
    []
  );

  const [showMarketingProgramOutput, setShowMarketingProgramOutput] =
    useState(false);
  const [marketingProgramOutput, setMarketingProgramOutput] = useState({});
  const [mpOutputTableLoading, setMpOutputTableLoading] = useState(false);
  const [marketingProgramMappingResponse, setMarketingProgramMappingResponse] =
    useState([]);

  const getMarketingProgramsFromApi = useCallback(
    async (searchText) => {
      setMarketingProgramsLoading(true);
      let filter = { itemsPerPage: 3, page: 1 };
      if (searchText && searchText.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      try {
        const rsp1 = await getMarketingPrograms(filter);
        setMarketingProgramOptions(rsp1.items);
        if (isEmpty(searchText)) {
          setInitialMpOptions(rsp1.items);
        }
      } catch (error) {
        handleError({
          error,
          handle404: () => {},
          addNotification,
        });
      } finally {
        setMarketingProgramsLoading(false);
      }
    },
    [addNotification]
  );

  const debouncedMarketingProgramsFromApi = useMemo(() => {
    return debounce(getMarketingProgramsFromApi, applicationConfig.waitTime);
  }, [getMarketingProgramsFromApi]);

  const memoizedMarketingProgramsFromApi = useCallback(
    (val) => {
      return debouncedMarketingProgramsFromApi(val);
    },
    [debouncedMarketingProgramsFromApi]
  );

  const getEcosystemsFromApi = useCallback(
    async (name) => {
      const filter = { name };
      if (!name && name.length === 0) {
        delete filter.name;
      }
      try {
        const rsp1 = await getReferenceData(
          applicationConfig.referenceDataQueries.ecoSystems
        );
        setEcosystems(rsp1.ecoSystems);
        return rsp1.ecoSystems;
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      }

      return [];
    },
    [addNotification]
  );

  useEffect(async () => {
    if (
      isUserAuthorized &&
      user.userId &&
      currentStep === 1 &&
      initialMpOptions.length === 0 &&
      !marketingProgramsLoading &&
      initialOptsLoadingRef.current === false
    ) {
      initialOptsLoadingRef.current = true;
      setInitialStepLoading(true);
      await memoizedMarketingProgramsFromApi("");
      await getEcosystemsFromApi("");
      setInitialStepLoading(false);
      initialOptsLoadingRef.current = false;
    }
  }, [
    currentStep,
    decreaseRequestsCount,
    getMarketingProgramsFromApi,
    getEcosystemsFromApi,
    increaseRequestsCount,
    initialMpOptions.length,
    isUserAuthorized,
    marketingProgramsLoading,
    memoizedMarketingProgramsFromApi,
    user.userId,
  ]);

  const marketingProgramDropodownValues = useMemo(
    () =>
      mpInputValue?.length === 0 ? initialMpOptions : marketingProgramOptions,
    [initialMpOptions, marketingProgramOptions, mpInputValue?.length]
  );

  const details = useMemo(() => {
    return [
      {
        label: t("common.labels.marketing_program"),
        value:
          newMps.length > 0 && !mpDropdownValue?.marketingProgramNumber ? (
            <div className={clsx(classes.flexContainer, classes.marginTop20)}>
              <div
                className={globalStyles.newBtnContainer}
                onClick={() => {
                  setOpenCreateMpModal(true);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <span className="traitName">
                  {newMps[0].title || newMps[0].marketingProgramDescription}
                </span>
                <Button variant="contained">{t("common.new")}</Button>
              </div>
            </div>
          ) : (
            `${
              mpDropdownValue?.marketingProgramNumber
                ? `${mpDropdownValue?.marketingProgramNumber} - `
                : ""
            }${mpDropdownValue?.description}`
          ),
      },
    ];
  }, [
    mpDropdownValue?.description,
    mpDropdownValue?.marketingProgramNumber,
    t,
  ]);

  const ecosystemsMapRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "marketingProgramDescription",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        const { value } = params;

        return (
          <StatusBadge
            status={value}
            showTooltip
            onTooltipClick={() => {
              if (value === requestStatus.APPROVED) {
                setShowMapEcosystemsOutput(true);
                setMarketingProgramDescription(
                  ecosystemsMappingResponse[0].marketingProgram.description
                );
                setMapEcosystemsOutput(ecosystemsMappingResponse[0].output);
              }
            }}
          />
        );
      },
      sortable: false,
    },
  ];

  const handleNewMarketingProgramModal = async (reqId) => {
    setMpOutputTableLoading(true);
    setMarketingProgramOutput({
      output: {
        warnings: [],
        items: [],
      },
    });
    try {
      const response = await getRequestDetails(reqId);
      setMarketingProgramOutput(response);
      setMpOutputTableLoading(false);
    } catch (error) {
      setShowMarketingProgramOutput(false);
      setMpOutputTableLoading(false);
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const mpRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "marketingProgramDescription",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        const { value } = params;

        return (
          <StatusBadge
            status={value}
            showTooltip
            onTooltipClick={async () => {
              if (value === requestStatus.APPROVED) {
                setShowMarketingProgramOutput(true);
                await handleNewMarketingProgramModal(
                  marketingProgramMappingResponse[0].requestId
                );
              }
            }}
          />
        );
      },
      sortable: false,
    },
  ];

  function validateForm(step) {
    const formErrors = {};
    switch (step) {
      case 1:
        if (isEmpty(mpDropdownValue)) {
          formErrors.marketingProgramError = t("errors.field_required");
        }
        break;
      default:
        break;
    }
    return formErrors;
  }

  useEffect(() => {
    (async () => {
      if (
        currentStep === 2 &&
        mpDropdownValue?.marketingProgramNumber &&
        initialMarketingEcosystems?.length === 0 &&
        !isRequestRevisionFlow
      ) {
        getMarketingProgramEcosystemsCallback();
      }
    })();
  }, [
    currentStep,
    getMarketingProgramEcosystemsCallback,
    initialMarketingEcosystems?.length,
    mpDropdownValue?.marketingProgramNumber,
  ]);

  const changeEcosystemMappingState = (ecosystem) => {
    const mpEcosystemDetails = ecosystems.find(
      (ecoSystem) => ecoSystem.ecoSystemName === ecosystem
    );
    const ecosystemId = mpEcosystemDetails?.ecoSystemId;
    if (ecosystemId === 3 || ecosystemId === 5) {
      setEcosystemDetails((draft) => {
        draft.disable = true;
        draft.ecosystemId = ecosystemId;
        draft.showAutoGeneratedMessage = true;
        draft.showEnableAutomation = false;
      });
      return;
    }

    let ecosystemMpNameDisabled = true;
    let ecosystemMpKeyDisabled = true;
    let ecosystemInstanceDisabled = true;

    if (mpEcosystemDetails) {
      let {
        ecosystemMarketingProgramName: ecosystemMpName,
        ecosystemMarketingProgramKey: ecosystemMpKey,
        ecosystemMarketingProgramInstance: ecosystemInstance,
      } = mpEcosystemDetails;

      // Check for automation enabled
      if (!mpEcosystemDetails.automationEnabled) {
        // Enable the null fields when automation is not enabled
        if (isEmpty(ecosystemMpName)) {
          ecosystemMpNameDisabled = false;
          ecosystemMpName = "";
        }
        if (isEmpty(ecosystemMpKey)) {
          ecosystemMpKeyDisabled = false;
          ecosystemMpKey = "";
        }
        if (isEmpty(ecosystemInstance)) {
          ecosystemInstanceDisabled = false;
          ecosystemInstance = "";
        }

        setEcosystemDetails((draft) => {
          draft.showEnableAutomation = true;
          draft.automationEnabled = false;
          draft.showAutoGeneratedMessage = false;
          draft.ecosystemId = mpEcosystemDetails.ecoSystemId;
          draft.ecosystemName = mpEcosystemDetails.ecoSystemName;
          draft.ecosystemMarketingProgramName = ecosystemMpName;
          draft.ecosystemMarketingProgramKey = ecosystemMpKey;
          draft.ecosystemMarketingProgramInstance = ecosystemInstance;
          draft.ecosystemMpNameDisabled = ecosystemMpNameDisabled;
          draft.ecosystemMpKeyDisabled = ecosystemMpKeyDisabled;
          draft.ecosystemInstanceDisabled = ecosystemInstanceDisabled;
        });
      } else {
        setEcosystemDetails((draft) => {
          draft.showEnableAutomation = false;
          draft.enableAutomation = false;
          draft.showAutoGeneratedMessage = false;
          draft.ecosystemId = mpEcosystemDetails.ecoSystemId;
          draft.ecosystemName = mpEcosystemDetails.ecoSystemName;
          draft.ecosystemMarketingProgramName = ecosystemMpName;
          draft.ecosystemMarketingProgramKey = ecosystemMpKey;
          draft.ecosystemMarketingProgramInstance = ecosystemInstance;
          draft.ecosystemMpNameDisabled = ecosystemMpNameDisabled;
          draft.ecosystemMpKeyDisabled = ecosystemMpKeyDisabled;
          draft.ecosystemInstanceDisabled = ecosystemInstanceDisabled;
        });
      }
    } else {
      setEcosystemDetails((draft) => {
        // draft.disable = false;
        draft.showEnableAutomation = false;
        draft.enableAutomation = false;
        draft.showAutoGeneratedMessage = false;
        draft.ecosystemId = mpEcosystemDetails.ecoSystemId;
        draft.ecosystemName = mpEcosystemDetails.ecoSystemName;
        draft.ecosystemMarketingProgramName = "";
        draft.ecosystemMarketingProgramKey = "";
        draft.ecosystemMarketingProgramInstance = "";
        draft.ecosystemMpNameDisabled = ecosystemMpNameDisabled;
        draft.ecosystemMpKeyDisabled = ecosystemMpKeyDisabled;
        draft.ecosystemInstanceDisabled = ecosystemInstanceDisabled;
      });
    }
  };

  const remainingEcosystems = ecosystems.filter((ecosystem1) => {
    if (ecosystem1.ecoSystemId === ecosystemDetails.ecosystemId) {
      return true;
    }
    const existingIndex = marketingEcosystems?.findIndex(
      (ecosystem2) => ecosystem2.ecosystemId === ecosystem1.ecoSystemId
    );

    if (existingIndex !== -1 && ecosystemDetails.index !== -1) {
      if (existingIndex === ecosystemDetails.index) {
        return true;
      }
      return false;
    }
    return existingIndex === -1;
  });

  const resetEcosystemDetails = () => {
    setEcosystemDetails(initialEcosystemState);
  };

  const [marketingProgramData, setMarketingProgramData] = useState(null);

  useEffect(() => {
    const id = 0;
    if (newMps.length >= 1) {
      setMarketingProgramData({
        legalEntity: {
          legalEntityName: newMps[id].legalEntity.legalOrg,
          legalEntityNumber: newMps[id].legalEntity.legalEntityId,
          legalEntityShortName: newMps[id].legalEntity.legalEntityShortName,
        },
        brandName: newMps[id].brandName,
        brandShortName: newMps[id].brandShortName,
        marketingProgramName: newMps[id].marketingProgramName,
        businessOrgUnit: newMps[id].businessOrgUnit,
        marketingProgramDescription: newMps[id].marketingProgramDescription,
        marketingProgramBusinessName: newMps[id].marketingProgramBusinessName,
        startingEcosystems: newMps[id].startingEcosystems,
        startingChannels: newMps[id].startingChannels,
      });
    }
  }, [newMps, mpDropdownValue]);

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/marketing-programs"
      goToText="GO TO MARKETING PROGRAMS"
    />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        currentStep={currentStep}
        steps={[
          t("create_marketing_program.headings.step_1"),
          t("create_marketing_program.headings.step_2"),
          t("create_marketing_program.headings.step_3"),
        ]}
        headerText={t("create_marketing_program.headerText")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      if (currentStep === 1) {
                        history.goBack();
                      } else {
                        setCurrentStep((step) => step - 1);
                      }
                    }}
                  >
                    {t("back")}
                  </Button>
                  <Button
                    disabled={loading}
                    classes={{ root: globalStyles.btn }}
                    onClick={async () => {
                      if (currentStep !== totalSteps) {
                        const formErrors = validateForm(currentStep);
                        setErrors(formErrors);
                        if (Object.keys(formErrors).length > 0) {
                          return;
                        }
                        if (currentStep === 1) {
                          if (mpDropdownValue.marketingProgramNumber) {
                            setManageEcosystemDetails(true);
                          } else if (manageEcosystemDetails) {
                            setManageEcosystemDetails(false);
                          }
                        }
                        if (currentStep === 2) {
                          if (
                            diffList.length === 0 ||
                            !manageEcosystemDetails
                          ) {
                            if (mpDropdownValue?.marketingProgramNumber) {
                              setErrors((errs) => ({
                                ...errs,
                                noChangesError: t(
                                  "create_marketing_program.no_changes_to_ecosystems"
                                ),
                              }));
                              return;
                            }
                          }
                        }
                        setCurrentStep((curr) => curr + 1);
                      } else {
                        try {
                          let payload;
                          const filteredMarketingEcosystems =
                            marketingEcosystems
                              .filter(
                                (mpE) => mpE.isUpdated || mpE.new || mpE.deleted
                              )
                              .map((mpE) => ({
                                ecosystemId: mpE.ecosystemId,
                                ecosystemName: mpE.ecosystemName,
                                ecosystemMarketingProgramName:
                                  mpE.ecosystemMarketingProgramName,
                                ecosystemMarketingProgramKey:
                                  mpE.ecosystemMarketingProgramKey,
                                ecosystemMarketingProgramInstance:
                                  mpE.ecosystemMarketingProgramInstance,
                                automationEnabled: mpE.automationEnabled,
                                isUpdated: mpE.isUpdated,
                                deleted: mpE.deleted,
                                new: mpE.new,
                              }));
                          if (mpDropdownValue.new) {
                            const mp = newMps.find(
                              (marketingProg) =>
                                marketingProg.marketingProgramName ===
                                mpDropdownValue.marketingProgramName
                            );
                            payload = createBackendPayload(
                              mp,
                              ecosystems,
                              createMarketingProgramConstants.channels
                                .contactPointTypeOptions,
                              filteredMarketingEcosystems.length === 0
                                ? undefined
                                : filteredMarketingEcosystems
                            );
                            if (isRequestRevisionFlow) {
                              increaseRequestsCount();
                              const data = await updateRequest(
                                request.requestId,
                                payload,
                                applicationConfig.modules.marketing_programs
                              );
                              setRequest(data);
                              setIsRequestRevisionUpdated(true);
                              history.goBack();
                              addNotification(
                                t("notifications.request_edited_success"),
                                t("status.success")
                              );
                              setCurrentStep((curr) => curr + 1);
                            } else {
                              increaseRequestsCount();
                              const data = await createMarketingProgram(
                                payload
                              );
                              setMarketingProgramMappingResponse(
                                data.map((item, index) => ({
                                  id: index,
                                  ...item,
                                  requestId: item.requestId,
                                  status:
                                    item.status[0].toUpperCase() +
                                    item.status.slice(1),
                                  marketingProgramDescription:
                                    item.marketingProgram.description,
                                }))
                              );
                              setCurrentStep((curr) => curr + 1);
                            }
                          } else {
                            payload = {
                              schemaVersion: "v2",
                              marketingProgram: {
                                marketingProgramNumber:
                                  mpDropdownValue.marketingProgramNumber,
                                description: mpDropdownValue.description,
                              },
                              ecosystems: filteredMarketingEcosystems,
                            };
                            increaseRequestsCount();
                            const data = await createMarketingProgram(payload);
                            setEcosystemsMappingResponse(
                              data.map((item, index) => ({
                                id: index,
                                ...item,
                                requestId: item.requestId,
                                status:
                                  item.status[0].toUpperCase() +
                                  item.status.slice(1),
                                marketingProgramDescription:
                                  item.marketingProgram.description,
                              }))
                            );
                            setCurrentStep((curr) => curr + 1);
                          }
                        } catch (error) {
                          handleError({
                            error,
                            handle404: false,
                            addNotification,
                          });
                        } finally {
                          decreaseRequestsCount();
                        }
                      }
                    }}
                  >
                    {currentStep === totalSteps && loading && (
                      <Box
                        sx={{
                          mr: 1,
                          mt: 0.5,
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )}
                    {createMarketingProgramConstants.footerText[currentStep]}
                  </Button>
                </>
              </>
            )}
            {currentStep > totalSteps && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/marketing-programs"
                >
                  {t("common.labels.back_to_marketing_programs")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${encodeURIComponent(
                    ecosystemsMappingResponse?.[0]?.requestId ||
                      marketingProgramMappingResponse?.[0]?.requestId
                  )}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div>
          {currentStep === 1 && initialStepLoading && (
            <>
              <Skeleton height={56} />
              <Skeleton height={56} />
              <Skeleton width={100} height={24} />
            </>
          )}
          {currentStep === 1 && !initialStepLoading && (
            <>
              {!mpDropdownValue?.title ? (
                <CustomAutoComplete
                  isMultiple={false}
                  error={!!errors?.marketingProgramError}
                  errorText={errors?.marketingProgramError}
                  classes={{
                    root: classes.customAutoCompleteRoot,
                  }}
                  options={
                    marketingProgramsLoading
                      ? []
                      : [
                          ...marketingProgramDropodownValues.map((mp) => ({
                            ...mp,
                            title: `${mp.marketingProgramNumber} - ${mp.description}`,
                          })),
                          ...(newMps ? newMpOptions : []),
                          <NewMp setOpenModal={setOpenCreateMpModal} />,
                        ]
                  }
                  id="select-mp"
                  placeholder={
                    marketingProgramsLoading
                      ? t("common.loading")
                      : t("create_marketing_program.placeholders.selectMp")
                  }
                  loading={marketingProgramsLoading}
                  value={mpDropdownValue || []}
                  closeIcon={<></>}
                  isExternalInputValue
                  inputValue={mpInputValue}
                  setValue={(value) => {
                    if (value?.type?.name === "NewMp") return;
                    if (!isEmpty(value)) {
                      setMpDropdownValue(value);
                      setInitialMarketingEcosystems([]);
                      setMarketingEcosystems((draft) => {
                        draft.splice(0, draft.length);
                      });
                    } else {
                      setMpDropdownValue(null);
                    }
                  }}
                  onInputChange={(value) => {
                    setMpInputValue(value);
                    if (!isEmpty(value)) {
                      memoizedMarketingProgramsFromApi(value);
                    }
                  }}
                />
              ) : (
                <div className={classes.fullWidth}>
                  <TextBox
                    isNew={mpDropdownValue?.new || false}
                    name={mpDropdownValue?.title}
                    onClick={() => {
                      if (mpDropdownValue?.new) {
                        const title = mpDropdownValue?.title;
                        let editMpIdx = 0;
                        let editMp = newMps[0];
                        if (newMps.length > 1) {
                          editMpIdx = newMps.findIndex(
                            (mp) => mp.description === title
                          );
                          editMp = newMps[editMpIdx];
                        }
                        setRequest({
                          ...request,
                          revisionMp: {
                            ...editMp,
                            idx: editMpIdx,
                          },
                        });
                        setOpenCreateMpModal(true);
                      } else {
                        setOpenDeleteModal(true);
                      }
                    }}
                    onDelete={() => {
                      setOpenDeleteModal(true);
                    }}
                  />
                </div>
              )}
            </>
          )}
          {currentStep === 2 && (
            <div>
              <RenderDetailsDom details={details} />
              <div className={classes.manageEcosystemDetailsWrapper}>
                <Typography>
                  {t("create_marketing_program.manage_ecosystem_details")}
                </Typography>
                <div className={classes.manageEcosystemDetailsBtns}>
                  <Button
                    className={manageEcosystemDetails ? "active" : ""}
                    onClick={() => setManageEcosystemDetails(true)}
                  >
                    {t("common.yes")}
                  </Button>
                  <Button
                    className={!manageEcosystemDetails ? "active" : ""}
                    onClick={() => setManageEcosystemDetails(false)}
                  >
                    {t("common.no")}
                  </Button>
                </div>
              </div>
              {errors.noChangesError && !manageEcosystemDetails && (
                <Alert style={{ marginBottom: "10px" }} severity="error">
                  {errors.noChangesError}
                </Alert>
              )}
              {manageEcosystemDetails && (
                <>
                  {!loading && marketingEcosystems?.length === 0 && (
                    <Alert style={{ marginBottom: "10px" }} severity="info">
                      {t("mp_ecosystem_mapping_container.no_ecosystem_mapping")}
                    </Alert>
                  )}
                  {loading ? (
                    <>
                      <Skeleton height={56} />
                      <Skeleton height={56} />
                      <Skeleton height={56} />
                    </>
                  ) : (
                    <MPAccordion
                      list={renderList}
                      currentStep={currentStep}
                      handleEdit={(marketingEcosystem) => {
                        setEcosystemDetails((draft) => {
                          Object.keys(marketingEcosystem).forEach((key) => {
                            draft[key] = marketingEcosystem[key];
                          });
                          if (
                            marketingEcosystem.ecosystemId === 3 ||
                            marketingEcosystem.ecosystemId === 5
                          ) {
                            draft.disable = true;
                            draft.showAutoGeneratedMessage = true;
                            draft.ecosystemMpNameDisabled = true;
                            draft.ecosystemMpKeyDisabled = true;
                            draft.ecosystemInstanceDisabled = true;
                          }
                        });
                        setShowEcosystemMappingModal(true);
                      }}
                      handleDelete={(marketingEcosystem) => {
                        setOpenDeleteModal(true);
                        setEcosystemDetails(marketingEcosystem);
                      }}
                      showDelete={(ecosystem) => {
                        if (isRequestRevisionFlow) {
                          return !request?.originalRequestDetails?.ecosystems?.find(
                            (e) => e.ecosystemId === ecosystem.ecosystemId
                          );
                        }
                        return false;
                      }}
                    />
                  )}
                  {errors.noChangesError && (
                    <Alert style={{ marginBottom: "10px" }} severity="error">
                      {errors.noChangesError}
                    </Alert>
                  )}
                  {errors.addEcosystemError && (
                    <Alert style={{ marginBottom: "10px" }} severity="error">
                      {errors.addEcosystemError}
                    </Alert>
                  )}
                  <div className={globalStyles.addTraitBtn}>
                    <AddCircleOutlineIcon />
                    <Button
                      onClick={() => {
                        if (remainingEcosystems.length > 0) {
                          setShowEcosystemMappingModal(true);
                        } else {
                          setErrors((errs) => ({
                            ...errs,
                            addEcosystemError: "All Ecosystems mapped already",
                          }));
                        }
                      }}
                    >
                      {t(
                        "mp_ecosystem_mapping_container.add_ecosystem_mapping"
                      )}
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          {currentStep === 3 && (
            <>
              <DetailsDOM details={details} />
              <div className={classes.confirmationContainer}>
                <Typography variant="h4">Mapped Ecosystems</Typography>
                {diffList.length === 0 && (
                  <Alert style={{ marginBottom: "10px" }} severity="info">
                    {t("mp_ecosystem_mapping_container.no_ecosystem_mapping")}
                  </Alert>
                )}
                <MPAccordion list={diffList} currentStep={currentStep} />
              </div>
            </>
          )}
          {currentStep > 0 && (
            <>
              {ecosystemsMappingResponse.length > 0 && (
                <div
                  style={{
                    height: 70 * ecosystemsMappingResponse.length + 60,
                    maxHeight: "calc(100vh - 300px)",
                  }}
                >
                  <Table
                    columns={ecosystemsMapRequestTableColumns}
                    rows={ecosystemsMappingResponse}
                    tableStyle={classes.myRequestsTable}
                    hidePagination
                  />
                </div>
              )}
              {marketingProgramMappingResponse.length > 0 && (
                <div
                  style={{
                    height: 70 * marketingProgramMappingResponse.length + 60,
                    maxHeight: "calc(100vh - 300px)",
                  }}
                >
                  <Table
                    columns={mpRequestTableColumns}
                    rows={marketingProgramMappingResponse}
                    tableStyle={classes.myRequestsTable}
                    hidePagination
                  />
                </div>
              )}
            </>
          )}
        </div>
      </InputFlow>
      {openCreateMpModal && (
        <CreateMarketingProgramsContainerModal
          open={openCreateMpModal}
          handleClose={() => {
            if (request.revisionMp) {
              setRequest({
                ...request,
                revisionMp: null,
              });
            }
            setOpenCreateMpModal(false);
          }}
          isDisabled={currentStep > 1}
          marketingProgramData={marketingProgramData}
          handleSubmit={(newMarketingProgram) => {
            const value = {
              description: newMarketingProgram.marketingProgramDescription,
              marketingProgramDescription:
                newMarketingProgram.marketingProgramDescription,
              legalEntity: newMarketingProgram.legalEntity,
              brandName: newMarketingProgram.brandName,
              brandShortName: newMarketingProgram.brandShortName,
              businessOrgUnit: newMarketingProgram.businessOrgUnit,
              marketingProgramName: newMarketingProgram.marketingProgramName,
              marketingProgramNumber: null,
              title: newMarketingProgram.marketingProgramDescription,
              startingEcosystems: newMarketingProgram.startingEcosystems,
              startingChannels: newMarketingProgram.startingChannels,
              mpBusinessName: newMarketingProgram.marketingProgramBusinessName,
              marketingProgramBusinessName:
                newMarketingProgram.marketingProgramBusinessName,
              new: true,
            };
            if (request.revisionMp) {
              setNewMps((mps) => {
                const mpsCpy = [...mps];
                mpsCpy[request.revisionMp.idx] = value;
                return mpsCpy;
              });
              setRequest({
                ...request,
                revisionMp: null,
              });
            } else {
              setNewMps((mps) => [...mps, newMarketingProgram]);
            }
            setMpDropdownValue(value);
            setInitialMarketingEcosystems([]);
            setMarketingEcosystems((draft) => {
              draft.splice(0, draft.length);
            });
            setOpenCreateMpModal(false);
          }}
        />
      )}
      {showMapEcosystemsOutput && (
        <EcosystemMappingRequestOutputModal
          isOpen={showMapEcosystemsOutput}
          requestId={mapEcosystemsOutput.requestId}
          data={mapEcosystemsOutput}
          marketingProgramDescription={marketingProgramDescription}
          setShowOutput={() => setShowMapEcosystemsOutput(false)}
        />
      )}
      {showMarketingProgramOutput && (
        <MarketingProgramRequestOutputModal
          isOpen={showMarketingProgramOutput}
          isLoading={mpOutputTableLoading}
          requestId={marketingProgramOutput.requestId}
          data={marketingProgramOutput.output}
          setShowOutput={() => setShowMarketingProgramOutput(false)}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={
          currentStep === 1
            ? t("create_marketing_program.mp_removal_warning")
            : t(
                "create_marketing_program.delete_marketing_ecosystem_confirmation"
              )
        }
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          if (currentStep === 1) {
            setMpDropdownValue();
            setMpInputValue("");
            setMarketingProgramData({});
          } else if (currentStep === 2) {
            setMarketingEcosystems((draft) => {
              deleteMp(draft, ecosystemDetails);
            });
            resetEcosystemDetails();
          }
          setOpenDeleteModal(false);
        }}
        type="error"
      />
      {showEcosystemMappingModal && (
        <EcosystemMapping
          openModal={showEcosystemMappingModal}
          handleClose={() => {
            setShowEcosystemMappingModal(false);
            resetEcosystemDetails();
          }}
          ecosystemDetails={ecosystemDetails}
          ecosystems={remainingEcosystems}
          isExistingEcosystem={initialMarketingEcosystems.find(
            (mpE) => mpE.ecosystemId === ecosystemDetails.ecosystemId
          )}
          handleEcosystemChange={(ecosystem) =>
            changeEcosystemMappingState(ecosystem)
          }
          handleSubmit={(data) => {
            const existingMpEcosystem = marketingEcosystems.find(
              (mpE) => mpE.ecosystemId === data.ecosystemId
            );
            if (!existingMpEcosystem) {
              const newIndex = marketingEcosystems?.length;
              setMarketingEcosystems((draft) => {
                createMp(draft, {
                  ...data,
                  index: newIndex,
                });
              });
            } else {
              setMarketingEcosystems((draft) => {
                updateMp(draft, data);
              });
            }
            setShowEcosystemMappingModal(false);
            resetEcosystemDetails();
          }}
          isDisabled={currentStep > 2}
          title="title"
          setEcosystemDetails={setEcosystemDetails}
        />
      )}
    </>
  );
}

const NewMp = memo(function NewMp({ setOpenModal }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className={classes.newMp}>
        <Typography variant="h6">
          {t("create_trait.no_suggestion_match")}
        </Typography>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setOpenModal(true);
          }}
        >
          {t("create_marketing_program.newMp")}
        </Button>
      </div>
    </>
  );
});

NewMp.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
};

const RenderDetailsDom = memo(function RenderDetailsDom({ details }) {
  const classes = useStyles();
  const detailsDOM = details.map((info) => {
    return (
      <div
        className={clsx(
          classes.flexContainer,
          classes.infoContainer,
          classes.justifyContent
        )}
        key={`${info.label}${info.index}`}
      >
        <Typography variant="h6">{info.label} :</Typography>
        <Typography variant="h6">{info.value}</Typography>
      </div>
    );
  });
  return detailsDOM;
});

RenderDetailsDom.propTypes = {
  details: PropTypes.array.isRequired,
};

const MPAccordion = memo(function MPAccordion({
  list,
  currentStep,
  handleEdit,
  handleDelete,
  showDelete,
}) {
  const classes = useStyles();
  const accordionClasses = useAccordionStyles();
  const [expanded, setExpanded] = useState(false);

  const { mpEcosystemKeys } =
    marketingProgramsModuleConfig.mpEcosystemMappingConstants;

  const toggleAccordion = useCallback(
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  return list.map((row, i) => {
    const id = `system-${i.toString()}`;
    let statusText;

    if (row.new && row.isUpdated) {
      statusText = applicationConfig.status.updated;
    } else if (row.deleted && row.isUpdated) {
      statusText = applicationConfig.status.existing;
    } else if (row.deleted) {
      statusText = applicationConfig.status.deleted;
    } else if (row.new) {
      statusText = applicationConfig.status.new;
    }

    const ecosystemInstance = row.ecosystemName;

    const ecosystemMarketingProgramName = truncateString(
      row.ecosystemMarketingProgramName,
      {
        maxLength: 40,
        showEllipsis: true,
      }
    );

    return (
      <div
        key={i.toString()}
        className={clsx(
          classes.flexContainer,
          classes.fullWidth,
          classes.trait
        )}
      >
        <Accordion
          className={clsx(accordionClasses.accordion, classes.customAccordion)}
          expanded={expanded === id}
          onChange={toggleAccordion(id)}
        >
          <AccordionSummary
            data-testid="accordion-summary"
            className={accordionClasses.accordionHeader}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={clsx(classes.flexContainer, classes.fullWidth)}>
              <Typography className={accordionClasses.systemType}>
                {ecosystemInstance}
              </Typography>
              <Typography
                variant="body2"
                className={accordionClasses.systemName}
              >
                {ecosystemMarketingProgramName}
              </Typography>
              {statusText && (
                <Typography
                  variant="body2"
                  checking
                  className={clsx(
                    classes.statusLabel,
                    row.new ? classes.new : classes.deleted
                  )}
                >
                  {statusText}
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={accordionClasses.accordionContent}>
            <AccordionRowDetails
              keys={mpEcosystemKeys}
              row={{
                ...row,
                language: `${
                  row?.languageName ?? row?.language
                } (${row?.languageCode?.toUpperCase()})`,
              }}
            />
          </AccordionDetails>
        </Accordion>
        {currentStep === 2 ? (
          <div className={classes.flexContainer}>
            <div
              className={classes.autoCompleteEditContainer}
              onClick={() => {
                handleEdit(row);
              }}
              role="button"
              data-testid="accordion-edit"
              tabIndex={0}
              onKeyDown={() => {
                handleEdit(row);
              }}
              aria-label="edit"
            >
              <EditOutlinedIcon />
            </div>
            {(statusText === applicationConfig.status.new ||
              showDelete(row)) && (
              <div
                className={classes.autoCompleteDeleteContainer}
                onClick={() => {
                  handleDelete(row);
                }}
                data-testid="accordion-delete"
                role="button"
                tabIndex={0}
                onKeyDown={() => {
                  handleDelete(row);
                }}
                aria-label="delete"
              >
                <DeleteOutlineOutlinedIcon />
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  });
});

MPAccordion.defaultProps = {
  showDelete: false,
};

MPAccordion.propTypes = {
  list: PropTypes.arrayOf({
    automationEnabled: PropTypes.bool,
    ecosystemId: PropTypes.number,
    ecosystemMarketingProgramInstance: PropTypes.string,
    ecosystemMarketingProgramKey: PropTypes.string,
    ecosystemMarketingProgramName: PropTypes.string,
    marketingProgramNumber: PropTypes.number,
  }).isRequired,
  currentStep: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  showDelete: PropTypes.boo,
};

const DetailsDOM = memo(function DetailsDOM({ details }) {
  const classes = useStyles();
  return (
    <>
      {details
        .filter((i) => i.label)
        .map((info) => {
          return (
            <div
              className={clsx(
                classes.flexContainer,
                classes.infoContainer,
                classes.justifyContent
              )}
              key={`${info.label}${info.index}`}
            >
              <Typography variant="h6">{info.label} :</Typography>
              <Typography variant="h6">{info.value}</Typography>
            </div>
          );
        })}
    </>
  );
});

DetailsDOM.propTypes = {
  details: PropTypes.arrayOf().isRequired,
};

export default memo(NewMarketingProgram);
