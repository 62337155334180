import api from "../utilities/api";

const createJob = async (body) => {
  const url = `/jobs`;
  const {
    data: { data },
  } = await api.post(url, body);
  return data;
};

export default createJob;
