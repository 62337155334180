import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import { KeyValueSeparator } from "../../../../../components/AccordionRowDetails";
import CustomModal from "../../../../../components/CustomModal";
import { MarketingPrograms } from "../../../../../components/MarketingProgramsModal";

import applicationConfig from "../../../../../config/applicationConfig";
import pageAccessConfig from "../../../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../../../config/traitsModuleConfig";

import useUserProfile from "../../../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../../../utilities/checkUserAuthorization";

import useStyles from "./styles";

const TraitDetailsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const { marketingPrograms, ecosystems } = data;
  const { ecosystemFeatureKeys } = traitsModuleConfig;

  const { user } = useUserProfile();
  const history = useHistory();

  const rows = [
    {
      label: "Trait ID",
      value: data.traitId,
    },
    {
      label: "Trait Name",
      value: data.traitName,
    },
    {
      label: "Description",
      value: data.description,
    },
    {
      label: "Personal (PII) Data",
      value: data.personalData,
    },
    {
      label: "Sensitive (NON-PII) Data",
      value: data.personalDataNonPii,
    },
    {
      label: "Health Data",
      value: data.healthData,
    },
    {
      label: "Data Type",
      value: data.dataType,
    },
    {
      label: "Allow Survivorship Update",
      value: data.allowSurvivorshipUpdate,
    },
    {
      label: "Data Class",
      value: data.dataClass,
    },
    {
      label: "Trait Type",
      value: data.traitType,
    },
    {
      label: "Global Required Trait Indicator",
      value: data.globalRequiredTraitIndicator,
    },
    {
      label: "Standard Registration Attribute Indicator",
      value: data.standardRegistrationAttributeIndicator,
    },
    {
      label: "Data Security Class",
      value: data.dataSecurityClass,
    },
    {
      label: "Survivorship Level",
      value: data.survivorshipLevel,
    },
    {
      label: "Trait Response Type",
      value: data.multiAnswerResponseIndicator,
    },
    {
      label: "Survivorship Rule",
      value: data.survivorshipRule,
    },
    {
      label: "Example Values",
      value: data.exampleValues,
    },
    {
      label: "Form Input",
      value: data.formInput,
    },
    {
      label: "Domain",
      value: data.domain,
    },
    {
      label: "Data Standardization Level",
      value: data.dataStandardizationLevel,
    },
  ]
    .map((item) => {
      const boolMapping = {
        true: "Yes",
        false: "No",
      };
      if (item.label === "traitResponseType") {
        return {
          label: "Trait Response Type",
          value: item.value ? "Multiple" : "Single",
        };
      }
      return {
        label: item.label,
        value: (() => {
          switch (typeof item.value) {
            case "boolean":
              return boolMapping[item.value];
            case "object":
              return item.value;
            default:
              return item.value?.toString();
          }
        })(),
      };
    })
    .filter(({ value }) => !!value);

  const renderUi = () => {
    if (tab === 0) {
      return (
        <table className={globalClasses.table}>
          <tbody>
            {rows.map((row, ind) => (
              <KeyValueSeparator
                keyVal={row.label}
                value={row.value}
                ind={ind.toString()}
                separator={null}
              />
            ))}
          </tbody>
        </table>
      );
    }
    if (tab === 1) {
      return (
        <MarketingPrograms
          type={applicationConfig.modules.traits}
          programs={marketingPrograms}
          loading={false}
        />
      );
    }
    return (
      <AccordionModalDetails
        keys={ecosystemFeatureKeys}
        rows={ecosystems}
        rowKeys={{
          title: "ecosystemName",
          subTitle: "ecosystemTraitName",
          subTitle2: "ecosystemSourceTruncatedName",
        }}
      />
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      data={{
        traitDetails: rows,
        marketingPrograms,
        ecosystems,
      }}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
            }}
          >
            <Tab label="Trait Details" />
            <Tab label="Marketing Programs" />
            <Tab label="Ecosystems" />
          </Tabs>
          {renderUi()}
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createTraits
          ) && (
            <Button
              onClick={() => {
                const state = {
                  traits: [data],
                  type: "single-reuse",
                  flow: "reuse",
                };
                history.push({
                  pathname: "/traits/map",
                  state,
                });
              }}
              classes={{
                root: globalClasses.btn,
              }}
              variant="outlined"
              style={{
                marginTop: "34px",
                justifySelf: "flex-end",
              }}
            >
              {t("traits_container.reuse_trait")}
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};
TraitDetailsModal.defaultProps = {
  title: null,
};

TraitDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    marketingPrograms: PropTypes.shape([]).isRequired,
    ecosystems: PropTypes.shape([]).isRequired,
    standardRegistrationAttributeIndicator: PropTypes.string,
    survivorshipLevel: PropTypes.string,
    survivorshipRule: PropTypes.string,
    traitId: PropTypes.number.isRequired,
    traitName: PropTypes.string.isRequired,
    traitType: PropTypes.string,
    multiAnswerResponseIndicator: PropTypes.bool,
    personalData: PropTypes.bool,
    personalDataNonPii: PropTypes.bool,
    exampleValues: PropTypes.string,
    formInput: PropTypes.string,
    globalRequiredTraitIndicator: PropTypes.string,
    healthData: PropTypes.bool,
    allowSurvivorshipUpdate: PropTypes.bool,
    dataChangeFrequency: PropTypes.string,
    dataClass: PropTypes.string,
    dataSecurityClass: PropTypes.string,
    dataStandardizationLevel: PropTypes.string,
    dataType: PropTypes.string,
    description: PropTypes.string.isRequired,
    domain: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TraitDetailsModal;
