import { useCallback, useEffect, useMemo, useState } from "react";

import { debounce } from "@material-ui/core";

import getEvents from "../api/get-events";
import applicationConfig from "../config/applicationConfig";
import handleError from "../utilities/handleError";
import parseSearchFilter from "../utilities/parseSearchFilter";

import useNotifier from "./useNotifier";

function useEvents({ initialEventNameOptionsFilter = () => {} }) {
  const { addNotification } = useNotifier();
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const [initialEventNameOptions, setInitialEventNameOptions] = useState([]);
  const [eventNamesLoading, setEventNamesLoading] = useState(false);

  const getEventsFromApi = useCallback(
    async (searchText) => {
      setEventNamesLoading(true);
      let filter = {};
      if (searchText?.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      try {
        const filterFieldMap = {
          searchText: "searchText",
        };
        const rsp1 = await getEvents(
          null,
          null,
          parseSearchFilter(
            Object.keys(filter).map((fil) => ({
              name: fil,
              value: filter[fil],
            })),
            filterFieldMap,
            1,
            3
          )
        );
        setEventNameOptions(rsp1.items);
        if (searchText.length === 0 && initialEventNameOptionsFilter?.()) {
          setInitialEventNameOptions(rsp1.items);
        }
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      } finally {
        setEventNamesLoading(false);
      }
    },
    [addNotification, initialEventNameOptionsFilter]
  );

  const debouncedEventsFromApi = useMemo(
    () => debounce(getEventsFromApi, applicationConfig.waitTime),
    [getEventsFromApi]
  );

  const memoizedEventsFromApi = useCallback(
    async (val) => {
      debouncedEventsFromApi(val);
    },
    [debouncedEventsFromApi]
  );

  useEffect(() => {
    return () => {
      debouncedEventsFromApi.clear();
    };
  }, [debouncedEventsFromApi]);

  return useMemo(
    () => ({
      memoizedEventsFromApi,
      eventNameOptions,
      initialEventNameOptions,
      eventNamesLoading,
      setInitialEventNameOptions,
      getEventsFromApi,
      setEventNamesLoading,
      setEventNameOptions,
    }),
    [
      eventNameOptions,
      eventNamesLoading,
      getEventsFromApi,
      initialEventNameOptions,
      memoizedEventsFromApi,
    ]
  );
}

export default useEvents;
