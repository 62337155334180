import {
  useState,
  useContext,
  createElement,
  useMemo,
  useEffect,
  useRef,
} from "react";

import {
  IconButton,
  Select,
  MenuItem,
  OutlinedInput,
  Drawer,
  Switch,
  Typography,
  styled,
  Box,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Pagination, Skeleton } from "@material-ui/lab";
import {
  DataGrid,
  useGridApiContext,
  gridColumnsTotalWidthSelector,
  gridColumnPositionsSelector,
} from "@mui/x-data-grid";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as DownloadIcon } from "../../assets/images/download.svg";
import { ReactComponent as HeaderTuneIcon } from "../../assets/images/header_tune.svg";
import { lightTheme } from "../../assets/styles/themes";
import applicationConfig from "../../config/applicationConfig";

import useNotifier from "../../hooks/useNotifier";
import usePrevious from "../../hooks/usePrevious";
import i18n from "../../i18n/init";
import { ColorModeContext } from "../../providers/ColorModeProvider";
import exportToExcel from "../../utilities/exportExcel";
import handleError from "../../utilities/handleError";
import isEmpty from "../../utilities/isEmpty";
import isEqual from "../../utilities/isEqual";
import parseDataFromTableConfig from "../../utilities/parseDataFromTableConfig";
import truncateString from "../../utilities/truncateString";
import StyledTooltip from "../StyledTooltip";

import useStyles from "./styles";

function mulberry32(a) {
  return () => {
    /* eslint-disable no-bitwise */
    let t = a;
    t += 0x6d2b79f5;
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    /* eslint-enable */
  };
}

function randomBetween(seed, min, max) {
  const random = mulberry32(seed);
  return () => min + (max - min) * random();
}

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const SkeletonLoadingOverlay = () => {
  const apiRef = useGridApiContext();
  const colorMode = useContext(ColorModeContext);

  const dimensions = apiRef.current?.getRootDimensions();
  const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;

  const rowHeight = apiRef.current.unstable_getRowHeight();
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);

  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = useMemo(
    () => positions.filter((value) => value <= totalWidth).length,
    [totalWidth, positions]
  );
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount);

  const children = useMemo(() => {
    const random = randomBetween(12345, 25, 75);
    const array = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      columns.forEach((column) => {
        const width = Math.round(random());
        array.push(
          <SkeletonCell
            key={`column-${i}-${column.field}`}
            sx={{
              justifyContent: column.align,
              backgroundColor:
                colorMode.mode === "light"
                  ? lightTheme.palette.static.white
                  : lightTheme.palette.static.darkGrey,
            }}
          >
            <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
          </SkeletonCell>
        );
      });
      array.push(<SkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [skeletonRowsCount, columns]);

  const rowsCount = apiRef.current.getRowsCount();

  return rowsCount > 0 ? (
    <LinearProgress />
  ) : (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `${columns
          .map(({ computedWidth }) => `${computedWidth}px`)
          .join(" ")} 1fr`,
        gridAutoRows: rowHeight,
      }}
    >
      {children}
    </div>
  );
};

const Table = ({
  columns,
  rows,
  renderCellData,
  autoHeight,
  openModal,
  initApiCall,
  moduleName,
  filters,
  hideConfig,
  externalLoading,
  onPageChange,
  onPerPageChange,
  totalItems,
  hidePagination,
  tableStyle,
  selectionModel,
  checkboxSelection,
  onSelectionModelChange,
  exportColumnsToExclude,
  customizeExportData,
  showArrayValueInNewSheet,
  exportMainSheetName,
  noRowsText,
  setLoadingCellData,
  clickableCellExternalParams,
  disableSelectAllCheckbox,
  isRowSelectable,
}) => {
  const tableUniqueIdentifier = JSON.stringify(columns);
  const location = useLocation();
  const { paginationConfig } = applicationConfig;
  const [filteredColumns, setFilteredColumns] = useState(
    JSON.parse(localStorage.getItem(tableUniqueIdentifier) || "[]")
  );
  const [tuneDrawerOpen, setTuneDrawerOpen] = useState(false);
  const [tableData, setTableData] = useState(rows);
  const [originalData, setOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const colorMode = useContext(ColorModeContext);
  const previousProp = usePrevious({ clickableCellExternalParams });
  const openRowParams = useRef({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(paginationConfig.defaultPerPage);

  const { addNotification, notification } = useNotifier();
  const totalItemsRef = useRef(0);
  const rawDataRef = useRef(0);

  const classes = useStyles();
  const { t } = useTranslation();
  let timeout;

  const columnTuneCell = {
    field: "tune",
    headerName: null,
    sortable: false,
    flex: 0.4,
    renderHeader: () => {
      return (
        <Box display="flex" alignItems="center">
          {moduleName && <CustomToolbar />}
          <IconButton
            onClick={() => setTuneDrawerOpen(true)}
            className={classes.columnFilterBtn}
          >
            {createElement(HeaderTuneIcon, {
              fill:
                colorMode.mode === "light"
                  ? lightTheme.palette.static.darkerGrey
                  : lightTheme.palette.static.white,
            })}
          </IconButton>
        </Box>
      );
    },
  };

  const renderClickableCell = (params) => {
    let content = params.value;

    if (!content) {
      return (
        <div data-testid="clickable-cell" className={classes.clickableCell} />
      );
    }

    let contentToBeRendered = content;

    if (content.length > 0) {
      if (Array.isArray(content) || content.includes(",")) {
        if (content.includes(",")) {
          content = content.split(",");
        }
        contentToBeRendered = content.join(",");
        contentToBeRendered =
          contentToBeRendered.length > 50
            ? `${contentToBeRendered.slice(0, 50)}...`
            : contentToBeRendered;
      } else {
        content = content.length > 95 ? `${content.slice(0, 95)}...` : content;
        contentToBeRendered = content;
      }
    }

    return (
      <div data-testid="clickable-cell" className={classes.clickableCell}>
        {contentToBeRendered}
      </div>
    );
  };

  const truncateAndRenderTooltip = (params) => {
    const content = params.value;
    let truncatedContent = params.value;
    if (content && !Number.isInteger(content)) {
      if (typeof content === "string" && content.length > 90) {
        truncatedContent = truncateString(content, {
          maxLength: 90,
          showEllipsis: true,
        });
      }
      if (truncatedContent === content) {
        return <span>{content}</span>;
      }
      return (
        <StyledTooltip placement="top" title={content}>
          <div
            style={{
              display: "flex",
            }}
          >
            <span>{truncatedContent}</span>
          </div>
        </StyledTooltip>
      );
    }
    return <span>{content}</span>;
  };

  const tableColumns = columns.map((data) => {
    const columnConfig = {
      ...data,
      flex: 1,
      editable: false,
      disableClickEventBubbling: true,
    };

    if (!data.renderCell && !("tooltip" in data && !data.tooltip)) {
      columnConfig.renderCell = (params) => truncateAndRenderTooltip(params);
    } else if (data.customJSX) {
      columnConfig.renderCell = (params) => <span>{params.value}</span>;
    }

    if (data.flex) {
      columnConfig.flex = data.flex;
    }

    if (data.width && hideConfig) {
      columnConfig.width = data.width;
      delete columnConfig.flex;
    }

    if (data.clickable) {
      if (!data.custom) {
        columnConfig.renderCell = renderClickableCell;
      }
    } else if (data.truncate) {
      columnConfig.renderCell = truncateAndRenderTooltip;
    }

    if (data.renderCell) {
      columnConfig.renderCell = (params) => data.renderCell(params);
    }

    return columnConfig;
  });

  const handleCellClick = async (params, event) => {
    const { colDef, row, id } = params;

    if (!colDef || !colDef.clickable) {
      return;
    }

    if (
      !row[colDef.field] ||
      (row[colDef.field] && row[colDef.field].length === 0)
    ) {
      return;
    }

    if (event?.type === "click") {
      openModal(params);
    }

    setLoadingCellData(true);
    openRowParams.current = params;

    const {
      clickableCellData,
      clickableCellExternalParams: colClickableCellExternalParams,
    } = colDef;
    try {
      const fnParams =
        clickableCellData.params?.map((param) => row[param]) || [];
      const response = await clickableCellData.api.apply(null, [
        ...fnParams,
        ...(typeof clickableCellExternalParams?.[0] === "object"
          ? [
              ...clickableCellExternalParams,
              ...(colClickableCellExternalParams || []),
            ]
          : [
              [
                ...clickableCellExternalParams,
                ...(colClickableCellExternalParams || []),
              ].join(""),
            ]),
      ]);
      renderCellData(
        response.items || response,
        colDef.field,
        row,
        rawDataRef.current[id]
      );
    } catch (error) {
      if (clickableCellData?.handle404 && error?.status === 404) {
        renderCellData(null, colDef.field, row, rawDataRef.current[id]);
      }
      handleError({
        error,
        handle404: clickableCellData?.handle404 || false,
        addNotification,
      });
    } finally {
      setLoadingCellData(false);
    }
  };

  const fetchDataFromApi = async (_page, _perPage, _filters) => {
    try {
      if (!initApiCall) {
        const offset = (_page - 1) * _perPage;
        totalItemsRef.current = rows.length;
        rawDataRef.current = rows.slice(offset, offset + _perPage);
        const parsedData = parseDataFromTableConfig(
          rawDataRef.current,
          columns
        );
        setTableData(parsedData);
        setOriginalData(rawDataRef.current);
        return;
      }
      setTableData([]);
      setLoading(true);
      const response = await initApiCall(_page, _perPage, _filters);
      if (typeof response === "object" && !isEmpty(response)) {
        totalItemsRef.current = response.totalItems || response.data.totalItems;
        rawDataRef.current = response.items;
        if (response.module !== "tasks") {
          const parsedData = parseDataFromTableConfig(response.items, columns);
          setTableData(parsedData);
          setOriginalData(response.items);
        }
      }
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setTableData([]);
        },
        addNotification,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (initApiCall) {
      setPage(1);
      setPerPage(50);
      await fetchDataFromApi(1, 50, filters);
    }
  }, [filters, location.pathname]);

  useEffect(() => {
    setTableData(rows);
  }, [rows]);

  useEffect(() => {
    if (
      !isEqual(
        previousProp?.clickableCellExternalParams,
        clickableCellExternalParams
      )
    ) {
      handleCellClick(openRowParams.current);
    }
  }, [clickableCellExternalParams]);

  const CustomToolbar = () => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        {exportLoading ? (
          <Box
            display="flex"
            alignItems="center"
            style={{ height: 47.5 }}
            mr={1}
          >
            <CircularProgress size={20} color="inherit" />
          </Box>
        ) : (
          <Box
            mr={1}
            mt={1.5}
            onClick={async () => {
              try {
                setExportLoading(true);
                const response = await initApiCall(1, 100000, {});
                const data = response.items;
                exportToExcel(
                  data,
                  exportColumnsToExclude,
                  moduleName,
                  false,
                  showArrayValueInNewSheet,
                  customizeExportData,
                  exportMainSheetName
                );
              } catch (error) {
                console.error(error);
              } finally {
                setExportLoading(false);
              }
            }}
            style={{
              color: lightTheme.palette.primary.main,
              cursor: "pointer",
            }}
          >
            <Tooltip title="Export as Excel">
              <DownloadIcon />
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <div className={clsx(classes.tableWrapper, tableStyle)}>
      <DataGrid
        rows={tableData}
        className={classes.table}
        columns={[
          ...tableColumns.filter(
            (x) => !filteredColumns.includes(x.headerName)
          ),
          !hideConfig && columnTuneCell,
        ]}
        autoHeight={autoHeight}
        pageSize={hidePagination && rows.length <= 100 ? 100 : perPage}
        sx={
          disableSelectAllCheckbox
            ? {
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                  {
                    display: "none",
                  },
              }
            : {}
        }
        isRowSelectable={isRowSelectable}
        columnBuffer={8}
        loading={loading || externalLoading}
        onCellClick={handleCellClick}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectionModel}
        hideFooterPagination={hidePagination}
        onRowSelectionModelChange={(e) =>
          onSelectionModelChange(e, tableData, originalData)
        }
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooter={hidePagination && rows.length <= 100}
        components={{
          LoadingOverlay: SkeletonLoadingOverlay,
          Footer: () => {
            const [localPage, setLocalPage] = useState(page);
            return (
              <div className={classes.footer}>
                <>
                  <div className={classes.footerOperation}>
                    <span>Rows per page:</span>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={perPage}
                      label="Per Page"
                      onChange={async (e) => {
                        setPerPage(e.target.value);
                        if (onPerPageChange) {
                          onPerPageChange(e.target.value);
                        }
                        await fetchDataFromApi(page, e.target.value, filters);
                      }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={35}>35</MenuItem>
                      <MenuItem value={40}>40</MenuItem>
                      <MenuItem value={45}>45</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </div>
                  {!hidePagination && (
                    <div className={classes.footerOperation}>
                      <span>Go to page</span>
                      <OutlinedInput
                        style={{ width: "70px" }}
                        value={localPage}
                        type="number"
                        onChange={async (e) => {
                          clearTimeout(timeout);
                          const val = Number(e.target.value);
                          if (val > 0) {
                            setLocalPage(val);
                            timeout = setTimeout(async () => {
                              setPage(val);
                              if (onPageChange) {
                                onPageChange(val);
                              }
                              await fetchDataFromApi(val, perPage, filters);
                            }, [1000]);
                          }
                        }}
                      />
                    </div>
                  )}
                  <Pagination
                    page={page}
                    onChange={async (_, nextPage) => {
                      setPage(nextPage);
                      await fetchDataFromApi(nextPage, perPage, filters);
                      if (onPageChange) {
                        onPageChange(nextPage);
                      }
                    }}
                    showFirstButton
                    showLastButton
                    siblingCount={0}
                    disabled={tableData.length === 0}
                    count={Math.ceil(
                      (totalItemsRef.current || totalItems) / perPage
                    )}
                  />
                </>
              </div>
            );
          },
          NoRowsOverlay: () => (
            <div className={classes.noRowsOverlay}>
              {!!notification.message &&
              !loading &&
              !externalLoading &&
              typeof notification.message === "object"
                ? notification.message[0]
                : notification.message}
              {!notification.message &&
                (!loading && !externalLoading
                  ? noRowsText
                  : `${t("common.loading")}...`)}
            </div>
          ),
        }}
      />
      <Drawer
        anchor="right"
        open={tuneDrawerOpen}
        onClose={() => setTuneDrawerOpen(false)}
      >
        <div className={classes.drawerWrapper}>
          <div className={classes.drawerHeaderWrapper}>
            <IconButton
              aria-label="close"
              onClick={() => setTuneDrawerOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography>Manage Columns</Typography>
          </div>
          {columns
            .filter((x) => x.field !== "" && x.headerName !== "")
            .map((column) => (
              <div key={column.headerName}>
                <Switch
                  color="primary"
                  disabled={column.disableToggle}
                  checked={!filteredColumns.includes(column.headerName)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setFilteredColumns((x) => {
                        const draft = [...x, column.headerName];
                        localStorage.setItem(
                          tableUniqueIdentifier,
                          JSON.stringify(draft)
                        );
                        return draft;
                      });
                    } else {
                      setFilteredColumns((x) => {
                        const draft = x.filter((y) => y !== column.headerName);
                        localStorage.setItem(
                          tableUniqueIdentifier,
                          JSON.stringify(draft)
                        );
                        return draft;
                      });
                    }
                  }}
                />
                <span>{column.headerName}</span>
              </div>
            ))}
        </div>
      </Drawer>
    </div>
  );
};

Table.defaultProps = {
  rows: [],
  columns: [],
  onPageChange: () => {},
  autoHeight: false,
  initApiCall: null,
  hidePagination: false,
  moduleName: "",
  tableStyle: "",
  hideConfig: false,
  checkboxSelection: false,
  onSelectionModelChange: () => {},
  noRowsText: i18n.t("common.no_matches_found"),
  selectionModel: [],
  setLoadingCellData: () => {},
  renderCellData: () => {},
  filters: {},
  openModal: () => {},
  externalLoading: false,
  clickableCellExternalParams: [],
  onPerPageChange: () => {},
  isRowSelectable: () => true,
  totalItems: 0,
  disableSelectAllCheckbox: false,
  exportColumnsToExclude: [],
  customizeExportData: undefined,
  showArrayValueInNewSheet: () => false,
  exportMainSheetName: "Sheet1",
};

Table.propTypes = {
  filters: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  moduleName: PropTypes.string,
  initApiCall: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
  hideConfig: PropTypes.bool,
  externalLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
    })
  ),
  isRowSelectable: PropTypes.func,
  renderCellData: PropTypes.func,
  setLoadingCellData: PropTypes.func,
  autoHeight: PropTypes.bool,
  onPageChange: PropTypes.func,
  hidePagination: PropTypes.bool,
  tableStyle: PropTypes.string,
  checkboxSelection: PropTypes.bool,
  onSelectionModelChange: PropTypes.func,
  noRowsText: PropTypes.string,
  selectionModel: PropTypes.arrayOf(PropTypes.any),
  openModal: PropTypes.func,
  clickableCellExternalParams: PropTypes.array,
  onPerPageChange: PropTypes.func,
  totalItems: PropTypes.number,
  disableSelectAllCheckbox: PropTypes.bool,
  exportColumnsToExclude: PropTypes.arrayOf(PropTypes.string),
  customizeExportData: PropTypes.func,
  showArrayValueInNewSheet: PropTypes.func,
  exportMainSheetName: PropTypes.string,
};

export default Table;
