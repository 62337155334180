import { useState, useRef } from "react";

import { Button, Paper, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getJobDetails from "../../../api/get-job-details";
import getJobs from "../../../api/get-jobs";
import getMarketingPrograms from "../../../api/get-marketing-programs";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import marketingProgramsModuleConfig from "../../../config/marketingProgramsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useNotifier from "../../../hooks/useNotifier";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import handleError from "../../../utilities/handleError";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import AutomationStatus from "./components/AutomationStatus";
import MarketingProgramDetailsModal from "./components/MarketingProgramDetailsModal";
import useStyles from "./styles";

const MarketingProgramsContainer = () => {
  const { tableColumns, searchDefaultFilterState, searchFilters } =
    marketingProgramsModuleConfig;
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [jobsDataLoading, setJobsDataLoading] = useState(false);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(false);
  const [jobsData, setJobsData] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [openAutomationStatusModal, setOpenAutomationStatusModal] =
    useState(false);
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "marketingProgramNumber",
    row: {},
  });
  const [mpSelected, setMpSelected] = useState([]);
  const [manageMp, setManageMp] = useState({});
  const [filters, setFilters] = useState({});
  const history = useHistory();

  const { addNotification } = useNotifier();
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const childRef = useRef();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchMarketingPrograms
  );

  const resetDrawer = () => {
    setModalOpen((x) => !x);
    setCellData({
      data: [],
      field: "marketingProgramNumber",
      row: {},
    });
  };

  const loadJobsInfo = async (mpNumber) => {
    try {
      setJobsDataLoading(true);
      const param = `?marketingProgramNumber=${mpNumber}`;
      const response = await getJobs(param);
      setJobsData(response);
    } catch (error) {
      setJobsData([]);
      handleError({
        error,
        handle404: () => {},
        addNotification,
      });
    } finally {
      setJobsDataLoading(false);
    }
  };

  const loadJobDetails = async (jobId) => {
    try {
      setJobDetailsLoading(true);
      const response = await getJobDetails(jobId);
      setJobDetails({
        ...response,
        retryJob: response.retryJob ? t("common.yes") : t("common.no"),
      });
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    } finally {
      setJobDetailsLoading(false);
    }
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const filterFieldMap = {
    marketingProgramNumber: "marketingProgramNumber",
    marketingProgramDescription: "description",
    legalEntity: "legalEntityName",
    regionCode: "regionCode",
    "": "searchText",
  };

  return !isUserAuthorized && !user.loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("common.labels.marketing_programs")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Marketing Programs",
              onClick: () => history.push("/marketing-programs"),
            },
          ]}
        />
        <div className={clsx(classes.marginLeftAuto, classes.flexContainer)}>
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createMarketingPrograms
          ) &&
            (mpSelected.length > 0 ? (
              <Button
                onClick={() => {
                  const [legalEntityNumber, legalEntityName] =
                    manageMp.legalEntity.split(" - ");
                  history.push(
                    applicationConfig.pathnames.marketing_program_manage,
                    {
                      mp: {
                        marketingProgramNumber: manageMp.marketingProgramNumber,
                        description: manageMp.marketingProgramDescription,
                        legalEntityName,
                        legalEntityNumber: Number(legalEntityNumber),
                        marketingProgramName: manageMp.mpBusinessName,
                        title: `${manageMp.marketingProgramNumber} - ${manageMp.marketingProgramDescription}`,
                      },
                    }
                  );
                }}
                classes={{
                  root: globalStyles.btn,
                }}
                variant="outlined"
                style={{
                  marginRight: "34px",
                  justifySelf: "flex-end",
                }}
              >
                {t("manage_mps.manage")}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  history.push(
                    applicationConfig.pathnames.marketing_program_manage,
                    {}
                  );
                }}
                classes={{
                  root: globalStyles.btn,
                }}
                variant="outlined"
                style={{
                  marginRight: "34px",
                  justifySelf: "flex-end",
                }}
              >
                {t("manage_mps.create")}
              </Button>
            ))}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={searchFilters}
            defaultFilterState={searchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={0}>
        <div className={classes.traitsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getMarketingPrograms(
                {
                  requestType: "search",
                },
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="marketing-programs"
            filters={filters}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            setLoadingCellData={setLoadingCellData}
            columns={tableColumns}
            openModal={() => setModalOpen(true)}
            checkboxSelection
            maxSelected={1}
            disableSelectAllCheckbox
            selectionModel={mpSelected}
            customizeExportData={(sheetObj, key, obj) => {
              const newSheetObj = {};
              Object.keys(sheetObj).forEach((sheetKey) => {
                if (sheetKey === "ecosystems") {
                  newSheetObj["MP Ecosystems"] = sheetObj[sheetKey].map(
                    (item) => ({
                      mpn: obj.marketingProgramNumber,
                      description: obj.marketingProgramDescription,
                      ...item,
                    })
                  );
                }
              });
              return newSheetObj;
            }}
            exportMainSheetName="Marketing Programs"
            showArrayValueInNewSheet={(key) => key === "ecosystems"}
            onSelectionModelChange={(e, tableData) => {
              const rows = e;
              if (rows.length > 2) {
                return "";
              }
              const row = rows.filter((newId) => !mpSelected.includes(newId));
              setMpSelected(row);
              setManageMp(tableData[row[0]]);
              return null;
            }}
          />
        </div>
      </Paper>
      {cellData.field === "marketingProgramNumber" && modalOpen && (
        <MarketingProgramDetailsModal
          title={`Marketing Program: ${cellData.row.marketingProgramNumber} - ${cellData.row.marketingProgramDescription}`}
          subtitle="Marketing Program Details"
          data={cellData.data}
          loading={loadingCellData}
          open={modalOpen}
          onClose={() => resetDrawer()}
        />
      )}
      <AutomationStatus
        title={`${t("marketing_programs_container.automation_status")} - MP #${
          cellData.row.marketingProgramNumber
        }`}
        loadingJobs={jobsDataLoading}
        loadingJobDetails={jobDetailsLoading}
        loadJobDetails={async (jobId) => {
          await loadJobDetails(jobId);
        }}
        open={openAutomationStatusModal}
        jobs={jobsData}
        jobDetails={jobDetails}
        loadJobsInfo={async () => {
          setJobDetails({});
          await loadJobsInfo(cellData.row.marketingProgramNumber);
        }}
        onBack={async () => {
          setOpenAutomationStatusModal(false);
          setJobDetails({});
          setModalOpen(true);
        }}
        onClose={() => {
          setOpenAutomationStatusModal(false);
          setJobDetails({});
        }}
      />
    </Layout>
  );
};

export default MarketingProgramsContainer;
