import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { splitCamelCaseString } from "./formValidation";
import truncateString from "./truncateString";

export const preProcessData = (
  data,
  nonIncludedKeys = [],
  showArrayValueInNewSheet = () => false,
  mainSheetName = "Sheet1",
  customizeData = (sheetsData) => sheetsData
) => {
  const processedData = [];

  const sheetsObj = {};
  sheetsObj[mainSheetName] = null;

  let newData = data;
  if (typeof data === "object" && !Array.isArray(data)) {
    newData = [data];
  }

  const addOrModifySheet = (newSheets) => {
    Object.keys(newSheets).forEach((sheetKey) => {
      const sheetName = truncateString(sheetKey, {
        maxLength: 31,
      });
      if (!sheetsObj[sheetName]) {
        sheetsObj[sheetName] = newSheets[sheetKey];
      } else {
        sheetsObj[sheetName] = [
          ...sheetsObj[sheetName],
          ...newSheets[sheetKey],
        ];
      }
    });
  };

  newData.forEach((obj) => {
    const flattenedObj = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (!nonIncludedKeys.includes(key)) {
        if (Array.isArray(value)) {
          if (showArrayValueInNewSheet(key)) {
            const newSheets = customizeData(
              preProcessData(value, [], () => false, key),
              key,
              obj
            );
            addOrModifySheet(newSheets);
          } else if (value.some((item) => typeof item === "object")) {
            flattenedObj[key] = value
              .map((item) => JSON.stringify(item))
              .join(", ");
          } else {
            flattenedObj[key] = value.join(", ");
          }
        } else if (typeof value === "object") {
          if (value) {
            flattenedObj[key] = JSON.stringify(value);
          } else if (!showArrayValueInNewSheet(key)) {
            flattenedObj[key] = "";
          }
        } else if (typeof value === "string") {
          if (showArrayValueInNewSheet(key)) {
            const newSheetObj = {
              properties: value,
            };
            const newSheets = customizeData(newSheetObj, key, obj);
            addOrModifySheet(newSheets);
          } else {
            flattenedObj[key] = truncateString(value, {
              maxLength: 32764,
              showEllipsis: true,
            });
          }
        } else {
          flattenedObj[key] = value;
        }
      }
    });

    if (flattenedObj.ecosystems && flattenedObj.survivorshipRules) {
      const ecosystems = flattenedObj.ecosystems.split(",");
      const survivorshipRules = flattenedObj.survivorshipRules.split(",");
      ecosystems.forEach((ecosystem, index) => {
        const finalData = {
          ...(flattenedObj.traitId
            ? { traitId: flattenedObj.traitId }
            : { marketingProgramNumber: flattenedObj.marketingProgramNumber }),
          description: flattenedObj.description,
          multiAnswerResponseIndicator:
            flattenedObj.multiAnswerResponseIndicator,
          ecosystem,
          survivorshipRule: survivorshipRules[index],
        };

        processedData.push(finalData);
      });
    } else {
      processedData.push(flattenedObj);
    }
  });

  sheetsObj[
    truncateString(mainSheetName, {
      maxLength: 31,
    })
  ] = processedData;

  return sheetsObj;
};

const getTimeStamp = () => {
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
};

export const saveAsXlsx = (workbook, fileName) => {
  const excelBuffer = XLSX.write(workbook, {
    type: "array",
    bookType: "xlsx",
  });

  const timestamp = getTimeStamp();

  // Save the XLSX file using the file-saver library
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${fileName}_${timestamp}.xlsx`);
};

const exportToExcel = (
  data,
  nonIncludedKeys,
  fileName = "data.xlsx",
  multipleSheets = false,
  showArrayValueInNewSheet = () => false,
  customizeData = (sheetsData) => sheetsData,
  mainSheetName = "Sheet1"
) => {
  try {
    let worksheet;
    const workbook = XLSX.utils.book_new();
    if (!multipleSheets) {
      const sheetsObj = preProcessData(
        data,
        nonIncludedKeys,
        showArrayValueInNewSheet,
        mainSheetName,
        customizeData
      );
      Object.keys(sheetsObj).forEach((sheetKey) => {
        worksheet = XLSX.utils.json_to_sheet(sheetsObj[sheetKey]);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetKey);
      });
    } else {
      Object.keys(data).forEach((sheet) => {
        const sheetsObj = preProcessData(
          data[sheet] || [],
          [],
          showArrayValueInNewSheet,
          splitCamelCaseString(sheet).toUpperCase(),
          customizeData
        );
        Object.keys(sheetsObj).forEach((sheetKey) => {
          worksheet = XLSX.utils.json_to_sheet(sheetsObj[sheetKey]);
          XLSX.utils.book_append_sheet(workbook, worksheet, sheetKey);
        });
      });
    }

    saveAsXlsx(workbook, fileName);
  } catch (error) {
    console.error("Export to Excel failed:", error);
  }
};

export default exportToExcel;
