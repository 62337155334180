import { useState, useEffect, useCallback, useMemo } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { Alert, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createEventRequest from "../../../api/create-event-request";
import getEventProperties from "../../../api/get-event-properties";
import getEvents from "../../../api/get-events";
import getRequestDetails from "../../../api/get-request-details";

import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import TextBox from "../../../components/TextBox";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";
import NewEvent from "../components/NewEvent";

import EventProperties from "./EventProperties";

import EventRequestOutputModal from "./EventRequestOutputModal";
import useStyles from "./styles";

const NewEventsContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const { requestStatus } = applicationConfig;
  const { createEventConstants, newEventPropertiesKeys } = eventsModuleConfig;
  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const location = useLocation();
  const [output, setOutput] = useState({});
  const [showOutput, setShowOutput] = useState(false);

  const {
    location: { state },
  } = history;

  let requestId = null;

  if (location.state) {
    requestId = location.state?.requestId;
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [eventsSelected, setEventsSelected] = useState([
    {
      title: "",
      isSelected: false,
      isNewEvent: false,
    },
  ]);
  const [eventProperties, setEventProperties] = useState([]);

  useEffect(() => {
    if (state?.event !== undefined && Object.entries(state.event).length > 0) {
      const makeSelectedObject = {
        title: state.event.eventName,
        isSelected: true,
        eventName: state.event.eventName,
        eventDescription: state.event.description,
      };
      setCurrentStep(currentStep + 1);
      setEventsSelected([makeSelectedObject]);
      if (state.event?.properties && Array.isArray(state.event?.properties)) {
        setEventProperties(
          state.event?.properties?.map((eventProperty) => ({
            ...eventProperty,
            id: eventProperty.propertyName,
          }))
        );
      } else {
        setEventProperties([]);
      }
    }
  }, [state]);

  useEffect(() => {
    if (
      location.pathname === applicationConfig.pathnames.events_requests_revision
    ) {
      if (Object.keys(request).length === 0 && !requestId) {
        history.goBack();
      }
    }
  }, []);

  const isRequestRevisionFlow =
    Object.keys(request).length !== 0 || Boolean(requestId);
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [storeDataLoading, setStoreDataLoading] = useState(false);

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.useEvents
  );

  const totalSteps = 3;

  const { t } = useTranslation();

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const [formErrors, setFormErrors] = useState({});

  const [eventValue, setEventValue] = useState("");
  const [eventOptions, setEventOptions] = useState([]);
  const [initialEventOptions, setInitialEventOptions] = useState([]);
  const [eventsInfo, setEventsInfo] = useImmer({
    eventName: "",
    eventDescription: "",
    eventCategory: "",
    actionTakenBy: "",
    eventType: null,
    eventSubType: null,
    gacaEventName: null,
  });
  const [eventSelectedIndex, setEventSelectedIndex] = useState(-1);
  const [isNewEventDisabled, setIsNewEventDisabled] = useState(false);

  const [eventRequestResponse, setEventRequestResponse] = useState([]);

  // current event index
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const [eventsLoading, setEventsLoading] = useState(false);

  const diffList = useMemo(() => {
    return eventProperties.filter((item) => item.new === true);
  }, [eventProperties]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
    setEventsInfo({
      eventName: "",
      eventDescription: "",
      eventCategory: "",
      actionTakenBy: "",
      eventType: null,
      eventSubType: null,
      gacaEventName: null,
    });
    setEventValue("");
    setIsNewEventDisabled(false);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const validateForm = (step) => {
    const errors = {};
    if (step === 1) {
      if (
        eventsSelected.filter((event) => event.title).length !==
        eventsSelected.length
      ) {
        errors.events = eventsSelected.map((event) => {
          if (!event.title) {
            return {
              error: t("create_event.errors.select_event"),
            };
          }
          return {
            error: "",
          };
        });
      }
    }
    if (step === 2) {
      if (isEmpty(diffList)) {
        errors.noChangesError = t("create_use_case.no_events_changes");
      }
    }
    setFormErrors(errors);
    return errors;
  };

  const isInfoStep = currentStep > totalSteps;

  const newEvent = (
    <div className="newEvent">
      <Typography variant="h6">
        {t("create_event.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
      >
        {t("create_event.new_event")}
      </Button>
    </div>
  );

  const getEventsFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const filterFieldMap = {
        searchText: "searchText",
      };

      const rsp1 = await getEvents(
        null,
        null,
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap, // new URLSearchParams(filter).toString(),
          1,
          6
        )
      );
      setEventOptions([...rsp1.items, newEvent]);
      if (searchText.length === 0) {
        if (initialEventOptions.length > 0) {
          const newInitialEventOptions = [...initialEventOptions];
          newInitialEventOptions.splice(
            newInitialEventOptions.length - 1,
            0,
            ...rsp1.items
          );
          setInitialEventOptions(newInitialEventOptions);
        } else {
          setInitialEventOptions([...rsp1.items, newEvent]);
        }
      }
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setEventOptions([newEvent]);
        },
        addNotification,
      });
    } finally {
      setEventsLoading(false);
    }
  }, []);

  const handleSubmit = (eventInfo) => {
    const currentEvents = [...eventsSelected];
    currentEvents[currentEventIndex] = {
      ...eventInfo,
      title: eventInfo.eventName,
      isSelected: true,
      isNewEvent: true,
    };

    setEventsSelected(currentEvents);
    handleClose();
  };

  // Debounce & Memoize Api Calls
  const debouncedEventsFromApi = debounce(
    getEventsFromApi,
    applicationConfig.waitTime
  );

  const memoizedEventsFromApi = useCallback(async (val) => {
    debouncedEventsFromApi(val);
  }, []);

  useEffect(() => {
    if (eventValue.length > 0) {
      memoizedEventsFromApi(eventValue);
    }
  }, [eventValue]);

  const setEventsData = (data) => {
    const { event, eventProperties: evtProperties } = data.requestDetails;
    setEventsSelected([
      {
        ...event,
        title: event.eventName,
        isSelected: true,
      },
    ]);
    setEventProperties(evtProperties);
    setCurrentStep(2);
  };

  useEffect(async () => {
    if (isRequestRevisionFlow && !requestId) {
      setEventsData(request);
    } else if (requestId) {
      const details = await getRequestDetails(requestId);
      setEventsData(details);
    }
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(2);
      await getEventsFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  useEffect(async () => {
    const filteredInitialEventOptions = initialEventOptions.filter((_event) => {
      if (_event.type !== "div") {
        const isExisting = eventsSelected.find(
          (existingEvent) => existingEvent.title === _event.eventName
        );
        if (isExisting) {
          return false;
        }
        return true;
      }
      return true;
    });
    if (
      initialEventOptions.length > 0 &&
      filteredInitialEventOptions.length < 4 &&
      !eventsLoading
    ) {
      setEventsLoading(true);
      try {
        await getEventsFromApi(
          "",
          Math.floor(initialEventOptions.length / 6 + 1),
          6
        );
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      }
    }
  }, [initialEventOptions, eventsSelected, getEventsFromApi]);

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.event_name"),
        value: eventsSelected.map((event) => {
          if (event.isNewEvent) {
            return (
              <div
                key={`${event.title}`}
                className={clsx(classes.flexContainer, classes.marginTop20)}
              >
                <div
                  className={classes.newBtnContainer}
                  onClick={() => {
                    setIsNewEventDisabled(true);
                    setEventsInfo(event);
                    setOpenModal(true);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  <span className="eventName">{event.title}</span>
                  <Button variant="contained">{t("common.new")}</Button>
                </div>
              </div>
            );
          }
          return <div>{event.title}</div>;
        }),
      },
    ];
    return info;
  };

  const handleEventOutputModal = async () => {
    try {
      setShowOutput(true);
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const createRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "eventName",
      headerName: t("common.labels.event_name"),
      flex: 1,
      renderCell: (params) => {
        const { value, row } = params;
        return row.isNewEvent ? (
          <div role="button" aria-hidden="true" className={classes.eventName}>
            <span>{value}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        ) : (
          <span>{value}</span>
        );
      },
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  handleEventOutputModal(params.row.requestId);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    (async () => {
      if (eventsSelected?.length === 0 || !eventsSelected[0].eventName) {
        setEventProperties([]);
        return;
      }
      try {
        setStoreDataLoading(true);
        const data = await getEventProperties(eventsSelected[0].eventName, {
          denormalize: false,
        });
        if (
          isRequestRevisionFlow &&
          request.requestDetails.event?.eventName ===
            eventsSelected[0].eventName
        ) {
          setEventProperties([
            ...data.properties.map((eventProperty) => ({
              ...eventProperty,
              id: eventProperty.propertyName,
            })),
            ...request.requestDetails.eventProperties,
          ]);
        } else {
          setEventProperties(
            data.properties.map((eventProperty) => ({
              ...eventProperty,
              id: eventProperty.propertyName,
            }))
          );
        }
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
      } finally {
        setStoreDataLoading(false);
      }
    })();
  }, [eventsSelected[0].eventName]);

  const renderStep = (step) => {
    if (step === 1) {
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      const eventDropdownValues =
        eventValue.length > 0 ? eventOptions : initialEventOptions;
      return (
        <div className={classes.center} data-testid="events-container">
          {eventsSelected.map((event, eventIndex) => {
            const eventName = event.title;
            let errorText = "";
            if (formErrors.events && formErrors.events[eventIndex]) {
              errorText = formErrors.events[eventIndex].error;
            }
            return !event.isSelected ? (
              <div
                className={clsx(classes.flexContainer, classes.autoCompleteBox)}
                key={`${eventName} - ${eventIndex + 1}`}
              >
                <CustomAutoComplete
                  isMultiple={false}
                  classes={{
                    listbox: classes.listbox,
                  }}
                  options={(() => {
                    const options = eventDropdownValues
                      .filter((_event) => {
                        if (_event.type !== "div") {
                          const isExisting = eventsSelected.find(
                            (existingEvent) =>
                              existingEvent.eventName === _event.eventName
                          );
                          if (isExisting) {
                            return false;
                          }
                          return true;
                        }
                        return true;
                      })
                      .map((option) => {
                        if (option.type !== "div") {
                          return {
                            title: `${option.eventName}`, // - ${option.eventName} - ${option.description}
                            ...option,
                          };
                        }
                        return option;
                      });
                    if (options.length > 4) {
                      return [...options.slice(0, 3), ...options.slice(-1)];
                    }
                    return options;
                  })()}
                  id={`event-${eventIndex}`}
                  placeholder={
                    loading
                      ? t("common.loading")
                      : t("autocomplete.event_select_or_event_placeholder")
                  }
                  loading={eventsLoading}
                  value={
                    event.title && [
                      {
                        title: event.title,
                      },
                    ]
                  }
                  closeIcon={<></>}
                  isExternalInputValue
                  inputValue={eventValue}
                  setValue={(val) => {
                    if (val && val.type !== "div") {
                      if (val) {
                        const tempEventName = val.title;
                        const option = [
                          ...eventOptions,
                          ...initialEventOptions,
                        ].find((Option) => Option.eventName === tempEventName);
                        const newEvents = [...eventsSelected];

                        newEvents[eventIndex].title = val.title;
                        newEvents[eventIndex].isSelected =
                          !newEvents[eventIndex].isSelected;
                        newEvents[eventIndex].isNewEvent = false;
                        newEvents[eventIndex].eventName = option.eventName;
                        newEvents[eventIndex] = {
                          ...newEvents[eventIndex],
                          ...option,
                        };
                        setEventsSelected(newEvents);
                      }
                    }
                  }}
                  onOpen={() => {
                    setCurrentEventIndex(eventIndex);
                  }}
                  onInputChange={(val) => {
                    setEventValue(val);
                    setEventOptions([]);
                    setEventsLoading(true);
                  }}
                  error={Boolean(errorText)}
                  errorText={errorText}
                />
                {eventIndex !== 0 && (
                  <div
                    className={classes.autoCompleteDeleteContainer}
                    onClick={() => {
                      const newEvents = [...eventsSelected];
                      newEvents.splice(eventIndex, 1);
                      if (newEvents.length > 0) {
                        setEventsSelected(newEvents);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </div>
                )}
              </div>
            ) : (
              <div
                key={`${eventName}-${eventIndex + 1}`}
                className={classes.fullWidth}
              >
                <TextBox
                  isNew={eventsSelected[eventIndex].isNewEvent}
                  name={eventName}
                  onClick={() => {
                    // EDIT NEW EVENT
                    if (eventsSelected[eventIndex].isNewEvent) {
                      setEventsInfo({
                        ...eventsSelected[eventIndex],
                      });
                      setCurrentEventIndex(eventIndex);
                      setOpenModal(true);
                    } else {
                      // DELETE EVENT
                      setOpenDeleteModal(true);
                      setEventSelectedIndex(eventIndex);
                    }
                  }}
                  onDelete={() => {
                    // DELETE EVENT
                    setOpenDeleteModal(true);
                    setEventSelectedIndex(eventIndex);
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }
    if (step === 2) {
      if (storeDataLoading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <>
          <div className={classes.marketingProgramContainer}>
            <div
              className={clsx(
                classes.flexContainer,
                classes.infoContainer,
                classes.justifyContent
              )}
            >
              <Typography variant="h6">
                {t("common.labels.event_name")} :
              </Typography>
              <div style={{ flex: "0 0 52%" }}>
                {eventsSelected.map((event) => {
                  if (event.isNewEvent) {
                    return (
                      <div
                        key={`${event.title}`}
                        className={clsx(
                          classes.flexContainer,
                          classes.marginTop20
                        )}
                      >
                        <div
                          className={classes.newBtnContainer}
                          onClick={() => {
                            setIsNewEventDisabled(true);
                            setEventsInfo(event);
                            setOpenModal(true);
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                        >
                          <span className="eventName">{event.title}</span>
                          <Button variant="contained">{t("common.new")}</Button>
                        </div>
                      </div>
                    );
                  }
                  return <div>{event.title}</div>;
                })}
              </div>
            </div>
          </div>
          <Typography variant="h4" className={classes.h4}>
            {t("create_event.create_event_property.property_heading")}
          </Typography>
          <EventProperties
            eventProperties={eventProperties}
            eventPropertiesKeys={newEventPropertiesKeys}
            setEventProperties={setEventProperties}
            onDeleteProperty={(property) => {
              setEventProperties((properties) =>
                properties.filter(
                  (v) => v.propertyName !== property.propertyName
                )
              );
            }}
            onEditProperty={(property) => {
              setEventProperties((properties) =>
                properties.map((v) =>
                  v.propertyName === property.propertyName ? property : v
                )
              );
            }}
          />
          {formErrors.noChangesError && (
            <Alert style={{ marginTop: "16px" }} severity="error">
              {formErrors.noChangesError}
            </Alert>
          )}
        </>
      );
    }
    if (step === 3) {
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            style={{ alignItems: "flex-start" }}
            key={`${info.label}${info.index}`}
          >
            <Typography className="label" variant="h6">
              {info.label} :
            </Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });

      return (
        <>
          {detailsDOM}
          <Typography variant="h4" className={classes.h4}>
            {t("create_event.create_event_property.property_heading")}
          </Typography>
          <EventProperties
            eventProperties={diffList}
            eventPropertiesKeys={newEventPropertiesKeys}
            setEventProperties={setEventProperties}
            onDeleteProperty={(version) => {
              setEventProperties((versions) =>
                versions.filter((v) => v.id !== version.id)
              );
            }}
            onEditProperty={(property) => {
              setEventProperties((properties) =>
                properties.map((v) => (v.id === property.id ? property : v))
              );
            }}
            viewOnly
          />
        </>
      );
    }
    return (
      <div
        style={{
          height: 70 * eventRequestResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table
          columns={createRequestTableColumns}
          rows={eventRequestResponse}
        />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/events" goToText="GO TO EVENTS" />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        steps={[
          t("create_events.headings.step_1"),
          t("create_events.headings.step_2"),
          t("create_events.headings.step_3"),
        ]}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        header={
          <>
            <Typography variant="h4" gutterBottom>
              {isRequestRevisionFlow
                ? createEventConstants.requestRevisionHeadings[currentStep]
                : createEventConstants.eventCreationHeadings[currentStep]}
            </Typography>
            {currentStep === 2 && (
              <Typography variant="h6" gutterBottom>
                {t("create_event.mapping_events")}:{" "}
                {eventsSelected.map((event, index) => {
                  if (event.isNewEvent) {
                    return (
                      <div
                        className={classes.newBtnContainer}
                        key={event.title}
                      >
                        <span>{event.title}</span>
                        <Button variant="contained">{t("common.new")}</Button>
                        {index !== eventsSelected.length - 1 && ","}
                      </div>
                    );
                  }

                  return index === eventsSelected.length - 1
                    ? event.title
                    : `${event.title}, `;
                })}
              </Typography>
            )}
          </>
        }
        headerText={t("events_container.events_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{
                        root: globalStyles.btn,
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                      root: globalStyles.btn,
                    }}
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                disabled={loading}
                className="nextStep"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const propertiesData = eventProperties.filter(
                        (item) => item.new === true
                      );
                      const eventSelected = {
                        eventName: eventsSelected[0].eventName
                          ? eventsSelected[0].eventName
                          : eventsSelected[0].eventName,
                        isNew: eventsSelected[0].isNewEvent,
                      };
                      if (eventsSelected[0].isNewEvent) {
                        eventSelected.description =
                          eventsSelected[0].eventDescription;
                        eventSelected.eventCategory =
                          eventsSelected[0].eventCategory;
                        eventSelected.actionTakenBy =
                          eventsSelected[0].actionTakenBy;
                        if (eventsSelected[0].eventType) {
                          eventSelected.eventType = eventsSelected[0].eventType;
                        }
                        if (eventsSelected[0].eventSubType) {
                          eventSelected.eventSubType =
                            eventsSelected[0].eventSubType;
                        }
                        if (eventsSelected[0].gacaEventName) {
                          eventSelected.gacaEventName =
                            eventsSelected[0].gacaEventName;
                        }
                      }
                      const payload = {
                        event: eventSelected,
                        eventProperties: propertiesData,
                      };

                      if (!isRequestRevisionFlow) {
                        const data = await createEventRequest(payload);
                        const { items } = data;
                        setEventRequestResponse(
                          items.map((item, index) => ({
                            id: index,
                            requestId: item.requestId,
                            eventName: item.event.eventName,
                            status:
                              item.status.charAt(0).toUpperCase() +
                              item.status.slice(1),
                            isNewEvent: !item.event.isNew,
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                        setOutput(items?.[0]?.output);
                      } else {
                        const data = await updateRequest(
                          request.requestId || requestId,
                          payload,
                          applicationConfig.modules.events // events
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    const errors = validateForm(currentStep);
                    setFormErrors(errors);
                    if (Object.keys(errors).length > 0) {
                      return null;
                    }
                    setCurrentStep(currentStep + 1);
                  }
                  return null;
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}

                {createEventConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/events"
                >
                  {t("common.labels.back_to_events")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${eventRequestResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.eventContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {openModal && (
        <NewEvent
          openModal={openModal}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          eventsInfo={eventsInfo}
          setEventsInfo={setEventsInfo}
          isDisabled={isNewEventDisabled}
          eventsSelected={eventsSelected}
          isRequestRevisionFlow={isRequestRevisionFlow}
          title={t("create_event.new_event")}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("create_events.event_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          const newEvents = [...eventsSelected];
          newEvents.splice(eventSelectedIndex, 1);
          if (newEvents.length > 0) {
            setEventsSelected(newEvents);
          } else {
            setEventsSelected([
              {
                title: "",
                isSelected: false,
                isNewEvent: false,
              },
            ]);
          }
          onCloseDeleteModal();
          setEventProperties([]);
        }}
        type="error"
      />
      {currentStep <= 3 && (
        <Prompt
          message={() => {
            if (isRequestRevisionFlow) {
              return true;
            }
            return t("prompt.progress_lost");
          }}
        />
      )}
      {showOutput && (
        <EventRequestOutputModal
          isOpen={showOutput}
          isLoading={loading}
          requestId={eventRequestResponse[0].requestId}
          data={output}
          setShowOutput={setShowOutput}
        />
      )}
    </>
  );
};

export default NewEventsContainer;
