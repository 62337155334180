import api from "../utilities/api";

import { getValue } from "../utilities/cache";

const getServiceNames = async (marketingProgramNumber, serviceName) => {
  const refreshCache = getValue("marketing-programs");
  let url = `/marketing-programs/${marketingProgramNumber}/service-names`;
  if (serviceName.length > 0) {
    url += `?serviceName=${serviceName}&refreshCache=${refreshCache}`;
  } else {
    url += `?refreshCache=${refreshCache}`;
  }
  const {
    data: { data },
  } = await api.get(url);

  return data;
};

export default getServiceNames;
