import getEvents from "../api/get-events";
import getEventsResponse from "../api/get-events-response";
import getUseCaseDetails from "../api/get-use-case-details";
import i18n from "../i18n/init";

const eventsModuleConfig = {
  eventsSearchFilters: [
    {
      name: "eventName",
      label: i18n.t("common.labels.event_name"),
    },
    {
      name: "eventDescription",
      label: i18n.t("events_container.search_filter.event_description"),
    },
    {
      name: "propertyName",
      label: i18n.t("common.labels.event_property_name"),
    },
    {
      name: "propertyDescription",
      label: i18n.t(
        "events_container.search_filter.event_property_description"
      ),
    },
  ],
  eventsTableColumns: [
    {
      field: "eventName",
      headerName: i18n.t("common.labels.event_name"),
      sortable: false,
      clickable: true,
      clickableCellData: {
        params: ["eventName"],
        api: getEvents,
      },
      disableToggle: true,
    },
    {
      field: "eventDescription",
      sortable: false,
      headerName: i18n.t("events_container.table_columns.event_description"),
    },
    {
      field: "gacaEventName",
      sortable: false,
      headerName: i18n.t("events_container.table_columns.gaca_event_name"),
    },
    {
      field: "actionTakenBy",
      sortable: false,
      headerName: i18n.t("events_container.table_columns.action_taken_by"),
    },
  ],
  eventsResponsesTableColumns: [
    {
      field: "eventName",
      sortable: false,
      headerName: i18n.t("common.labels.event_name"),
      disableToggle: true,
    },
    {
      field: "propertyName",
      sortable: false,
      headerName: i18n.t("common.labels.event_property_name"),
      disableToggle: true,
    },
    {
      field: "eventPropertyDescription",
      sortable: false,
      headerName: i18n.t(
        "events_container.search_filter.event_property_description"
      ),
    },
    {
      field: "eventPropertyResponses",
      sortable: false,
      headerName: i18n.t("common.labels.events_responses"),
      truncate: true,
      clickable: true,
      parseData: (row) =>
        row.predefinedEventPropertyResponses?.map(
          (eventProperty) => eventProperty.predefinedEventPropertyResponse
        ),
      clickableCellData: {
        params: ["propertyName", "eventName"],
        api: getEventsResponse,
        handle404: true,
      },
    },
  ],
  eventsResponsesKeys: [
    {
      id: "predefinedEventPropertyResponseLocalLanguage",
      name: i18n.t(
        "eventsResponses.accordion_keys.event_property_response_local_language"
      ),
    },
    {
      id: "language",
      name: i18n.t("eventsResponses.accordion_keys.language"),
    },
    {
      id: "predefinedEventPropertyResponse",
      name: i18n.t(
        "eventsResponses.accordion_keys.event_property_response_english"
      ),
    },
    {
      id: "globalStandardResponse",
      name: i18n.t(
        "eventsResponses.accordion_keys.predefined_event_property_response"
      ),
    },
  ],
  eventResponsesSearchFilters: [
    {
      name: "eventName",
      label: i18n.t("common.labels.event_name"),
    },
    {
      name: "propertyName",
      label: i18n.t("common.labels.event_property_name"),
    },
    {
      name: "eventPropertyResponseEnglish",
      label: i18n.t("eventsResponses.search_filter.events_response_english"),
    },
    {
      name: "eventPropertyResponseLocalLanguage",
      label: i18n.t(
        "eventsResponses.search_filter.events_response_local_language"
      ),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.marketing_program"),
    },
  ],
  eventResponsesSearchDefaultFilterState: {
    eventName: false,
    propertyName: false,
    eventResponseEnglish: false,
    eventResponseLocalLanguage: false,
    marketingProgram: false,
  },
  eventsSearchDefaultFilterState: {
    eventName: false,
    eventDescription: false,
    propertyName: false,
    propertyDescription: false,
  },
  eventPropertiesKeys: [
    {
      id: "propertyName",
      name: i18n.t("common.labels.event_property_name"),
    },
    {
      id: "description",
      name: i18n.t("common.labels.description"),
    },
    {
      id: "dataType",
      name: i18n.t("common.labels.data_type"),
    },
    {
      id: "sampleValues",
      name: i18n.t("events_container.event_properties_keys.sample_values"),
    },
    {
      id: "subProperties",
      name: i18n.t("events_container.event_properties_keys.sub_properties"),
    },
    {
      id: "required",
      name: "Required for Event",
    },
    {
      id: "personalData",
      name: "Personal (PII) Data",
    },
    {
      id: "personalDataNonPii",
      name: "Sensitive (NON-PII) Data",
    },
    {
      id: "healthData",
      name: "Health Data",
    },
  ],
  newEventPropertiesKeys: [
    {
      id: "propertyName",
      name: i18n.t("common.labels.event_property_name"),
    },
    {
      id: "description",
      name: i18n.t("common.labels.description"),
    },
    {
      id: "dataType",
      name: i18n.t("common.labels.data_type"),
    },
    {
      id: "sampleValues",
      name: i18n.t("events_container.event_properties_keys.sample_values"),
    },
    {
      id: "required",
      name: "Required for Event",
    },
    {
      id: "personalData",
      name: "Personal (PII) Data",
    },
    {
      id: "personalDataNonPii",
      name: "Sensitive (NON-PII) Data",
    },
    {
      id: "healthData",
      name: "Health Data",
    },
  ],
  eventsRequestsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "eventName",
      label: i18n.t("common.labels.event_name"),
    },
  ],
  eventsApprovalsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "eventName",
      label: i18n.t("common.labels.event_name"),
    },
    {
      name: "requester",
      label: i18n.t("common.labels.requester"),
    },
  ],
  useEventsConstants: {
    eventsHeadings: {
      1: i18n.t("use_events_container.headings.step_1"),
      2: i18n.t("use_events_container.headings.step_1"),
      3: i18n.t("use_events_container.headings.step_3"),
      4: i18n.t("use_events_container.headings.step_4"),
    },
    eventsMessages: {
      1: i18n.t("use_events_container.steps.step_1"),
      2: i18n.t("use_events_container.steps.step_2"),
      3: i18n.t("common.texts.request_details"),
    },
  },
  eventsUseCaseTableColumns: [
    {
      field: "useCaseId",
      sortable: false,
      headerName: i18n.t("manage_use_cases.table_columns.use_case_id"),
      flex: 0.3,
      disableToggle: true,
    },
    {
      field: "useCaseName",
      sortable: false,
      headerName: i18n.t("common.labels.use_case_name"),
    },
    {
      field: "useCaseDescription",
      sortable: false,
      headerName: i18n.t("manage_use_cases.table_columns.use_case_description"),
    },
    {
      field: "relatedEventProperties",
      sortable: false,
      headerName: i18n.t(
        "manage_use_cases.table_columns.related_event_properties"
      ),
      truncate: true,
      clickable: true,
      parseData: (row) =>
        row.eventProperties.map(({ eventPropertyName }) => eventPropertyName),
      clickableCellData: {
        params: ["useCaseId"],
        api: getUseCaseDetails,
      },
    },
  ],
  useCaseSearchDefaultFilterState: {
    useCaseDescription: false,
    useCaseName: false,
    eventProperty: false,
    marketingProgram: false,
  },
  eventsUsecaseSearchFilters: [
    {
      name: "useCaseName",
      label: i18n.t("common.labels.use_case_name"),
    },
    {
      name: "useCaseDescription",
      label: i18n.t("traits_usecases.search_filter.usecase_description"),
    },
    {
      name: "eventProperty",
      label: i18n.t("common.labels.event_property_name"),
    },
    {
      name: "marketingProgram",
      label: i18n.t("common.labels.marketing_program"),
    },
  ],
  createEventConstants: {
    eventCategoryOptions: [
      i18n.t("constants.event_category.consumer_action"),
      i18n.t("constants.event_category.email_ctl"),
      i18n.t("constants.event_category.iot"),
      i18n.t("constants.event_category.loyalty"),
      i18n.t("constants.event_category.postal_CLT"),
      i18n.t("constants.event_category.push_CLT"),
      i18n.t("constants.event_category.replenishment"),
      i18n.t("constants.event_category.sms_CLT"),
      i18n.t("constants.event_category.sales_transaction"),
      i18n.t("constants.event_category.website_event"),
    ],
    dataType: [
      {
        option: "string",
        label: i18n.t("constants.data_type_options.string"),
      },
      {
        option: "integer",
        label: i18n.t("constants.data_type_options.integer"),
      },
      {
        option: "decimal",
        label: i18n.t("constants.data_type_options.decimal"),
      },
      {
        option: "date-time",
        label: i18n.t("constants.data_type_options.date_time"),
      },
      {
        option: "date",
        label: i18n.t("constants.data_type_options.date"),
      },
      {
        option: "array",
        label: i18n.t("constants.data_type_options.array"),
      },
      {
        option: "json",
        label: i18n.t("constants.data_type_options.json"),
      },
      {
        option: "boolean",
        label: i18n.t("constants.data_type_options.boolean"),
      },
    ],
    actionTakenByOptions: [
      i18n.t("constants.action_taken_by_options.pg"),
      i18n.t("constants.action_taken_by_options.consumer"),
    ],
    isRequiredOptions: [
      {
        option: true,
        label: i18n.t("common.yes"),
      },
      {
        option: false,
        label: i18n.t("common.no"),
      },
    ],
    personalDataTooltip: i18n.t("constants.personal_data_tooltip"),
    sensitiveDataTooltip: i18n.t("constants.sensitive_data_tooltip"),
    healthDataTooltip: i18n.t("constants.health_data_tooltip"),
    eventCreationHeadings: {
      1: i18n.t("create_trait.headings.step_1"),
      2: i18n.t("create_trait.headings.step_1"),
      3: i18n.t("create_trait.headings.step_3"),
    },
    requestRevisionHeadings: {
      1: i18n.t("revise_request.headings.step_1"),
      2: i18n.t("revise_request.headings.step_1"),
      3: i18n.t("revise_request.headings.step_3"),
    },
    footerText: {
      1: i18n.t("common.labels.proceed_to_mapping"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
    characterLimits: {
      eventName: 50,
      eventDescription: 500,
      eventType: 2,
    },
  },
};

export default eventsModuleConfig;
