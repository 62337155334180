import api from "../utilities/api";

const getJobs = async (queryParams) => {
  const url = "/jobs";
  const queryParamsVal = queryParams.replace(
    "success partial",
    "success-partial"
  );
  const {
    data: { data },
  } = await api.get(url + queryParamsVal);

  return data;
};

export default getJobs;
