import { useState } from "react";

import { Typography, Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useGlobalStyles from "../../assets/styles/global";

import applicationConfig from "../../config/applicationConfig";
import traitsModuleConfig from "../../config/traitsModuleConfig";

import reverseObject from "../../utilities/reverseObject";

import CustomModal from "../CustomModal";
import Table from "../Table";

import useStyles from "./styles";

export const TraitsMapping = ({ marketingPrograms, mpLevel }) => {
  const { survivorshipRuleMapping } = traitsModuleConfig;
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const globalClasses = useGlobalStyles();
  const mpEcosystemRuleMappings = [];
  const mpTraitResponseMappings = [];
  const mpEcosystemRuleColumns = [
    !mpLevel
      ? {
          field: "marketingProgram",
          headerName: "Marketing Program",
          width: 250,
          sortable: false,
        }
      : {
          field: "trait",
          headerName: "Trait",
          width: 250,
          sortable: false,
        },
    {
      field: "ecosystem",
      headerName: "Ecosystem",
      width: 150,
      sortable: false,
    },
    {
      field: "rule",
      headerName: "Survivorship Rule",
      width: 200,
      sortable: false,
    },
  ];
  const mpTraitResponseColumns = [
    !mpLevel
      ? {
          field: "marketingProgram",
          headerName: "Marketing Program",
          width: 400,
          sortable: false,
        }
      : {
          field: "trait",
          headerName: "Trait",
          width: 400,
          sortable: false,
        },
    {
      field: "traitResponseType",
      headerName: "Trait Response Type",
      width: 300,
      sortable: false,
    },
  ];

  const ruleMapping = reverseObject(survivorshipRuleMapping);
  let index = 0;
  marketingPrograms.forEach((program) => {
    const rules = program.survivorshipRules.split(",");
    const mappings = program.ecosystems.split(",").map((ecosystem, id) => {
      index += 1;
      return {
        ecosystem,
        marketingProgram: `${program.marketingProgramNumber} - ${program.description}`,
        trait: `${program.traitId} - ${program.description}`,
        rule: ruleMapping[rules[id]],
        id: index,
      };
    });
    mpEcosystemRuleMappings.push(...mappings);
    mpTraitResponseMappings.push({
      marketingProgram: `${program.marketingProgramNumber} - ${program.description}`,
      trait: `${program.traitId} - ${program.description}`,
      traitResponseType: program.multiAnswerResponseIndicator
        ? "Multiple"
        : "Single",
      id: `${program.description} - ${program.multiAnswerResponseIndicator}`,
    });
  });

  return (
    <div>
      <Tabs
        value={tab}
        onChange={(_, number) => setTab(number)}
        aria-label="basic tabs"
        classes={{
          indicator: globalClasses.indicator,
        }}
      >
        <Tab label="MP Mappings" />
        <Tab label="MP Ecosystem Mappings" />
      </Tabs>
      {tab === 0 && (
        <Table
          columns={mpTraitResponseColumns}
          rows={mpTraitResponseMappings}
          tableStyle={classes.table}
          hidePagination
          hideConfig
          totalItems={mpTraitResponseMappings.length}
        />
      )}
      {tab === 1 && (
        <Table
          columns={mpEcosystemRuleColumns}
          rows={mpEcosystemRuleMappings}
          tableStyle={classes.table}
          hidePagination
          hideConfig
          totalItems={mpEcosystemRuleMappings.length}
        />
      )}
    </div>
  );
};

TraitsMapping.propTypes = {
  marketingPrograms: PropTypes.shape([]).isRequired,
  mpLevel: PropTypes.bool.isRequired,
};

export const MarketingPrograms = ({ type, programs, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const getSourcesMapping = (program, index) => {
    // Sources
    return (
      <div key={`${program.marketingProgamNumber} - ${index}`}>
        <Typography>
          {index + 1}. {program.marketingProgramNumber} -{" "}
          {program.marketingProgramDescription}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.programs}>
      {type === applicationConfig.modules.data_sources && (
        <Typography gutterBottom paragraph>
          This Data Source is used by the following Marketing Programs
        </Typography>
      )}
      {type === applicationConfig.modules.data_sources && (
        <div>{programs.map((program, i) => getSourcesMapping(program, i))}</div>
      )}
      {type === applicationConfig.modules.traits && programs.length > 0 && (
        <div>
          <TraitsMapping marketingPrograms={programs} />
        </div>
      )}
      {programs.length === 0 && !loading && (
        <Typography variant="body2">
          {t("marketing_programs.no_mapping")}
        </Typography>
      )}
    </div>
  );
};

MarketingPrograms.defaultProps = {
  type: applicationConfig.modules.traits,
};

MarketingPrograms.propTypes = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      marketingProgramName: PropTypes.string,
      ecosystems: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

const MarketingProgramsModal = ({
  title,
  subtitle,
  programs,
  loading,
  open,
  onClose,
  type,
}) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      showCloseIcon
    >
      <MarketingPrograms type={type} programs={programs} loading={loading} />
    </CustomModal>
  );
};

MarketingProgramsModal.defaultProps = {
  type: applicationConfig.modules.traits,
  title: null,
};

MarketingProgramsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      marketingProgramName: PropTypes.string,
      ecosystems: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export default MarketingProgramsModal;
