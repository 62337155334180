import { Typography } from "@material-ui/core";

import StyledTooltip from "../../../components/StyledTooltip";

import i18n from "../../../i18n/init";

import truncateString from "../../../utilities/truncateString";

const optsMappingTableColumns = [
  {
    field: "optId",
    headerName: i18n.t("common.labels.opt_id"),
    flex: 1,
    tooltip: false,
    customJSX: true,
    sortable: false,
    disableToggle: true,
  },
  {
    field: "optText",
    headerName: i18n.t("common.labels.opt_text"),
    flex: 1,
    truncate: true,
    renderCell: (params) => {
      const { value } = params;

      return (
        <StyledTooltip placement="top" title={value}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Typography variant="h6">{truncateString(value)}</Typography>
          </div>
        </StyledTooltip>
      );
    },
    sortable: false,
  },
  {
    field: "subId",
    headerName: i18n.t("opts_status_trait.mapping_columns.sub_id"),
    flex: 1,
    sortable: false,
  },
  {
    field: "serviceName",
    headerName: i18n.t("common.labels.service_name"),
    flex: 1,
    sortable: false,
  },
  {
    field: "channelType",
    headerName: i18n.t("opts_status_trait.mapping_columns.channel_type"),
    flex: 1,
    sortable: false,
  },
  {
    field: "operations",
    headerName: "",
    sortable: false,
    customJSX: true,
    flex: 1,
  },
];

export default optsMappingTableColumns;
