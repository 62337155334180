import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";

const { setValue } = require("../utilities/cache");

const { requestIdPrefix } = applicationConfig;

const createMarketingProgram = async (body, validate) => {
  let url = `/marketing-programs`;
  if (validate) {
    url = `${url}?validateOnly=true`;
  }
  let {
    data: { data },
  } = await api.post(url, body);

  if (!validate) {
    if (data.some((item) => item.status === "approved")) {
      setValue("marketing-programs");
    }
    data = data.map((item) => ({
      ...item,
      requestId: `${requestIdPrefix}${item.requestId}`,
    }));
  }
  return data;
};

export default createMarketingProgram;
