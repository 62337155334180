import { forwardRef, useImperativeHandle, useState } from "react";

import {
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const SearchFilter = forwardRef((props, ref) => {
  const classes = useStyles();
  const { searchFilters, defaultFilterState } = props;
  const [searchInput, setSearchInput] = useState("");
  const [searchMenuVisible, setSearchMenuVisible] = useState(false);
  const [searchFilterChecked, setSearchFilterChecked] = useState(() => {
    return {
      ...defaultFilterState,
      [props.defaultName]: true,
    };
  });

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    clearFilter() {
      setSearchInput("");
      setSearchFilterChecked({
        ...defaultFilterState,
        [props.defaultName]: true,
      });
    },
  }));

  const onSearchFilterSelect = (event) => {
    const filterState = defaultFilterState;
    const keys = Object.keys(filterState);
    const obj = {};
    keys.map((key) => {
      obj[key] = false;
      return obj;
    });
    setSearchFilterChecked({
      ...obj,
      [event.target.name]: event.target.checked,
    });
  };

  const onSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const searchItems = () => {
    setSearchMenuVisible(false);
    const { defaultName } = props;
    const selectedFilterName = Object.keys(searchFilterChecked).find(
      (filterName) => searchFilterChecked[filterName]
    );
    props.onSearch({
      name: selectedFilterName || defaultName,
      value: searchInput,
    });
  };

  const onSearchInputSubmit = (event) => {
    if (event.key === "Enter") {
      searchItems();
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setSearchMenuVisible(false)}>
      <div className={classes.container}>
        <TextField
          data-testid="search-textbox"
          InputLabelProps={{ shrink: false }}
          label={searchInput === "" ? "Search" : " "}
          value={searchInput}
          onChange={onSearchInputChange}
          onKeyDown={onSearchInputSubmit}
          onClick={() => {
            setSearchMenuVisible(!searchMenuVisible);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  aria-label="search"
                  className={classes.searchBtn}
                  onClick={searchItems}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            inputProps: {
              "data-testid": "search-filter",
            },
          }}
        />
        {searchMenuVisible && (
          <Paper role="menu" elevation={3} className={classes.searchFilterMenu}>
            <Typography className="title">{t("common.filter_by")}</Typography>
            {searchFilters.map((filter, i) => (
              <FormControlLabel
                key={i.toString()}
                control={
                  <Checkbox
                    className="search-filter-checkbox"
                    checked={searchFilterChecked[filter.name]}
                    onChange={onSearchFilterSelect}
                    name={filter.name}
                    color="primary"
                  />
                }
                label={filter.label}
              />
            ))}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
});

SearchFilter.defaultProps = {
  searchFilters: [],
  defaultName: "",
  defaultFilterState: {},
};

SearchFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }).isRequired
  ),
  defaultFilterState: PropTypes.shape(),
  defaultName: PropTypes.string,
};

export default SearchFilter;
