import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& svg": {
      width: "20px",
      height: "20px",
    },
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      zIndex: "1",
      pointerEvents: "none",
      top: "-10px",
      left: "10px",
    },
    "& .MuiInputBase-adornedEnd": {
      backgroundColor: theme.colorTheme.backgroundColor,
      borderRadius: "6px",
      padding: "4px",
      paddingLeft: "10px",
      "&:before": {
        content: "unset",
      },
      marginTop: "0px",
    },
  },
  searchFilterMenu: {
    position: "absolute",
    top: "50px",
    left: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
    width: "calc(100% - 38px)",
    padding: "18px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "roboto",
    "& .title": {
      marginBottom: "10px",
    },
  },
  searchBtn: {
    padding: "6px",
  },
}));

export default useStyles;
