import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    statusContainer: {
      display: "flex",
      alignItems: "center",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
    badge: {
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    statusWrapper: {
      display: "flex",
      padding: "4px 8px",
      gap: "4px",
      alignItems: "center",
      borderRadius: "20px",
      "& svg": {
        marginLeft: "unset !important",
      },
      "& .icon": {
        position: "relative",
        left: "4px",
        top: "4px",
      },
    },
    statusText: {
      whiteSpace: "nowrap",
    },
    editIcon: {
      cursor: "pointer",
    },
    success: {
      backgroundColor: theme.palette.green.main,
    },
    approved: {
      backgroundColor: theme.palette.green.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.light,
    },
    pending: {
      backgroundColor: theme.palette.warning.light,
    },
    rejected: {
      backgroundColor: theme.palette.error.main,
    },
    failed: {
      backgroundColor: theme.palette.error.main,
    },
    running: {
      backgroundColor: theme.colorTheme.blue,
    },
    "success-partial": {
      backgroundColor: theme.palette.green.light1,
    },
    returned: {
      backgroundColor: theme.palette.primary.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  };
});

export default useStyles;
