const createBackendPayload = (
  marketingProgram,
  dataSources,
  segmentSource,
  mapToSegmentSource
) => {
  const payload = {
    schemaVersion: "v2",
    marketingProgram: {
      marketingProgramNumber: marketingProgram.marketingProgramNumber,
      description: marketingProgram.description,
    },
    dataSources: dataSources.map(({ new: newDataSource, ...rest }) =>
      newDataSource
        ? { ...rest, dataType: rest.dataType.join(",") }
        : {
            sourceId: rest.sourceId,
            sourceName: rest.sourceName,
          }
    ),
  };

  payload.mapToSegmentSource = mapToSegmentSource === "true";

  let source = {};

  if (segmentSource.isNewSource) {
    source = {
      ecosystemSourceName: segmentSource.ecosystemSourceName,
      ecosystemSourceType: segmentSource.sourceIntegrationType,
      ecoSystemSourceKey: segmentSource.ecoSystemSourceKey,
      ecoSystemSourceSlug: segmentSource.ecosyStemSourceSlug,
    };
  } else {
    source = {
      ecosystemSourceId: segmentSource.ecosystemSourceId,
      ecosystemSourceName: segmentSource.ecosystemSourceName,
      ecoSystemSourceKey: segmentSource.ecoSystemSourceKey,
      ecoSystemSourceSlug: segmentSource.ecosyStemSourceSlug,
    };
  }

  return {
    ...payload,
    ...(payload.mapToSegmentSource ? { segmentSource: source } : {}),
  };
};

export default createBackendPayload;
