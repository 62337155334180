import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    graphqlContainer: {
      marginTop: "80px",
      margin: "100px auto 0",
      padding: "0 30px 0 103px",
    },
    apiSelect: {
      width: "300px",
    },
  };
});

export default useStyles;
