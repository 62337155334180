const truncateString = (str, opts) => {
  if (!str || typeof str !== "string") return str;
  let options = opts;
  if (!options) {
    options = {
      maxLength: 80,
      showEllipsis: true,
    };
  }

  let truncated = str;

  if (str.length > options.maxLength) {
    truncated = str.slice(0, options.maxLength).trim();
    if (options.showEllipsis) {
      truncated += "...";
    }
  }

  return truncated;
};

export default truncateString;
