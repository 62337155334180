import applicationConfig from "../config/applicationConfig";

import api from "../utilities/api";
import { setValue } from "../utilities/cache";

const { requestIdPrefix } = applicationConfig;

const updateRequestStatus = async (requestId, status, comments, module) => {
  const updatedRequestId = requestId.replace(requestIdPrefix, "");
  const url = `/${module}/requests/${updatedRequestId}`;
  const {
    data: { data },
  } = await api.patch(url, {
    status,
    comments,
  });
  data.requestId = `${requestIdPrefix}${data.requestId}`;
  if (data.status === "approved") {
    setValue(module);
  }
  return data;
};

export default updateRequestStatus;
