import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    tableFooterContainer: {
      minHeight: "55px",
      backgroundColor: theme.palette.blue.darker,
      zIndex: "100",
      padding: "25px 30px",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableFooterLeft: {
      flex: "0 0 50%",
      maxWidth: "70%",
      wordWrap: "break-word",
      color: "white",
      "& > div:first-child": {
        justifyContent: "flex-start",
        "& p": {
          fontWeight: "bold",
        },
      },
    },
    tableFooterRight: {
      "& p": {
        color: theme.palette.static.white,
        cursor: "pointer",
        textDecoration: "underline",
        marginRight: "1.5rem",
      },
      "& .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary":
        {
          color: theme.palette.static.white,
          border: "1px solid #fff",
        },
      "& a": {
        backgroundColor: `${theme.colorTheme.white} !important`,
        color: `${theme.palette.primary.main} !important`,
      },
    },
  };
});

export default useStyles;
