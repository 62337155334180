import { Typography, Dialog, DialogContent } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "../../../../components/Form";

import i18n from "../../../../i18n/init";

import getFields from "../../NewMarketingProgramsContainer/getFields";

import convertBackendPayload from "../../NewMarketingProgramsContainer/helpers/convertBackendPayload";

import useStyles from "./styles";

const NewMarketingProgram = ({
  isOpen,
  info,
  setNewMarketingProgramModal,
  title,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const payload = convertBackendPayload(info);

  let mappingInfoFields = getFields(payload);

  mappingInfoFields = mappingInfoFields.map((field) => {
    if (
      field.label === t("common.labels.starting_ecosystems") ||
      field.label === t("common.labels.starting_channels")
    ) {
      return {
        ...field,
        type: "text-input",
        props: {
          ...field.props,
          value: field.props.value?.join(","),
          disabled: true,
          variant: "outlined",
        },
      };
    }
    return {
      ...field,
      props: {
        ...field.props,
        disabled: true,
        variant: "outlined",
      },
    };
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setNewMarketingProgramModal(false);
      }}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <div className={clsx(classes.flexContainer, classes.alignStart)}>
        <Typography variant="h5">{title}</Typography>
        <div
          className={clsx(classes.marginLeftAuto, classes.closeBtn)}
          onClick={() => {
            setNewMarketingProgramModal(false);
          }}
          onKeyDown={() => null}
          role="button"
          tabIndex={0}
          data-testid="close-btn"
        >
          <Close />
        </div>
      </div>
      <div className={classes.horizontalBar} />
      <DialogContent>
        <div>
          <Form
            fields={mappingInfoFields}
            fieldClassName={classes.inputContainer}
            containerClassName={classes.columnGrid}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

NewMarketingProgram.defaultProps = {
  isOpen: false,
  setNewMarketingProgramModal: () => {},
  title: i18n.t("opts_mapping.headings.step_1"),
  info: {},
};

NewMarketingProgram.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  info: PropTypes.shape({}),
  setNewMarketingProgramModal: PropTypes.func,
};

export default NewMarketingProgram;
