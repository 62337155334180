const createBackendPayload = (
  useCaseInfo,
  scope,
  items,
  isTraitsFlow,
  eventName
) => {
  let payload = {};
  if (useCaseInfo.useCaseId) {
    payload = {
      dataUseCase: {
        useCaseId: useCaseInfo.useCaseId,
      },
    };
  }
  payload.dataUseCase = {
    ...payload.dataUseCase,
    useCaseName: "",
    useCaseDescription: "",
  };
  payload.dataUseCase.useCaseName = useCaseInfo.useCaseName;
  payload.dataUseCase.useCaseDescription = useCaseInfo.useCaseDescription;
  payload = {
    ...payload,
    scope: {
      type: scope,
    },
  };

  if (isTraitsFlow) {
    payload = {
      ...payload,
      traits: items
        .filter((x) => !(x.isUpdated && x.deleted))
        .map((item) => {
          if (item.isUpdated && !item.deleted) {
            const existingLabels = item.labels || [];
            const updatedLabels = item.selectedLabels || [];
            const modifiedLabels = [];

            existingLabels?.forEach((el) => {
              const labelIndex = updatedLabels.findIndex(
                (x) => x.labelName === el.labelName
              );
              if (labelIndex === -1) {
                modifiedLabels.push({ ...el, deleted: true });
              }
            });

            updatedLabels?.forEach((ul) => {
              const labelIndex = existingLabels.findIndex(
                (x) => x.labelName === ul.labelName
              );
              if (labelIndex === -1) {
                modifiedLabels.push({
                  ...ul,
                  ...(!ul.labelId ? { new: true } : {}),
                });
              }
            });

            return {
              ...item,
              useCaseTraitMapId: item.traitMapId,
              labels:
                modifiedLabels.length === 0 ? existingLabels : modifiedLabels,
            };
          }

          if (item.deleted && !item.isUpdated) {
            return {
              ...item,
              useCaseTraitMapId: item.traitMapId,
              traitId: item.traitId,
              traitName: item.traitName,
              deleted: true,
            };
          }
          return {
            ...item,
            useCaseTraitMapId: item.traitMapId,
            traitId: item.traitId,
            traitName: item.traitName,
          };
        }),
    };
  } else {
    payload = {
      ...payload,
      eventProperties: {
        eventName,
        properties: items
          .filter((x) => !(x.isUpdated && x.deleted))
          .map((item) => {
            if (item.isUpdated && !item.deleted) {
              const existingLabels = item.labels || [];
              const updatedLabels = item.selectedLabels || [];
              const modifiedLabels = [];

              existingLabels?.forEach((el) => {
                const labelIndex = updatedLabels.findIndex(
                  (x) => x.labelName === el.labelName
                );
                if (labelIndex === -1) {
                  modifiedLabels.push({ ...el, deleted: true });
                }
              });

              updatedLabels?.forEach((ul) => {
                const labelIndex = existingLabels.findIndex(
                  (x) => x.labelName === ul.labelName
                );
                if (labelIndex === -1) {
                  modifiedLabels.push({
                    ...ul,
                    ...(!ul.labelId ? { new: true } : {}),
                  });
                }
              });

              return {
                ...item,
                eventProperty: item.propertyName,
                eventPropertyDescription: item.description,
                labels:
                  modifiedLabels.length === 0 ? existingLabels : modifiedLabels,
              };
            }

            if (item.deleted && !item.isUpdated) {
              return {
                ...item,
                eventProperty: item.propertyName,
                eventPropertyDescription: item.description,
                deleted: true,
              };
            }
            return {
              ...item,
              eventProperty: item.propertyName,
              eventPropertyDescription: item.description,
              new: true,
            };
          }),
      },
    };
  }

  if (scope === "regional") {
    payload.scope.regionCode =
      useCaseInfo.selectedSubMenuItem.geoRegionCode ||
      useCaseInfo.selectedSubMenuItem.name ||
      useCaseInfo.selectedSubMenuItem.regionCode;
    payload.scope.regionName =
      useCaseInfo.selectedSubMenuItem.geoRegionName ||
      useCaseInfo.selectedSubMenuItem.label ||
      useCaseInfo.selectedSubMenuItem.regionName;
  }

  if (scope === "legalEntity") {
    payload.scope.legalEntityId =
      useCaseInfo.selectedSubMenuItem.legalEntityId ||
      useCaseInfo.selectedSubMenuItem.legalEntityNumber;
    payload.scope.legalEntityName =
      useCaseInfo.selectedSubMenuItem.legalEntityName;
  }

  if (scope === "marketingProgram") {
    payload.scope.marketingProgramNumber =
      useCaseInfo.selectedSubMenuItem.marketingProgramNumber;
    payload.scope.description =
      useCaseInfo.selectedSubMenuItem.marketingProgramDescription ||
      useCaseInfo.selectedSubMenuItem.description;
  }

  return payload;
};

export default createBackendPayload;
