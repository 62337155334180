import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    MuiPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minWidth: "807px !important",
      maxWidth: "807px !important",
      padding: "20px 25px",
      overflowY: "unset !important",
      "& .MuiTypography-h5": {
        marginBottom: "15px",
        fontSize: "20px",
        color: theme.colorTheme.grey,
        fontWeight: "500",
      },
      "& .MuiDialogContent-root": {
        maxHeight: "810px",
        padding: "0px !important",
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDialogActions-root": {
        padding: "0px !important",
      },
      "& .MuiFormHelperText-contained": {
        margin: "0px !important",
        marginTop: "5px !important",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100%",
    },
    marginTop20: {
      marginTop: "10px",
    },
    horizontalBar: {
      height: "1px",
      backgroundColor: theme.colorTheme.greyishWhite,
      marginLeft: "-25px",
      width: "calc(100% + 50px)",
      marginBottom: "14px",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
        marginBottom: "24px",
      },
    },
    newBtnContainer: {
      display: "inline-block",
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.primary.light2,
        color: theme.colorTheme.grey,
        margin: "0px 10px",
      },
    },
    fieldContainer: {
      justifyContent: "space-between",
      marginBottom: "16px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "& input,& textarea, & .MuiInputBase-root": {
        minWidth: "558px",
        maxWidth: "558px",
      },
      "& .MuiSelect-root": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& .MuiFormControl-root": {
        marginRight: "10px",
      },
    },
    grid: {
      display: "grid",
      gridGap: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
      "& .MuiAutocomplete-root": {
        width: "auto !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    columnGrid: {
      gridTemplateColumns: "repeat(auto-fill, minmax(400px, 2fr)) !important",
    },
    labelContainer: {
      display: "flex",
    },
    inputContainer: {
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: `${theme.colorTheme.modalTitle} !important`,
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    sourceNameContainer: {
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
      },
    },
    popper: {
      "& .MuiCheckbox-root": {
        color: theme.palette.primary.light,
      },
    },
    badge: {
      backgroundColor: theme.palette.blue.darker,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "20px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.static.white,
    },
    container: {
      "& > div": {
        marginBottom: "20px",
        "& div:nth-child(4)": {
          "& textarea": {
            cursor: "not-allowed",
          },
        },
      },
      "& div:nth-child(2)": {
        "& p:last-child": {
          marginLeft: "20px",
        },
        "& .MuiFormControl-root": {
          width: "100%",
        },
      },
    },
    sourceContainer: {
      padding: "40px",
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    paddingZero: {
      padding: "0px",
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    noOptionsContainer: {
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
      },
    },
    backBtn: {
      "& a": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
      "& button": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.colorTheme.white} !important`,
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
    },
    btnContainer: {
      "& button": {
        minWidth: "200px !important",
      },
      "& span > span": {
        marginLeft: "auto",
      },
      "& svg": {
        marginLeft: "auto",
      },
    },
    footerRightContainer: {
      flex: "0 0 50%",
      display: "flex",
      alignItems: "start",
      "& a": {
        textAlign: "center",
        marginRight: "20px !important",
      },
    },
    step2Footer: {
      "& button, & a": {
        marginRight: "10px",
        whiteSpace: "no-wrap",
      },
      "& .MuiButton-label": {
        whiteSpace: "no-wrap",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.greyishBlack,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        fontWeight: "bold",
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& a,& a:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        border: "none",
        marginLeft: "auto",
      },
    },
    addServiceNameBtn: {
      "& .MuiButton-label": {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
      paddingLeft: "16px",
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
    },
    infoIcon: {
      width: 18,
      height: 18,
      marginLeft: "6px",
      position: "relative",
      top: "2px",
      cursor: "pointer",
    },
    outlinedIcon: {
      position: "relative",
      top: "-2px",
      cursor: "pointer",
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    statusPending: {
      backgroundColor: theme.palette.warning.light,
    },
    statusApproved: {
      backgroundColor: theme.palette.green.main,
    },
    statusRejected: {
      backgroundColor: theme.palette.error.main,
    },
    fileIconWrapper: {
      marginLeft: "10px",
    },
    traitName: {
      cursor: "pointer",
    },
    traitId: {
      cursor: "pointer",
    },
    loadingSkeleton: {
      width: "807px !important",
      padding: "30px",
    },
  };
});

export default useStyles;
