import api from "../utilities/api";
import { getValue } from "../utilities/cache";
import isEmpty from "../utilities/isEmpty";

const getEventsResponses = async (queryParams) => {
  let url = "/events/responses";
  const refreshCache = getValue("events");
  if (!isEmpty(queryParams)) {
    url += `${queryParams}&refreshCache=${refreshCache}`;
  } else {
    url += `?refreshCache=${refreshCache}`;
  }
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getEventsResponses;
