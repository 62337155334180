import { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";

import {
  Button,
  Typography,
  Accordion,
  TextField,
  InputLabel,
  CircularProgress,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import AutoComplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory } from "react-router-dom";

import createResponsesRequest from "../../../api/create-responses-request";

import getEventProperties from "../../../api/get-event-properties";
import getEventsResponse from "../../../api/get-events-response";
import getTraitsResponse from "../../../api/get-traits-response";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import InlineMessage from "../../../components/InlineMessage";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";
import traitsResponsesModuleConfig from "../../../config/traitsResponsesModuleConfig";

import useEvents from "../../../hooks/useEvents";
import useLegalEntities from "../../../hooks/useLegalEntities";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useMarketingPrograms from "../../../hooks/useMarketingPrograms";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useTraits from "../../../hooks/useTraits";
import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import { isEmptyString } from "../../../utilities/formValidation";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import mergeArrays from "../../../utilities/mergeArrays";
import truncateString from "../../../utilities/truncateString";

import useImmer from "../../../utilities/useImmer";

import AliasesModal from "./components/AliasesModal";
import TraitsResponseModal from "./components/TraitsResponseModal";
import TraitsResponseRequestOutputModal from "./components/TraitsResponseRequestOutputModal";
import createBackendPayload from "./helpers/createBackendPayload";
import useStyles from "./styles";

const NewTraitsResponseContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const { createTraitsResponsesConstants, requestStatus } =
    traitsResponsesModuleConfig;

  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const count = useRef(0);
  count.current += 1;
  const isTraitsFlow = useMemo(
    () =>
      state?.from === applicationConfig.modules.trait_response ||
      request.module === applicationConfig.modules.traits_responses,
    [request.module, state?.from]
  );
  const isEventsFlow = useMemo(
    () =>
      state?.from === applicationConfig.modules.event_response ||
      request.module === applicationConfig.modules.events_responses,
    [request.module, state?.from]
  );

  const eventNameExists = useMemo(
    () =>
      !isEmpty(request?.requestDetails?.eventName) ||
      !isEmpty(state?.event?.eventName),
    [request?.requestDetails?.eventName, state?.event?.eventName]
  );

  const isRequestRevisionFlow = useMemo(
    () => Object.keys(request ?? {}).length !== 0 && request.requestId,
    [request]
  );

  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [traitsResponsesModal, setTraitsResponsesModal] = useState(false);
  const isUserAuthorized = useMemo(
    () => checkUserAuthorization(user.access, pageAccessConfig.createTraits),
    [user.access]
  );

  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(
    (state?.event?.eventName && state?.event?.propertyName) ||
      state?.trait?.traitName
      ? 2
      : 1
  );
  const [initialStep, setInitialStep] = useState(1);

  const [loadTraitResponse, setLoadTraitResponse] = useState(null);
  const [loadEventResponse, setLoadEventResponse] = useState(null);
  const [traitsResponseOutput, setTraitsResponseOutput] = useState({});
  const [eventProperties, setEventProperties] = useState([]);
  const { t } = useTranslation();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const [traitValue, setTraitValue] = useState("");
  const [selectedEventProperty, setSelectedEventProperty] = useState({});
  const [selectedEventName, setSelectedEventName] = useState({});
  const [eventValue, setEventValue] = useState("");

  const {
    getTraitsFromApi,
    traitsLoading,
    setTraitsLoading,
    traitOptions,
    setTraitOptions,
    initialTraitOptions,
  } = useTraits();

  const {
    marketingProgramOptions,
    getMarketingProgramsFromApi,
    marketingProgramsLoading,
    initialMarketingProgramOptions,
    setMarketingProgramsLoading,
    setMarketingProgramOptions,
  } = useMarketingPrograms();

  const initialEventNameOptionsFilter = useCallback(
    () => !isTraitsFlow,
    [isTraitsFlow]
  );

  const {
    getEventsFromApi,
    memoizedEventsFromApi,
    eventNameOptions,
    eventNamesLoading,
    setEventNamesLoading,
    initialEventNameOptions,
  } = useEvents({
    initialEventNameOptionsFilter,
  });

  const {
    legalEntitiesLoading,
    memoizedLegalEntitiesFromApi,
    initialLegalEntitiesOptions,
    legalEntities,
    setLegalEntities,
  } = useLegalEntities();

  const { regionFilters } = applicationConfig;

  const [traitsResponsesResponse, setTraitsResponsesResponse] = useState([]);
  const [editableTrait, setEditableTrait] = useState({});

  const [traitSelected, setTraitSelected] = useState({});
  const initialList = useRef([]);
  const globalTraitResponses = useRef([]);
  const [globalTraitResponsesLoaded, setGlobalTraitResponsesLoaded] =
    useState(false);
  const [renderList, setRenderList] = useState([]);
  const [diffList, setDiffList] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const [initialRegionalOptions] = useState([]);
  const [subMenuValue, setSubMenuValue] = useState("");
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(
    state?.selectedSubMenuItem
      ? {
          ...state.selectedSubMenuItem,
          title: state.selectedSubMenuItem.scopeIdentifier,
        }
      : null
  );
  const [scope, setScope] = useState(
    request?.requestDetails?.scope?.type || state?.scope || "global"
  );

  const [defaultLanguage, setDefaultLanguage] = useImmer({});
  const [updateDefaultLang, setUpdateDefaultLang] = useState(false);
  const updateDefaultLanguage = useCallback(() => {
    if (renderList.length > 0) {
      if (scope === "global") {
        setDefaultLanguage((draft) => {
          draft.languageCode = "en";
          draft.languageName = "English";
          draft.title = "English";
        });
        return;
      }
      const recentEntry = renderList[renderList.length - 1];
      setDefaultLanguage((draft) => {
        draft.languageCode = recentEntry.languageCode;
        draft.languageName = recentEntry.languageName;
        draft.title = recentEntry.languageName;
      });
    } else {
      setDefaultLanguage((draft) => {
        delete draft.languageCode;
        delete draft.languageName;
        delete draft.title;
      });
    }
  }, [renderList, scope]);
  useEffect(() => {
    if (updateDefaultLang) {
      setUpdateDefaultLang(false);
      updateDefaultLanguage();
    }
  }, [renderList]);

  const onCloseDeleteModal = () => {
    setEditableTrait({});
    setOpenDeleteModal(false);
  };

  const trackChanges = (existing, changes) => {
    const diff = [];

    existing.forEach((etr) => {
      const index = changes.findIndex((ntr) => {
        return (
          (ntr.id === etr.id && !("oldValue" in ntr)) ||
          ("oldValue" in ntr && ntr.oldValue.id === etr.id)
        );
      });

      if (index === -1) {
        diff.push({
          ...etr,
          deleted: true,
        });
      }
    });

    changes
      .filter((x) => !("oldValue" in x))
      .forEach((ntr) => {
        const index = existing.findIndex((etr) => etr.id === ntr.id);
        if (index === -1) {
          diff.push({
            ...ntr,
            new: true,
          });
        }
      });

    const updatedTraits = changes.filter((x) => x.oldValue);

    updatedTraits.forEach((ut) => {
      const updatedTrait = { ...ut };

      const oldTrait = {
        ...updatedTrait.oldValue,
        deleted: true,
        isUpdated: true,
        reference: updatedTrait.id,
      };

      delete updatedTrait.oldValue;

      diff.push(
        {
          ...oldTrait,
        },
        { ...updatedTrait, isUpdated: true, reference: oldTrait.id }
      );
    });

    return [...diff];
  };

  const isInfoStep = currentStep > totalSteps;

  const isSelected = useMemo(() => {
    if (scope === "global") {
      return true;
    }
    if (scope && selectedSubMenuItem?.title) {
      return true;
    }
    return false;
  }, [scope, selectedSubMenuItem?.title]);

  useEffect(() => {
    if (!loading) {
      setDiffList(trackChanges(initialList.current, renderList));
    }
  }, [loading, renderList, scope]);

  useEffect(() => {
    if (traitValue.length > 0 && isTraitsFlow) {
      getTraitsFromApi?.(traitValue);
    }
  }, [traitValue, isTraitsFlow, getTraitsFromApi]);

  useEffect(() => {
    (async () => {
      if (isEventsFlow && currentStep === 1) {
        if (isEmpty(selectedEventName) || !selectedEventName.eventName) {
          setEventProperties([]);
          setSelectedEventProperty({});
          return;
        }
        const response = await getEventProperties(selectedEventName.eventName, {
          denormalize: false,
        });
        if (response && response.properties) {
          setEventProperties(response.properties);
        }
      }
    })();
  }, [isEventsFlow, selectedEventName, currentStep]);

  useEffect(() => {
    (async () => {
      if (user.userId && isUserAuthorized) {
        increaseRequestsCount(3);
        try {
          if (isTraitsFlow && !traitsLoading && traitOptions.length === 0) {
            await getTraitsFromApi?.("");
          }
          if (
            isEventsFlow &&
            !eventNamesLoading &&
            eventNameOptions.length === 0
          ) {
            await getEventsFromApi("");
          }
          if (
            !marketingProgramsLoading &&
            marketingProgramOptions.length === 0
          ) {
            await getMarketingProgramsFromApi("");
          }
          await memoizedLegalEntitiesFromApi("");
        } catch (err) {
          handleError({ error: err, handle404: true, addNotification });
        } finally {
          decreaseRequestsCount(3);
        }
        if (isEventsFlow) {
          let eventName = {};
          if (isRequestRevisionFlow) {
            eventName = {
              eventName: request?.requestDetails?.eventName,
              title: request?.requestDetails?.eventName,
            };
          } else if (state?.event) {
            eventName = {
              eventName: state?.event?.eventName,
              title: state?.event?.eventName,
            };
          }

          let eventProperty = {};
          if (state?.event?.propertyName) {
            eventProperty = {
              propertyName: state.event.propertyName,
              title: state.event.propertyName,
            };
          } else if (isRequestRevisionFlow) {
            eventProperty = {
              propertyName: request?.requestDetails?.propertyName,
              title: request?.requestDetails?.propertyName,
            };
          }
          setSelectedEventName(eventName);
          setSelectedEventProperty(eventProperty);
        }
      }
    })();
  }, [
    isEventsFlow,
    isRequestRevisionFlow,
    isTraitsFlow,
    isUserAuthorized,
    memoizedLegalEntitiesFromApi,
    request?.requestDetails?.eventName,
    request?.requestDetails?.propertyName,
    state?.event,
    user.userId,
  ]);

  useEffect(() => {
    if (isRequestRevisionFlow) {
      setCurrentStep(2);
      setScope(request?.requestDetails?.scope?.type);
      const {
        regionCode,
        regionName,
        legalEntityId,
        legalEntityName,
        type,
        marketingProgramNumber,
        description,
      } = request?.requestDetails?.scope || {};
      const id = regionCode || legalEntityId || marketingProgramNumber;
      const title = regionName || legalEntityName || description;
      setSelectedSubMenuItem({
        title: !id ? "Global" : `${id} - ${title}`,
        regionCode,
        scope: type,
        regionName,
        legalEntityId,
        legalEntityName,
        marketingProgramNumber,
        description,
      });
    }
  }, [isRequestRevisionFlow, request?.requestDetails?.scope]);

  const idMapping = useMemo(
    () => ({
      regional: "geoRegionCode",
      legalEntity: "legalEntityId",
      marketingProgram: "marketingProgramNumber",
      global: "global",
    }),
    []
  );

  useEffect(() => {
    if (state?.selectedSubMenuItem?.scopeIdentifier) {
      setSelectedSubMenuItem({
        title: state.selectedSubMenuItem.scopeIdentifier,
        ...state?.selectedSubMenuItem,
      });
    }
    if (
      (!isEmpty(state?.event) || !isEmpty(state?.trait)) &&
      state?.selectedSubMenuItem?.scopeIdentifier
    ) {
      setInitialStep(2);
    }
  }, [state]);

  const scopeMapping = useMemo(
    () => ({
      global: "Global",
      regional: "Region",
      legalEntity: "Legal Entity",
      marketingProgram: "Marketing Program",
    }),
    []
  );

  const details = useMemo(
    () => [
      ...(isTraitsFlow
        ? [
            {
              label: t("common.labels.trait"),
              value: traitSelected?.title,
            },
          ]
        : [
            {
              label: t("common.labels.event_name"),
              value: selectedEventName?.title,
            },
            {
              label: t("common.labels.event_property_name"),
              value: selectedEventProperty?.title,
            },
          ]),
      {
        label: t("common.scope"),
        value: scopeMapping[scope],
      },
      {
        label: scope !== "global" && scopeMapping[scope],
        value: selectedSubMenuItem?.title,
      },
    ],
    [
      isTraitsFlow,
      scope,
      scopeMapping,
      selectedEventName?.title,
      selectedEventProperty?.title,
      selectedSubMenuItem?.title,
      t,
      traitSelected?.title,
    ]
  );

  const handleRequestDiff = useCallback(
    (current) => {
      let existingModifications =
        request?.requestDetails?.traitResponses ||
        request?.requestDetails?.eventPropertyResponses;
      let modifiedItems = [...current];

      if (request?.requestDetails?.traitResponses) {
        if (scope === "global") {
          existingModifications = existingModifications.map((trp) => ({
            ...trp,
            predefinedTraitResponse: trp.globalStandardResponse,
          }));
        }
      }
      existingModifications?.forEach((mod, index) => {
        const modifiedTraitResponse = {
          ...mod,
          id:
            scope === "global"
              ? mod.globalStandardResponse
              : `${mod.languageName}:${
                  mod.predefinedTraitResponseLocalLanguage ||
                  mod.predefinedEventPropertyResponseLocalLanguage
                }`,
          sId:
            scope === "global"
              ? mod.globalStandardResponse
              : `${
                  mod.predefinedTraitResponse ||
                  mod.predefinedEventPropertyResponse ||
                  "*"
                }:${mod.globalStandardResponse}`,
        };
        if (
          modifiedTraitResponse.new &&
          existingModifications[index - 1]?.isUpdated
        ) {
          return;
        }

        if (modifiedTraitResponse.new) {
          modifiedItems.push(modifiedTraitResponse);
          return;
        }

        if (
          modifiedTraitResponse.deleted &&
          !("isUpdated" in modifiedTraitResponse)
        ) {
          modifiedItems = modifiedItems.filter(
            (x) => x.id !== modifiedTraitResponse.id
          );
          return;
        }

        if (modifiedTraitResponse.deleted && modifiedTraitResponse.isUpdated) {
          delete modifiedTraitResponse.deleted;
          delete modifiedTraitResponse.isUpdated;
          delete modifiedTraitResponse.reference;

          const replacingTraitResponse = {
            ...existingModifications[index + 1],
          };
          delete replacingTraitResponse.reference;

          modifiedItems = modifiedItems.filter(
            (x) => x.id !== modifiedTraitResponse.id
          );

          modifiedItems.push({
            ...replacingTraitResponse,
            oldValue: {
              ...modifiedTraitResponse,
            },
          });
        }
      });

      return modifiedItems;
    },
    [
      request?.requestDetails?.eventPropertyResponses,
      request?.requestDetails?.traitResponses,
    ]
  );

  const loadTraitResponses = useCallback(
    async (data) => {
      increaseRequestsCount();
      try {
        let response = {};
        if (isTraitsFlow) {
          try {
            if (!loadTraitResponse) {
              response = await getTraitsResponse(data.traitId, {
                schemaVersion: "v2",
                scope,
              });
              setLoadTraitResponse(response);
              if (scope === "global" && !globalTraitResponsesLoaded) {
                globalTraitResponses.current =
                  response.mappedTraitResponses?.[0]?.traitResponses?.map(
                    (x) => ({
                      ...x,
                      id: x.predefinedTraitResponse,
                      languageName: "English",
                      languageCode: "en",
                    })
                  );
                setGlobalTraitResponsesLoaded(true);
              }
            }
          } catch (error) {
            setLoadTraitResponse([]);
            if (scope === "global" && !globalTraitResponsesLoaded) {
              globalTraitResponses.current = [];
              setGlobalTraitResponsesLoaded(true);
            }
            handleError({
              error,
              handle404: false,
              addNotification: () => {},
            });
          }
          if (scope !== "global" && !globalTraitResponsesLoaded) {
            const globalTraitsResponse = await getTraitsResponse(data.traitId, {
              schemaVersion: "v2",
              scope: "global",
            });
            globalTraitResponses.current =
              globalTraitsResponse.mappedTraitResponses?.[0]?.traitResponses?.map(
                (x) => ({
                  ...x,
                  id: x.predefinedTraitResponse,
                  languageName: "English",
                  languageCode: "en",
                })
              );
            setGlobalTraitResponsesLoaded(true);
          }
        }

        if (isEventsFlow) {
          try {
            if (!loadEventResponse) {
              response = await getEventsResponse(
                data.propertyName,
                selectedEventName?.eventName,
                {
                  schemaVersion: "v2",
                  scope,
                }
              );
              setLoadEventResponse(response);
            }
          } catch (error) {
            handleError({
              error,
              handle404: false,
              addNotification: () => {},
            });
          }
          if (!globalTraitResponsesLoaded) {
            const globalEventsResponse = await getEventsResponse(
              data.propertyName,
              selectedEventName?.eventName,
              {
                schemaVersion: "v2",
                scope: "global",
              }
            );
            globalTraitResponses.current =
              globalEventsResponse.mappedEventPropertyResponses?.[0]?.eventPropertyResponses?.map(
                (x) => ({
                  ...x,
                  id: x.predefinedEventPropertyResponse,
                  languageName: "English",
                  languageCode: "en",
                })
              );
            setGlobalTraitResponsesLoaded(true);
          }
        }
      } catch (error) {
        handleError({
          error,
          handle404: false,
          addNotification: () => {},
        });
      } finally {
        decreaseRequestsCount();
      }
    },
    [
      decreaseRequestsCount,
      globalTraitResponsesLoaded,
      increaseRequestsCount,
      isEventsFlow,
      isTraitsFlow,
      scope,
      selectedEventName?.eventName,
    ]
  );

  useEffect(() => {
    if (isTraitsFlow) {
      let { clonedTraitResponses = [] } = state;
      if (selectedSubMenuItem?.title) {
        const idKey = idMapping?.[scope];
        const filteredItem =
          idKey === "global"
            ? loadTraitResponse?.mappedTraitResponses[0]
            : loadTraitResponse?.mappedTraitResponses?.find(
                (traitResp) => traitResp?.[idKey] === selectedSubMenuItem[idKey]
              );
        const transformTraitResponse = (tr) => ({
          ...tr,
          id:
            selectedSubMenuItem.title === "Global"
              ? tr.predefinedTraitResponse
              : `${tr.languageName}:${tr.predefinedTraitResponseLocalLanguage}`,
          sId: `${tr.predefinedTraitResponse || "*"}:${
            tr.globalStandardResponse
          }`,
        });
        const existingTraitResponses =
          filteredItem?.traitResponses?.map(transformTraitResponse) || [];
        clonedTraitResponses = clonedTraitResponses.map(transformTraitResponse);
        const traitResponses = mergeArrays(
          existingTraitResponses,
          clonedTraitResponses,
          "id"
        );
        if (isRequestRevisionFlow) {
          setRenderList(handleRequestDiff(traitResponses));
        } else {
          setRenderList(traitResponses || []);
        }
        if (traitResponses) {
          initialList.current = [...existingTraitResponses];
        }
      } else {
        const existingTraitResponses =
          loadTraitResponse?.mappedTraitResponses?.[0]?.traitResponses || [];
        let traitResponses = existingTraitResponses;
        if (clonedTraitResponses?.length > 0) {
          traitResponses = mergeArrays(
            traitResponses,
            clonedTraitResponses,
            "predefinedTraitResponse"
          );
        }
        traitResponses = traitResponses.map((tr) => ({
          ...tr,
          id: tr.predefinedTraitResponse,
        }));
        if (isRequestRevisionFlow) {
          setRenderList(handleRequestDiff(traitResponses));
        } else {
          setRenderList(traitResponses);
        }
        initialList.current = traitResponses.filter(
          (tr) =>
            existingTraitResponses.filter(
              (etr) =>
                etr.predefinedTraitResponse === tr.predefinedTraitResponse
            ).length !== 0
        );
      }
      setUpdateDefaultLang(true);
    }
  }, [
    handleRequestDiff,
    idMapping,
    isRequestRevisionFlow,
    isTraitsFlow,
    loadTraitResponse?.mappedTraitResponses,
    scope,
    selectedSubMenuItem,
  ]);

  useEffect(() => {
    if (isEventsFlow) {
      let { clonedEventPropertyResponses: newEventPropertyResponses = [] } =
        state;
      if (
        selectedSubMenuItem?.title &&
        selectedSubMenuItem.title !== "Global"
      ) {
        const idKey = idMapping?.[scope];
        const filteredItem =
          loadEventResponse?.mappedEventPropertyResponses?.find(
            (eventResp) => eventResp?.[idKey] === selectedSubMenuItem[idKey]
          );
        const transformEventPropertyResponse = (event) => ({
          ...event,
          id: `${event.languageName}:${event.predefinedEventPropertyResponseLocalLanguage}`,
          sId: `${event.predefinedEventPropertyResponse || "*"}:${
            event.globalStandardResponse
          }`,
        });
        const existingEventPropertyResponses =
          filteredItem?.eventPropertyResponses?.map(
            transformEventPropertyResponse
          ) || [];
        newEventPropertyResponses = newEventPropertyResponses.map(
          transformEventPropertyResponse
        );
        const eventPropertyResponses = mergeArrays(
          existingEventPropertyResponses,
          newEventPropertyResponses,
          "id"
        );
        if (isRequestRevisionFlow) {
          setRenderList(handleRequestDiff(eventPropertyResponses));
        } else {
          setRenderList(eventPropertyResponses || []);
        }
        initialList.current = [...existingEventPropertyResponses];
      } else {
        const existingEventPropertyResponses =
          loadEventResponse?.mappedEventPropertyResponses?.[0]
            ?.eventPropertyResponses || [];
        let eventPropertyResponses = [...existingEventPropertyResponses];
        if (newEventPropertyResponses?.length > 0) {
          eventPropertyResponses = mergeArrays(
            eventPropertyResponses,
            newEventPropertyResponses,
            "predefinedEventPropertyResponse"
          );
        }
        eventPropertyResponses = eventPropertyResponses.map((event) => ({
          ...event,
          id: event.predefinedEventPropertyResponse,
        }));
        if (isRequestRevisionFlow) {
          setRenderList(handleRequestDiff(eventPropertyResponses));
        } else {
          setRenderList(eventPropertyResponses);
        }
        initialList.current = eventPropertyResponses.filter(
          (epr) =>
            existingEventPropertyResponses.filter(
              (eepr) =>
                eepr.predefinedEventPropertyResponse ===
                epr.predefinedEventPropertyResponse
            ).length !== 0
        );
      }
      setUpdateDefaultLang(true);
    }
  }, [
    handleRequestDiff,
    idMapping,
    isEventsFlow,
    isRequestRevisionFlow,
    loadEventResponse?.mappedEventPropertyResponses,
    scope,
    selectedSubMenuItem,
  ]);

  useEffect(() => {
    (async () => {
      if (
        currentStep === 2 &&
        (isTraitsFlow
          ? Object.keys(traitSelected).length > 0
          : Object.keys(selectedEventProperty).length > 0) &&
        (isTraitsFlow
          ? !loadTraitResponse || !globalTraitResponsesLoaded
          : !loadEventResponse || !globalTraitResponsesLoaded)
      ) {
        await loadTraitResponses(
          isTraitsFlow ? traitSelected : selectedEventProperty
        );
      }
    })();
  }, [
    currentStep,
    isTraitsFlow,
    loadTraitResponses,
    scope,
    selectedEventProperty,
    traitSelected,
  ]);

  const traitDropdownValues = useMemo(
    () =>
      traitValue.length > 0 ||
      traitOptions.some(
        ({ traitId, traitName, description }) =>
          `${traitId} - ${traitName} - ${description}` === traitSelected.title
      )
        ? traitOptions
        : initialTraitOptions,
    [initialTraitOptions, traitOptions, traitSelected.title, traitValue.length]
  );

  const eventDropdownValues = useMemo(
    () =>
      eventValue.length > 0 || initialEventNameOptions.length === 0
        ? eventNameOptions
        : initialEventNameOptions,
    [eventNameOptions, eventValue.length, initialEventNameOptions]
  );

  const marketingProgramDropdownValues = useMemo(
    () =>
      subMenuValue.length > 0 || initialMarketingProgramOptions.length === 0
        ? marketingProgramOptions
        : initialMarketingProgramOptions,
    [
      initialMarketingProgramOptions,
      marketingProgramOptions,
      subMenuValue.length,
    ]
  );

  const legalEntitiesDropdownValues = useMemo(
    () =>
      subMenuValue.length > 0 || initialLegalEntitiesOptions.length === 0
        ? legalEntities
        : initialLegalEntitiesOptions,
    [initialLegalEntitiesOptions, legalEntities, subMenuValue.length]
  );

  const regionalDropdownValues = useMemo(
    () =>
      subMenuValue.length > 0 || initialRegionalOptions.length === 0
        ? regionFilters.map((r) => ({
            ...r,
            geoRegionCode: r.name,
            geoRegionName: r.label,
          }))
        : initialRegionalOptions,
    [initialRegionalOptions, regionFilters, subMenuValue.length]
  );

  useEffect(() => {
    if (currentStep === 1) {
      globalTraitResponses.current = [];
    }
  }, [currentStep]);

  const subList = useMemo(() => {
    if (scope === "regional") {
      return regionalDropdownValues
        ?.filter((region) => {
          if (region.label !== "GLOBAL" && region.geoRegionName !== "GLOBAL") {
            return true;
          }
          return false;
        })
        .map((region) => {
          return {
            ...region,
            title: region.label
              ? `${region.name} - ${region.label}`
              : `${region.geoRegionCode} - ${region.geoRegionName}`,
          };
        });
    }
    if (scope === "legalEntity") {
      return legalEntitiesDropdownValues.map((entity) => ({
        ...entity,
        title: `${entity.legalEntityId || entity.legalEntityNumber} - ${
          entity.legalEntityName
        }`,
      }));
    }
    return marketingProgramDropdownValues.map((program) => ({
      ...program,
      title: `${program.marketingProgramNumber} - ${
        program.marketingProgramDescription ||
        /* istanbul ignore next */ program.description
      }`,
    }));
  }, [
    legalEntitiesDropdownValues,
    marketingProgramDropdownValues,
    regionalDropdownValues,
    scope,
  ]);

  const stateInitializedRef = useRef(false);

  useEffect(() => {
    if (stateInitializedRef.current) {
      return;
    }
    (async () => {
      if (state?.selectedSubMenuItem && isEmpty(selectedSubMenuItem)) {
        if (isTraitsFlow) {
          setLoadTraitResponse(state.trait);
        } else if (isEventsFlow) {
          setLoadEventResponse(state.event);
        }
      }
      const trait = isRequestRevisionFlow
        ? request?.requestDetails?.trait
        : state?.trait;
      if (isTraitsFlow && !trait) {
        history.push("/traits?displayTab=trait-responses");
      }
      if (
        isTraitsFlow &&
        (state?.trait || isRequestRevisionFlow) &&
        isEmpty(traitSelected?.traitId) &&
        !isEmpty(trait)
      ) {
        const ts = !isEmpty(trait)
          ? {
              ...trait,
              title: `${trait.traitId} - ${trait.traitName} - ${
                trait.traitDescription || trait.description
              }`,
            }
          : {};
        setTraitSelected(ts);
      }
      if (state?.selectedSubMenuItem) {
        let subMenuItemInfo = {};
        if (isTraitsFlow && state?.trait) {
          subMenuItemInfo = state?.trait?.mappedTraitResponses?.find(
            (traitResp) =>
              String(traitResp[idMapping[state?.trait?.scope]]) ===
              state?.selectedSubMenuItem.scopeIdentifier?.split(" - ")?.[0]
          );
        } else if (isEventsFlow && state?.event) {
          subMenuItemInfo = state?.event?.mappedEventPropertyResponses?.find(
            (eventResp) =>
              String(eventResp[idMapping[state?.event?.scope]]) ===
              state?.selectedSubMenuItem.scopeIdentifier?.split(" - ")?.[0]
          );
        }
        if (subMenuItemInfo) {
          setSelectedSubMenuItem({
            title: state.selectedSubMenuItem.scopeIdentifier,
            marketingProgramNumber: subMenuItemInfo?.marketingProgramNumber,
            marketingProgramDescription:
              subMenuItemInfo?.marketingProgramDescription,
            marketingProgramName: subMenuItemInfo?.marketingProgramName,
            geoRegionCode: subMenuItemInfo?.geoRegionCode,
            geoRegionName: subMenuItemInfo?.geoRegionName,
            geoRegionDescription: subMenuItemInfo?.geoRegionDescription,
            legalEntityId: subMenuItemInfo?.legalEntityId,
            legalEntityName: subMenuItemInfo?.legalEntityName,
            legalEntityShortName: subMenuItemInfo?.legalEntityShortName,
          });
        }
      }
      stateInitializedRef.current = true;
    })();
  }, [
    selectedSubMenuItem,
    state,
    request,
    isRequestRevisionFlow,
    isTraitsFlow,
    traitSelected,
    currentStep,
  ]);

  useEffect(() => {
    if (scope && scope !== "global" && isEmpty(selectedSubMenuItem?.title)) {
      setErrors((errs) => ({
        ...errs,
        missingSubMenuItemError: `Please select a ${
          scopeMapping[scope]
        } to view the mapped ${
          isTraitsFlow ? "trait" : "event property"
        } responses`,
      }));
    } else if (scope && scope !== "global" && !isEmpty(selectedSubMenuItem)) {
      setErrors((errs) => ({
        ...errs,
        missingSubMenuItemError: null,
      }));
    } else if (scope === "global" && errors.missingSubMenuItemError) {
      setErrors((errs) => ({
        ...errs,
        missingSubMenuItemError: null,
      }));
    }
  }, [isTraitsFlow, scope, scopeMapping, selectedSubMenuItem]);

  // Disable MPN level mapping check
  // useEffect(() => {
  //   if (scope === "marketingProgram" && traitMps?.length > 0) {
  //     if (
  //       isTraitsFlow &&
  //       selectedSubMenuItem?.marketingProgramNumber &&
  //       traitMps
  //     ) {
  //       const mp = traitMps?.find(
  //         (traitMp) =>
  //           traitMp.marketingProgramNumber ===
  //           selectedSubMenuItem?.marketingProgramNumber
  //       );
  //       if (isEmpty(mp)) {
  //         setErrors({
  //           mpMappingError: `Trait ${traitSelected?.traitId} - ${traitSelected?.traitName} is not mapped to the marketing program ${selectedSubMenuItem?.title}. Please map the trait to the Marketing program before creating trait responses at the marketing program level.`,
  //         });
  //         return;
  //       }
  //     }
  //   }
  //   if (errors.mpMappingError) {
  //     setErrors((errs) => ({ ...errs, mpMappingError: null }));
  //   }
  // }, [
  //   scope,
  //   isTraitsFlow,
  //   selectedSubMenuItem,
  //   traitMps,
  //   traitSelected,
  //   setErrors,
  // ]);

  const subtitleText = () => {
    if (!isSelected) {
      return t("manage_traits_responses.associated_global_standard_responses");
    }

    if (isTraitsFlow) {
      return t("manage_traits_responses.associated_trait_responses");
    }

    return t("manage_traits_responses.new_event_response");
  };

  const addResponseBtnText = () => {
    if (!isSelected) {
      return t("manage_traits_responses.add_global_standard_response");
    }
    if (isTraitsFlow) {
      return t("manage_traits_responses.add_traits_response");
    }
    return t("manage_traits_responses.add_event_response");
  };

  const alertText = () => {
    if (!isSelected) {
      return t("manage_traits_responses.no_global_standard_responses");
    }
    if (isTraitsFlow) {
      return t("manage_traits_responses.no_traits");
    }
    return t("manage_traits_responses.no_event_responses");
  };

  const traitsResponsesRequestOutputTableColumns = useMemo(
    () => [
      {
        field: "requestId",
        headerName: t("common.labels.request_id"),
        flex: 1,
        sortable: false,
        disableToggle: true,
      },
      ...(isTraitsFlow
        ? [
            {
              field: "traitName",
              headerName: t("common.labels.trait_name"),
              flex: 1,
              sortable: false,
            },
          ]
        : [
            {
              field: "eventName",
              headerName: t("common.labels.event_name"),
              flex: 1,
              sortable: false,
            },
          ]),
      {
        field: "scope",
        headerName: t("common.scope"),
        flex: 1,
        sortable: false,
      },
      {
        field: "status",
        headerName: t("status.status"),
        flex: 1,
        renderCell: (params) => {
          return (
            <div className={classes.statusWrapper}>
              <StatusBadge
                status={params.value}
                showTooltip
                onTooltipClick={() => {
                  if (
                    params.value.toLowerCase() ===
                    requestStatus.APPROVED.toLowerCase()
                  ) {
                    setTraitsResponseOutput({
                      ...traitsResponsesResponse[0].output,
                      traitName: traitsResponsesResponse[0].traitName,
                      isGlobal: scope === "global",
                    });
                  }
                }}
              />
            </div>
          );
        },
        sortable: false,
      },
    ],
    [
      classes.statusWrapper,
      isTraitsFlow,
      requestStatus.APPROVED,
      t,
      traitsResponsesResponse,
    ]
  );

  let headerText = "";
  if (isRequestRevisionFlow) {
    if (isTraitsFlow) {
      headerText = t("manage_traits_responses.trait_response_revision");
    } else {
      headerText = t(
        "manage_traits_responses.event_property_response_revision"
      );
    }
  } else if (isTraitsFlow) {
    headerText = isEmpty(state?.trait)
      ? t("manage_traits_responses.trait_response_creation")
      : t("traits_container.traits_responses_management");
  } else {
    headerText = eventNameExists
      ? t("manage_traits_responses.event_response_management")
      : t("manage_traits_responses.event_response_creation");
  }

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink={
        isTraitsFlow
          ? "/traits?displayTab=trait-responses"
          : "/events?displayTab=event-responses"
      }
      goToText={
        isTraitsFlow
          ? t("access_denied.go_to_traits")
          : t("access_denied.go_to_events")
      }
    />
  ) : (
    <>
      <InputFlow
        steps={[
          isTraitsFlow
            ? t("traits_traits_responses.headings.step_1")
            : t("traits_traits_responses.headings.events_flow_step_1"),
          t("traits_traits_responses.headings.step_2"),
          t("traits_traits_responses.headings.step_3"),
        ]}
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading}
        headerText={headerText}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ||
                (currentStep === 2 && initialStep !== 1) ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                variant="outlined"
                onClick={async () => {
                  setErrors({});
                  if (currentStep === 1) {
                    const errs = {};
                    if (isTraitsFlow && isEmpty(traitSelected)) {
                      errs.traitSelectedError = t("errors.field_required");
                    }
                    if (isEventsFlow && isEmpty(selectedEventName)) {
                      errs.selectedEventNameError = t("errors.field_required");
                    }
                    if (isEventsFlow && isEmpty(selectedEventProperty)) {
                      errs.selectedEventPropertyError = t(
                        "errors.field_required"
                      );
                    }
                    setErrors(errs);
                    const errorValues = Object.values(errs);
                    if (errorValues.length > 0) {
                      return;
                    }
                  }
                  if (currentStep === 2) {
                    // Disable MPN Mapping validation
                    // if (scope === "marketingProgram") {
                    //   if (
                    //     isTraitsFlow &&
                    //     selectedSubMenuItem.marketingProgramNumber
                    //   ) {
                    //     const mp = traitMps.find(
                    //       (traitMp) =>
                    //         traitMp.marketingProgramNumber ===
                    //         selectedSubMenuItem.marketingProgramNumber
                    //     );
                    //     if (isEmpty(mp)) {
                    //       setErrors({
                    //         mpMappingError: `Trait ${loadResponse?.traitId} - ${loadResponse?.traitName} is not mapped to the marketing program ${selectedSubMenuItem.title}. Please map the trait to the Marketing program before creating trait responses at the marketing program level.`,
                    //       });
                    //       return;
                    //     }
                    //   }
                    // }
                    if (isEmpty(diffList)) {
                      setErrors({
                        noChangesError: t("create_use_case.no_traits_changes"),
                      });
                      return;
                    }
                  }
                  setErrors({});
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const payload = createBackendPayload(
                        traitSelected,
                        {
                          ...selectedEventProperty,
                          ...(isRequestRevisionFlow
                            ? request?.requestDetails
                            : state?.trait),
                          ...selectedEventName,
                        },
                        diffList,
                        scope,
                        selectedSubMenuItem,
                        globalTraitResponses.current
                      );

                      let data;
                      if (isRequestRevisionFlow) {
                        data = await updateRequest(
                          request.requestId,
                          payload,
                          isTraitsFlow
                            ? applicationConfig.modules.traits
                            : applicationConfig.modules.events
                        );
                        setRequest(data);
                        setIsRequestRevisionUpdated(true);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      } else {
                        data = await createResponsesRequest(
                          isTraitsFlow ? "traits" : "events",
                          payload
                        );

                        setTraitsResponsesResponse(
                          data.items.map((item, index) => ({
                            ...item,
                            id: index,
                            requestId: `CRS${item.requestId}`,
                            status: item.status.toUpperCase(),
                            traitName: item.trait?.traitName,
                            eventName: item.eventName,
                            propertyName: item.propertyName,
                            output: item.output,
                            scope: scopeMapping[item.scope.type],
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.7,
                    }}
                  >
                    <CircularProgress color="palette.static.white" size={20} />
                  </Box>
                )}
                {createTraitsResponsesConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={classes.lastStepFooter}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to={
                    isTraitsFlow
                      ? "/traits?displayTab=trait-responses"
                      : "/events?displayTab=event-responses"
                  }
                >
                  {isTraitsFlow
                    ? t("manage_traits_responses.back_to_traits_responses")
                    : t("manage_traits_responses.back_to_events_responses")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${
                    traitsResponsesResponse[0].requestId.startsWith("CRS")
                      ? traitsResponsesResponse[0].requestId.slice(3)
                      : traitsResponsesResponse[0].requestId
                  }`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div className={clsx(isInfoStep && classes.paddingZero)}>
          {currentStep === 1 && loading && (
            <>
              <Skeleton height={65} />
              <Skeleton height={65} />
            </>
          )}
          {currentStep === 1 && !loading && (
            <div className={classes.marketingProgramContainer}>
              <div
                className={clsx(classes.flexContainer, classes.justifyContent)}
                key="ecosystem-container"
              >
                {isTraitsFlow && (
                  <>
                    <Typography>{t("common.labels.trait")}</Typography>
                    <div>
                      <CustomAutoComplete
                        disableCloseOnSelect={false}
                        className="traits-responses"
                        isMultiple={false}
                        options={traitDropdownValues.map((option) => ({
                          ...option,
                          title: `${option.traitId} - ${option.traitName} - ${option.description}`,
                        }))}
                        filterOptions={(options) => options}
                        id="traits"
                        placeholder={t("autocomplete.trait_name_placeholder")}
                        inputValue={traitValue || traitSelected.title}
                        isExternalInputValue
                        loading={traitsLoading}
                        value={traitSelected}
                        setValue={(val) => {
                          setTraitSelected(val || {});
                          setTraitValue("");
                          if (loadTraitResponse) {
                            setLoadTraitResponse(null);
                          }
                          setScope("global");
                          setSelectedSubMenuItem(null);
                          setSubMenuValue("");
                        }}
                        onInputChange={(val) => {
                          setTraitValue(val);
                          setTraitOptions([]);
                          setTraitsLoading(true);
                        }}
                      />
                      {errors.traitSelectedError && (
                        <FormHelperText error>
                          {errors.traitSelectedError}
                        </FormHelperText>
                      )}
                    </div>
                  </>
                )}
                {isEventsFlow && (
                  <div className={classes.eventInputWrapper}>
                    <div
                      className={clsx(
                        classes.eventNameInput,
                        classes.inputContainer
                      )}
                    >
                      <Typography
                        component={InputLabel}
                        variant="h6"
                        id="event-name-label"
                      >
                        {t("common.labels.event_name")}
                      </Typography>
                      <AutoComplete
                        options={eventDropdownValues.map((value) => ({
                          ...value,
                          title: value.eventName,
                        }))}
                        filterOptions={(options) => options}
                        id="event-name"
                        data-testid="autocomplete-wrapper"
                        placeholder="Event Names"
                        loading={eventNamesLoading}
                        getOptionLabel={(option) => option.title || ""}
                        getOptionSelected={(option, value) =>
                          option.title === value.title
                        }
                        value={selectedEventName}
                        onChange={(event, value) => {
                          setSelectedEventName(value);
                          if (loadEventResponse) {
                            setLoadEventResponse(null);
                          }
                        }}
                        disabled={eventNameExists}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Please select an event name"
                            onChange={(event) => {
                              setEventNamesLoading(true);
                              memoizedEventsFromApi(event.target.value);
                              setEventValue(event.target.value);
                            }}
                          />
                        )}
                      />
                      {errors.selectedEventNameError && (
                        <FormHelperText error>
                          {errors.selectedEventNameError}
                        </FormHelperText>
                      )}
                    </div>
                    <div
                      className={clsx(
                        classes.eventPropertyInput,
                        classes.inputContainer
                      )}
                    >
                      <Typography variant="h6" component={InputLabel}>
                        {t("common.labels.event_property_name")}
                      </Typography>
                      <div>
                        <AutoComplete
                          disablePortal
                          disabled={isEmpty(selectedEventName)}
                          id="event-property"
                          data-testid="autocomplete-wrapper"
                          value={selectedEventProperty}
                          getOptionLabel={(option) => {
                            if (option.title) {
                              return option.title.toString();
                            }
                            return "";
                          }}
                          options={eventProperties.map((property) => ({
                            ...property,
                            title: property.propertyName,
                          }))}
                          getOptionSelected={(option, value) =>
                            option.title === value.title
                          }
                          fullWidth
                          onChange={(_, value) => {
                            setSelectedEventProperty(value);
                            if (loadEventResponse) {
                              setLoadEventResponse(null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              data-testid="property-input"
                              placeholder="Example: bannerTitle"
                            />
                          )}
                        />
                        {errors.selectedEventPropertyError && (
                          <FormHelperText error>
                            {errors.selectedEventPropertyError}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className={classes.marketingProgramContainer}>
              <div
                className={clsx(classes.flexContainer, classes.justifyContent)}
              >
                <FormControl fullWidth>
                  <InputLabel id="scope-label">Scope</InputLabel>
                  <Select
                    value={scope}
                    label="Scope"
                    id="scope"
                    disabled={loading || initialStep !== 1}
                    onChange={(e) => {
                      setScope(e.target.value);
                      setSelectedSubMenuItem(null);
                      setSubMenuValue("");
                      if (isTraitsFlow) {
                        setLoadTraitResponse(null);
                      } else {
                        setLoadEventResponse(null);
                      }
                      if (errors.noChangesError) {
                        setErrors({ ...errors, noChangesError: null });
                      }
                    }}
                  >
                    <MenuItem value="global" data-testid="global-option">
                      Global
                    </MenuItem>
                    <MenuItem value="regional" data-testid="regional-option">
                      Region
                    </MenuItem>
                    <MenuItem value="legalEntity" data-testid="legal-option">
                      Legal Entity
                    </MenuItem>
                    <MenuItem
                      value="marketingProgram"
                      data-testid="marketing-option"
                    >
                      Marketing Program
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              {scope !== "global" && !loading && (
                <div
                  className={clsx(
                    classes.inputContainer,
                    classes.justifyContent
                  )}
                  key="marketing-program"
                >
                  <InputLabel shrink id="scope-value-label">
                    {scope === "regional"
                      ? "Region"
                      : scope
                          ?.replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                  </InputLabel>
                  <Autocomplete
                    options={subList}
                    id="marketing-programs"
                    placeholder={`Please select a ${
                      scope === "regional"
                        ? "Region"
                        : scope
                            ?.replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())
                    }`}
                    loading={marketingProgramsLoading || legalEntitiesLoading}
                    getOptionLabel={(option) => option.title || ""}
                    getOptionSelected={(option, value) =>
                      option.title === value.title
                    }
                    value={selectedSubMenuItem}
                    onChange={(event, value) => {
                      setErrors({
                        ...errors,
                        scopeIdentifier: null,
                      });
                      if (!isEmpty(value)) {
                        setSelectedSubMenuItem({
                          ...value,
                          scopeIdentifier: value.title,
                        });
                      } else {
                        setSelectedSubMenuItem({});
                      }
                      if (!isTraitsFlow) {
                        setLoadEventResponse(null);
                      }
                      setSubMenuValue("");
                      if (errors.noChangesError) {
                        setErrors({ ...errors, noChangesError: null });
                      }
                    }}
                    disabled={initialStep !== 1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        onChange={(event) => {
                          if (scope === "marketingProgram") {
                            setMarketingProgramsLoading(true);
                            setMarketingProgramOptions([]);
                            getMarketingProgramsFromApi(event.target.value);
                          }
                          if (scope === "legalEntity") {
                            setLegalEntities([]);
                            memoizedLegalEntitiesFromApi(event.target.value);
                          }
                          setSubMenuValue(event.target.value);
                        }}
                        placeholder={`Please select a ${
                          scope === "regional"
                            ? "Region"
                            : scope
                                ?.replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())
                        }`}
                        error={Boolean(errors.scopeIdentifier)}
                        helperText={
                          <InlineMessage message={errors.scopeIdentifier} />
                        }
                      />
                    )}
                  />
                </div>
              )}
              <div>
                {scope &&
                  (scope === "global"
                    ? true
                    : !isEmptyString(selectedSubMenuItem?.title)) &&
                  renderList?.length > 0 && (
                    <Box sx={{ mb: 0.25 }}>
                      <Typography>
                        {isEventsFlow
                          ? "Associated Event Property Responses"
                          : "Associated Trait Responses"}
                      </Typography>
                    </Box>
                  )}
                {!loading && errors.missingSubMenuItemError && (
                  <div>
                    <Alert severity="info">
                      {errors.missingSubMenuItemError}
                    </Alert>
                  </div>
                )}
                {errors.mpMappingError && (
                  <div>
                    <Alert severity="error">{errors.mpMappingError}</Alert>
                  </div>
                )}
                {loading ? (
                  <>
                    <Skeleton height={56} />
                    <Skeleton height={56} />
                    <Skeleton width={56} height={24} />
                  </>
                ) : (
                  <>
                    {scope &&
                      (scope === "global"
                        ? true
                        : selectedSubMenuItem?.title) && (
                        <TraitAccordion
                          list={renderList || []}
                          currentStep={currentStep}
                          isTraitsFlow={isTraitsFlow}
                          setEditableTrait={setEditableTrait}
                          setTraitsResponsesModal={setTraitsResponsesModal}
                          setOpenDeleteModal={setOpenDeleteModal}
                          isSelected={isSelected}
                          scope={scope}
                        />
                      )}
                    {isEmpty(renderList) &&
                      !errors.missingSubMenuItemError &&
                      !errors.mpMappingError &&
                      !loading && (
                        <Alert
                          classes={{
                            root: classes.alert,
                          }}
                          severity="info"
                        >
                          {alertText()}
                        </Alert>
                      )}
                  </>
                )}
              </div>
              {errors.noChangesError && (
                <div>
                  <Alert severity="error">{errors.noChangesError}</Alert>
                </div>
              )}
              {!loading &&
                scope &&
                (scope === "global" ? true : selectedSubMenuItem?.title) && (
                  <div className={globalStyles.addTraitBtn}>
                    <AddCircleOutlineIcon />
                    <Button onClick={() => setTraitsResponsesModal(true)}>
                      {addResponseBtnText()}
                    </Button>
                  </div>
                )}
            </div>
          )}
          {currentStep === 3 && (
            <div>
              <DetailsDOM details={details} />
              <div className={classes.confirmationContainer}>
                <Typography variant="h4">{subtitleText()}</Typography>
                <TraitAccordion
                  list={diffList || []}
                  currentStep={currentStep}
                  isTraitsFlow={isTraitsFlow}
                  setEditableTrait={setEditableTrait}
                  setTraitsResponsesModal={setTraitsResponsesModal}
                  setOpenDeleteModal={setOpenDeleteModal}
                  isSelected
                  scope={scope}
                />
              </div>
            </div>
          )}
          {(!currentStep || currentStep < 1 || currentStep > 3) && (
            <div
              className={classes.tableWrapper}
              style={{
                height: 70 * traitsResponsesResponse.length + 60,
              }}
            >
              <Table
                columns={traitsResponsesRequestOutputTableColumns}
                rows={traitsResponsesResponse}
                tableStyle={classes.myRequestsTable}
              />
            </div>
          )}
        </div>
      </InputFlow>
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={
          !isSelected
            ? t("manage_traits_responses.delete_global_standard_response")
            : t("manage_traits_responses.delete_traits_responses_confirmation")
        }
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          setRenderList((list) =>
            list.filter((x) => x.id !== editableTrait.id)
          );
          setUpdateDefaultLang(true);
          if (errors.noChangesError) {
            setErrors({ ...errors, noChangesError: null });
          }
          onCloseDeleteModal();
        }}
        type="error"
      />
      {!isEmpty(traitsResponseOutput) && (
        <TraitsResponseRequestOutputModal
          isOpen
          isTraitsFlow={isTraitsFlow}
          requestId={traitsResponsesResponse[0].requestId}
          data={traitsResponseOutput}
          onClose={() => setTraitsResponseOutput({})}
        />
      )}
      {traitsResponsesModal && (
        <TraitsResponseModal
          diffList={diffList}
          ref={globalTraitResponses}
          globalTraitResponses={globalTraitResponses.current}
          renderList={renderList}
          setRenderList={setRenderList}
          editableTrait={editableTrait}
          isTraitsFlow={isTraitsFlow}
          isEventsFlow={isEventsFlow}
          isGlobal={scope === "global"}
          open={traitsResponsesModal}
          defaultLanguage={defaultLanguage}
          setDefaultLanguage={setDefaultLanguage}
          handleClose={() => {
            setEditableTrait({});
            setTraitsResponsesModal(false);
          }}
        />
      )}
      <Prompt
        message={() => {
          if (isRequestRevisionFlow || isEmpty(traitSelected)) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={!isInfoStep}
      />
    </>
  );
};

const DetailsDOM = memo(function DetailsDOM({ details }) {
  const classes = useStyles();
  return (
    <>
      {details
        .filter((i) => i.label)
        .map((info) => {
          return (
            <div
              className={clsx(
                classes.flexContainer,
                classes.infoContainer,
                classes.justifyContent
              )}
              key={`${info.label}${info.index}`}
            >
              <Typography variant="h6">{info.label} :</Typography>
              <Typography variant="h6">{info.value}</Typography>
            </div>
          );
        })}
    </>
  );
});

DetailsDOM.propTypes = {
  details: PropTypes.arrayOf().isRequired,
};

const TraitAccordion = memo(function TraitAccordion({
  list,
  currentStep,
  isTraitsFlow,
  setEditableTrait,
  setTraitsResponsesModal,
  setOpenDeleteModal,
  isSelected,
  scope,
}) {
  const classes = useStyles();
  const accordionClasses = useAccordionStyles();
  const [expanded, setExpanded] = useState(false);
  const [aliases, setAliases] = useState([]);
  const [openAliasesModal, setOpenAliasesModal] = useState(false);
  const { traitsResponsesKeys } = traitsModuleConfig;
  const { eventsResponsesKeys } = eventsModuleConfig;

  const toggleAccordion = useCallback(
    (panel) => (event, isExpanded) => {
      if (!isSelected) {
        return;
      }
      setExpanded(isExpanded ? panel : false);
    },
    [isSelected, scope]
  );

  return list.map((row, i) => {
    const id = `system-${i.toString()}`;
    let statusText;

    if (row.deleted && row.isUpdated) {
      statusText = applicationConfig.status.existing;
    } else if (row.deleted) {
      statusText = applicationConfig.status.deleted;
    } else if ((row.new && row.reference) || (row.new && row.oldValue)) {
      statusText = applicationConfig.status.updated;
    } else if (row.new) {
      statusText = applicationConfig.status.new;
    }

    const languageCode =
      truncateString(row?.languageCode || (scope === "global" && "EN") || "", {
        maxLength: 40,
        showEllipsis: true,
      })?.toUpperCase() ?? /* istanbul ignore next */ "EN";

    const traitResponseLocalLanguage = truncateString(
      !isSelected
        ? row.predefinedTraitResponse ||
            row.predefinedEventPropertyResponse ||
            row.id
        : row.predefinedTraitResponseLocalLanguage ||
            row.predefinedEventPropertyResponseLocalLanguage ||
            row.predefinedEventPropertyResponse ||
            row.predefinedTraitResponse,
      {
        maxLength: 40,
        showEllipsis: true,
      }
    );

    return (
      <div
        key={i.toString()}
        className={clsx(
          classes.flexContainer,
          classes.fullWidth,
          classes.trait
        )}
      >
        <Accordion
          className={clsx(accordionClasses.accordion, classes.customAccordion)}
          expanded={expanded === id}
          onChange={toggleAccordion(id)}
        >
          <AccordionSummary
            data-testid="accordion-summary"
            className={accordionClasses.accordionHeader}
            expandIcon={isSelected && <ExpandMoreIcon />}
          >
            <div className={clsx(classes.flexContainer, classes.fullWidth)}>
              <Typography className={accordionClasses.systemType}>
                {languageCode}
              </Typography>
              <Typography
                variant="body2"
                className={accordionClasses.systemName}
              >
                {traitResponseLocalLanguage}
              </Typography>
              {statusText && (
                <Typography
                  variant="body2"
                  checking
                  className={clsx(
                    classes.statusLabel,
                    row.new ? classes.new : classes.deleted
                  )}
                >
                  {statusText}
                </Typography>
              )}
            </div>
          </AccordionSummary>
          {isSelected && (
            <AccordionDetails className={accordionClasses.accordionContent}>
              <AccordionRowDetails
                keys={isTraitsFlow ? traitsResponsesKeys : eventsResponsesKeys}
                row={{
                  ...row,
                  language:
                    row.languageName || row.language
                      ? `${
                          row?.languageName ?? row?.language
                        } (${row?.languageCode?.toUpperCase()})`
                      : "English (EN)",
                  aliases: row.predefinedTraitResponseAliases ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setAliases(
                          row.predefinedTraitResponseAliases.map(
                            (alias, index) => ({
                              ...alias,
                              id: alias.predefinedTraitResponseAliasId || index,
                            })
                          )
                        );
                        setOpenAliasesModal(true);
                      }}
                    >
                      View Aliases
                    </Button>
                  ) : (
                    <Typography style={{ fontSize: "0.875rem" }}>
                      None
                    </Typography>
                  ),
                }}
              />
            </AccordionDetails>
          )}
        </Accordion>
        {openAliasesModal && (
          <AliasesModal
            openManageAliasesModal={openAliasesModal}
            viewOnly
            setOpenManageAliasesModal={() => {
              setOpenAliasesModal(false);
            }}
            isGlobal={scope === "global"}
            traitResponses={aliases}
          />
        )}
        {currentStep === 2 ? (
          <div className={classes.flexContainer}>
            <div
              className={classes.autoCompleteEditContainer}
              onClick={() => {
                setEditableTrait(row);
                setTraitsResponsesModal(true);
              }}
              role="button"
              data-testid="accordion-edit"
              tabIndex={0}
              onKeyDown={() => {}}
              aria-label="edit"
            >
              <EditOutlinedIcon />
            </div>
            <div
              className={classes.autoCompleteDeleteContainer}
              onClick={() => {
                setEditableTrait(row);
                setOpenDeleteModal(true);
              }}
              data-testid="accordion-delete"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              aria-label="delete"
            >
              <DeleteOutlineOutlinedIcon />
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  });
});

TraitAccordion.propTypes = {
  list: PropTypes.arrayOf().isRequired,
  currentStep: PropTypes.number.isRequired,
  isTraitsFlow: PropTypes.bool.isRequired,
  setEditableTrait: PropTypes.func.isRequired,
  setTraitsResponsesModal: PropTypes.func.isRequired,
  setOpenDeleteModal: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
};

export default memo(NewTraitsResponseContainer);
