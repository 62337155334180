import api from "../utilities/api";

const getJobDetails = async (jobId) => {
  const apiUrl = `/jobs`;
  const {
    data: { data },
  } = await api.get(`${apiUrl}/${jobId}`);

  return data;
};

export default getJobDetails;
