import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import useGlobalStyles from "../../../../../assets/styles/global";

import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import { KeyValueSeparator } from "../../../../../components/AccordionRowDetails";
import CustomModal from "../../../../../components/CustomModal";

import applicationConfig from "../../../../../config/applicationConfig";
import pageAccessConfig from "../../../../../config/pageAccessConfig";

import useUserProfile from "../../../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../../../utilities/checkUserAuthorization";
import { splitCamelCaseString } from "../../../../../utilities/formValidation";

import useStyles from "./styles";

const OptsDetailsModal = ({
  title,
  subtitle,
  data,
  loading,
  open,
  onClose,
}) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const history = useHistory();
  const { user } = useUserProfile();

  const { optMappings, optTraits, versions } = data;

  const rows = Object.keys(data)
    .filter(
      (item) =>
        item !== "optTraits" && item !== "optMappings" && item !== "versions"
    )
    .map((key) => {
      const camelCase = splitCamelCaseString(key);
      return {
        label: camelCase[0].toUpperCase() + camelCase.slice(1),
        value: data[key]?.toString(),
      };
    })
    .filter(({ value }) => !!value);

  const mappings = optMappings?.map((x) => ({
    ...x,
    contactPointTypeCode: `${x.contactPointTypeCode} - ${x.contactPointTypeName}`,
    contactPointCategoryCode: `${x.contactPointCategoryCode} - ${x.contactPointCategoryDescription}`,
    contactPointTypeName: x.contactPointTypeName?.split(" ")[0],
    cdpMarketingProgramDescription: `${x.cdpMarketingProgramNumber} - ${x.cdpMarketingProgramDescription}`,
  }));

  const renderUi = () => {
    if (tab === 0) {
      return (
        <div style={{ height: 400, overflowY: "scroll" }}>
          <table className={globalClasses.table}>
            <tbody>
              {rows.map((row, ind) => {
                let val = row.value;
                if (/<[^>]+>/i.test(val)) {
                  val = (
                    <span
                      style={{ fontSize: "0.875rem" }}
                      dangerouslySetInnerHTML={{ __html: row.value }}
                    />
                  );
                }
                return (
                  <KeyValueSeparator
                    keyVal={row.label}
                    value={val}
                    ind={ind.toString()}
                    separator={null}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }

    if (tab === 1) {
      return optMappings.length > 0 ? (
        <AccordionModalDetails
          keys={[
            {
              name: t("common.labels.marketing_program"),
              id: "cdpMarketingProgramDescription",
            },
            {
              name: "Channel",
              id: "contactPointTypeCode",
            },
            {
              name: "Contact Point Category",
              id: "contactPointCategoryCode",
            },
            {
              name: t("common.labels.service_name"),
              id: "serviceName",
            },
            {
              name: t(
                "opts_container.modals.text_mapping_modal.primary_indicator"
              ),
              id: "primaryIndicator",
            },
            {
              name: t(
                "opts_container.modals.text_mapping_modal.subscription_opt_number"
              ),
              id: "subscriptionOptNumber",
            },
          ]}
          rows={mappings}
          rowKeys={{
            title: "contactPointTypeName",
            subTitle: "cdpMarketingProgramDescription",
          }}
        />
      ) : (
        <Typography variant="body2">No Opt Mappings found</Typography>
      );
    }

    if (tab === 2) {
      return optTraits.length > 0 ? (
        <AccordionModalDetails
          keys={[
            {
              name: t("common.labels.marketing_program"),
              id: "cdpMarketingProgramDescription",
            },
            {
              name: "Service Name",
              id: "serviceNames",
            },
          ]}
          type="traitNames"
          rows={optTraits}
          rowKeys={{
            title: "cdpMarketingProgramDescription",
            subTitle: "traitName",
          }}
        />
      ) : (
        <Typography variant="body2">No Opt Traits mapped</Typography>
      );
    }

    return versions.length > 0 ? (
      <AccordionModalDetails
        keys={[
          {
            name: t("opts_container.modals.versions_modal.full_opt_text"),
            id: "optTextEnglish",
          },
          {
            name: t(
              "opts_container.modals.versions_modal.opt_text_local_language"
            ),
            id: "optTextLocalLanguage",
          },
          {
            name: t("opts_container.modals.versions_modal.version_number"),
            id: "ciamOptVersionNumber",
          },
          {
            name: t("opts_container.modals.versions_modal.created_date"),
            id: "createdDate",
          },
          {
            name: t("opts_container.modals.versions_modal.opt_start_date"),
            id: "optStartDate",
          },
        ]}
        rows={versions}
        rowKeys={{
          title: "ciamOptVersionNumber",
          subTitle: "optTextEnglish",
        }}
      />
    ) : (
      <Typography variant="body2">No Opt versions mapped</Typography>
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      data={{
        optDetails: rows,
        mappings,
        optTraits,
        optVersions: versions,
      }}
      subtitle={subtitle}
      loading={loading}
      contentClassName={classes.optModalContents}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
              root: classes.tab,
            }}
          >
            <Tab label="OPT DETAILS" />
            <Tab label="MAPPINGS" />
            <Tab label="OPT TRAITS" />
            <Tab label="Opt Versions" />
          </Tabs>
          {renderUi()}
          {checkUserAuthorization(user.access, pageAccessConfig.createOpts) && (
            <Button
              onClick={() => {
                history.push(applicationConfig.pathnames.opts_revision, {
                  ciamOptId: data.ciamOptId,
                  optTextEnglish: data.optTextEnglish,
                  optTextLocal: data.optTextLocalLanguage,
                  ciamLegalEntityId: data.ciamLegalEntityId,
                  marketingProgramNumber: data.ciamMarketingProgramNumber,
                  marketingProgramDescription:
                    data.ciamMarketingProgramDescription,
                });
              }}
              classes={{
                root: globalClasses.btn,
              }}
              variant="outlined"
              style={{
                marginTop: "34px",
                justifySelf: "flex-end",
              }}
            >
              MANAGE OPT ID
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

OptsDetailsModal.defaultProps = {
  title: null,
};

OptsDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    optMappings: PropTypes.shape([]).isRequired,
    optTraits: PropTypes.shape([]).isRequired,
    versions: PropTypes.shape([]).isRequired,
    ciamOptId: PropTypes.string.isRequired,
    optTextEnglish: PropTypes.string.isRequired,
    optTextLocalLanguage: PropTypes.string.isRequired,
    ciamLegalEntityId: PropTypes.number.isRequired,
    ciamMarketingProgramNumber: PropTypes.number.isRequired,
    ciamMarketingProgramDescription: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OptsDetailsModal;
