import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    swaggerContainer: {
      marginTop: "100px",
      maxWidth: "1200px",
      margin: "100px auto 0",
    },
    moduleSelect: {
      width: "300px",
    },
  };
});

export default useStyles;
