import { useState, useRef } from "react";

import { Paper, Typography, Tab, Tabs } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getInsights from "../../../api/get-insights";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import InsightDetailsModal from "./components/InsightDetailsModal";
import useStyles from "./styles";

const InsightsContainer = () => {
  const {
    insightTableColumns,
    insightsSearchDefaultFilterState,
    insightsSearchFilter,
  } = traitsModuleConfig;
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const childRef = useRef();
  const [tab, setTab] = useState(2);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "insightsId",
    row: {},
  });

  const { user } = useUserProfile();
  const { t } = useTranslation();
  const { loading } = useLoadingSpinner();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchTraits
  );

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "insightsId",
      row: {},
    });
  };

  const filterFieldMap = {
    insightsId: "insightsId",
    insightsName: "insightsName",
    description: "description",
    outputTrait: "outputTrait",
    "": "searchText",
  };

  return !loading && !isUserAuthorized ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("traits_container.insights_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={tab}
          onChange={(_, number) => setTab(number)}
          aria-label="basic tabs"
          classes={{
            indicator: globalStyles.indicator,
          }}
        >
          <Tab
            label="Traits"
            onClick={() => history.push("/traits?displayTab=traits")}
          />
          <Tab
            label="Trait Responses"
            onClick={() => history.push("/traits?displayTab=trait-responses")}
          />
          <Tab
            label="Insights"
            onClick={() => history.push("/traits?displayTab=insights")}
          />
        </Tabs>
        <div className={classes.flexContainer}>
          <div className={classes.flexContainer}>
            <SearchFilter
              ref={childRef}
              onSearch={(data) => {
                setFilters(data);
              }}
              searchFilters={insightsSearchFilter}
              defaultFilterState={insightsSearchDefaultFilterState}
            />
            <StyledTooltip
              placement="top"
              title={t("common.labels.clear_search")}
            >
              <div
                role="button"
                aria-hidden="true"
                data-testid="reset-btn"
                onClick={resetFilter}
              >
                <ClearIcon data-testid="filter-icon" />
              </div>
            </StyledTooltip>
          </div>
        </div>
      </div>
      <Paper data-testid="insights-container" elevation={2}>
        <div className={classes.insightsTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getInsights(
                parseSearchFilter([filters], filterFieldMap, page, perPage)
              )
            }
            moduleName="insights"
            filters={filters}
            columns={insightTableColumns}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            exportMainSheetName="Insights"
            openModal={() => setDrawerOpen(true)}
          />
        </div>
      </Paper>
      {cellData.field === "insightsId" && drawerOpen && (
        <InsightDetailsModal
          title={`${t("common.labels.insight_id")} - ${
            cellData.row.insightsId
          }`}
          subtitle={t("traits_container.insight_id_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

export default InsightsContainer;
