import api from "../utilities/api";

import { getValue } from "../utilities/cache";

const getEventProperties = async (eventName, params) => {
  const url = `/events/properties`;
  const refreshCache = getValue("events");
  const updatedEventName = eventName?.split(" ")?.join("_");
  const {
    data: { data },
  } = await api.get(url, {
    params: {
      eventName: updatedEventName,
      refreshCache,
      denormalize: !params?.denormalize ? false : params.denormalize,
    },
  });
  return data;
};

export default getEventProperties;
