import i18next from "i18next";

import isEmpty from "../../../../utilities/isEmpty";

import extractNamesFromTemplate from "./extractNamesFromTemplate";

const validateConsentTexts = ({
  countryValue,
  updatedConsentTexts,
  languagesValue,
  templateVariablesValues,
}) => {
  const errs = {};
  if (isEmpty(countryValue)) {
    errs.countryError = i18next.t("errors.field_required");
  }
  if (!Array.isArray(languagesValue) || languagesValue?.length === 0) {
    errs.languagesError = i18next.t("errors.field_required");
  }
  if (Object.keys(updatedConsentTexts).length > 0) {
    const { consentText, noticeText } = Object.values(updatedConsentTexts)[0];
    const fields = extractNamesFromTemplate(consentText, noticeText);
    const requiredFields = fields.filter((field) =>
      isEmpty(templateVariablesValues[field])
    );
    requiredFields.forEach((field) => {
      errs[field] = i18next.t("errors.field_required");
    });
  }
  return errs;
};

export default validateConsentTexts;
