import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import PropTypes from "prop-types";

import { isEmptyString } from "../../utilities/formValidation";

import useStyles from "./styles";

const InlineMessage = ({ message, state }) => {
  const classes = useStyles();

  if (isEmptyString(message)) {
    return null;
  }

  return (
    <span className={clsx(classes.flexContainer, classes[state])}>
      <InfoIcon />
      <span>{message}</span>
    </span>
  );
};

InlineMessage.defaultProps = {
  state: "error",
  message: "",
};

InlineMessage.propTypes = {
  message: PropTypes.string,
  state: PropTypes.string,
};

export default InlineMessage;
