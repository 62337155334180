import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getConsentDetails = async (consentId) => {
  const refreshCache = getValue("consents");
  const apiUrl = `/consent-mappings/${consentId}?refreshCache=${refreshCache}`;
  const {
    data: { data },
  } = await api.get(apiUrl);

  return data;
};

export default getConsentDetails;
