import api from "../utilities/api";

const logoutUser = async (pingToken, pingRefreshToken) => {
  const response = await api.post(
    "/auth/logout",
    {},
    {
      headers: {
        "x-crs-ping-token": pingToken,
        "x-crs-ping-refresh-token": pingRefreshToken,
      },
    }
  );
  return response.data;
};

export default logoutUser;
