import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getConsentMappings = async (params) => {
  const refreshCache = getValue("consents");
  let url = `/consent-mappings`;
  if (params) {
    url += `${params}&refreshCache=${refreshCache}`;
  } else {
    url += `?refreshCache=${refreshCache}`;
  }
  const {
    data: { data },
  } = await api.get(url);

  return data;
};

export default getConsentMappings;
