import { useState, useEffect } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getEvents from "../../../../api/get-events";

import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";
import eventsModuleConfig from "../../../../config/eventsModuleConfig";
import { isEmptyString } from "../../../../utilities/formValidation";
import useImmer from "../../../../utilities/useImmer";
import validateEventsInfo from "../../NewEventsContainer/helpers/validateEventsInfo";

import useStyles from "./styles";

const NewEvent = ({
  eventsInfo,
  setEventsInfo,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  eventsSelected,
  title,
}) => {
  const initialFocusedValues = {
    eventName: false,
    eventDescription: false,
    eventCategory: false,
    actionTakenBy: false,
    eventType: false,
    eventSubType: false,
    gacaEventName: false,
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [focused, setFocused] = useImmer(initialFocusedValues);
  const [eventNameValidationState, setEventNameValidationState] = useState({});

  const { t } = useTranslation();
  const classes = useStyles();
  const { createEventConstants } = eventsModuleConfig;
  const initialErrorsState = {
    eventNameError: null,
    eventNameErrorState: "",
    eventDescriptionError: null,
    eventCategoryError: null,
    actionTakenByError: null,
    eventTypeError: null,
    eventSubTypeError: null,
    gacaEventNameError: null,
  };
  const [errors, setErrors] = useState(initialErrorsState);

  const resetState = () => {
    setFocused(initialFocusedValues);
    setCurrentStep(1);
    setErrors(initialErrorsState);
    setEventNameValidationState({});
  };

  const checkStep1Disabled = (errorMap) => {
    const requiredFields = ["eventName", "eventDescription"];
    const errorValues = JSON.parse(JSON.stringify(errorMap));
    const checkRequiredFields = requiredFields.filter((field) =>
      isEmptyString(eventsInfo[field])
    );
    if (errorValues.eventNameErrorState === applicationConfig.status.success) {
      delete errorValues.eventNameErrorState;
      delete errorValues.eventNameError;
    }
    return !(
      Object.values(errorValues).filter((val) => val).length === 0 &&
      checkRequiredFields.length === 0
    );
  };

  const searchForEvent = async () => {
    try {
      setEventNameValidationState({
        message: t("create_event.errors.event_name.message_1"),
        state: "info",
      });

      const rsp = await getEvents(eventsInfo.eventName, "v2", null);
      const itemsTobeSearched = rsp.items;
      itemsTobeSearched.push(
        ...eventsSelected.map((event) => ({ eventName: event.title }))
      );

      const isMatched = rsp.items.filter(
        (event) =>
          event.eventName.toLowerCase() === eventsInfo.eventName.toLowerCase()
      );
      if (isMatched.length > 0) {
        setEventNameValidationState({
          message: t("create_event.errors.event_name.error_1"),
          state: "error",
        });
        return true;
      }
      setEventNameValidationState({
        message: t("create_event.errors.event_name.message_2"),
        state: "success",
      });
      return false;
    } catch (error) {
      setEventNameValidationState({
        message: t("create_event.errors.event_name.message_2"),
        state: "success",
      });
      return false;
    }
  };

  const newEventFields = [
    {
      label: t("common.labels.event_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      required: true,
      props: {
        placeholder: t("create_event.placeholders.event_name"),
        onChange: (event) => {
          setEventsInfo((draft) => {
            draft.eventName = event.target.value;
          });
          setEventNameValidationState({
            message: "",
          });
        },
        error:
          eventNameValidationState.state === "error" ||
          errors.eventNameError !== null,
        value: eventsInfo.eventName,
        helperText: (
          <InlineMessage
            message={errors.eventNameError || eventNameValidationState.message}
            state={eventNameValidationState.state}
          />
        ),
        onBlur: () => {
          setFocused((draft) => {
            draft.eventName = true;
          });
        },
        onFocus: () => {
          setEventNameValidationState({});
          setFocused((draft) => {
            draft.eventName = false;
          });
        },
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.event_description"),
      type: applicationConfig.inputType.textInput,
      required: true,
      props: {
        placeholder: t("create_event.placeholders.event_description"),
        onChange: (event) =>
          setEventsInfo((draft) => {
            draft.eventDescription = event.target.value;
          }),
        error: errors.eventDescriptionError !== null,
        value: eventsInfo.eventDescription,
        helperText: <InlineMessage message={errors.eventDescriptionError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.eventDescription = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.eventDescription = false;
          });
        },
        inputProps: {
          "data-testid": "event-description",
        },
        rows: 2,
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.event_category"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !eventsInfo.eventCategory ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setEventsInfo((draft) => {
            draft.eventCategory = event.target.value;
          }),
        inputProps: {
          "data-testid": "event-category",
        },
        values: createEventConstants.eventCategoryOptions.map((option) => ({
          label: option,
          value: option,
        })),
        value: eventsInfo.eventCategory,
        disabled: isDisabled,
        helperText: focused.eventCategory && (
          <InlineMessage message={errors.eventCategoryError} />
        ),
      },
    },
    {
      label: t("create_event.action_taken_by"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !eventsInfo.actionTakenBy ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setEventsInfo((draft) => {
            draft.actionTakenBy = event.target.value;
          }),
        values: createEventConstants.actionTakenByOptions.map((option) => ({
          label: option,
          value: option,
        })),
        value: eventsInfo.actionTakenBy,
        disabled: isDisabled,
        onBlur: () => {
          setFocused((draft) => {
            draft.actionTakenBy = true;
          });
        },
        helperText: focused.actionTakenBy && (
          <InlineMessage message={errors.actionTakenByError} />
        ),
      },
    },
    {
      label: t("common.labels.event_type"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("create_event.placeholders.event_type"),
        onChange: (event) => {
          setEventsInfo((draft) => {
            draft.eventType = event.target.value;
          });
        },
        value: eventsInfo.eventType,
        multiline: true,
        disabled: isDisabled,
        helperText: focused.eventType && (
          <InlineMessage message={errors.eventTypeError} />
        ),
      },
    },
    {
      label: t("common.labels.event_sub_type"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("create_event.placeholders.event_sub_type"),
        onChange: (event) => {
          setEventsInfo((draft) => {
            draft.eventSubType = event.target.value;
          });
        },
        value: eventsInfo.eventSubType,
        inputProps: {
          "data-testid": "eventSubType",
        },
        type: "number",
        disabled: isDisabled,
        helperText: focused.eventSubType && (
          <InlineMessage message={errors.eventSubTypeError} />
        ),
      },
    },
    {
      label: t("common.labels.gaca_event_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("create_event.placeholders.gaca_event_name"),
        onChange: (event) => {
          setEventsInfo((draft) => {
            draft.gacaEventName = event.target.value;
          });
        },
        error: errors.gacaEventNameError !== null,
        value: eventsInfo.gacaEventName,
        helperText: focused.gacaEventName && (
          <InlineMessage message={errors.gacaEventNameError} />
        ),
        onBlur: () => {
          setFocused((draft) => {
            draft.gacaEventName = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.gacaEventName = false;
          });
        },
        inputProps: {
          "data-testid": "gacaEventName",
        },
        multiline: true,
        disabled: isDisabled,
      },
    },
  ];
  useEffect(() => {
    const errors1 = validateEventsInfo(eventsInfo, focused, isDisabled);
    setErrors(errors1);
  }, [eventsInfo, focused]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">
            {!isDisabled
              ? title
              : `${t("common.new_event")} - ${eventsInfo.eventName}`}
          </Typography>
          <div
            className={classes.marginLeftAuto}
            onClick={() => {
              handleClose();
              resetState();
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        {currentStep === 1 && (
          <DialogContent>
            <Form
              fields={newEventFields}
              fieldClassName={clsx(
                classes.fieldContainer,
                classes.flexContainer
              )}
            />
          </DialogContent>
        )}
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          {!(currentStep === 1 && isDisabled) && (
            <div className={classes.btnContainer}>
              <Button
                onClick={() => {
                  if (currentStep === 1) {
                    if (!isDisabled) {
                      setIsCancelClicked(true);
                    } else {
                      handleClose();
                    }
                  } else {
                    setCurrentStep(currentStep - 1);
                  }
                }}
                color="primary"
                variant={
                  currentStep === 2 && isDisabled ? "contained" : "outlined"
                }
              >
                {currentStep === 1 ? t("common.cancel1") : t("common.previous")}
              </Button>
            </div>
          )}
          {!(currentStep === 2 && isDisabled) && !isDisabled && (
            <div className={classes.btnContainer}>
              <Button
                onClick={async () => {
                  setEventNameValidationState({});
                  const allFocusedValues = {};
                  Object.keys(initialFocusedValues).forEach((key) => {
                    allFocusedValues[key] = true;
                  });

                  setFocused(allFocusedValues);
                  const errors1 = validateEventsInfo(
                    eventsInfo,
                    allFocusedValues,
                    isDisabled
                  );
                  if (Object.values(errors1).some((x) => x)) {
                    setErrors(errors1);
                  }
                  if (!Object.values(errors1).some((x) => x) && !isDisabled) {
                    const isInValid = await searchForEvent();
                    if (isInValid) {
                      return;
                    }
                  }
                  if (checkStep1Disabled(errors1)) {
                    return;
                  }

                  handleSubmit(eventsInfo);
                  resetState();
                }}
                color="primary"
                variant="contained"
              >
                {currentStep === 1 &&
                  eventNameValidationState.state === "info" && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.5,
                      }}
                    >
                      <CircularProgress size={20} color="white" />
                    </Box>
                  )}
                {!isDisabled && t("common.submit")}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewEvent.defaultProps = {
  eventsInfo: {},
};

NewEvent.propTypes = {
  eventsInfo: PropTypes.shape({
    eventName: PropTypes.string,
    eventDescription: PropTypes.string,
    eventCategory: PropTypes.string,
    actionTakenBy: PropTypes.string,
    eventType: PropTypes.string,
    eventSubType: PropTypes.string,
    gacaEventName: PropTypes.string,
  }),
  setEventsInfo: PropTypes.func.isRequired,
  eventsSelected: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
      isNewEvent: PropTypes.bool.isRequired,
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewEvent;
