import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

import applicationConfig from "../../../../../config/applicationConfig";

const TraitsResponseRequestOutputModal = ({
  onClose,
  isOpen,
  data,
  requestId,
}) => {
  const { warnings, items } = data;
  const { t } = useTranslation();
  const isTraitsFlow = !!data?.traitName;

  const traitsColumns = [
    {
      field: "traitName",
      headerName: t("common.labels.trait_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
  ];
  const eventsColumns = [
    {
      field: "eventName",
      headerName: t("common.labels.event_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "propertyName",
      headerName: t("common.labels.event_property_name"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
  ];

  const columns = [
    ...(isTraitsFlow ? traitsColumns : eventsColumns),
    {
      field: "language",
      headerName: t("common.language"),
      flex: 1,
      sortable: false,
    },
  ];

  if (!data.isGlobal) {
    if (isTraitsFlow) {
      columns.push({
        field: "predefinedTraitResponseLocalLanguage",
        headerName: t("common.labels.trait_response_local_language"),
        flex: 1,
        sortable: false,
      });
    } else {
      columns.push({
        field: "predefinedEventPropertyResponseLocalLanguage",
        headerName: t(
          "eventsResponses.search_filter.events_response_local_language"
        ),
        flex: 1,
        sortable: false,
      });
    }
  } else {
    columns.push({
      field: "globalStandardResponse",
      headerName: t("common.labels.global_standard_response"),
      flex: 1,
      sortable: false,
    });
  }

  columns.push(
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    }
  );

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    const row = {
      id: `${warning?.traitId}-${index}`,
      [isTraitsFlow ? "traitName" : "eventName"]:
        data.traitName || data.eventName,
      status: applicationConfig.status.warning,
      message: warning.message,
    };
    if (!isTraitsFlow) {
      row.propertyName = data?.propertyName;
    }
    return row;
  });

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      if (!item.mappingWarning) {
        status = applicationConfig.status.success;
        message = data.isGlobal
          ? t("manage_traits_responses.global_standard_responses_created")
          : t("manage_traits_responses.trait_responses_created");
      } else if (item.mappingWarning) {
        status = applicationConfig.status.warning;
        message = item.mappingWarning;
      }
      if (item.deleted) {
        message = data.isGlobal
          ? "Global Standard Response deleted successfully"
          : "Trait Response deleted successfully";
      } else if (item.isUpdated) {
        message = data.isGlobal
          ? "Global Standard Response updated successfully"
          : "Trait Response updated successfully";
      }
      return {
        id: `${item.traitId}-${index}`,
        traitName: data.traitName,
        eventName: data.eventName,
        propertyName: data.propertyName,
        language: item.language || item.languageName,
        ...(!data.isGlobal
          ? {
              predefinedTraitResponseLocalLanguage:
                item.predefinedTraitResponseLocalLanguage,
              predefinedEventPropertyResponseLocalLanguage:
                item.predefinedEventPropertyResponseLocalLanguage,
            }
          : {
              globalStandardResponse: item.globalStandardResponse,
            }),
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => onClose()}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={(() => {
        if (!data.isGlobal) {
          return isTraitsFlow
            ? t("request_output.traits_responses_modal_title")
            : t("request_output.events_responses_modal_title");
        }
        return t("request_output.global_standard_response_modal_title");
      })()}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

TraitsResponseRequestOutputModal.defaultProps = {
  requestId: "",
};

TraitsResponseRequestOutputModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    traitName: PropTypes.string,
    eventName: PropTypes.string,
    propertyName: PropTypes.string,
    isGlobal: PropTypes.bool,
  }).isRequired,
  requestId: PropTypes.number,
};

export default TraitsResponseRequestOutputModal;
