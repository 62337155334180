import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import pageAccessConfig from "../../config/pageAccessConfig";

import i18n from "../../i18n/init";

import RequestsContainer from "./Requests";
import approvalsTableColumns from "./Requests/config/approvalsTableColumns";
import requestsTableColumns from "./Requests/config/requestsTableColumns";

const TasksModule = ({ basePath, config }) => {
  let approvalConfig = config;
  if (basePath === "/tasks") {
    approvalConfig = {
      ...config,
      tableColumns: approvalsTableColumns,
      type: "approval",
      name: i18n.t("common.labels.my_approvals"),
      access: pageAccessConfig.myApprovals,
    };
  }
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}/requests`}
        render={() => <RequestsContainer config={config} type="requests" />}
      />
      <Route
        exact
        path={`${basePath}/approvals`}
        render={() => (
          <RequestsContainer config={approvalConfig} type="approvals" />
        )}
      />
      <Route exact path={`${basePath}/*`} render={() => <Redirect to="/" />} />
    </Switch>
  );
};

TasksModule.defaultProps = {
  basePath: "/tasks",
  config: {
    name: i18n.t("common.labels.my_requests"),
    tableColumns: requestsTableColumns,
    module: "global",
    type: "request",
    access: pageAccessConfig.myRequests,
  },
};

TasksModule.propTypes = {
  basePath: PropTypes.string,
  config: PropTypes.shape({
    name: PropTypes.string,
    tableColumns: PropTypes.arrayOf(PropTypes.shape({})),
    module: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default TasksModule;
