import traitsModuleConfig from "../../../../config/traitsModuleConfig";
import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateEqualStrings,
  validateAlphaNumericCharacters,
  validateStartingCharacters,
  validateEndingCharacters,
  validateMinimumCharactersCount,
  validateMaximumCharactersCount,
  validateMinimumNonSpaceWords,
} from "../../../../utilities/formValidation";

const { createTraitConstants } = traitsModuleConfig;

const validateTraitsInfo = (traitsInfo, focused, isDisabled) => {
  const errors = {
    traitNameError: null,
    traitNameErrorState: "",
    traitDescriptionError: null,
    traitInputTypeError: null,
    exampleValuesError: null,
    frequencyChangeError: null,
  };
  if (isDisabled) {
    return errors;
  }
  // Required field checks
  if (isEmptyString(traitsInfo.traitName) && focused.traitName) {
    errors.traitNameError = i18n.t("errors.field_required");
  }
  if (isEmptyString(traitsInfo.traitDescription) && focused.traitDescription) {
    errors.traitDescriptionError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(traitsInfo.traitInputType) &&
    traitsInfo.personalData &&
    focused.traitInputType
  ) {
    errors.traitInputTypeError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(traitsInfo.exampleValues) &&
    traitsInfo.personalData &&
    focused.exampleValues
  ) {
    errors.exampleValuesError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(traitsInfo.frequencyChange) &&
    traitsInfo.personalData &&
    focused.frequencyChange
  ) {
    errors.frequencyChangeError = i18n.t("errors.field_required");
  }
  // Individual field checks
  if (!isEmptyString(traitsInfo.traitName) && focused.traitName) {
    errors.traitNameErrorState = "";
    if (!validateAlphaNumericCharacters(traitsInfo.traitName)) {
      errors.traitNameError = i18n.t("common.errors.only_letters_numbers");
    } else if (validateStartingCharacters(traitsInfo.traitName, "trait")) {
      errors.traitNameError = i18n.t("new_trait.errors.trait_name.error_1");
    } else if (validateEndingCharacters(traitsInfo.traitName, "IdValue")) {
      errors.traitNameError = i18n.t("new_trait.errors.trait_name.error_2");
    } else if (!validateMinimumCharactersCount(traitsInfo.traitName, 3)) {
      errors.traitNameError = i18n.t("new_trait.errors.trait_name.error_3");
    } else if (
      !validateMaximumCharactersCount(
        traitsInfo.traitName,
        createTraitConstants.characterLimits.traitName
      )
    ) {
      errors.traitNameError = i18n.t("common.errors.character_limit_error", {
        count: createTraitConstants.characterLimits.traitName,
      });
    }
  }
  if (!isEmptyString(traitsInfo.traitDescription) && focused.traitDescription) {
    if (
      validateEqualStrings(traitsInfo.traitName, traitsInfo.traitDescription)
    ) {
      errors.traitDescriptionError = i18n.t(
        "new_trait.errors.trait_description.error_1"
      );
    } else if (
      !validateMinimumCharactersCount(traitsInfo.traitDescription, 5)
    ) {
      errors.traitDescriptionError = i18n.t(
        "new_trait.errors.trait_description.error_2"
      );
    } else if (!validateMinimumNonSpaceWords(traitsInfo.traitDescription, 3)) {
      errors.traitDescriptionError = i18n.t(
        "new_trait.errors.trait_description.error_3"
      );
    } else if (
      !validateMaximumCharactersCount(
        traitsInfo.traitDescription,
        createTraitConstants.characterLimits.traitDescription
      )
    ) {
      errors.traitDescriptionError = i18n.t(
        "common.errors.character_limit_error",
        {
          count: createTraitConstants.characterLimits.traitDescription,
        }
      );
    }
  }
  if (
    !isEmptyString(traitsInfo.exampleValues) &&
    validateEqualStrings(traitsInfo.traitName, traitsInfo.exampleValues) &&
    focused.exampleValues
  ) {
    errors.exampleValuesError = i18n.t("new_trait.errors.example_values");
  } else if (
    traitsInfo.exampleValues !== "" &&
    !validateMaximumCharactersCount(
      traitsInfo.exampleValues,
      createTraitConstants.characterLimits.exampleValues
    )
  ) {
    errors.exampleValuesError = i18n.t("common.errors.character_limit_error", {
      count: createTraitConstants.characterLimits.exampleValues,
    });
  }
  return errors;
};

export default validateTraitsInfo;
