import sourcesModuleConfig from "../../../../config/sourcesModuleConfig";

import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateAlphaNumericCharacters,
  validateEqualStrings,
  validateMinimumCharactersCount,
  validateMinimumNonSpaceWords,
  validateMaximumCharactersCount,
  validateURL,
} from "../../../../utilities/formValidation";

const { createSourceConstants } = sourcesModuleConfig;

const validateSourcesInfo = (sourcesInfo, focused) => {
  const errors = {
    sourceNameError: null,
    descriptionError: null,
    consumerFacingDescriptionError: null,
    descriptionOfProcessError: null,
    sourceTypeError: null,
    dataTypeError: null,
    businessFacingNameError: null,
    dataSourceWebsiteError: null,
    urlError: null,
    registrationPageUrlError: null,
    systemDisplayingPageError: null,
    managedByExternalAgencyError: null,
    agencyNameError: null,
    coControllerIndError: null,
    ropaIdError: null,
  };
  if (isEmptyString(sourcesInfo.sourceName) && focused.sourceName) {
    errors.sourceNameError = i18n.t("errors.field_required");
  }
  if (isEmptyString(sourcesInfo.description) && focused.description) {
    errors.descriptionError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.consumerFacingDescription) &&
    focused.consumerFacingDescription
  ) {
    errors.consumerFacingDescriptionError = i18n.t("errors.field_required");
  }
  if (sourcesInfo.sourceType === null && focused.sourceType) {
    errors.sourceTypeError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.descriptionOfProcess) &&
    focused.descriptionOfProcess
  ) {
    errors.descriptionOfProcessError = i18n.t("errors.field_required");
  }
  if (isEmptyString(sourcesInfo.dataType) && focused.dataType) {
    errors.dataTypeError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.businessFacingName) &&
    focused.businessFacingName
  ) {
    errors.businessFacingNameError = i18n.t("errors.field_required");
  }
  if (sourcesInfo.dataSourceWebsite === null && focused.dataSourceWebsite) {
    errors.dataSourceWebsiteError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.url) &&
    focused.url &&
    sourcesInfo.dataSourceWebsite
  ) {
    errors.urlError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.registrationPageUrl) &&
    focused.registrationPageUrl &&
    sourcesInfo.dataSourceWebsite &&
    sourcesInfo.dataType.includes(
      i18n.t("constants.kind_of_data_collected_options.registration_data")
    )
  ) {
    errors.registrationPageUrlError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.systemDisplayingPage) &&
    focused.systemDisplayingPage &&
    sourcesInfo.dataSourceWebsite
  ) {
    errors.systemDisplayingPageError = i18n.t("errors.field_required");
  }
  if (
    sourcesInfo.managedByExternalAgency === null &&
    focused.managedByExternalAgency
  ) {
    errors.managedByExternalAgencyError = i18n.t("errors.field_required");
  }
  if (sourcesInfo.coControllerInd === null && focused.coControllerInd) {
    errors.coControllerIndError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.agencyName) &&
    focused.agencyName &&
    sourcesInfo.managedByExternalAgency
  ) {
    errors.agencyNameError = i18n.t("errors.field_required");
  }
  if (
    isEmptyString(sourcesInfo.ropaId) &&
    focused.ropaId &&
    sourcesInfo.coControllerInd
  ) {
    errors.ropaIdError = i18n.t("errors.field_required");
  }
  // Individual fields
  if (!isEmptyString(sourcesInfo.sourceName)) {
    const sourceNameSuffix = sourcesInfo.sourceName
      .replace(sourcesInfo.marketingProgramDescription, "")
      .replace(/\s/g, "");
    if (isEmptyString(sourceNameSuffix)) {
      errors.sourceNameError = i18n.t("new_source.errors.source_name_suffix");
    } else if (!validateAlphaNumericCharacters(sourceNameSuffix, "'&()-")) {
      errors.sourceNameError = i18n.t("new_source.errors.source_name_error");
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.sourceName,
        createSourceConstants.characterLimits.sourceName
      )
    ) {
      errors.sourceNameError = i18n.t("common.errors.character_limit_error", {
        count: createSourceConstants.characterLimits.sourceName,
      });
    } else if (
      !sourcesInfo.sourceName.startsWith(
        sourcesInfo.marketingProgramDescription
      )
    ) {
      errors.sourceNameError = i18n.t("new_source.errors.source_name_prefix", {
        sourceName: sourcesInfo.marketingProgramDescription,
      });
    }
  }
  if (!isEmptyString(sourcesInfo.description)) {
    if (validateEqualStrings(sourcesInfo.description, sourcesInfo.sourceName)) {
      errors.descriptionError = i18n.t(
        "new_source.errors.source_description.error_1"
      );
    } else if (!validateMinimumCharactersCount(sourcesInfo.description, 5)) {
      errors.descriptionError = i18n.t(
        "new_source.errors.source_description.error_2"
      );
    } else if (!validateMinimumNonSpaceWords(sourcesInfo.description, 3)) {
      errors.descriptionError = i18n.t(
        "new_source.errors.source_description.error_3"
      );
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.description,
        createSourceConstants.characterLimits.description
      )
    ) {
      errors.descriptionError = i18n.t("common.errors.character_limit_error", {
        count: createSourceConstants.characterLimits.description,
      });
    }
  }
  if (!isEmptyString(sourcesInfo.consumerFacingDescription)) {
    if (
      validateEqualStrings(
        sourcesInfo.consumerFacingDescription,
        sourcesInfo.sourceName
      )
    ) {
      errors.consumerFacingDescriptionError = i18n.t(
        "new_source.errors.consumer_facing_description.error_1"
      );
    } else if (
      !validateMinimumCharactersCount(sourcesInfo.consumerFacingDescription, 5)
    ) {
      errors.consumerFacingDescriptionError = i18n.t(
        "new_source.errors.consumer_facing_description.error_2"
      );
    } else if (
      !validateMinimumNonSpaceWords(sourcesInfo.consumerFacingDescription, 3)
    ) {
      errors.consumerFacingDescriptionError = i18n.t(
        "new_source.errors.consumer_facing_description.error_3"
      );
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.consumerFacingDescription,
        createSourceConstants.characterLimits.consumerFacingDescription
      )
    ) {
      errors.consumerFacingDescriptionError = i18n.t(
        "common.errors.character_limit_error",
        {
          count:
            createSourceConstants.characterLimits.consumerFacingDescription,
        }
      );
    }
  }
  if (!isEmptyString(sourcesInfo.systemDisplayingPage)) {
    if (
      !validateMaximumCharactersCount(
        sourcesInfo.systemDisplayingPage,
        createSourceConstants.characterLimits.systemDisplayingPage
      )
    ) {
      errors.systemDisplayingPageError = i18n.t(
        "common.errors.character_limit_error",
        {
          count: createSourceConstants.characterLimits.systemDisplayingPage,
        }
      );
    }
  }
  if (!isEmptyString(sourcesInfo.agencyName)) {
    if (
      !validateMaximumCharactersCount(
        sourcesInfo.agencyName,
        createSourceConstants.characterLimits.agencyName
      )
    ) {
      errors.agencyNameError = i18n.t("common.errors.character_limit_error", {
        count: createSourceConstants.characterLimits.agencyName,
      });
    }
  }
  if (!isEmptyString(sourcesInfo.ropaId)) {
    if (
      !validateMaximumCharactersCount(
        sourcesInfo.ropaId,
        createSourceConstants.characterLimits.ropaId
      )
    ) {
      errors.ropaIdError = i18n.t("common.errors.character_limit_error", {
        count: createSourceConstants.characterLimits.ropaId,
      });
    }
  }

  if (!isEmptyString(sourcesInfo.descriptionOfProcess)) {
    if (
      validateEqualStrings(
        sourcesInfo.descriptionOfProcess,
        sourcesInfo.sourceName
      )
    ) {
      errors.descriptionOfProcessError = i18n.t(
        "new_source.errors.description_of_process.error_1"
      );
    } else if (
      !validateMinimumCharactersCount(sourcesInfo.descriptionOfProcess, 5)
    ) {
      errors.descriptionOfProcessError = i18n.t(
        "new_source.errors.description_of_process.error_2"
      );
    } else if (
      !validateMinimumNonSpaceWords(sourcesInfo.descriptionOfProcess, 3)
    ) {
      errors.descriptionOfProcessError = i18n.t(
        "new_source.errors.description_of_process.error_3"
      );
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.descriptionOfProcess,
        createSourceConstants.characterLimits.descriptionOfProcess
      )
    ) {
      errors.descriptionOfProcessError = i18n.t(
        "common.errors.character_limit_error",
        {
          count: createSourceConstants.characterLimits.descriptionOfProcess,
        }
      );
    }
  }
  if (!isEmptyString(sourcesInfo.businessFacingName)) {
    if (
      validateEqualStrings(
        sourcesInfo.businessFacingName,
        sourcesInfo.marketingProgramDescription
      )
    ) {
      errors.businessFacingNameError = i18n.t(
        "new_source.errors.business_facing_name.error_1"
      );
    }
    if (!validateAlphaNumericCharacters(sourcesInfo.businessFacingName, " ")) {
      errors.businessFacingNameError = i18n.t(
        "common.errors.only_letters_numbers"
      );
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.businessFacingName,
        createSourceConstants.characterLimits.businessFacingName
      )
    ) {
      errors.businessFacingNameError = i18n.t(
        "common.errors.character_limit_error",
        {
          count: createSourceConstants.characterLimits.businessFacingName,
        }
      );
    }
  }
  if (!isEmptyString(sourcesInfo.url)) {
    if (!validateURL(sourcesInfo.url, " ")) {
      errors.urlError = i18n.t("new_source.errors.valid_url");
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.url,
        createSourceConstants.characterLimits.url
      )
    ) {
      errors.urlError = i18n.t("common.errors.character_limit_error", {
        count: createSourceConstants.characterLimits.url,
      });
    }
  }

  if (!isEmptyString(sourcesInfo.registrationPageUrl)) {
    if (!validateURL(sourcesInfo.registrationPageUrl, " ")) {
      errors.registrationPageUrlError = i18n.t("new_source.errors.valid_url");
    } else if (
      !validateMaximumCharactersCount(
        sourcesInfo.registrationPageUrl,
        createSourceConstants.characterLimits.registrationPageUrl
      )
    ) {
      errors.registrationPageUrlError = i18n.t(
        "common.errors.character_limit_error",
        {
          count: createSourceConstants.characterLimits.registrationPageUrl,
        }
      );
    }
  }
  return errors;
};

export default validateSourcesInfo;
