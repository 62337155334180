import applicationConfig from "../../../config/applicationConfig";

import i18n from "../../../i18n/init";

const getFields = (marketingProgramsInfo) => {
  const countryCode = marketingProgramsInfo.legalEntity
    ? marketingProgramsInfo.legalEntity.legalEntityName
    : "";

  const fields = [
    {
      label: i18n.t("common.labels.brand_program_name"),
      type: applicationConfig.inputType.textInput,
      props: {
        value: marketingProgramsInfo.brandName,
      },
    },
    {
      label: i18n.t("common.labels.brand_short_name"),
      type: applicationConfig.inputType.textInput,
      props: {
        value: marketingProgramsInfo.brandShortName,
      },
    },
    {
      label: i18n.t("common.labels.legal_entity"),
      type: applicationConfig.inputType.dropdown,
      props: {
        value: countryCode,
      },
    },
    {
      label: i18n.t("common.labels.marketing_program_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        value: marketingProgramsInfo.marketingProgramName,
      },
    },
    {
      label: i18n.t("common.labels.marketing_program_description"),
      type: applicationConfig.inputType.textInput,
      props: {
        value: marketingProgramsInfo.marketingProgramDescription,
      },
    },
    {
      label: i18n.t("common.labels.marketing_program_business_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        value: marketingProgramsInfo.marketingProgramBusinessName,
      },
    },
    {
      label: i18n.t("common.labels.business_org_unit"),
      type: applicationConfig.inputType.dropdown,
      props: {
        value: marketingProgramsInfo.businessOrgUnit,
      },
    },
    {
      label: i18n.t("common.labels.starting_ecosystems"),
      type: applicationConfig.inputType.multiSelectDropdown,
      props: {
        value: marketingProgramsInfo.startingEcosystems,
      },
    },
    {
      label: i18n.t("common.labels.starting_channels"),
      type: applicationConfig.inputType.multiSelectDropdown,
      flex: 2,
      props: {
        value: marketingProgramsInfo.startingChannels,
      },
    },
  ];
  return fields;
};

export default getFields;
