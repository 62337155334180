import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Avatar,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useAccordionStyles from "../../../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../../../components/AccordionRowDetails";
import truncateString from "../../../../../utilities/truncateString";

import useStyles from "./styles";

const EventPropertiesAccordion = ({ eventProperty, statusLabel }) => {
  const accordionClasses = useAccordionStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const accordionRowDetails = [
    {
      id: "propertyName",
      name: t("use_events_container.property_name"),
    },
    {
      id: "description",
      name: t("common.labels.description"),
    },
    {
      id: "selectedLabels",
      name: "Labels",
    },
  ];

  return (
    <Accordion
      key={eventProperty.propertyName.toString()}
      className={clsx(accordionClasses.accordion, classes.fullWidth)}
    >
      <AccordionSummary
        data-testid="accordion-summary"
        className={accordionClasses.accordionHeader}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={accordionClasses.systemType}>
          {truncateString(eventProperty.propertyName, {
            maxLength: 40,
            showEllipsis: true,
          })}
        </Typography>
        <Typography variant="body2" className={accordionClasses.systemName}>
          {truncateString(eventProperty.description, {
            maxLength: 40,
            showEllipsis: true,
          })}
        </Typography>
        {statusLabel && (
          <Typography
            variant="body2"
            className={clsx(
              classes.statusLabel,
              classes[statusLabel.toLowerCase()],
              eventProperty.new ||
                (eventProperty.isUpdated && !eventProperty.deleted)
                ? classes.new
                : classes.deleted
            )}
          >
            {statusLabel}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={accordionClasses.accordionContent}>
        <AccordionRowDetails
          keys={accordionRowDetails}
          row={{
            propertyName: eventProperty.propertyName,
            description: eventProperty.description,
            dataType: eventProperty.dataType,
            selectedLabels: eventProperty.selectedLabels?.map((x, i) => (
              <div key={i} className={classes.accordionChips}>
                <Chip
                  label={x.title}
                  color="primary"
                  avatar={x.labelId ? <></> : <Avatar>NEW</Avatar>}
                />
              </div>
            )),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

EventPropertiesAccordion.propTypes = {
  eventProperty: PropTypes.shape({
    propertyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    dataType: PropTypes.string.isRequired,
    new: PropTypes.bool.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    deleted: PropTypes.bool.isRequired,
    selectedLabels: PropTypes.array.isRequired,
  }).isRequired,
  statusLabel: PropTypes.string.isRequired,
};

export default EventPropertiesAccordion;
