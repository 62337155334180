import traitsModuleConfig from "../../../../config/traitsModuleConfig";
import reverseObject from "../../../../utilities/reverseObject";

const buildMappings = (survivorshipRules, traitResponseTypes, ecosystems) => {
  const store = {};
  const mapping = reverseObject(traitsModuleConfig.survivorshipRuleMapping);
  survivorshipRules.forEach((rule) => {
    const ecosystem = ecosystems.filter(
      (entry) => entry.ecosystemId === rule.ecosystemId
    )[0];
    store[
      `${rule.traitId || rule.traitName}#${rule.marketingProgramNumber}#${
        ecosystem.ecosystemName
      }`
    ] = mapping[rule.survivorshipRule];
  });
  traitResponseTypes.forEach((traitResponseType) => {
    store[
      `${traitResponseType.traitId || traitResponseType.traitName}#${
        traitResponseType.marketingProgramNumber
      }`
    ] = traitResponseType.multiAnswerResponseIndicator;
  });
  return store;
};

export default buildMappings;
