import { useState } from "react";

import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import SwaggerUI from "swagger-ui-react";

import useStyles from "./styles";

import "swagger-ui-react/swagger-ui.css";

const modules = {
  Traits: "/api/docs/traits",
  "Use Cases": "/api/docs/use-cases",
  Sources: "/api/docs/data-sources",
  Opts: "/api/docs/opts",
  "Marketing Programs": "/api/docs/marketing-programs",
  Events: "/api/docs/events",
};

const SwaggerWrapper = () => {
  const classes = useStyles();
  const [module, setModule] = useState(modules.Traits);

  return (
    <div className={classes.swaggerContainer}>
      <FormControl className={classes.moduleSelect}>
        <InputLabel id="demo-simple-select-label">Swagger Module</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={module}
          label="Swagger Module"
          onChange={(e) => setModule(e.target.value)}
        >
          {Object.keys(modules).map((key) => (
            <MenuItem value={modules[key]}>{key}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <SwaggerUI
        url={module}
        requestInterceptor={(req) => {
          req.headers = {
            ...req.headers,
            "x-crs-ping-token": localStorage.getItem("ping-token"),
            "x-crs-user-groups-token": localStorage.getItem("groups-token"),
          };
        }}
        supportedSubmitMethods={[]}
      />
    </div>
  );
};

export default SwaggerWrapper;
