import React from "react";

import PropTypes from "prop-types";

import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import EventsResponsesManagementContainer from "../EventsResponses/EventsResponsesManagementContainer";

import EventsContainer from "./EventsContainer";
import NewEventsContainer from "./NewEventsContainer";
import UseEventContainer from "./UseEventContainer";

const EventsModule = React.memo(
  ({ basePath }) => {
    return (
      <Switch>
        <Route
          exact
          path={`${basePath}`}
          component={({ location: { search } }) => {
            if (search.includes(applicationConfig.modules.events_responses)) {
              return <EventsResponsesManagementContainer />;
            }
            return <EventsContainer />;
          }}
        />
        <Route
          exact
          path={`${basePath}/manage`}
          render={() => <NewEventsContainer />}
        />
        <Route
          exact
          path={`${basePath}/map`}
          render={() => <UseEventContainer />}
        />
        <Route
          exact
          path={`${basePath}/map/revision`}
          render={() => <UseEventContainer />}
        />
        <Route
          exact
          path={`${basePath}/*`}
          render={() => <Redirect to={`${basePath}`} />}
        />
      </Switch>
    );
  },
  () => true
);

EventsModule.defaultProps = {
  basePath: applicationConfig.basePaths.events,
};

EventsModule.propTypes = {
  basePath: PropTypes.string,
};

export default EventsModule;
