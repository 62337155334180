import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    btnContainer: {
      //   minWidth: "102px",
      height: "35px",
      "& .MuiButton-containedPrimary": {
        height: "100%",
        display: "flex",
        alignItems: "end",
      },
      "& > div:nth-child(2)": {
        zIndex: "100",
      },
      "& button:nth-child(2)": {
        width: "40px !important",
      },
    },
  };
});

export default useStyles;
