export const isEmptyString = (string) => {
  if (string === undefined || string === null) {
    return true;
  }
  if (typeof string === "boolean") {
    return false;
  }
  return typeof string === "string"
    ? string.trim().length === 0
    : string.length === 0;
};

export const validateAlphaNumericCharacters = (string, delimiter) => {
  if (isEmptyString(string)) {
    return false;
  }
  if (!delimiter) {
    return /^[a-z0-9]+$/i.test(string);
  }
  const regex = new RegExp(`^[a-z0-9${delimiter}]+$`, "i");
  return regex.test(string);
};

export const validateAlphaNumericCharactersWithSpace = (string, delimiter) => {
  if (isEmptyString(string)) {
    return false;
  }
  if (!delimiter) {
    return /^[a-z0-9 ]+$/i.test(string);
  }
  const regex = new RegExp(`^[a-z0-9 ${delimiter}]+$`, "i");
  return regex.test(string);
};

export const validateStartingCharacters = (string1, string2) => {
  if (!isEmptyString(string1) && !isEmptyString(string2))
    return string1.startsWith(string2);
  return false;
};

export const validateEndingCharacters = (string1, string2) => {
  if (!isEmptyString(string1) && !isEmptyString(string2))
    return string1.endsWith(string2);
  return false;
};

export const validateMinimumNonSpaceWords = (string, count) => {
  if (!isEmptyString(string))
    return string.split(" ").filter((val) => val.length).length >= count + 1;
  return false;
};

export const validateMinimumCharactersCount = (string, count) => {
  if (!isEmptyString(string)) return string.replace(/\s/g, "").length >= count;
  return false;
};

export const validateMaximumCharactersCount = (string, count) => {
  if (!isEmptyString(string)) return string.length <= count;
  return false;
};

export const validateEqualStrings = (string1, string2) => {
  if (!isEmptyString(string1) && !isEmptyString(string2))
    return string1.toLowerCase() === string2.toLowerCase();
  return false;
};

export const getNonAlphaNumericCharacters = (string) => {
  if (isEmptyString(string)) {
    return "";
  }
  return Array.from(
    new Set(string.replace(/[A-Za-z0-9]/g, "").split(""))
  ).toString();
};

export const splitCamelCaseString = (string) => {
  if (isEmptyString(string)) {
    return "";
  }
  return string
    .replace(/([a-z0-9](?=[A-Z]))/g, "$1 ")
    .replace(/[A-Z]+/g, (x) => {
      return `${x.substring(0, x.length - 1)} ${x[x.length - 1]}`;
    })
    .replace(/\s\s+/g, " ");
};

export const validateURL = (str) => {
  if (isEmptyString(str)) {
    return false;
  }
  const pattern = new RegExp(
    "^((ftps?|https?):\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const validatePropertyName = (string, delimiter) => {
  if (isEmptyString(string)) {
    return false;
  }
  if (!delimiter) {
    return /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?$/.test(string);
  }
  const regex = new RegExp(/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?$/, "i");
  return regex.test(string);
};

export const validateSnakeCase = (string) => {
  if (isEmptyString(string)) {
    return false;
  }
  const regex = new RegExp(/^[a-z0-9]+(?:_[a-z0-9]+)*$/);
  return regex.test(string);
};
