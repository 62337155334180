import api from "../utilities/api";

import { getValue } from "../utilities/cache";

const getTraitsResponse = async (traitId, params) => {
  const url = `/traits/${traitId}/responses`;
  const refreshCache = getValue("traits");
  const {
    data: { data },
  } = await api.get(url, {
    params: {
      refreshCache,
      ...params,
    },
  });
  return data;
};

export default getTraitsResponse;
