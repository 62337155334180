import { useEffect, useMemo, useState } from "react";

import getReferenceData from "../../../../api/get-reference-data";
import applicationConfig from "../../../../config/applicationConfig";
import useLoadingSpinner from "../../../../hooks/useLoadingSpinner";
import useNotifier from "../../../../hooks/useNotifier";
import handleError from "../../../../utilities/handleError";

const useConsentTemplateTypesAndCountries = () => {
  const [consentTemplateTypes, setConsentTemplateTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifier();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        increaseRequestsCount();
        const data = await getReferenceData(
          applicationConfig.referenceDataQueries
            .consentTemplateTypesAndCountries
        );
        setConsentTemplateTypes(data?.consentTemplateTypes);
        setCountries(
          data?.legalEntities.map((country) => {
            return {
              countryCode: country.countryCode,
              countryName: country.country,
            };
          })
        );
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            console.error(error);
          },
          addNotification,
        });
      } finally {
        decreaseRequestsCount();
        setLoading(false);
      }
    })();
  }, []);

  const val = useMemo(
    () => ({
      consentTemplateTypes,
      countries,
      loading,
    }),
    [consentTemplateTypes, countries, loading]
  );

  return val;
};

export default useConsentTemplateTypesAndCountries;
