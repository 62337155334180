import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: theme.spacing(18),
      maxWidth: "980px",
      margin: "0 auto",
    },
    paperContainer: {
      minHeight: "560px",
      maxHeight: "560px",
      "& .MuiPaper-root": {
        border: `1px solid ${theme.colorTheme.greyishWhite}`,
        borderRadius: "4px",
        padding: "60px 100px",
      },
      "& .MuiPaper-elevation1": {
        boxShadow: `0px 2px 3px ${theme.colorTheme.greyishWhite1}`,
      },
      "& .MuiSvgIcon-root": {
        width: "320px",
        height: "320px",
        fill: theme.colorTheme.greyishWhite,
      },
    },
    infoContainer: {
      color: theme.colorTheme.grey,
      "& .MuiTypography-h1": {
        fontSize: "231px",
      },
      "& .MuiTypography-h3": {
        marginTop: "-20px",
        fontSize: "56px",
      },
      "& .MuiTypography-h6": {
        marginTop: "10px",
        fontSize: "20px",
        fontWeight: "normal",
      },
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    footer: {
      marginTop: "52px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiButton-containedPrimary": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  };
});

export default useStyles;
