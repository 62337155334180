import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import NewOptIdContainer from "./NewOptIdContainer";
import NewOptsMappingContainer from "./NewOptsMappingContainer";
import NewOptsStatusTraitContainer from "./NewOptsStatusTrait";
import OptsContainer from "./OptsContainer";
import TraitsOptsContainer from "./TraitsOptsContainer";

const OptsModule = ({ basePath }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}`}
        component={({ location: { search } }) => {
          if (search.includes(applicationConfig.modules.trait_opts)) {
            return <TraitsOptsContainer />;
          }
          return <OptsContainer />;
        }}
      />
      <Route
        exact
        path={`${basePath}/mapping/new`}
        component={NewOptsMappingContainer}
      />
      <Route
        exact
        path={`${basePath}/mapping/revision`}
        component={NewOptsMappingContainer}
      />
      <Route
        exact
        path={`${basePath}/status/manage`}
        component={NewOptsStatusTraitContainer}
      />
      <Route exact path={`${basePath}/new`} component={NewOptIdContainer} />
      <Route
        exact
        path={`${basePath}/revision`}
        component={NewOptIdContainer}
      />
      <Route
        exact
        path={`${basePath}/*`}
        render={() => <Redirect to={`${basePath}`} />}
      />
    </Switch>
  );
};

OptsModule.defaultProps = {
  basePath: "/opts",
};

OptsModule.propTypes = {
  basePath: PropTypes.string,
};

export default OptsModule;
