import api from "../utilities/api";
import { getValue } from "../utilities/cache";
import isEmpty from "../utilities/isEmpty";

const getOptTraits = async (queryParams) => {
  let apiUrl = "/opts/traits";
  const refreshCache = getValue("opts");

  if (!isEmpty(queryParams)) {
    apiUrl += `${queryParams}&refreshCache=${refreshCache}`;
  } else {
    apiUrl += `?refreshCache=${refreshCache}`;
  }

  const {
    data: { data },
  } = await api.get(apiUrl);

  return data;
};

export default getOptTraits;
