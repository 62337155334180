import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

import applicationConfig from "../../../../../config/applicationConfig";

const OptsTraitRequestOutputModal = ({
  setShowOptsTraitOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  const { warnings, items } = data;
  const { t } = useTranslation();

  const columns = [
    {
      field: "optIndicatorTrait",
      headerName: t("opts_status_trait.opt_indicator_trait"),
      flex: 2,
      sortable: false,
    },
    {
      field: "optDateTrait",
      headerName: t("opts_status_trait.opt_date_trait"),
      flex: 2,
      sortable: false,
    },
    {
      field: "optId",
      headerName: t("opts_status_trait.opt_id"),
      sortable: false,
      disableToggle: true,
    },
    {
      field: "serviceName",
      headerName: t("common.labels.service_name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
      sortable: false,
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  // Process warnings
  const rows = (warnings || /* istanbul ignore next */ []).map(
    (warning, index) => {
      return {
        id: index,
        optId: warning.ciamOptId,
        optIndicatorTrait: warning.traitIdOptIndicator
          ? `${warning.traitIdOptIndicator} - ${warning.traitIdOptIndicatorName}`
          : /* istanbul ignore next */ "",
        optDateTrait: warning.traitIdOptChoiceDate
          ? `${warning.traitIdOptChoiceDate} - ${warning.traitIdOptChoiceDateName}`
          : /* istanbul ignore next */ "",
        serviceName: warning.serviceName
          ? warning.serviceName
          : /* istanbul ignore next */ "",
        status: applicationConfig.status.warning,
        message: warning.mappingWarning,
      };
    }
  );

  const getTraitIdOptIndicator = (item) => {
    if (item.traitIdOptIndicator) {
      return `${item.traitIdOptIndicator} - ${item.traitIdOptIndicatorName}`;
    }
    return "";
  };

  const getTraitIdOptChoiceDate = (item) => {
    if (item.traitIdOptChoiceDate) {
      return `${item.traitIdOptChoiceDate} - ${item.traitIdOptChoiceDateName}`;
    }
    return "";
  };

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      if (item.mappingWarning) {
        status = applicationConfig.status.warning;
        message = item.mappingWarning;
      } else if (item.new) {
        status = applicationConfig.status.success;
      } else {
        status = applicationConfig.status.warning;
      }

      if (index === 0) {
        return {
          id: `${item.optId}-${index}`,
          optId: item.ciamOptId,
          optIndicatorTrait: `${item.traitId} - ${item.traitName}`,
          optDateTrait: "",
          serviceName: item.serviceName
            ? item.serviceName
            : /* istanbul ignore next */ "",
          status,
          message,
        };
      }
      if (index === 1) {
        return {
          id: `${item.optId}-${index}`,
          optId: item.ciamOptId,
          optIndicatorTrait: "",
          optDateTrait: `${item.traitId} - ${item.traitName}`,
          serviceName: /* istanbul ignore next */ item.serviceName
            ? item.serviceName
            : "",
          status,
          message,
        };
      }
      return {
        id: `${item.optId}-${index}`,
        optId: item.ciamOptId,
        optIndicatorTrait: getTraitIdOptIndicator(item),
        optDateTrait: getTraitIdOptChoiceDate(item),
        serviceName: item.serviceName
          ? item.serviceName
          : /* istanbul ignore next */ "",
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOptsTraitOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.opts_trait_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} />
    </RequestOutputModal>
  );
};

OptsTraitRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

OptsTraitRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOptsTraitOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default OptsTraitRequestOutputModal;
