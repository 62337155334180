import { useState } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import sourcesModuleConfig from "../../../../config/sourcesModuleConfig";
import validateNewSegmentSource from "../../CreateSourcesContainer/helpers/validateNewSegmentSource";

import useStyles from "./styles";

const NewSegmentSource = ({
  isOpen,
  newSegmentSource,
  setNewSegmentSource,
  focused,
  setFocused,
  marketingProgram,
  isDisabled,
  setNewSegmentSourceModal,
  setSegmentSource,
}) => {
  const classes = useStyles();
  const [sourceAlreadyPresent, setSourceAlreadyPresent] = useState(false);

  const { t } = useTranslation();

  const errors = validateNewSegmentSource(
    focused,
    newSegmentSource,
    marketingProgram,
    isDisabled,
    sourceAlreadyPresent
  );

  const { createSourceConstants } = sourcesModuleConfig;

  const segmentSourceFields = [
    {
      label: t("common.labels.source_name"),
      type: "text-input",
      flex: 2,
      required: true,
      props: {
        onChange: (event) =>
          setNewSegmentSource((draft) => {
            draft.ecosystemSourceName = event.target.value;
          }),
        placeholder: t("common.labels.segment_source_name"),
        error: Boolean(errors.ecosystemSourceNameError),
        value: newSegmentSource.ecosystemSourceName,
        helperText: <InlineMessage message={errors.ecosystemSourceNameError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.ecosystemSourceName = true;
          });
        },
        disabled:
          isDisabled ||
          newSegmentSource.sourceIntegrationType ===
            t("constants.source_integration_type_options.janrain_source"),
        inputProps: {
          "data-testid": "segment-source-name",
        },
      },
    },
    {
      label: t("new_segment_source.source_integration_type"),
      type: "dropdown",
      flex: 2,
      required: true,
      props: {
        onChange: (event) =>
          setNewSegmentSource((draft) => {
            if (
              event.target.value ===
              t("constants.source_integration_type_options.janrain_source")
            ) {
              draft.ecosystemSourceName = `${marketingProgram.description} Janrain`;
            } else if (
              draft.sourceIntegrationType ===
              t("constants.source_integration_type_options.janrain_source")
            ) {
              draft.ecosystemSourceName = marketingProgram.description;
            }
            draft.sourceIntegrationType = event.target.value;
          }),
        label: !newSegmentSource.sourceIntegrationType
          ? t(
              "create_segment_source.new_segment_source.source_integration_type"
            )
          : "",
        error: Boolean(errors.sourceIntegrationTypeError),
        select: true,
        variant: "outlined",
        values: createSourceConstants.sourceIntegrationTypeOptions.map(
          (option) => ({
            label: option,
            value: option,
          })
        ),
        value: newSegmentSource.sourceIntegrationType,
        helperText: (
          <InlineMessage message={errors.sourceIntegrationTypeError} />
        ),
        onBlur: () => {
          setFocused((draft) => {
            draft.sourceIntegrationType = true;
          });
        },
        disabled: isDisabled,
        inputProps: {
          "data-testid": "source-integration-type",
        },
      },
    },
    {
      label: "Is the source already present in Segment?",
      type: "custom",
      element: () => (
        <div className={classes.mapToSegmentSourceWrapper}>
          <div className={classes.mapToSegmentSourceBtns}>
            <Button
              disabled={isDisabled}
              className={sourceAlreadyPresent ? "active" : ""}
              onClick={() => setSourceAlreadyPresent(true)}
            >
              Yes
            </Button>
            <Button
              disabled={isDisabled}
              className={!sourceAlreadyPresent ? "active" : ""}
              onClick={() => setSourceAlreadyPresent(false)}
            >
              No
            </Button>
          </div>
        </div>
      ),
      flex: 2,
    },
    ...(sourceAlreadyPresent
      ? [
          {
            label: "Source Slug",
            type: "text-input",
            flex: 2,
            required: true,
            props: {
              onChange: (event) =>
                setNewSegmentSource((draft) => {
                  draft.ecosystemSourceSlug = event.target.value;
                }),
              placeholder: "Source Slug",
              error: Boolean(errors.ecosystemSourceSlugError),
              value: newSegmentSource.ecosystemSourceSlug,
              helperText: (
                <InlineMessage message={errors.ecosystemSourceSlugError} />
              ),
              onBlur: () => {
                setFocused((draft) => {
                  draft.ecosystemSourceSlug = true;
                });
              },
              disabled:
                isDisabled ||
                newSegmentSource.sourceIntegrationType ===
                  t("constants.source_integration_type_options.janrain_source"),
              inputProps: {
                "data-testid": "segment-slug",
              },
            },
          },
          {
            label: "Source Key",
            type: "text-input",
            flex: 2,
            required: true,
            props: {
              onChange: (event) =>
                setNewSegmentSource((draft) => {
                  draft.ecosystemSourceKey = event.target.value;
                }),
              placeholder: "Source Key",
              error: Boolean(errors.ecosystemSourceKeyError),
              value: newSegmentSource.ecosystemSourceKey,
              helperText: (
                <InlineMessage message={errors.ecosystemSourceKeyError} />
              ),
              onBlur: () => {
                setFocused((draft) => {
                  draft.ecosystemSourceKey = true;
                });
              },
              disabled:
                isDisabled ||
                newSegmentSource.sourceIntegrationType ===
                  t("constants.source_integration_type_options.janrain_source"),
              inputProps: {
                "data-testid": "segment-source-name",
              },
            },
          },
        ]
      : []),
  ];

  const handleModalClose = () => {
    setNewSegmentSourceModal(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <DialogContent>
        <div>
          <div className={classes.modalHeaderWrapper}>
            <Typography variant="h5">
              {t("common.labels.new_segment_source")}
            </Typography>
            <IconButton aria-label="close" onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.horizontalBar} />
          <Form
            fields={segmentSourceFields}
            fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div />
        {!isDisabled && (
          <Button
            onClick={() => {
              setFocused((draft) => {
                draft.ecosystemSourceName = true;
                draft.sourceIntegrationType = true;
                if (sourceAlreadyPresent) {
                  draft.ecosystemSourceSlug = true;
                  draft.ecosystemSourceKey = true;
                }
              });

              if (Object.values(errors).some((value) => value !== null)) return;

              setSegmentSource({
                title: newSegmentSource.ecosystemSourceName,
                ecosystemSourceName: newSegmentSource.ecosystemSourceName,
                sourceIntegrationType: newSegmentSource.sourceIntegrationType,
                ...(sourceAlreadyPresent
                  ? {
                      ecoSystemSourceKey: newSegmentSource.ecosystemSourceKey,
                      ecosyStemSourceSlug: newSegmentSource.ecosystemSourceSlug,
                    }
                  : {}),
                isNewSource: true,
              });
              setNewSegmentSourceModal(false);
            }}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

NewSegmentSource.defaultProps = {
  isDisabled: false,
  setNewSegmentSourceModal: () => {},
  setSegmentSource: () => {},
  setFocused: () => {},
  focused: {},
  setNewSegmentSource: () => {},
  marketingProgram: {
    description: "",
  },
};

NewSegmentSource.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  newSegmentSource: PropTypes.shape({
    ecosystemSourceName: PropTypes.string.isRequired,
    sourceIntegrationType: PropTypes.string.isRequired,
    ecosystemSourceKey: PropTypes.string.isRequired,
    ecosystemSourceSlug: PropTypes.string.isRequired,
  }).isRequired,
  focused: PropTypes.shape({
    ecosystemSourceName: PropTypes.bool,
    sourceIntegrationType: PropTypes.bool,
  }),
  setFocused: PropTypes.func,
  setNewSegmentSource: PropTypes.func,
  marketingProgram: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool,
  setNewSegmentSourceModal: PropTypes.func,
  setSegmentSource: PropTypes.func,
};

export default NewSegmentSource;
