import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getMarketingPrograms = async (params, queryParams) => {
  let url = `/marketing-programs`;
  if (queryParams) {
    url += queryParams;
  }
  const refreshCache = getValue("marketing-programs");
  const {
    data: { data },
  } = await api.get(url, {
    params: {
      normalize: true,
      refreshCache,
      ...params,
    },
  });
  return data;
};

export default getMarketingPrograms;
