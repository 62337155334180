import { Typography, Button } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import useGlobalStyles from "../../../../assets/styles/global";

import useStyles from "./styles";

const SelectedService = ({
  isNewService,
  serviceName,
  onClick,
  onDelete,
  isDisabled,
  isConfirmationStep,
  openDetailsModal,
  hideTitle,
  required,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();

  return (
    <div className={classes.rootContainer}>
      {!hideTitle && (
        <Typography variant="h5">{t("common.labels.service_name")}</Typography>
      )}
      <div className={classes.flexContainer}>
        <div
          className={clsx(
            classes.container,
            isConfirmationStep && classes.viewDetailsContainer
          )}
        >
          {isNewService && (
            <Button variant="contained">{t("common.new")}</Button>
          )}
          <Typography
            data-testid="selected-service"
            variant="h6"
            onClick={() => {
              if (isConfirmationStep) {
                openDetailsModal();
              }
            }}
          >
            {serviceName}
            {required && <i style={{ color: "red" }}>*</i>}
          </Typography>
        </div>
        {!isDisabled && (
          <div
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex={0}
            className={classes.btnContainer}
            data-testid="btn-1"
          >
            {isNewService ? (
              <span className={globalStyles.editIcon}>
                <EditOutlinedIcon />
              </span>
            ) : (
              <span className={globalStyles.deleteIcon}>
                <DeleteOutlineOutlinedIcon />
              </span>
            )}
          </div>
        )}

        {!isDisabled && isNewService && (
          <div
            onClick={onDelete}
            onKeyDown={onDelete}
            role="button"
            tabIndex={0}
            data-testid="btn-2"
            className={classes.btnContainer}
          >
            <span className={globalStyles.deleteIcon}>
              <DeleteOutlineOutlinedIcon />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

SelectedService.defaultProps = {
  isNewService: false,
  isDisabled: false,
  isConfirmationStep: false,
  openDetailsModal: () => {},
  hideTitle: false,
  required: false,
};

SelectedService.propTypes = {
  isNewService: PropTypes.bool,
  serviceName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isConfirmationStep: PropTypes.bool,
  openDetailsModal: PropTypes.func,
  hideTitle: PropTypes.bool,
  required: PropTypes.bool,
};

export default SelectedService;
