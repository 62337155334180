import { useEffect, useState, useCallback, useMemo } from "react";

import {
  Typography,
  TextField,
  Checkbox,
  Button,
  FormHelperText,
  Chip,
  Avatar,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import AutoComplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getTraits from "../../../../../api/get-traits";
import CustomModal from "../../../../../components/CustomModal";
import InlineMessage from "../../../../../components/InlineMessage";
import applicationConfig from "../../../../../config/applicationConfig";
import useNotifier from "../../../../../hooks/useNotifier";

import i18n from "../../../../../i18n/init";
import debounce from "../../../../../utilities/debounce";
import { isEmptyString } from "../../../../../utilities/formValidation";
import handleError from "../../../../../utilities/handleError";

import isEmpty from "../../../../../utilities/isEmpty";

import useStyles from "./styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const validateNewTrait = (trait) => {
  const errors = {};
  if (isEmptyString(trait?.traitName)) {
    errors.traitNameError = i18n.t("errors.field_required");
  }
  return errors;
};

const NewTraitModal = ({
  traits,
  setTraits,
  labels,
  setLabels,
  open,
  handleClose,
  labelsLoading,
  newLabelModal,
  setNewLabelModal,
  traitSelectedIndex,
  changedItems,
}) => {
  const classes = useStyles();
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedTrait, setSelectedTrait] = useState({});
  const [traitValue, setTraitValue] = useState("");
  const [traitOptions, setTraitOptions] = useState([]);
  const [initialTraitOptions, setInitialTraitOptions] = useState([]);
  const [traitsLoading, setTraitsLoading] = useState(false);
  const [traitOptionsOpen, setTraitOptionsOpen] = useState(false);
  const { addNotification } = useNotifier();
  const [newLabel, setNewLabel] = useState("");
  const defaultErrors = {
    traitNameError: null,
    newLabelError: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const { t } = useTranslation();

  const getTraitsFromApi = useCallback(
    async (openModal, searchText = "") => {
      if (!openModal) {
        setTraitsLoading(false);
        return;
      }
      if (searchText.length === 0 && initialTraitOptions.length !== 0) {
        setTraitsLoading(false);
        return;
      }
      let filter = {};
      if (searchText && searchText.length > 0) {
        filter = {
          searchText,
          searchFields: "traitId,traitName,description",
        };
      }
      try {
        const rsp1 = await getTraits(
          `?page=1&itemsPerPage=3&${new URLSearchParams(filter).toString()}`
        );
        setTraitOptions([...rsp1.items]);
        if (searchText.length === 0) {
          setInitialTraitOptions([...rsp1.items]);
        }
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setTraitOptions([]);
          },
          addNotification,
        });
      } finally {
        setTraitsLoading(false);
      }
    },
    [addNotification, open, setTraitsLoading, setTraitOptions]
  );

  const debouncedTraitsFromApi = useMemo(
    () => debounce(getTraitsFromApi, applicationConfig.waitTime),
    [getTraitsFromApi]
  );

  const memoizedTraitsFromApi = useCallback(
    async (val) => {
      debouncedTraitsFromApi(open, val);
    },
    [debouncedTraitsFromApi]
  );

  useEffect(() => {
    if (traitSelectedIndex !== -1) {
      setSelectedTrait(traits[traitSelectedIndex] || {});
      setSelectedLabels(traits[traitSelectedIndex]?.selectedLabels || []);
    }
  }, [traitSelectedIndex, traits]);

  useEffect(() => {
    if (isEmpty(traitValue) && initialTraitOptions.length !== 0) {
      return;
    }
    setTraitsLoading(true);
    memoizedTraitsFromApi(traitValue);
  }, [memoizedTraitsFromApi, traitSelectedIndex, traitValue]);

  const handleModalClose = () => {
    setTraitValue("");
    setSelectedTrait({});
    setSelectedLabels([]);
    handleClose();
  };

  const options = isEmpty(traitValue) ? initialTraitOptions : traitOptions;

  return (
    <>
      <CustomModal
        open={open}
        onClose={handleModalClose}
        showCloseIcon
        title="Select Trait"
      >
        <div className={classes.eventPropertyModalField}>
          <Typography>
            Trait
            <i style={{ color: "red" }}>*</i>
          </Typography>
          <div style={{ width: "80%" }}>
            <AutoComplete
              id="asynchronous-traits"
              open={traitOptionsOpen}
              onOpen={() => {
                setTraitOptionsOpen(true);
              }}
              onClose={() => {
                setTraitOptionsOpen(false);
                setTraitValue("");
              }}
              getOptionSelected={(option, value) =>
                `${option.traitId}` === `${value.traitId}`
              }
              getOptionLabel={(option) =>
                option.traitName
                  ? `${option.traitId} - ${option.traitName} - ${option.description}`
                  : ""
              }
              disabled={traits[traitSelectedIndex]}
              value={selectedTrait}
              onChange={(_, value) => setSelectedTrait(value)}
              options={options.filter((x) =>
                traits.every((y) => y.traitId !== x.traitId)
              )}
              loading={traitsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    if (!isEmpty(e.target.value)) {
                      setTraitsLoading(true);
                      setTraitOptions([]);
                      setTraitValue(e.target.value);
                    } else {
                      setTraitValue(e.target.value);
                      setTraitOptions(initialTraitOptions);
                    }
                  }}
                  label="Traits"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {traitsLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {errors.traitNameError && (
              <FormHelperText
                id="my-helper-text"
                error={Boolean(errors.traitNameError)}
              >
                <InlineMessage message={errors.traitNameError} />
              </FormHelperText>
            )}
          </div>
        </div>
        <div className={classes.labelsContainer}>
          <Typography>{t("use_events_container.labels")}</Typography>
          <div style={{ width: "80%" }}>
            <AutoComplete
              multiple
              id="labels-input"
              data-testid="labels-input"
              options={labels}
              className={classes.labelsInput}
              disableCloseOnSelect
              value={selectedLabels}
              defaultValue={selectedLabels}
              loading={labelsLoading}
              onChange={(_, value) => setSelectedLabels(value)}
              getOptionSelected={(option, value) =>
                option.type !== "div" ? option.title === value.title : false
              }
              getOptionLabel={(option) =>
                option.type !== "div" ? option.title : ""
              }
              renderOption={(option, { selected }) => {
                return option.type !== "div" ? (
                  <>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                    {!option.labelId && (
                      <Button className={classes.newOptionTag}>NEW</Button>
                    )}
                  </>
                ) : (
                  option
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color="primary"
                    avatar={!option.labelId ? <Avatar>NEW</Avatar> : <></>}
                    label={option.title}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("use_events_container.labels")}
                  placeholder={t("use_events_container.placeholders.labels")}
                />
              )}
            />
          </div>
        </div>
        <div className={classes.eventPropertyModalFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const validationErrors = validateNewTrait(
                selectedTrait,
                selectedLabels
              );
              setErrors(validationErrors);
              if (
                validationErrors &&
                Object.values(validationErrors).filter((e) => e).length > 0
              ) {
                return;
              }
              let editTrait = traits[traitSelectedIndex];
              if (!editTrait) {
                const item = changedItems.find(
                  (x) => x.traitId === selectedTrait.traitId
                );
                if (item) {
                  editTrait = item;
                }
              }
              const trait = {
                ...selectedTrait,
                title: selectedTrait.traitName,
                isSelected: true,
                selectedLabels,
              };
              let cb = (x) => x;

              const beingUpdated = (a, b) => {
                if (
                  Array.isArray(a) &&
                  Array.isArray(b) &&
                  a.length !== b.length
                ) {
                  return true;
                }

                if (!a.every((x) => b.some((y) => y.title === x.title))) {
                  return true;
                }

                return false;
              };

              if (editTrait && !editTrait.new) {
                const existingLabels = editTrait.labels;
                let isUpdated = false;

                if (beingUpdated(existingLabels || [], selectedLabels || [])) {
                  isUpdated = true;
                }

                cb = (x) => {
                  const existing = x.find(
                    (y) => y.traitId === editTrait.traitId
                  );
                  if (!existing) {
                    const updatedAfterDeleteItems = changedItems
                      .filter((c) => c.traitId === editTrait.traitId)
                      .map(() => ({
                        ...trait,
                        isUpdated: true,
                        deleted: false,
                        labels: selectedLabels,
                      }));
                    return [...x, ...updatedAfterDeleteItems];
                  }
                  return x.map((y) => {
                    if (y.traitName === editTrait.traitName) {
                      return {
                        ...trait,
                        isUpdated,
                      };
                    }

                    return y;
                  });
                };
              } else if (editTrait && editTrait.new) {
                cb = (x) =>
                  x.map((y) => {
                    if (y.traitName === editTrait.traitName) {
                      return {
                        ...trait,
                        new: true,
                        labels: selectedLabels,
                      };
                    }

                    return y;
                  });
              } else {
                cb = (x) => [
                  ...x,
                  {
                    ...trait,
                    new: true,
                    labels: selectedLabels,
                  },
                ];
              }

              setTraits(cb);
              handleModalClose();
            }}
          >
            {t("use_events_container.submit")}
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        open={newLabelModal}
        onClose={() => setNewLabelModal(false)}
        title="New Label"
        showCloseIcon
      >
        <div className={classes.newLabelContainer}>
          <Typography>
            Label Name
            <i style={{ color: "red" }}>*</i>
          </Typography>
          <div>
            <TextField
              style={{ flexGrow: 1 }}
              id="outlined-basic"
              label="Label Name"
              variant="outlined"
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)}
            />
            {errors.newLabelError && (
              <FormHelperText
                id="my-helper-text"
                error={Boolean(errors.newLabelError)}
              >
                <InlineMessage message={errors.newLabelError} />
              </FormHelperText>
            )}
          </div>
        </div>
        <div className={classes.eventPropertyModalFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isEmpty(newLabel)) {
                setErrors((err) => ({
                  ...err,
                  newLabelError: i18n.t("errors.field_required"),
                }));
                return;
              }
              const newData = {
                labelName: newLabel,
                labelValue: newLabel,
                title: newLabel,
              };
              setLabels((x) => [...x.slice(0, -1), newData, x.at(-1)]);
              setSelectedLabels((x) => [...x, newData]);
              setNewLabelModal(false);
            }}
          >
            {t("use_events_container.submit")}
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

NewTraitModal.defaultProps = {
  open: false,
};

NewTraitModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  setTraits: PropTypes.func.isRequired,
  traits: PropTypes.array.isRequired,
  traitSelectedIndex: PropTypes.number.isRequired,
  labels: PropTypes.array.isRequired,
  setLabels: PropTypes.func.isRequired,
  labelsLoading: PropTypes.bool.isRequired,
  newLabelModal: PropTypes.bool.isRequired,
  setNewLabelModal: PropTypes.func.isRequired,
  changedItems: PropTypes.array.isRequired,
};

export default NewTraitModal;
