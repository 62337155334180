import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    themeWrapper: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.default,
    },
    modalBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      userSelect: "none",
    },
    modalWrapper: {
      width: "800px",
      height: "300px",
      overflowY: "hidden",
      display: "flex",
      alignItems: "center",
      "& .MuiCardContent-root": {
        flex: 1,
        height: "60%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        "& .MuiButton-contained": {
          backgroundColor: theme.palette.green.main2,
          padding: "10px 25px",
          color: theme.palette.static.white,
        },
      },
    },
    modalLeftContainer: {
      flex: 1,
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      "& svg": {
        zIndex: 1,
        position: "absolute",
        top: "30px",
        left: "30px",
        width: "115px",
        height: "50px",
      },
      "& ul": {
        marginRight: "unset",
      },
    },
    menuItem: {
      "& a": {
        textDecoration: "none",
        color: theme.palette.text.primary,
      },
    },
    heroWrapper: {
      backgroundColor: `${theme.palette.static.dullWhite} !important`,
      color: theme.palette.static.almostBlack,
      borderRadius: "15px",
      padding: "20px",
      marginBottom: "20px",
      overflow: "hidden",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      padding: "unset",
      paddingBottom: "unset !important",
      "& button, & a": {
        padding: "15px 45px",
        border: "unset",
        textTransform: "capitalize",
        backgroundColor: theme.palette.background.default,
      },
      "& .MuiButtonBase-root.MuiButton-root.MuiButton-outlined": {
        padding: "15px",
      },
      "& .MuiButton-label .MuiButton-endIcon": {
        marginLeft: "auto",
      },
    },
    card: {
      width: "220px",
      borderRadius: "15px",
      "&, & .MuiButtonBase-root": {
        border: "1px solid rgba(255, 255, 255, 0.12)",
      },
    },
    cardMedia: {
      height: "140px",
      padding: "20px",
      textAlign: "center",
      "& h5": {
        fontWeight: "bold",
      },
      "& svg": {
        marginBottom: "10px",
      },
    },
    flexContainer: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      justifyContent: "space-between",
    },
    infoList: {
      color: theme.palette.static.dullWhite,
      height: "100%",
      width: "300px",
      position: "relative",
      margin: "unset",
      zIndex: 0,
      fontSize: "18px",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      marginRight: "60px",
      "&::after": {
        zIndex: -1,
        content: "''",
        position: "absolute",
        top: "-40px",
        left: "-180px",
        backgroundColor: theme.palette.blue.darker,
        height: "150%",
        width: "150%",
        transform: "skew(-15deg)",
      },
    },
    visitorCount: {
      color: "white",
      position: "relative",
      zIndex: 0,
      fontSize: "18px",
      bottom: "30px",
      left: "40px",
    },
    flexContainer1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    alignCenter: {
      alignItems: "center",
    },
    flexContainer2: {
      display: "flex",
      flexDirection: "column",
    },
    leftContainer: {
      position: "relative",
      "& svg": {
        zIndex: 1,
        position: "absolute",
        top: "5px",
        left: "10px",
        width: "115px",
        height: "50px",
      },
    },
    rightContainer: {
      "& a": {
        color: "transparent",
      },
      "& a:last-child": {
        marginLeft: "20px",
      },
      "& h2": {
        fontSize: 32,
        fontWeight: "bold",
      },
      "& h5": {
        fontSize: 18,
        marginTop: 20,
        marginBottom: 20,
      },
      "& h6": {
        fontSize: 18,
        marginBottom: 20,
        fontWeight: "normal",
      },
      "& .MuiButton-containedPrimary": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    divider: {
      height: 1,
      width: "100%",
      backgroundColor: theme.colorTheme.white,
      opacity: 0.3,
      marginBottom: 10,
    },
    animation: {
      animation: "$moveUp 500ms",
    },
    "@keyframes moveUp": {
      "0%": {
        transform: "translateY(200px)",
      },
      "100%": {
        transform: "translateY(0)",
      },
    },
    block: {
      backgroundColor: theme.palette.primary.light,
      boxSizing: "border-box",
      padding: "30px",
      width: 350,
      height: 180,
      marginRight: 30,
      cursor: "pointer",
      "& a": {
        color: "transparent",
      },
      "& .MuiTypography-root": {
        color: theme.colorTheme.white,
      },
      position: "relative",
      "& h4": {
        fontSize: 34,
        fontWeight: "bold",
        position: "absolute",
        top: "5px",
      },
      "& h6": {
        fontSize: 20,
        fontWeight: "normal",
      },
      "& .MuiSvgIcon-root": {
        fill: theme.colorTheme.white,
      },
    },
    infoContainer: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "baseline",
      flexFlow: "row wrap",
      "& > *:not(:last-child)": {
        marginRight: "50px",
        marginBottom: "50px",
      },
      "& >:last-child": {
        marginBottom: "105px",
      },
      // "& >:last-child": {
      //   marginBottom: "163px",
      //   marginRight: "50px",
      // },
      // "& >:nth-last-child(2)": {
      //   marginBottom: "105px",
      // },
    },
    footerContainer: {
      marginTop: "20px",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiCircularProgress-colorPrimary": {
        color: theme.colorTheme.black,
      },
    },
  };
});

export default useStyles;
