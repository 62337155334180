import i18next from "i18next";

import isEmpty from "../../../../utilities/isEmpty";

const validateConsentMappingForm = (consentTemplateType, marketingProgram) => {
  const errs = {};
  if (isEmpty(consentTemplateType)) {
    errs.consentTemplateTypeError = i18next.t("errors.field_required");
  }
  if (isEmpty(marketingProgram)) {
    errs.marketingProgramError = i18next.t("errors.field_required");
  }
  return errs;
};

export default validateConsentMappingForm;
