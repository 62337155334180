import api from "../utilities/api";

const refreshToken = async () => {
  const pingRefreshToken = localStorage.getItem("ping-refresh-token");
  const response = await api.post(
    "/auth/refresh",
    {},
    {
      headers: {
        "x-crs-ping-refresh-token": pingRefreshToken,
      },
    }
  );
  return response.data;
};

export default refreshToken;
