import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

const ConsentTemplateRequestOutputModal = ({
  setShowOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  if (!data.templateVersionNumber) {
    return null;
  }
  const { templateVersionNumber, new: isNew, mappingWarning } = data;
  const { t } = useTranslation();

  const columns = [
    {
      field: "templateVersionNumber",
      headerName: t("common.labels.template_version_number"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const rows = [];

  let status = "";
  if (isNew) {
    status = "success";
  }
  if (mappingWarning) {
    status = "warning";
  }

  rows.push({
    id: templateVersionNumber,
    templateVersionNumber,
    status,
    message:
      mappingWarning || t("create_consent_template.new_template_success_msg"),
  });

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.consent_template_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

ConsentTemplateRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

ConsentTemplateRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    templateVersionNumber: PropTypes.number,
    mappingWarning: PropTypes.string,
    new: PropTypes.bool,
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ConsentTemplateRequestOutputModal;
