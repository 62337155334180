import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import CreateSourcesContainer from "./CreateSourcesContainer";
import SourcesContainer from "./SourcesContainer";

const SourcesModule = ({ basePath }) => {
  return (
    <Switch>
      <Route exact path={`${basePath}`} component={SourcesContainer} />
      <Route
        exact
        path={`${basePath}/new`}
        component={CreateSourcesContainer}
      />
      <Route
        exact
        path={`${basePath}/revision`}
        component={CreateSourcesContainer}
      />
      <Route
        exact
        path={`${basePath}/map/revision`}
        component={CreateSourcesContainer}
      />
      <Route
        exact
        path={`${basePath}/*`}
        render={() => <Redirect to={`${basePath}`} />}
      />
    </Switch>
  );
};

SourcesModule.defaultProps = {
  basePath: applicationConfig.basePaths.sources,
};

SourcesModule.propTypes = {
  basePath: PropTypes.string,
};

export default SourcesModule;
