import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../components/RequestOutputModal";
import TraitsMappingOutput from "../TraitsMappingOutput";

import useStyles from "./styles";

const TraitsRequestOutputModal = ({
  requestId,
  traitOutput,
  isLoading,
  open,
  handleClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <RequestOutputModal
      requestId={requestId}
      isLoading={isLoading}
      open={open}
      handleClose={handleClose}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.modal_title")}
      requestModalStyle={classes.requestDialog}
    >
      <TraitsMappingOutput
        data={traitOutput.output || {}}
        inputData={traitOutput.requestDetails || {}}
      />
    </RequestOutputModal>
  );
};

TraitsRequestOutputModal.defaultProps = {
  requestId: "",
  traitOutput: {},
  open: false,
  isLoading: false,
};

TraitsRequestOutputModal.propTypes = {
  requestId: PropTypes.string,
  traitOutput: PropTypes.shape(),
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

export default TraitsRequestOutputModal;
