import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    layoutChildren: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - 170px)",
    },
    accordionChips: {
      display: "inline-block",
      marginRight: "4px",
      marginBottom: "4px",
      "& .MuiAvatar-root": {
        fontSize: "8px",
      },
    },
    themeWrapper: {
      paddingBottom: "100px",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      "& .MuiDataGrid-columnHeader--moving.MuiDataGrid-columnHeader": {
        backgroundColor: theme.palette.background.default,
      },
    },
    toolTipBtn: {
      marginTop: "8px",
      cursor: "pointer",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flexContainerVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "5px",
    },
    warningIcon: {
      "& path": {
        fill: theme.palette.error.main,
      },
    },
    badge: {
      backgroundColor: theme.palette.blue.darker,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "20px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.static.white,
    },
    checkboxContainer: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.light,
      },
    },
    badgeText2: {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
    btnContainer: {
      minWidth: "172px",
      height: "35px",
      "& .MuiButton-containedPrimary": {
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.primary.light,
        display: "flex",
        alignItems: "end",
      },
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginLeft: "10px",
      cursor: "pointer",
    },
    editIcon: {
      width: 16,
      height: 16,
      cursor: "pointer",
      position: "relative",
      bottom: "1px",
      left: "10px",
    },
    requestsTableStyle: {
      height: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-root .MuiDataGrid-colCellMoving": {
        backgroundColor: "transparent !important",
      },
    },
    tableHeader: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    statusWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "4px 8px",
      borderRadius: "20px",
    },
    statusText: {
      verticalAlign: "top",
    },
    switchHeader: {
      backgroundColor: theme.palette.background.default,
      "& .MuiTypography-body1": {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    flexVertical: {
      display: "flex",
      flexDirection: "column",
      "& div": {
        fontSize: "14px",
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      },
    },
    wordBreak: {
      wordBreak: "break-all",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    statusPending: {
      backgroundColor: theme.palette.warning.light,
    },
    statusApproved: {
      backgroundColor: theme.palette.green.main,
    },
    statusRejected: {
      backgroundColor: theme.palette.error.main,
    },
    statusReturned: {
      backgroundColor: theme.palette.primary.main,
    },
    fileIconWrapper: {
      marginLeft: "10px",
    },
    traitName: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      fontWeight: "bold",
    },
    traitId: {
      cursor: "pointer",
    },
    loadingSkeleton: {
      width: "807px !important",
      padding: "30px",
    },
    regionColumn: {
      margin: "8px 0",
      "& .MuiListItem-root": {
        padding: 0,
      },
      "& .MuiListItem-root+ .MuiListItem-root": {
        marginTop: "5px",
      },
    },
  };
});

export default useStyles;
