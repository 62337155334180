import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    editIconBtn: {
      "& svg": {
        color: theme.palette.blue.light,
      },
    },
    deleteIcon: {
      "& svg": {
        color: theme.palette.green.main,
      },
    },
    clearIcon: {
      "& svg": {
        color: theme.palette.error.dark,
      },
    },
    rootContainer: {
      "& .MuiTypography-h5": {
        color: `${theme.colorTheme.modalTitle} !important`,
        fontSize: "14px",
        fontWeight: 400,
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    flexContainer1: {
      display: "flex",
      alignItems: "center",
    },
    flexContainer2: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& > div:first-child": {
        width: "90%",
      },
      "& > div:last-child": {
        width: "10%",
      },
    },
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.colorTheme.greyishWhite3}`,
      borderRadius: "4px",
      marginRight: "12px",
      padding: "10px",
      minHeight: "34px",
      "& .MuiTypography-h6": {
        fontSize: "14px",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.primary.light2,
        color: theme.colorTheme.grey,
        marginRight: "20px",
      },
    },
    btnContainer: {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
