import getUseCaseDetails from "../api/get-use-case-details";

import i18n from "../i18n/init";

const useCaseManagementModuleConfig = {
  createUseCaseConstants: {
    useCaseCreationHeadings: {
      1: i18n.t("create_use_case.headings.step_1"),
      2: i18n.t("create_use_case.headings.step_1"),
      3: i18n.t("create_use_case.headings.step_3"),
      4: i18n.t("create_use_case.headings.step_4"),
    },
    useCaseRevisionHeadings: {
      1: i18n.t("create_use_case.revision.headings.step_1"),
      2: i18n.t("create_use_case.revision.headings.step_1"),
      3: i18n.t("create_use_case.revision.headings.step_3"),
      4: i18n.t("create_use_case.revision.headings.step_4"),
    },
    useCaseCreationMessages: {
      1: i18n.t("create_use_case.steps.step_1"),
      2: i18n.t("create_use_case.steps.step_2"),
      3: i18n.t("create_use_case.steps.step_3"),
      4: i18n.t("common.texts.request_details"),
    },
    useCaseRevisionMessages: {
      1: i18n.t("create_use_case.revision.steps.step_1"),
      2: i18n.t("create_use_case.revision.steps.step_2"),
      3: i18n.t("create_use_case.revision.steps.step_3"),
      4: i18n.t("create_use_case.revision.steps.step_4"),
    },
    footerText: {
      1: i18n.t("common.next"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
      4: i18n.t("create_use_case.footer.step_4"),
    },
  },
  requestStatus: {
    APPROVED: i18n.t("status.approved"),
    REJECTED: i18n.t("status.rejected"),
    PENDING: i18n.t("status.pending"),
    RETURNED: i18n.t("status.returned"),
    PROCESSING: i18n.t("status.processing"),
  },
  requestsConstants: {
    toolTipTextPending: i18n.t("traits_requests.tooltip.pending_status"),
    toolTipTextApproved: i18n.t("traits_requests.tooltip.approved_status"),
    toolTipTextReturned: i18n.t("traits_requests.tooltip.returned_status"),
  },
  dcfUseCaseTableColumns: [
    {
      field: "useCaseId",
      sortable: false,
      headerName: i18n.t("dcf_use_cases.table_columns.crs_use_case_id"),
      flex: 0.75,
      disableToggle: true,
      clickable: true,
      clickableCellData: {
        params: ["useCaseId"],
        api: getUseCaseDetails,
      },
    },
    {
      field: "useCaseName",
      sortable: false,
      flex: 2,
      headerName: i18n.t("dcf_use_cases.table_columns.pipeline_name"),
    },
    {
      field: "schemaValidationIndicator",
      sortable: false,
      flex: 1,
      headerName: i18n.t("dcf_use_cases.table_columns.schema_validation"),
    },
    {
      field: "dataQualityRulesIndicator",
      sortable: false,
      flex: 1,
      headerName: i18n.t("dcf_use_cases.table_columns.data_quality_rules"),
    },
    {
      field: "dataTransformationIndicator",
      sortable: false,
      flex: 1.2,
      headerName: i18n.t(
        "dcf_use_cases.table_columns.data_payload_transformation"
      ),
    },
    {
      field: "mlFunctionsIndicator",
      sortable: false,
      flex: 0.8,
      headerName: i18n.t("dcf_use_cases.table_columns.ml_functions"),
    },
    {
      field: "cdpServiceMapping",
      sortable: false,
      flex: 1.55,
      headerName: i18n.t(
        "dcf_use_cases.table_columns.implemented_in_below_microservices"
      ),
      renderCell: ({ value }) => (
        <div>
          {value?.split(",")?.map((x) => (
            <div>{x}</div>
          ))}
        </div>
      ),
    },
  ],
  dcfUseCaseSearchFilters: [
    {
      name: "useCaseName",
      label: i18n.t("dcf_use_cases.table_columns.pipeline_name"),
    },
    {
      name: "services",
      label: i18n.t("dcf_use_cases.services"),
    },
  ],
  dcfUseCaseSearchDefaultFilterState: {
    useCaseName: false,
    services: false,
  },
};

export default useCaseManagementModuleConfig;
