import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: "0 60px",
    },
    header: {
      marginTop: theme.spacing(14),
      marginBottom: "20px",
    },
    tabs: {
      "&, & .MuiTabs-scroller.MuiTabs-fixed": {
        overflow: "visible !important",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.colorTheme.white,
    },
    tableHeader: {
      height: "70px",
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.background.default,
    },
    searchInputWrapper: {
      gap: "20px",
      marginLeft: "auto",
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
    switchHeader: {
      "& .MuiTypography-body1": {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    btnContainer: {
      minWidth: "102px",
      height: "35px",
      "& .MuiButton-containedPrimary": {
        height: "100%",
        backgroundColor: theme.palette.primary.light,
        display: "flex",
        alignItems: "end",
      },
      "& > div:nth-child(2)": {
        zIndex: "100",
      },
      "& button:last-child": {
        width: "40px !important",
      },
    },
    checkboxContainer: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.light,
      },
    },
    eventsTableContainer: {
      "& .MuiDataGrid-colCellWrapper": {
        backgroundColor: theme.colorTheme.bluishWhite,
      },
    },
    filterIconWrapper: {
      display: "flex",
      alignItems: "center",
      "& svg": {
        position: "relative",
        top: "4px",
      },
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tableFooterContainer: {
      minHeight: "55px",
      backgroundColor: theme.palette.primary.light,
      zIndex: "100",
      padding: "25px 30px",
    },
    tableFooterLeft: {
      flex: "0 0 50%",
      color: "white",
      "& > div:first-child": {
        justifyContent: "flex-start",
        "& p": {
          fontWeight: "bold",
        },
      },
    },
    tableFooterRight: {
      "& p": {
        color: theme.colorTheme.white,
        cursor: "pointer",
        textDecoration: "underline",
        marginRight: "1.5rem",
      },
      "& a": {
        backgroundColor: `${theme.colorTheme.white} !important`,
        color: `${theme.palette.primary.main} !important`,
      },
    },
    accordionSelect: {
      margin: "14px 0 22px",
    },
  };
});

export default useStyles;
