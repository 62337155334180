import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateEqualStrings,
  validateAlphaNumericCharactersWithSpace,
} from "../../../../utilities/formValidation";

const validateNewServiceName = (
  newServiceName,
  marketingProgramName,
  isDisabled
) => {
  const errors = {
    serviceNameError: null,
    serviceNameDescriptionError: null,
  };
  if (isDisabled) {
    return errors;
  }

  if (!isEmptyString(newServiceName.serviceName)) {
    if (
      !validateAlphaNumericCharactersWithSpace(
        newServiceName.serviceName.trim()
      )
    ) {
      errors.serviceNameError = i18n.t(
        "new_trait.errors.only_letters_numbers_spaces"
      );
    } else if (
      !newServiceName.serviceName.startsWith(marketingProgramName?.trim())
    ) {
      errors.serviceNameError = i18n.t(
        "opts_mapping.errors.service_name_prefix",
        {
          serviceName: marketingProgramName,
        }
      );
    } else {
      const sourceNameSuffix = newServiceName.serviceName.replace(
        `${marketingProgramName}`,
        ""
      );
      if (isEmptyString(sourceNameSuffix) || sourceNameSuffix[0] !== " ") {
        errors.serviceNameError = i18n.t(
          "opts_mapping.errors.service_name_suffix",
          {
            serviceName: marketingProgramName,
          }
        );
      }
    }
  } else {
    errors.serviceNameError = i18n.t(
      "opts_mapping.errors.missing_service_name"
    );
  }

  if (!isEmptyString(newServiceName.serviceNameDescription)) {
    if (
      !validateAlphaNumericCharactersWithSpace(
        newServiceName.serviceNameDescription.trim()
      )
    ) {
      errors.serviceNameDescriptionError = i18n.t(
        "new_trait.errors.only_letters_numbers_spaces"
      );
    } else if (
      validateEqualStrings(
        newServiceName.serviceName.trim(),
        newServiceName.serviceNameDescription.trim()
      )
    ) {
      errors.serviceNameDescriptionError = i18n.t(
        "opts_mapping.errors.service_name_description"
      );
    }
  }

  return errors;
};

export default validateNewServiceName;
