import { useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CustomAutoComplete from "../../../../../components/CustomAutoComplete";
import Form from "../../../../../components/Form";

import languages from "../../../../../config/languages";

import useLoadingSpinner from "../../../../../hooks/useLoadingSpinner";

import validateConsentTemplate from "../../helpers/validateConsentTemplate";

import useStyles from "./styles";

const Details = ({
  countryValue,
  languageValue,
  consentTemplateTypeValue,
  setConsentTemplateTypeValue,
  setCountryValue,
  setLanguageValue,
  showValidation,
  consentTemplateTypes,
  countries,
}) => {
  const { t } = useTranslation();
  const { loading } = useLoadingSpinner();
  const classes = useStyles();
  const [consentTemplateTypeInputValue, setConsentTemplateTypeInputValue] =
    useState(consentTemplateTypeValue?.title || "");
  const [countryInputValue, setCountryInputValue] = useState(
    countryValue?.title || ""
  );
  const [languageInputValue, setLanguageInputValue] = useState(
    languageValue?.title || ""
  );

  const errors = useMemo(
    () =>
      validateConsentTemplate(
        consentTemplateTypeValue,
        countryValue,
        languageValue
      ),
    [consentTemplateTypeValue, countryValue, languageValue]
  );

  const [focused, setFocused] = useState({
    consentTemplateType: false,
    language: false,
    country: false,
  });

  const fields = useMemo(
    () => [
      {
        type: "custom",
        element: CustomAutoComplete,
        label: t("common.labels.consent_template_type"),
        props: {
          isMultiple: false,
          hideCheckbox: true,
          disableCloseOnSelect: false,
          disableFilterOptions: true,
          id: "consent-template-id",
          loading: !loading,
          placeholder: t(
            "create_consent_template.placeholders.consent_template_type"
          ),
          options: consentTemplateTypes.map((type) => ({
            ...type,
            title: `${type.consentTemplateTypeId} - ${type.description}`,
          })),
          value: consentTemplateTypeValue,
          inputValue:
            consentTemplateTypeInputValue || consentTemplateTypeValue?.title,
          setValue: (value) => {
            setConsentTemplateTypeValue(value);
            setConsentTemplateTypeInputValue(value?.title);
          },
          onInputChange: setConsentTemplateTypeInputValue,
          onBlur: () => {
            if (!focused.consentTemplateType) {
              setFocused((f) => ({
                ...f,
                consentTemplateType: true,
              }));
            }
          },
          error:
            (focused.consentTemplateType || showValidation) &&
            errors.consentTemplateTypeError,
          errorText: errors.consentTemplateTypeError,
        },
      },
      {
        type: "custom",
        element: CustomAutoComplete,
        label: t("common.labels.country"),
        props: {
          isMultiple: false,
          hideCheckbox: true,
          disableCloseOnSelect: false,
          disableFilterOptions: true,
          id: "country",
          loading: !loading,
          placeholder: t("create_consent_template.placeholders.country"),
          options: countries.map((c) => ({
            ...c,
            title: `${c.countryCode} - ${c.countryName}`,
          })),
          value: countryValue,
          inputValue: countryInputValue || countryValue?.title,
          setValue: (value) => {
            setCountryValue(value);
            setCountryInputValue(value?.title);
          },
          onInputChange: setCountryInputValue,
          onBlur: () => {
            if (!focused.country) {
              setFocused((f) => ({
                ...f,
                country: true,
              }));
            }
          },
          error: (focused.country || showValidation) && errors.countryError,
          errorText: errors.countryError,
        },
      },
      {
        type: "custom",
        element: CustomAutoComplete,
        label: t("common.labels.language"),
        props: {
          isMultiple: false,
          hideCheckbox: true,
          disableCloseOnSelect: false,
          disableFilterOptions: true,
          id: "language",
          loading: !loading,
          placeholder: t("create_consent_template.placeholders.language"),
          options: languages.map((l) => ({
            ...l,
            languageCode: l.languageCode.toUpperCase(),
            title: `${l.languageCode.toUpperCase()} - ${l.languageName}`,
          })),
          value: languageValue,
          inputValue: languageInputValue || languageValue?.title,
          setValue: (value) => {
            setLanguageValue(value);
            setLanguageInputValue(value?.title);
          },
          onInputChange: setLanguageInputValue,
          onBlur: () => {
            if (!focused.language) {
              setFocused((f) => ({
                ...f,
                language: true,
              }));
            }
          },
          error: (focused.language || showValidation) && errors.languageError,
          errorText: errors.languageError,
        },
      },
    ],
    [
      consentTemplateTypes,
      consentTemplateTypeInputValue,
      consentTemplateTypeValue,
      countries,
      countryInputValue,
      countryValue,
      languageValue,
      languageInputValue,
      showValidation,
      focused,
    ]
  );

  return (
    <>
      <Form
        fields={fields}
        fieldClassName={classes.inputContainer}
        containerClassName={classes.grid}
      />
    </>
  );
};

Details.defaultProps = {
  showValidation: false,
};

Details.propTypes = {
  countryValue: PropTypes.shape({
    countryName: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  languageValue: PropTypes.shape({
    languageCode: PropTypes.string.isRequired,
    languageName: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  consentTemplateTypeValue: PropTypes.shape({
    consentTemplateTypeId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
  setCountryValue: PropTypes.func.isRequired,
  setConsentTemplateTypeValue: PropTypes.func.isRequired,
  setLanguageValue: PropTypes.func.isRequired,
  showValidation: PropTypes.bool,
  consentTemplateTypes: PropTypes.arrayOf({
    consentTemplateTypeId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  countries: PropTypes.arrayOf({
    country: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
  }).isRequired,
};

export default Details;
