const logout = async () => {
  window.localStorage.removeItem("ping-token");
  window.localStorage.removeItem("ping-refresh-token");
  window.localStorage.removeItem("session-timer");
  window.localStorage.removeItem("session-end");
  window.sessionStorage.clear();
  if (window.location.pathname !== "/") {
    window.location.assign(
      `${window.location.origin}?redirect_uri=${window.location.pathname}${window.location.search}`
    );
  } else if (!window.location.search.includes("redirect_uri")) {
    window.location.assign(window.location.origin);
  }
};

export default logout;
