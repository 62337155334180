import { Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import StatusBadge from "../../../../components/StatusBadge";
import Table from "../../../../components/Table";

import applicationConfig from "../../../../config/applicationConfig";
import traitsModuleConfig from "../../../../config/traitsModuleConfig";

import reverseObject from "../../../../utilities/reverseObject";

import useStyles from "./styles";

const TraitsMappingOutput = ({ data, inputData }) => {
  const classes = useStyles();
  const { reuseTraitsTableColumns } = traitsModuleConfig;

  const { warnings = [], items = [] } = data;
  const { t } = useTranslation();

  const ruleMapping = reverseObject(traitsModuleConfig.survivorshipRuleMapping);

  // Process warnings
  const rows = warnings.map((warning, index) => {
    const { traits, trait } = inputData;
    let marketingProgram = inputData.marketingPrograms.find(
      (program) =>
        program.marketingProgramNumber === warning.marketingProgramNumber
    );
    const ecosystem = inputData.ecosystems.find(
      (inputEcosystem) => inputEcosystem.ecosystemId === warning.ecosystemId
    );
    if (marketingProgram) {
      marketingProgram = marketingProgram.marketingProgramDescription
        ? `${marketingProgram.marketingProgramNumber} - ${marketingProgram.marketingProgramDescription}`
        : `${marketingProgram.marketingProgramNumber} - ${marketingProgram.marketingProgramName}`;
    }
    const finalTrait = traits && traits.length > 0 ? traits[0] : trait;
    return {
      id: `${finalTrait.traitId}-${index}`,
      traitName: `${finalTrait.traitId} - ${finalTrait.traitName}`,
      marketingProgram,
      ecosystem: ecosystem ? ecosystem.ecosystemName : null,
      ecosystemTraitName: ecosystem ? ecosystem.ecosystemTraitName : null,
      status: applicationConfig.status.warning,
      message: warning.message,
      survivorshipRule: warning.survivorshipRule,
    };
  });

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      const isNewTrait =
        item.new && !item.marketingProgramNumber && !item.marketingProgramName;
      let marketingProgram = "";
      if (item.marketingProgramDescription) {
        marketingProgram = `${item.marketingProgramNumber} - ${item.marketingProgramDescription}`;
      } else if (item.marketingProgramName) {
        marketingProgram = `${item.marketingProgramNumber} - ${item.marketingProgramName}`;
      }

      if (item.mappingWarning) {
        status = applicationConfig.status.warning;
        message = item.mappingWarning;
      } else if (item.new) {
        status = applicationConfig.status.success;
      } else if (item.ecosystemName && !item.ecosystemTraitName?.length > 0) {
        status = applicationConfig.status.warning;
        message = t("traits_mapping_output.ecosystem_not_found");
      } else if (item.useCaseId) {
        status = applicationConfig.status.success;
        message =
          inputData.type === applicationConfig.common.approval
            ? t("create_use_case.update_use_case_preview")
            : t("create_use_case.update_use_case");
      } else {
        status = applicationConfig.status.success;
        if (item.survivorshipRule) {
          // Ecosystem & Marketing Program
          message = t("traits_mapping_output.survivorship_rule_updated");
        } else {
          message = t("traits_mapping_output.trait_response_updated");
        }
      }

      const row = {
        id: `${item.traitId}-${index}`,
        marketingProgram: isNewTrait ? "" : marketingProgram,
        traitName: isNewTrait ? (
          <div>{item.traitName}</div>
        ) : (
          `${item.traitId} - ${item.traitName}`
        ),
        ecosystem: item.ecosystemName,
        ecosystemTraitName: item.ecosystemTraitName,
        survivorshipRule: ruleMapping[item.survivorshipRule],
        status,
        message,
      };

      return row;
    })
  );

  const headers = reuseTraitsTableColumns;

  const columns = headers.map((column) => {
    const columnConfig = {
      ...column,
      sortable: false,
    };

    if (column.field === t("reuse_traits.table_columns.status1")) {
      columnConfig.renderCell = (columnData) => {
        const { value } = columnData;
        return <StatusBadge status={value} />;
      };
    }

    if (column.field === t("reuse_traits.table_columns.trait_name1")) {
      columnConfig.renderCell = (columnData) => {
        const { value } = columnData;
        if (value && value.type === "div") {
          return (
            <div className={clsx(classes.flexContainer, classes.container)}>
              {value}
              <Button variant="contained">{t("common.new")}</Button>
            </div>
          );
        }
        return value;
      };
    }

    return columnConfig;
  });

  return (
    <div className={classes.root}>
      <Table
        rows={rows}
        tableStyle={classes.tableWrapper}
        columns={columns}
        hidePagination
        loading={false}
        error=""
        columnBuffer={6}
      />
    </div>
  );
};

TraitsMappingOutput.propTypes = {
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
  }).isRequired,
  inputData: PropTypes.shape({
    dataUseCase: PropTypes.shape({
      useCaseId: PropTypes.number,
      useCaseName: PropTypes.string.isRequired,
      useCaseDescription: PropTypes.string.isRequired,
    }),
    type: PropTypes.string.isRequired,
    ecosystems: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    marketingPrograms: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    traits: PropTypes.arrayOf(
      PropTypes.shape({
        traitId: PropTypes.number,
        traitName: PropTypes.string.isRequired,
      })
    ),
    trait: PropTypes.shape({
      traitId: PropTypes.number,
      traitName: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default TraitsMappingOutput;
