import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateMinimumCharactersCount,
  validateMinimumNonSpaceWords,
} from "../../../../utilities/formValidation";

const validateNewOptId = (optInfo) => {
  const errors = {
    optTextEnglishError: null,
    optTextLocalError: null,
    marketingProgramError: null,
  };
  if (isEmptyString(optInfo.ciamMarketingProgram)) {
    errors.marketingProgramError = i18n.t("errors.field_required");
  }
  // Required field checks
  if (isEmptyString(optInfo.optTextEnglish)) {
    errors.optTextEnglishError = i18n.t("errors.field_required");
  }
  if (isEmptyString(optInfo.optTextLocal)) {
    errors.optTextLocalError = i18n.t("errors.field_required");
  }
  // Individual field checks
  if (!isEmptyString(optInfo.optTextEnglish)) {
    if (
      !validateMinimumCharactersCount(
        optInfo.optTextEnglish.replace(/<[^>]*>/g, ""),
        5
      )
    ) {
      errors.optTextEnglishError = i18n.t(
        "new_opt_id.errors.opt_text_english.error_1"
      );
    } else if (
      !validateMinimumNonSpaceWords(
        optInfo.optTextEnglish.replace(/<[^>]*>/g),
        2
      )
    ) {
      errors.optTextEnglishError = i18n.t(
        "new_opt_id.errors.opt_text_english.error_2"
      );
    }
  }
  if (!isEmptyString(optInfo.optTextLocal)) {
    if (
      !validateMinimumCharactersCount(
        optInfo.optTextLocal.replace(/<[^>]*>/g, ""),
        5
      )
    ) {
      errors.optTextLocalError = i18n.t(
        "new_opt_id.errors.opt_text_local.error_1"
      );
    }
    // Disabled Temporarily due to Non English character validation issues
    // else if (!validateMinimumNonSpaceWords(optInfo.optTextLocal, 2)) {
    //   errors.optTextLocalError = i18n.t(
    //     "new_opt_id.errors.opt_text_local.error_2"
    //   );
    // }
  }
  return errors;
};

export default validateNewOptId;
