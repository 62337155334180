import applicationConfig from "../../../../config/applicationConfig";
import marketingProgramsModuleConfig from "../../../../config/marketingProgramsModuleConfig";

import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateMinimumCharactersCount,
} from "../../../../utilities/formValidation";

const validateEcosystemMapping = (ecosystem, isDisabled, countryCodes) => {
  const { mpEcosystemMappingConstants } = marketingProgramsModuleConfig;

  const { ecosystemNames } = mpEcosystemMappingConstants;

  const error = {
    ecosystemError: null,
    ecosystemMarketingProgramNameError: null,
    ecosystemMarketingProgramKeyError: null,
    ecosystemMarketingProgramInstanceError: null,
    segmentPersonaSpaceError: null,
    lyticsSecretError: null,
    enableAutomationError: null,
  };

  let { deploymentEnv } = applicationConfig;

  deploymentEnv = deploymentEnv ? deploymentEnv.toUpperCase() : "DEV";

  if (deploymentEnv === "LOCAL") {
    deploymentEnv = "DEV";
  }

  if (isDisabled) {
    return error;
  }

  if (isEmptyString(ecosystem.ecosystemId)) {
    error.ecosystemError = i18n.t("errors.field_required");
  }
  if (isEmptyString(ecosystem.ecosystemMarketingProgramName)) {
    error.ecosystemMarketingProgramNameError = i18n.t("errors.field_required");
  }

  // Required for Segment, Lytics, Mapp
  if (
    ecosystem.ecosystemName === ecosystemNames.SEGMENT ||
    ecosystem.ecosystemName === ecosystemNames.LYTICS ||
    ecosystem.ecosystemName === ecosystemNames.MAPP
  ) {
    if (isEmptyString(ecosystem.ecosystemMarketingProgramKey)) {
      error.ecosystemMarketingProgramKeyError = i18n.t("errors.field_required");
    }
  }

  // Required for Segment, Mapp, Janrain
  if (
    ecosystem.ecosystemName === ecosystemNames.SEGMENT ||
    ecosystem.ecosystemName === ecosystemNames.MAPP ||
    ecosystem.ecosystemName === ecosystemNames.JANRAIN
  ) {
    if (isEmptyString(ecosystem.ecosystemMarketingProgramInstance)) {
      error.ecosystemMarketingProgramInstanceError = i18n.t(
        "errors.field_required"
      );
    }
  }

  if (ecosystem.ecosystemName === ecosystemNames.SEGMENT) {
    if (
      ecosystem.automationEnabled &&
      isEmptyString(ecosystem.segmentPersonaSpace)
    ) {
      error.segmentPersonaSpaceError = i18n.t("errors.field_required");
    } else if (
      ecosystem.automationEnabled &&
      ecosystem.segmentPersonaSpace !== "Yes"
    ) {
      error.segmentPersonaSpaceError = i18n.t(
        "mp_ecosystem_mapping_container.errors.segment_persona_space"
      );
    }
  }

  if (ecosystem.ecosystemName === ecosystemNames.LYTICS) {
    if (ecosystem.automationEnabled && isEmptyString(ecosystem.lyticsSecret)) {
      error.lyticsSecretError = i18n.t("errors.field_required");
    } else if (
      ecosystem.automationEnabled &&
      ecosystem.lyticsSecret !== "Yes"
    ) {
      error.lyticsSecretError = i18n.t(
        "mp_ecosystem_mapping_container.errors.lytics_secret"
      );
    }
  }

  // Individual field checks
  if (!isEmptyString(ecosystem.ecosystemMarketingProgramName)) {
    if (ecosystem.ecosystemName === ecosystemNames.SEGMENT) {
      const splittedWords = ecosystem.ecosystemMarketingProgramName.split(/\s/);
      const countryCode = splittedWords[0];
      const name = splittedWords.slice(1).join(" ");
      if (
        !validateMinimumCharactersCount(countryCode, 3) ||
        !validateMinimumCharactersCount(name, 3)
      ) {
        error.ecosystemMarketingProgramNameError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_name.segment"
        );
      } else if (!countryCodes.includes(countryCode)) {
        error.ecosystemMarketingProgramNameError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_name.segment_iso_code",
          {
            countryCode,
          }
        );
      }
    } else if (ecosystem.ecosystemName === ecosystemNames.LYTICS) {
      const [pg, env, details] =
        ecosystem.ecosystemMarketingProgramName.split("-");
      let lyticsEnv = "QA";
      if (deploymentEnv === "PRD") {
        lyticsEnv = "PROD";
      }
      if (
        pg !== "PG " ||
        env !== ` ${lyticsEnv} ` ||
        !validateMinimumCharactersCount(details, 5)
      ) {
        error.ecosystemMarketingProgramNameError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_name.lytics",
          {
            deploymentEnv: lyticsEnv,
          }
        );
      }
    } else if (ecosystem.ecosystemName === ecosystemNames.MAPP) {
      if (
        !validateMinimumCharactersCount(
          ecosystem.ecosystemMarketingProgramName,
          5
        )
      ) {
        error.ecosystemMarketingProgramNameError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_name.mapp"
        );
      }
    } else if (ecosystem.ecosystemName === ecosystemNames.JANRAIN) {
      const [string1, string2, number] =
        ecosystem.ecosystemMarketingProgramName.split("_");
      const numberRegex = new RegExp("[0-9]");
      if (
        !validateMinimumCharactersCount(string1, 2) ||
        !validateMinimumCharactersCount(string2, 2) ||
        !numberRegex.test(number)
      ) {
        error.ecosystemMarketingProgramNameError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_name.janrain"
        );
      }
    }
  }

  if (!isEmptyString(ecosystem.ecosystemMarketingProgramKey)) {
    if (ecosystem.ecosystemName === ecosystemNames.SEGMENT) {
      const [spa, randomString] =
        ecosystem.ecosystemMarketingProgramKey.split("_");
      if (spa !== "spa" || !validateMinimumCharactersCount(randomString, 5)) {
        error.ecosystemMarketingProgramKeyError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_key.segment"
        );
      }
    } else if (ecosystem.ecosystemName === ecosystemNames.LYTICS) {
      const numberRegex = new RegExp("^[0-9]*$");
      const decimalRegex = /^[0-9]*\.[0-9]+$/;

      if (decimalRegex.test(ecosystem.ecosystemMarketingProgramKey)) {
        error.ecosystemMarketingProgramKeyError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_key.decimal_error"
        );
      } else if (
        !numberRegex.test(ecosystem.ecosystemMarketingProgramKey) ||
        ecosystem.ecosystemMarketingProgramKey.length < 4
      ) {
        error.ecosystemMarketingProgramKeyError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_key.lytics"
        );
      }
    } else if (ecosystem.ecosystemName === ecosystemNames.MAPP) {
      const numberRegex = new RegExp("^[0-9]*$");
      const decimalRegex = /^[0-9]*\.[0-9]+$/;
      if (decimalRegex.test(ecosystem.ecosystemMarketingProgramKey)) {
        error.ecosystemMarketingProgramKeyError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_key.decimal_error"
        );
      } else if (
        !numberRegex.test(ecosystem.ecosystemMarketingProgramKey) ||
        ecosystem.ecosystemMarketingProgramKey.length < 10
      ) {
        error.ecosystemMarketingProgramKeyError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_mp_key.mapp"
        );
      }
    }
  }

  if (!isEmptyString(ecosystem.ecosystemMarketingProgramInstance)) {
    if (ecosystem.ecosystemName === ecosystemNames.SEGMENT) {
      const ecosystemInstances =
        mpEcosystemMappingConstants.ecosystemInstances[ecosystemNames.SEGMENT][
          deploymentEnv
        ] || [];
      if (
        !ecosystemInstances.includes(
          ecosystem.ecosystemMarketingProgramInstance
        )
      ) {
        error.ecosystemMarketingProgramInstanceError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_instance.segment",
          {
            validInstances:
              mpEcosystemMappingConstants.ecosystemInstances[
                ecosystemNames.SEGMENT
              ][deploymentEnv].join(" , "),
          }
        );
      }
    }
    if (ecosystem.ecosystemName === ecosystemNames.MAPP) {
      const [pg, string1, string2] =
        ecosystem.ecosystemMarketingProgramInstance.split("_");
      if (
        pg !== "pg" ||
        !validateMinimumCharactersCount(string1, 2) ||
        !validateMinimumCharactersCount(string2, 2)
      ) {
        error.ecosystemMarketingProgramInstanceError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_instance.mapp"
        );
      }
    }
    if (ecosystem.ecosystemName === ecosystemNames.JANRAIN) {
      if (
        !mpEcosystemMappingConstants.ecosystemInstances[
          ecosystemNames.JANRAIN
        ].includes(ecosystem.ecosystemMarketingProgramInstance)
      ) {
        error.ecosystemMarketingProgramInstanceError = i18n.t(
          "mp_ecosystem_mapping_container.errors.ecosystem_instance.janrain",
          {
            validInstances:
              mpEcosystemMappingConstants.ecosystemInstances[
                ecosystemNames.JANRAIN
              ].join(" , "),
          }
        );
      }
    }
  }

  return error;
};

export default validateEcosystemMapping;
