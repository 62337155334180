function extractPlaceholders(templateHTML, versionHTML) {
  const parser = new DOMParser();
  const template = parser
    .parseFromString(templateHTML, "text/html")
    .querySelector("body").textContent;
  const version = parser
    .parseFromString(versionHTML, "text/html")
    .querySelector("body").textContent;
  const placeholders = {};
  const placeholderRegex = /\[([^\]]+)\]/g;

  const templateParts = template.split(placeholderRegex);

  let currentIndex = 0;

  for (let i = 0; i < templateParts.length; i += 1) {
    const part = templateParts[i];
    if (i % 2 === 1) {
      const placeholder = part;
      const nextPart = templateParts[i + 1] || "";
      const endIndex = nextPart
        ? version.indexOf(nextPart, currentIndex)
        : version.length;
      placeholders[placeholder] = version.slice(currentIndex, endIndex).trim();
      currentIndex = endIndex;
    } else {
      currentIndex += part.length;
    }
  }

  return placeholders;
}

export default extractPlaceholders;
