import { useCallback, useEffect, useState } from "react";

import { debounce } from "@material-ui/core";

import getMarketingPrograms from "../api/get-marketing-programs";
import applicationConfig from "../config/applicationConfig";
import handleError from "../utilities/handleError";

import useNotifier from "./useNotifier";

function useMarketingPrograms() {
  const { addNotification } = useNotifier();
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [initialMarketingProgramOptions, setInitialMarketingProgramOptions] =
    useState([]);
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(false);

  const getMarketingProgramsFromApi = useCallback(
    async (searchText) => {
      setMarketingProgramsLoading(true);
      let filter = { itemsPerPage: 3, page: 1 };
      if (searchText?.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      try {
        const rsp1 = await getMarketingPrograms(filter);
        setMarketingProgramOptions(rsp1.items);
        if (searchText.length === 0) {
          setInitialMarketingProgramOptions(rsp1.items);
        }
        return rsp1;
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
        return [];
      } finally {
        setMarketingProgramsLoading(false);
      }
    },
    [addNotification]
  );

  const memoizedMarketingProgramsFromApi = useCallback(
    debounce(getMarketingProgramsFromApi, applicationConfig.waitTime),
    []
  );

  useEffect(() => {
    return () => {
      memoizedMarketingProgramsFromApi.clear();
    };
  }, [memoizedMarketingProgramsFromApi]);

  return {
    getMarketingProgramsFromApi: memoizedMarketingProgramsFromApi,
    marketingProgramOptions,
    initialMarketingProgramOptions,
    marketingProgramsLoading,
    setInitialMarketingProgramOptions,
    setMarketingProgramsLoading,
    setMarketingProgramOptions,
  };
}

export default useMarketingPrograms;
