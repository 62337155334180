import { useState, useCallback } from "react";

import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getServiceNames from "../../../../api/get-service-names";
import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";

import debounce from "../../../../utilities/debounce";

import validateNewServiceName from "../../NewOptsMappingContainer/helpers/validateNewServiceName";

import useStyles from "./styles";

const NewServiceName = ({
  isOpen,
  newServiceName,
  setNewServiceName,
  isDisabled,
  marketingProgramNumber,
  setNewServiceNameModal,
  setServiceName,
  currentStep,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const errors = validateNewServiceName(
    newServiceName,
    newServiceName.marketingProgramName,
    isDisabled
  );

  const [existingServiceNameError, setExistingServiceNameError] = useState("");

  const [loading, setLoading] = useState(false);

  const checkServiceNameDisabled = () => {
    const serviceNameObject = JSON.parse(JSON.stringify(newServiceName));
    delete serviceNameObject.serviceNameDescription;
    if (
      Object.keys(serviceNameObject).length > 0 &&
      Object.values(serviceNameObject).every((val) => val && val.length > 0)
    ) {
      if (!Object.values(errors).every((error) => error === null)) {
        return true;
      }
      if (
        existingServiceNameError !== t("opts_mapping.errors.service_name_valid")
      ) {
        if (existingServiceNameError.length === 0) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  };

  const searchForServiceName = useCallback(async (searchText) => {
    try {
      if (searchText.length > 0) {
        setLoading(true);
        setExistingServiceNameError(t("opts_mapping.validating_service_name"));
        await getServiceNames(marketingProgramNumber, searchText);
        setExistingServiceNameError(
          t("opts_mapping.errors.service_name_already_present")
        );
      }
    } catch (error) {
      setExistingServiceNameError(t("opts_mapping.errors.service_name_valid"));
    } finally {
      setLoading(false);
    }
  }, []);

  // Debounce & Memoize
  const debouncedSearchForServiceNameFromApi = debounce(
    searchForServiceName,
    applicationConfig.waitTime
  );

  const memoizedSearchForServiceNamesFromApi = useCallback((val) => {
    debouncedSearchForServiceNameFromApi(val);
  }, []);

  const getState = () => {
    if (errors.serviceNameError) {
      return applicationConfig.status.error;
    }
    if (
      existingServiceNameError === t("opts_mapping.validating_service_name")
    ) {
      return applicationConfig.status.info;
    }
    if (
      existingServiceNameError ===
      t("opts_mapping.errors.service_name_already_present")
    ) {
      return applicationConfig.status.error;
    }
    return applicationConfig.status.success;
  };

  const serviceNameFields = [
    {
      label: t("common.labels.marketing_program"),
      type: "text-input",
      flex: 2,
      props: {
        value: newServiceName.marketingProgramName,
        helperText: (
          <InlineMessage message={errors.marketingProgramNameError} />
        ),
        disabled: true,
        inputProps: {
          "data-testid": "marketing-program-name",
        },
      },
    },
    {
      label: t("common.labels.service_name"),
      type: "text-input",
      flex: 2,
      props: {
        onChange: async (event) => {
          setNewServiceName((draft) => {
            draft.serviceName = event.target.value;
          });
          setExistingServiceNameError("");
          await memoizedSearchForServiceNamesFromApi(event.target.value);
        },
        error:
          Boolean(errors.serviceNameError) ||
          existingServiceNameError ===
            t("opts_mapping.errors.service_name_already_present"),
        variant: "outlined",
        value: newServiceName.serviceName,
        helperText: (
          <InlineMessage
            message={errors.serviceNameError || existingServiceNameError}
            state={getState()}
          />
        ),
        disabled: isDisabled,
        inputProps: {
          "data-testid": "service-name-input",
        },
      },
    },
    {
      label: t("opts_mapping.new_service_name_modal.service_name_description"),
      type: "text-input",
      flex: 2,
      props: {
        onChange: (event) =>
          setNewServiceName((draft) => {
            draft.serviceNameDescription = event.target.value;
          }),
        error: Boolean(errors.serviceNameDescriptionError),
        variant: "outlined",
        value: newServiceName.serviceNameDescription,
        helperText: (
          <InlineMessage message={errors.serviceNameDescriptionError} />
        ),
        disabled: isDisabled,
        inputProps: {
          "data-testid": "service-name-description-input",
        },
      },
    },
  ];

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <DialogContent>
        <div>
          <div className={clsx(classes.flexContainer, classes.header)}>
            <Typography variant="h5">
              {!isDisabled
                ? t("opts_mapping.new_service_name")
                : t("opts_mapping.view_service_name")}
            </Typography>
            <div
              className={classes.closeBtn}
              onClick={() => {
                setNewServiceName((draft) => {
                  draft.marketingProgramName =
                    newServiceName.marketingProgramName;
                  draft.serviceName = newServiceName.marketingProgramName;
                  draft.serviceNameDescription = "";
                });
                setNewServiceNameModal(false);
              }}
              onKeyDown={() => null}
              role="button"
              tabIndex={0}
              data-testid="close-btn"
            >
              <Close />
            </div>
          </div>
          <div className={classes.horizontalBar} />

          <Form
            fields={serviceNameFields}
            fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {currentStep === 1 && !isDisabled && (
          <Button
            onClick={() => {
              setServiceName({
                title: newServiceName.marketingProgramName,
                marketingProgramName: newServiceName.marketingProgramName,
                serviceNameDescription:
                  newServiceName.serviceNameDescription.trim(),
                serviceName: newServiceName.serviceName.trim(),
                isNewService: true,
              });
              setNewServiceNameModal(false);
            }}
            color="primary"
            variant="contained"
            disabled={checkServiceNameDisabled()}
          >
            {loading && (
              <Box
                sx={{
                  mr: 1,
                  mt: 0.5,
                }}
              >
                <CircularProgress size={20} color="white" />
              </Box>
            )}
            {t("common.submit")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

NewServiceName.defaultProps = {
  currentStep: 1,
  isDisabled: false,
  setNewServiceNameModal: () => {},
  setServiceName: () => {},
  focused: {},
  setNewServiceName: () => {},
  marketingProgramNumber: null,
};

NewServiceName.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  newServiceName: PropTypes.shape({
    marketingProgramName: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    serviceNameDescription: PropTypes.string.isRequired,
  }).isRequired,
  focused: PropTypes.shape({
    marketingProgramName: PropTypes.bool,
    serviceName: PropTypes.bool,
    serviceNameDescription: PropTypes.bool,
  }),
  setNewServiceName: PropTypes.func,
  isDisabled: PropTypes.bool,
  setNewServiceNameModal: PropTypes.func,
  setServiceName: PropTypes.func,
  currentStep: PropTypes.number,
  marketingProgramNumber: PropTypes.number,
};

export default NewServiceName;
