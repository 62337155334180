import { useEffect, useState } from "react";

import { Box, Button } from "@material-ui/core";
import {
  AddCircleOutline,
  Edit,
  Delete,
  Save,
  Close,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "../../../../../components/ConfirmationModal";
import CustomModal from "../../../../../components/CustomModal";

function EditToolbar(props) {
  const { rows, viewOnly, setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = rows?.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        isNew: true,
        predefinedTraitResponseAlias: "",
        predefinedTraitResponseAliasLocalLanguage: "",
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: "edit", fieldToFocus: "predefinedTraitResponseAlias" },
    }));
  };

  if (viewOnly) {
    return <></>;
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" onClick={handleClick}>
        <AddCircleOutline />
        Add Alias
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  rows: PropTypes.arrayOf().isRequired,
  setRows: PropTypes.func.isRequired,
  setRowModesModel: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

const AliasesModal = ({
  traitResponses,
  openManageAliasesModal,
  setOpenManageAliasesModal,
  isGlobal,
  handleAliasSubmit,
  viewOnly,
}) => {
  const [rows, setRows] = useState(() => traitResponses);
  const [rowModesModel, setRowModesModel] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (traitResponses.length > 0) {
      setRows(traitResponses);
      const obj = {};
      traitResponses.forEach((response) => {
        obj[response.id] = { mode: "view" };
      });
      setRowModesModel(obj);
    } else {
      setRows([
        {
          id: 0,
          isNew: true,
          predefinedTraitResponseAlias: "",
          predefinedTraitResponseAliasLocalLanguage: "",
        },
      ]);
      setRowModesModel({
        0: { mode: "edit" },
      });
    }
  }, [traitResponses]);

  useEffect(() => {
    setErrorMessage("");
  }, [rows]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === "rowFocusOut") {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "edit" } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "view" } });
  };

  const handleDeleteClick = (id) => () => {
    setShowConfirmation(true);
    setSelectedId(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "predefinedTraitResponseAlias",
      sortable: false,
      editable: true,
      headerName: "Trait Response Alias",
      flex: 1,
    },
  ];

  if (!isGlobal) {
    columns.push({
      field: "predefinedTraitResponseAliasLocalLanguage",
      sortable: false,
      editable: true,
      headerName: "Trait Response Alias (Local Language)",
      flex: 1,
    });
  }

  if (!viewOnly) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === "edit";

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Close />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    });
  }

  return (
    <CustomModal
      open={openManageAliasesModal}
      showCloseIcon
      onClose={() => {
        setOpenManageAliasesModal(false);
      }}
      title={
        viewOnly
          ? "View Trait Response Aliases"
          : "Manage Trait Response Aliases"
      }
    >
      <Box
        sx={{
          height: 500,
          width: "100%",
          margin: "0 auto",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          "& .MuiDataGrid-cell--editing": {
            border: "1px solid #1976D2",
            borderColor: "#1976D2 !important",
          },
          "& .MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: "#FEFFFF",
          },
          "& .MuiDataGrid-row:nth-child(even)": {
            backgroundColor: "#FAFAFA",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          disableColumnMenu
          isCellEditable={() => !viewOnly}
          hideFooter
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              rows,
              setRows,
              rowModesModel,
              setRowModesModel,
              viewOnly,
            },
          }}
        />
      </Box>
      {errorMessage && (
        <Alert severity="error" variant="filled">
          {errorMessage}
        </Alert>
      )}
      {!viewOnly && (
        <Box sx={{ marginLeft: "auto", marginTop: "20px", width: 100 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const nonSavedRows = Object.values(rowModesModel).filter(
                (row) => row.mode === "edit"
              );
              if (nonSavedRows.length !== 0) {
                setErrorMessage("Please Save All The Rows");
                return;
              }
              const emptyRows = rows.filter((row) => {
                return isGlobal
                  ? row.predefinedTraitResponseAlias.trim().length === 0
                  : row.predefinedTraitResponseAlias.trim().length === 0 ||
                      row.predefinedTraitResponseAliasLocalLanguage.trim()
                        .length === 0;
              });
              if (emptyRows.length !== 0) {
                setErrorMessage("Please fill in all the fields in the table.");
                return;
              }
              handleAliasSubmit(rows);
            }}
          >
            {t("use_events_container.submit")}
          </Button>
        </Box>
      )}
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title={t("dialogs.confirm_remove")}
        message={t("manage_traits_responses.alias_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          setRows(rows.filter((row) => row.id !== selectedId));
          setShowConfirmation(false);
        }}
        type="error"
      />
    </CustomModal>
  );
};

export default AliasesModal;

AliasesModal.defaultProps = {
  viewOnly: false,
  isGlobal: false,
};

AliasesModal.propTypes = {
  traitResponses: PropTypes.shape([]).isRequired,
  openManageAliasesModal: PropTypes.bool.isRequired,
  setOpenManageAliasesModal: PropTypes.bool.isRequired,
  handleAliasSubmit: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  isGlobal: PropTypes.bool,
};
