import api from "../utilities/api";
import { getValue } from "../utilities/cache";

const getTrait = async (id) => {
  const refreshCache = getValue("traits");
  let url = `traits/${id}`;
  url += `?schemaVersion=v2&refreshCache=${refreshCache}`;
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getTrait;
