import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      ".MuiAutocomplete-popper .traits-responses": {
        padding: "10px 0",
      },
      ".MuiAutocomplete-popper .traits-responses .MuiCheckbox-root.MuiCheckbox-colorSecondary.MuiIconButton-colorSecondary":
        {
          display: "none",
        },
    },
    customAccordion: {
      "& .MuiAccordionDetails-root tr td:first-child": {
        width: "50%",
      },
    },
    alert: {
      width: "fit-content",
    },
    eventInputWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    tableWrapper: {
      maxHeight: "calc(100vh - 300px)",
      overflowY: "hidden",
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100%",
    },
    marginTop20: {
      marginTop: "10px",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
        marginBottom: "24px",
      },
    },
    autoCompleteBox: {
      width: "100%",
    },
    lastStepFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "& .MuiButtonBase-root.MuiButton-root": {
        backgroundColor: theme.palette.blue.dark,
        color: theme.palette.static.white,
        marginLeft: "unset",
      },
      "& .MuiButtonBase-root.MuiButton-root.MuiButton-outlinedPrimary": {
        color: `${theme.palette.blue.main} !important`,
        border: `1px solid ${theme.palette.blue.main}`,
        backgroundColor: "unset",
      },
    },
    autoCompleteEditContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.blue.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    newGlobalResponse: {},
    newGlobalResponseInputWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&:first-child": {
        marginBottom: "14px",
      },
    },
    autoCompleteDeleteContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.error.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    accordion: {
      border: `1px solid ${theme.colorTheme.greyishWhite}`,
      borderRadius: "4px",
      marginBottom: 16,
      width: "100%",
    },
    accordionHeader: {
      padding: "0 16px",
      "& .MuiAccordionSummary-content": {
        display: "flex",
        alignItems: "center",
      },
    },
    accordionContent: {
      padding: 16,
      paddingBottom: 0,
      borderTop: `1px solid ${theme.colorTheme.greyishWhite}`,
      background: theme.colorTheme.white1,
      margin: "0 !important",
      display: "flex",
      flexDirection: "column",
    },
    popper: {
      "& .MuiCheckbox-root": {
        color: theme.palette.primary.light,
      },
    },
    badge: {
      backgroundColor: theme.palette.blue.dark,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "20px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.static.white,
    },
    useCaseContainer: {
      padding: "40px",
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
    paddingZero: {
      padding: "0px",
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    noOptionsContainer: {
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    removeModalContainer: {
      "& .MuiDialogContent-root": {
        paddingTop: "32px !important",
      },
      "& .MuiDialogActions-root": {
        marginBottom: "24px",
        marginTop: "32px",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.modalTitle,
        fontWeight: "normal",
        marginTop: "10px",
      },
      "& .MuiButton-containedSecondary": {
        margin: "0px 20px",
        backgroundColor: theme.palette.error.main,
      },
    },
    inputContainer: {
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: `${theme.colorTheme.grey}`,
        fontWeight: 400,
        lineHeight: "22px",
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    backBtn: {
      "& a": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: "transparent !important",
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
      "& button": {
        color: `${theme.palette.primary.main} !important`,
        backgroundColor: "transparent !important",
        border: `1px solid ${theme.palette.primary.light1} !important`,
        minWidth: "94px !important",
      },
    },
    marketingProgramContainer: {
      "& > div": {
        marginBottom: "30px",
      },
      "& > div:nth-child(3)": {
        overflow: "unset",
        maxHeight: "unset",
      },
      "& .MuiTypography-h4": {
        fontSize: "16px",
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
      "& .MuiPaper-elevation1": {
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      },
      "& .nandu": {
        display: "none",
      },
      "& .MuiButtonBase-root.MuiButton-containedPrimary": {
        backgroundColor: theme.palette.blue.dark,
      },
    },
    confirmationContainer: {
      "& > div": {
        marginTop: "10px",
      },
      "& .MuiTypography-h4": {
        fontSize: "16px",
        fontWeight: "500",
        margin: "16px 0",
        color: theme.colorTheme.grey,
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
      "& .MuiPaper-elevation1": {
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      },
    },
    newBtnContainer: {
      display: "inline-block",
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.primary.light2,
        color: theme.colorTheme.grey,
        margin: "0px 10px",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& a,& a:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.colorTheme.white,
        border: "none",
        marginLeft: "auto",
      },
      "& > button.MuiButtonBase-root:nth-child(2)": {
        backgroundColor: theme.palette.blue.dark,
        color: theme.palette.static.white,
      },
    },
    trait: {
      "& > div:first-child": {
        flex: "0 0 90%",
      },
      "& > div:last-child": {
        flex: "0 0 5%",
      },
    },
    traitTitle: {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      minWidth: 88,
      padding: "10px 16px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
    },
    traitDescription: {
      marginLeft: 16,
    },
    statusLabel: {
      marginLeft: "auto",
    },
    deleted: {
      backgroundColor: theme.palette.error.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    addTraitBtn: {
      "& .MuiButton-label": {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
    },
    grid: {
      display: "grid",
      gridGap: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
      "& .MuiAutocomplete-root": {
        width: "auto !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    infoIcon: {
      width: 16,
      height: 16,
      marginLeft: "10px",
      cursor: "pointer",
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    statusPending: {
      backgroundColor: theme.palette.warning.light,
    },
    statusApproved: {
      backgroundColor: theme.palette.green.main,
    },
    statusRejected: {
      backgroundColor: theme.palette.error.main,
    },
    fileIconWrapper: {
      marginLeft: "10px",
    },
    traitName: {
      cursor: "pointer",
    },
    traitId: {
      cursor: "pointer",
    },
    loadingSkeleton: {
      width: "807px !important",
      padding: "30px",
    },
    eventPropertyModalFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "30px",
      "& .MuiButtonBase-root:not(:disabled)": {
        backgroundColor: theme.palette.blue.dark,
        color: theme.palette.static.white,
      },
    },
    eventPropertyModalField: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
      "& > p.MuiTypography-root.MuiTypography-body1": {
        maxWidth: "35%",
      },
    },
  };
});

export default useStyles;
