import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    autocompleteWrapper: {
      "& .MuiChip-root.MuiAutocomplete-tag.Mui-disabled.MuiChip-deletable": {
        opacity: "unset",
      },
    },
    popper: {
      "& .MuiCheckbox-root": {
        color: `${theme.palette.blue.main}`,
      },
    },
    fullWidth: {
      width: "100%",
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    flexJustify: {
      justifyContent: "space-between",
    },
    errorText: {
      flex: "0 0 60%",
      color: `${theme.palette.error.main}`,
      opacity: "1 !important",
      "& .MuiTypography-root": {
        fontSize: 12,
      },
    },
    textFieldContainer: {
      "& .MuiFormHelperText-root.Mui-error": {
        marginLeft: "0px",
      },
    },
  };
});

export default useStyles;
