import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import {
  Button,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  CircularProgress,
  Chip,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createUseCase from "../../../api/create-use-case";
import getEventProperties from "../../../api/get-event-properties";
import getReferenceData from "../../../api/get-reference-data";
import getUseCaseDetails from "../../../api/get-use-case-details";
import getUseCases from "../../../api/get-use-cases";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Form from "../../../components/Form";

import InlineMessage from "../../../components/InlineMessage";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import useCaseManagementModuleConfig from "../../../config/useCaseManagementModuleConfig";

import useEvents from "../../../hooks/useEvents";
import useLegalEntities from "../../../hooks/useLegalEntities";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useMarketingPrograms from "../../../hooks/useMarketingPrograms";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import mergeArrays from "../../../utilities/mergeArrays";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";
import EventPropertyModal from "../../Events/components/EventPropertyModal";
import EditableText from "../../MarketingPrograms/NewMarketingProgramsContainer/components/EditableText";

import EventPropertiesAccordion from "./components/EventPropertiesAccordion";
import NewTraitModal from "./components/NewTraitModal";
import UseCaseRequestOutputModal from "./components/UseCaseRequestOutputModal";
import createBackendPayload from "./helpers/createBackendPayload";
import validateUseCaseInfo from "./helpers/validateUseCaseInfo";
import useStyles from "./styles";

const NewUseCaseContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const { regionFilters } = applicationConfig;
  const { user } = useUserProfile();
  const { createUseCaseConstants, requestStatus } =
    useCaseManagementModuleConfig;
  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();

  const history = useHistory();
  const location = useLocation();
  const [module, setModule] = useState(() => {
    if (
      location.state?.from === applicationConfig.modules.events_use_case ||
      request.module === applicationConfig.modules.events_use_case
    ) {
      return "events";
    }
    if (
      location.state?.from === applicationConfig.modules.traits_use_case ||
      request.module === applicationConfig.modules.traits_use_case
    ) {
      return "traits";
    }
    return "";
  });

  const isRequestRevisionFlow = Object.keys(request).length !== 0;
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const isTraitsFlow = module === "traits";
  const isEventsFlow = module === "events";
  let useCaseId = null;

  if (isRequestRevisionFlow && request) {
    const { dataUseCase } = request.requestDetails;
    if (dataUseCase.useCaseId) {
      useCaseId = dataUseCase.useCaseId;
    }
  } else if (location.state && location.state?.useCaseId) {
    useCaseId = parseInt(location.state?.useCaseId, 10);
  }

  const isManageUseCaseFlow = !!useCaseId;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const isUserAuthorized = checkUserAuthorization(user.access, [
    ...pageAccessConfig.createTraits,
    ...pageAccessConfig.useEvents,
  ]);

  const totalSteps = 3;

  const [currentStep, setCurrentStep] = useState(1);
  const [initialStep, setInitialStep] = useState(1);

  const { t } = useTranslation();

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const [initialRegionalOptions, setInitialRegionalOptions] = useState([]);
  const [validatingUseCaseName, setValidatingUseCaseName] = useState(false);

  const initialEventNameOptionsFilter = useCallback(
    () => !isTraitsFlow && !isRequestRevisionFlow && !isManageUseCaseFlow,
    [isTraitsFlow]
  );

  const {
    memoizedEventsFromApi,
    eventNameOptions,
    eventNamesLoading,
    initialEventNameOptions,
    setInitialEventNameOptions,
    getEventsFromApi,
    setEventNamesLoading,
  } = useEvents({
    initialEventNameOptionsFilter,
  });

  const {
    memoizedLegalEntitiesFromApi,
    allLegalEntitiesOptions,
    legalEntities,
    legalEntitiesLoading,
    initialLegalEntitiesOptions,
    setLegalEntities,
    getLegalEntitiesFromApi,
    setInitialLegalEntitiesOptions,
  } = useLegalEntities();

  const {
    getMarketingProgramsFromApi,
    initialMarketingProgramOptions,
    marketingProgramOptions,
    marketingProgramsLoading,
    setInitialMarketingProgramOptions,
    setMarketingProgramOptions,
    setMarketingProgramsLoading,
  } = useMarketingPrograms();

  const [useCaseInfo, setUseCaseInfo] = useImmer({
    useCaseId: "",
    useCaseName: "",
    useCaseDescription: "",
    selectedSubMenuItem: {},
    eventName: {},
    scope: "",
  });

  const [isExistingUseCaseError, setIsExistingUseCaseError] = useState("");
  const [scope, setScope] = useState(
    request.requestDetails?.scope.type || "global"
  );

  const [existingUseCaseInfo, setExistingUseCaseInfo] = useState({
    mappedTraits: [],
    mappedEventProperties: [],
  });

  const [eventValue, setEventValue] = useState("");

  const [useCaseResponse, setUseCaseResponse] = useState({});
  const [useCaseOutput, setUseCaseOutput] = useState({});

  const [traits, setTraits] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelsLoading, setLabelsLoading] = useState(false);
  const [newLabelModal, setNewLabelModal] = useState(false);

  const [eventProperties, setEventProperties] = useState([]);
  const [eventPropertiesOptions, setEventPropertiesOptions] = useState([]);

  const [traitSelectedIndex, setTraitSelectedIndex] = useState(-1);
  const defaultErrors = {
    useCaseNameError: null,
    useCaseDescriptionError: null,
    scopeIdentifier: null,
    traits: [],
  };

  const [errors, setErrors] = useState(defaultErrors);

  const [subMenuValue, setSubMenuValue] = useState("");
  const [eventPropertyModal, setEventPropertyModal] = useState(false);
  const [traitModal, setTraitModal] = useState(false);
  const [eventSelectedIndex, setEventSelectedIndex] = useState(-1);

  const [openEventPropertyDeleteModal, setOpenEventPropertyDeleteModal] =
    useState(false);

  const changedItems = useRef([]);

  const onlyOnce = useRef(true);

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const isFormDisabled = false;

  const isSubMenuItemSelected =
    !isEmpty(useCaseInfo.selectedSubMenuItem) || scope === "global";

  const scopeMapping = {
    global: "Global",
    regional: "Region",
    legalEntity: "Legal Entity",
    marketingProgram: "Marketing Program",
  };

  const checkDisabledStatus = (step) => {
    if (step === 3) {
      if (isManageUseCaseFlow) {
        if (isEmpty(changedItems.current)) {
          return true;
        }
      }
    }
    return false;
  };

  const filterByEventName = (data, name) => {
    return data?.filter((property) => property.eventName === name);
  };

  const validateMappings = () => {
    if (scope !== "global" && isEmpty(useCaseInfo.selectedSubMenuItem)) {
      return {
        scopeIdentifier: "Please Select this field",
      };
    }

    if (isTraitsFlow && isEmpty(traits) && !isManageUseCaseFlow) {
      return {
        traitsError: "Please add some traits to proceed",
      };
    }

    if (isEventsFlow && isEmpty(eventProperties) && !isManageUseCaseFlow) {
      return {
        traitsError: "Please add some event properties to proceed",
      };
    }

    if (isManageUseCaseFlow && changedItems.current.length === 0) {
      return {
        traitsError: t("create_use_case.no_traits_changes"),
      };
    }

    if (traits.filter((trait) => trait.title).length !== traits.length) {
      return {
        traits: traits.map((trait) => {
          if (!trait.title) {
            return {
              error: t("traits_container.errors.select_trait"),
            };
          }
          return {
            error: "",
          };
        }),
      };
    }
    return {};
  };

  const isInfoStep = currentStep > totalSteps;

  useEffect(() => {
    if (
      !isEmpty(useCaseInfo.selectedSubMenuItem) &&
      isTraitsFlow &&
      !isManageUseCaseFlow
    ) {
      const index = existingUseCaseInfo.mappedTraits.findIndex(
        (trait) =>
          trait.title === useCaseInfo.selectedSubMenuItem?.scopeIdentifier
      );
      if (index !== -1) {
        setTraits(existingUseCaseInfo.mappedTraits[index].traits);
      }
    }
  }, [
    existingUseCaseInfo.mappedTraits,
    isTraitsFlow,
    useCaseInfo.selectedSubMenuItem,
  ]);

  const mappedIdentifier = (updatedUseCaseScope) => {
    if (updatedUseCaseScope.type === "regional") {
      const item = {
        title: `${updatedUseCaseScope.geoRegionCode} - ${updatedUseCaseScope.geoRegionName}`,
        geoRegionName: updatedUseCaseScope.geoRegionName,
        geoRegionDescription: updatedUseCaseScope.geoRegionDescription,
        geoRegionCode: updatedUseCaseScope.geoRegionCode,
        id: updatedUseCaseScope.geoRegionCode,
      };
      return {
        attribute: "regionCode",
        subMenuItem: item,
      };
    }

    if (updatedUseCaseScope.type === "legalEntity") {
      const item = {
        title: `${updatedUseCaseScope.legalEntityId} - ${updatedUseCaseScope.legalEntityName}`,
        legalEntityId: updatedUseCaseScope.legalEntityId,
        legalEntityName: updatedUseCaseScope.legalEntityName,
        id: updatedUseCaseScope.legalEntityId,
      };

      return {
        attribute: "legalEntityId",
        subMenuItem: item,
      };
    }

    if (updatedUseCaseScope.type === "marketingProgram") {
      const item = {
        title: `${updatedUseCaseScope.marketingProgramNumber} - ${updatedUseCaseScope.marketingProgramDescription}`,
        marketingProgramNumber: updatedUseCaseScope.marketingProgramNumber,
        marketingProgramDescription:
          updatedUseCaseScope.marketingProgramDescription,
        id: updatedUseCaseScope.marketingProgramNumber,
      };
      return {
        attribute: "marketingProgramNumber",
        subMenuItem: item,
      };
    }

    return {
      attribute: "",
      subMenuItem: {},
    };
  };

  const setUseCaseData = (
    existingUseCase,
    updatedUseCase,
    selectedSubMenuItem
  ) => {
    let existingTraits = [];
    let existingEventProperties = [];
    const initialSubOptions = [];
    let subMenuItem = {};
    const { clonedTraits = [], clonedEventProperties = [] } = location.state;
    if (
      (updatedUseCase && updatedUseCase.existingMarketingProgram) ||
      selectedSubMenuItem.scopeIdentifier
    ) {
      setScope(existingUseCase.scope);
    }

    if (isTraitsFlow) {
      let localTraits = [];
      if (existingUseCase.mappedTraits.length > 0) {
        if (existingUseCase.scope === "marketingProgram") {
          existingTraits = existingUseCase.mappedTraits.map((trait, index) => {
            const item = {
              title: `${trait.marketingProgramNumber} - ${trait.marketingProgramDescription}`,
              marketingProgramNumber: trait.marketingProgramNumber,
              marketingProgramDescription: trait.marketingProgramDescription,
              id: trait.marketingProgramNumber,
            };

            if (index === 0) {
              subMenuItem = { ...item };
              localTraits = trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }));
            }

            if (
              selectedSubMenuItem.scopeIdentifier &&
              item.title === selectedSubMenuItem.scopeIdentifier
            ) {
              subMenuItem = { ...item };
              localTraits = trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }));
            }

            initialSubOptions.push(item);

            return {
              ...item,
              traits: trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              })),
            };
          });

          if (clonedTraits.length > 0) {
            localTraits = mergeArrays(localTraits, clonedTraits, "traitId");
            subMenuItem = selectedSubMenuItem;
          }
          setInitialMarketingProgramOptions(initialSubOptions);
        }

        if (existingUseCase.scope === "regional") {
          existingTraits = existingUseCase.mappedTraits.map((trait, index) => {
            const item = {
              title: `${trait.geoRegionCode} - ${trait.geoRegionName}`,
              geoRegionName: trait.geoRegionName,
              geoRegionDescription: trait.geoRegionDescription,
              geoRegionCode: trait.geoRegionCode,
              id: trait.geoRegionCode,
            };

            if (index === 0) {
              subMenuItem = { ...item };
              localTraits = trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }));
            }

            if (
              selectedSubMenuItem.scopeIdentifier &&
              item.title === selectedSubMenuItem.scopeIdentifier
            ) {
              subMenuItem = { ...item };
              localTraits = trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }));
            }

            initialSubOptions.push(item);

            return {
              ...item,
              traits: trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              })),
            };
          });
          if (clonedTraits.length > 0) {
            localTraits = mergeArrays(localTraits, clonedTraits, "traitId");
            subMenuItem = selectedSubMenuItem;
          }
          setInitialRegionalOptions(initialSubOptions);
        }

        if (existingUseCase.scope === "legalEntity") {
          existingTraits = existingUseCase.mappedTraits.map((trait, index) => {
            const item = {
              title: `${trait.legalEntityId} - ${trait.legalEntityName}`,
              legalEntityId: trait.legalEntityId,
              legalEntityDescription: trait.legalEntityDescription,
              legalEntityName: trait.legalEntityName,
              id: trait.legalEntityId,
            };

            if (index === 0) {
              subMenuItem = { ...item };
              localTraits = trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }));
            }

            if (
              selectedSubMenuItem.scopeIdentifier &&
              item.title === selectedSubMenuItem.scopeIdentifier
            ) {
              subMenuItem = { ...item };
              localTraits = trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }));
            }

            initialSubOptions.push(item);

            return {
              ...item,
              traits: trait.traits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              })),
            };
          });

          if (clonedTraits.length > 0) {
            localTraits = mergeArrays(localTraits, clonedTraits, "traitId");
            subMenuItem = selectedSubMenuItem;
          }
          setInitialLegalEntitiesOptions(initialSubOptions);
        }

        if (existingUseCase.scope === "global") {
          existingTraits = existingUseCase.mappedTraits.map((trait) => ({
            title: "Global",
            traits: trait.traits.map((x) => ({
              ...x,
              title: x.traitName,
              isSelected: true,
            })),
          }));
          if (existingUseCase.mappedTraits[0]) {
            localTraits = existingUseCase.mappedTraits[0].traits.map((x) => ({
              ...x,
              title: x.traitName,
              isSelected: true,
            }));
          }
          if (clonedTraits.length > 0) {
            localTraits = mergeArrays(localTraits, clonedTraits, "traitId");
            subMenuItem = selectedSubMenuItem;
          }
        }
      } else {
        localTraits = clonedTraits;
        existingTraits = clonedTraits;
        subMenuItem = selectedSubMenuItem;
      }
      setExistingUseCaseInfo({
        ...existingUseCase,
        mappedTraits: existingTraits,
      });

      if (isRequestRevisionFlow) {
        const { scope: updatedUseCaseScope, traits: updatedTraits } =
          updatedUseCase;

        localTraits = existingUseCase.mappedTraits
          .find((mt) => {
            if (updatedUseCaseScope.type === "global") {
              return true;
            }
            return (
              mt[mappedIdentifier(updatedUseCaseScope).attribute] ===
              updatedUseCaseScope[
                mappedIdentifier(updatedUseCaseScope).attribute
              ]
            );
          })
          ?.traits.map((x) => {
            const updatedTrait = updatedTraits.find(
              (ut) => ut.traitId === x.traitId
            );
            if (updatedTrait) {
              return updatedTrait;
            }

            return { ...x, isSelected: true, title: x.traitName };
          });

        subMenuItem = {
          ...mappedIdentifier(updatedUseCaseScope).subMenuItem,
        };
      }

      localTraits = localTraits
        ?.filter((x) => !x.deleted)
        .map((x) => ({
          ...x,
          labels: (x.labels || []).map((y) => ({ ...y, title: y.labelName })),
          selectedLabels: (x.labels || []).map((y) => ({
            ...y,
            title: y.labelName,
          })),
        }));
      setTraits(localTraits);
    } else if (isEventsFlow) {
      let localEventProperties = [];
      if (
        existingUseCase &&
        existingUseCase.mappedEventProperties?.length > 0
      ) {
        if (existingUseCase.scope === "marketingProgram") {
          existingEventProperties = existingUseCase.mappedEventProperties.map(
            (property, index) => {
              const item = {
                title: `${property.marketingProgramNumber} - ${property.marketingProgramDescription}`,
                marketingProgramNumber: property.marketingProgramNumber,
                marketingProgramDescription:
                  property.marketingProgramDescription,
              };

              if (index === 0) {
                subMenuItem = { ...item };
                localEventProperties = property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                }));
              }

              if (
                selectedSubMenuItem.scopeIdentifier &&
                item.title === selectedSubMenuItem.scopeIdentifier
              ) {
                subMenuItem = { ...item };
                localEventProperties = property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                }));
              }

              initialSubOptions.push(item);

              return {
                ...item,
                properties: property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                })),
              };
            }
          );
          if (clonedEventProperties.length > 0) {
            localEventProperties = mergeArrays(
              localEventProperties,
              clonedEventProperties,
              "eventProperty"
            );
            subMenuItem = selectedSubMenuItem;
          }
          setInitialMarketingProgramOptions(initialSubOptions);
        }

        if (existingUseCase.scope === "regional") {
          existingEventProperties = existingUseCase.mappedEventProperties.map(
            (property, index) => {
              const item = {
                title: `${property.geoRegionCode} - ${property.geoRegionName}`,
                geoRegionName: property.geoRegionName,
                geoRegionDescription: property.geoRegionDescription,
                geoRegionCode: property.geoRegionCode,
              };

              if (index === 0) {
                subMenuItem = { ...item };
                localEventProperties = property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                }));
              }

              if (
                selectedSubMenuItem.scopeIdentifier &&
                item.title === selectedSubMenuItem.scopeIdentifier
              ) {
                subMenuItem = { ...item };
                localEventProperties = property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                }));
              }

              initialSubOptions.push(item);

              return {
                ...item,
                properties: property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                })),
              };
            }
          );

          if (clonedEventProperties.length > 0) {
            localEventProperties = mergeArrays(
              localEventProperties,
              clonedEventProperties,
              "eventProperty"
            );
            subMenuItem = selectedSubMenuItem;
          }
          setInitialRegionalOptions(initialSubOptions);
        }

        if (existingUseCase.scope === "legalEntity") {
          existingEventProperties = existingUseCase.mappedEventProperties.map(
            (property, index) => {
              const item = {
                title: `${property.legalEntityId} - ${property.legalEntityName}`,
                legalEntityId: property.legalEntityId,
                legalEntityDescription: property.legalEntityDescription,
                legalEntityName: property.legalEntityName,
              };

              if (index === 0) {
                subMenuItem = { ...item };
                localEventProperties = property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                }));
              }

              if (
                selectedSubMenuItem.scopeIdentifier &&
                item.title === selectedSubMenuItem.scopeIdentifier
              ) {
                subMenuItem = { ...item };
                localEventProperties = property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                }));
              }

              initialSubOptions.push(item);

              return {
                ...item,
                properties: property.properties.map((x) => ({
                  ...x,
                  propertyName: x.eventProperty,
                  description: x.eventPropertyDescription,
                  title: x.eventName,
                })),
              };
            }
          );

          if (clonedEventProperties.length > 0) {
            localEventProperties = mergeArrays(
              localEventProperties,
              clonedEventProperties,
              "eventProperty"
            );
            subMenuItem = selectedSubMenuItem;
          }

          setInitialLegalEntitiesOptions(initialSubOptions);
        }

        if (existingUseCase.scope === "global") {
          existingEventProperties = existingUseCase.mappedEventProperties.map(
            (property) => ({
              properties: property.properties.map((x) => ({
                ...x,
                propertyName: x.eventProperty,
                description: x.eventPropertyDescription,
                title: x.eventName,
              })),
            })
          );
          if (existingUseCase.mappedEventProperties[0]) {
            localEventProperties =
              existingUseCase.mappedEventProperties[0].properties.map((x) => ({
                ...x,
                propertyName: x.eventProperty,
                description: x.eventPropertyDescription,
                title: x.eventName,
              }));
          }
          if (clonedEventProperties.length > 0) {
            localEventProperties = mergeArrays(
              localEventProperties,
              clonedEventProperties,
              "eventProperty"
            );
            subMenuItem = selectedSubMenuItem;
          }
        }
      } else {
        localEventProperties = clonedEventProperties;
        existingEventProperties = clonedEventProperties;
        subMenuItem = selectedSubMenuItem;
      }

      setExistingUseCaseInfo({
        ...existingUseCase,
        mappedEventProperties: existingEventProperties,
      });

      if (isRequestRevisionFlow) {
        const {
          scope: updatedUseCaseScope,
          eventProperties: updatedEventProperties,
        } = updatedUseCase;
        const updatedEventName = updatedEventProperties.eventName;

        localEventProperties = existingUseCase.mappedEventProperties
          .find((mt) => {
            if (updatedUseCaseScope.type === "global") {
              return true;
            }
            return (
              mt[mappedIdentifier(updatedUseCaseScope).attribute] ===
              updatedUseCaseScope[
                mappedIdentifier(updatedUseCaseScope).attribute
              ]
            );
          })
          ?.properties?.filter((x) => x.eventName === updatedEventName)
          .map((x) => {
            const updatedEventProperty = updatedEventProperties.properties.find(
              (uep) => uep.eventProperty === x.eventProperty
            );
            if (updatedEventProperty) {
              return updatedEventProperty;
            }

            return { ...x, isSelected: true, title: x.traitName };
          });

        subMenuItem = {
          ...mappedIdentifier(updatedUseCaseScope).subMenuItem,
        };
      }

      const initEventNames = Array.from(
        new Set(localEventProperties.map((property) => property.eventName))
      ).map((eventName) => ({
        title: eventName,
        eventName,
      }));
      setInitialEventNameOptions(initEventNames);

      localEventProperties = localEventProperties
        ?.filter((x) => !x.deleted)
        .map((x) => ({
          ...x,
          labels: (x.labels || []).map((y) => ({ ...y, title: y.labelName })),
          selectedLabels: (x.labels || []).map((y) => ({
            ...y,
            title: y.labelName,
          })),
        }));

      setEventProperties(
        localEventProperties.map((x, index) => ({
          ...x,
          propertyName: x.eventProperty,
          description: x.eventPropertyDescription,
          title: x.eventName,
          index,
        }))
      );

      setUseCaseInfo((draft) => {
        draft.eventName = {
          title: selectedSubMenuItem.eventName || initEventNames[0]?.eventName,
        };
        draft.mappedEventProperties = existingEventProperties;
      });
    }

    setUseCaseInfo((draft) => {
      draft.useCaseId = useCaseId;
      if (!isEmpty(subMenuItem)) {
        draft.selectedSubMenuItem = {
          ...subMenuItem,
          scopeIdentifier: subMenuItem.title,
        };
      } else {
        draft.selectedSubMenuItem = {};
      }
    });

    if (!existingUseCase && updatedUseCase) {
      setUseCaseInfo((draft) => {
        draft.selectedSubMenuItem = updatedUseCase.selectedSubMenuItem;
      });
    }
  };

  useEffect(() => {
    if (isManageUseCaseFlow) {
      if (module) {
        setCurrentStep(2);
        if (useCaseInfo.selectedSubMenuItem?.scopeIdentifier) {
          setInitialStep(2);
        }
      } else {
        setUseCaseInfo((draft) => {
          draft.useCaseName = location.state.useCaseName;
          draft.useCaseDescription = location.state.useCaseDescription;
        });
      }
    }
  }, [isManageUseCaseFlow]);

  useEffect(() => {
    if (useCaseId) {
      const localEventProperties = useCaseInfo?.mappedEventProperties?.find(
        (x) => x.title === useCaseInfo.selectedSubMenuItem?.title
      )?.properties;
      const initEventNames = Array.from(
        new Set(
          (localEventProperties || []).map((property) => property.eventName)
        )
      ).map((eventName) => ({
        title: eventName,
        eventName,
      }));
      setInitialEventNameOptions(initEventNames);
      if (!(useCaseInfo.eventName?.title?.length > 0)) {
        setUseCaseInfo((draft) => {
          draft.eventName = {
            title: initEventNames[0]?.eventName,
          };
        });
      }
    }
  }, [useCaseInfo.selectedSubMenuItem]);

  const initUseCaseFormData = (existingUseCase, updatedUseCase) => {
    if (onlyOnce.current) {
      setUseCaseInfo((draft) => {
        draft.useCaseName = updatedUseCase
          ? updatedUseCase.useCaseName
          : existingUseCase.useCaseName;
        draft.useCaseDescription = updatedUseCase
          ? updatedUseCase.useCaseDescription
          : existingUseCase.useCaseDescription;
        draft.useCaseId = useCaseId;
      });
      onlyOnce.current = false;
    }
  };

  useEffect(() => {
    if (!isEmpty(traits)) {
      setErrors({
        ...errors,
        traitsError: null,
      });
    }
  }, [traits]);

  const setData = async () => {
    setErrors(defaultErrors);
    if (isRequestRevisionFlow) {
      setCurrentStep(2);
      if (useCaseId) {
        increaseRequestsCount(1);
        try {
          const existingUseCase = await getUseCaseDetails(
            useCaseId,
            `scope=${scope}${module ? `&module=${module}` : ""}`
          );

          setUseCaseData(existingUseCase, request.requestDetails, {});
          initUseCaseFormData(existingUseCase, null);
        } catch (error) {
          handleError({
            error,
            handle404: false,
            addNotification,
          });
        } finally {
          decreaseRequestsCount(1);
        }
      } else {
        increaseRequestsCount(1);

        try {
          setCurrentStep(2);

          const {
            dataUseCase: { useCaseName, useCaseDescription },
            eventProperties: ep,
            traits: requestTraits,
            scope: requestScope,
          } = request.requestDetails;

          const itemTitle = () => {
            if (requestScope.type === "marketingProgram") {
              return {
                title: `${requestScope.marketingProgramNumber} - ${
                  requestScope.marketingProgramDescription ||
                  /* istanbul ignore next */ requestScope.description
                }`,
                id: requestScope.marketingProgramNumber,
              };
            }
            if (requestScope.type === "legalEntity") {
              return {
                title: `${
                  requestScope.legalEntityId || requestScope.legalEntityNumber
                } - ${requestScope.legalEntityName}`,
                id: requestScope.legalEntityId,
              };
            }
            if (requestScope.type === "regional") {
              return {
                title: `${requestScope.regionCode} - ${requestScope.regionName}`,
                id: requestScope.regionCode,
              };
            }
            return null;
          };

          const title = itemTitle();
          const useCaseData = {
            useCaseName,
            useCaseDescription,
            selectedSubMenuItem: title
              ? {
                  ...requestScope,
                  ...title,
                  scopeIdentifier: title.title,
                }
              : {},
            eventName: {},
          };

          if (requestTraits) {
            setTraits(
              requestTraits.map((x) => ({
                ...x,
                title: x.traitName,
                isSelected: true,
              }))
            );
          } else if (ep) {
            useCaseData.eventName = {
              title: ep.eventName,
            };

            const data = await getEventProperties(ep.eventName, {
              denormalize: true,
            });

            setEventPropertiesOptions(data);

            setEventProperties(
              ep.properties.map((x, index) => {
                const eventProperty = data.find(
                  (y) => y.propertyName === x.eventProperty
                );
                return {
                  ...x,
                  eventName: ep.eventName,
                  title: ep.eventName,
                  index,
                  ...eventProperty,
                };
              })
            );
          }

          setUseCaseInfo(useCaseData);
        } catch (error) {
          handleError({
            error,
            handle404: false,
            addNotification,
          });
        } finally {
          decreaseRequestsCount(1);
        }
      }
    }

    if (
      isManageUseCaseFlow &&
      !isRequestRevisionFlow &&
      useCaseInfo.scope !== scope
    ) {
      increaseRequestsCount(1);
      try {
        let params = `scope=${scope}${module ? `&module=${module}` : ""}`;

        if (location.state?.selectedSubMenuItem) {
          params = `scope=${location.state?.scope}${
            module ? `&module=${module}` : ""
          }`;
        }

        let existingUseCase = "";

        if (location.state.mappedTraits) {
          existingUseCase = location.state;
        } else {
          existingUseCase = await getUseCaseDetails(useCaseId, params);
        }

        setUseCaseInfo((draft) => {
          draft.scope = scope;
        });
        setUseCaseData(
          existingUseCase,
          null,
          location.state?.selectedSubMenuItem || {}
        );
        initUseCaseFormData(existingUseCase, null);
      } catch (error) {
        handleError({
          error,
          handle404: false,
          addNotification,
        });
      } finally {
        decreaseRequestsCount(1);
      }
    }
  };

  useEffect(async () => {
    if (currentStep === 2) {
      setData();
    }
  }, [scope, currentStep]);

  useEffect(() => {
    if (currentStep === 1) {
      setUseCaseInfo((draft) => {
        draft.scope = "";
      });
    }
  }, [module]);

  const newLabelOption = useMemo(
    () => (
      <div
        className={clsx(
          classes.flexContainer,
          classes.addTraitBtn,
          classes.noOptionsContainer
        )}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={() => {}}
        role="button"
        tabIndex="-1"
      >
        <Typography variant="h6">
          {t("create_trait.no_suggestion_match")}
        </Typography>
        <Button
          onClick={() => {
            setNewLabelModal(true);
          }}
          data-testid="new-segment-source"
        >
          New Label
        </Button>
      </div>
    ),
    [classes.addTraitBtn, classes.flexContainer, classes.noOptionsContainer, t]
  );

  useEffect(() => {
    (async () => {
      setLabelsLoading(true);
      const labelsData =
        (
          await getReferenceData(applicationConfig.referenceDataQueries.labels)
        )?.labels.map((x) => ({
          ...x,
          title: x.labelName,
        })) || [];

      setLabels([...labelsData, newLabelOption]);
      setLabelsLoading(false);
    })();
  }, [newLabelOption]);

  useEffect(() => {
    (async () => {
      if (user.userId && isUserAuthorized && currentStep === 2) {
        increaseRequestsCount(3);

        if (initialMarketingProgramOptions.length === 0) {
          await getMarketingProgramsFromApi("");
        }
        if (initialLegalEntitiesOptions.length === 0) {
          await getLegalEntitiesFromApi("");
        }

        if (isEventsFlow) {
          if (initialEventNameOptions.length === 0) {
            await getEventsFromApi("");
          }
        }

        decreaseRequestsCount(3);
      }
    })();
  }, [user, currentStep, isTraitsFlow, isEventsFlow]);

  useEffect(() => {
    (async () => {
      if (
        useCaseInfo.eventName.title &&
        (useCaseId || !isRequestRevisionFlow)
      ) {
        const data = await getEventProperties(useCaseInfo.eventName.title, {
          denormalize: true,
        });
        setEventPropertiesOptions(data);
      }
    })();
  }, [useCaseInfo.eventName.title]);

  const validateUseCaseName = async () => {
    if (!isEmpty(useCaseInfo.useCaseName)) {
      if (isManageUseCaseFlow) {
        if (existingUseCaseInfo.useCaseName === useCaseInfo.useCaseName) {
          return false;
        }
      }
      try {
        setValidatingUseCaseName(true);
        const filters = [
          {
            name: "useCaseName",
            value: useCaseInfo.useCaseName,
          },
          {
            name: "exactMatch",
            value: "true",
          },
        ];
        await getUseCases(
          parseSearchFilter(filters, {
            useCaseName: "useCaseName",
            exactMatch: "exactMatch",
          })
        );
        setIsExistingUseCaseError(
          t("create_use_case.errors.existing_use_case")
        );
        return true;
      } catch (error) {
        handleError({
          error,
          handle404: true,
          addNotification,
        });
        setIsExistingUseCaseError("");
        return false;
      } finally {
        setValidatingUseCaseName(false);
      }
    }
    return false;
  };

  const useCaseRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "useCaseName",
      headerName: t("common.labels.use_case_name"),
      flex: 2,
      sortable: false,
    },
    {
      field: "useCaseDescription",
      headerName: t("create_use_case.use_case_description"),
      flex: 2,
      sortable: false,
    },
    {
      field: "scope",
      headerName: "Scope",
      flex: 1,
      sortable: false,
      renderCell: (params) => scopeMapping[params.value],
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  setUseCaseOutput({
                    ...useCaseResponse[0].output,
                    useCaseName: useCaseResponse[0].useCaseName,
                  });
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.use_case_name"),
        value: !useCaseInfo.useCaseId ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: "0 0 50%",
            }}
          >
            <p style={{ fontWeight: "700" }}>{useCaseInfo.useCaseName}</p>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "8px",
                borderRadius: "20px",
                padding: "2px",
              }}
            >
              New
            </Button>
          </div>
        ) : (
          useCaseInfo.useCaseName
        ),
      },
      {
        label: t("create_use_case.use_case_description"),
        value: useCaseInfo.useCaseDescription,
      },
      {
        label: t("common.scope"),
        value: scopeMapping[scope],
      },
    ];
    if (scope !== "global") {
      info.push({
        label:
          scope === "regional"
            ? "Region"
            : scope
                ?.replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase()),
        value: useCaseInfo.selectedSubMenuItem.scopeIdentifier,
      });
    }
    if (isEventsFlow) {
      info.push({
        label: t("common.labels.event_name"),
        value: useCaseInfo.eventName.title,
      });
    }
    return info;
  };

  const trackItemChanges = useCallback(
    (
      existingItems = [],
      /* istanbul ignore next */
      newItems = [],
      primaryKey
    ) => {
      const newlyAddedItems = [];
      const deletedItems = [];
      const updatedItems = [];
      // Track Deleted and newly added traits
      existingItems?.forEach((item) => {
        // if not exists then deleted
        const index = newItems.findIndex(
          (newItem) => newItem[primaryKey] === item[primaryKey]
        );
        if (index === -1) {
          deletedItems.push({
            ...item,
            title: isTraitsFlow ? item.traitName : item[primaryKey],
            deleted: true,
          });
        }
      });

      newItems?.forEach((newItem) => {
        const index = existingItems.findIndex(
          (item) => item[primaryKey] === newItem[primaryKey]
        );
        // if not exists then new trait
        if (index === -1) {
          newlyAddedItems.push({
            ...newItem,
            new: true,
          });
        }
      });

      const updatedTraits = newItems?.filter((x) => x.isUpdated);
      updatedTraits?.forEach((ut) => {
        const updatedTrait = { ...ut };
        let oldTrait = existingItems.find(
          (y) => y[primaryKey] === updatedTrait[primaryKey]
        );

        oldTrait = {
          ...oldTrait,
          deleted: true,
          isUpdated: true,
        };

        updatedItems.push(
          {
            ...oldTrait,
          },
          { ...updatedTrait }
        );
      });

      return [...deletedItems, ...newlyAddedItems, ...updatedItems];
    },
    [isTraitsFlow]
  );

  useEffect(() => {
    let itemsToBeRendered = [];
    if (isTraitsFlow) {
      if (!isManageUseCaseFlow) {
        itemsToBeRendered = traits.map((trait) => ({ ...trait, new: true }));
      } else {
        let currentTraits = [];

        if (existingUseCaseInfo.mappedTraits?.length) {
          currentTraits =
            scope === "global"
              ? existingUseCaseInfo.mappedTraits[0]?.traits
              : existingUseCaseInfo.mappedTraits.find(
                  (mt) =>
                    mt.title === useCaseInfo.selectedSubMenuItem.scopeIdentifier
                )?.traits;
          currentTraits = (currentTraits || []).map((x) => {
            if ("labels" in x) {
              const modifiedLabels = x.labels?.map((y) => ({
                ...y,
                title: y.labelName,
              }));
              return {
                ...x,
                labels: modifiedLabels,
                selectedLabels: modifiedLabels,
              };
            }
            return x;
          });
        }
        itemsToBeRendered = trackItemChanges(currentTraits, traits, "traitId");
      }
    } else if (isEventsFlow) {
      if (!isManageUseCaseFlow) {
        itemsToBeRendered = eventProperties.map((property) => ({
          ...property,
          new: true,
        }));
      } else {
        let currentProperties =
          scope === "global"
            ? existingUseCaseInfo.mappedEventProperties?.[0]?.properties
            : existingUseCaseInfo.mappedEventProperties?.find(
                (mt) =>
                  mt.title === useCaseInfo.selectedSubMenuItem.scopeIdentifier
              )?.properties;
        currentProperties = filterByEventName(
          currentProperties || [],
          useCaseInfo.eventName.title
        );
        currentProperties = (currentProperties || []).map((x) => {
          if ("labels" in x) {
            const modifiedLabels = x.labels?.map((y) => ({
              ...y,
              title: y.labelName,
            }));
            return {
              ...x,
              labels: modifiedLabels,
              selectedLabels: modifiedLabels,
            };
          }
          return x;
        });
        const eventPropertiesUi = filterByEventName(
          eventProperties,
          useCaseInfo.eventName.title
        );
        itemsToBeRendered = trackItemChanges(
          currentProperties || /* istanbul ignore next */ [],
          eventPropertiesUi,
          "propertyName"
        );
      }
    }

    changedItems.current = itemsToBeRendered;
  }, [
    traits,
    existingUseCaseInfo,
    scope,
    useCaseInfo,
    eventProperties,
    isTraitsFlow,
    isEventsFlow,
    isManageUseCaseFlow,
    trackItemChanges,
  ]);

  const renderTraitAccordion = (trait) => {
    let statusLabel = "";
    if (trait.deleted && trait.isUpdated) {
      statusLabel = applicationConfig.status.existing;
    } else if (trait.deleted) {
      statusLabel = applicationConfig.status.deleted;
    } else if (trait.isUpdated) {
      statusLabel = applicationConfig.status.updated;
    } else if (trait.new) {
      statusLabel = applicationConfig.status.new;
    }

    return (
      <Accordion className={classes.accordion}>
        <AccordionSummary
          data-testid="accordion-summary"
          className={classes.accordionHeader}
          expandIcon={<ExpandMoreIcon />}
        >
          <div className={clsx(classes.flexContainer, classes.fullWidth)}>
            <Typography className={classes.traitTitle}>
              {trait.title}
            </Typography>
            <Typography variant="body2" className={classes.traitDescription}>
              {trait.description}
            </Typography>
            {statusLabel && (
              <Typography
                variant="body2"
                className={clsx(
                  classes.statusLabel,
                  trait.new || (trait.isUpdated && !trait.deleted)
                    ? classes.new
                    : classes.deleted
                )}
              >
                {statusLabel}
              </Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionContent}>
          <AccordionRowDetails
            keys={[
              {
                id: "traitId",
                name: t("create_use_case.traits.traitId"),
              },
              {
                id: "traitName",
                name: t("create_use_case.traits.globalTraitName"),
              },
              {
                id: "description",
                name: t("common.labels.description"),
              },
              {
                id: "dataType",
                name: t("create_use_case.traits.dataType"),
              },
              {
                id: "personalData",
                name: t("create_use_case.traits.personalData"),
              },
              {
                id: "selectedLabels",
                name: "Labels",
              },
            ]}
            row={{
              ...trait,
              selectedLabels: trait.selectedLabels?.map((x, i) => (
                <div key={i} className={classes.accordionChips}>
                  <Chip
                    label={x.title}
                    color="primary"
                    avatar={x.labelId ? <></> : <Avatar>NEW</Avatar>}
                  />
                </div>
              )),
            }}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderEventPropertiesAccordion = (item) => {
    let statusLabel = "";

    if (item.deleted && item.isUpdated) {
      statusLabel = applicationConfig.status.existing;
    } else if (item.deleted) {
      statusLabel = applicationConfig.status.deleted;
    } else if (item.isUpdated) {
      statusLabel = applicationConfig.status.updated;
    } else if (item.new) {
      statusLabel = applicationConfig.status.new;
    }

    return (
      <EventPropertiesAccordion
        eventProperty={item}
        statusLabel={statusLabel}
      />
    );
  };

  const renderLoadingShimmer = () => {
    return (
      <>
        <Skeleton height={56} />
        <Skeleton height={56} />
        <Skeleton width={100} height={24} />
      </>
    );
  };

  const renderStep = (step) => {
    if (step !== 3 && loading) {
      return renderLoadingShimmer();
    }
    if (step === 1) {
      let fields = [];
      fields = [
        {
          type: "custom",
          element: EditableText,
          label: t("common.labels.use_case_name"),
          props: {
            editMode: isManageUseCaseFlow,
            placeholder: t("common.labels.use_case_name"),
            error:
              errors.useCaseNameError !== null ||
              !isEmpty(isExistingUseCaseError),
            helperText: (
              <InlineMessage
                message={errors.useCaseNameError || isExistingUseCaseError}
              />
            ),
            onChange: (value) => {
              setUseCaseInfo((draft) => {
                draft.useCaseName = value;
              });
              setIsExistingUseCaseError("");
              setErrors({
                ...errors,
                useCaseNameError: null,
              });
            },
            value: useCaseInfo.useCaseName,
            onCancel: (initValue) => {
              setUseCaseInfo((draft) => {
                draft.useCaseName = initValue;
              });
            },
            isDisabled: isFormDisabled,
          },
        },
        {
          type: "custom",
          element: EditableText,
          label: t("create_use_case.use_case_description"),
          props: {
            editMode: isManageUseCaseFlow,
            placeholder: t("create_use_case.use_case_description"),
            error: errors.useCaseDescriptionError !== null,
            helperText: (
              <InlineMessage message={errors.useCaseDescriptionError} />
            ),
            onChange: (value) => {
              setUseCaseInfo((draft) => {
                draft.useCaseDescription = value;
              });
              setErrors({
                ...errors,
                useCaseDescriptionError: null,
              });
            },
            rows: 6,
            multiline: true,
            value: useCaseInfo.useCaseDescription,
            onCancel: (initValue) => {
              setUseCaseInfo((draft) => {
                draft.useCaseDescription = initValue;
              });
            },
            isDisabled: isFormDisabled,
          },
        },
      ];

      return (
        <>
          <Form
            fields={fields}
            fieldClassName={classes.inputContainer}
            containerClassName={classes.grid}
          />
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <InputLabel id="demo-simple-select-label">
              What would you like to map?
            </InputLabel>
            <Select
              value={module}
              label="What would you like to map?"
              onChange={(e) => {
                setModule(e.target.value);
                setErrors({
                  ...errors,
                  moduleError: null,
                });
              }}
            >
              <MenuItem value="traits">Traits</MenuItem>
              <MenuItem value="events">Events</MenuItem>
            </Select>
            {errors.moduleError && (
              <FormHelperText error>
                <InlineMessage message={t("errors.field_required")} />
              </FormHelperText>
            )}
          </FormControl>
        </>
      );
    }

    if (step === 2) {
      const marketingProgramDropdownValues =
        subMenuValue.length > 0 || initialMarketingProgramOptions.length === 0
          ? marketingProgramOptions
          : initialMarketingProgramOptions;
      const legalEntitiesDropdownValues =
        subMenuValue.length > 0 || initialLegalEntitiesOptions.length === 0
          ? legalEntities
          : initialLegalEntitiesOptions;
      const regionalDropdownValues =
        subMenuValue.length > 0 || initialRegionalOptions.length === 0
          ? regionFilters
          : initialRegionalOptions;
      const eventDropdownValues =
        eventValue.length > 0 || initialEventNameOptions.length === 0
          ? eventNameOptions
          : initialEventNameOptions;

      const subList = () => {
        if (scope === "regional") {
          return regionalDropdownValues
            ?.filter((region) => {
              if (
                region.label !== "GLOBAL" &&
                region.geoRegionName !== "GLOBAL"
              ) {
                return true;
              }
              return false;
            })
            .map((region) => {
              return {
                ...region,
                title: region.label
                  ? `${region.name} - ${region.label}`
                  : `${region.geoRegionCode} - ${region.geoRegionName}`,
              };
            });
        }
        if (scope === "legalEntity") {
          return legalEntitiesDropdownValues.map((entity) => ({
            ...entity,
            title: `${entity.legalEntityId || entity.legalEntityNumber} - ${
              entity.legalEntityName
            }`,
          }));
        }
        return marketingProgramDropdownValues.map((program) => ({
          ...program,
          title: `${program.marketingProgramNumber} - ${
            program.marketingProgramDescription ||
            /* istanbul ignore next */ program.description
          }`,
        }));
      };

      return (
        <div className={classes.marketingProgramContainer}>
          <FormControl fullWidth>
            <InputLabel
              id="scope-label"
              classes={{
                root: globalStyles.label,
                shrink: classes.shrinkLabel,
              }}
            >
              Scope
            </InputLabel>
            <Select
              value={scope}
              label="Scope"
              id="scope"
              disabled={isManageUseCaseFlow && initialStep !== 1}
              onChange={(e) => {
                if (location.state?.selectedSubMenuItem) {
                  location.state.scope = e.target.value;
                  location.state.selectedSubMenuItem = {};
                }
                setScope(e.target.value);
                if (isEventsFlow) {
                  setUseCaseInfo((draft) => {
                    draft.selectedSubMenuItem = {};
                    draft.eventName = {};
                  });
                } else {
                  setUseCaseInfo((draft) => {
                    draft.selectedSubMenuItem = {};
                  });
                }
                setSubMenuValue("");
              }}
            >
              <MenuItem value="global" data-testid="global-option">
                Global
              </MenuItem>
              <MenuItem value="regional" data-testid="regional-option">
                Region
              </MenuItem>
              <MenuItem value="legalEntity" data-testid="legal-option">
                Legal Entity
              </MenuItem>
              <MenuItem value="marketingProgram" data-testid="marketing-option">
                Marketing Program
              </MenuItem>
            </Select>
          </FormControl>
          {scope !== "global" && (
            <div
              className={clsx(classes.inputContainer, classes.justifyContent)}
              key="marketing-program"
            >
              <InputLabel
                id="scope-value-label"
                classes={{
                  root: globalStyles.label,
                }}
              >
                {scope === "regional"
                  ? "Region"
                  : scope
                      ?.replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
              </InputLabel>
              <Autocomplete
                options={subList()}
                id="marketing-programs"
                placeholder={`Please select a ${
                  scope === "regional"
                    ? "Region"
                    : scope
                        ?.replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())
                }`}
                loading={marketingProgramsLoading || legalEntitiesLoading}
                getOptionLabel={(option) => option.title || ""}
                value={useCaseInfo.selectedSubMenuItem}
                onChange={(event, value) => {
                  setErrors({
                    ...errors,
                    scopeIdentifier: null,
                  });
                  setUseCaseInfo((draft) => {
                    if (!isEmpty(value)) {
                      draft.selectedSubMenuItem = {
                        ...value,
                        scopeIdentifier: value.title,
                      };
                    } else {
                      draft.selectedSubMenuItem = {};
                    }
                    draft.eventName = {};
                  });
                  if (isTraitsFlow) {
                    if (
                      (isManageUseCaseFlow || isRequestRevisionFlow) &&
                      !isEmpty(value)
                    ) {
                      const index = existingUseCaseInfo.mappedTraits.findIndex(
                        (trait) => trait.title === value.title
                      );
                      if (index !== -1) {
                        setTraits(
                          existingUseCaseInfo.mappedTraits[index].traits
                        );
                      } else {
                        setTraits([]);
                      }
                    } else if (isManageUseCaseFlow || isRequestRevisionFlow) {
                      setSubMenuValue("");
                      setTraits([]);
                    } else {
                      setTraits([]);
                    }
                  } else if (isEventsFlow) {
                    if (
                      (isManageUseCaseFlow || isRequestRevisionFlow) &&
                      !isEmpty(value)
                    ) {
                      const index =
                        existingUseCaseInfo.mappedEventProperties.findIndex(
                          (eventProperty) => eventProperty.title === value.title
                        );
                      if (index !== -1) {
                        setEventProperties(
                          existingUseCaseInfo.mappedEventProperties[index]
                            .properties
                        );
                      } else {
                        setEventProperties([]);
                      }
                    } else if (isManageUseCaseFlow || isRequestRevisionFlow) {
                      setSubMenuValue("");
                      setEventProperties([]);
                    } else {
                      setEventProperties([]);
                    }
                  }
                }}
                disabled={
                  (isManageUseCaseFlow && initialStep !== 1) || isFormDisabled
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={`Please select a ${
                      scope === "regional"
                        ? "Region"
                        : scope
                            ?.replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())
                    }`}
                    onChange={(event) => {
                      if (scope === "marketingProgram") {
                        setMarketingProgramsLoading(true);
                        setMarketingProgramOptions([]);
                        getMarketingProgramsFromApi(event.target.value);
                      }
                      if (scope === "legalEntity") {
                        setLegalEntities([]);
                        memoizedLegalEntitiesFromApi(event.target.value);
                      }
                      setSubMenuValue(event.target.value);
                    }}
                    error={Boolean(errors.scopeIdentifier)}
                    helperText={
                      <InlineMessage message={errors.scopeIdentifier} />
                    }
                  />
                )}
              />
            </div>
          )}
          {scope !== "global" && isEmpty(useCaseInfo.selectedSubMenuItem) && (
            <Alert
              severity="info"
              classes={{
                root: classes.alert,
              }}
            >
              Please select a{" "}
              {scope === "regional"
                ? "Region"
                : scope
                    ?.replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}{" "}
              to view the mapped {isTraitsFlow ? "traits" : "event properties"}
            </Alert>
          )}
          {isEventsFlow &&
            (scope === "global" ||
              !isEmpty(useCaseInfo.selectedSubMenuItem)) && (
              <div>
                <InputLabel
                  id="event-name-label"
                  classes={{
                    root: globalStyles.label,
                  }}
                >
                  {t("common.labels.event_name")}
                </InputLabel>
                <Autocomplete
                  options={eventDropdownValues.map((value) => ({
                    ...value,
                    title: value.eventName,
                  }))}
                  id="event-name"
                  placeholder="Event Names"
                  loading={eventNamesLoading}
                  getOptionLabel={(option) => option.title || ""}
                  value={useCaseInfo.eventName}
                  onChange={(event, value) => {
                    setUseCaseInfo((draft) => {
                      /* istanbul ignore next */
                      draft.eventName = value || {};
                    });
                  }}
                  disabled={
                    (isFormDisabled && initialStep !== 1) || isManageUseCaseFlow
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Please select an event name"
                      onChange={(event) => {
                        setEventNamesLoading(true);
                        memoizedEventsFromApi(event.target.value);
                        setEventValue(event.target.value);
                      }}
                    />
                  )}
                />
              </div>
            )}
          {(isTraitsFlow
            ? isSubMenuItemSelected && traits?.length > 0
            : isSubMenuItemSelected &&
              !isEmpty(useCaseInfo.eventName) &&
              eventProperties?.length > 0) && (
            <Typography
              classes={{
                root: globalStyles.label,
              }}
            >
              {isEventsFlow
                ? "Associated Event Properties"
                : "Associated Traits"}
            </Typography>
          )}
          {isTraitsFlow && (
            <div className={classes.center} data-testid="traits-container">
              {isTraitsFlow && isSubMenuItemSelected && isEmpty(traits) && (
                <Alert severity="info">No Traits mapped at this level</Alert>
              )}
              {isTraitsFlow &&
                !isEmpty(traits) &&
                traits.map((trait, traitIndex) => {
                  const traitName = trait.title;
                  return (
                    <div
                      key={`${traitName}-${traitIndex + 1}`}
                      className={clsx(
                        classes.flexContainer,
                        classes.fullWidth,
                        classes.trait
                      )}
                    >
                      {renderTraitAccordion(trait)}
                      <div
                        className={classes.autoCompleteEditContainer}
                        onClick={() => {
                          setTraitModal(true);
                          setTraitSelectedIndex(traitIndex);
                        }}
                        role="button"
                        data-testid="accordion-edit"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        aria-label="edit"
                      >
                        <EditOutlinedIcon />
                      </div>
                      <div
                        className={classes.autoCompleteDeleteContainer}
                        onClick={() => {
                          setTraitSelectedIndex(traitIndex);
                          setOpenDeleteModal(true);
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyDown={/* istanbul ignore next */ () => {}}
                        aria-label="delete"
                        data-testid="delete-trait-btn"
                      >
                        <DeleteOutlineOutlinedIcon />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {isEventsFlow && (
            <div>
              {isEmpty(useCaseInfo.eventName.title) &&
              (!isEmpty(useCaseInfo.selectedSubMenuItem) ||
                scope === "global") ? (
                <Alert severity="info">
                  Please select an Event to view the mapped event properties
                </Alert>
              ) : (
                isEventsFlow &&
                (!isEmpty(useCaseInfo.selectedSubMenuItem) ||
                  scope === "global") &&
                isEmpty(
                  filterByEventName(
                    eventProperties,
                    useCaseInfo.eventName.title
                  )
                ) &&
                !isEmpty(useCaseInfo.eventName) && (
                  <Alert severity="info">
                    No Event Properties mapped at this level
                  </Alert>
                )
              )}
              {filterByEventName(
                eventProperties,
                useCaseInfo.eventName.title
              ).map((row) => {
                return (
                  <div
                    className={clsx(classes.flexContainer, classes.fullWidth)}
                  >
                    {renderEventPropertiesAccordion(row)}
                    <div
                      className={classes.autoCompleteEditContainer}
                      onClick={() => {
                        setEventPropertyModal(true);
                        setEventSelectedIndex(row.index);
                      }}
                      role="button"
                      data-testid="accordion-edit"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      aria-label="edit"
                    >
                      <EditOutlinedIcon />
                    </div>
                    <div
                      className={classes.autoCompleteDeleteContainer}
                      onClick={() => {
                        setEventSelectedIndex(row.index);
                        setOpenEventPropertyDeleteModal(true);
                      }}
                      role="button"
                      aria-label="delete"
                      data-testid="delete-trait-btn"
                      tabIndex={0}
                      onKeyDown={/* istanbul ignore next */ () => {}}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {(isTraitsFlow || isEventsFlow) && !isEmpty(errors.traitsError) && (
            <Alert severity="error" style={{ marginTop: "8px" }}>
              {errors.traitsError}
            </Alert>
          )}
          {!isEmpty(useCaseInfo.useCaseName) &&
            isTraitsFlow &&
            isSubMenuItemSelected && (
              <div className={globalStyles.addTraitBtn}>
                <AddCircleOutlineIcon />
                <Button
                  onClick={() => {
                    setTraitModal(true);
                  }}
                >
                  {t("common.new_trait")}
                </Button>
              </div>
            )}
          {!isEmpty(useCaseInfo.useCaseName) &&
            isEventsFlow &&
            !isEmpty(useCaseInfo.eventName.title) &&
            isSubMenuItemSelected && (
              <div className={globalStyles.addTraitBtn}>
                <AddCircleOutlineIcon />
                <Button
                  onClick={() => {
                    setErrors(defaultErrors);
                    setEventPropertyModal(true);
                  }}
                >
                  {t("common.new_event_property")}
                </Button>
              </div>
            )}
        </div>
      );
    }

    if (step === 3) {
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            key={`${info.label}${info.index}`}
          >
            <Typography variant="h6">{info.label} :</Typography>
            {typeof info.value === "string" ? (
              <Typography variant="h6" className={classes.bold}>
                {info.value}
              </Typography>
            ) : (
              info.value
            )}
          </div>
        );
      });

      const renderTraitChanges = () => {
        return (
          <div className={classes.confirmationContainer}>
            <Typography variant="h4">
              {t("create_use_case.associated_traits")}
            </Typography>
            {isEmpty(changedItems.current) && (
              <Alert severity="error">
                {t("create_use_case.no_traits_changes")}
              </Alert>
            )}
            {changedItems.current.some(
              (trait) => trait.deleted && !trait.isUpdated
            ) && (
              <Alert severity="warning">
                {t("create_use_case.trait_deletion_warning")}
              </Alert>
            )}

            {changedItems.current.map((trait, traitIndex) => {
              return (
                <div
                  key={`${trait.title}-${traitIndex + 1}`}
                  className={clsx(classes.flexContainer, classes.fullWidth)}
                >
                  {renderTraitAccordion(trait)}
                </div>
              );
            })}
          </div>
        );
      };

      const renderEventChanges = () => {
        return (
          <div className={classes.confirmationContainer}>
            <Typography variant="h4">
              {t("create_use_case.associated_event_properties")}
            </Typography>
            {isEmpty(changedItems.current) && (
              <Alert severity="info">
                {t("create_use_case.no_event_property_changes")}
              </Alert>
            )}
            {changedItems.current.map((item, itemIndex) => {
              return (
                <div
                  key={`${item.propertyName}-${itemIndex + 1}`}
                  className={clsx(classes.flexContainer, classes.fullWidth)}
                >
                  {renderEventPropertiesAccordion(item)}
                </div>
              );
            })}
          </div>
        );
      };

      return (
        <div>
          {detailsDOM}
          {isTraitsFlow ? renderTraitChanges() : renderEventChanges()}
        </div>
      );
    }
    return (
      <div
        style={{
          height: 70 * useCaseResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table columns={useCaseRequestTableColumns} rows={useCaseResponse} />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/use-cases" goToText="GO TO USE CASE" />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading}
        steps={[
          t("create_use_case.headings.step_1"),
          module === "events" ? "Event Properties Mapping" : "Traits Mapping",
          t("create_use_case.headings.step_3"),
        ]}
        headerText={t("traits_container.use_case_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep < totalSteps ? (
                  <div className={classes.backBtn}>
                    {!isManageUseCaseFlow ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        classes={{
                          root: globalStyles.btn,
                        }}
                        to="/use-cases"
                      >
                        {t("back")}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        classes={{
                          root: globalStyles.btn,
                        }}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {t("back")}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    {t("back")}
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                disabled={
                  loading ||
                  checkDisabledStatus(currentStep) ||
                  validatingUseCaseName
                }
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const payload = createBackendPayload(
                        useCaseInfo,
                        scope,
                        changedItems.current,
                        isTraitsFlow,
                        useCaseInfo.eventName.title
                      );

                      let data;

                      if (isRequestRevisionFlow) {
                        data = await updateRequest(
                          request.requestId,
                          payload,
                          "use-cases"
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      } else {
                        data = await createUseCase(payload);
                        setUseCaseResponse(
                          data.map((item, index) => ({
                            id: index,
                            requestId: item.requestId,
                            status:
                              item.status[0].toUpperCase() +
                              item.status.slice(1),
                            useCaseName: item.dataUseCase.useCaseName,
                            useCaseDescription:
                              item.dataUseCase.useCaseDescription,
                            scope: item.scope.type,
                            output: item.output,
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    if (currentStep === 1 && !isManageUseCaseFlow) {
                      const errors1 = validateUseCaseInfo(
                        useCaseInfo,
                        isFormDisabled,
                        allLegalEntitiesOptions,
                        module
                      );
                      const errorValues = Object.values(errors1).some(
                        (val) => val
                      );
                      if (!errorValues) {
                        setIsExistingUseCaseError("");
                        const alreadyExisting = await validateUseCaseName();
                        if (alreadyExisting) {
                          return;
                        }
                      } else {
                        setErrors({
                          ...errors,
                          ...errors1,
                        });
                        return;
                      }
                    } else if (currentStep === 1 && isManageUseCaseFlow) {
                      const errors1 = validateUseCaseInfo(
                        useCaseInfo,
                        isFormDisabled,
                        allLegalEntitiesOptions,
                        module
                      );
                      const errorValues = Object.values(errors1).some(
                        (val) => val
                      );
                      if (errorValues) {
                        setErrors(errors1);
                        return;
                      }
                    } else if (currentStep === 2) {
                      const errors2 = validateMappings();
                      const errorValues = Object.values(errors2).some(
                        (val) => val
                      );
                      if (errorValues) {
                        setErrors({
                          ...errors,
                          ...errors2,
                        });
                        return;
                      }
                      setErrors(defaultErrors);
                    }
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {((currentStep === totalSteps && loading) ||
                  (currentStep === 1 && validatingUseCaseName)) && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {createUseCaseConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/use-cases"
                >
                  {t("create_use_case.back_to_use_cases")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${useCaseResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.useCaseContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("create_use_case.trait_removal_warning", {
          scope: scopeMapping[scope],
        })}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          const newTraits = [...traits];
          newTraits.splice(traitSelectedIndex, 1);
          setTraits(newTraits);
          onCloseDeleteModal();
        }}
        type="error"
      />
      <ConfirmationModal
        open={openEventPropertyDeleteModal}
        onClose={() => setOpenEventPropertyDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={t("create_event.event_removal_warning", {
          useCaseName: useCaseInfo.useCaseName,
        })}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          const newEventProperties = [...eventProperties];
          setEventProperties(
            newEventProperties
              .slice(0, eventSelectedIndex)
              .concat(
                newEventProperties
                  .slice(eventSelectedIndex + 1)
                  .map((x) => ({ ...x, index: Number(x.index) - 1 }))
              )
          );
          setOpenEventPropertyDeleteModal(false);
          setEventSelectedIndex(-1);
        }}
        type="error"
      />
      {!isEmpty(useCaseOutput) && (
        <UseCaseRequestOutputModal
          isOpen
          requestId={useCaseResponse[0].requestId}
          data={useCaseOutput}
          onClose={() => setUseCaseOutput({})}
          marketingProgram={useCaseResponse[0].marketingProgram}
          traits={changedItems.current}
          eventProperties={changedItems.current}
          module={
            isTraitsFlow
              ? applicationConfig.modules.traits_use_case
              : applicationConfig.modules.events_use_case
          }
        />
      )}
      <NewTraitModal
        traits={traits}
        setTraits={setTraits}
        labels={labels}
        setLabels={setLabels}
        open={traitModal}
        labelsLoading={labelsLoading}
        newLabelModal={newLabelModal}
        setNewLabelModal={setNewLabelModal}
        traitSelectedIndex={traitSelectedIndex}
        changedItems={changedItems.current}
        handleClose={() => {
          setTraitModal(false);
          setTraitSelectedIndex(-1);
        }}
      />
      {eventPropertyModal && (
        <EventPropertyModal
          setEvents={setEventProperties}
          eventProperties={eventPropertiesOptions?.filter((option) => {
            const selectedEventProperties = eventProperties.map(
              (event) => event.propertyName
            );
            if (selectedEventProperties.includes(option.propertyName)) {
              return false;
            }
            return true;
          })}
          labels={labels}
          setLabels={setLabels}
          labelsLoading={labelsLoading}
          newLabelModal={newLabelModal}
          setNewLabelModal={setNewLabelModal}
          selectedEvent={eventProperties[eventSelectedIndex]}
          setEventProperties={setEventPropertiesOptions}
          open={eventPropertyModal}
          eventSelectedIndex={eventSelectedIndex}
          handleClose={() => {
            setEventPropertyModal(false);
            setEventSelectedIndex(-1);
          }}
          eventName={useCaseInfo.eventName.title}
          denormalize
          hideValueType
        />
      )}
      <Prompt
        message={() => {
          if (isManageUseCaseFlow) {
            return true;
          }
          if (isRequestRevisionFlow) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={!isInfoStep}
      />
    </>
  );
};

export default NewUseCaseContainer;
