import eventsModuleConfig from "../../../../config/eventsModuleConfig";
import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateEqualStrings,
  validateMinimumCharactersCount,
  validateMaximumCharactersCount,
  validateMinimumNonSpaceWords,
  validateAlphaNumericCharactersWithSpace,
  validateSnakeCase,
} from "../../../../utilities/formValidation";

const { createEventConstants } = eventsModuleConfig;

const validateEventsInfo = (eventsInfo, focused, isDisabled) => {
  const errors = {
    eventNameError: null,
    eventNameErrorState: "",
    eventDescriptionError: null,
    eventCategoryError: null,
    actionTakenByError: null,
    eventTypeError: null,
    eventSubTypeError: null,
    gacaEventNameError: null,
  };
  if (isDisabled) {
    return errors;
  }
  // Required field checks
  if (isEmptyString(eventsInfo.eventName) && focused.eventName) {
    errors.eventNameError = i18n.t("errors.field_required");
  }
  if (isEmptyString(eventsInfo.eventDescription) && focused.eventDescription) {
    errors.eventDescriptionError = i18n.t("errors.field_required");
  }
  if (isEmptyString(eventsInfo.eventCategory) && focused.eventCategory) {
    errors.eventCategoryError = i18n.t("errors.field_required");
  }
  if (isEmptyString(eventsInfo.actionTakenBy) && focused.actionTakenBy) {
    errors.actionTakenByError = i18n.t("errors.field_required");
  }
  // Individual field checks
  if (!isEmptyString(eventsInfo.eventName) && focused.eventName) {
    errors.eventNameErrorState = "";
    if (!validateAlphaNumericCharactersWithSpace(eventsInfo.eventName)) {
      errors.eventNameError = i18n.t("common.errors.only_letters_numbers");
    } else if (
      !validateMaximumCharactersCount(
        eventsInfo.eventName,
        createEventConstants.characterLimits.eventName
      )
    ) {
      errors.eventNameError = i18n.t("common.errors.character_limit_error", {
        count: createEventConstants.characterLimits.eventName,
      });
    }
  }
  if (!isEmptyString(eventsInfo.eventDescription) && focused.eventDescription) {
    if (
      validateEqualStrings(eventsInfo.eventName, eventsInfo.eventDescription)
    ) {
      errors.eventDescriptionError = i18n.t(
        "create_event.errors.event_description.error_1"
      );
    } else if (
      !validateMinimumCharactersCount(eventsInfo.eventDescription, 5)
    ) {
      errors.eventDescriptionError = i18n.t(
        "create_event.errors.event_description.error_2"
      );
    } else if (!validateMinimumNonSpaceWords(eventsInfo.eventDescription, 3)) {
      errors.eventDescriptionError = i18n.t(
        "create_event.errors.event_description.error_3"
      );
    }
  }
  if (!isEmptyString(eventsInfo.eventType) && focused.eventType) {
    if (!validateAlphaNumericCharactersWithSpace(eventsInfo.eventType)) {
      errors.eventTypeError = i18n.t("common.errors.only_letters_numbers");
    } else if (
      !validateMaximumCharactersCount(
        eventsInfo.eventType,
        createEventConstants.characterLimits.eventType
      )
    ) {
      errors.eventTypeError = i18n.t("common.errors.character_limit_error", {
        count: createEventConstants.characterLimits.eventType,
      });
    }
  }
  if (!isEmptyString(eventsInfo.gacaEventName) && focused.gacaEventName) {
    if (!validateSnakeCase(eventsInfo.gacaEventName)) {
      errors.gacaEventNameError = i18n.t("create_event.errors.gaca_event_name");
    }
  }
  return errors;
};

export default validateEventsInfo;
