import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    inputContainer: {
      "& .MuiTypography-root": {
        fontSize: "14px",
        color: `${theme.colorTheme.black}`,
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    grid: {
      display: "grid",
      gridGap: "20px",
      gridTemplateColumns: "repeat(auto-fill, minmax(600px, 1fr))",
      "& .MuiAutocomplete-root": {
        width: "auto !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
  };
});

export default useStyles;
