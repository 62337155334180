import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    myRequestsTable: {
      minHeight: 70,
      height: "100%",
      maxHeight: "calc(100vh - 300px)",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
      "& h6": {
        fontSize: "0.875rem",
        fontWeight: "400",
      },
    },
  };
});

export default useStyles;
