import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    modalHeaderWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    MuiPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minWidth: "807px !important",
      maxWidth: "807px !important",
      padding: "20px 25px",
      overflowY: "unset !important",
      "& .MuiTypography-h5": {
        marginBottom: "15px",
        fontSize: "20px",
        color: theme.colorTheme.grey,
        fontWeight: "500",
      },
      "& .MuiDialogContent-root": {
        maxHeight: "810px",
        padding: "0px !important",
        overflowY: "unset !important",
        overflowX: "unset !important",
      },
      "& .MuiDialogActions-root": {
        padding: "0px !important",
      },
      "& .MuiFormHelperText-contained": {
        margin: "0px !important",
        marginTop: "5px !important",
      },
    },
    inputContainer: {
      "& .MuiTypography-root": {
        marginBottom: "6px",
        fontSize: "14px",
        color: `${theme.colorTheme.modalTitle} !important`,
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
    },
    horizontalBar: {
      height: "1px",
      backgroundColor: theme.colorTheme.greyishWhite,
      marginLeft: "-25px",
      width: "calc(100% + 50px)",
      marginBottom: "14px",
    },
    columnGrid: {
      display: "grid",
      gridGap: "20px",
      "& .MuiAutocomplete-root": {
        width: "auto !important",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      gridTemplateColumns: "repeat(2, 1fr) !important",
    },
    fieldContainer: {
      justifyContent: "space-between",
      marginBottom: "16px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        fontWeight: "400",
      },
      "& input,& textarea, & .MuiInputBase-root": {
        minWidth: "558px",
        maxWidth: "558px",
      },
      "& .MuiSelect-root": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      "& .MuiFormControl-root": {
        marginRight: "10px",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
  };
});

export default useStyles;
