import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    accordionContent: {
      padding: 16,
      paddingBottom: 0,
      borderTop: `1px solid ${theme.colorTheme.greyishWhite}`,
      background: theme.colorTheme.white1,
      margin: "0 !important",
      display: "flex",
      flexDirection: "column",
    },
    fullWidth: {
      width: "100%",
    },
    statusLabel: {
      marginLeft: "auto",
    },
    deleted: {
      backgroundColor: theme.palette.error.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    accordionChips: {
      "& .MuiAvatar-root": {
        fontSize: "8px",
      },
    },
  };
});

export default useStyles;
