import { Typography, Button } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const SelectedSource = ({ isNewSource, sourceName, onClick, onDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.rootContainer}>
      <Typography variant="h5">{t("common.labels.segment_source")}</Typography>
      <div className={classes.flexContainer}>
        <div className={clsx(classes.container)}>
          {isNewSource && (
            <Button variant="contained">{t("common.new")}</Button>
          )}
          <Typography variant="h6">{sourceName}</Typography>
        </div>
        <div
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
          tabIndex={0}
          className={clsx(
            classes.btnContainer,
            isNewSource ? "edit" : "delete"
          )}
          data-testid="btn-1"
        >
          {isNewSource ? <EditOutlinedIcon /> : <DeleteOutlineOutlinedIcon />}
        </div>
        {isNewSource && (
          <div
            onClick={onDelete}
            onKeyDown={onDelete}
            role="button"
            tabIndex={0}
            data-testid="btn-2"
            className={clsx(classes.btnContainer, "delete")}
          >
            <DeleteOutlineOutlinedIcon />
          </div>
        )}
      </div>
    </div>
  );
};

SelectedSource.defaultProps = {
  isNewSource: false,
};

SelectedSource.propTypes = {
  isNewSource: PropTypes.bool,
  sourceName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SelectedSource;
