import applicationConfig from "../../../../config/applicationConfig";
import i18n from "../../../../i18n/init";

import {
  isEmptyString,
  validateAlphaNumericCharactersWithSpace,
} from "../../../../utilities/formValidation";

const validateNewMarketingProgram = (
  newMarketingProgram,
  isDisabled,
  apiValidationErrors
) => {
  const errors = {
    brandNameError: null,
    brandShortNameError: null,
    marketingProgramNameError: null,
    marketingProgramDescriptionError: null,
    mpBusinessNameError: null,
    legalEntityError: null,
    startingEcosystemsError: null,
    startingChannelsError: null,
  };

  if (isDisabled) {
    return errors;
  }

  // Give Preference to API validation Errors
  if (apiValidationErrors && apiValidationErrors.length > 0) {
    apiValidationErrors.forEach((error) => {
      let { param } = error;
      if (param) {
        param = param.split(".")[param.split(".").length - 1];
        if (param === applicationConfig.common.description) {
          errors.marketingProgramDescriptionError = error.msg;
        } else if (param === applicationConfig.common.marketing_program_name) {
          errors.marketingProgramNameError = error.msg;
        } else if (param === applicationConfig.common.mp_business_name) {
          errors.mpBusinessNameError = error.msg;
        }
      }
    });
  }

  if (!isEmptyString(newMarketingProgram.brandName)) {
    const specialCharacters = "+&-()";
    if (
      !validateAlphaNumericCharactersWithSpace(
        newMarketingProgram.brandName.trim(),
        specialCharacters
      )
    ) {
      errors.brandNameError = i18n.t(
        "marketing_programs_container.errors.brand_name",
        {
          characters: specialCharacters,
        }
      );
    }
  } else {
    errors.brandNameError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  }

  if (!isEmptyString(newMarketingProgram.brandShortName)) {
    const specialCharacters = "+&-()";
    if (
      !validateAlphaNumericCharactersWithSpace(
        newMarketingProgram.brandShortName.trim(),
        specialCharacters
      )
    ) {
      errors.brandShortNameError = i18n.t(
        "marketing_programs_container.errors.brand_short_name",
        {
          characters: specialCharacters,
        }
      );
    }
  } else {
    errors.brandShortNameError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  }

  if (!newMarketingProgram.legalEntity) {
    errors.legalEntityError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  } else {
    errors.legalEntityError = null;
  }

  if (newMarketingProgram.startingEcosystems.length === 0) {
    errors.startingEcosystemsError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  } else {
    errors.startingEcosystemsError = null;
  }

  if (newMarketingProgram.businessOrgUnit?.length === 0) {
    errors.businessOrgUnitError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  } else {
    errors.businessOrgUnitError = null;
  }

  if (newMarketingProgram.startingChannels.length === 0) {
    errors.startingChannelsError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  } else {
    errors.startingChannelsError = null;
  }

  const countryCode = newMarketingProgram.legalEntity
    ? newMarketingProgram.legalEntity.legalEntityShortName
    : "";

  if (!isEmptyString(newMarketingProgram.marketingProgramName)) {
    const text = newMarketingProgram.marketingProgramName.split("-");
    const integer = parseInt(text[2], 10) && text[2].length === 1;
    if (text.length !== 3 || text[1] !== countryCode || !integer) {
      errors.marketingProgramNameError = i18n.t(
        "marketing_programs_container.errors.marketing_program_name",
        {
          countryCode,
        }
      );
    }
  } else {
    errors.marketingProgramNameError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  }

  if (!isEmptyString(newMarketingProgram.marketingProgramDescription)) {
    const text = newMarketingProgram.marketingProgramDescription.split(" ");
    if (text[0] !== countryCode) {
      errors.marketingProgramDescriptionError = i18n.t(
        "marketing_programs_container.errors.marketing_program_description",
        {
          countryCode,
        }
      );
    }
  } else {
    errors.marketingProgramDescriptionError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  }

  if (isEmptyString(newMarketingProgram.marketingProgramBusinessName)) {
    errors.mpBusinessNameError = i18n.t(
      "marketing_programs_container.errors.required"
    );
  }

  return errors;
};

export default validateNewMarketingProgram;
