import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    MuiPaper: {
      minWidth: "1200px !important",
      height: "512px !important",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    skeletonContainer: {
      margin: "32px",
    },
    middleSkeleton: {
      marginTop: "24px",
    },
    lastSkeleton: {
      marginTop: "32px",
    },
  };
});

export default useStyles;
