import api from "../utilities/api";

import { getValue } from "../utilities/cache";

const getOptMarketingPrograms = async (optId) => {
  const apiUrl = `/opts/${optId}/marketing-program-opts`;
  const refreshCache = getValue("opts");
  const {
    data: { data },
  } = await api.get(apiUrl, { params: { refreshCache } });

  return data;
};

export default getOptMarketingPrograms;
