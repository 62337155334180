import { createContext, useState, useCallback } from "react";

import PropTypes from "prop-types";

export const UserProfileContext = createContext({
  user: { access: [], loading: true },
  removeUser: () => {},
  setUser: () => {},
});

export default function UserProfileProvider({ children }) {
  const [user, setUser] = useState({ access: [], loading: true });

  const removeUser = () => setUser({ access: [], loading: false });

  const contextValue = {
    user,
    removeUser: useCallback(() => removeUser(), []),
    setUser: useCallback((userData) => setUser(userData), []),
  };

  return (
    <UserProfileContext.Provider value={contextValue}>
      {children}
    </UserProfileContext.Provider>
  );
}

UserProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
