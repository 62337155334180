const createBackendPayload = (
  optInfo,
  optMappings,
  optIdValue,
  optsMappingInfo
) => {
  let opt = {};
  let marketingProgram = {};
  if (optIdValue.new) {
    opt = {
      optTextLocalLanguage: optInfo.optTextLocal,
      optTextEnglish: optInfo.optTextEnglish,
    };
    marketingProgram = {
      marketingProgramNumber:
        optInfo.ciamMarketingProgram.marketingProgramNumber,
      marketingProgramName: optInfo.ciamMarketingProgram.marketingProgramName,
      description: optInfo.ciamMarketingProgram.description,
    };
  } else {
    opt = {
      optTextLocalLanguage: optsMappingInfo.optTextLocal,
      optTextEnglish: optsMappingInfo.optText,
      optId: optsMappingInfo.optId,
    };
    marketingProgram = {
      marketingProgramNumber: optsMappingInfo.marketingProgramNumber,
      marketingProgramName: `${optsMappingInfo.marketingProgramNumber} - ${optsMappingInfo.marketingProgramDescription}`,
      description: optsMappingInfo.marketingProgramDescription,
    };
  }
  const payload = {
    opt,
    marketingProgram,
    marketingProgramOpts: optMappings.map((mapping) => {
      let optMapping = {
        primaryIndicator: mapping.primaryIndicator,
        marketingProgram: {
          marketingProgramNumber:
            mapping.cdpMarketingProgram.marketingProgramNumber,
          marketingProgramName:
            mapping.cdpMarketingProgram.marketingProgramName,
          description: mapping.cdpMarketingProgram.description,
        },
        contactPointType: {
          contactPointTypeCode: mapping.channel.contact_point_type_code,
          contactPointTypeName: mapping.channel.contact_point_type_name,
        },
        contactPointCategory: {
          contactPointCategoryCode:
            mapping.contactPointCategoryCode.contact_point_category_code,
          description: mapping.contactPointCategoryCode.description,
        },
        subscriptionOptNumber: parseInt(mapping.subscriptionOptNumber, 10),
      };
      if (typeof mapping.serviceName === "object") {
        optMapping = {
          ...optMapping,
          service: {
            isNew: true,
            serviceName: mapping.serviceName.serviceName,
            serviceNameDescription: mapping.serviceName.serviceNameDescription,
          },
        };
      } else {
        optMapping = {
          ...optMapping,
          service: {
            isNew: false,
            serviceName: mapping.serviceName,
          },
        };
      }
      return optMapping;
    }),
  };
  return payload;
};

export default createBackendPayload;
