import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      padding: "0px",
      maxWidth: "994px",
      marginTop: "75px",
      "& .heading": {
        color: theme.colorTheme.black,
        whiteSpace: "nowrap",
      },
    },
    step: {
      "& .MuiStepIcon-root.MuiStepIcon-completed": {
        color: theme.palette.green.main,
      },
    },
    stepInfoWrapper: {
      margin: "10px 0",
    },
    container1400: {
      maxWidth: "1100px",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    header: {
      marginTop: theme.spacing(14),
      marginBottom: "60px",
      textAlign: "center",
      "& .MuiTypography-h4": {
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginBottom: "32px",
      },
      "& .MuiTypography-h6": {
        fontWeight: "normal",
        color: theme.colorTheme.modalTitle,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.colorTheme.white,
    },
    paperColor: {
      backgroundColor: theme.palette.background.default,
    },
    paper: {
      padding: "20px",
      border: `1px solid ${theme.colorTheme.greyishWhite}`,
      boxShadow: `0px 2px 3px ${theme.colorTheme.greyishWhite1}`,
      minHeight: "484px",
      "& .title": {
        marginBottom: "20px",
      },
    },
    paperHeight: {
      minHeight: "100px",
    },
    traitHeader: {
      padding: "20px",
      borderBottom: `1px solid ${theme.colorTheme.greyishWhite}`,
    },
    traitHeaderLeft: {
      "& h6": {
        fontSize: "14px",
      },
      "& p": {
        fontSize: "14px",
      },
      "& p:first-child": {
        color: theme.colorTheme.modalTitle,
        fontWeight: "bold",
        marginRight: "8px",
      },
    },
    traitContainer: {
      padding: "40px 20px",
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.primary.light,
        color: "white",
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: "white",
          width: "20px",
          height: "20px",
        },
      },
    },
    paddingZero: {
      padding: "0px",
    },
    marketingProgramContainer: {
      marginLeft: "40px",
      "& > div:first-child": {
        marginBottom: "40px",
      },
    },
    progressComplete: {
      width: "80px",
      height: "21px",
      borderRadius: "12px",
      backgroundColor: theme.palette.green.main,
      justifyContent: "center",
      alignItems: "center",
      "& svg": {
        width: "16px",
        height: "16px",
        color: "white",
        position: "relative",
        bottom: "1px",
        marginRight: "3px",
      },
      "& h6": {
        fontSize: "12px",
        color: theme.colorTheme.white,
      },
    },
    progressFailed: {
      backgroundColor: theme.palette.error.main,
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.greyishBlack,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        fontWeight: "bold",
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    footer: {
      width: "100%",
      marginTop: "40px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& button:last-child": {
        marginLeft: "auto",
      },
    },
  };
});

export default useStyles;
