import { Dialog } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

import PropTypes from "prop-types";

import useStyles from "./styles";

const RequestModal = ({ isLoading, open, children, className }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{
        paper: clsx(className, classes.MuiPaper),
      }}
    >
      {isLoading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton variant="text" width={200} height={26} />
          <Skeleton
            variant="text"
            width={300}
            height={33}
            className={classes.middleSkeleton}
          />
          <Skeleton
            variant="rect"
            height={118}
            className={classes.lastSkeleton}
          />
        </div>
      ) : (
        children
      )}
    </Dialog>
  );
};

RequestModal.defaultProps = {
  open: false,
  isLoading: false,
  className: "",
};

RequestModal.propTypes = {
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default RequestModal;
