import { useEffect, useState } from "react";

import {
  Typography,
  TextField,
  OutlinedInput,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Button,
  FormHelperText,
  Chip,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import AutoComplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CustomModal from "../../../../components/CustomModal";

import applicationConfig from "../../../../config/applicationConfig";
import i18n from "../../../../i18n/init";
import useStyles from "../../UseEventContainer/styles";

const ITEM_HEIGHT = 408;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: "65px",
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function validateEventProperty({
  selectedEventProperty,
  hideValueType,
  selectedValueTypes,
}) {
  const errors = {};
  if (Object.keys(selectedEventProperty ?? {}).length === 0) {
    errors.eventPropertyError = i18n.t("errors.field_required");
  }
  if (!hideValueType && !selectedValueTypes.length) {
    errors.valueTypeError = i18n.t("errors.field_required");
  }
  return errors;
}

const EventPropertyModal = ({
  selectedEvent,
  eventProperties,
  setEventProperties,
  labels,
  eventName,
  setLabels,
  labelsLoading,
  newLabelModal,
  setNewLabelModal,
  open,
  eventSelectedIndex,
  events,
  denormalize,
  handleClose,
  setEvents,
  hideValueType,
  hideLabels,
}) => {
  const classes = useStyles();
  const [selectedEventProperty, setSelectedEventProperty] = useState({});
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [selectedValueTypes, setSelectedValueTypes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newLabel, setNewLabel] = useState("");
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      setSelectedEventProperty({});
      setSelectedValueTypes([]);
      setSelectedLabels([]);
    }
  }, [open]);

  useEffect(() => {
    if (Object.keys(selectedEvent).length !== 0) {
      setSelectedEventProperty({
        ...selectedEvent,
        propertyName:
          selectedEvent.eventProperty?.propertyName ||
          selectedEvent.propertyName ||
          selectedEvent.eventProperty,
        title:
          selectedEvent.eventProperty?.propertyName ||
          selectedEvent.propertyName ||
          selectedEvent.eventProperty,
      });
      setSelectedValueTypes(selectedEvent.valueTypes || []);
      setSelectedLabels(selectedEvent.selectedLabels);
    }
  }, [selectedEvent]);

  function checkForErrors() {
    const errs = validateEventProperty({
      selectedEventProperty,
      hideValueType,
      selectedValueTypes,
    });
    if (Object.keys(errs).length > 0) {
      setErrors(errs);
    }
    return Object.keys(errs).length === 0;
  }

  function clearError(field) {
    if (errors?.[field]) {
      setErrors((errs) => {
        const newErrors = { ...errs };
        delete newErrors[field];
        return newErrors;
      });
    }
  }

  return (
    <>
      <CustomModal
        open={open}
        onClose={handleClose}
        title={t("use_events_container.select_property")}
        showCloseIcon
      >
        <div className={classes.eventPropertyModalField}>
          <Typography>{t("use_events_container.property_name")}</Typography>
          <div style={{ width: "80%" }}>
            <AutoComplete
              disablePortal
              disabled={eventProperties[eventSelectedIndex]}
              id="event-property"
              data-testid="event-property"
              value={selectedEventProperty}
              getOptionLabel={(option) => {
                if (option.title) {
                  return option.title.toString();
                }
                return "";
              }}
              options={eventProperties.map((property) => ({
                ...property,
                title: property.propertyName,
              }))}
              fullWidth
              onChange={(_, value) => setSelectedEventProperty(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  data-testid="property-input"
                  placeholder={t(
                    "use_events_container.placeholders.eventPropertyName"
                  )}
                />
              )}
              onBlur={() => checkForErrors()}
              onFocus={() => clearError("eventPropertyError")}
            />
            {errors.eventPropertyError && (
              <FormHelperText error>{errors.eventPropertyError}</FormHelperText>
            )}
          </div>
        </div>
        {!hideLabels && (
          <div className={classes.eventPropertyModalField}>
            <Typography>{t("use_events_container.labels")}</Typography>
            <div style={{ width: "80%" }}>
              <AutoComplete
                multiple
                id="labels-input"
                options={labels}
                className={classes.labelsInput}
                disableCloseOnSelect
                value={selectedLabels}
                defaultValue={selectedLabels}
                loading={labelsLoading}
                onChange={(_, value) => setSelectedLabels(value)}
                getOptionSelected={(option, value) =>
                  option.type !== "div" ? option.title === value.title : false
                }
                getOptionLabel={(option) =>
                  option.type !== "div" ? option.title : ""
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      color="primary"
                      label={option.title}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderOption={(option, { selected }) => {
                  return option.type !== "div" ? (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                      {!option.labelId && (
                        <Button className={classes.newOptionTag}>NEW</Button>
                      )}
                    </>
                  ) : (
                    option
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      selectedLabels && selectedLabels?.length > 0
                        ? ""
                        : t("use_events_container.placeholders.labels")
                    }
                  />
                )}
              />
            </div>
          </div>
        )}
        {!hideValueType && (
          <div className={classes.eventPropertyModalField}>
            <Typography>{t("use_events_container.valueType")}</Typography>
            <div style={{ width: "80%" }}>
              <FormControl fullWidth>
                <Select
                  id="value-type-multi-select"
                  data-testid="value-type-select"
                  multiple
                  open={dropdownOpen}
                  value={selectedValueTypes}
                  onChange={(event) =>
                    setSelectedValueTypes(event.target.value)
                  }
                  input={
                    <OutlinedInput onClick={() => setDropdownOpen((x) => !x)} />
                  }
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  onBlur={() => checkForErrors()}
                  onFocus={() => clearError("valueTypeError")}
                >
                  {[
                    t("use_events_container.latest"),
                    t("use_events_container.all"),
                  ].map((valueType) => (
                    <MenuItem key={valueType} value={valueType}>
                      <Checkbox
                        color="primary"
                        onClick={() => setDropdownOpen(false)}
                        checked={selectedValueTypes.indexOf(valueType) > -1}
                      />
                      <ListItemText primary={valueType} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.valueTypeError && (
                <FormHelperText error>{errors.valueTypeError}</FormHelperText>
              )}
            </div>
          </div>
        )}

        <div className={classes.eventPropertyModalFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const errs = validateEventProperty({
                selectedEventProperty,
                hideValueType,
                selectedValueTypes,
              });
              if (Object.keys(errs).length > 0) {
                setErrors(errs);
                return;
              }
              const eventProperty = {
                ...selectedEvent,
                ...selectedEventProperty,
                eventName,
                title: eventName,
                eventProperty: selectedEventProperty.propertyName,
                eventPropertyDescription: selectedEventProperty.description,
                isSelected: true,
                selectedLabels,
                selectedValueTypes,
              };

              if (
                events?.find(
                  (event) =>
                    event.eventProperty.propertyName ===
                    selectedEventProperty.propertyName
                )
              ) {
                return;
              }

              if (hideLabels) {
                let changeState = (cs) => [
                  ...cs,
                  denormalize
                    ? {
                        ...selectedEventProperty,
                        eventName,
                        index: cs.length,
                      }
                    : {
                        eventProperty: {
                          ...selectedEventProperty,
                          title: eventName,
                          eventProperty: selectedEventProperty.propertyName,
                          eventPropertyDescription:
                            selectedEventProperty.description,
                          eventName,
                        },
                        valueTypes: selectedValueTypes,
                      },
                ];

                if (applicationConfig.common.event_property in selectedEvent) {
                  changeState = (cs) =>
                    cs.map((event) => {
                      if (
                        event.eventProperty.propertyName ===
                        selectedEvent.eventProperty.propertyName
                      ) {
                        return {
                          ...selectedEventProperty,
                          valueTypes: selectedValueTypes,
                        };
                      }
                      return event;
                    });
                  setEventProperties((cs) => [
                    ...cs,
                    selectedEvent.eventProperty,
                  ]);
                }

                setEvents(changeState);
              } else {
                let cb = (x) => x;

                const beingUpdated = (a, b) => {
                  if (
                    Array.isArray(a) &&
                    Array.isArray(b) &&
                    a.length !== b.length
                  ) {
                    return true;
                  }

                  if (!a.every((x) => b.some((y) => y.title === x.title))) {
                    return true;
                  }

                  return false;
                };

                if (
                  Object.keys(selectedEvent).length !== 0 &&
                  !("new" in eventProperty)
                ) {
                  const existingLabels = selectedEventProperty.labels || [];
                  let isUpdated = false;

                  if (
                    beingUpdated(existingLabels || [], selectedLabels || [])
                  ) {
                    isUpdated = true;
                  }

                  cb = (x) =>
                    x.map((y) => {
                      if (
                        y.propertyName === selectedEventProperty.propertyName
                      ) {
                        return {
                          ...eventProperty,
                          isUpdated,
                        };
                      }

                      return y;
                    });
                } else if (eventProperty && eventProperty.new) {
                  cb = (x) =>
                    x.map((y) => {
                      if (
                        y.propertyName === selectedEventProperty.propertyName
                      ) {
                        return {
                          ...eventProperty,
                          new: true,
                          labels: selectedLabels,
                        };
                      }

                      return y;
                    });
                } else {
                  cb = (x) => [
                    ...x,
                    {
                      index: x.length,
                      new: true,
                      labels: selectedLabels,
                      ...eventProperty,
                    },
                  ];
                }

                setEvents(cb);
                handleClose();
              }
            }}
          >
            {t("common.submit")}
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        open={newLabelModal}
        onClose={() => setNewLabelModal(false)}
        title="New Label"
      >
        <div className={classes.newLabelContainer}>
          <TextField
            id="outlined-basic"
            label="Label Name"
            variant="outlined"
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
          />
        </div>
        <div className={classes.eventPropertyModalFooter}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setNewLabelModal(false)}
          >
            {t("common.close")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={newLabel.length === 0}
            onClick={() => {
              const newData = {
                labelName: newLabel,
                labelValue: newLabel,
                title: newLabel,
              };
              setLabels((x) => [...x.slice(0, -1), newData, x.at(-1)]);
              setSelectedLabels((x) => [...x, newData]);
              setNewLabelModal(false);
            }}
          >
            {t("use_events_container.confirm")}
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

EventPropertyModal.defaultProps = {
  open: false,
  eventSelectedIndex: -1,
  selectedEvent: {},
  hideValueType: false,
  eventName: "",
  labels: [],
  denormalize: false,
  hideLabels: false,
};

EventPropertyModal.propTypes = {
  open: PropTypes.bool,
  eventSelectedIndex: PropTypes.number,
  events: PropTypes.array.isRequired,
  selectedEvent: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  setEventProperties: PropTypes.func.isRequired,
  eventProperties: PropTypes.array.isRequired,
  setEvents: PropTypes.func.isRequired,
  hideValueType: PropTypes.bool,
  hideLabels: PropTypes.bool,
  eventName: PropTypes.string,
  denormalize: PropTypes.bool,
  labels: PropTypes.array,
  setLabels: PropTypes.func.isRequired,
  labelsLoading: PropTypes.bool.isRequired,
  newLabelModal: PropTypes.bool.isRequired,
  setNewLabelModal: PropTypes.func.isRequired,
};

export default EventPropertyModal;
