const languages = [
  {
    languageCode: "Aa",
    languageName: "Afar",
  },
  {
    languageCode: "ab",
    languageName: "Abkhazian",
  },
  {
    languageCode: "ae",
    languageName: "Avestan",
  },
  {
    languageCode: "af",
    languageName: "Afrikaans",
  },
  {
    languageCode: "am",
    languageName: "Amharic",
  },
  {
    languageCode: "an",
    languageName: "Aragonese",
  },
  {
    languageCode: "ar",
    languageName: "Arabic",
  },
  {
    languageCode: "as",
    languageName: "Assamese",
  },
  {
    languageCode: "ay",
    languageName: "Aymara",
  },
  {
    languageCode: "az",
    languageName: "Azerbaijani",
  },
  {
    languageCode: "ba",
    languageName: "Bashkir",
  },
  {
    languageCode: "be",
    languageName: "Belarusian",
  },
  {
    languageCode: "bg",
    languageName: "Bulgarian",
  },
  {
    languageCode: "bh",
    languageName: "Bihari",
  },
  {
    languageCode: "bi",
    languageName: "Bislama",
  },
  {
    languageCode: "bn",
    languageName: "Bengali",
  },
  {
    languageCode: "bo",
    languageName: "Tibetan",
  },
  {
    languageCode: "br",
    languageName: "Breton",
  },
  {
    languageCode: "bs",
    languageName: "Bosnian",
  },
  {
    languageCode: "ca",
    languageName: "Catalan",
  },
  {
    languageCode: "ce",
    languageName: "Chechen",
  },
  {
    languageCode: "ch",
    languageName: "Chamorro",
  },
  {
    languageCode: "co",
    languageName: "Corsican",
  },
  {
    languageCode: "cs",
    languageName: "Czech",
  },
  {
    languageCode: "cu",
    languageName: "ChurchSlavic;Slavonic;OldBulgarian",
  },
  {
    languageCode: "cv",
    languageName: "Chuvash",
  },
  {
    languageCode: "cy",
    languageName: "Welsh",
  },
  {
    languageCode: "da",
    languageName: "Danish",
  },
  {
    languageCode: "de",
    languageName: "German",
  },
  {
    languageCode: "dv",
    languageName: "Divehi;Dhivehi;Maldivian",
  },
  {
    languageCode: "dz",
    languageName: "Dzongkha",
  },
  {
    languageCode: "el",
    languageName: "Greek;Modern(1453-)",
  },
  {
    languageCode: "en",
    languageName: "English",
  },
  {
    languageCode: "eo",
    languageName: "Esperanto",
  },
  {
    languageCode: "es",
    languageName: "Spanish;Castilian",
  },
  {
    languageCode: "et",
    languageName: "Estonian",
  },
  {
    languageCode: "eu",
    languageName: "Basque",
  },
  {
    languageCode: "fa",
    languageName: "Persian",
  },
  {
    languageCode: "fi",
    languageName: "Finnish",
  },
  {
    languageCode: "fj",
    languageName: "Fijian",
  },
  {
    languageCode: "fo",
    languageName: "Faroese",
  },
  {
    languageCode: "fr",
    languageName: "French",
  },
  {
    languageCode: "fy",
    languageName: "WesternFrisian",
  },
  {
    languageCode: "ga",
    languageName: "Irish",
  },
  {
    languageCode: "gd",
    languageName: "Gaelic;ScottishGaelic",
  },
  {
    languageCode: "gl",
    languageName: "Galician",
  },
  {
    languageCode: "gn",
    languageName: "Guarani",
  },
  {
    languageCode: "gu",
    languageName: "Gujarati",
  },
  {
    languageCode: "gv",
    languageName: "Manx",
  },
  {
    languageCode: "ha",
    languageName: "Hausa",
  },
  {
    languageCode: "he",
    languageName: "Hebrew",
  },
  {
    languageCode: "hi",
    languageName: "Hindi",
  },
  {
    languageCode: "ho",
    languageName: "HiriMotu",
  },
  {
    languageCode: "hr",
    languageName: "Croatian",
  },
  {
    languageCode: "ht",
    languageName: "Haitian;HaitianCreole",
  },
  {
    languageCode: "hu",
    languageName: "Hungarian",
  },
  {
    languageCode: "hy",
    languageName: "Armenian",
  },
  {
    languageCode: "hz",
    languageName: "Herero",
  },
  {
    languageCode: "ia",
    languageName: "Interlingua(InternationalAuxiliaryLanguageAssociation)",
  },
  {
    languageCode: "id",
    languageName: "Indonesian",
  },
  {
    languageCode: "ie",
    languageName: "Interlingue",
  },
  {
    languageCode: "ii",
    languageName: "SichuanYi",
  },
  {
    languageCode: "ik",
    languageName: "Inupiaq",
  },
  {
    languageCode: "io",
    languageName: "Ido",
  },
  {
    languageCode: "is",
    languageName: "Icelandic",
  },
  {
    languageCode: "it",
    languageName: "Italian",
  },
  {
    languageCode: "iu",
    languageName: "Inuktitut",
  },
  {
    languageCode: "ja",
    languageName: "Japanese",
  },
  {
    languageCode: "jv",
    languageName: "Javanese",
  },
  {
    languageCode: "ka",
    languageName: "Georgian",
  },
  {
    languageCode: "ki",
    languageName: "Kikuyu;Gikuyu",
  },
  {
    languageCode: "kj",
    languageName: "Kuanyama;Kwanyama",
  },
  {
    languageCode: "kk",
    languageName: "Kazakh",
  },
  {
    languageCode: "kl",
    languageName: "Kalaallisut",
  },
  {
    languageCode: "km",
    languageName: "Khmer",
  },
  {
    languageCode: "kn",
    languageName: "Kannada",
  },
  {
    languageCode: "ko",
    languageName: "Korean",
  },
  {
    languageCode: "ks",
    languageName: "Kashmiri",
  },
  {
    languageCode: "ku",
    languageName: "Kurdish",
  },
  {
    languageCode: "kv",
    languageName: "Komi",
  },
  {
    languageCode: "kw",
    languageName: "Cornish",
  },
  {
    languageCode: "ky",
    languageName: "Kirghiz",
  },
  {
    languageCode: "la",
    languageName: "Latin",
  },
  {
    languageCode: "lb",
    languageName: "Luxembourgish;Letzeburgesch",
  },
  {
    languageCode: "li",
    languageName: "Limburgan;Limburger;Limburgish",
  },
  {
    languageCode: "ln",
    languageName: "Lingala",
  },
  {
    languageCode: "lo",
    languageName: "Lao",
  },
  {
    languageCode: "lt",
    languageName: "Lithuanian",
  },
  {
    languageCode: "lv",
    languageName: "Latvian",
  },
  {
    languageCode: "mg",
    languageName: "Malagasy",
  },
  {
    languageCode: "mh",
    languageName: "Marshallese",
  },
  {
    languageCode: "mi",
    languageName: "Maori",
  },
  {
    languageCode: "mk",
    languageName: "Macedonian",
  },
  {
    languageCode: "ml",
    languageName: "Malayalam",
  },
  {
    languageCode: "mn",
    languageName: "Mongolian",
  },
  {
    languageCode: "mo",
    languageName: "Moldavian",
  },
  {
    languageCode: "mr",
    languageName: "Marathi",
  },
  {
    languageCode: "ms",
    languageName: "Malay",
  },
  {
    languageCode: "mt",
    languageName: "Maltese",
  },
  {
    languageCode: "my",
    languageName: "Burmese",
  },
  {
    languageCode: "na",
    languageName: "Nauru",
  },
  {
    languageCode: "nb",
    languageName: "NorwegianBokmal",
  },
  {
    languageCode: "nd",
    languageName: "Ndebele;North",
  },
  {
    languageCode: "ne",
    languageName: "Nepali",
  },
  {
    languageCode: "ng",
    languageName: "Ndonga",
  },
  {
    languageCode: "nl",
    languageName: "Dutch",
  },
  {
    languageCode: "nn",
    languageName: "NorwegianNynorsk",
  },
  {
    languageCode: "no",
    languageName: "Norwegian",
  },
  {
    languageCode: "nr",
    languageName: "Ndebele;South",
  },
  {
    languageCode: "nv",
    languageName: "Navaho;Navajo",
  },
  {
    languageCode: "ny",
    languageName: "Nyanja;Chichewa;Chewa",
  },
  {
    languageCode: "oc",
    languageName: "Occitan(post1500);Provencal",
  },
  {
    languageCode: "om",
    languageName: "Oromo",
  },
  {
    languageCode: "or",
    languageName: "Oriya",
  },
  {
    languageCode: "os",
    languageName: "Ossetian;Ossetic",
  },
  {
    languageCode: "pa",
    languageName: "Panjabi",
  },
  {
    languageCode: "pi",
    languageName: "Pali",
  },
  {
    languageCode: "pl",
    languageName: "Polish",
  },
  {
    languageCode: "ps",
    languageName: "Pushto",
  },
  {
    languageCode: "pt",
    languageName: "Portuguese",
  },
  {
    languageCode: "qu",
    languageName: "Quechua",
  },
  {
    languageCode: "rm",
    languageName: "Raeto-Romance",
  },
  {
    languageCode: "rn",
    languageName: "Rundi",
  },
  {
    languageCode: "ro",
    languageName: "Romanian",
  },
  {
    languageCode: "ru",
    languageName: "Russian",
  },
  {
    languageCode: "rw",
    languageName: "Kinyarwanda",
  },
  {
    languageCode: "sa",
    languageName: "Sanskrit",
  },
  {
    languageCode: "sc",
    languageName: "Sardinian",
  },
  {
    languageCode: "sd",
    languageName: "Sindhi",
  },
  {
    languageCode: "se",
    languageName: "NorthernSami",
  },
  {
    languageCode: "sg",
    languageName: "Sango",
  },
  {
    languageCode: "si",
    languageName: "Sinhala;Sinhalese",
  },
  {
    languageCode: "sk",
    languageName: "Slovak",
  },
  {
    languageCode: "sl",
    languageName: "Slovenian",
  },
  {
    languageCode: "sm",
    languageName: "Samoan",
  },
  {
    languageCode: "sn",
    languageName: "Shona",
  },
  {
    languageCode: "so",
    languageName: "Somali",
  },
  {
    languageCode: "sq",
    languageName: "Albanian",
  },
  {
    languageCode: "sr",
    languageName: "Serbian",
  },
  {
    languageCode: "ss",
    languageName: "Swati",
  },
  {
    languageCode: "st",
    languageName: "Sotho;Southern",
  },
  {
    languageCode: "su",
    languageName: "Sundanese",
  },
  {
    languageCode: "sv",
    languageName: "Swedish",
  },
  {
    languageCode: "sw",
    languageName: "Swahili",
  },
  {
    languageCode: "ta",
    languageName: "Tamil",
  },
  {
    languageCode: "te",
    languageName: "Telugu",
  },
  {
    languageCode: "tg",
    languageName: "Tajik",
  },
  {
    languageCode: "th",
    languageName: "Thai",
  },
  {
    languageCode: "ti",
    languageName: "Tigrinya",
  },
  {
    languageCode: "tk",
    languageName: "Turkmen",
  },
  {
    languageCode: "tl",
    languageName: "Tagalog",
  },
  {
    languageCode: "tn",
    languageName: "Tswana",
  },
  {
    languageCode: "to",
    languageName: "Tonga(TongaIslands)",
  },
  {
    languageCode: "tr",
    languageName: "Turkish",
  },
  {
    languageCode: "ts",
    languageName: "Tsonga",
  },
  {
    languageCode: "tt",
    languageName: "Tatar",
  },
  {
    languageCode: "tw",
    languageName: "Twi",
  },
  {
    languageCode: "ty",
    languageName: "Tahitian",
  },
  {
    languageCode: "ug",
    languageName: "Uighur",
  },
  {
    languageCode: "uk",
    languageName: "Ukrainian",
  },
  {
    languageCode: "ur",
    languageName: "Urdu",
  },
  {
    languageCode: "uz",
    languageName: "Uzbek",
  },
  {
    languageCode: "vi",
    languageName: "Vietnamese",
  },
  {
    languageCode: "vo",
    languageName: "Volapuk",
  },
  {
    languageCode: "wa",
    languageName: "Walloon",
  },
  {
    languageCode: "wo",
    languageName: "Wolof",
  },
  {
    languageCode: "xh",
    languageName: "Xhosa",
  },
  {
    languageCode: "yi",
    languageName: "Yiddish",
  },
  {
    languageCode: "yo",
    languageName: "Yoruba",
  },
  {
    languageCode: "za",
    languageName: "Zhuang;Chuang",
  },
  {
    languageCode: "zh",
    languageName: "Chinese",
  },
  {
    languageCode: "zu",
    languageName: "Zulu",
  },
];

export default languages;
