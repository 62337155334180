import { useState } from "react";

import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "../../../../../components/Form";
import InlineMessage from "../../../../../components/InlineMessage";

import jobsModuleConfig from "../../../../../config/jobsModuleConfig";

import i18n from "../../../../../i18n/init";

import isEmpty from "../../../../../utilities/isEmpty";
import useImmer from "../../../../../utilities/useImmer";

import useStyles from "./styles";

const NewJobModal = ({
  isOpen,
  title,
  setOpenJobCreationModal,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [jobInfo, setJobInfo] = useImmer({
    jobName: "",
    marketingProgramNumber: "",
    ecosystemSourceIds: "",
  });

  const isMarketingProgramRequired =
    jobInfo.jobName === jobsModuleConfig.jobsList.segmentTrackingPlans &&
    !isEmpty(jobInfo.ecosystemSourceIds) &&
    isEmpty(jobInfo.marketingProgramNumber);

  const [formErrors, setFormErrors] = useState({});

  const resetJobInfo = () => {
    setJobInfo((draft) => {
      draft.jobName = "";
      draft.marketingProgramNumber = "";
      draft.ecosystemSourceIds = "";
    });
  };

  let fields = [
    {
      label: t("common.labels.job"),
      type: "dropdown",
      key: "jobName",
      required: true,
      props: {
        label: !jobInfo.jobName
          ? t("jobs_monitoring_container.select_job")
          : "",
        select: true,
        variant: "outlined",
        onChange: (event) => {
          setFormErrors({});
          setJobInfo((draft) => {
            draft.jobName = event.target.value;
          });
        },
        inputProps: {
          "data-testid": "jobName",
        },
        helperText: (
          <InlineMessage message={formErrors.jobName} state="error" />
        ),
        values: Object.values(jobsModuleConfig.jobsList).map((option) => ({
          label: option,
          value: option,
        })),
        value: jobInfo.jobName,
      },
    },
    {
      type: "text-input",
      key: "marketingProgramNumber",
      label: t("common.labels.marketing_program_number"),
      props: {
        placeholder: t("common.labels.marketing_program_number"),
        onChange: (event) =>
          setJobInfo((draft) => {
            draft.marketingProgramNumber = event.target.value;
          }),
        value: jobInfo.marketingProgramNumber,
        variant: "outlined",
        error: isMarketingProgramRequired,
        helperText: (
          <InlineMessage
            message={
              isMarketingProgramRequired
                ? t("jobs_monitoring_container.errors.marketing_program_number")
                : ""
            }
            state="error"
          />
        ),
        inputProps: {
          "data-testid": "marketing-program-number",
        },
      },
    },
    {
      type: "text-input",
      key: "ecosystemSourceIds",
      label: t(
        "jobs_monitoring_container.create_job_modal.ecosystem_source_id"
      ),
      props: {
        placeholder: t(
          "jobs_monitoring_container.create_job_modal.ecosystem_source_id"
        ),
        onChange: (event) =>
          setJobInfo((draft) => {
            draft.ecosystemSourceIds = event.target.value;
          }),
        value: jobInfo.segmentSource,
        variant: "outlined",
        inputProps: {
          "data-testid": "ecosystem-source-id",
        },
      },
    },
  ];

  fields = fields.filter((field) => {
    if (field.label === fields[0].label) {
      return true;
    }
    if (isEmpty(jobInfo.jobName)) {
      if (field.label === fields[1].label || field.label === fields[2].label) {
        return false;
      }
    }
    if (
      jobInfo.jobName === jobsModuleConfig.jobsList.pubsubTraits ||
      jobInfo.jobName === jobsModuleConfig.jobsList.pubsubOpts ||
      jobInfo.jobName === jobsModuleConfig.jobsList.pubsubMarketingPrograms
    ) {
      return false;
    }
    if (
      jobInfo.jobName === jobsModuleConfig.jobsList.lyticsQueries ||
      jobInfo.jobName === jobsModuleConfig.jobsList.lyticsEvents ||
      jobInfo.jobName === jobsModuleConfig.jobsList.mappEmails ||
      jobInfo.jobName === jobsModuleConfig.jobsList.segmentDestinations ||
      jobInfo.jobName === jobsModuleConfig.jobsList.resciEmails
    ) {
      if (field.label === fields[1].label) {
        return true;
      }
      return false;
    }
    if (jobInfo.jobName === jobsModuleConfig.jobsList.segmentSources) {
      if (field.label === fields[2].label) {
        return true;
      }
      return false;
    }
    return true;
  });

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <DialogContent>
        <div>
          <div className={clsx(classes.flexContainer, classes.flexContainer1)}>
            <Typography variant="h5">{title}</Typography>
            <Button
              className="close-btn"
              onClick={() => {
                resetJobInfo();
                setOpenJobCreationModal(false);
              }}
            >
              <Close />
            </Button>
          </div>
          <div className={classes.horizontalBar} />
          <Form
            fields={fields}
            fieldClassName={clsx(classes.fieldContainer, classes.flexContainer)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const payload = {};
            fields.forEach((field) => {
              payload[field.key] = jobInfo[field.key];
            });
            if (isEmpty(jobInfo.jobName)) {
              setFormErrors({
                jobName: "Please Select Job Name",
              });
              return;
            }
            handleSubmit(payload);
          }}
          color="primary"
          variant="contained"
        >
          {t("common.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewJobModal.defaultProps = {
  isOpen: false,
  setOpenJobCreationModal: () => {},
  title: i18n.t("job_monitoring_container.create_new_job"),
  handleSubmit: () => {},
};

NewJobModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  setOpenJobCreationModal: PropTypes.bool,
  handleSubmit: PropTypes.bool,
};

export default NewJobModal;
