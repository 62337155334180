import { Button } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

import applicationConfig from "../../../../../config/applicationConfig";

import useStyles from "./styles";

const OptsRequestOutputModal = ({
  setShowOptOutput,
  isOpen,
  data,
  requestId,
  isLoading,
  marketingProgramDescription,
}) => {
  const { warnings, items } = data;
  const { t } = useTranslation();

  const classes = useStyles();

  const columns = [
    {
      field: "optId",
      headerName: t("common.labels.opt_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "serviceName",
      headerName: t("common.labels.service_name"),
      flex: 2,
      sortable: false,
      renderCell: (columnData) => {
        const { value } = columnData;
        if (value && value.type === "div") {
          return (
            <div className={clsx(classes.flexContainer, classes.container)}>
              {value}
              <Button variant="contained">{t("common.new")}</Button>
            </div>
          );
        }
        return value;
      },
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  const getWarningMessage = (item) => {
    if (!item.optId) {
      return t("opts_mapping.service_name_marketing_program_error");
    }
    if (item.optId && !item.new) {
      return t("opts_mapping.service_name_marketing_program_combination");
    }
    return item.message;
  };

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    return {
      id: `${warning.optId}-${index}`,
      optId: warning.optId,
      serviceName: warning.isNew ? (
        <div>{warning.serviceName}</div>
      ) : (
        warning.serviceName
      ),
      marketingProgram: warning.marketingProgramNumber
        ? `${warning.marketingProgramNumber} - ${marketingProgramDescription}`
        : "",
      status: applicationConfig.status.warning,
      message: warning.message,
    };
  });

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      if (item.mappingWarning) {
        status = applicationConfig.status.warning;
        message = item.mappingWarning;
      } else if (item.new) {
        status = applicationConfig.status.success;
      } else {
        status = applicationConfig.status.warning;
        message = getWarningMessage(item);
      }
      return {
        id: `${item.optId}-${index}`,
        optId: item.optId,
        serviceName:
          !item.optId && item.new ? (
            <div>{item.serviceName}</div>
          ) : (
            item.serviceName
          ),
        marketingProgram: item.marketingProgramNumber
          ? `${item.marketingProgramNumber} - ${marketingProgramDescription}`
          : "",
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOptOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.opts_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

OptsRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

OptsRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
  }).isRequired,
  requestId: PropTypes.string,
  marketingProgramDescription: PropTypes.string.isRequired,
  setShowOptOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default OptsRequestOutputModal;
