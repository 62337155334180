import { useState, useRef } from "react";

import { Paper, Typography, Button, Tab, Tabs } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import getOptTraits from "../../../api/get-opt-traits";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";

import TraitDetailsModal from "./components/TraitDetailsModal";

import useStyles from "./styles";

const TraitsOptsContainer = () => {
  const classes = useStyles();
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const childRef = useRef();
  const history = useHistory();
  const globalClasses = useGlobalStyles();

  const {
    optTraitsColumns,
    optTraitSearchFilters,
    optsSearchDefaultFilterState,
  } = optsModuleConfig;

  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tab, setTab] = useState(1);

  const [cellData, setCellData] = useState([]);

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData([]);
  };

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchOpts
  );

  const filterFieldMap = {
    traitId: "traitId",
    traitName: "traitName",
    description: "description",
    "": "searchText",
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  return !isUserAuthorized && !user.loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4">Opt Trait Management</Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          value={tab}
          onChange={(_, number) => setTab(number)}
          aria-label="basic tabs"
          classes={{
            indicator: globalClasses.indicator,
          }}
        >
          <Tab label="Opts" onClick={() => history.push("/opts")} />
          <Tab
            label="Opt Traits"
            onClick={() => history.push("/opts?displayTab=opt-traits")}
          />
        </Tabs>
        <div className={clsx(classes.marginLeftAuto, classes.flexContainer)}>
          {checkUserAuthorization(user.access, pageAccessConfig.createOpts) && (
            <Button
              onClick={() => {
                history.push(applicationConfig.pathnames.opts_status_manage, {
                  state: {},
                });
              }}
              variant="outlined"
              className={classes.newOptIdBtn}
            >
              MANAGE OPT TRAIT
            </Button>
          )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={optTraitSearchFilters}
            defaultFilterState={optsSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="opts-container" elevation={0}>
        <div className={classes.optsTableContainer}>
          <Table
            columns={optTraitsColumns}
            filters={filters}
            openModal={() => setDrawerOpen(true)}
            renderCellData={(data) => {
              setCellData(data);
            }}
            disableSelectAllCheckbox
            maxSelected={1}
            moduleName="opt-traits"
            initApiCall={(_page, perPage) =>
              getOptTraits(
                parseSearchFilter([filters], filterFieldMap, _page, perPage)
              )
            }
            setLoadingCellData={setLoadingCellData}
            showArrayValueInNewSheet={(key) => key === "opts"}
            customizeExportData={(sheetObj, key, obj) => {
              const newSheetObj = {};
              Object.keys(sheetObj).forEach((sheetKey) => {
                if (sheetKey === "opts") {
                  newSheetObj["Opt Mappings"] = sheetObj[sheetKey].map((x) => ({
                    traitId: obj.traitId,
                    traitName: obj.traitName,
                    ...x,
                  }));
                }
              });
              return newSheetObj;
            }}
            exportMainSheetName="Opt Traits"
          />
        </div>
      </Paper>
      {Object.keys(cellData).length > 0 && drawerOpen && (
        <TraitDetailsModal
          title={`${t("common.labels.trait_id")} - ${cellData.traitId}`}
          subtitle="This opt trait has the following details and mappings"
          data={cellData}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

export default TraitsOptsContainer;
