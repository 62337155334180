import { createContext, useState, useCallback } from "react";

import PropTypes from "prop-types";

import applicationConfig from "../../config/applicationConfig";

export const NotificationContext = createContext({
  notification: null,
  addNotification: () => {},
  removeNotification: () => {},
});

export default function NotificationProvider({ children }) {
  const [notification, setNotification] = useState({});

  const removeNotification = () => setNotification({});

  const addNotification = (message, type) => setNotification({ message, type });

  const contextValue = {
    notification,
    addNotification: useCallback(
      (message, type = applicationConfig.status.error) =>
        addNotification(message, type),
      []
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
