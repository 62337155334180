import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const { type } = theme.palette;
  return {
    themeWrapper: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    detailWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
      "& >:nth-child(1)": {
        flex: 0.5,
      },
      "& >:nth-child(2)": {
        flex: 1.5,
      },
    },
    editButton: {
      width: "45px",
      height: "45px",
      "& svg": {
        fill: theme.palette.blue.light,
      },
    },
    MuiPaper: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minWidth: "500px !important",
      overflowX: "hidden !important",
    },
    w700: {
      minWidth: "700px !important",
    },
    skeletonContainer: {
      padding: "32px",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    middleSkeleton: {
      marginTop: "24px",
    },
    lastSkeleton: {
      marginTop: "32px",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    rightContainer: {
      marginLeft: "auto",
    },
    rightFooterContainer: {
      "& button": {
        marginRight: "10px",
        width: "119px",
      },
      "& button:last-child": {
        marginRight: "0px",
      },
      "& button:nth-child(1)": {
        backgroundColor: theme.palette.green.main3,
      },
      "& button:nth-child(2)": {
        backgroundColor: theme.palette.error.light2,
      },
      "& button:disabled": {
        backgroundColor: theme.colorTheme.greyishWhite,
        color: theme.colorTheme.greyishWhite2,
      },
    },
    verticalBar: {
      height: "20px",
      width: "1px",
      backgroundColor: theme.colorTheme.greyishWhite,
      margin: "0px 20px",
    },
    headerRightContainer: {
      "& .MuiTypography-h6": {
        color: theme.palette.primary.light,
        fontSize: "14px",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
        marginRight: "10px",
      },
      "& div": {
        cursor: "pointer",
      },
    },
    detailsContainer: {
      display: "flex",
      "& > div": {
        padding: "5px 0",
        "& >*": {
          display: "inline-block",
        },
        "& .MuiTypography-h6:nth-child(1)": {
          color:
            type === "light"
              ? theme.colorTheme.greyishBlack
              : theme.colorTheme.greyishWhite,
          fontWeight: "500",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiTypography-h6": {
        fontSize: "14px",
        wordBreak: "break-all",
      },
      "& span": {
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        fontSize: "14px",
      },
    },
    alignEnd: {
      alignItems: "flex-end",
    },
    originalVersionContainer: {
      marginTop: "15px",
      "& .MuiTypography-h4": {
        fontSize: "15px",
      },
      "& .MuiAccordionSummary-root": {
        borderBottom: `1px solid ${theme.colorTheme.greyishWhite}`,
        padding: "2px 16px !important",
        maxHeight: "53px !important",
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "53px !important",
      },
      "& .MuiAccordion-root": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.colorTheme.greyishWhite}`,
        marginTop: "20px !important",
        marginRight: "12px",
        boxShadow: "0px 0px 0px 0px !important",
      },
      "& .MuiAccordionDetails-root div .MuiTypography-h6": {
        wordBreak: "break-all",
      },
      "& .MuiAccordionDetails-root div .MuiTypography-h6 + div": {
        borderBottom: "unset",
      },
    },
    horizontalContainer: {
      marginTop: "20px",
      marginBottom: "20px",
      backgroundColor: theme.colorTheme.greyishWhite,
      height: "2px",
      width: "100%",
    },
    commentContainer: {
      "& textarea": {
        height: "126px !important",
        width: "100% !important",
        borderColor: theme.colorTheme.greyishWhite3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: "4px",
        outline: "none",
      },
      "& .MuiTypography-h4": {
        fontSize: "20px",
        marginBottom: "15px",
        fontWeight: "500",
      },
      "& .MuiTypography-h6": {
        fontSize: "14px",
        marginBottom: "8px",
      },
      "& .MuiTypography-h6:nth-child(2)": {
        color: theme.colorTheme.greyishBlack2,
        marginLeft: "2px",
      },
    },
    asterisk: {
      color: theme.palette.error.main,
      fontSize: "18px",
    },
    gridContainer: {
      display: "grid",
      maxWidth: "95%",
      minWidth: "95%",
      gridRowGap: "5px",
      gridTemplateColumns: "50% 50%",
    },
    requestsTableStyle: {
      height: "300px !important",
      "& .MuiDataGrid-window": {
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-root .MuiDataGrid-colCellMoving": {
        backgroundColor: "transparent !important",
      },
      "& .MuiDataGrid-row": {
        height: "69px",
      },
      "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft >div":
        {
          borderRadius: "20px",
          padding: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
    cancelBtn: {
      marginLeft: "16px",
    },
    title: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 0,
      left: 0,
      right: 0,
      marginTop: "13px",
      paddingTop: "16px",
      paddingBottom: "10px",
      borderBottom: `1px solid ${theme.colorTheme.greyishWhite3}`,
    },
    tabs: {
      marginTop: "-20px",
      "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.primary.light,
      },
      "& .Mui-selected": {
        color: theme.palette.primary.light,
      },
    },
    tab: {
      fontSize: "14px",
      textTransform: "capitalize",
    },
    footer: {
      paddingRight: "32px",
      marginBottom: "20px",
    },
    closeBtn: {
      width: "100px",
    },
    badge: {
      backgroundColor: theme.colorTheme.greyishWhite,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "4px",
      minWidth: "42px",
      height: "25px",
    },
    newTrait: {
      color: theme.palette.primary.light,
      cursor: "pointer",
    },
    subTitle: {
      fontSize: "14px",
      marginTop: "16px",
    },
    returnBtn: {
      backgroundColor: theme.palette.blue.main,
    },
  };
});

export default useStyles;
