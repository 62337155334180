import { Fragment } from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import useGlobalStyles from "../../assets/styles/global";
import { lightTheme } from "../../assets/styles/themes";
import applicationConfig from "../../config/applicationConfig";

import getStatusColorIcon from "../../utilities/getStatusColorIcon";

import { KeyValueSeparator } from "../AccordionRowDetails";
import Table from "../Table";

import useStyles from "./styles";

const ReviewModal = ({
  data,
  open,
  isRevised,
  status,
  handleOutput,
  handleClose,
  handleAction,
  handleEditRequest,
  commentRef,
  isLoading,
  extraDetails,
  requestDetailsFields,
  isBatchRequest,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { requestStatus } = applicationConfig;
  const globalClasses = useGlobalStyles();

  const renderRequestDetails = () => {
    return (
      <div className={clsx(classes.detailsContainer)}>
        <table className={globalClasses.table}>
          <tbody>
            {requestDetailsFields.map((field, index) => {
              return !data[field.selector] ? null : (
                <KeyValueSeparator
                  keyVal={field.label}
                  value={
                    typeof data[field.selector] === "string" ? (
                      <Typography
                        variant="h6"
                        key={field.selector}
                        style={{ fontWeight: "normal" }}
                      >
                        {field.format === "html" ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data[field.selector],
                            }}
                          />
                        ) : (
                          data[field.selector]
                        )}
                      </Typography>
                    ) : (
                      <div
                        className={clsx(
                          classes.flexContainer,
                          classes.alignEnd,
                          classes.toolTipContainer
                        )}
                      >
                        {data[field.selector]}
                      </div>
                    )
                  }
                  ind={index}
                  key={field.selectorr}
                  separator={null}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderBatchRequests = () => {
    const batchRequestColumns = [
      {
        field: "requestId",
        sortable: false,
        headerName: t("common.labels.request_id"),
        disableToggle: true,
        flex: 1,
      },
      {
        field: "module",
        sortable: false,
        headerName: t("requests.table_columns.module"),
        flex: 1,
      },
      {
        field: "name",
        sortable: false,
        headerName: t("common.labels.name"),
        flex: 2,
      },
      {
        field: "status",
        sortable: false,
        headerName: t("status.status"),
        flex: 1,
        renderCell: (params) => {
          const { value } = params;

          const { statusColor, statusIcon } = getStatusColorIcon(
            value,
            requestStatus
          );

          return (
            <div
              className={classes.statusWrapper}
              style={{
                backgroundColor: statusColor,
                color: lightTheme.palette.static.pureGrey,
              }}
            >
              {statusIcon[0]}
              <span className={classes.statusText}>{value}</span>
            </div>
          );
        },
      },
    ];
    const { batchRequests: batchRequestsData } = data;
    return (
      <Table
        rows={batchRequestsData?.map((batchRequest) => ({
          ...batchRequest,
          id: batchRequest.requestId,
        }))}
        columns={batchRequestColumns}
        tableStyle={classes.requestsTableStyle}
        hidePagination
      />
    );
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: clsx(classes.MuiPaper, isBatchRequest && classes.w700),
      }}
    >
      {isLoading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton variant="text" width={200} height={26} />
          <Skeleton
            variant="text"
            width={300}
            height={33}
            className={classes.middleSkeleton}
          />
          <Skeleton
            variant="rect"
            height={118}
            className={classes.lastSkeleton}
          />
          <Skeleton
            variant="rect"
            height={400}
            className={classes.lastSkeleton}
          />
        </div>
      ) : (
        <div className={classes.themeWrapper}>
          <DialogTitle
            id="request-details-modal-title"
            className={classes.title}
            data-testid="request-details-modal"
          >
            <div
              className={clsx(classes.flexContainer, classes.alignItemsCenter)}
            >
              <p style={{ margin: "unset", marginRight: "8px" }}>
                {t("reviewModal.request_details")}
              </p>
              {(status === requestStatus.RETURNED.toLowerCase() ||
                (status === requestStatus.PENDING.toLowerCase() &&
                  commentRef)) && (
                <IconButton
                  className={classes.editButton}
                  onClick={handleEditRequest}
                >
                  <EditIcon />
                </IconButton>
              )}
              {status === requestStatus.APPROVED.toLowerCase() && (
                <IconButton
                  className={classes.editButton}
                  onClick={() => handleOutput(data)}
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              )}
              <div
                className={clsx(classes.rightContainer, classes.flexContainer)}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  style={{
                    marginRight: "8px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {isBatchRequest ? renderBatchRequests() : renderRequestDetails()}
            {isRevised && !isBatchRequest && (
              <div className={classes.originalVersionContainer}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h4">
                      {t("reviewModal.original_version")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={clsx(classes.detailsContainer)}>
                      <table className={globalClasses.table}>
                        <tbody>
                          {extraDetails.map((field, index) => {
                            return !data[field.selector] ? null : (
                              <Fragment key={field.label}>
                                <KeyValueSeparator
                                  ind={index}
                                  separator={null}
                                  keyVal={field.label}
                                  value={
                                    typeof data[field.selector] === "string" ? (
                                      <Typography
                                        variant="h6"
                                        key={field.selector}
                                        style={{ fontWeight: "normal" }}
                                      >
                                        {field.format === "html" ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: data[field.selector],
                                            }}
                                          />
                                        ) : (
                                          data[field.selector]
                                        )}
                                      </Typography>
                                    ) : (
                                      <div
                                        className={clsx(
                                          classes.flexContainer,
                                          classes.alignEnd
                                        )}
                                      >
                                        {data[field.selector]}
                                      </div>
                                    )
                                  }
                                />
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </DialogContent>
          <DialogActions
            className={clsx(classes.footer, classes.flexContainer)}
          >
            {(status === requestStatus.PENDING.toLowerCase() ||
              status === requestStatus.RETURNED.toLowerCase() ||
              isBatchRequest) &&
            commentRef ? (
              <>
                <div
                  className={clsx(
                    classes.rightContainer,
                    classes.rightFooterContainer
                  )}
                >
                  <Button
                    onClick={() => handleAction(requestStatus.APPROVED)}
                    color="primary"
                    variant="contained"
                    className={classes.closeBtn}
                  >
                    {t("common.approve")}
                  </Button>
                  <Button
                    onClick={() => handleAction(requestStatus.REJECTED)}
                    color="primary"
                    variant="contained"
                    className={classes.closeBtn}
                  >
                    {t("common.reject")}
                  </Button>
                  <Button
                    onClick={() => handleAction(requestStatus.RETURNED)}
                    color="primary"
                    variant="contained"
                    className={clsx(classes.closeBtn, classes.returnBtn)}
                  >
                    {t("common.return")}
                  </Button>
                </div>
              </>
            ) : null}
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

ReviewModal.defaultProps = {
  open: false,
  isRevised: false,
  status: "",
  commentRef: null,
  isBatchRequest: false,
};

ReviewModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    batchRequests: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isRevised: PropTypes.bool,
  status: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleEditRequest: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleOutput: PropTypes.func.isRequired,
  commentRef: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }),
  extraDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  requestDetailsFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isBatchRequest: PropTypes.bool,
};

export default ReviewModal;
