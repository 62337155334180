import PropTypes from "prop-types";

import applicationConfig from "../../config/applicationConfig";

import StyledTooltip from "../StyledTooltip/index";

import useStyles from "./styles";

const CustomTooltip = ({ items }) => {
  const { myApprovalsTooltips } = applicationConfig;
  const { maxVisibleItems } = myApprovalsTooltips;
  const classes = useStyles();
  const totalItems = items.length;
  const remainingItems = totalItems - maxVisibleItems;
  const hasTooltipItems = remainingItems > 0;

  return (
    <div>
      {items.slice(0, maxVisibleItems).map((item, i) => (
        <span data-testid="tooltip-item" key={item}>
          {item}
          {i < totalItems - 1 ? <span>, {` `}</span> : null}
        </span>
      ))}
      {hasTooltipItems && (
        <StyledTooltip
          placement="right-end"
          title={
            <div>
              {items.slice(maxVisibleItems, totalItems).map((item) => (
                <div key={item}>{item}</div>
              ))}
            </div>
          }
        >
          <span className={classes.remainingItems} data-testid="plus">
            +{remainingItems}
          </span>
        </StyledTooltip>
      )}
    </div>
  );
};

CustomTooltip.defaultProps = {
  items: [],
};

CustomTooltip.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

export default CustomTooltip;
