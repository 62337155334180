import { useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";

import getUserProfile from "../../api/get-user-profile";
import logoutUser from "../../api/logout-user";
import validateLogin from "../../api/validate-login";

import useLoadingSpinner from "../../hooks/useLoadingSpinner";
import useNotifier from "../../hooks/useNotifier";
import useUserProfile from "../../hooks/useUserProfile";

import handleError from "../../utilities/handleError";

import Header from "../Header";
import LoginModal from "../LoginModal";
import Sidebar from "../Sidebar";

const Navigation = () => {
  const [drawerState, setDrawerState] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const { setUser, removeUser } = useUserProfile();
  const { addNotification } = useNotifier();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { pathname } = location;

  const { increaseRequestsCount, decreaseRequestsCount } = useLoadingSpinner();

  const { user } = useUserProfile();

  const logout = async (trackLocation = true) => {
    const pingToken = localStorage.getItem("ping-token");
    const pingRefreshToken = localStorage.getItem("ping-refresh-token");
    if (pingToken || pingRefreshToken) {
      logoutUser(pingToken, pingRefreshToken);
    }
    localStorage.removeItem("ping-token");
    localStorage.removeItem("ping-refresh-token");
    localStorage.removeItem("session-timer");
    localStorage.removeItem("session-end");
    sessionStorage.clear();
    removeUser();
    if (trackLocation && pathname !== "/") {
      history.push(`/?redirect_uri=${pathname}`);
    } else if (pathname !== "/") {
      history.push("/");
    }
  };

  const fetchUserInfo = async () => {
    try {
      increaseRequestsCount();
      const code = query.get("code");
      const state = query.get("state");
      const redirectUri = query.get("redirect_uri");
      if (code) {
        const loginResponse = await validateLogin(code, state);
        window.localStorage.setItem("ping-token", loginResponse.token);
        window.localStorage.setItem(
          "ping-refresh-token",
          loginResponse.refreshToken
        );
        window.localStorage.setItem(
          "session-end",
          Date.now() + Number(loginResponse.expiresIn) * 1000
        );
      }
      const pingToken = window.localStorage.getItem("ping-token");
      const sessionEnd = window.localStorage.getItem("session-end");
      if (pingToken && sessionEnd && sessionEnd > Date.now()) {
        const userData = await getUserProfile(!!(code && code.length > 0));
        window.localStorage.setItem("groups-token", userData.token);
        setUser({
          ...userData.user,
          loading: false,
          totalVisitorCount: userData.totalVisitorCount,
        });
        if (code && state) {
          const redirectUrl = atob(state);
          history.push(redirectUrl);
        }
      } else if (pathname !== "/" && !redirectUri) {
        logout();
      } else if (!sessionEnd || (sessionEnd && sessionEnd < Date.now())) {
        logout();
      }
    } catch (error) {
      if (error.status === 401) {
        logout();
      }
      handleError({
        error,
        handle404: false,
        addNotification,
      });
      setUser({
        loading: false,
        userFetchComplete: true,
        access: [],
      });
    } finally {
      decreaseRequestsCount();
    }
  };

  // Fetch authenticated user profile on component load
  useEffect(async () => {
    await fetchUserInfo();
  }, []);

  useEffect(() => {
    setLoginModalVisible(!user || !user.userId);
  }, [user]);

  return (
    <div>
      <Header
        logout={async (trackLocation = true) => {
          await logout(trackLocation);
        }}
      />
      <Sidebar isOpen={drawerState} toggleDrawer={toggleDrawer} />
      {loginModalVisible && <LoginModal />}
    </div>
  );
};

export default Navigation;
