import { Fragment, useState, useEffect } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Switch,
  Button,
  Box,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@material-ui/core";
import { ExpandMore, Edit, Close } from "@material-ui/icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import getTraits from "../../../../api/get-traits";

import useGlobalStyles from "../../../../assets/styles/global";
import AccordionRowDetails from "../../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";
import traitsModuleConfig from "../../../../config/traitsModuleConfig";

import {
  getNonAlphaNumericCharacters,
  splitCamelCaseString,
  isEmptyString,
} from "../../../../utilities/formValidation";
import parseSearchFilter from "../../../../utilities/parseSearchFilter";
import useImmer from "../../../../utilities/useImmer";

import validateEcosystemTraits from "../../CreateTraitsContainer/helpers/validateEcosystemTraits";
import validateSurvivorshipRule from "../../CreateTraitsContainer/helpers/validateSurvivorshipRules";
import validateTraitsInfo from "../../CreateTraitsContainer/helpers/validateTraitsInfo";

import useStyles from "./styles";

const NewTrait = ({
  traitsInfo,
  setTraitsInfo,
  openModal,
  handleClose,
  handleSubmit,
  ecosystems,
  isDisabled,
  traitsSelected,
  title,
  isRequestRevisionFlow,
}) => {
  const initialFocusedValues = {
    traitName: false,
    traitDescription: false,
    traitInputType: false,
    exampleValues: false,
    frequencyChange: false,
  };

  const [ecoSystemsFocused, setEcosystemsFocused] = useState([]);
  const [ecoSystemTraits, setEcosystemTraits] = useImmer([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [editDetailsList, setEditDetailsList] = useState([]);
  const [defaultExpandedList, setDefaultExpandedList] = useState([]);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [inEditMode, setInEditMode] = useState(0);
  const [focused, setFocused] = useImmer(initialFocusedValues);
  const [traitNameValidationState, setTraitNameValidationState] = useState({});

  const [openCreateTraitWarningModal, setOpenCreateTraitWarningModal] =
    useState(false);

  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const getEcosystemTraitInfo = (ecosystem) => {
    let ecoSystemTraitName = "";
    let ecoSystemTraitType = "";
    let ecoSystemTraitMiscProperty = "";
    let {
      ecoSystemTraitPrefix,
      ecoSystemTraitSuffix,
      ecoSystemTraitDelimiter,
    } = ecosystem;

    const { ecosystemId, ecoSystemTraitCharacterLimit, ecosystemName } =
      ecosystem;

    // IF Data Class = “Enriched Traits”, ADD the word “Enrich” AFTER eco_system_trait_prefix
    // IF Data Class = “Dependent Traits” ADD the word “dependent” BEFORE eco_system_trait_prefix
    // IF Data Class = “Scores”, REPLACE eco_system_trait_prefix with “score” and eco_system_trait_suffix with “Value”

    if (traitsInfo.dataClass === "Enriched Traits") {
      ecoSystemTraitPrefix += "Enrich";
    }

    if (traitsInfo.dataClass === "Dependent Traits") {
      ecoSystemTraitPrefix = `dependent${ecoSystemTraitPrefix}`;
    }

    if (traitsInfo.dataClass === "Scores") {
      if (ecoSystemTraitPrefix && ecoSystemTraitPrefix.length > 0) {
        ecoSystemTraitPrefix = "score";
      }
      if (ecoSystemTraitSuffix && ecoSystemTraitSuffix.length > 0) {
        ecoSystemTraitSuffix = "Value";
      }
    }

    // If Trait Type is computed trait
    if (traitsInfo.traitType === "Computed Trait") {
      if (ecosystemId === 1 || ecosystemId === 3) {
        ecoSystemTraitDelimiter = "_";
      }
      ecoSystemTraitPrefix = "";
      ecoSystemTraitSuffix = "";
    }

    if (!ecoSystemTraitDelimiter) {
      const { traitName } = traitsInfo;
      if (ecoSystemTraitPrefix) {
        ecoSystemTraitName =
          traitName.charAt(0).toUpperCase() + traitName.slice(1);
      } else {
        ecoSystemTraitName = traitName;
      }
    } else {
      ecoSystemTraitName = splitCamelCaseString(
        traitsInfo.traitName.charAt(0).toLowerCase() +
          traitsInfo.traitName.slice(1)
      )
        .toLowerCase()
        .split(" ")
        .join(ecoSystemTraitDelimiter);
    }

    ecoSystemTraitName =
      ecoSystemTraitPrefix + ecoSystemTraitName + ecoSystemTraitSuffix;

    if (ecosystemId !== 4) {
      ecoSystemTraitType = traitsInfo.traitType;
    } else {
      ecoSystemTraitType = "Member";
    }

    if (ecosystemId !== 2) {
      ecoSystemTraitMiscProperty = "";
    } else {
      ecoSystemTraitMiscProperty = splitCamelCaseString(traitsInfo.traitName);
      ecoSystemTraitMiscProperty =
        ecoSystemTraitMiscProperty.charAt(0).toUpperCase() +
        ecoSystemTraitMiscProperty.slice(1);
    }

    if (ecoSystemTraitCharacterLimit) {
      if (
        ecoSystemTraitName.length > parseInt(ecoSystemTraitCharacterLimit, 10)
      ) {
        ecoSystemTraitName = ecoSystemTraitName.replace(
          ecoSystemTraitPrefix,
          "trt"
        );
        ecoSystemTraitName = ecoSystemTraitName.replace(
          ecoSystemTraitSuffix,
          "Val"
        );
      }
      if (
        ecoSystemTraitName.length > parseInt(ecoSystemTraitCharacterLimit, 10)
      ) {
        ecoSystemTraitName = ecoSystemTraitName.replace("trt", "");
        ecoSystemTraitName = ecoSystemTraitName.replace("Val", "");
        ecoSystemTraitName = ecoSystemTraitName.replace(/[aeiou]/g, "");
        ecoSystemTraitName = `trt${ecoSystemTraitName}Val`;
      }
    }

    return {
      ecosystemTraitName: ecoSystemTraitName.replace(/null/g, ""),
      dataType: traitsInfo.dataType,
      dataClass: traitsInfo.dataClass,
      ecosystemTraitType: ecoSystemTraitType,
      ecosystemTraitMiscProperty1: ecoSystemTraitMiscProperty,
      ecoSystemDelimeter: ecoSystemTraitDelimiter,
      specialCharacters: getNonAlphaNumericCharacters(ecoSystemTraitName),
      ecoSystemTraitCharacterLimit,
      ecosystemId: ecosystem.ecosystemId,
      ecosystemName,
    };
  };
  const classes = useStyles();
  const { createTraitConstants } = traitsModuleConfig;
  const initialErrorsState = {
    traitNameError: null,
    traitNameErrorState: "",
    traitDescriptionError: null,
    traitInputTypeError: null,
    exampleValuesError: null,
    frequencyChangeError: null,
  };
  const [errors, setErrors] = useState(initialErrorsState);

  const resetState = () => {
    setFocused(initialFocusedValues);
    setEcosystemsFocused([]);
    setEcosystemTraits([]);
    setCurrentStep(1);
    setErrors(initialErrorsState);
    setTraitNameValidationState({});
  };

  const [ecoSystemErrors, setEcoSystemErrors] = useState([]);
  const checkStep1Disabled = (errorMap) => {
    const requiredFields = [
      applicationConfig.common.trait_name,
      applicationConfig.common.trait_description,
    ];
    const errorValues = JSON.parse(JSON.stringify(errorMap));
    if (traitsInfo.personalData) {
      requiredFields.push("exampleValues");
      requiredFields.push("traitInputType");
    } else {
      delete errorValues.exampleValuesError;
      delete errorValues.traitInputTypeError;
    }
    if (traitsInfo.personalData) {
      requiredFields.push("frequencyChange");
    } else {
      delete errorValues.frequencyChangeError;
    }
    const checkRequiredFields = requiredFields.filter((field) =>
      isEmptyString(traitsInfo[field])
    );
    if (errorValues.traitNameErrorState === applicationConfig.status.success) {
      delete errorValues.traitNameErrorState;
      delete errorValues.traitNameError;
    }
    return !(
      Object.values(errorValues).filter((val) => val).length === 0 &&
      checkRequiredFields.length === 0
    );
  };

  const checkEcoSystemDisabled = (index, ecoSystemErrorsList) => {
    const errorValues = JSON.parse(JSON.stringify(ecoSystemErrorsList[index]));
    return Object.values(errorValues).some((val) => val);
  };

  const checkStep2Disabled = (ecoSystemErrorsList) => {
    let check = false;
    for (let i = 0; i < ecoSystemErrorsList.length; i += 1) {
      check = check || checkEcoSystemDisabled(i, ecoSystemErrorsList);
    }
    return check || inEditMode > 0;
  };

  const onEditEcoSystemDetails = (index) => {
    const editDetails = [...editDetailsList];
    editDetails[index] = !editDetails[index];
    setEditDetailsList(editDetails);
  };

  const searchForTrait = async () => {
    let filter = {};
    filter = {
      name: applicationConfig.common.trait_name,
      value: traitsInfo.traitName,
    };
    try {
      setTraitNameValidationState({
        message: t("new_trait.errors.trait_name.error_5"),
        state: "info",
      });
      const rsp = await getTraits(
        parseSearchFilter(
          [filter],
          {
            traitName: applicationConfig.common.trait_name,
          },
          1,
          3
        )
      );
      const itemsTobeSearched = rsp.items;
      itemsTobeSearched.push(
        ...traitsSelected.map((trait) => ({ traitName: trait.title }))
      );
      const isMatched = rsp.items.filter(
        (trait) => trait.traitName === traitsInfo.traitName
      );
      if (isMatched.length > 0) {
        setTraitNameValidationState({
          message: t("new_trait.errors.trait_name.error_4"),
          state: "error",
        });
        return true;
      }
      setTraitNameValidationState({
        message: t("new_trait.errors.trait_name.error_6"),
        state: "success",
      });
      return false;
    } catch (error) {
      setTraitNameValidationState({
        message: t("new_trait.errors.trait_name.error_6"),
        state: "success",
      });
      return false;
    }
  };

  const newTraitFields = [
    {
      label: t("common.labels.trait_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      required: true,
      props: {
        placeholder: t("new_trait.placeholders.trait_name"),
        onChange: (event) => {
          setTraitsInfo((draft) => {
            draft.traitName = event.target.value;
          });
          setTraitNameValidationState({
            message: "",
          });
        },
        multiline: true,
        error:
          traitNameValidationState.state === "error" ||
          errors.traitNameError !== null,
        value: traitsInfo.traitName,
        helperText: (
          <InlineMessage
            message={errors.traitNameError || traitNameValidationState.message}
            state={traitNameValidationState.state}
          />
        ),
        onBlur: () => {
          setFocused((draft) => {
            draft.traitName = true;
          });
        },
        onFocus: () => {
          setTraitNameValidationState({});
          setFocused((draft) => {
            draft.traitName = false;
          });
        },
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.trait_description"),
      type: applicationConfig.inputType.textInput,
      required: true,
      props: {
        placeholder: t("new_trait.placeholders.trait_description"),
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.traitDescription = event.target.value;
          }),
        error: errors.traitDescriptionError !== null,
        value: traitsInfo.traitDescription,
        helperText: <InlineMessage message={errors.traitDescriptionError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.traitDescription = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.traitDescription = false;
          });
        },
        inputProps: {
          "data-testid": "trait-description",
        },
        rows: 2,
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.data_type"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !traitsInfo.dataType ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.dataType = event.target.value;
          }),
        inputProps: {
          "data-testid": "dataType",
        },
        values: createTraitConstants.dataTypeOptions.map((option) => ({
          label: option,
          value: option,
        })),
        value: traitsInfo.dataType,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.data_class"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !traitsInfo.dataClass ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.dataClass = event.target.value;
          }),
        inputProps: {
          "data-testid": "dataClass",
        },
        values: createTraitConstants.dataClassOptions.map((option) => ({
          label: option,
          value: option,
        })),
        value: traitsInfo.dataClass,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.trait_type"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !traitsInfo.traitType ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.traitType = event.target.value;
            draft.survivorshipRule =
              event.target.value === "Computed Trait"
                ? t("constants.survivorship_rules.most_recent")
                : traitsInfo.survivorshipRule;
          }),
        inputProps: {
          "data-testid": "traitType",
        },
        values: createTraitConstants.traitTypeOptions.map((option) => ({
          label: option,
          value: option,
        })),
        value: traitsInfo.traitType,
        disabled: isDisabled,
      },
    },
    {
      label: t("new_trait.example_values"),
      type: applicationConfig.inputType.textInput,
      required: traitsInfo.personalData,
      props: {
        placeholder: t("new_trait.placeholders.example_values"),
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.exampleValues = event.target.value;
          }),
        inputProps: {
          "data-testid": "exampleValues",
        },
        rows: 2,
        multiline: true,
        error: focused.exampleValues && errors.exampleValuesError !== null,
        helperText: <InlineMessage message={errors.exampleValuesError} />,
        value: traitsInfo.exampleValues,
        onBlur: () => {
          setFocused((draft) => {
            draft.exampleValues = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.exampleValues = false;
          });
        },
        disabled: isDisabled,
      },
    },
    {
      label: t("new_trait.trait_input_type"),
      type: applicationConfig.inputType.dropdown,
      required: traitsInfo.personalData,
      props: {
        label: !traitsInfo.traitInputType ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) => {
          setTraitsInfo((draft) => {
            draft.traitInputType = event.target.value;
          });
        },
        values: createTraitConstants.traitInputTypeOptions.map((option) => ({
          label: option,
          value: option,
        })),
        inputProps: {
          "data-testid": "traitInputType",
        },
        value: traitsInfo.traitInputType,
        error: errors.traitInputTypeError !== null,
        helperText: <InlineMessage message={errors.traitInputTypeError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.traitInputType = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.traitInputType = false;
          });
        },
        disabled: isDisabled,
      },
    },
    {
      label: t("new_trait.trait_response_type"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !traitsInfo.traitResponseType
          ? t("new_trait.select_option")
          : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.traitResponseType = event.target.value;
          }),
        inputProps: {
          "data-testid": "traitResponseType",
        },
        values: createTraitConstants.traitResponseTypeOptions.map((option) => ({
          label: option,
          value: option,
        })),
        value: traitsInfo.traitResponseType,
        disabled: isDisabled,
      },
    },
    {
      label: t("new_trait.survivorship_rule"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        label: !traitsInfo.survivorshipRule ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.survivorshipRule = event.target.value;
          }),
        inputProps: {
          "data-testid": "survivorshipRule",
        },
        values: createTraitConstants.survivorshipRules.map((option) => {
          return validateSurvivorshipRule(traitsInfo, option, true);
        }),
        value: traitsInfo.survivorshipRule,
        disabled: isDisabled,
      },
    },
  ];

  if (traitsInfo.personalData) {
    newTraitFields.push({
      label: t("new_trait.frequency_of_data_change"),
      type: applicationConfig.inputType.dropdown,
      required: traitsInfo.personalData,
      props: {
        label: !traitsInfo.frequencyChange ? t("new_trait.select_option") : "",
        select: true,
        variant: "outlined",
        onChange: (event) =>
          setTraitsInfo((draft) => {
            draft.frequencyChange = event.target.value;
          }),
        values: createTraitConstants.frequencyOfDataChange.map((option) => ({
          label: option,
          value: option,
        })),
        inputProps: {
          "data-testid": "frequencyChange",
        },
        value: traitsInfo.frequencyChange,
        error: errors.frequencyChangeError !== null,
        helperText: <InlineMessage message={errors.frequencyChangeError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.frequencyChange = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.frequencyChange = false;
          });
        },
        disabled: isDisabled,
      },
    });
  }

  const traitEcosystemFields = [
    {
      label: `${t("common.labels.ecosystem_trait_name")}`,
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("new_trait.ecosystem.placeholders.trait_name"),
        disabled: isDisabled,
        rows: 2,
        multiline: true,
        wrap: "hard",
      },
      selector: "ecosystemTraitName",
    },
    {
      label: `${t("common.labels.data_type")}`,
      type: applicationConfig.inputType.dropdown,
      props: {
        select: true,
        variant: "outlined",
        disabled: isDisabled,
      },
      selector: "dataType",
    },
    {
      label: `${t("common.labels.data_class")}`,
      type: applicationConfig.inputType.dropdown,
      props: {
        select: true,
        variant: "outlined",
        disabled: isDisabled,
      },
      selector: "dataClass",
    },
    {
      label: `${t("common.labels.ecosystem_trait_type")}`,
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("new_trait.ecosystem.placeholders.trait_type"),
        disabled: isDisabled,
      },
      selector: "ecosystemTraitType",
    },
    {
      label: `${t("new_trait.ecosystem.misc_property")}`,
      type: applicationConfig.inputType.textInput,
      flex: 2,
      props: {
        placeholder: t("new_trait.ecosystem.placeholders.misc_property"),
        disabled: isDisabled,
      },
      selector: "ecosystemTraitMiscProperty1",
    },
  ];

  useEffect(() => {
    const errors1 = validateTraitsInfo(traitsInfo, focused, isDisabled);
    setErrors(errors1);
  }, [traitsInfo, focused]);

  useEffect(() => {
    const errors1 = validateEcosystemTraits(
      ecoSystemTraits,
      ecoSystemsFocused,
      isDisabled
    );
    setEcoSystemErrors(errors1);
  }, [ecoSystemTraits]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">
            {!isDisabled
              ? title
              : `${t("common.new_trait")} - ${traitsInfo.traitName}`}
          </Typography>
          <div
            className={classes.marginLeftAuto}
            onClick={() => {
              handleClose();
              resetState();
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        <div className={classes.horizontalBar} />
        {currentStep === 1 && (
          <DialogContent>
            <Typography style={{ fontWeight: "bold", marginBottom: "15px" }}>
              {t("new_trait.dialogs.trait_classification")}
            </Typography>
            <Box display="flex" alignItems="flexStart">
              <div className={classes.personalDataContainer}>
                <div className={clsx(classes.flexContainer, classes.fullWidth)}>
                  <Switch
                    checked={traitsInfo.personalData}
                    data-testid="personal-data"
                    onChange={(event) => {
                      if (!event.target.checked) {
                        setTraitsInfo((draft) => {
                          draft.personalData = event.target.checked;
                        });
                      }
                      setTraitsInfo((draft) => {
                        draft.personalData = event.target.checked;
                      });
                    }}
                    disabled={isDisabled}
                    color="primary"
                  />
                  <Typography variant="h6">
                    {t("new_trait.personal_data")}{" "}
                  </Typography>
                  <Tooltip title={createTraitConstants.personalDataTooltip}>
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
                <div className={clsx(classes.flexContainer, classes.fullWidth)}>
                  <Switch
                    checked={traitsInfo.personalDataNonPii}
                    data-testid="sensitive-data"
                    onChange={(event) => {
                      if (event.target.checked) {
                        setTraitsInfo((draft) => {
                          draft.personalDataNonPii = event.target.checked;
                        });
                      } else {
                        setTraitsInfo((draft) => {
                          if (!draft.healthData) {
                            draft.personalDataNonPii = event.target.checked;
                          }
                        });
                      }
                    }}
                    disabled={isDisabled}
                    color="primary"
                  />
                  <Typography variant="h6">
                    {t("new_trait.sensitive_data")}
                  </Typography>
                  <Tooltip title={createTraitConstants.sensitiveDataTooltip}>
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
              <div className={classes.personalDataContainer}>
                <div className={clsx(classes.flexContainer, classes.fullWidth)}>
                  <Switch
                    checked={traitsInfo.healthData}
                    data-testid="health-data"
                    onChange={(event) => {
                      if (event.target.checked) {
                        setTraitsInfo((draft) => {
                          draft.healthData = event.target.checked;
                          draft.personalDataNonPii = event.target.checked;
                        });
                      } else {
                        setTraitsInfo((draft) => {
                          draft.healthData = event.target.checked;
                        });
                      }
                    }}
                    disabled={isDisabled}
                    color="primary"
                  />
                  <Typography variant="h6">
                    {t("new_trait.health_data")}{" "}
                  </Typography>
                  <Tooltip title={createTraitConstants.healthDataTooltip}>
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
            </Box>
            <Form
              fields={newTraitFields}
              fieldClassName={clsx(
                classes.fieldContainer,
                classes.flexContainer
              )}
            />
          </DialogContent>
        )}
        {currentStep === 2 && (
          <DialogContent>
            <div
              className={clsx(
                classes.personalDataContainer,
                classes.ecoSystemDetailsContainer
              )}
            >
              <div className={classes.flexContainer}>
                <Typography variant="h6">
                  {t("new_trait.technical_details")}
                </Typography>
              </div>
              {ecosystems.map((ecosystem, index) => {
                let fields = JSON.parse(JSON.stringify(traitEcosystemFields));
                fields = fields.map((field) => {
                  const fieldObject = field;
                  if (
                    fieldObject.label ===
                    `${t("common.labels.ecosystem_trait_name")}`
                  ) {
                    fieldObject.props = {
                      ...fieldObject.props,
                      value: ecoSystemTraits[index].ecosystemTraitName,
                      onChange: (event) => {
                        setEcosystemTraits((draft) => {
                          draft[index].ecosystemTraitName = event.target.value;
                        });
                      },
                      error: ecoSystemErrors[index].ecoTraitNameError !== null,
                      helperText: (
                        <InlineMessage
                          message={ecoSystemErrors[index].ecoTraitNameError}
                        />
                      ),
                      inputProps: {
                        "data-testid": "ecosystem-trait-name",
                      },
                      onBlur: () => {
                        const ecosystemsFocused = [...ecoSystemsFocused];
                        if (!ecosystemsFocused[index]) {
                          ecosystemsFocused[index] = {};
                        }
                        ecosystemsFocused[index].ecosystemTraitName = true;
                        setEcosystemsFocused(ecosystemsFocused);
                      },
                    };
                  } else if (
                    fieldObject.label === `${t("common.labels.data_type")}`
                  ) {
                    fieldObject.props = {
                      ...fieldObject.props,
                      value: ecoSystemTraits[index].dataType,
                      values: createTraitConstants.dataTypeOptions.map(
                        (option) => ({
                          label: option,
                          value: option,
                        })
                      ),
                      inputProps: {
                        "data-testid": "ecosystem-data-type",
                      },
                      onChange: (event) => {
                        setEcosystemTraits((draft) => {
                          draft[index].dataType = event.target.value;
                        });
                      },
                    };
                  } else if (
                    fieldObject.label === `${t("common.labels.data_class")}`
                  ) {
                    fieldObject.props = {
                      ...fieldObject.props,
                      value: ecoSystemTraits[index].dataClass,
                      values: createTraitConstants.dataClassOptions.map(
                        (option) => ({
                          label: option,
                          value: option,
                        })
                      ),
                      inputProps: {
                        "data-testid": "ecosystem-data-class",
                      },
                      onChange: (event) => {
                        setEcosystemTraits((draft) => {
                          draft[index].dataClass = event.target.value;
                        });
                      },
                    };
                  } else if (
                    fieldObject.label ===
                    `${t("common.labels.ecosystem_trait_type")}`
                  ) {
                    fieldObject.props = {
                      ...fieldObject.props,
                      value: ecoSystemTraits[index].ecosystemTraitType,
                      onChange: (event) => {
                        setEcosystemTraits((draft) => {
                          draft[index].ecosystemTraitType = event.target.value;
                        });
                      },
                      inputProps: {
                        "data-testid": "ecosystem-trait-type",
                      },
                      error:
                        ecoSystemErrors[index].ecoSystemTraitTypeError !== null,
                      helperText: (
                        <InlineMessage
                          message={
                            ecoSystemErrors[index].ecoSystemTraitTypeError
                          }
                        />
                      ),
                      onBlur: () => {
                        const ecosystemsFocused = [...ecoSystemsFocused];
                        if (!ecosystemsFocused[index]) {
                          ecosystemsFocused[index] = {};
                        }
                        ecosystemsFocused[index].ecosystemTraitType = true;
                        setEcosystemsFocused(ecosystemsFocused);
                      },
                    };
                  } else if (
                    fieldObject.label ===
                    `${t("new_trait.ecosystem.misc_property")}`
                  ) {
                    fieldObject.props = {
                      ...fieldObject.props,
                      value: ecoSystemTraits[index].ecosystemTraitMiscProperty1,
                      onChange: (event) => {
                        setEcosystemTraits((draft) => {
                          draft[index].ecosystemTraitMiscProperty1 =
                            event.target.value;
                        });
                      },
                      inputProps: {
                        "data-testid": "ecosystem-trait-misc-property",
                      },
                      error:
                        ecoSystemErrors[index]
                          .ecoSystemTraitMiscPropertyError !== null,
                      helperText: (
                        <InlineMessage
                          message={
                            ecoSystemErrors[index]
                              .ecoSystemTraitMiscPropertyError
                          }
                        />
                      ),
                    };
                  }
                  return fieldObject;
                });
                return (
                  <div
                    key={ecosystem.ecosystemName}
                    className={clsx(
                      checkEcoSystemDisabled(index, ecoSystemErrors)
                        ? classes.errorContainer
                        : classes.accordionWrapper
                    )}
                  >
                    <Accordion
                      defaultExpanded={defaultExpandedList[index]}
                      data-testid={`accordion-${index}`}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        className={globalClasses.accordionHeader}
                      >
                        <Button>{ecosystem.ecosystemName}</Button>
                        <div className={classes.ecoTraitNameContainer}>
                          <Typography variant="h6">
                            {((traitsInfo.ecoSystemTraits || [])[index] || {})
                              .ecosystemTraitName ||
                              traitsInfo.ecoSystemTraits[index]
                                .ecosystemTraitName}
                          </Typography>
                          {ecoSystemErrors[index]
                            .ecoTraitNameCharacterLimitError && (
                            <InlineMessage
                              message={
                                ecoSystemErrors[index]
                                  .ecoTraitNameCharacterLimitError
                              }
                            />
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {editDetailsList[index] ? (
                          <>
                            <Form
                              fields={fields.map((field) => {
                                const fieldObject = field;
                                delete fieldObject.selector;
                                return fieldObject;
                              })}
                              fieldClassName={clsx(
                                classes.fieldContainer1,
                                classes.flexContainer
                              )}
                              containerClassName={classes.fieldContainer1Parent}
                            />
                            <div
                              className={clsx(
                                classes.flexContainer,
                                classes.btnContainer,
                                classes.ecosystemFooter
                              )}
                            >
                              <Button
                                onClick={() => {
                                  onEditEcoSystemDetails(index);
                                  setEcosystemTraits(
                                    traitsInfo.ecoSystemTraits
                                  );
                                  setInEditMode(inEditMode - 1);
                                }}
                                color="primary"
                                variant="outlined"
                              >
                                {t("common.cancel")}
                              </Button>
                              {!isDisabled && (
                                <Button
                                  onClick={() => {
                                    const errors2 = validateEcosystemTraits(
                                      ecoSystemTraits,
                                      ecoSystemsFocused,
                                      isDisabled
                                    );
                                    setEcoSystemErrors(errors2);
                                    if (
                                      checkEcoSystemDisabled(index, errors2)
                                    ) {
                                      return;
                                    }
                                    const info = [
                                      ...ecoSystemTraits.map((trait) => ({
                                        ...trait,
                                      })),
                                    ];
                                    onEditEcoSystemDetails(index);
                                    setTraitsInfo((draft) => {
                                      draft.ecoSystemTraits = [...info];
                                    });
                                    setInEditMode(inEditMode - 1);
                                  }}
                                  color="primary"
                                  variant="contained"
                                >
                                  {t("common.save")}
                                </Button>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className={clsx(classes.viewDetailsContainer)}>
                            <AccordionRowDetails
                              keys={traitEcosystemFields.map((field) => ({
                                name: field.label,
                                id: field.selector,
                              }))}
                              row={ecoSystemTraits[index]}
                            />
                            {!isDisabled && (
                              <div
                                onClick={() => {
                                  setInEditMode(inEditMode + 1);
                                  onEditEcoSystemDetails(index);
                                }}
                                onKeyDown={() => {}}
                                role="button"
                                className={classes.editIcon}
                                tabIndex={0}
                              >
                                <Edit />
                              </div>
                            )}
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </DialogContent>
        )}
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          {!(currentStep === 1 && isDisabled) && (
            <div className={classes.btnContainer}>
              <Button
                onClick={() => {
                  if (currentStep === 1) {
                    if (!isDisabled) {
                      setIsCancelClicked(true);
                    } else {
                      handleClose();
                    }
                  } else {
                    setCurrentStep(currentStep - 1);
                  }
                }}
                color="primary"
                variant={
                  currentStep === 2 && isDisabled ? "contained" : "outlined"
                }
              >
                {currentStep === 1 ? t("common.cancel1") : t("common.previous")}
              </Button>
            </div>
          )}
          {!(currentStep === 2 && isDisabled) && (
            <div className={classes.btnContainer}>
              <Button
                onClick={async () => {
                  if (currentStep === 1) {
                    setTraitNameValidationState({});
                    const allFocusedValues = {};
                    Object.keys(initialFocusedValues).forEach((key) => {
                      allFocusedValues[key] = true;
                    });
                    setFocused(allFocusedValues);
                    const errors1 = validateTraitsInfo(
                      traitsInfo,
                      allFocusedValues,
                      isDisabled
                    );
                    if (Object.values(errors1).some((x) => x)) {
                      setErrors(errors1);
                      return;
                    }
                    if (!errors1.traitNameError && !isDisabled) {
                      const isInValid = await searchForTrait();
                      if (isInValid) {
                        return;
                      }
                    }
                    if (checkStep1Disabled(errors1)) {
                      return;
                    }
                    let ecosystemTraitDetails = traitsInfo.ecoSystemTraits;
                    if (ecosystemTraitDetails.length === 0) {
                      ecosystemTraitDetails = ecosystems.map((ecosystem) => {
                        if (
                          !ecosystem.dataClass &&
                          !ecosystem.dataType &&
                          !ecosystem.ecosystemTraitMiscProperty1
                        ) {
                          const info = getEcosystemTraitInfo(ecosystem);
                          return {
                            ...info,
                          };
                        }

                        return ecosystem;
                      });
                    }
                    if (isRequestRevisionFlow) {
                      ecosystemTraitDetails = ecosystemTraitDetails.map(
                        (trait) => ({
                          ...trait,
                          specialCharacters: getNonAlphaNumericCharacters(
                            trait.ecosystemTraitName
                          ),
                        })
                      );
                    }
                    setTraitsInfo((draft) => {
                      draft.ecoSystemTraits = ecosystemTraitDetails;
                    });
                    setEcosystemTraits(
                      JSON.parse(JSON.stringify(ecosystemTraitDetails))
                    );
                    const errorValues = validateEcosystemTraits(
                      ecosystemTraitDetails
                    );
                    setEcoSystemErrors(errorValues);
                    const details = errorValues.map((error) => {
                      if (!isDisabled) {
                        if (Object.values(error).some((val) => val)) {
                          return true;
                        }
                        return false;
                      }

                      return false;
                    });
                    setEditDetailsList(details);
                    setDefaultExpandedList(details);
                    if (isDisabled) {
                      setCurrentStep(currentStep + 1);
                    } else {
                      setOpenCreateTraitWarningModal(true);
                    }
                  } else {
                    handleSubmit(traitsInfo);
                    resetState();
                  }
                }}
                disabled={
                  currentStep === 2 && checkStep2Disabled(ecoSystemErrors)
                }
                color="primary"
                variant="contained"
              >
                {currentStep === 1 &&
                  traitNameValidationState.state === "info" && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.5,
                      }}
                    >
                      <CircularProgress size={20} color="white" />
                    </Box>
                  )}
                {currentStep === 1
                  ? t("common.next")
                  : !isDisabled && t("common.submit")}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        open={openCreateTraitWarningModal}
        title={t("new_trait.dialogs.confirm_trait_classification")}
        message={
          <div className={classes.traitConfirmationDialog}>
            <Typography>{t("new_trait.dialogs.trait_text_warning")}</Typography>
          </div>
        }
        btn1Text={t("new_trait.dialogs.yes_confirm")}
        btn2Text={t("new_trait.dialogs.cancel")}
        btn1Action={() => {
          setOpenCreateTraitWarningModal(false);
          setCurrentStep(currentStep + 1);
        }}
        btn2Action={async () => {
          setOpenCreateTraitWarningModal(false);
        }}
        type="warning"
      />
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewTrait.defaultProps = {
  traitsInfo: {},
  isRequestRevisionFlow: false,
};

NewTrait.propTypes = {
  traitsInfo: PropTypes.shape({
    traitName: PropTypes.string,
    traitDescription: PropTypes.string,
    dataType: PropTypes.string,
    dataClass: PropTypes.string,
    traitType: PropTypes.string,
    exampleValues: PropTypes.string,
    personalData: PropTypes.bool,
    personalDataNonPii: PropTypes.bool,
    healthData: PropTypes.bool,
    traitInputType: PropTypes.string,
    traitResponseType: PropTypes.string,
    survivorshipRule: PropTypes.string,
    ecoSystemTraits: PropTypes.arrayOf(
      PropTypes.shape({
        ecosystemTraitName: PropTypes.string,
        specialCharacters: PropTypes.string,
        [PropTypes.string]: PropTypes.string,
      })
    ),
    frequencyChange: PropTypes.string,
  }),
  setTraitsInfo: PropTypes.func.isRequired,
  traitsSelected: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      isSelected: PropTypes.bool.isRequired,
      isNewTrait: PropTypes.bool.isRequired,
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isRequestRevisionFlow: PropTypes.bool,
  ecosystems: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default NewTrait;
