import { useContext } from "react";

import { NotificationContext } from "../providers/NotificationProvider";

const useNotifier = () => {
  const { notification, addNotification, removeNotification } =
    useContext(NotificationContext);
  return { notification, addNotification, removeNotification };
};

export default useNotifier;
