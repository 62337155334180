import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "600px",
    margin: "0 auto",
    "& .MuiTypography-root.MuiTypography-body2": {
      color: theme.palette.text.primary,
    },
    '& .MuiButtonBase-root[role="button"]': {
      color: theme.palette.static.white,
    },
    "& .MuiButtonBase-root:disabled, & .MuiButtonBase-root.Mui-disabled": {
      backgroundColor: theme.palette.static.mediumGrey,
      color: theme.palette.static.mildGrey,
    },
  },
  title: {
    marginBottom: 16,
    textTransform: "capitalize",
  },
  subtitle: {
    marginBottom: 16,
  },
  content: {
    minWidth: 640,
    maxHeight: "70vh",
    overflow: "hidden auto",
    padding: 32,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeBtn: {
    cursor: "pointer",
  },
}));

export default useStyles;
