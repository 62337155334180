import React, { useEffect, useState } from "react";

import { Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";

import useGlobalStyles from "../../assets/styles/global";

import useStyles from "./styles";

const CustomTabs = ({ tabList, value }) => {
  const [tab, setTab] = useState(value);
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  useEffect(() => {
    if (tab !== value) {
      setTab(value);
    }
  }, [value]);

  return (
    <Tabs
      value={tab}
      indicatorColor="primary"
      onChange={(_, number) => setTab(number)}
      aria-label="basic tabs"
      classes={{
        indicator: globalStyles.indicator,
      }}
    >
      {tabList
        .filter((_tab) => !_tab.hidden)
        .map((_tab) => {
          return (
            <Tab
              key={_tab.label}
              label={_tab.label}
              className={classes.tab}
              onClick={() => _tab.onClick()}
            />
          );
        })}
    </Tabs>
  );
};

CustomTabs.defaultProps = {
  value: 0,
};

CustomTabs.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  value: PropTypes.number,
};

export default CustomTabs;
