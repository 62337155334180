const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    return new Promise((resolve, reject) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        try {
          await func.apply(this, args);
          resolve();
        } finally {
          reject();
        }
      }, timeout);
    });
  };
};

export default debounce;
