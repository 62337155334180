import { useCallback, useEffect, useMemo, useState, Fragment } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import createConsentMappings from "../../../api/create-consent-mappings";
import getConsentDetails from "../../../api/get-consent-details";
import getConsentTemplates from "../../../api/get-consent-templates";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import consentsModuleConfig from "../../../config/consentsModuleConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";

import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";

import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";

import NewOptIdMapping from "../../Opts/components/NewOptIdMapping";
import NewServiceName from "../../Opts/components/NewServiceName";
import SelectedService from "../../Opts/components/SelectedService";
import ConfirmationDetails from "../NewConsentTemplateContainer/components/ConfirmationDetails";
import useConsentTemplateTypesAndCountries from "../NewConsentTemplateContainer/helpers/useConsentTemplateTypesAndCountries";

import ConsentIdRequestOutputModal from "./components/ConsentIdRequestOutputModal";

import ConsentID from "./ConsentID";
import ConsentTexts from "./ConsentTexts";
import TemplateTexts from "./ConsentTexts/TemplateTexts";

import createBackendPayload from "./helpers/createBackendPayload";
import extractPlaceholders from "./helpers/extractPlaceholders";
import validateConsentMappingForm from "./helpers/validateConsentMappingForm";
import validateConsentTexts from "./helpers/validateConsentTexts";

import useStyles from "./styles";

const totalSteps = 4;

const NewConsentContainer = () => {
  const { t } = useTranslation();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const { user } = useUserProfile();
  const { request, setRequest } = useRequest();
  const { addNotification } = useNotifier();
  const accordionClasses = useAccordionStyles();
  const classes = useStyles();
  const { createConsentIDConstants } = consentsModuleConfig;
  const { requestStatus } = optsModuleConfig;
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.manageConsents
  );
  const { consentTemplateTypes, loading: consentTemplatesLoading } =
    useConsentTemplateTypesAndCountries();

  const isRequestRevisionFlow = useMemo(
    () => Object.keys(request).length !== 0,
    [request]
  );
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);
  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [initialStep, setInitialStep] = useState(1);

  const [consentTemplateTypeValue, setConsentTemplateTypeValue] = useState({});
  const [marketingProgramValue, setMarketingProgramValue] = useState();
  const [countryValue, setCountryValue] = useState({});
  const [languagesValue, setLanguagesValue] = useState([]);
  const [consentTemplates, setConsentTemplates] = useState();
  const [updatedConsentTexts, setUpdatedConsentTexts] = useState({});
  const [templateVariablesValues, setTemplateVariablesValues] = useState({});

  const [consentMappingAlertInfo, setConsentMappingAlertInfo] = useState();
  const [urlInfo, setUrlInfo] = useState();
  const [showConsentIdValidation, setShowConsentIdValidation] = useState(false);
  const [showConsentTextValidation, setShowConsentTextValidation] =
    useState(false);
  const [consentMappingInfo, setConsentMappingInfo] = useState(null);
  const [mappingResponse, setMappingResponse] = useState({});
  const [output, setOutput] = useState({});
  const [showOutput, setShowOutput] = useState(false);
  useEffect(() => {
    if (state) {
      setConsentMappingInfo(state);
      setConsentTemplateTypeValue({
        consentTemplateTypeId:
          state?.consentDetails?.consentTemplateType?.split(" - ")?.[0],
        description:
          state?.consentDetails?.consentTemplateType?.split(" - ")?.[1],
        title: state?.consentDetails?.consentTemplateType,
      });
      setUrlInfo(
        `Your Updated Consent Details will be available in this URL(https://api.pg.com/brandbuilding/consumer/V2/datadictionary/consents?consentId=${state?.consentDetails?.consentId}) once the request is approved.`
      );
      setInitialStep(2);
      setCurrentStep(2);
    }
  }, [state]);

  const [templateVariableValuesLoaded, setTemplateVariablesValuesLoaded] =
    useState(false);

  useEffect(() => {
    if (
      consentMappingInfo?.consentVersions &&
      Object.keys(templateVariablesValues).length === 0 &&
      consentTemplates &&
      !templateVariableValuesLoaded
    ) {
      let newTemplateVariablesValues = {};
      consentMappingInfo?.consentVersions?.forEach((consentVersion) => {
        const consentTemplateTypeId =
          consentMappingInfo?.consentDetails?.consentTemplateType?.split(
            " - "
          )?.[0];
        const countryCode = consentVersion?.country.split(" - ")?.[0];
        const languageCode = consentVersion?.language?.split(" - ")?.[0];
        const consentTemplateKey = `${consentTemplateTypeId}-${countryCode}-${languageCode}`;
        const consentTemplate = consentTemplates.find(
          (template) => template.consentTemplateKey === consentTemplateKey
        );
        if (consentTemplate) {
          const placeholders = extractPlaceholders(
            consentTemplate.consentText,
            consentVersion.consentText
          );
          newTemplateVariablesValues = {
            ...newTemplateVariablesValues,
            ...placeholders,
          };
        }
      });
      setTemplateVariablesValues(newTemplateVariablesValues);
      setTemplateVariablesValuesLoaded(true);
    }
  }, [consentMappingInfo, templateVariablesValues, consentTemplates]);

  const existingOptMappings = useMemo(() => {
    if (consentMappingInfo) {
      return consentMappingInfo?.consentMappings?.map((mapping) => ({
        ...mapping,
        cdpMarketingProgram: `${mapping.cdpMarketingProgramNumber} - ${mapping.cdpMarketingProgramDescription}`,
        channel: mapping.contactPointTypeName,
        contactPointCategoryCode: mapping.contactPointCategoryDescription,
      }));
    }
    return [];
  }, [consentMappingInfo]);
  const [optMappings, setOptMappings] = useImmer([]);
  const [showOptMappingValidaion, setShowOptMappingValidation] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [optMappingIndex, setOptMappingIndex] = useState(-1);
  const [newServiceName, setNewServiceName] = useImmer({
    marketingProgramName: "",
    serviceName: "",
    serviceNameDescription: "",
  });
  const [serviceName, setServiceName] = useState({});
  const [newServiceNameModal, setNewServiceNameModal] = useState(false);
  const [openOptMappingModal, setOpenOptMappingModal] = useState(false);
  const [optMappingInfo, setOptMappingInfo] = useImmer({
    channel: "",
    contactPointCategoryCode: "",
    serviceName: "",
    primaryIndicator: "",
    subscriptionOptNumber: 1,
    cdpMarketingProgram: "",
  });

  const [cdpMarketingProgramOptions, setCdpMarketingProgramOptions] = useState(
    []
  );
  const [cdpMarketingProgramsLoading, setCdpMarketingProgramsLoading] =
    useState(false);

  useEffect(() => {
    if (consentMappingInfo) {
      const countryInfo =
        consentMappingInfo?.consentDetails?.country.split(" - ");
      setCountryValue({
        country: countryInfo[1],
        countryCode: countryInfo[0],
        title: `${countryInfo[0]} - ${countryInfo[1]}`,
      });
      setLanguagesValue(
        consentMappingInfo.consentVersions.map((version) => ({
          ...version,
          languageCode: version.language?.split(" - ")[0],
          country: version.country?.split(" - ")[1],
          title: version.language,
        }))
      );
    }
  }, [consentMappingInfo]);

  useEffect(() => {
    if (Object.keys(consentTemplateTypeValue).length > 0) {
      (async () => {
        try {
          increaseRequestsCount();
          const data = await getConsentTemplates(
            null,
            parseSearchFilter(
              [
                {
                  name: "consentTemplateTypeId",
                  value: consentTemplateTypeValue.consentTemplateTypeId,
                },
              ],
              {
                consentTemplateTypeId: "searchText",
                country: "country",
              },
              1,
              50
            )
          );
          setConsentTemplates(data?.items || []);
        } catch (error) {
          handleError({
            error,
            handle404: false,
            addNotification,
          });
        } finally {
          decreaseRequestsCount();
        }
      })();
    }
  }, [consentTemplateTypeValue]);

  const initializeConsentMapping = useCallback(
    async (consentTemplateType, marketingProgram) => {
      try {
        increaseRequestsCount();
        const data = await getConsentDetails(
          `${marketingProgram?.marketingProgramNumber}_${consentTemplateType?.consentTemplateTypeId}`
        );
        setConsentMappingInfo(data);
        return data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        decreaseRequestsCount();
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      const errors = validateConsentMappingForm(
        consentTemplateTypeValue,
        marketingProgramValue
      );
      if (Object.keys(errors).length === 0) {
        setConsentMappingInfo(null);
        const data = await initializeConsentMapping(
          consentTemplateTypeValue,
          marketingProgramValue
        );
        const consentId =
          data?.consentDetails?.consentId ||
          `${marketingProgramValue.marketingProgramNumber}_${consentTemplateTypeValue.consentTemplateTypeId}`;
        if (data) {
          setConsentMappingAlertInfo(
            `Consent ID ${consentId} already exists, Styles and Mappings can be updated in next steps`
          );
          setUrlInfo(
            `Your Updated Consent Details will be available in this URL(https://api.pg.com/brandbuilding/consumer/V2/datadictionary/consents?consentId=${consentId}) once the request is approved.`
          );
        } else {
          setConsentMappingAlertInfo(
            `Consent ID ${consentId} will be created, Styles and Mappings can be updated in next steps`
          );
          setUrlInfo(
            `Your Consent Details will be available in this URL(https://api.pg.com/brandbuilding/consumer/V2/datadictionary/consents?consentId=${consentId}) once the request is approved.`
          );
        }
      }
    })();
  }, [
    initializeConsentMapping,
    marketingProgramValue,
    consentTemplateTypeValue,
  ]);

  const confirmationDetails = useMemo(() => {
    const details = [
      {
        label: "Consent ID",
        value:
          consentMappingInfo?.consentDetails?.consentId ||
          `${marketingProgramValue?.marketingProgramNumber}_${consentTemplateTypeValue?.consentTemplateTypeId}`,
      },
      {
        label: "Consent Template Type",
        value:
          consentMappingInfo?.consentDetails?.consentTemplateType ||
          `${consentTemplateTypeValue?.consentTemplateTypeId} - ${consentTemplateTypeValue?.description}`,
      },
      {
        label: "Marketing Program",
        value:
          consentMappingInfo?.consentDetails?.marketingProgram ||
          `${marketingProgramValue?.marketingProgramNumber} - ${marketingProgramValue?.description}`,
      },
    ];
    if (consentMappingInfo) {
      details.push({
        label: "Country",
        value: consentMappingInfo?.consentDetails?.country,
      });
    }
    return details;
  }, [consentMappingInfo, consentTemplateTypeValue]);

  const renderOptMappingAccordion = (
    optMapping,
    index,
    custom,
    statusLabel
  ) => {
    return (
      <>
        <Accordion
          className={custom ? "" : accordionClasses.accordion}
          data-test-id={`opt-mapping-${index.toString()}`}
        >
          <AccordionSummary
            className={accordionClasses.accordionHeader}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={clsx(classes.flexContainer, classes.fullWidth)}>
              <Typography className={accordionClasses.systemType}>
                {custom
                  ? optMapping.channel.contact_point_type_name
                  : optMapping.contactPointTypeName}
              </Typography>
              <Typography
                variant="body2"
                className={accordionClasses.systemName}
              >
                {custom
                  ? optMapping.cdpMarketingProgram.title
                  : optMapping.cdpMarketingProgram}
              </Typography>
              {statusLabel && (
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.statusLabel,
                    statusLabel === applicationConfig.status.new
                      ? classes.new
                      : ""
                  )}
                >
                  {statusLabel}
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={accordionClasses.accordionContent}>
            <AccordionRowDetails
              keys={[
                {
                  id: "cdpMarketingProgram",
                  name: t("common.labels.marketing_program"),
                },
                {
                  id: "serviceName",
                  name: t("common.labels.service_name"),
                },
                {
                  id: "channel",
                  name: t("common.labels.channel"),
                },
                {
                  id: "contactPointCategoryCode",
                  name: t("opts_mapping.contact_point_category_code"),
                },
                {
                  id: "primaryIndicator",
                  name: t("opts_mapping.primary_indicator"),
                },
                {
                  id: "subscriptionOptNumber",
                  name: t("opts_mapping.subscription_opt_number"),
                },
              ]}
              row={
                custom
                  ? {
                      channel: optMapping.channel.contact_point_type_name,
                      contactPointCategoryCode:
                        optMapping.contactPointCategoryCode
                          .contact_point_category_name,
                      serviceName:
                        typeof optMapping.serviceName === "object" ? (
                          <SelectedService
                            isNewService
                            serviceName={optMapping.serviceName.serviceName}
                            openDetailsModal={() => {
                              setOptMappingIndex(index);
                              setNewServiceName((draft) => {
                                draft.marketingProgramName =
                                  optMapping.serviceName.marketingProgramName;
                                draft.serviceName =
                                  optMapping.serviceName.serviceName;
                                draft.serviceNameDescription =
                                  optMapping.serviceName.serviceNameDescription;
                              });
                              setNewServiceNameModal(true);
                            }}
                            isConfirmationStep
                            isDisabled
                            hideTitle
                          />
                        ) : (
                          optMapping.serviceName
                        ),
                      primaryIndicator: optMapping.primaryIndicator,
                      subscriptionOptNumber: optMapping.subscriptionOptNumber,
                      cdpMarketingProgram: optMapping.cdpMarketingProgram.title,
                    }
                  : optMapping
              }
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const getMarketingProgramsFromApi = useCallback(
    async (searchText, legalEntityId) => {
      let filter = { itemsPerPage: 3, page: 1 };
      if (searchText && searchText.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      if (legalEntityId) {
        filter = {
          ...filter,
          legalEntity: legalEntityId,
        };
      }
      try {
        const rsp1 = await getMarketingProgramsApi(filter);
        setCdpMarketingProgramOptions(rsp1.items);
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setCdpMarketingProgramOptions([]);
          },
          addNotification,
        });
      } finally {
        setCdpMarketingProgramsLoading(false);
      }
    },
    []
  );

  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );

  const memoizedMarketingProgramsFromApi = useCallback((val, legalEntityId) => {
    debouncedMarketingProgramsFromApi(val, legalEntityId);
  }, []);

  const consentTemplatesTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "consentId",
      headerName: t("common.labels.consent_id"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <span>{params?.row?.consent?.consentId}</span>;
      },
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { marketingProgramNumber, description } =
          params.row.marketingProgram;
        return <span>{`${marketingProgramNumber} - ${description}`}</span>;
      },
    },
    {
      field: "consentTemplateType",
      headerName: t("common.labels.consent_template_type"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>{params.row?.consentTemplateType?.consentTemplateTypeId}</span>
        );
      },
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  setShowOutput(true);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    if (request.requestDetails) {
      const { marketingProgram, consentTemplateType } = request.requestDetails;
      setMarketingProgramValue({
        ...marketingProgram,
        title: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
      });
      setConsentTemplateTypeValue({
        ...consentTemplateType,
        title: `${consentTemplateType.consentTemplateTypeId} - ${consentTemplateType.description}`,
      });
    }
  }, [isRequestRevisionFlow]);

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/consents"
      goToText={t("access_denied.go_to_consents")}
    />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading}
        steps={[
          t("create_consent.headings.step_1"),
          t("create_consent.headings.step_2"),
          t("create_consent.headings.step_3"),
          t("create_consent.headings.step_4"),
        ]}
        headerText={t("create_consent.headerText")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: globalStyles.btn,
                  }}
                  onClick={() => {
                    if (currentStep === initialStep) {
                      history.goBack();
                    } else {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {t("common.back")}
                </Button>
                <Button
                  onClick={async () => {
                    if (loading) return;
                    if (currentStep === 1) {
                      const errs = validateConsentMappingForm(
                        consentTemplateTypeValue,
                        marketingProgramValue
                      );
                      if (Object.keys(errs).length !== 0) {
                        setShowConsentIdValidation(true);
                        return;
                      }
                      setConsentMappingInfo(null);
                      initializeConsentMapping(
                        consentTemplateTypeValue,
                        marketingProgramValue
                      );
                      setCurrentStep(currentStep + 1);
                    } else if (currentStep === 2) {
                      const errs = validateConsentTexts({
                        countryValue,
                        updatedConsentTexts,
                        languagesValue,
                        templateVariablesValues,
                      });
                      if (Object.keys(errs).length !== 0) {
                        setShowConsentTextValidation(true);
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } else if (currentStep === 3) {
                      if (optMappings.length === 0 && !isRequestRevisionFlow) {
                        setShowOptMappingValidation(true);
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } else if (currentStep === 4) {
                      try {
                        const payload = createBackendPayload(
                          marketingProgramValue,
                          consentTemplateTypeValue,
                          optMappings,
                          updatedConsentTexts,
                          consentTemplates,
                          consentMappingInfo,
                          templateVariablesValues
                        );
                        increaseRequestsCount();
                        if (!isRequestRevisionFlow) {
                          const data = await createConsentMappings(payload);

                          const { items } = data;

                          setMappingResponse(
                            items.map((item, index) => ({
                              id: index,
                              ...item,
                              status:
                                item.status[0].toUpperCase() +
                                item.status.slice(1),
                            }))
                          );
                          setOutput(items?.[0].output);
                        } else {
                          const data = await updateRequest(
                            request.requestId,
                            payload,
                            applicationConfig.modules.consents
                          );
                          setIsRequestRevisionUpdated(true);
                          setRequest(data);
                          history.goBack();
                          addNotification(
                            t("notifications.request_edited_success"),
                            t("status.success")
                          );
                          return;
                        }
                        setCurrentStep(currentStep + 1);
                      } catch (error) {
                        handleError({
                          error,
                          handle404: false,
                          addNotification,
                        });
                      } finally {
                        decreaseRequestsCount();
                      }
                    }
                  }}
                  classes={{
                    root: globalStyles.btn,
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.7,
                      }}
                    >
                      <CircularProgress
                        color="palette.static.white"
                        size={20}
                      />
                    </Box>
                  )}
                  {createConsentIDConstants.footerText[currentStep]}
                </Button>
              </>
            )}
            {currentStep > totalSteps && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/consents"
                >
                  {t("create_consent_template.back_to_consents")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${mappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div className={clsx(classes.newConsentContainer)}>
          {currentStep === 1 && (
            <>
              <ConsentID
                consentTemplateTypeValue={consentTemplateTypeValue}
                setConsentTemplateTypeValue={setConsentTemplateTypeValue}
                consentTemplateTypes={consentTemplateTypes}
                marketingProgramSelected={marketingProgramValue}
                setMarketingProgramSelected={setMarketingProgramValue}
                showValidation={showConsentIdValidation}
                consentTemplatesLoading={consentTemplatesLoading}
              />
              {consentMappingAlertInfo && !loading && (
                <Alert severity="info" style={{ marginTop: 20 }}>
                  <Typography variant="body2">
                    {consentMappingAlertInfo}
                  </Typography>
                </Alert>
              )}
              {consentMappingAlertInfo && (
                <Alert severity="info" style={{ marginTop: 20 }}>
                  <Typography variant="body2">{urlInfo}</Typography>
                </Alert>
              )}
            </>
          )}
          {currentStep === 2 && (
            <>
              <ConfirmationDetails details={confirmationDetails} />
              <ConsentTexts
                isExisting={consentMappingInfo !== null}
                consentTemplateTypeValue={consentTemplateTypeValue}
                countryValue={countryValue}
                languagesValue={languagesValue}
                setCountryValue={setCountryValue}
                consentTemplates={consentTemplates}
                setLanguagesValue={setLanguagesValue}
                updatedConsentTexts={updatedConsentTexts}
                setUpdatedConsentTexts={setUpdatedConsentTexts}
                showValidation={showConsentTextValidation}
                templateVariablesValues={templateVariablesValues}
                setTemplateVariablesValues={setTemplateVariablesValues}
                mpCountryCode={
                  marketingProgramValue?.marketingProgramName?.split("-")?.[1]
                }
                consentMappingInfo={consentMappingInfo}
              />
            </>
          )}
          {currentStep === 3 && (
            <div>
              <ConfirmationDetails details={confirmationDetails} />
              <div className={classes.infoContainer}>
                <Typography variant="h6">
                  {t("common.labels.opt_mappings")}
                </Typography>
              </div>
              {existingOptMappings.length === 0 && optMappings.length === 0 && (
                <Box sx={{ mb: 2 }}>
                  <Alert severity="info">
                    {t("new_opt_id.no_opt_mappings")}
                  </Alert>
                </Box>
              )}
              {existingOptMappings.map((optMapping, index) => {
                return renderOptMappingAccordion(optMapping, index, false);
              })}
              {optMappings.map((optMapping, index) => {
                return (
                  <div
                    key={`${
                      optMapping.cdpMarketingProgram.marketingProgramNumber
                    }-${index + 1}`}
                    className={clsx(
                      classes.flexContainer,
                      classes.fullWidth,
                      classes.mb20
                    )}
                  >
                    {renderOptMappingAccordion(
                      optMapping,
                      index,
                      true,
                      applicationConfig.status.new
                    )}
                    <div className={clsx(classes.flexContainer, classes.ml12)}>
                      <div
                        onClick={async () => {
                          setOpenOptMappingModal(true);
                          setOptMappingIndex(index);
                          setOptMappingInfo(optMapping);
                          if (optMapping.serviceName.isNewService) {
                            setServiceName(optMapping.serviceName);
                          } else {
                            setServiceName({});
                          }
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                        data-testid="button-1"
                      >
                        <span className={globalStyles.editIcon}>
                          <EditOutlinedIcon />
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setOptMappingIndex(index);
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        aria-label="delete"
                        data-testid="button-2"
                      >
                        <span className={globalStyles.deleteIcon}>
                          <DeleteOutlineOutlinedIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                {showOptMappingValidaion && isEmpty(optMappings) && (
                  <Alert severity="error">
                    Please add any new Opt Mappings to proceed
                  </Alert>
                )}
              </div>
              <div className={globalStyles.addTraitBtn}>
                <AddCircleOutlineIcon />
                <Button
                  onClick={() => {
                    if (cdpMarketingProgramOptions.length === 0) {
                      getMarketingProgramsFromApi("");
                    }
                    setOpenOptMappingModal(true);
                  }}
                >
                  {t("new_opt_id.add_opt_mapping")}
                </Button>
              </div>
              <ConfirmationModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                title={t("dialogs.confirm_remove")}
                message={
                  currentStep === 1
                    ? t("new_opt_id.dialogs.opt_id_warning")
                    : t("new_opt_id.dialogs.opt_mapping_warning")
                }
                btn1Text={t("common.cancel")}
                btn2Text={t("common.ok")}
                btn2Action={async () => {
                  setOptMappings(
                    produce((draft) => {
                      draft.splice(optMappingIndex, 1);
                    })
                  );
                  setOptMappingIndex(-1);
                  setOpenDeleteModal(false);
                }}
                type="error"
              />
              {newServiceNameModal && (
                <NewServiceName
                  isOpen={newServiceNameModal}
                  newServiceName={newServiceName}
                  setNewServiceName={setNewServiceName}
                  marketingProgramNumber={
                    optMappings[optMappingIndex].cdpMarketingProgram
                      .marketingProgramNumber
                  }
                  isDisabled
                  marketingProgram={
                    optMappings[optMappingIndex].cdpMarketingProgram
                  }
                  setNewServiceNameModal={setNewServiceNameModal}
                />
              )}
              <NewOptIdMapping
                cdpMarketingProgramOptions={cdpMarketingProgramOptions}
                cdpMarketingProgramsLoading={cdpMarketingProgramsLoading}
                getcdpMarketingProgramOptions={async (marketingProgram) => {
                  setCdpMarketingProgramsLoading(true);
                  memoizedMarketingProgramsFromApi(
                    marketingProgram,
                    Number(
                      consentMappingInfo?.consentDetails?.legalEntity?.split(
                        " - "
                      )[0]
                    )
                  );
                }}
                openModal={openOptMappingModal}
                handleClose={() => {
                  setOpenOptMappingModal(false);
                  setOptMappingIndex(-1);
                }}
                existingOptMappings={existingOptMappings}
                optMappings={optMappings}
                editFlow={optMappingIndex > -1}
                handleSubmit={(data) => {
                  if (optMappingIndex !== -1) {
                    setOptMappings(
                      produce((draft) => {
                        draft[optMappingIndex] = data;
                      })
                    );
                  } else {
                    setOptMappings(
                      produce((draft) => {
                        draft.push(data);
                      })
                    );
                  }
                  setOptMappingIndex(-1);
                }}
                serviceName={serviceName}
                setServiceName={setServiceName}
                optMappingInfo={optMappingInfo}
                setOptMappingInfo={setOptMappingInfo}
                isDisabled={false}
                title={t("new_opt_id.new_opt_mapping")}
              />
            </div>
          )}
          {currentStep === 4 && (
            <>
              <ConfirmationDetails details={confirmationDetails} />
              <Alert severity="info" style={{ marginTop: 20 }}>
                <Typography variant="body2">{urlInfo}</Typography>
              </Alert>
              <div className={classes.confirmationContainer}>
                <Typography
                  style={{
                    marginBottom: "16px",
                  }}
                  variant="h6"
                >
                  Consent Texts
                </Typography>
                <TemplateTexts
                  updatedConsentTexts={updatedConsentTexts}
                  // setUpdatedConsentTexts={setUpdatedConsentTexts}
                  isDisabled
                  templateVariablesValues={templateVariablesValues}
                />
              </div>
              <div className={classes.confirmationContainer}>
                <Typography
                  style={{
                    marginBottom: "16px",
                  }}
                  variant="h6"
                >
                  {t("common.labels.opt_mappings")}
                </Typography>
                {optMappings.map((optMapping, index) => {
                  return (
                    <div
                      key={`${
                        optMapping.cdpMarketingProgram.marketingProgramNumber
                      }-${index + 1}`}
                      className={clsx(
                        classes.flexContainer,
                        classes.fullWidth,
                        classes.trait
                      )}
                    >
                      {renderOptMappingAccordion(
                        optMapping,
                        index,
                        true,
                        applicationConfig.status.new
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {currentStep > 4 && (
            <div
              style={{
                height: 300 * mappingResponse.length + 60,
                maxHeight: "calc(100vh - 100px)",
                overflow: "hidden",
              }}
            >
              <Table
                columns={consentTemplatesTableColumns}
                rows={mappingResponse}
              />
            </div>
          )}
        </div>
        {showOutput && (
          <ConsentIdRequestOutputModal
            isOpen={showOutput}
            isLoading={false}
            requestId={mappingResponse[0]?.requestId}
            marketingProgramDescription={
              mappingResponse && mappingResponse[0]?.marketingProgram
                ? `${mappingResponse[0]?.marketingProgram?.marketingProgramNumber} - ${mappingResponse[0]?.marketingProgram?.description}`
                : ""
            }
            data={output}
            setShowOutput={() => setShowOutput(false)}
          />
        )}
      </InputFlow>
    </>
  );
};

export default NewConsentContainer;
