import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    userSelect: "none",
  },
  modalWrapper: {
    width: "800px",
    height: "300px",
    overflowY: "hidden",
    display: "flex",
    alignItems: "center",
    "& .MuiCardContent-root": {
      flex: 1,
      height: "60%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "& .MuiButton-contained": {
        backgroundColor: theme.palette.green.main2,
        padding: "10px 25px",
        color: theme.palette.static.white,
      },
    },
  },
  modalLeftContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& svg": {
      zIndex: 1,
      position: "absolute",
      top: "30px",
      left: "30px",
      width: "115px",
      height: "50px",
    },
    "& ul": {
      marginRight: "unset",
    },
  },
  infoList: {
    color: theme.palette.static.mutedWhite,
    height: "100%",
    width: "300px",
    position: "relative",
    margin: "unset",
    zIndex: 0,
    fontSize: "18px",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginRight: "60px",
    "&::after": {
      zIndex: -1,
      content: "''",
      position: "absolute",
      top: "-40px",
      left: "-180px",
      backgroundColor: theme.palette.blue.darker,
      height: "150%",
      width: "150%",
      transform: "skew(-15deg)",
    },
  },
  menuItem: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  },
  footerContainer: {
    marginTop: "20px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiCircularProgress-colorPrimary": {
      color: theme.colorTheme.black,
    },
  },
}));

export default useStyles;
