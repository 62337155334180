import { createContext, useCallback, useReducer } from "react";

import PropTypes from "prop-types";

import { actions, reducer } from "./reducer";

export const LoadingSpinnerContext = createContext({
  loading: false,
  increaseRequestsCount: () => {},
  decreaseRequestsCount: () => {},
});

export default function LoadingSpinnerProvider({ children }) {
  const initialState = {
    count: 0,
    loading: true,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const increaseRequestsCount = useCallback(
    (noOfRequests = 1) => {
      dispatch({
        type: actions.INCREMENT_REQUESTS_COUNT,
        payload: noOfRequests,
      });
    },
    [dispatch]
  );

  const decreaseRequestsCount = useCallback(
    (noOfRequests = 1) => {
      dispatch({
        type: actions.DECREMENT_REQUESTS_COUNT,
        payload: noOfRequests,
      });
    },
    [dispatch]
  );

  const value = {
    loading: state.loading,
    increaseRequestsCount,
    decreaseRequestsCount,
  };

  return (
    <LoadingSpinnerContext.Provider value={value}>
      {children}
    </LoadingSpinnerContext.Provider>
  );
}

LoadingSpinnerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
