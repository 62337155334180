import { useState, useEffect, useCallback, useRef } from "react";

import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
} from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Skeleton, Alert } from "@material-ui/lab";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Prompt, Link } from "react-router-dom";

import createNewOpt from "../../../api/create-new-opt";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import getOpts from "../../../api/get-opts";
import getOptsDetails from "../../../api/get-opts-details";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";
import AccessDenied from "../../../components/AccessDenied";
import useAccordionStyles from "../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
// import InlineMessage from "../../../components/InlineMessage";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import TextBox from "../../../components/TextBox";

import applicationConfig from "../../../config/applicationConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";
import NewOptIdMapping from "../components/NewOptIdMapping";

import NewServiceName from "../components/NewServiceName";
import SelectedService from "../components/SelectedService";

import NewOptIdModal from "./components/NewOptIdModal";
import OptIdRequestOutputModal from "./components/OptIdRequestOutputModal";
import createBackendPayload from "./helpers/createBackendPayload";
import useStyles from "./styles";

const NewOptIdContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const accordionClasses = useAccordionStyles();

  const { user } = useUserProfile();
  const location = useLocation();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createOpts
  );

  const history = useHistory();

  const { request, setRequest } = useRequest();

  const totalSteps = 3;

  const [currentStep, setCurrentStep] = useState(1);
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const isInfoStep = currentStep > totalSteps;

  const { createOptIdConstants, requestStatus } = optsModuleConfig;

  const { addNotification } = useNotifier();

  const ciamOptId = location.state?.ciamOptId;

  const [optValue, setOptValue] = useState("");
  // const [marketingProgram, setMarketingProgram] = useState(null);

  const [optsMappingInfo, setOptsMappingInfo] = useImmer({
    optId: "",
    optText: "",
    optTextLocal: "",
    marketingProgram: "",
    marketingProgramNumber: "",
    marketingProgramDescription: "",
  });

  const [existingOptMappings, setExistingOptMappings] = useState([]);

  const [openOptTextWarningModal, setOpenOptTextWarningModal] = useState(false);
  const [openOptMappingModal, setOpenOptMappingModal] = useState(false);

  const [optMappingIndex, setOptMappingIndex] = useState(-1);

  const [cdpMarketingProgramOptions, setCdpMarketingProgramOptions] = useState(
    []
  );
  const [cdpMarketingProgramsLoading, setCdpMarketingProgramsLoading] =
    useState(false);

  const [showOptOutput, setShowOptOutput] = useState(false);

  const [optsOutput, setOptsOutput] = useState({});

  const [opts, setOpts] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [optMappings, setOptMappings] = useImmer([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [optsLoading, setOptsLoading] = useState(true);

  const [errors, setErrors] = useState({});

  const [optIdValue, setOptIdValue] = useState({
    new: false,
    title: "",
  });

  const alertRef = useRef(null);

  const { t } = useTranslation();

  const [mappingResponse, setMappingResponse] = useState([]);

  const [newServiceName, setNewServiceName] = useImmer({
    marketingProgramName: "",
    serviceName: "",
    serviceNameDescription: "",
  });

  const [serviceName, setServiceName] = useState({});

  const [optMappingInfo, setOptMappingInfo] = useImmer({
    channel: "",
    contactPointCategoryCode: "",
    serviceName: "",
    primaryIndicator: "",
    subscriptionOptNumber: 1,
    cdpMarketingProgram: "",
  });

  const [newServiceNameModal, setNewServiceNameModal] = useState(false);
  const [initialOpts, setInitialOpts] = useState([]);
  // Marketing Program
  const [ciamMarketingProgramOptions, setCiamMarketingProgramOptions] =
    useState([]);
  const [ciamMarketingProgramsLoading, setCiamMarketingProgramsLoading] =
    useState(true);

  const [optInfo, setOptInfo] = useImmer({
    ciamMarketingProgram: "",
    optTextEnglish: "",
    optTextLocal: "",
  });

  const isRequestRevisionFlow = Object.keys(request).length !== 0;
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const checkDisabledStatus = (step) => {
    if (step === 1) {
      if (Object.values(optInfo).some((val) => isEmpty(val))) {
        return true;
      }
    } else if (step === 2) {
      if (optMappings.length < 1) {
        return true;
      }
    }
    return false;
  };

  const getMarketingProgramsFromApi = useCallback(
    async (searchText, legalEntityId) => {
      let filter = { itemsPerPage: 3, page: 1 };
      if (searchText && searchText.length > 0) {
        filter = {
          ...filter,
          searchText,
        };
      }
      if (legalEntityId) {
        filter = {
          ...filter,
          legalEntity: legalEntityId,
        };
      }
      try {
        const rsp1 = await getMarketingProgramsApi(filter);
        if (legalEntityId) {
          setCdpMarketingProgramOptions(rsp1.items);
        } else {
          setCiamMarketingProgramOptions(rsp1.items);
        }
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            if (legalEntityId) {
              setCdpMarketingProgramOptions([]);
            } else {
              setCiamMarketingProgramOptions([]);
            }
          },
          addNotification,
        });
      } finally {
        if (legalEntityId) {
          setCdpMarketingProgramsLoading(false);
        } else {
          setCiamMarketingProgramsLoading(false);
        }
      }
    },
    []
  );

  const newOptId = (
    <div className="newOpt">
      <Typography variant="h6">
        {t("create_trait.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
          // setIsNewOptId(true);
        }}
      >
        {t("opts_container.new_opt_id")}
      </Button>
    </div>
  );

  const fetchOptMappings = async () => {
    if (optIdValue.new) {
      setExistingOptMappings([]);
      return null;
    }
    try {
      increaseRequestsCount();
      const optDetails = await getOptsDetails(optIdValue.title);
      setExistingOptMappings(
        optDetails.optMappings.map((mapping) => ({
          ...mapping,
          cdpMarketingProgram: `${mapping.cdpMarketingProgramNumber} - ${mapping.cdpMarketingProgramDescription}`,
          channel: mapping.contactPointTypeName,
          contactPointCategoryCode: mapping.contactPointCategoryDescription,
        }))
      );
    } catch (error) {
      console.error("Error fetching optMappings:", error);
    } finally {
      decreaseRequestsCount();
    }
    return null;
  };

  const validateForm = (step) => {
    const formErrors = {};
    if (step === 1) {
      if (isEmpty(optIdValue.title)) {
        formErrors.optId = "Please add atleast one opt Id to proceed";
      }
    }
    if (step === 2) {
      if (optMappings.length === 0) {
        formErrors.optMappings = "Please add any new Opt Mappings to proceed";
      }
      if (alertRef.current) {
        alertRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    return formErrors;
  };

  const getOptsFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
      };
    }
    const filterFieldMap = {
      searchText: "searchText",
    };
    try {
      const rsp1 = await getOpts(
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap,
          1,
          3
        )
      );
      setOpts([...rsp1.items]);
      if (searchText.length === 0) {
        setInitialOpts([...rsp1.items]);
      }
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setOpts([]);
        },
        addNotification,
      });
    } finally {
      setOptsLoading(false);
    }
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );

  const memoizedMarketingProgramsFromApi = useCallback((val, legalEntityId) => {
    debouncedMarketingProgramsFromApi(val, legalEntityId);
  }, []);

  // Debounce & Memoize
  const debouncedOptsFromApi = debounce(
    getOptsFromApi,
    applicationConfig.waitTime
  );

  const memoizedOptsFromApi = useCallback((searchText) => {
    debouncedOptsFromApi(searchText);
  }, []);

  const setOptsMappingInfoFromApi = async (data) => {
    const { opt, marketingProgram, marketingProgramOpts } = data.requestDetails;
    setOptInfo((draft) => {
      draft.ciamMarketingProgram = {
        ...marketingProgram,
        title: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
      };
      draft.optTextEnglish = opt.optTextEnglish;
      draft.optTextLocal = opt.optTextLocalLanguage;
    });

    setOptIdValue({
      new: false,
      title: opt.optId,
    });

    setOptsMappingInfo({
      optText: opt.optTextEnglish,
      ciamLegalEntityId: marketingProgram.legalEntityNumber,
      marketingProgramNumber: marketingProgram.marketingProgramNumber,
      marketingProgramDescription: marketingProgram.description,
      optId: opt.optId,
    });

    const optMappingsInfo = marketingProgramOpts.map((optMapping) => {
      let serviceNameInfo = "";
      if (optMapping.service.isNew) {
        serviceNameInfo = {
          ...optMapping.service,
          isNewService: true,
        };
      } else {
        serviceNameInfo = optMapping.service.serviceName;
      }
      return {
        primaryIndicator: optMapping.primaryIndicator,
        cdpMarketingProgram: {
          marketingProgramNumber:
            optMapping.marketingProgram.marketingProgramNumber,
          marketingProgramDescription: optMapping.marketingProgram.description,
          title: `${optMapping.marketingProgram.marketingProgramNumber} - ${optMapping.marketingProgram.description}`,
        },
        channel: {
          contact_point_type_code:
            optMapping.contactPointType.contactPointTypeCode,
          contact_point_type_name:
            optMapping.contactPointType.contactPointTypeName,
        },
        contactPointCategoryCode: {
          contact_point_category_code:
            optMapping.contactPointCategory.contactPointCategoryCode,
          description: optMapping.contactPointCategory.description,
          contact_point_category_name:
            optMapping.contactPointCategory.description,
        },
        subscriptionOptNumber: optMapping.subscriptionOptNumber,
        serviceName: serviceNameInfo,
      };
    });
    setOptMappings(optMappingsInfo);
  };

  useEffect(() => {
    if (location.pathname === applicationConfig.pathnames.opts_revision) {
      if (Object.keys(request).length === 0 && !ciamOptId) {
        history.goBack();
      }
    }
  }, []);

  useEffect(async () => {
    if (isRequestRevisionFlow) {
      setOptsMappingInfoFromApi(request);
    }
  }, []);

  useEffect(() => {
    if (currentStep === 2 && optMappings.length === 0) {
      fetchOptMappings();
    }
  }, [currentStep]);

  useEffect(() => {
    if (ciamOptId) {
      setOptIdValue({
        new: false,
        title: ciamOptId,
      });
      setOptsMappingInfo({
        optText: location.state.optTextEnglish,
        optTextLocal: location.state.optTextLocal,
        ciamLegalEntityId: location.state.ciamLegalEntityId,
        marketingProgramNumber: location.state.marketingProgramNumber,
        marketingProgramDescription: location.state.marketingProgramDescription,
        optId: ciamOptId,
      });
      getMarketingProgramsFromApi("", location.state.ciamLegalEntityId);
      setCurrentStep(2);
    }
  }, [ciamOptId]);

  useEffect(async () => {
    if (user.userId && isUserAuthorized && currentStep === 1 && !ciamOptId) {
      increaseRequestsCount();
      await getOptsFromApi("");
      await getMarketingProgramsFromApi("");
      decreaseRequestsCount();
    }
  }, [user]);

  useEffect(() => {
    if (optValue.length > 0) {
      memoizedOptsFromApi(optValue);
    }
  }, [optValue]);

  useEffect(() => {
    memoizedMarketingProgramsFromApi(
      "",
      optInfo.ciamMarketingProgram.legalEntityNumber
    );
  }, [optInfo.ciamMarketingProgram]);

  const optsRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "optTextEnglish",
      headerName: t("new_opt_id.opt_text_english"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
      },
    },
    {
      field: "optTextLocalLanguage",
      headerName: t("new_opt_id.opt_text_local"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
      },
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  setShowOptOutput(true);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const renderOptMappingAccordion = (
    optMapping,
    index,
    custom,
    statusLabel
  ) => {
    return (
      <>
        <Accordion
          className={custom ? "" : accordionClasses.accordion}
          data-test-id={`opt-mapping-${index.toString()}`}
        >
          <AccordionSummary
            className={accordionClasses.accordionHeader}
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={clsx(classes.flexContainer, classes.fullWidth)}>
              <Typography className={accordionClasses.systemType}>
                {custom
                  ? optMapping.channel.contact_point_type_name
                  : optMapping.contactPointTypeName}
              </Typography>
              <Typography
                variant="body2"
                className={accordionClasses.systemName}
              >
                {custom
                  ? optMapping.cdpMarketingProgram.title
                  : optMapping.cdpMarketingProgram}
              </Typography>
              {statusLabel && (
                <Typography
                  variant="body2"
                  className={clsx(
                    classes.statusLabel,
                    statusLabel === applicationConfig.status.new
                      ? classes.new
                      : ""
                  )}
                >
                  {statusLabel}
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={accordionClasses.accordionContent}>
            <AccordionRowDetails
              keys={[
                {
                  id: "cdpMarketingProgram",
                  name: t("common.labels.marketing_program"),
                },
                {
                  id: "serviceName",
                  name: t("common.labels.service_name"),
                },
                {
                  id: "channel",
                  name: t("common.labels.channel"),
                },
                {
                  id: "contactPointCategoryCode",
                  name: t("opts_mapping.contact_point_category_code"),
                },
                {
                  id: "primaryIndicator",
                  name: t("opts_mapping.primary_indicator"),
                },
                {
                  id: "subscriptionOptNumber",
                  name: t("opts_mapping.subscription_opt_number"),
                },
              ]}
              row={
                custom
                  ? {
                      channel: optMapping.channel.contact_point_type_name,
                      contactPointCategoryCode:
                        optMapping.contactPointCategoryCode
                          .contact_point_category_name,
                      serviceName:
                        typeof optMapping.serviceName === "object" ? (
                          <SelectedService
                            isNewService
                            serviceName={optMapping.serviceName.serviceName}
                            openDetailsModal={() => {
                              setOptMappingIndex(index);
                              setNewServiceName((draft) => {
                                draft.marketingProgramName =
                                  optMapping.serviceName.marketingProgramName;
                                draft.serviceName =
                                  optMapping.serviceName.serviceName;
                                draft.serviceNameDescription =
                                  optMapping.serviceName.serviceNameDescription;
                              });
                              setNewServiceNameModal(true);
                            }}
                            isConfirmationStep
                            isDisabled
                            hideTitle
                          />
                        ) : (
                          optMapping.serviceName
                        ),
                      primaryIndicator: optMapping.primaryIndicator,
                      subscriptionOptNumber: optMapping.subscriptionOptNumber,
                      cdpMarketingProgram: optMapping.cdpMarketingProgram.title,
                    }
                  : optMapping
              }
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const getConfirmationDetails = () => {
    if (optIdValue.new) {
      return [
        {
          label: t("common.labels.opt_details"),
          value: (
            <div
              className={clsx(
                globalStyles.newBtnContainer,
                classes.flexContainer
              )}
              onClick={() => {
                setOpenModal(true);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <span className="traitName">
                {optInfo.ciamMarketingProgram.title} - NEW Opt ID
              </span>
              <Button className={globalStyles.btn} variant="contained">
                {t("common.new")}
              </Button>
            </div>
          ),
        },
        {
          label: t("new_opt_id.opt_text_english"),
          value: optInfo.optTextEnglish,
        },
      ];
    }
    return [
      {
        label: t("common.labels.opt_details"),
        value: optIdValue.title,
      },
      {
        label: t("new_opt_id.opt_text_english"),
        value: optsMappingInfo.optText,
      },
    ];
  };

  const renderDetailsDom = () => {
    const details = getConfirmationDetails();
    const detailsDOM = details.map((info) => {
      return (
        <div
          className={clsx(
            classes.flexContainer,
            classes.infoContainer,
            classes.justifyContent
          )}
          key={`${info.label}${info.index}`}
        >
          <Typography variant="h6">{info.label} :</Typography>
          {info.label === t("new_opt_id.opt_text_english") ? (
            <div dangerouslySetInnerHTML={{ __html: info.value }} />
          ) : (
            <Typography>{info.value}</Typography>
          )}
        </div>
      );
    });
    return detailsDOM;
  };

  const renderStep = (step) => {
    if ((step === 1 || step === 2) && loading) {
      return (
        <>
          <Skeleton height={56} />
          <Skeleton height={56} />
          <Skeleton width={100} height={24} />
        </>
      );
    }
    if (step === 1) {
      const dropdownValues = optValue.length > 0 ? opts : initialOpts;
      return (
        <div
          className={clsx(classes.center, classes.container, classes.fullWidth)}
          data-testid="opts-mapping-container"
        >
          {!optIdValue.title ? (
            <CustomAutoComplete
              isMultiple={false}
              classes={{
                listbox: classes.listbox,
              }}
              options={
                optsLoading
                  ? []
                  : [...dropdownValues, newOptId].map((option) => {
                      if (option.type !== "div") {
                        return {
                          ...option,
                          title: option.ciamOptId,
                        };
                      }
                      return option;
                    })
              }
              id="combo-box-demo"
              placeholder={
                loading
                  ? t("common.loading")
                  : t("opts_mapping.placeholders.opt_id")
              }
              loading
              value={optIdValue}
              closeIcon={<></>}
              isExternalInputValue
              inputValue={optValue}
              setValue={(value) => {
                if (value && value.type !== "div") {
                  if (value) {
                    let marketingProgramNumber = "";
                    let marketingProgramDescription = "";
                    if (value && value.ciamMarketingProgram) {
                      [marketingProgramNumber, marketingProgramDescription] =
                        value.ciamMarketingProgram.split(" - ");
                    }
                    setOptsMappingInfo((draft) => {
                      draft.optId = value ? value.title : "";
                      draft.optText = value ? value.optTextEnglish : "";
                      draft.optTextLocal = value
                        ? value.optTextLocalLanguage
                        : "";
                      draft.ciamLegalEntityId = value
                        ? value.ciamLegalEntityId
                        : "";
                      draft.marketingProgramNumber = marketingProgramNumber;
                      draft.marketingProgramDescription =
                        marketingProgramDescription;
                    });
                    setOptIdValue({
                      new: false,
                      title: value.title,
                    });
                    // setMarketingProgram("");
                    if (value) {
                      memoizedMarketingProgramsFromApi(
                        "",
                        value.ciamLegalEntityId
                      );
                    }
                  }
                }
              }}
              onInputChange={(val) => {
                setOptValue(val);
                setOpts([]);
                if (val.length > 0) {
                  setOptsLoading(true);
                }
              }}
              errorText={errors.optId}
            />
          ) : (
            <div className={classes.fullWidth}>
              <TextBox
                isNew={optIdValue.new}
                name={optIdValue.title}
                onClick={() => {
                  if (optIdValue.new) {
                    setOpenModal(true);
                  } else {
                    setOpenDeleteModal(true);
                  }
                }}
                onDelete={() => {
                  // DELETE TRAIT
                  setOpenDeleteModal(true);
                }}
              />
            </div>
          )}
        </div>
      );
    }
    if (step === 2) {
      return (
        <div
          className={clsx(classes.container, classes.optMappingContainer)}
          data-testid="opts-mapping-container"
        >
          {renderDetailsDom()}
          <div className={classes.infoContainer}>
            <Typography variant="h6">
              {t("common.labels.opt_mappings")}
            </Typography>
          </div>
          {existingOptMappings.length === 0 && optMappings.length === 0 && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="info">{t("new_opt_id.no_opt_mappings")}</Alert>
            </Box>
          )}
          {existingOptMappings.map((optMapping, index) => {
            return renderOptMappingAccordion(optMapping, index, false);
          })}
          {optMappings.map((optMapping, index) => {
            return (
              <div
                key={`${
                  optMapping.cdpMarketingProgram.marketingProgramNumber
                }-${index + 1}`}
                className={clsx(
                  classes.flexContainer,
                  classes.fullWidth,
                  classes.mb20
                )}
              >
                {renderOptMappingAccordion(
                  optMapping,
                  index,
                  true,
                  applicationConfig.status.new
                )}
                <div className={clsx(classes.flexContainer, classes.ml12)}>
                  <div
                    onClick={async () => {
                      setOpenOptMappingModal(true);
                      setOptMappingIndex(index);
                      setOptMappingInfo(optMapping);
                      if (optMapping.serviceName.isNewService) {
                        setServiceName(optMapping.serviceName);
                      } else {
                        setServiceName({});
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    data-testid="button-1"
                  >
                    <span className={globalStyles.editIcon}>
                      <EditOutlinedIcon />
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setOptMappingIndex(index);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    aria-label="delete"
                    data-testid="button-2"
                  >
                    <span className={globalStyles.deleteIcon}>
                      <DeleteOutlineOutlinedIcon />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          <div ref={alertRef}>
            {errors.optMappings && isEmpty(optMappings) && (
              <Alert severity="error">{errors.optMappings}</Alert>
            )}
          </div>
          <div className={globalStyles.addTraitBtn}>
            <AddCircleOutlineIcon />
            <Button
              onClick={() => {
                setOpenOptMappingModal(true);
              }}
            >
              {t("new_opt_id.add_opt_mapping")}
            </Button>
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          {renderDetailsDom()}
          <div className={classes.confirmationContainer}>
            <Typography variant="h4">
              {t("common.labels.opt_mappings")}
            </Typography>
            {optMappings.map((optMapping, index) => {
              return (
                <div
                  key={`${
                    optMapping.cdpMarketingProgram.marketingProgramNumber
                  }-${index + 1}`}
                  className={clsx(
                    classes.flexContainer,
                    classes.fullWidth,
                    classes.trait
                  )}
                >
                  {renderOptMappingAccordion(
                    optMapping,
                    index,
                    true,
                    applicationConfig.status.new
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          height: 300 * mappingResponse.length + 60,
          maxHeight: "calc(100vh - 100px)",
          overflow: "hidden",
        }}
      >
        <Table columns={optsRequestTableColumns} rows={mappingResponse} />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/opts" goToText={t("access_denied.go_to_opts")} />
  ) : (
    <>
      <InputFlow
        steps={[
          t("new_opt_id.headings.step_1"),
          t("new_opt_id.headings.step_2"),
          t("new_opt_id.headings.step_3"),
        ]}
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        header={
          <Typography variant="h4" gutterBottom>
            {isRequestRevisionFlow
              ? createOptIdConstants.optIdRevisionHeadings[currentStep]
              : createOptIdConstants.optIdCreationHeadings[currentStep]}
          </Typography>
        }
        headerText={t("opts_container.opt_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{
                        root: globalStyles.btn,
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <div
                    className={clsx(classes.flexContainer, classes.step2Footer)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        setCurrentStep(currentStep - 1);
                      }}
                      classes={{
                        root: globalStyles.btn,
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                variant="outlined"
                disabled={loading}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      const payload = createBackendPayload(
                        optInfo,
                        optMappings,
                        optIdValue,
                        optsMappingInfo
                      );
                      increaseRequestsCount();
                      if (!isRequestRevisionFlow) {
                        const data = await createNewOpt(payload);
                        const { items } = data;
                        setMappingResponse(
                          items.map((item, index) => ({
                            id: index,
                            ...item,
                            requestId: item.requestId,
                            status:
                              item.status[0].toUpperCase() +
                              item.status.slice(1),
                            optTextEnglish: item.opt.optTextEnglish,
                            optTextLocalLanguage: item.opt.optTextLocalLanguage,
                          }))
                        );
                        setOptsOutput(items[0].output);
                      } else if (currentStep === totalSteps) {
                        const data = await updateRequest(
                          request.requestId,
                          payload,
                          applicationConfig.modules.opts
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    const formErrors = validateForm(currentStep);
                    setErrors(formErrors);
                    if (Object.keys(formErrors).length > 0) {
                      return;
                    }
                    setCurrentStep(currentStep + 1);
                  }
                }}
                classes={{
                  root: globalStyles.btn,
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {createOptIdConstants.optFooterText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/opts"
                >
                  {t("common.labels.back_to_opts")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${mappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.sourceContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {showOptOutput && (
        <OptIdRequestOutputModal
          isOpen={showOptOutput}
          isLoading={false}
          requestId={mappingResponse[0].requestId}
          marketingProgramDescription={
            mappingResponse && mappingResponse[0].marketingProgram
              ? `${mappingResponse[0].marketingProgram.marketingProgramNumber} - ${mappingResponse[0].marketingProgram.description}`
              : ""
          }
          data={optsOutput}
          setShowOptOutput={() => setShowOptOutput(false)}
        />
      )}
      <ConfirmationModal
        open={openOptTextWarningModal}
        title={t("new_opt_id.dialogs.warning")}
        message={
          <div className={classes.optTextDialogContainer}>
            <Typography>{t("new_opt_id.dialogs.opt_text_warning")}</Typography>
            <Typography>
              {t("new_opt_id.dialogs.opt_text_confirmation")}
            </Typography>
            <Typography>
              {t("new_opt_id.dialogs.opt_text_team_check")}
            </Typography>
          </div>
        }
        btn1Text={t("new_opt_id.dialogs.approved_by_legal")}
        btn2Text={t("new_opt_id.dialogs.cancel")}
        btn1Action={() => {
          setOpenOptTextWarningModal(false);
          setOptIdValue({
            new: true,
            title: `${optInfo.ciamMarketingProgram.title} - New Opt ID`,
          });
          setOpenModal(false);
        }}
        btn2Action={async () => {
          setOpenOptTextWarningModal(false);
        }}
        type="warning"
      />
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={
          currentStep === 1
            ? t("new_opt_id.dialogs.opt_id_warning")
            : t("new_opt_id.dialogs.opt_mapping_warning")
        }
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          if (currentStep === 1) {
            setOptIdValue({
              new: false,
              title: "",
            });
            setOptInfo((draft) => {
              draft.ciamMarketingProgram = "";
              draft.optTextEnglish = "";
              draft.optTextLocal = "";
            });
          } else {
            setOptMappings(
              produce((draft) => {
                draft.splice(optMappingIndex, 1);
              })
            );
            setOptMappingIndex(-1);
          }
          setOpenDeleteModal(false);
        }}
        type="error"
      />
      <NewOptIdMapping
        cdpMarketingProgramOptions={cdpMarketingProgramOptions}
        cdpMarketingProgramsLoading={cdpMarketingProgramsLoading}
        getcdpMarketingProgramOptions={async (marketingProgram) => {
          let legalEntity = null;
          if (optIdValue.new) {
            legalEntity = optInfo.ciamMarketingProgram.legalEntityNumber;
          } else {
            legalEntity = optsMappingInfo.ciamLegalEntityId;
          }
          setCdpMarketingProgramsLoading(true);
          memoizedMarketingProgramsFromApi(marketingProgram, legalEntity);
        }}
        openModal={openOptMappingModal}
        handleClose={() => {
          setOpenOptMappingModal(false);
          setOptMappingIndex(-1);
        }}
        existingOptMappings={existingOptMappings}
        optMappings={optMappings}
        editFlow={optMappingIndex > -1}
        handleSubmit={(data) => {
          if (optMappingIndex !== -1) {
            setOptMappings(
              produce((draft) => {
                draft[optMappingIndex] = data;
              })
            );
          } else {
            setOptMappings(
              produce((draft) => {
                draft.push(data);
              })
            );
          }
          setOptMappingIndex(-1);
        }}
        serviceName={serviceName}
        setServiceName={setServiceName}
        optMappingInfo={optMappingInfo}
        setOptMappingInfo={setOptMappingInfo}
        isDisabled={false}
        title={t("new_opt_id.new_opt_mapping")}
      />
      {newServiceNameModal && (
        <NewServiceName
          isOpen={newServiceNameModal}
          newServiceName={newServiceName}
          setNewServiceName={setNewServiceName}
          marketingProgramNumber={
            optMappings[optMappingIndex].cdpMarketingProgram
              .marketingProgramNumber
          }
          isDisabled
          marketingProgram={optMappings[optMappingIndex].cdpMarketingProgram}
          setNewServiceNameModal={setNewServiceNameModal}
        />
      )}
      <NewOptIdModal
        openModal={openModal}
        handleClose={() => setOpenModal(false)}
        ciamMarketingProgramOptions={ciamMarketingProgramOptions}
        setCiamMarketingProgramOptions={setCiamMarketingProgramOptions}
        ciamMarketingProgramsLoading={ciamMarketingProgramsLoading}
        setCiamMarketingProgramsLoading={setCiamMarketingProgramsLoading}
        memoizedMarketingProgramsFromApi={memoizedMarketingProgramsFromApi}
        setOptInfo={setOptInfo}
        resetState={() => {
          setOptInfo({
            ciamMarketingProgram: "",
            optTextEnglish: "",
            optTextLocal: "",
          });
        }}
        optInfo={optInfo}
        isDisabled={currentStep > 1}
        handleSubmit={() => {
          if (
            !createOptIdConstants.validOptTexts.some((optText) =>
              optInfo.optTextEnglish
                .toLowerCase()
                .includes(optText.toLowerCase())
            )
          ) {
            setOpenOptTextWarningModal(true);
          } else {
            setOptIdValue({
              new: true,
              title: `${optInfo.ciamMarketingProgram.title} - New Opt ID`,
            });
            setOpenModal(false);
          }
        }}
      />
      <Prompt
        message={() => {
          if (isRequestRevisionFlow) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={
          isInfoStep || isRequestRevisionFlow
            ? false
            : !checkDisabledStatus(currentStep)
        }
      />
    </>
  );
};

export default NewOptIdContainer;
