import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../../components/RequestOutputModal";
import StatusBadge from "../../../../../components/StatusBadge";
import Table from "../../../../../components/Table";

import applicationConfig from "../../../../../config/applicationConfig";

const EcosystemMappingRequestOutputModal = ({
  isOpen,
  requestId,
  data,
  marketingProgramDescription,
  setShowOutput,
}) => {
  const { warnings, items } = data;

  const { t } = useTranslation();

  const columns = [
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "ecosystemId",
      headerName: t("mp_ecosystem_mapping_container.ecosystem_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "ecosystemMarketingProgramName",
      headerName: t("mp_ecosystem_mapping_container.ecosystem_mp_name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "ecosystemMarketingProgramKey",
      headerName: t("mp_ecosystem_mapping_container.ecosystem_mp_key"),
      flex: 1,
      sortable: false,
    },
    {
      field: "ecosystemMarketingProgramInstance",
      headerName: t("mp_ecosystem_mapping_container.ecosystem_instance"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 1,
      sortable: false,
      renderCell: (colData) => {
        const { value } = colData;
        return <StatusBadge status={value} />;
      },
    },
    {
      field: "message",
      headerName: t("common.labels.message"),
      flex: 1,
      sortable: false,
    },
  ];

  // Process warnings
  const rows = (warnings || []).map((warning, index) => {
    return {
      id: `warn-${index}`,
      ...warning,
      marketingProgram: `${warning.marketingProgramNumber} - ${marketingProgramDescription}`,
      status: applicationConfig.status.warning,
      message: warning.message,
    };
  });

  rows.push(
    ...items.map((item, index) => {
      let status;
      let message;
      if (item.updated) {
        status = applicationConfig.status.success;
      } else if (item.new) {
        status = applicationConfig.status.success;
      } else {
        status = applicationConfig.status.warning;
        message = item.message;
      }
      return {
        id: index,
        ...item,
        marketingProgram: `${item.marketingProgramNumber} - ${marketingProgramDescription}`,
        status,
        message,
      };
    })
  );

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.ecosystem_marketing_program_modal_title")}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

EcosystemMappingRequestOutputModal.defaultProps = {
  requestId: "",
};

EcosystemMappingRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      })
    ),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
        description: PropTypes.string,
        marketingProgramNumber: PropTypes.number,
      })
    ),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  marketingProgramDescription: PropTypes.string.isRequired,
};

export default EcosystemMappingRequestOutputModal;
