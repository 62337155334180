import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  // const { type } = theme.palette;

  return {
    tableHeader: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "18px",
      paddingBottom: "12px",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      "& .MuiInputBase-input.MuiInput-input": {
        color: theme.palette.text.primary,
        fontSize: "14px",
      },
    },
    filterLeft: {
      display: "flex",
      justifyContent: "flex-start",
    },
    checkboxContainer: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    alignBottom: {
      gap: "20px",
      alignItems: "flex-end",
    },
    filterRight: {
      "& .MuiTypography-body1": {
        color: theme.palette.text.primary,
      },
      "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl": {
        top: -13,
      },
    },
    formControl: {
      width: "200px",
      marginRight: "20px",
      marginTop: "15px",
      [theme.breakpoints.down(1700)]: {
        width: "150px",
        marginRight: "10px",
      },
    },
    datePickerWrapper: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginRight: "20px",
      minWidth: "400px",
      [theme.breakpoints.down(1700)]: {
        minWidth: "360px",
        marginRight: "10px",
      },
      "& .MuiIconButton-root": {
        padding: "0",
      },
      "& > div": {
        width: 190,
        [theme.breakpoints.down(1700)]: {
          width: 175,
        },
      },
      "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium":
        {
          marginRight: "unset",
        },
      "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl": {
        left: 0,
        top: 0,
      },
    },
    dateField: {
      "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium svg":
        {
          fill: theme.palette.text.primary,
        },
    },
    seperator: {
      width: "4px",
      color: theme.palette.text.primary,
      fontSize: "14px",
      alignSelf: "center",
      margin: "25px 8px 0 8px",
    },
    selectItem: {
      fontSize: "14px !important",
    },
    // menuItemContainer: {
    //   "&.MuiListItem-root.Mui-selected": {
    //     backgroundColor: `${
    //       type === "dark"
    //         ? theme.colorTheme.grey
    //         : theme.colorTheme.greyishWhite
    //     } !important`,
    //   },
    // },
    filterIconWrapper: {
      display: "flex",
      marginLeft: "18px",
      marginTop: "35px",
    },
    filterIcon: {
      display: "flex",
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
  };
});

export default useStyles;
