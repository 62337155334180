import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginBottom: "24px",
    },
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.colorTheme.greyishWhite3}`,
      borderRadius: "4px",
      marginRight: "12px",
      padding: "10px",
      minHeight: "34px",
      "& .MuiTypography-h6": {
        fontSize: "14px",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        minWidth: "42px",
        maxHeight: "33px",
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
        marginRight: "20px",
      },
    },
    btnContainer: {
      "&:hover": {
        outline: "none",
        cursor: "pointer",
      },
    },
    editIcon: {
      "& svg": {
        fill: theme.palette.blue.light,
      },
    },
    deleteIcon: {
      "& svg": {
        fill: theme.palette.error.light,
      },
    },
  };
});

export default useStyles;
