import { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

import InlineMessage from "../../../../../components/InlineMessage";
import StyledTooltip from "../../../../../components/StyledTooltip";

import { isEmptyString } from "../../../../../utilities/formValidation";

import useStyles from "./styles";

const NewVersion = ({
  title,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  initialConsentText,
  initialNoticeText,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [consentText, setConsentText] = useState(initialConsentText);
  const [noticeText, setNoticeText] = useState(initialNoticeText);
  const [errors, setErrors] = useState({
    consentTextError: null,
    noticeTextError: null,
  });
  const resetState = () => {
    setConsentText("");
    setNoticeText("");
  };
  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => {
          handleClose();
          resetState();
        }}
        disableEnforceFocus
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">{title}</Typography>
          <Box
            className={clsx(classes.marginLeftAuto, classes.closeBtn)}
            onClick={() => {
              if (isDisabled) {
                handleClose();
              } else {
                setIsCancelClicked(true);
              }
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </Box>
        </div>
        <DialogContent>
          <div className={classes.grid}>
            <div className={classes.inputContainer}>
              <StyledTooltip
                placement="top-start"
                title={t("common.labels.consent_text")}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography variant="h6">
                    {t("common.labels.consent_text")}
                    <i style={{ color: "red" }}>*</i>
                  </Typography>
                  <InfoIcon className={classes.infoIcon} />
                </div>
              </StyledTooltip>
              <ReactQuill
                theme="snow"
                value={consentText}
                onChange={(value) => {
                  setConsentText(value);
                  setErrors({
                    ...errors,
                    consentTextError: null,
                  });
                }}
                readOnly={isDisabled}
                modules={{
                  toolbar: [
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                  ],
                }}
              />
              <InlineMessage message={errors.consentTextError} />
            </div>
            <div className={classes.inputContainer}>
              <StyledTooltip
                placement="top-start"
                title={t("common.labels.notice_text")}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography variant="h6">
                    {t("common.labels.notice_text")}
                    <i style={{ color: "red" }}>*</i>
                  </Typography>
                  <InfoIcon className={classes.infoIcon} />
                </div>
              </StyledTooltip>
              <ReactQuill
                theme="snow"
                value={noticeText}
                onChange={(value) => {
                  setNoticeText(value);
                  setErrors({
                    ...errors,
                    noticeTextError: null,
                  });
                }}
                readOnly={isDisabled}
                modules={{
                  toolbar: [
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                  ],
                }}
              />
              <InlineMessage message={errors.noticeTextError} />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          <div className={classes.btnContainer}>
            {!isDisabled && (
              <Button
                onClick={() => {
                  if (isEmptyString(consentText)) {
                    setErrors({
                      ...errors,
                      consentTextError: t("errors.field_required"),
                    });
                  }
                  if (isEmptyString(noticeText)) {
                    setErrors({
                      ...errors,
                      noticeTextError: t("errors.field_required"),
                    });
                    return;
                  }
                  handleSubmit({
                    consentText,
                    noticeText,
                  });
                  handleClose();
                  resetState();
                }}
                color="primary"
                variant="contained"
              >
                {t("common.submit")}
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => {
          setIsCancelClicked(false);
          handleClose();
          resetState();
        }}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewVersion.defaultProps = {
  isDisabled: false,
  initialConsentText: "",
  initialNoticeText: "",
};

NewVersion.propTypes = {
  openModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  initialConsentText: PropTypes.string,
  initialNoticeText: PropTypes.string,
};

export default NewVersion;
