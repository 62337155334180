import { useState, useEffect, useRef } from "react";

import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";

import useGlobalStyles from "../../assets/styles/global";

import applicationConfig from "../../config/applicationConfig";
import jobsModuleConfig from "../../config/jobsModuleConfig";
import traitsModuleConfig from "../../config/traitsModuleConfig";

import compareObjects from "../../utilities/compareObjects";
import mapValuesFromKeys from "../../utilities/mapValuesFromKeys";
import parseDate from "../../utilities/parseDate";
import useImmer from "../../utilities/useImmer";
import SearchFilter from "../SearchFilter";
import StyledTooltip from "../StyledTooltip";

import useStyles from "./styles";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const TableHeader = ({
  handleFilterChange,
  searchFilters,
  defaultSearchFilterName,
  defaultStatus,
  visibleFilters,
  isApprovalPage,
  isJobModule,
  isJobStatusFilter,
  headerComp,
  searchFilter: searchFilterProp,
}) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const location = useLocation();
  let storedFilters = [];
  const { t } = useTranslation();

  const { traitsSearchDefaultFilterState } = traitsModuleConfig;
  const { applicationModules, regionFilters, requestStatus } =
    applicationConfig;
  const { jobStatus, jobsList } = jobsModuleConfig;

  let selectItems = [
    applicationConfig.filters.all,
    requestStatus.APPROVED,
    requestStatus.REJECTED,
    requestStatus.PENDING,
    requestStatus.RETURNED,
    requestStatus.PROCESSING,
  ];
  const selectJobStatus = [
    applicationConfig.filters.all,
    jobStatus.PENDING,
    jobStatus.RUNNING,
    jobStatus.FAILED,
    jobStatus.SUCCESS,
    jobStatus.SUCCESS_PARTIAL,
  ];

  if (isJobStatusFilter) {
    selectItems = selectJobStatus;
  }

  if (location.pathname === "/tasks/requests") {
    storedFilters =
      JSON.parse(localStorage.getItem("task-filters-request")) || [];
  } else if (location.pathname === "/tasks/approvals") {
    storedFilters =
      JSON.parse(localStorage.getItem("task-filters-approval")) || [];
  }

  const [status, setStatus] = useState(
    storedFilters.find((x) => x.name === "status")?.value.split(",") ||
      defaultStatus
  );

  const [regions, setRegions] = useState(() =>
    storedFilters.find((x) => x.name === "region")?.value
      ? storedFilters
          .find((x) => x.name === "region")
          ?.value.map((x) => {
            return regionFilters.find((y) => y.name === x)?.label;
          })
      : [
          applicationConfig.filters.all,
          ...regionFilters.map((config) => config.label),
        ]
  );

  const allModules = [
    applicationConfig.filters.all,
    applicationModules.TRAITS,
    applicationModules.SOURCES,
    applicationModules.OPTS,
    applicationModules.MARKETING_PROGRAMS,
    applicationModules.EVENTS,
    applicationModules.CONSENTS,
  ];

  const allJobServices = [
    applicationConfig.filters.all,
    jobsList.lyticsQueries,
    jobsList.lyticsEvents,
    jobsList.pubsubTraits,
    jobsList.pubsubOpts,
    jobsList.pubsubMarketingPrograms,
    jobsList.mappEmails,
    jobsList.segmentDestinations,
    jobsList.segmentSources,
    jobsList.segmentTrackingPlans,
    jobsList.resciEmails,
  ];

  const [startDate, setStartDate] = useState(
    storedFilters.find((x) => x.name === "startDate")?.value || null
  );
  const [endDate, setEndDate] = useState(
    storedFilters.find((x) => x.name === "endDate")?.value || null
  );
  const [searchFilter, setSearchFilter] = useImmer({
    requestId: "",
    name: "",
    sourceName: "",
    traitName: "",
    requester: "",
    jobId: "",
    marketingProgramName: "",
    serviceName: "",
    eventName: "",
  });
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [modules, setModules] = useState(
    storedFilters.find((x) => x.name === "modules")?.value || allModules
  );
  const [isRequestTypeOpen, setIsRequestTypeOpen] = useState(false);
  const [isJobStatusOpen, setIsJobStatusOpen] = useState(false);
  const [jobServices, setJobServices] = useState(allJobServices);
  const [isRegionSelectOpen, setIsRegionSelectOpen] = useState(false);
  const childRef = useRef();

  useEffect(() => {
    const filters = [
      { name: "status", value: !status ? "" : status.join(",") },
      { name: "requestId", value: searchFilter.requestId },
      { name: "name", value: searchFilter.name },
      { name: "jobName", value: jobServices },
      { name: "sourceName", value: searchFilter.sourceName },
      { name: "traitName", value: searchFilter.traitName },
      { name: "serviceName", value: searchFilter.serviceName },
      {
        name: "marketingProgramName",
        value: searchFilter.marketingProgramName,
      },
      {
        name: "eventName",
        value: searchFilter.eventName,
      },
      { name: "startDate", value: parseDate(startDate) },
      { name: "endDate", value: parseDate(endDate) },
      { name: "requester", value: searchFilter.requester },
      {
        name: "modules",
        value: modules,
      },
      {
        name: "region",
        value: mapValuesFromKeys(
          regionFilters,
          applicationConfig.common.label,
          applicationConfig.common.name,
          regions
        ),
      },
      {
        name: "jobId",
        value: searchFilter.jobId,
      },
      {
        name: "searchText",
        value: searchFilter.searchText,
      },
    ];
    if (!status) {
      if (isApprovalPage) {
        filters[0].value = requestStatus.PENDING;
      } else {
        filters[0].value = [...selectItems].join(",");
      }
    }

    if (!compareObjects(searchFilterProp, filters)) {
      if (location.pathname === "/tasks/requests") {
        localStorage.setItem(`task-filters-request`, JSON.stringify(filters));
      } else if (location.pathname === "/tasks/approvals") {
        localStorage.setItem(`task-filters-approval`, JSON.stringify(filters));
      }
      handleFilterChange(filters);
    }
  }, [
    searchFilter,
    status,
    startDate,
    endDate,
    regions,
    modules,
    jobServices,
    searchFilterProp,
  ]);

  const resetFilter = (e) => {
    if (
      !e &&
      JSON.parse(
        localStorage.getItem(
          `task-filters-${isApprovalPage ? "approval" : "request"}`
        ) || "[]"
      ).length !== 0
    )
      return;

    setStatus(defaultStatus);
    setStartDate(null);
    setEndDate(null);
    setSearchFilter((draft) => {
      draft.requestId = "";
      draft.name = "";
      draft.sourceName = "";
      draft.traitName = "";
      draft.requester = "";
      draft.marketingProgramName = "";
      draft.serviceName = "";
      draft.eventName = "";
      draft.jobId = "";
    });
    setModules(allModules);
    setRegions([
      applicationConfig.filters.all,
      ...regionFilters.map((config) => config.label),
    ]);
    setJobServices(allJobServices);

    if (!isJobModule) childRef.current.clearFilter();
  };

  useEffect(() => {
    resetFilter();
  }, [searchFilters]);

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    const allSelected = selectedValue.includes(applicationConfig.filters.all);
    const hasAllStatus = !status
      ? false
      : status.includes(applicationConfig.filters.all);

    if (allSelected && !hasAllStatus) {
      setStatus(selectItems);
    } else if (!allSelected && hasAllStatus) {
      setStatus([]);
    } else if (
      hasAllStatus &&
      selectedValue !== applicationConfig.filters.all
    ) {
      let newSelectedValue = [];
      newSelectedValue = selectItems.filter(
        (item) =>
          !selectedValue.includes(item) &&
          item !== applicationConfig.filters.all
      );
      setStatus(newSelectedValue);
    } else {
      setStatus(selectedValue);
    }
    setIsJobStatusOpen(false);
  };

  const handleJobServiceChange = (event) => {
    const selectedValue = event.target.value;
    const allSelected = selectedValue.includes(applicationConfig.filters.all);
    const hasAllTypes = jobServices.includes(applicationConfig.filters.all);
    if (allSelected && !hasAllTypes) {
      setJobServices(allJobServices);
    } else if (!allSelected && hasAllTypes) {
      setJobServices([]);
    } else if (hasAllTypes && selectedValue !== applicationConfig.filters.all) {
      const newSelectedValue = allJobServices.filter(
        (item) =>
          !selectedValue.includes(item) &&
          item !== applicationConfig.filters.all
      );
      setJobServices(newSelectedValue);
    } else if (
      !hasAllTypes &&
      allJobServices.length - jobServices.length === 2 &&
      selectedValue.length === 3
    ) {
      setJobServices(allJobServices);
    } else {
      setJobServices(selectedValue);
    }

    setIsJobStatusOpen(false);
  };

  const handleRequestTypeChange = (event) => {
    const selectedValue = event.target.value;
    const allSelected = selectedValue.includes(applicationConfig.filters.all);
    const hasAllTypes = modules.includes(applicationConfig.filters.all);
    if (allSelected && !hasAllTypes) {
      setModules(allModules);
    } else if (!allSelected && hasAllTypes) {
      setModules([]);
    } else if (hasAllTypes && selectedValue !== applicationConfig.filters.all) {
      const newSelectedValue = allModules.filter(
        (item) =>
          !selectedValue.includes(item) &&
          item !== applicationConfig.filters.all
      );
      setModules(newSelectedValue);
    } else if (
      !hasAllTypes &&
      allModules.length - modules.length === 2 &&
      selectedValue.length === allModules.length - 1
    ) {
      setModules(allModules);
    } else {
      setModules(selectedValue);
    }
  };

  const handleRegionsChange = (event) => {
    const selectedValue = event.target.value;
    const allSelected = selectedValue.includes(applicationConfig.filters.all);
    const hasAllStatus = regions.includes(applicationConfig.filters.all);

    if (allSelected && !hasAllStatus) {
      setRegions([
        applicationConfig.filters.all,
        ...regionFilters.map((config) => config.label),
      ]);
    } else if (!allSelected && hasAllStatus) {
      setRegions([]);
    } else if (
      hasAllStatus &&
      selectedValue !== applicationConfig.filters.all
    ) {
      const newSelectedValue = regions.filter(
        (item) =>
          !selectedValue.includes(item) &&
          item !== applicationConfig.filters.all
      );
      setRegions(newSelectedValue);
    } else {
      setRegions(selectedValue);
    }
  };

  const getStatus = () => {
    if (!status) {
      return defaultStatus.includes(t("status.all"))
        ? [t("status.all")]
        : defaultStatus;
    }

    return status.includes(t("status.all")) ? [t("status.all")] : status;
  };

  return (
    <div className={classes.tableHeader}>
      <div className={classes.filterLeft}>
        {visibleFilters.includes(applicationConfig.filters.status) && (
          <FormControl className={classes.formControl}>
            <InputLabel id="status-label-666">{t("status.status")}</InputLabel>
            <Select
              labelId="status-label-666"
              id="demo-multiple-checkbox"
              value={getStatus()}
              data-testid="select-filter"
              multiple
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              onChange={handleStatusChange}
              open={isSelectOpen}
              onOpen={() => setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen(false)}
              // MenuProps={MenuProps}
              inputProps={{
                id: "select-status",
              }}
            >
              {selectItems.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  className={classes.menuItemContainer}
                >
                  <div className={classes.checkboxContainer}>
                    <Checkbox
                      checked={
                        (!status ? defaultStatus : status).indexOf(item) > -1
                      }
                    />
                  </div>
                  <span className={classes.selectItem}>{item}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {visibleFilters.includes(applicationConfig.filters.date_range) && (
          <div className={classes.datePickerWrapper}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} />}
                id="start-date"
                data-testid="start-date"
                placeholder="YYYY-MM-DD"
                label="From"
                value={startDate}
                onChange={(date) => {
                  if (date === null) {
                    setEndDate(null);
                  }
                  setStartDate(date);
                }}
                className={classes.dateField}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
              />
              <DesktopDatePicker
                id="end-date"
                data-testid="end-date"
                inputFormat="YYYY-MM-DD"
                placeholder="YYYY-MM-DD"
                minDate={startDate}
                label="To"
                value={endDate}
                disabled={!startDate}
                onChange={(date) => {
                  if (date === null) {
                    setStartDate(null);
                  }
                  setEndDate(date);
                }}
                minDateMessage={t("table_header.date_validation")}
                className={classes.dateField}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                renderInput={(params) => <TextField {...params} />}
                components={{
                  OpenPickerIcon: CalendarIcon,
                }}
              />
            </LocalizationProvider>
          </div>
        )}
        {visibleFilters.includes("modules") && (
          <FormControl className={classes.formControl}>
            <InputLabel id="status-label-667">{t("type.modules")}</InputLabel>
            <Select
              value={
                modules.includes(t("type.all")) ? [t("type.all")] : modules
              }
              labelId="status-label-667"
              data-testid="select-filter"
              multiple
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              onChange={handleRequestTypeChange}
              open={isRequestTypeOpen}
              onOpen={() => setIsRequestTypeOpen(true)}
              onClose={() => setIsRequestTypeOpen(false)}
              inputProps={{
                id: "select-status",
              }}
            >
              {allModules.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  className={classes.menuItemContainer}
                >
                  <div className={classes.checkboxContainer}>
                    <Checkbox checked={modules.indexOf(item) > -1} />
                  </div>
                  <span className={classes.selectItem}>{item}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {visibleFilters.includes(applicationConfig.filters.regions) && (
          <FormControl className={classes.formControl}>
            <InputLabel id="status-label-668">
              {t("common.labels.region")}
            </InputLabel>
            <Select
              value={
                regions.includes(t("status.all")) ? [t("status.all")] : regions
              }
              labelId="status-label-667"
              data-testid="select-filter"
              multiple
              input={<Input />}
              renderValue={(selected) =>
                selected.includes(t("status.all"))
                  ? `${regions.length - 1} ${t("common.labels.regions")}`
                  : `${selected.length} ${t("common.labels.regions")}`
              }
              onChange={handleRegionsChange}
              open={isRegionSelectOpen}
              onOpen={() => setIsRegionSelectOpen(true)}
              onClose={() => setIsRegionSelectOpen(false)}
              inputProps={{
                id: "select-status",
              }}
            >
              {[{ label: t("status.all") }, ...regionFilters].map((item) => (
                <MenuItem
                  value={item.label}
                  key={item.label}
                  className={classes.menuItemContainer}
                >
                  <div className={classes.checkboxContainer}>
                    <Checkbox checked={regions.indexOf(item.label) > -1} />
                  </div>
                  <span className={classes.selectItem}>{item.label}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {visibleFilters.includes(
          applicationConfig.filters.job_service_type
        ) && (
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="status-label">
              {t("jobs_monitoring_container.job_services")}
            </InputLabel>
            <Select
              value={
                jobServices.includes(t("type.all"))
                  ? [t("type.all")]
                  : jobServices
              }
              data-testid="select-filter"
              multiple
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              onChange={handleJobServiceChange}
              open={isJobStatusOpen}
              onOpen={() => setIsJobStatusOpen(true)}
              onClose={() => setIsJobStatusOpen(false)}
              inputProps={{
                id: "select-status",
              }}
            >
              {allJobServices.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  className={classes.menuItemContainer}
                >
                  <div className={classes.checkboxContainer}>
                    <Checkbox checked={jobServices.indexOf(item) > -1} />
                  </div>
                  <span className={classes.selectItem}>{item}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <StyledTooltip placement="top" title="Reset filters">
          <div
            role="button"
            aria-hidden="true"
            data-testid="reset-btn"
            className={classes.filterIconWrapper}
            onClick={resetFilter}
          >
            <FilterIcon
              data-testid="filter-icon"
              className={classes.filterIcon}
            />
          </div>
        </StyledTooltip>
      </div>
      <div className={clsx(globalClasses.flexContainer, classes.alignBottom)}>
        {headerComp}
        {!isJobModule && (
          <div className={classes.filterRight}>
            <SearchFilter
              ref={childRef}
              defaultName={defaultSearchFilterName}
              onSearch={(data) => {
                setSearchFilter((draft) => {
                  draft.requestId = "";
                  draft.name = "";
                  draft.sourceName = "";
                  draft.traitName = "";
                  draft.requester = "";
                  draft.serviceName = "";
                  draft.marketingProgramName = "";
                  draft.eventName = "";
                  draft.jobId = "";
                  draft.searchText = "";
                });
                setSearchFilter((draft) => {
                  if (data.name) {
                    draft[data.name] = data.value;
                  } else {
                    draft.jobId = data.value;
                    draft.searchText = data.value;
                  }
                });
              }}
              defaultFilterState={traitsSearchDefaultFilterState}
              searchFilters={searchFilters}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TableHeader.defaultProps = {
  defaultSearchFilterName: "",
  defaultStatus: [applicationConfig.filters.all],
  visibleFilters: [
    applicationConfig.filters.status,
    applicationConfig.filters.date_range,
  ],
  isApprovalPage: false,
  isJobModule: false,
  isJobStatusFilter: false,
  searchFilters: [],
  headerComp: <></>,
  searchFilter: {},
};

TableHeader.propTypes = {
  defaultSearchFilterName: PropTypes.string,
  handleFilterChange: PropTypes.func.isRequired,
  defaultStatus: PropTypes.arrayOf(PropTypes.string),
  searchFilters: PropTypes.arrayOf(PropTypes.shape({})),
  visibleFilters: PropTypes.arrayOf(PropTypes.string),
  isApprovalPage: PropTypes.bool,
  isJobModule: PropTypes.bool,
  isJobStatusFilter: PropTypes.bool,
  headerComp: PropTypes.node,
  searchFilter: PropTypes.array,
};

export default TableHeader;
