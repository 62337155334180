import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  programs: {
    overflowY: "scroll",
    maxHeight: 400,
    width: "calc(100% - 32px)",
    padding: 16,
    border: `1px solid ${theme.colorTheme.greyishWhite}`,
  },
  programName: {},
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 34,
  },
  programsMenu: {
    marginTop: "2px",
    fontFamily: "Roboto, Hevetica, Arial, sans-serif",
    fontSize: 13,
  },
  programContainer: {
    "& > p:nth-child(1)": {
      marginBottom: 5,
      fontWeight: "bold",
      color: `${theme.colorTheme.black}`,
      fontSize: 14,
    },
    "& > p:nth-child(2)": {
      fontWeight: "400",
      color: `${theme.colorTheme.grey}`,
      fontSize: 13,
    },
  },
  horizontalBar: {
    height: "1px",
    backgroundColor: `${theme.colorTheme.black}`,
    opacity: 0.5,
    marginTop: 15,
    marginBottom: 15,
  },
  table: {
    height: "300px !important",
  },
}));

export default useStyles;
