import api from "../utilities/api";
import { getValue } from "../utilities/cache";
import isEmpty from "../utilities/isEmpty";

const getTraitsResponses = async (queryParams) => {
  let url = "/traits/responses";
  const refreshCache = getValue("traits");
  if (!isEmpty(queryParams)) {
    url += `${queryParams}&refreshCache=${refreshCache}&schemaVersion=v2`;
  } else {
    url += `?refreshCache=${refreshCache}&schemaVersion=v2`;
  }
  const {
    data: { data },
  } = await api.get(url);
  return data;
};

export default getTraitsResponses;
