import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    newMp: {
      display: "flex",
      alignItems: "center",
      "& .MuiButton-label": {
        color: theme.palette.blue.main,
        textTransform: "upperCase",
        fontWeight: "normal",
      },
      "& .MuiButton-root": {
        padding: "0px",
      },
      "& .MuiTypography-h6": {
        fontSize: "14px",
        color: theme.colorTheme.grey,
        fontWeight: "normal",
        marginRight: "8px",
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: theme.palette.background.default1,
      },
      "& .nextStep": {
        "& .MuiCircularProgress-circle.MuiCircularProgress-circle": {
          stroke: "white",
        },
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    manageEcosystemDetailsWrapper: {
      margin: "20px 0",
      display: "flex",
      alignItems: "center",
    },
    manageEcosystemDetailsBtns: {
      marginLeft: "15px",
      "& .MuiButtonBase-root": {
        border: `2px solid ${theme.palette.blue.darker}`,
        fontSize: "18px",
        padding: "unset !important",
        color: theme.palette.blue.darker,
      },
      "& .MuiButtonBase-root:first-child": {
        borderRadius: "10px",
        borderBottomRightRadius: "unset",
        borderTopRightRadius: "unset",
      },
      "& .MuiButtonBase-root:not(:first-child)": {
        borderRadius: "10px",
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
      },
      "& .MuiButtonBase-root.active": {
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
      },
    },
    autoCompleteEditContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.blue.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    autoCompleteDeleteContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.error.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    confirmationContainer: {
      "& > div": {
        marginTop: "10px",
      },
      "& .MuiTypography-h4": {
        fontSize: "16px",
        fontWeight: "500",
        margin: "16px 0",
        color: theme.colorTheme.grey,
      },
      "& .MuiButton-containedPrimary": {
        width: "100%",
      },
      "& .MuiPaper-elevation1": {
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      },
    },
    statusLabel: {
      marginLeft: "auto",
    },
    deleted: {
      backgroundColor: theme.palette.error.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
    },
    new: {
      backgroundColor: theme.palette.primary.main,
      minWidth: 88,
      padding: "5px 8px",
      borderRadius: 4,
      fontSize: "12px",
      textAlign: "center",
      color: theme.colorTheme.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    myRequestsTable: {
      minHeight: 70,
      height: "100% !important",
      "& .MuiDataGrid-window": {
        overflowY: "scroll !important",
        overflowX: "hidden !important",
      },
      "& .MuiDataGrid-cell": {
        paddingBottom: "0px !important",
      },
    },
    customAutoCompleteRoot: {
      width: "100% !important",
    },
    statusWrapper: {
      display: "flex",
    },
    statusText: {
      verticalAlign: "top",
    },
    statusAlert: {
      height: "10px",
      width: "10px",
      marginRight: "9px",
      borderRadius: "50%",
      marginTop: "2px",
    },
  };
});

export default useStyles;
