import { useMemo, useRef, useState } from "react";

import { Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import useGlobalStyles from "../../../../../assets/styles/global";
import { AccordionModalDetails } from "../../../../../components/AccordionModal";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import consentsModuleConfig from "../../../../../config/consentsModuleConfig";
import useNotifier from "../../../../../hooks/useNotifier";
import NewVersion from "../NewVersion";

const Versions = ({
  consentTemplateVersions,
  onAddNewVersion,
  onEditNewVersion,
  onDeleteNewVersion,
  viewOnly,
}) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const { addNotification } = useNotifier();

  const modalRef = useRef();

  const { consentVersionKeys } = consentsModuleConfig;

  const [openVersionModal, setOpenVersionModal] = useState(false);
  const [editRow, setEditRow] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const disableAddNewVersion = useMemo(() => {
    return consentTemplateVersions.find((v) => v.new) !== undefined;
  }, [consentTemplateVersions]);

  return (
    <>
      <AccordionModalDetails
        ref={modalRef}
        emptyDataMessage={t("create_consent_template.emptyVersionsMessage")}
        keys={consentVersionKeys}
        rows={consentTemplateVersions?.map((version) => ({
          ...version,
          templateVersionNumber: `V${version.templateVersionNumber}`,
          createdDate: version.createdDate
            ?.replace("T", " ")
            ?.replace(".000Z", ""),
        }))}
        rowKeys={{
          title: "templateVersionNumber",
          subTitle: "consentText",
        }}
        showDelete={(row) => row.new === true && !viewOnly}
        showEdit={(row) => row.new === true && !viewOnly}
        onEdit={(row) => {
          setOpenVersionModal(true);
          setEditRow(row);
        }}
        onDelete={(row) => {
          setEditRow(row);
          setOpenDeleteModal(true);
        }}
      />
      {!viewOnly && (
        <div className={globalStyles.addTraitBtn}>
          <AddCircleOutlineIcon />
          <Button
            onClick={() => {
              if (disableAddNewVersion) {
                addNotification(
                  t("create_consent_template.disable_new_version_msg")
                );
                return;
              }
              setOpenVersionModal(true);
            }}
          >
            {t("create_consent_template.new_version")}
          </Button>
        </div>
      )}
      {openVersionModal && (
        <NewVersion
          title={t("create_consent_template.new_version")}
          openModal={openVersionModal}
          handleClose={() => {
            setOpenVersionModal(false);
          }}
          handleSubmit={(newVersion) => {
            if (editRow) {
              onEditNewVersion({
                ...editRow,
                consentText: newVersion.consentText,
                noticeText: newVersion.noticeText,
                templateVersionNumber: Number(
                  editRow.templateVersionNumber.replace("V", "")
                ),
              });
              setEditRow(null);
            } else {
              const newVersionData = {
                ...newVersion,
                templateVersionNumber: consentTemplateVersions.length + 1,
              };
              onAddNewVersion(newVersionData);
              modalRef.current.scrollTop = 0;
            }
            setOpenVersionModal(false);
          }}
          initialConsentText={editRow?.consentText}
          initialNoticeText={editRow?.noticeText}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          setEditRow(null);
        }}
        title={t("dialogs.confirm_remove")}
        message={t("create_consent_template.delete_version")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onDeleteNewVersion(editRow);
          setEditRow(null);
          setOpenDeleteModal(false);
        }}
        type="error"
      />
    </>
  );
};

Versions.defaultProps = {
  consentTemplateVersions: [],
  onAddNewVersion: () => {},
  onEditNewVersion: () => {},
  onDeleteNewVersion: () => {},
  viewOnly: false,
};

Versions.propTypes = {
  consentTemplateVersions: PropTypes.arrayOf({
    templateVersionNumber: PropTypes.number.isRequired,
    consentText: PropTypes.string.isRequired,
    noticeText: PropTypes.string.isRequired,
  }),
  onAddNewVersion: PropTypes.func,
  onEditNewVersion: PropTypes.func,
  onDeleteNewVersion: PropTypes.func,
  viewOnly: PropTypes.bool,
};

export default Versions;
