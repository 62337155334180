import traitsModuleConfig from "../../../../config/traitsModuleConfig";

const createBackendPayload = (
  traits,
  marketingPrograms,
  ecosystems,
  mappingStore
) => {
  const survivorshipRules = [];
  const traitResponseTypes = [];
  traits.forEach((trait) => {
    marketingPrograms.forEach((marketingProgram) => {
      if (
        `${trait.traitId || trait.traitName}#${
          marketingProgram.marketingProgramNumber
        }` in mappingStore
      )
        traitResponseTypes.push({
          traitId: trait.traitId,
          traitName: trait.traitName,
          marketingProgramNumber: marketingProgram.marketingProgramNumber,
          multiAnswerResponseIndicator:
            mappingStore[
              `${trait.traitId || trait.traitName}#${
                marketingProgram.marketingProgramNumber
              }`
            ],
        });
      ecosystems.forEach((ecosystem) => {
        if (
          `${trait.traitId || trait.traitName}#${
            marketingProgram.marketingProgramNumber
          }#${ecosystem.ecosystemName}` in mappingStore
        )
          survivorshipRules.push({
            traitId: trait.traitId,
            traitName: trait.traitName,
            marketingProgramNumber: marketingProgram.marketingProgramNumber,
            ecosystemId: ecosystem.ecosystemId,
            survivorshipRule:
              traitsModuleConfig.survivorshipRuleMapping[
                mappingStore[
                  `${trait.traitId || trait.traitName}#${
                    marketingProgram.marketingProgramNumber
                  }#${ecosystem.ecosystemName}`
                ]
              ],
          });
      });
    });
  });
  return {
    traits: traits.map((trait) => {
      if (!trait.isNewTrait) {
        return {
          traitId: trait.traitId,
          traitName: trait.traitName,
        };
      }
      return {
        traitName: trait.traitName,
        description: trait.traitDescription,
        personalData: trait.personalData,
        personalDataNonPii: trait.personalDataNonPii,
        healthData: trait.healthData,
        dataType: trait.dataType.toLowerCase(),
        dataClass: trait.dataClass,
        traitType: trait.traitType,
        exampleValues: trait.exampleValues ? trait.exampleValues : null,
        formInput: trait.traitInputType ? trait.traitInputType : null,
        uDataChangeFrequency: trait.frequencyChange
          ? trait.frequencyChange
          : null,
        multiAnswerResponseIndicator: trait.traitResponseType === "Multiple",
        survivorshipRule:
          traitsModuleConfig.survivorshipRuleMapping[trait.survivorshipRule],
        ecosystemTraits: trait.ecoSystemTraits.map((ecoTrait) => {
          return {
            ecosystemId: ecoTrait.ecosystemId,
            ecosystemTraitName: ecoTrait.ecosystemTraitName,
            ecosystemName: ecoTrait.ecosystemName,
            dataType: ecoTrait.dataType.toLowerCase(),
            dataClass: ecoTrait.dataClass,
            ecosystemTraitType: ecoTrait.ecosystemTraitType,
            ecosystemTraitMiscProperty1: ecoTrait.ecosystemTraitMiscProperty1,
          };
        }),
      };
    }),
    marketingPrograms: marketingPrograms.map((program) => ({
      marketingProgramNumber: program.marketingProgramNumber,
      description: program.description,
    })),
    ecosystems: ecosystems.map((system) => ({
      ecosystemId: system.ecosystemId,
      ecosystemName: system.ecosystemName,
    })),
    survivorshipRules,
    traitResponseTypes,
  };
};

export default createBackendPayload;
