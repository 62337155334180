import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
    fullWidth: {
      width: "100%",
    },
    marginTop20: {
      marginTop: "10px",
    },
    center: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      "& .MuiAutocomplete-root": {
        width: "98% !important",
        marginBottom: "24px",
      },
    },
    autoCompleteBox: {
      width: "100%",
    },
    autoCompleteDeleteContainer: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        marginLeft: "10px",
        marginBottom: "18px",
        width: "24px",
        height: "24px",
        fill: theme.palette.error.light,
      },
      "&:focus": {
        outline: "none",
      },
    },
    badge: {
      backgroundColor: theme.palette.blue.darker,
      marginLeft: "10px",
      border: 0,
      padding: "8px",
      borderRadius: "20px",
      minWidth: "42px",
      height: "25px",
    },
    badgeText: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.static.white,
    },
    traitContainer: {
      "& .MuiAutocomplete-tag": {
        backgroundColor: theme.palette.blue.main,
        color: theme.colorTheme.white,
        borderRadius: "4px",
        "& .MuiChip-label": {
          fontSize: "14px",
        },
        "& svg": {
          color: theme.colorTheme.white,
          width: "20px",
          height: "20px",
        },
      },
    },
    paddingZero: {
      padding: "0px",
    },
    justifyContent: {
      justifyContent: "space-between",
    },
    marketingProgramContainer: {
      "& > div:first-child": {
        marginBottom: "40px",
      },
      "& .label": {
        color: theme.colorTheme.grey,
      },
    },
    newBtnContainer: {
      display: "inline-block",
      cursor: "pointer",
      "& span.traitName": {
        color: theme.palette.blue.main,
      },
      "& .MuiButton-root": {
        width: "37px",
        height: "20px",
        backgroundColor: theme.palette.blue.main,
        color: theme.colorTheme.white,
        borderRadius: "10px",
        margin: "0px 10px",
      },
    },
    infoContainer: {
      marginBottom: "10px",
      "& .MuiTypography-h6": {
        fontSize: "16px",
        color: theme.colorTheme.grey,
      },
      "& .MuiTypography-h6:last-child": {
        color: theme.colorTheme.grey,
        // fontWeight: "bold",
        flex: "0 0 50%",
        whiteSpace: "break-spaces",
      },
    },
    footer: {
      width: "100%",
      marginTop: "10px",
      marginBottom: "40px",
      "& button": {
        minWidth: "94px",
      },
      "& button:disabled": {
        backgroundColor: `${theme.colorTheme.greyishWhite} !important`,
        border: "none",
        color: `${theme.colorTheme.greyishWhite2} !important`,
      },
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: theme.palette.background.default1,
      },
      "& .nextStep": {
        "& .MuiCircularProgress-circle.MuiCircularProgress-circle": {
          stroke: "white",
        },
      },
    },
    btnSwitchWrapper: {
      "& >div:nth-child(2) h5 + div:nth-child(2)": {
        width: "95%",
      },
      "& .MuiTypography-root": {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    btnSwitchWrapper2: {
      margin: "20px 0",
      display: "flex",
      alignItems: "center",

      "& .label": {
        flex: "0 0 25%",
      },
    },
    mapBtns: {
      marginLeft: "15px",
      "& .MuiButtonBase-root": {
        border: `2px solid ${theme.palette.blue.darker}`,
        fontSize: "18px",
        padding: "unset !important",
        color: theme.palette.blue.darker,
      },
      "& .MuiButtonBase-root:first-child": {
        borderRadius: "10px",
        borderBottomRightRadius: "unset",
        borderTopRightRadius: "unset",
      },
      "& .MuiButtonBase-root:not(:first-child)": {
        borderRadius: "10px",
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
      },
      "& .MuiButtonBase-root.active": {
        backgroundColor: theme.palette.blue.darker,
        color: theme.palette.static.white,
      },
    },
    listbox: {
      "& .newTrait": {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-label": {
          color: theme.palette.blue.main,
          textTransform: "upperCase",
          fontWeight: "normal",
        },
        "& .MuiButton-root": {
          padding: "0px",
        },
        "& .MuiTypography-h6": {
          fontSize: "14px",
          color: theme.colorTheme.grey,
          fontWeight: "normal",
          marginRight: "8px",
        },
      },
    },
    statusWrapper: {
      display: "flex",
    },
  };
});

export default useStyles;
