import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Form from "../../../../components/Form";
import i18n from "../../../../i18n/init";

import getFields from "../../NewOptsMappingContainer/getFields";

import convertBackendPayload from "../../NewOptsMappingContainer/helpers/convertBackendPayload";

import useStyles from "./styles";

const NewOptsMapping = ({
  isOpen,
  optsMappingInfo,
  setNewOptsMappingModal,
  title,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const payload = convertBackendPayload(optsMappingInfo);

  let mappingInfoFields = getFields(payload);

  mappingInfoFields = mappingInfoFields.map((field) => {
    if (field.label === i18n.t("common.labels.opt_text")) {
      return {
        ...field,
        tooltip: {
          title: field.props.value,
          placement: "top-start",
          icon: <InfoIcon className={classes.infoIcon} />,
        },
        props: {
          ...field.props,
          disabled: true,
          variant: "outlined",
        },
      };
    }

    if (field.label === i18n.t("common.labels.marketing_program")) {
      return {
        ...field,
        label: i18n.t("marketing_programs.service_marketing_program"),
        props: {
          ...field.props,
          disabled: true,
          variant: "outlined",
        },
      };
    }

    return {
      ...field,
      props: {
        ...field.props,
        disabled: true,
        variant: "outlined",
      },
    };
  });

  mappingInfoFields.splice(2, 0, {
    label: i18n.t("marketing_programs.opt_marketing_program"),
    type: "dropdown",
    props: {
      value: `${optsMappingInfo.opt.marketingProgramNumber} - ${optsMappingInfo.opt.marketingProgramDescription}`,
      disabled: true,
      variant: "outlined",
    },
  });

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.MuiPaper,
      }}
    >
      <DialogContent>
        <div>
          <Typography variant="h5">{title}</Typography>
          <div className={classes.horizontalBar} />
          <Form
            fields={mappingInfoFields}
            fieldClassName={classes.inputContainer}
            containerClassName={classes.columnGrid}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setNewOptsMappingModal(false);
          }}
        >
          {t("common.close2")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewOptsMapping.defaultProps = {
  isOpen: false,
  optsMappingInfo: {},
  setNewOptsMappingModal: () => {},
  title: i18n.t("opts_mapping.headings.step_1"),
};

NewOptsMapping.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  optsMappingInfo: PropTypes.shape({
    opt: PropTypes.shape({
      marketingProgramNumber: PropTypes.number.isRequired,
      marketingProgramDescription: PropTypes.string.isRequired,
    }),
  }),
  setNewOptsMappingModal: PropTypes.func,
};

export default NewOptsMapping;
