import i18n from "../../../../i18n/init";
import { isEmptyString } from "../../../../utilities/formValidation";

const validateNewSegmentSource = (
  focused,
  newSegmentSource,
  marketingProgram,
  isDisabled,
  sourceAlreadyPresent
) => {
  const errors = {
    ecosystemSourceNameError: null,
    sourceIntegrationTypeError: null,
    ecosystemSourceSlugError: null,
    ecosystemSourceKeyError: null,
  };

  if (isDisabled) {
    return errors;
  }
  if (sourceAlreadyPresent) {
    if (
      focused.ecosystemSourceSlug &&
      isEmptyString(newSegmentSource.ecosystemSourceSlug)
    ) {
      errors.ecosystemSourceSlugError = i18n.t("errors.field_required");
    } else if (!isEmptyString(newSegmentSource.ecosystemSourceSlug)) {
      if (!/^[a-z0-9_]*$/.test(newSegmentSource.ecosystemSourceSlug)) {
        errors.ecosystemSourceSlugError = "Must be in snake case format";
      }
    }

    if (
      focused.ecosystemSourceKey &&
      isEmptyString(newSegmentSource.ecosystemSourceKey)
    ) {
      errors.ecosystemSourceKeyError = i18n.t("errors.field_required");
    } else if (!isEmptyString(newSegmentSource.ecosystemSourceKey)) {
      if (
        newSegmentSource.ecosystemSourceKey.length < 5 ||
        !/^[a-z0-9]+$/i.test(newSegmentSource.ecosystemSourceKey)
      ) {
        errors.ecosystemSourceKeyError =
          "Must be at least 5 characters long alphanumeric value";
      }
    }
  }
  if (
    focused.ecosystemSourceName &&
    isEmptyString(newSegmentSource.ecosystemSourceName)
  ) {
    errors.ecosystemSourceNameError = i18n.t("errors.field_required");
  } else if (!isEmptyString(newSegmentSource.ecosystemSourceName)) {
    if (/\(.*\)/.test(newSegmentSource.ecosystemSourceName)) {
      errors.ecosystemSourceNameError =
        "Please don't include data source ids in the segment source name. It will be added automatically";
    }
  }

  if (
    focused.sourceIntegrationType &&
    isEmptyString(newSegmentSource.sourceIntegrationType)
  ) {
    errors.sourceIntegrationTypeError = i18n.t("errors.field_required");
  }

  if (!isEmptyString(newSegmentSource.ecosystemSourceName)) {
    if (
      !newSegmentSource.ecosystemSourceName.startsWith(
        marketingProgram.description
      )
    ) {
      errors.ecosystemSourceNameError = i18n.t(
        "new_source.errors.source_name_prefix",
        {
          sourceName: marketingProgram.description,
        }
      );
    } else {
      const sourceNameSuffix = newSegmentSource.ecosystemSourceName
        .replace(marketingProgram.description, "")
        .replace(/\s/g, "");
      if (isEmptyString(sourceNameSuffix)) {
        errors.ecosystemSourceNameError = i18n.t(
          "new_source.errors.source_name_suffix"
        );
      }
    }
  }

  return errors;
};

export default validateNewSegmentSource;
