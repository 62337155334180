import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    border: `1px solid ${theme.colorTheme.greyishWhite}`,
  },
  optModalContents: {
    minWidth: "800px",
    "& .MuiTabs-root + div": {
      maxWidth: "unset",
    },
  },
  tab: {
    marginBottom: "20px",
  },
}));

export default useStyles;
